// Customizable Area Start
import React from "react";
import { Box, Button, Grid, ThemeProvider, Typography, CircularProgress, createTheme } from "@material-ui/core";
import Popup from 'reactjs-popup';
import CloseIcon from '@material-ui/icons/Close';
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { CustomButton } from "../../../components/src/CustomButton";
import { popImages } from "../../locallistingads/src/assets";
import { image_Gray, image_Green } from "./assets";
import FromApprovalSellerController from "./FromApprovalSellerController";
export default class FromApprovalSeller extends FromApprovalSellerController {

    renderHeaders() {
        return (
            <Box>
                <Typography style={StylesNegotiation.heading}>
                    {this.state.datas.title}
                </Typography>
                <Typography style={StylesNegotiation.userName}>
                    @{this.state.userDetailss}
                </Typography>
            </Box>
        );
    }
    handleChangeDatas = (e: any) => {
        this.setState({ negotiatedReasons: e.target.value });
    }

    handleCommissionReasons = (e:any) => {
        const { value } = e.target;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (value !== '' && !regex.test(value)) {
            return;
        }
        const val = parseFloat(value);
        if (value === '' || !isNaN(val)) {
            this.setState({ commissionReasons: value });
        }
     };

    handleBudgetReasons = (e: { target: { value: string; }; }) => {
        const { value } = e.target;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (value !== '' && !regex.test(value)) {
            return;
        }
        const numericValue = parseFloat(value);
        if (value === '' || !isNaN(numericValue)) {
            this.setState({ budgetReasons: value });
        }
    };

    handlepreferenceReasons = (e: any) => {
        this.setState({ preferenceReasons: e.target.value })
    }
    getButtonLabels(status: any) {
        return status === 'accept' ? "Accepted" : 'Negotiate';
    }
    getButtonStyle = (status: any) => {
        return status === 'accept' ? StylesNegotiation.acceptButtonOnclick : StylesNegotiation.negotiateButtonOnClick;
    };

    renderAmendmentCriteria(status: any) {
        const { amendment_dealss } = this.state;

        return amendment_dealss.map((criteria: any) => {
            return (
                <div key={criteria.id}>
                    {criteria.amendment_type === "budget" && status === 'negotiate' ? (
                        <div style={StylesNegotiation.textAreaDisable2}>
                            <span style={StylesNegotiation.spanText2}>{criteria.reason}</span>
                            <div style={{ alignSelf: 'end' }}>
                                <Grid item>
                                    <Grid container spacing={2} justifyContent="flex-end">
                                        <Grid item>
                                            <CustomButton
                                                label="Accept"
                                                style={this.state.buttonClickeds ? StylesNegotiation.acceptButtonOnclick : StylesNegotiation.acceptButton}
                                                data-testid="handleAcceptClick"
                                                onClick={this.handleAcceptClick}
                                            ></CustomButton>
                                        </Grid>
                                        <Grid item>
                                            <CustomButton
                                                label="Amended"
                                                style={this.state.negotiateButtons ? StylesNegotiation.negotiateButtonOnClick : StylesNegotiation.negotiateButton}
                                                data-testid="handleNegotiateClick"
                                                onClick={this.handleNegotiateClick}
                                            ></CustomButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        });
    }

    renderTextAreas(state: any) {
        if (state.showTextAreas) {
            return (
                <>
                    <Typography style={StylesNegotiation.counterOffer}>Counter-offer</Typography>
                    <textarea
                        placeholder="Type here..."
                        style={{
                            ...StylesNegotiation.textAreaStyle,
                            backgroundColor: state.negotiatedReasons ? '#E2E8F0' : '',
                            color: state.negotiatedReasons ? '#676767' : '',
                            border: state.negotiatedReasons ? 'none' : '1px solid #E5E7EB',
                            borderRadius: state.negotiatedReasons ? '8px' : '',
                            resize: 'none',
                            marginTop: '5px',
                            padding: '5px',
                            width: '100%',
                            height: '100px',
                        }}
                        value={state.negotiatedReasons}
                        onChange={(e) => this.handleChangeDatas(e)}
                        data-testid="handleChangeDatas"
                    />
                </>
            );
        }
        return null;
    }

    renderBudgetSectionn() {
        const budgetDeal = this.state.amendment_dealss.find((deal: { amendment_type: string; }) => deal.amendment_type === 'budget');
        if (!budgetDeal) {
            return null;
        }
        const { status } = budgetDeal;
        const budgetDeals = this.state.amendment_dealss.filter((deal: { amendment_type: string; }) => deal.amendment_type === 'budget');
        const latestStatus = budgetDeals.reduce((acc: { accept: boolean; negotiate: boolean; }, deal: { status: string; }) => {
            if (deal.status === 'accept') {
                acc.accept = true;
            } else if (deal.status === 'negotiate') {
                acc.negotiate = true;
            }
            return acc;
        }, { accept: false, negotiate: false });

        return (
            <>
                <Grid container alignItems="center">
                    <Box width='100%' display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography style={StylesNegotiation.budget}>{this.state.listingTypes === "looking_for_something" ? "Budget " : "Price "}<span style={StylesNegotiation.price}>£ {this.state.datas.budget}</span></Typography>
                        <Box>
                            {latestStatus.accept && <img width="32px" height="32px" src={image_Green} alt="Accepted" />}
                            {status === "negotiate" && !latestStatus.accept && <img width="32px" height="32px" src={image_Gray} alt="Negotiated" />}
                        </Box>
                    </Box>
                </Grid>
                <div>{this.renderAmendmentCriteriaBudget(budgetDeal.status, latestStatus.accept)}</div>
                {this.state.amendment_dealss?.map((criteria: { id: string | number }) => {
                    const styles = this.state.budgetReasons ?
                        StylesNegotiation.reason
                        : StylesNegotiation.noReason

                    return(
                        <React.Fragment key={criteria.id}>
                            {this.state.showTextAreas === criteria.id && (
                                <>
                                    <Typography style={StylesNegotiation.counterOffer}>Counter-offer</Typography>
                                    <textarea
                                        placeholder="Type here..."
                                        style={styles as React.CSSProperties}
                                        value={this.state.budgetReasons}
                                        onChange={(e) => this.handleBudgetReasons(e)}
                                        onPaste={(e) => { e.preventDefault(); }}
                                        data-test-id="handleBudgetReasons"
                                    />
                                </>
                            )}
                        </React.Fragment>
                    )}
                )}
            </>
        );
    }

    renderBudgetSection() {
        const { amendment_dealss } = this.state;
        const budgetDeal = amendment_dealss.find((deal: any) => deal.amendment_type === 'budget');
        if (!budgetDeal) {
            return null;
        }
        const { status } = budgetDeal;
        const buttonStyle = this.getButtonStyle(status);
        return (
            <>
                <Grid container alignItems="center">
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={StylesNegotiation.budget}>Price <span style={StylesNegotiation.price}>£ {this.state.datas.budget}</span></Typography>
                        <Box> {(this.state.buttonClickeds || this.state.negotiateButtons) ? (
                            <CustomButton
                                label={this.getButtonLabels(status)} style={buttonStyle}

                            />)
                            :
                            (<>{status
                                === "accept" &&
                                (
                                    <img style={{ width: "32px", height: "32px" }} src={image_Green} alt="Accepted" />)
                            }{status
                                === "deny" && (<img style={{ width: "32px", height: "32px" }} src={image_Gray} alt="Negotiated" />)}
                            </>
                            )}  </Box>
                    </div> </Grid>
                {this.renderAmendmentCriteria(status)}
                {this.renderTextAreas(this.state)}
            </>

        );
    }
    getButtonLabel(status: any) {
        return status === 'accept' ? "Accepted" : 'Negotiate';
    }
    renderCommissionSection() {
        const { amendment_dealss } = this.state;
        const commissionDeal = amendment_dealss.find((deal: any) => deal.amendment_type === 'commission');
        if (!commissionDeal) {
            return null;
        }
        const { status } = commissionDeal;

        const commissionDeals = amendment_dealss.filter((deal: any) => deal.amendment_type === 'commission');
        const latestStatus = commissionDeals.reduce((acc: any, deal: any) => {
            if (deal.status === 'accept') {
                acc.accept = true;
            } else if (deal.status === 'negotiate') {
                acc.negotiate = true;
            }
            return acc;
        }, { accept: false, negotiate: false })

        return (
            <>
                <Grid container alignItems="center">
                    <div style={{ marginTop: "10px", width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={StylesNegotiation.budget}>Reward <span style={StylesNegotiation.price}>£ {this.state.datas.commission}</span></Typography>
                        <Box>

                            <>
                                {latestStatus.accept && (
                                    <img style={{ width: "32px", height: "32px" }} src={image_Green} alt="Accepted" />
                                )}

                                {status === "negotiate" && !latestStatus.accept && (
                                    <img style={{ width: "32px", height: "32px" }} src={image_Gray} alt="Negotiated" />
                                )}
                            </>

                        </Box>

                    </div>
                </Grid>

                <div>
                    {this.renderAmendmentCriteriaCommission(commissionDeal.status, latestStatus.accept)}
                </div>

                {amendment_dealss?.map((criteria: any) => (
                    <React.Fragment key={criteria.id}>
                        {this.state.showTextAreaCommissions === criteria.id && (
                            <>
                                <Typography style={StylesNegotiation.counterOffer}>Counter-offer</Typography>
                                <textarea
                                    placeholder="Type here..."
                                    style={{
                                        ...StylesNegotiation.textAreaStyle,
                                        backgroundColor: this.state.commissionReasons ? '#E2E8F0' : '',
                                        color: this.state.commissionReasons ? '#676767' : '',
                                        border: this.state.commissionReasons ? 'none' : '1px solid #E5E7EB',
                                        borderRadius: this.state.commissionReasons ? '8px' : '',
                                        resize: 'none',
                                        marginTop: '5px',
                                        padding: '5px',
                                        width: '100%',
                                        height: '100px',
                                    }}
                                    value={this.state.showTextAreaCommissions === criteria.id ? this.state.commissionReasons : ''}
                                    onChange={(e) => this.handleCommissionReasons(e)}
                                     onPaste={(e) => 
                                        {
                                         e.preventDefault();
                                     }
                                    }
                                    data-testid="handleCommissionReasons"
                                />
                            </>
                        )}
                    </React.Fragment>
                ))}
            </>
        )
    }


    renderCriteriaOrTermsSection() {
        const { criteria_amendmentss, criteria_or_termss } = this.state;
        return (
            <>
                <Typography style={this.state.datas.listing_type === "looking_for_something" ? StylesNegotiation.secondHeading as React.CSSProperties : StylesNegotiation.secondHeading}>
                    {this.state.datas.listing_type === "selling_for_something" ? "Terms of Purchase" : "Approval Criteria"}
                </Typography>
                <Typography style={StylesNegotiation.secondSecionText as React.CSSProperties}>Kindly specify if the product or service you want aligns with the following criteria.</Typography>
                <div>
                    {Array.isArray(criteria_or_termss) && criteria_or_termss?.map((term: any, index: number) => (
                        <div key={term.id}>
                            <div style={{ padding: '20px 0px', paddingBottom: "0px", width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography style={StylesNegotiation.dataTitile as React.CSSProperties}>
                                    <span style={{ marginRight: "15px" }}>{index + 1}.</span>{term.title}
                                </Typography>
                                {criteria_amendmentss
                                    .filter((item: any) => item.criteria_id === term.id && item.status)
                                    .slice(-1)
                                    .map((item: any) => (
                                        <div key={item.id}>
                                            {this.renderButton(item.status)}
                                        </div>
                                    ))
                                }
                            </div>

                            {criteria_amendmentss
                                ?.filter((item: any) => item.criteria_id === term.id && item.reason)
                                .slice(0, -1)
                                .map((item: any, index: number) => (
                                    <div key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                            style={{
                                                ...StylesNegotiation.textAreaDisables112,
                                                ...(index % 2 === 0 ? StylesNegotiation.evenBackgroundColor : StylesNegotiation.oddBackgroundColor)
                                            } as React.CSSProperties}
                                        >
                                            <span style={StylesNegotiation.spanTextss as React.CSSProperties}>{item.reason}</span>
                                        </div>
                                    </div>
                                ))}


                            {criteria_amendmentss
                                .filter((item: any) => item.criteria_id === term.id && item.reason)
                                .slice(-1)
                                .map((item: any) => (
                                    <div key={item.id} style={{}}>
                                        {item.status !== "accept" && (
                                            <div style={StylesNegotiation.textAreaDisables as React.CSSProperties}>
                                                <p style={StylesNegotiation.spanTextsss as React.CSSProperties}>{item.reason}</p>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: "12px" }}>
                                                    <CustomButton
                                                        label={this.state.finalApprovalForm[index]?.status === "accept" ? "Accepted" : "Accept"}
                                                        style={this.state.finalApprovalForm[index]?.status === "accept" ? StylesNegotiation.acceptButtonOnclick : StylesNegotiation.acceptButton}
                                                        onClick={() => {
                                                            this.handleAcceptClickfunctions(item.id, item.criteria_id, index, item.reason);
                                                        }}

                                                        data-testid="popupApprovalss"
                                                    />
                                                    <CustomButton
                                                        label={this.state.finalApprovalForm[index]?.status === "deny" ? "Amended" : "Amend"}
                                                        style={this.state.finalApprovalForm[index]?.status === "deny" ? StylesNegotiation.negotiateButtonCommissionOnClick : StylesNegotiation.negotiateButtonCommission}
                                                        onClick={() => {
                                                            this.handleNegotiateClickfunctions(item.id, item.criteria_id, index);
                                                        }}


                                                        data-testid="popupApprovalss1"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.finalApprovalForm?.[index]?.status === "deny" && (
                                            <div style={{ marginTop: "5px", width: "100%" }}>
                                                <Typography style={StylesNegotiation.counterOffers}>{this.state.datas.listing_type === "looking_for_something" ? "Counter-criterion": "Counter-term"}</Typography>
                                                <textarea
                                                    style={{
                                                        ...StylesNegotiation.textAreaStyle,
                                                        backgroundColor: '#E2E8F0',
                                                        color: '#676767',
                                                        border: '1px solid #E5E7EB',
                                                        borderRadius: '8px',
                                                        resize: 'none',
                                                        width: "100%",
                                                        height: "92px",
                                                    }}
                                                    placeholder="Type here"
                                                    value={this.state.finalApprovalForm?.[index]?.reason ?? ""}
                                                    onChange={(e) => this.handleTextAreaChangess(e, index, item.criteria_id)}
                                                    data-testid="handleTextAreaData"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            <hr style={StylesNegotiation.termHr} />
                        </div>
                    ))}
                </div>
            </>
        );
    }

    renderStylePreference(status: string) {
        return status === 'accept' ? StylesNegotiation.acceptButtonOnclick : StylesNegotiation.negotiateButtonOnClick;
    }

    renderPreferenceSection() {
        const { amendment_dealss } = this.state;
        const preferences = amendment_dealss.find((deal: any) => deal.amendment_type === 'preferences');
        if (!preferences) {
            return null;
        }
        const { status } = preferences;
        const buttonStyle = this.renderStylePreference(status);

        const preferencesDeals = amendment_dealss.filter((deal: any) => deal.amendment_type === 'preferences');
        const latestStatuspreferences = preferencesDeals.reduce((acc: any, deal: any) => {
            if (deal.status === 'accept') {
                acc.accept = true;
            } else if (deal.status === 'deny') {
                acc.deny = true;
            }
            return acc;
        }, { accept: false, deny: false });

        return (
            <>
                <Box style={StylesNegotiation.commonPaddings as React.CSSProperties}>
                    <Typography style={StylesNegotiation.secondHeading}>Preference</Typography>
                    <div style={{ width: '100%', padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={StylesNegotiation.secondSecionText as React.CSSProperties}>{this.state.datas.preferences}</Typography>
                        <Box>
                            {latestStatuspreferences.accept && (
                                <>
                                    <img style={{ width: "32px", height: "32px" }} src={image_Green} alt="Accepted" />
                                </>
                            )}

                            {status === "deny" && !latestStatuspreferences.accept && (
                                <img style={{ width: "32px", height: "32px" }} src={image_Gray} alt="Deny" />
                            )}
                        </Box>
                    </div>

                </Box>

                <div>
                    {this.renderAmendmentCriteriaCommissionPreference(preferences.status, latestStatuspreferences.accept)}
                </div>
                {amendment_dealss?.map((criteria: any) => (
                    <React.Fragment key={criteria.id}>
                        {this.state.showTextAreaPrefrences === criteria.id && (
                            <>
                                <Typography style={StylesNegotiation.counterOffer}>Counter-offer</Typography>
                                <textarea
                                    placeholder="Type here..."
                                    style={{
                                        ...StylesNegotiation.textAreaStyle,
                                        backgroundColor: this.state.preferenceReasons ? '#E2E8F0' : '',
                                        color: this.state.preferenceReasons ? '#676767' : '',
                                        border: this.state.preferenceReasons ? 'none' : '1px solid #E5E7EB',
                                        borderRadius: this.state.preferenceReasons ? '8px' : '',
                                    } as React.CSSProperties}
                                    value={this.state.preferenceReasons}
                                    onChange={(e) => this.handlepreferenceReasons(e)}
                                    data-testid="handlepreferenceReasons"
                                />
                            </>
                        )}
                    </React.Fragment>
                ))}
            </>
        );

    }

    getTermLabel = () => {
        return this.state.prefrenceButtonCommissions ? "Accepted" : "Accept";
    }

  renderAmendmentCriteriaCommissionPreference(status: any, latestAcceptedss: boolean) {
    const { amendment_dealss } = this.state;
    const commissionAmendmentsPreference = amendment_dealss.filter(
        (deal: any) => deal.amendment_type === 'preferences'
    );

    let latestCriteriaId = "";
    if (commissionAmendmentsPreference.length > 0) {
        const sortedCommissionAmendments = commissionAmendmentsPreference.sort((a: any, b: any) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        latestCriteriaId = sortedCommissionAmendments[0].id;
    }

    const commissionDealsFiltered = commissionAmendmentsPreference.filter((deal: any) => deal.status !== 'accept');
    const reversedCommissionDeals = commissionDealsFiltered.reverse();

    return reversedCommissionDeals.map((criteria: any, index: number) => {
        if (criteria.amendment_type === "preferences" && status === 'deny') {
            const isOdd = index % 2 === 1;
            const textAreaStyle = isOdd 
                ? StylesNegotiation.textAreaDisableOdd1
                : StylesNegotiation.textAreaDisableEven1;

            return (
                <div key={criteria.id}>
                    <div style={textAreaStyle as React.CSSProperties}>
                        <span style={StylesNegotiation.spanTextssss as React.CSSProperties}>{criteria.reason}</span>
                        {criteria.id === latestCriteriaId && !latestAcceptedss && (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: "12px" }}>
                                <CustomButton
                                    label={this.getTermLabel()}
                                    style={this.state.prefrenceButtonCommissions ? StylesNegotiation.acceptButtonOnclick : StylesNegotiation.acceptButton}
                                    onClick={() => this.handlePrefrenceClickCommissions(criteria.id, criteria.reason)}
                                    data-testid="handlePrefrenceClickCommission"
                                />
                                <CustomButton
                                    label="Amend"
                                    style={this.state.prefrenceDenyButtonCommissions ? StylesNegotiation.negotiateButtonCommissionOnClick : StylesNegotiation.negotiateButtonCommission}
                                    onClick={() => this.handlePrefrenceDenyClickCommissions(criteria.id)}
                                    data-testid="handlePrefrenceDenyClickCommission"
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    });
}

    getBudgetLabel = () => {
        return this.state.buttonClickeds ? "Accepted" : "Accept";
    }

    getBudgetStyle = () => {
        return this.state.buttonClickeds ? StylesNegotiation.acceptButtonOnclick : StylesNegotiation.acceptButton;
    }

    renderAmendmentCriteriaBudget(status: string, latestAccepted: boolean) {
        const budgetRecord = this.state.amendment_dealss.filter(
            (deal: { amendment_type: string; }) => deal.amendment_type === 'budget'
        );
        let latestBudgetReason = "";
        if (budgetRecord.length > 0) {
            budgetRecord.sort((a: { created_at: string | number | Date; }, b: { created_at: string | number | Date; }) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            latestBudgetReason = budgetRecord[0]?.id;
        }
        const budgetDealsFiltered = budgetRecord.filter((deal: { status: string; }) => deal.status !== 'accept');
        const reversedBudgetDeals = budgetDealsFiltered.reverse();

        return reversedBudgetDeals.map((criteria: { amendment_type: string; id: string | number; reason: string }, index: number) => {
            if (criteria.amendment_type === "budget" && status === 'negotiate') {
                const isOdd = index % 2 !== 0;
                const textAreaStyle = isOdd 
                    ? StylesNegotiation.textAreaDisableOdd 
                    : StylesNegotiation.textAreaDisableEven;
                return (
                    <div key={criteria.id}>
                        <div style={textAreaStyle as React.CSSProperties}>
                            <span style={StylesNegotiation.spanText as React.CSSProperties}>{criteria.reason}</span>
                            <Box alignSelf='end' paddingRight="24px">
                                {criteria.id === latestBudgetReason && !latestAccepted && (
                                    <Box display='flex' alignItems='center' style={{ gap: "12px" }}>
                                        <CustomButton
                                            label={this.getBudgetLabel()}
                                            style={this.getBudgetStyle()}
                                            onClick={() => this.handleAcceptClickBudget(criteria.reason)}
                                            data-test-id="handleAcceptClickBudget"
                                        />
                                        <CustomButton
                                            label={this.state.negotiateButtons ? "Amended" : "Amend"}
                                            style={this.state.negotiateButtons ? StylesNegotiation.negotiateButtonCommissionOnClick : StylesNegotiation.negotiateButtonCommission}
                                            onClick={() => this.handleNegotiateClickBudget(criteria.id)}
                                            data-test-id="handleNegotiateClickBudget"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </div>
                    </div>
                );
            }
        }).filter(Boolean);
    }

    getCommissionLabel = () => {
        return this.state.buttonClickedCommissions ? "Accepted" : "Accept";
    }

    getCommissionStyle = () => {
        return this.state.buttonClickedCommissions ? StylesNegotiation.acceptButtonOnclick : StylesNegotiation.acceptButton;
    }

    renderAmendmentCriteriaCommission(status: any, latestAccepted: boolean) {
        const { amendment_dealss } = this.state;
        const commissionAmendments = amendment_dealss.filter(
            (deal: any) => deal.amendment_type === 'commission'
        );
        let latestCommissionReason = "";
        if (commissionAmendments.length > 0) {
            commissionAmendments.sort((a: any, b: any) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            const latestCommissionAmendment = commissionAmendments[0];
            latestCommissionReason = latestCommissionAmendment.id;
        }
        const commissionDealsFiltered = commissionAmendments.filter((deal: any) => deal.status !== 'accept');
        const reversedCommissionDeals = commissionDealsFiltered.reverse();
    
        return reversedCommissionDeals.map((criteria: any, index: number) => {
            if (criteria.amendment_type === "commission" && status === 'negotiate') {
                const isOdd = index % 2 !== 0;
                const textAreaStyle = isOdd 
                    ? StylesNegotiation.textAreaDisableOdd 
                    : StylesNegotiation.textAreaDisableEven;
    
                return (
                    <div key={criteria.id}>
                        <div style={textAreaStyle as React.CSSProperties}>
                            <span style={StylesNegotiation.spanText as React.CSSProperties}>{criteria.reason}</span>
                            <div style={{ alignSelf: 'end', paddingRight: "24px" }}>
    
                                {criteria.id === latestCommissionReason && !latestAccepted && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: "12px" }}>
                                        <CustomButton
                                            label={this.getCommissionLabel()} 
                                            style={this.getCommissionStyle()}
                                            onClick={() => this.handleAcceptClickCommissions(criteria.id, criteria.reason)}
                                            data-testid="handleAcceptClickCommission"
                                        ></CustomButton>
                                        <CustomButton
                                            label={this.state.negotiateButtonCommissions ? "Amended": "Amend"}
                                            style={this.state.negotiateButtonCommissions ? StylesNegotiation.negotiateButtonCommissionOnClick : StylesNegotiation.negotiateButtonCommission}
                                            onClick={() => this.handleNegotiateClickCommissions(criteria.id)}
                                            data-testid="handleNegotiateClickCommission"
                                        ></CustomButton>
                                    </div>
                                )}
    
                            </div>
                        </div>
                    </div>
                );
            }
        }).filter(Boolean);
    }
    
    renderButton(item: any) {
        let buttonLabel, buttonStyle, imageSource, imageAlt;
        switch (item) {
            case 'accept':
                buttonLabel = '';
                buttonStyle = {};
                imageSource = image_Green;
                break;
            case 'deny':
                buttonLabel = 'Denied';
                buttonStyle = StylesNegotiation.denyButtonOnClickss;
                imageSource = image_Gray;
                break;
            case 'amendment':
                buttonLabel = 'Amendment Requested';
                buttonStyle = StylesNegotiation.requestButtonOnClick;
                imageSource = image_Gray;
                break;
            default:
                buttonLabel = '';
                buttonStyle = {};
                imageSource = '';
                break;
        }
        const latestStatus = item === 'accept' || item === 'deny' || item === 'amendment';
        return (
            <div>
                {latestStatus && (
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <img style={{ width: "32px", height: "32px" }} src={imageSource} alt={imageAlt} />
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }


    isButtonActive1() {
        return (
            this.state.buttonClickedCommissions || this.state.negotiateButtonCommissions
            && this.state.buttonClickeds || this.state.negotiateButtons
            && this.state.prefrenceButtonCommissions || this.state.prefrenceDenyButtonCommissions
            && this.state.ApprovalCriteriaButton || this.state.ApprovalCriteriaDenyButton
        )
    }
    isButtonActive() {
        return (
            this.state.buttonClickedCommissions || this.state.negotiateButtonCommissions
            || this.state.buttonClickeds || this.state.negotiateButtons
            || this.state.prefrenceButtonCommissions || this.state.prefrenceDenyButtonCommissions
            || this.state.ApprovalCriteriaButton || this.state.ApprovalCriteriaDenyButton || this.state.showTextAreaCommissions || this.state.showTextAreaPrefrences || this.state.showTextAreas || this.state.finalApprovalForm)
    }

    isBudgetDataValid = () => {
        let isBudgetDataValid = true;
        let budgetDeal = this.state.amendment_dealss.filter((deal: { amendment_type: string; }) => deal.amendment_type === "budget");
        if (budgetDeal.length > 0) {
            if (budgetDeal[budgetDeal.length - 1].status !== "accept") {
                isBudgetDataValid = this.state.buttonClickeds || (this.state.negotiateButtons && !!this.state.budgetReasons);
            }
        }
        return isBudgetDataValid;
    }

    isCommissionDataValid = () => {
        let isCommissionDataValid = true;
        let commissionDeal = this.state.amendment_dealss.filter((deal: { amendment_type: string; }) => deal.amendment_type === "commission");
        if (commissionDeal.length > 0) {
            if (commissionDeal[commissionDeal.length - 1].status !== "accept") {
                isCommissionDataValid = this.state.buttonClickedCommissions || (this.state.negotiateButtonCommissions && !!this.state.commissionReasons);
            }
        }
        return isCommissionDataValid;
    }

    isPreferenceDataValid = () => {
        let isPreferenceDataValid = true;
        let preferenceDeal = this.state.amendment_dealss.filter((deal: { amendment_type: string; }) => deal.amendment_type === "preferences");
        if (preferenceDeal.length > 0) {
            if (preferenceDeal[preferenceDeal.length - 1].status !== "accept") {
                isPreferenceDataValid = this.state.prefrenceButtonCommissions || (this.state.prefrenceDenyButtonCommissions && !!this.state.preferenceReasons);
            }
        }
        return isPreferenceDataValid;
    }

    isTermAndConditionDataValid = () => {
        let count = 0;
        let termValidCount = 0;
        Array.isArray(this.state.criteria_or_termss) && this.state.criteria_or_termss.map((term) => {
            this.state.criteria_amendmentss
                .filter((item: { criteria_id: number | string | undefined; reason: string | null; }) => item.criteria_id === term.id && item.reason)
                .slice(-1)
                .map((item: { status: string | null; }) => {
                    if (item.status !== "accept") {
                        count++;
                    }
                });
        });
        this.state.finalApprovalForm.forEach((term: { status: string | undefined | null; reason: string | null | undefined; }) => {
            if (term && term.status && (term.status === "accept" || (term.status !== "accept" && term.reason))) {
                termValidCount += 1;
            }
        });
        return termValidCount === count;
    }

    isButtonEnabled = () => {
        const isBudgetDataValid = this.isBudgetDataValid();
        const isCommissionDataValid = this.isCommissionDataValid();
        const isTermAndConditionDataValid = this.isTermAndConditionDataValid();
        const isPreferenceDataValid = this.isPreferenceDataValid();
        let isAllDataValid = isBudgetDataValid && isCommissionDataValid && isTermAndConditionDataValid && isPreferenceDataValid;
        return isAllDataValid;
    }

    renderButtons() {
        const budgetDealBugd = this.state.amendment_dealss.filter((dealb: any) => dealb.amendment_type === 'budget');
        const latestStatusBugd = budgetDealBugd.reduce((acc: any, dealb: any) => {
            if (dealb.status === 'accept') {
                acc.accept = true;
            } else if (dealb.status === 'negotiate') {
                acc.negotiate = true;
            }
            return acc;
        }, { accept: false, negotiate: false })

        const commissionDealsComm = this.state.amendment_dealss.filter((dealc: any) => dealc.amendment_type === 'commission');
        const latestStatusComm = commissionDealsComm.reduce((acc: any, dealc: any) => {
            if (dealc.status === 'accept') {
                acc.accept = true;
            } else if (dealc.status === 'negotiate') {
                acc.negotiate = true;
            }
            return acc;
        }, { accept: false, negotiate: false })

        const preferencesDealsPref = this.state.amendment_dealss.filter((dealp: any) => dealp.amendment_type === 'preferences');
        const latestStatuspreferencesPref = preferencesDealsPref.reduce((acc: any, dealp: any) => {
            if (dealp.status === 'accept') {
                acc.accept = true;
            } else if (dealp.status === 'deny') {
                acc.deny = true;
            }
            return acc;
        }, { accept: false, deny: false });

        let count = 0;
        Array.isArray(this.state.criteria_or_termss) && this.state.criteria_or_termss?.map((term: any) => {
            this.state.criteria_amendmentss
                .filter((item: any) => item.criteria_id === term.id && item.reason)
                .slice(-1)
                .map((item: any) => {
                    if (item.status !== "accept") {
                        count++;
                    }
                });
        })
        const filteredPayloadTerms = Array.isArray(this.state.finalApprovalForm)
            ? this.state.finalApprovalForm.filter((item: any) =>
                item && typeof item === 'object' &&
                item.criteria_id !== null &&
                item.status.trim() !== ""
            )
            : [];
        const isButtonEnabled = () => {
            if ((filteredPayloadTerms.length) === count) {
                return filteredPayloadTerms.every(
                    (item) =>
                        (item.status === "deny" && item.reason?.trim() !== "") ||
                        item.status === "accept"
                )
            } else {
                return false
            }
        };

        const isButtonEnableds = () => {
            if ((filteredPayloadTerms.length) === count) {
                return filteredPayloadTerms.every(
                    (item) =>
                        item.status === "accept"
                )
            } else {
                return false
            }
        };
        let isTrue = (latestStatusBugd.accept || this.state.buttonClickeds) &&
        (latestStatusComm.accept || this.state.buttonClickedCommissions);

        if (this.state.datas.preferences) {
            isTrue = isTrue && (latestStatuspreferencesPref.accept || this.state.prefrenceButtonCommissions);
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0 80px 0' }}>
                {this.state.datas.status === "sold" ?
                <Box marginRight='10px'>
                 <Button
                    disabled={true}
                    variant="contained"
                    style={StylesNegotiation.createButtonsDiasable}
                >
                    <span style={{ textTransform: 'none' }}>Already sold</span>
                </Button>
                </Box>
                :<div style={{ marginRight: '10px' }}>
                    { isTrue && isButtonEnableds()
                            ?
                            <Button
                                test-id="popupApproval1"
                                variant="contained"
                                style={
                                    this.isButtonActive()
                                        ? StylesNegotiation.createButtons
                                        : StylesNegotiation.createButtonsDiasable
                                }
                                onClick={() => { this.updateApiForFormApproval("finalise_deal"); }}
                                data-testid="createApiForNegotiate11"
                            >
                                {
                                    this.state.statusPayment === "finalise_deal" ? (
                                        <span style={{ textTransform: 'none' }}>Deal Finalised</span>
                                    ) : (
                                        <span style={{ textTransform: 'none' }}>Finalise Deal</span>
                                    )
                                }

                            </Button> :
                            <Button
                                test-id="popupApproval"
                                variant="contained"
                                disabled={!this.isButtonEnabled()}
                                style={
                                    this.isButtonEnabled()
                                        ? StylesNegotiation.createButtons
                                        : StylesNegotiation.createButtonsDiasable
                                }
                                onClick={() => { this.updateApiForFormApproval("counter_offer") }}
                                data-testid="createApiForNegotiate"
                            >
                                {
                                    <span style={{ textTransform: 'none' }}>Send to Review</span>
                                }
                            </Button>
                    }

                </div>}

                <div>
                    <Button
                        variant="contained" style={StylesNegotiation.createButtons2}
                        onClick={() => { this.openPopups() }}
                        data-testid="openPopup"
                    >
                        <span style={{ textTransform: 'none' }}>Close Negotiation</span>
                    </Button>
                </div>
            </div>
        );
    }



    renderPopups() {
        return (
            <>
                <div style={StylesNegotiation.actionsStyles as React.CSSProperties}>
                    <Popup
                        open={this.state.showPopupsLogoutNegotiations}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.NegotiationOnClickss}
                        data-testid="NegotiationOnClicks"
                        className="popup"
                        contentStyle={StylesNegotiation.contentsNegotiations}
                        overlayStyle={StylesNegotiation.overlaysNegotiation}
                    >
                        <div style={StylesNegotiation.deletePopuplogNegotiation as React.CSSProperties}>
                            <p style={StylesNegotiation.popuptextslogNegotiation as React.CSSProperties}>Close Negotiation</p>
                            <div>
                                <p style={StylesNegotiation.popuptextssslogNegotiation as React.CSSProperties}>Are you sure you want to close this negotiation? </p>
                            </div>
                            <Box>
                                <Button style={StylesNegotiation.plateformExploreBtnssNegotiation as React.CSSProperties}
                                    onClick={() => this.FromApprovalDataCancelButtons()}
                                    data-testid="NegotiationOnClick"
                                >Close</Button>
                                <Button style={StylesNegotiation.plateformListingBtnsNegotiation as React.CSSProperties}
                                    onClick={this.NegotiationOnClickCancles}
                                    data-testid="NegotiationOnClickCancle"
                                >Cancel</Button>
                            </Box>
                        </div>
                    </Popup>
                </div>
                <div style={StylesNegotiation.actionsStyles as React.CSSProperties}>
                    <Popup
                        open={this.state.popupNegotiates}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.NegotiationOnClicksPopups}
                        data-testid="NegotiationOnClicksPopup"
                        className="popup"
                        contentStyle={StylesNegotiation.contentsNegotiation}
                        overlayStyle={StylesNegotiation.overlaysNegotiation}
                    >
                        <Box style={StylesNegotiation.poupMainNegotiation}>
                            <Box display="flex" justifyContent="right">
                                <CloseIcon
                                    data-test-id="close-btn"
                                    onClick={this.NegotiationOnClicksPopups}
                                    style={StylesNegotiation.close as React.CSSProperties}
                                />
                            </Box>
                            <Box style={StylesNegotiation.leftBoxsNegotiation as React.CSSProperties}>
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        src={popImages}
                                        style={{ width: '232px', height: '232px', }}
                                    />
                                </div>
                            </Box>
                            <Typography style={StylesNegotiation.uploadTextsPopupNegotiation as React.CSSProperties}> Sent to Review Successfully!</Typography>
                            <Typography data-testid="setting1" style={{ textAlign: 'center', cursor: "pointer" }}>
                                <span style={StylesNegotiation.gotoNegotiation as React.CSSProperties}>@{this.handleShowName()} is currently reviewing your negotiations</span>
                            </Typography>
                        </Box>
                    </Popup>
                </div>
            </>
        );
    }
    render() {

        const theme = createTheme();

        return (
            <ThemeProvider theme={theme}>
                <AppHeader navigation={this.props.navigation.navigate} />
                <Box style={StylesNegotiation.containerMain}>
                    {this.state.LoaderFormApproval ?
                        (<div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                            }}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{ color: '#4fa94d' }} />
                            </Box>
                        </div>) :
                        <Box style={StylesNegotiation.childContainer}>
                            {this.renderHeaders()}

                            <hr style={StylesNegotiation.hr as React.CSSProperties} />
                            <Box style={StylesNegotiation.commonPadding}>
                                {this.renderBudgetSectionn()}
                                {this.renderCommissionSection()}
                                <hr style={StylesNegotiation.hr as React.CSSProperties} />
                                {this.renderCriteriaOrTermsSection()}
                                {this.renderPreferenceSection()}
                            </Box>
                            {this.renderButtons()}
                            {this.renderPopups()}
                        </Box>
                    }
                </Box>
                <AppFooter naviagtion={this.props.navigation.navigate} />
            </ThemeProvider>
        );
    }



}
// Customizable Area End

// Customizable Area Start
const StylesNegotiation = {
    close: {
        color:"gray",
        cursor: "pointer"
    },
    termHr: {
        width: "100%",
        height: "1px",
        backgroundColor: "#E5E7EB",
        border: "none",
        margin: "25px 0px",
        marginBottom: "0px"
    },
    reason: {
        backgroundColor: '#E2E8F0',
        borderRadius: '8px',
        color: '#676767',
        border: 'none',
        resize: 'none',
        marginTop: '5px',
        padding: '5px',
        width: '100%',
        height: '100px',
        fontFamily: "Segoe UI",
        fontSize: "20px",
    },
    noReason: {
        border: '1px solid #E5E7EB',
        borderRadius: '8px',
        marginTop: '5px',
        padding: '5px',
        resize: 'none',
        width: '100%',
        height: '100px',
        fontFamily: "Segoe UI",
        fontSize: "20px",
    },
    containerMain: { maxWidth: "1440px", width: "100%", margin: '0 auto' },
    childContainer: {
        padding: "20px 65px",
    },
    commonPadding: {
        padding: '20px 0',
        paddingTop: "0px"
    },
    commonPaddings: {
        padding: '0px',
        paddingTop: "25px"
    },
    heading: {
        fontSize: '32px',
        fontFamily: "Segoe UI Bold",
        color: '#676767',
    },
    userName: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
    },
    hr: {
        width: "100%",
        height: "1px",
        backgroundColor: " #E5E7EB",
        border: "none",
        margin: "25px 0",
    },
    budget: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
    },
    price: {
        fontSize: '32px',
        fontFamily: "Segoe UI Bold",
        color: '#3CAA49',
        margin: 0
    },
    acceptButton: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
        background: 'white'
    },
    Preference: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    PreferenceDeny: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    PreferenceOnClick: {
        width: '105px',
        height: '44px',
        border: '1px solid #3CAA49',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#3CAA49',
        textTransform: 'none',
    },
    PreferenceDenyOnClick: {
        width: '105px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    acceptButtonOnclick: {
        width: '105px',
        height: '44px',
        border: '1px solid #3CAA49',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#3CAA49',
        textTransform: 'none',
        background: 'white'
    },
    negotiateButton: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
        background: 'white'
    },
    negotiateButtonOnClick: {
        width: '105px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
        background: 'white'
    },
    requestButton: {
        width: '228px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    textAreaStyle: {
        fontFamily: "Segoe UI",
        fontSize: "20px",
        resize: 'none',
        marginTop: "20px",
        padding: "10px",
        width: "100%",
        height: "92px",
        color: "black",
        border: "1px solid #E5E7EB",
        borderRadius: '8px'
    },
    negotiateButtonCommission: {
        width: '121px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
        background: 'white'
    },
    negotiateButtonCommissionOnClick: {
        width: '121px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    secondHeading: {
        fontSize: '24px',
        fontFamily: "Segoe UI Bold",
        color: '#676767',
    },
    secondSecionText: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
        margin: 0,
        textAlign: "justify",
        maxWidth: "90%"
    },
    dynamicData: {
        justifyContent: 'end'
    },
    details: {
        padding: '20px 10px',
        alignItems: 'center'
    },
    denyButtonOnClickss: {
        width: '105px',
        height: '44px',
        border: '1px solid #FE4023',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#FE4023',
        textTransform: 'none',
    },
    counterOffer: {
        marginTop: '10px',
        fontSize: '16px',
        fontFamily: "Segoe UI Bold",
        color: '#676767',
    },
    counterOffers: {
        marginTop: '10px',
        fontSize: '16px',
        fontFamily: "Segoe UI Bold",
        color: '#676767',
    },
    requestButtonOnClick: {
        width: '228px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    dataTitile: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
        width: "100%",
        maxWidth: "90%",
        display: "flex",
        textAlign: "justify",
    },
    createButtons: {
        backgroundColor: '#3CAA49',
        color: '#FFFFFF',
        width: '180px',
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        borderRedius: '5px'
    },
    createButtonsDiasable: {
        backgroundColor: 'rgba(60, 170, 73, 0.3)',
        color: '#FFFFFF',
        width: '180px',
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        borderRedius: '5px'
    },
    createButtons2: {
        color: '#3CAA49',
        width: '199px',
        background: "#FFFFFF",
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        border: '1px solid #3CAA49',
        borderRedius: '5px'
    },
    actionsStyles: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center',
    },
    popuptextsNegotiation: {
        fontSize: '26px',
        textAlign: "center",
        fontFamily: "Segoe UI Bold",
        color: '#0F172A',
        lineHeight: '28px'
    },
    overlaysNegotiation: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    contentsNegotiation: {
        backgroundColor: '#fff',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        maxWidth: '80%',
        padding: '20px',
        width: '540px',
        borderRadius: '8px',
        minHeight: '350px'
    },
    contentsNegotiations: {
        backgroundColor: '#fff',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        maxWidth: '80%',
        padding: '20px',
        width: '526px',
        borderRadius: '8px',
        height: '322px'
    },
    deletePopuplogNegotiation: {
        marginTop: "85px",
        paddingLeft: '50px',
    },
    popuptextslogNegotiation: {
        fontSize: '26px',
        fontFamily: "Segoe UI Bold",
        color: '#0F172A',
        lineHeight: '28px'
    },
    popuptextssslogNegotiation: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#181818',
        LineHeight: '24px',
    },
    plateformExploreBtnssNegotiation: {
        height: "56px",
        width: "170px",
        borderRedius: "4px",
        background: "#3CAA49",
        color: "#ECF7ED",
        fontFamily: "Segoe UI Bold",
        fontSize: "20px",
        border: "none",
        marginRight: "15px",
        textTransform: "capitalize",
    },
    plateformListingBtnsNegotiation: {
        height: "60px",
        width: "150px",
        borderRedius: "4px",
        background: "#FFFFFFE5",
        color: "#3CAA49",
        fontFamily: "Segoe UI Bold",
        fontSize: "18px",
        textTransform: "capitalize",
        border: "1px solid #3CAA49",
    },
    poupMainNegotiation: {
        padding: "10px 20px 20px 20px"
    },
    leftBoxsNegotiation: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    uploadTextsPopupNegotiation: {
        fontSize: '30px',
        fontFamily: "Segoe UI Bold",
        color: '#181818',
        textAlign: 'center',
        paddingTop: "20px"
    },
    gotoNegotiation: {
        fontSize: '18px',
        fontFamily: "Segoe UI",
        color: '#676767',
        textAlign: 'center',
        paddingTop: "20px"
    },
    textAreaDisable: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "16px 0px",
        background: '#ECF7ED',
        borderRadius: '8px',
        marginTop: '20px',
        alignItem: 'center',
        width: "100%",
    },
    textAreaDisableOdd: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "16px 0px",
        background: '#E2E8F0',
        borderRadius: '8px',
        marginTop: '20px',
        alignItem: 'center',
        width: "100%",
    },
    textAreaDisableEven: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "16px 0px",
        background: '#ECF7ED',
        borderRadius: '8px',
        marginTop: '20px',
        alignItem: 'center',
        width: "100%",
    },
    textAreaDisableEven1: {
        display: 'flex',
        background: '#ECF7ED',
        justifyContent: 'space-between',
        padding: "16px 24px 16px 24px",
        borderRadius: '8px',
        marginTop: '20px',
        alignItem: 'center',
        flexDirection: "column",
    },
    textAreaDisableOdd1: {
        display: 'flex',
        background: '#E2E8F0',
        justifyContent: 'space-between',
        padding: "16px 24px 16px 24px",
        borderRadius: '8px',
        marginTop: '20px',
        alignItem: 'center',
        flexDirection: "column",
    },
    textAreaDisable12: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "16px 24px 16px 24px",
        background: '#ECF7ED',
        borderRadius: '8px',
        marginTop: '20px',
        alignItem: 'center',
        flexDirection: "column",
    },
    textAreaDisable2: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "16px 0px 16px 0px",
        background: '#ECF7ED',
        borderRadius: '8px',
        marginTop: '20px'
    },
    textAreaDisables: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "16px 0px 16px 0px",
        background: '#ECF7ED',
        borderRadius: '8px',
        marginTop: '20px',
        flexDirection: "column",
        paddingLeft: "24px",
        paddingRight: "24px",
        alignItems: "end",
    },
    textAreaDisables112: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "16px 0px 16px 0px",
        background: '#ECF7ED',
        borderRadius: '8px',
        marginTop: '20px',
        width: '100%',
    },
    spanTextssss: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#676767',
        margin: 0,
        textAlign: "justify",
    },
    evenBackgroundColor: {
        backgroundColor: '#ECF7ED',
    },
    oddBackgroundColor: {
        backgroundColor: '#E2E8F0',
    },
    spanText: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#676767',
        margin: 0,
        padding: "0px 24px",
    },
    spanTextss: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#676767',
        margin: 0,
        padding: "0px 24px",
        textAlign: "justify",
    },
    spanTextsss: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#676767',
        margin: 0,
        width: "100%",
        textAlign: "justify",
    },
    spanText2: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#676767',
    },
    spanText11: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#676767',
    },
    checkText: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "14px",
        lineHeight: "24px"
    },
}
// Customizable Area End