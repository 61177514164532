import React from "react";

import {
  Box,
  CircularProgress,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import Switch from "react-switch";

// Customizable Area End

import SubscribeNotificationController from "./SubscribeNotificationController";

export default class SubscribeNotification extends SubscribeNotificationController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.mainWrapper}>
      {this.state.loading ?
        <Box style={webStyle.loader}>
          <CircularProgress style={webStyle.green} />
        </Box>
        :<>
        <Box>
          <Typography style={webStyle.headerStyle}>
            Get Email Notifications when:
          </Typography>
        </Box>

        <Box>
          <Box
            style={{
              display: "flex",
              paddingBottom: "32px",
              justifyContent: "space-between",
            }}
          >
            <Typography style={webStyle.bodyStyle}>
              I receive a new message in notification
            </Typography>
            <Switch
              onChange={this.handleChangeSwitch1}
              checked={this.state.switchChecked1}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#3caa49"
              width={50}
              data-test-id={"switch1"}
            />
          </Box>
          <hr style={webStyle.hr} />
          <Box
            style={{
              display: "flex",
              padding: "32px 0px 32px 0px",
              justifyContent: "space-between",
            }}
          >
            <Typography style={webStyle.bodyStyle}>
              Changes have been made to an ongoing deal
            </Typography>
            <Switch
              onChange={this.handleChangeSwitch2}
              checked={this.state.switchChecked2}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#3caa49"
              width={50}
              data-test-id={"switch2"}
            />
          </Box>
          <hr style={webStyle.hr} />
          <Box
            style={{
              display: "flex",
              paddingTop: "32px",
              justifyContent: "space-between",
            }}
          >
            <Typography style={webStyle.bodyStyle}>
              Someone has left a review
            </Typography>
            <Switch
              onChange={this.handleChangeSwitch3}
              checked={this.state.switchChecked3}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#3caa49"
              width={50}
              data-test-id={"switch3"}
            />
          </Box>
        </Box>
        </>
      }
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  loader: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  green: {
    color: '#4fa94d'
  },
  mainWrapper: {
    background: "rgb(247, 245, 245)",
    height: "calc(100% - 168px)",
    padding: "40px 72px 76px 40px",
  },
  headerStyle: {
    fontFamily: "Segoe UI Bold",
    color: "rgba(103 103 103)",
    fontWeight: 700,
    fontSize: "24px",
    marginBottom: "32px",
  },
  bodyStyle: {
    fontFamily: "Segoe UI",
    color: "rgba(103, 103, 103, 1)",
    fontWeight: 400,
    fontSize: "24px",
  },
  hr: {
    border: "none",
    height: "0.8px",
    margin: "0px",
    width: "96%",
    backgroundColor: "rgba(229, 231, 235, 1)",
  },
};
// Customizable Area End
