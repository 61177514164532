import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area End
    // Customizable Area Start
}

export interface S {
    // Customizable Area Start
    otpAuthToken: string;
    datamAIN: any[]
    titleData: any;
    email: string;
    listing_typeData: any;
    budgetData: any;
    commissionData: any;
    categoryData: any;
    sub_categoryData: any;
    locationData: any;
    descriptionData: any;
    account_idData: string;
    idsLocalData: any;
    userDetailsData: any;
    photoData: any[];
    someListingData: any[];
    UserPhoto: string,
    UserName: string;
    fullName: string;
    city: string;
    country: string;
    LoaderFinalProduct: boolean,
    statusSeller: any;
    SellerPhoto: any;
    SellerName: any;
    SellerUserName: any;
    SellerCity: any;
    SellerLocation: any;
    BuyerPhoto: any;
    BuyerName: any;
    BuyerUserName: any;
    BuyerCity: any;
    BuyerLocation: any;
    AccountSeller: any;
    AccountBuyer: any;
    LocalData : any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class FinalProductsDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiViewListingItemCallIdsss: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            otpAuthToken: "",
            datamAIN: [],
            titleData: "",
            listing_typeData: "",
            budgetData: "",
            commissionData: "",
            categoryData: "",
            sub_categoryData: "",
            account_idData: "",
            idsLocalData: null,
            locationData: "",
            descriptionData: "",
            email: "",
            photoData: [],
            someListingData: [],
            userDetailsData: {},
            UserPhoto: '',
            UserName: '',
            fullName: '',
            city: '',
            LoaderFinalProduct: false,
            country: '',
            statusSeller: {},
            SellerPhoto: "",
            SellerName: null,
            SellerUserName: null,
            SellerCity: null,
            SellerLocation: null,
            BuyerPhoto: null,
            BuyerName: null,
            BuyerUserName: null,
            BuyerCity: null,
            BuyerLocation: null,
            AccountSeller: null,
            AccountBuyer: null,
            LocalData : ""
            // Customizable Area End
        };

        // Customizable Area Start

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.apiViewListingItemCallIdsss) {
                if(responseJson.errors && responseJson.errors[0] && responseJson.errors[0].deal.includes("Record with id")){
                    toast.success("This deal is cancelled!");
                    this.props.navigation.navigate("LandingPage");
                    return;
                }
                if (responseJson && !responseJson.errors) {
                    this.setState({ LoaderFinalProduct: false })
                    this.setState({
                        city: responseJson.data.attributes.listing_detail.data.attributes.user_details.data.attributes.city,
                        country: responseJson.data.attributes.listing_detail.data.attributes.user_details.data.attributes.country,
                        UserName: responseJson.data.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name,
                        fullName: responseJson.data.attributes.listing_detail.data.attributes.user_details.data.attributes.full_name,
                        email: responseJson.data.attributes.listing_detail.data.attributes.user_details.data.attributes.email,
                        titleData: responseJson.data.attributes.listing_detail.data.attributes.title,
                        listing_typeData: responseJson.data.attributes.listing_detail.data.attributes.listing_type,
                        budgetData: responseJson.data.attributes.listing_detail.data.attributes.budget,
                        commissionData: responseJson.data.attributes.listing_detail.data.attributes.commission,
                        categoryData: responseJson.data.attributes.listing_detail.data.attributes.category,
                        sub_categoryData: responseJson.data.attributes.listing_detail.data.attributes.sub_category,
                        locationData: responseJson.data.attributes.listing_detail.data.attributes.location,
                        descriptionData: responseJson.data.attributes.listing_detail.data.attributes.description,
                        photoData: responseJson.data.attributes.listing_detail.data.attributes.photo,
                        UserPhoto: responseJson.data.attributes.listing_detail.data.attributes.user_details.data.attributes.photo,
                        statusSeller: responseJson.data.attributes.status,
                    });
                     this.setState({
                            SellerPhoto: responseJson.data.attributes.user_detail.seller.data.attributes.photo,
                            SellerName: responseJson.data.attributes.user_detail.seller.data.attributes.full_name,
                            SellerUserName: responseJson.data.attributes.user_detail.seller.data.attributes.user_name,
                            SellerCity: responseJson.data.attributes.user_detail.seller.data.attributes.city,
                            SellerLocation: responseJson.data.attributes.user_detail.seller.data.attributes.country,
                            AccountSeller: responseJson.data.attributes.user_detail.seller.data.attributes.account_id, 
                        })
                      this.setState({
                            BuyerPhoto: responseJson.data.attributes.user_detail.buyer.data.attributes.photo,
                            BuyerName: responseJson.data.attributes.user_detail.buyer.data.attributes.full_name,
                            BuyerUserName: responseJson.data.attributes.user_detail.buyer.data.attributes.user_name,
                            BuyerCity: responseJson.data.attributes.user_detail.buyer.data.attributes.city,
                            BuyerLocation: responseJson.data.attributes.user_detail.buyer.data.attributes.country,
                            AccountBuyer: responseJson.data.attributes.user_detail.buyer.data.attributes.account_id
                        });
                    
                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const contentss = this.props.navigation.getParam('id');
        this.viewDataListings(contentss)
        window.scrollTo(0, 0);
        const userNameFromLocalStorage = localStorage.getItem("fullName");
        this.setState({LocalData: userNameFromLocalStorage})
    }
    handleClickRefresh = () => {
        const dataShow = this.props.navigation.getParam('id');
        this.props.navigation.navigate(`FromApprovalSeller`, { id: dataShow })
    }
    userDetailsIds = (contentId: any) => {
        this.props.navigation.navigate(`OtherUserDetails`, { id: contentId })
    }
    handleRefreshClick = () => {
        window.location.reload();
    }
    
    viewDataListings = async (id: any) => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiViewListingItemCallIdsss = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/initiate_deals/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        this.setState({ LoaderFinalProduct: true })
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
    // Customizable Area End
}
