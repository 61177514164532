import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export type someListingSave= {
    "id": number;
    "type": string;
    "attributes": {
      listing_type: string;
      title: string;
      budget: string;
      commission: string;
      photo: string[];
    }
  }
  import FilterAllCategoryController from "./FilterAllCategoryController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  searchText: string;
  lookingForSomethingChecked: boolean;
  sellingSomethingChecked: boolean;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  };
  bugdetMin: any;
  // budgetMax: any;
  isAllCategory:boolean;
  subCategoryApiDatasAll: string[];
  isCheckedList: string[];
  title: string;
  categortText: any;
  budgetMin: number;
  budgetMax: number;
  budgetMinc: number;
  budgetMaxc: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showBudgetData:boolean,
  showCommissionData:boolean,
  showLookingSomeData:boolean, 
  showSellingSomeData:boolean,
  keyCount: boolean,
  isTypeChanges: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
 someListingSave: someListingSave[],
  savedData: object,
  savedPopup: boolean,
  showAllCheckbox: boolean,
  showAntiquesCheckbox: boolean,
  savedListings: someListingSave[],
  savedListingsDatas: string[],
  removeSavedLists: boolean,
  successRemoveSavings: boolean,
  LoaderSavedSelling: boolean,
  deleteItemIdss: number,
  deleteItemss: object,
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SavedListingSellingController extends  FilterAllCategoryController {
  // Customizable Area Start
  apiLookingSomeItemCallIdLists: string = "";
  apiSave_ListingCallIdSelling: string = "";
  apiSaveListingItemsCallId: string = "";
  apideleteSavingsItemCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
     someListingSave: [],
      savedData: {},
      savedPopup: false,
      showAllCheckbox: false,
      showAntiquesCheckbox: false,
      savedListings: [],
      savedListingsDatas: [],
      removeSavedLists: false,
      successRemoveSavings: false,
      LoaderSavedSelling: true,
      deleteItemIdss: 0,
      deleteItemss: {},
    } as any;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiLookingSomeItemCallIdLists) {
        this.setState({LoaderSavedSelling: false})
        this.handleLookingAPiResponseLooking(responseJson);
      }
      else if (apiRequestCallId === this.apiSave_ListingCallIdSelling) {
        this.handleSaveListingResponseList(responseJson);
      }
      else if (apiRequestCallId === this.apiSaveListingItemsCallId) {
        this.handleSaveApiResponseId(responseJson);
      }
      else if (apiRequestCallId === this.apideleteSavingsItemCallId) {
        this.handleRemoveSavingApiResponsess(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getJobsDatasSavingsSelling(this.props.sellingSomethingChecked)
    this.saveListingApiCallSaved()
  }
  saveClosePopups = () =>{
    this.setState({savedPopup: false})
  }
  handleSaveListingResponseList(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        savedData: responseJson.message,
        savedPopup: true
      })
      this.saveListingApiCallSaved()
    }
  }
  handleSaveApiResponseId(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      const titles = responseJson?.data?.map((item: any) => item.attributes.listing.data.id);
      this.setState({
        savedListings: responseJson.data,
        savedListingsDatas: titles,
      });
    }
  }
  datas(item:any){
    this.state.savedListingsDatas?.includes(item.id) ? this.popupSavedsDone(item.id) : this.savedlistApiCallIdSelling(item.id)
  }
  handleRemoveSavingApiResponsess(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        deleteItemss: responseJson.message,
        successRemoveSavings: true,
      });
      this.saveListingApiCallSaved()
    }
  }
  handleLookingAPiResponseLooking(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        someListingSave: responseJson.data?.filter((item: { attributes?: { listing_type?: string, category?: string[], sub_category?: string[] , budget?: string[], commission?: string[]} }) =>
            item.attributes?.listing_type !== "looking_for_something"
        )
    });
    }
}
  viewDataOnHomess(id:number){
    this.props.navigation.navigate('ViewListing' ,{ id: id })
  }
  handleSaveListingHome = () => {
    this.props.navigation.navigate("LandingPage")
  }
  handleSaveListingses = () => {
    this.props.navigation.navigate("UserProfileBasicBlock", "?tab=savedlist")
  }
  navigateSavedSomethingsRemove = () => {
    this.setState({removeSavedLists: false})
  }
  popupSavedsDone = (id:number) => {
    this.setState({removeSavedLists: true, deleteItemIdss: id,})
  }
  successpopupSaveded = () => {
    this.setState({successRemoveSavings: false})
  }
  closePopupPassworded = () => {
    this.setState({successRemoveSavings: false});
  };  
  savelistSelling = () => {
    localStorage.setItem("LookingForSomething", "FilterAllCategory");
    this.props.navigation.navigate("EmailAccountLoginBlock")
}
  componentDidUpdate(prevProps: any) {
    if (prevProps.subCategoryApiDatasAll !== this.props.subCategoryApiDatasAll) {
      this.getJobsDatasSavingsSelling(this.props.subCategoryApiDatasAll, this.props.categortText)
    }
    if (prevProps.budgetMin !== this.props.budgetMin || prevProps.budgetMax !== this.props.budgetMax) {
      this.setState({
        budgetMin: this.props.budgetMin,
        budgetMax: this.props.budgetMax,
      });
      this.getJobsDatasSavingsSelling(
        this.props.budgetMin,
        this.props.budgetMax
      );
    }
    if (prevProps.budgetMinc !== this.props.budgetMinc || prevProps.budgetMaxc !== this.props.budgetMaxc) {
      this.setState({
        budgetMinc: this.props.budgetMinc,
        budgetMaxc: this.props.budgetMaxc,
      });
      this.getJobsDatasSavingsSelling(
        this.props.budgetMinc,
        this.props.budgetMaxc
      );
    }
  }
  getJobsDatasSavingsSelling  = (search?: boolean, categories?: any, subCategories?: string[][], budget?: number, commission?: number) => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiLookingSomeItemCallIdLists = requestMessage.messageId;
  
    const searchsl = search ? "selling_for_something" : "";
    let urls = `bx_block_locallistingads/local_listing_ads`;
    if (searchsl) {
        urls += `?listing_type=${searchsl}`;
    };
    
    let categorys=Object.keys(this.props.subCategoryApiDatasAll)[0];

    if (categories) {
      urls += `&category=${categories[0]}`;
    }

    if (categorys !== undefined ) {
      const subCategoryApiDatass = this.props?.subCategoryApiDatasAll[categories?.[0]];
      const startIndexs = subCategoryApiDatass?.[0].isChecked ? 1 : 0;
      const relevantSubcategories1 = subCategoryApiDatass?.slice(startIndexs);
  
      relevantSubcategories1?.forEach((subcategory: any) => {
          if (subcategory.isChecked) {
              urls += `&sub_categories[]=${subcategory.id}`;
          }
      });
  }
   
  if (this.props.budgetMin) {
    urls += `&min_budget=${this.props.budgetMin}`;
  }

  if (this.props.budgetMax) {
    urls += `&max_budget=${this.props.budgetMax}`;
  }
  if (this.props.budgetMinc) {
    urls += `&min_commission=${this.props.budgetMinc}`;
  }

  if (this.props.budgetMaxc) {
    urls += `&max_commission=${this.props.budgetMaxc}`;
  }
  
      
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        urls,
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
};
  saveListingApiCallSaved = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSaveListingItemsCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ "Content-Type":  "application/json",
      token: token})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  savedlistApiCallIdSelling = async (id:number) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSave_ListingCallIdSelling = requestMessage.messageId;
    let Body = {
        "data":{
          "local_listing_ad_id": id
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteSavelistingCalleds = async (id:number) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apideleteSavingsItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/bx_block_locallistingads/wishlist_items/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
      token: token})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
