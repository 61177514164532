// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ContentFlag from "../../blocks/contentflag/src/ContentFlag";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlockWeb from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import FilterAllCategory from "../../blocks/landingpage/src/FilterAllCategory";
import AllListings from "../../blocks/landingpage/src/AllListings";
import SavedListingLooking from "../../blocks/landingpage/src/SavedListingLooking";
import SavedListingSelling from "../../blocks/landingpage/src/SavedListingSelling";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import VerifyAccount from "../../blocks/landingpage/src/VerifyAccount.web";
import LocalListingAds from "../../blocks/locallistingads/src/LocalListingAds";
import CreateListing from "../../blocks/locallistingads/src/CreateListing";
import SellingSomething from "../../blocks/locallistingads/src/SellingSomething";
import LookingSomething from "../../blocks/locallistingads/src/LookingSomething.web";
import ViewListing from "../../blocks/locallistingads/src/ViewListing.web"
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Interactivefaqs from "../../blocks/interactivefaqs/src/interactivefaqs.web";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import MyListing from "../../blocks/user-profile-basic/src/MyListing.web";
import SavedListing from "../../blocks/user-profile-basic/src/SavedListing.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContactuNewsWeb from "../../blocks/contactus/src/AddContactusNew.web"
import LandingPageMain from "../../blocks/landingpage/src/LandingPageMain";
import RefundPolicy from "../../blocks/refundmanagement/src/RefundPolicy.web"
import NegotiationSellerToBuyer from "../../blocks/locallistingads/src/NegotiationSellerToBuyer.web"
import NegotiationUserData from "../../blocks/user-profile-basic/src/NegotiationUserData"
import FromApprovalSeller from "../../blocks/user-profile-basic/src/FromApprovalSeller.web"
import OtherUserDetails from "../../blocks/user-profile-basic/src/OtherUserDetails"
import OtherListing from "../../blocks/user-profile-basic/src/OtherListing"
import FinalProductsDetails from "../../blocks/user-profile-basic/src/FinalProductsDetails"
import MyReviewsData from "../../blocks/reviews/src/MyReviewsData.web"
import PaymentSeller from '../../blocks/paypalintegration/src/PaymentSeller.web';
import PaymentBuyer from '../../blocks/paypalintegration/src/PaymentBuyer.web';
import RefundManagement from "../../blocks/refundmanagement/src/RefundManagement.web"
import PaymentSuccess from '../../blocks/paypalintegration/src/PaymentSuccess.web';
import OrderDetails from '../../blocks/user-profile-basic/src/OrderDetails.web'
import PaypalDetails from '../../blocks/settings2/src/PaypalDetails.web'
import InvoiceBilling from '../../blocks/invoicebilling/src/InvoiceBilling.web'
import PaypalAccount from '../../blocks/settings2/src/PaypalAccount.web';

const routeMap = {
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch",
authRequired: true
},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2",
authRequired: true},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlockWeb,
path:"/UserProfileBasicBlock",
authRequired: true
},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
AddContactus:{
  component:ContactuNewsWeb,
 path:"/AddContactusNew"},
Settings2:{
 component:Settings2,
path:"/Settings2",
authRequired: true},
AllListings:{
  component:AllListings,
 path:"/AllListings",
 authRequired: true},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview",
authRequired: true},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock",
authRequired: true},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword",
authRequired: false},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword",
authRequired: false},
Chat:{
 component:Chat,
path:"/Chat",
authRequired: true},
ViewChat:{
 component:ViewChat,
path:"/ViewChat",
authRequired: true},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/HomePage",
authRequired: true},
RefundPolicy:{
  component:RefundPolicy,
 path:"/RefundPolicy"},
 RefundManagement:{
  component:RefundManagement,
 path:"/RefundManagement",
 authRequired: true},
 NegotiationSellerToBuyer:{
  component:NegotiationSellerToBuyer,
 path:"/NegotiationSellerToBuyer/:id",
 authRequired: true},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration",
authRequired: false},
SavedListingLooking:{
  component:SavedListingLooking,
 path:"/SavedListingLooking",
 authRequired: true},
 SavedListingSelling:{
  component:SavedListingSelling,
 path:"/SavedListingSelling",
 authRequired: true},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories",
authRequired: true},
MyListing:{
  component: MyListing,
 path:"/UserProfileBasicBlock/MyListing",
 authRequired: true}, 
 SavedListing:{
  component: SavedListing,
 path:"/UserProfileBasicBlock/SavedListing",
 authRequired: true}, 
 LocalListingAds:{
  component:LocalListingAds,
 path:"/LocalListingAds"
},
CreateListing:{
  component:CreateListing,
 path:"/CreateListing",
 authRequired: true
},
SellingSomething:{
  component:SellingSomething,
 path:"/SellingSomething",
 authRequired: true
},
SellingSomethings:{
  component:SellingSomething,
 path:"/SellingSomethings/:id",
 authRequired: true
},
LookingSomething: {
  component: LookingSomething,
  path: "/LookingSomething",
  authRequired: true
},
PhotoLibrary:{
  component:PhotoLibrary,
 path:"/PhotoLibrary"},
FilterAllCategory: {
  component: FilterAllCategory,
  path: "/FilterAllCategory/:id?",
  authRequired: false
},
LookingSomethings: {
  component: LookingSomething,
  path: "/LookingSomethings/:id",
  authRequired: true
},
NegotiationUserData: {
  component: NegotiationUserData,
  path: "/NegotiationUserData/:id",
  authRequired: true
},
FromApprovalSeller: {
  component: FromApprovalSeller,
  path: "/FromApprovalSeller/:id",
  authRequired: true
},
MyReviewsData: {
  component: MyReviewsData,
  path: "/MyReviewsData",
  authRequired: true
},

OrderDetails: {
  component:OrderDetails ,
  path: "/OrderDetails/:id",
  authRequired: true
},
FinalProductsDetails: {
  component: FinalProductsDetails,
  path: "/FinalProductsDetails/:id",
  authRequired: true
},
OtherUserDetails: {
  component: OtherUserDetails,
  path: "/OtherUserDetails/:id",
  authRequired: true
},
OtherListing: {
  component: OtherListing,
  path: "/OtherListing",
  authRequired: true
},
ViewListing: {
  component: ViewListing,
  path: "/ViewListing/:id",
  authRequired: false
},

InvoiceBilling: {
  component: InvoiceBilling,
  path: "/InvoiceBilling/:id",
  authRequired: true
},

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  LandingPageMain: {
    component: LandingPageMain,
    path: "/",
    exact: true,
    authRequired: false
  },
  PaymentSeller: {
    component: PaymentSeller,
    path: '/PaymentSeller/:id',
    authRequired: true
  },
  PaymentBuyer: {
    component: PaymentBuyer,
    path: '/PaymentBuyer/:id',
    authRequired: true
  },

  PaymentSuccess: {
    component: PaymentSuccess,
    path: '/PaymentSuccess/:id',
    authRequired: true
  },
  PaypalDetails:{
    component:PaypalDetails,
   path:"/PaypalDetails",
   authRequired: true},
   PaypalAccount: {
    component: PaypalAccount,
    path: "/success",
    authRequired: true
  },
  VerifyAccount: {
    component: VerifyAccount,
    path: "/verify",
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  scrollToTop=() =>{
    window.scrollTo(
      0,0
    )
  }
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
      {/* <TopNav /> */}
      <ToastContainer style={{ zIndex: 9999999 }} />
      {this.scrollToTop()}
      {WebRoutesGenerator({ routeMap })}
      <ModalContainer />
    </View>
    );
  }
}

export default App;