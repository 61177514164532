import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area End
    // Customizable Area Start
}

export interface S {
    // Customizable Area Start
    otpAuthTokenS: string;
    useDetailsDataSS: any;
    countrysS: any;
    citysS: any;
    setInitiateDatasS: string[];
    listingTypesS: string;
    setBuyerSellerS: any;
    setBuyerImgS: any;
    setSellerImgS: any;
    setTitleS: string;
    setBudgetS: string;
    setCommissionS: string;
    tabSelectValueOther: number;
    LoaderOtherUser: boolean,
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class OtherUserDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    otherUserDetails_data_Profiles: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            otpAuthTokenS: "",
            useDetailsDataSS: null,
            countrysS: '',
            citysS: '',
            setInitiateDatasS: [],
            listingTypesS: '',
            setBuyerSellerS: {},
            setBuyerImgS: '',
            setSellerImgS: '',
            setTitleS: '',
            setCommissionS: '',
            setBudgetS: '',
            tabSelectValueOther: 0,
            LoaderOtherUser: false,
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJsonS = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallIdS = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallIdS === this.otherUserDetails_data_Profiles) {
                if (responseJsonS && !responseJsonS.errors) {
                    this.setState({ LoaderOtherUser: false })
                    this.setState({
                        useDetailsDataSS: responseJsonS.data,

                    });
                }
            }

        }

        // Customizable Area End
    }

    // Customizable Area Start

    componentDidMount(): any {
        this.otherUserDetailsProfile()
    }
    otherUserDetailsProfile = async () => {
        const contentss = this.props.navigation.getParam('id');
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.otherUserDetails_data_Profiles = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_profile/profiles/${contentss}/other_user_profile`
        );

        this.setState({ LoaderOtherUser: true })
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };
    handleTabSelectOther = (value: number) => {
        this.setState({
            tabSelectValueOther: value
        })
    }

    // Customizable Area End
}
