// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  InputBase,
  Avatar,
  Grid,
  CircularProgress,
  List,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { imageSend } from "./assets";
import ViewMyChatController, { Props } from "./ViewMyChatController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const styles = () => ({
  alignLeftCss: {
    border: "2px solid green",
    width: "fit-content",
    textAlign: "left",
    backgroundColor: "red",
  },
  messageReceived: {
    padding: "10px",
    flex: 1,
  },
  messageSend: {
    display: "flex",
    justifyContent: "flex-end",
    border: "3px solid green",
    padding: "10px",
  },
  alignRightCss: { textAlign: "right", border: "2px solid red" },
  sendMessageLink: { color: "#fff" },
  receivedMsgLink: { color: "#000000c2" },
  cancelIcon: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  mediaLoader: {
    display: "flex",
    height: "17vh",
    justifyContent: "center",
    width: "auto",
    alignItems: "center",
  },
});

class ViewMyChat extends ViewMyChatController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
    theme: PropTypes.Validator<object>;
  };
  constructor(props: Props) {
    super(props);
  }

  makeLinksClickable = (messageText: any) => {
    return [messageText];
  };

  handleMessageSendLink(message: any) {
    return <div>{this.makeLinksClickable(message.body)} </div>;
  }
  getSentMessage = (
    message: any,
    classes: any,
    index: number,
    classesOuter: any,
    userNameFromLocalStorage: any
  ) => (
    <>
      <Box
        style={{
          padding: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          variant="body1"
          style={{
            maxWidth: "60%",
            height: "auto",
            position: "relative",
            background: "rgba(236, 247, 237, 1)",
            color: "rgba(103, 103, 103, 1)",
            borderRadius: "10px 0 10px 10px",
            fontSize: "18px",
            fontFamily:"Segoe UI",
            padding: "16px",
            gap: "10px",
            minWidth:"12%"
          }}
        >
          {this.handleMessageSendLink(message)}

          <Typography
            variant="body2"
            style={{
              position: "absolute",
              bottom: "5px",
              right: "5px",
              color: "gray",
              fontFamily:"Segoe UI",
              fontSize: "10px",
            }}
          >
            {new Date(message.timestamp.toString()).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </Typography>
        </Typography>
        <Avatar
          src={
           this.renderSentUserImage()
          }
          style={{ width: "25px", height: "25px", marginLeft: "10px" }}
        />
      </Box>
    </>
  );

  renderMsgs(msgsData: any, classes: any, classesOuter: any) {
    const userNameFromLocalStorage = localStorage.getItem("fullName");
    
    return (
      <>
        {msgsData.map((message: any, index: number) => {
          let myMsgs = false;
          if (
            message.customAttributes &&
            message.customAttributes.senderId == this.state.userId
          ) {
            myMsgs = true;
          }

          return (
            <>
              {myMsgs ? (
                this.getSentMessage(
                  message,
                  classes,
                  index,
                  classesOuter,
                  userNameFromLocalStorage
                )
              ) : (
                <>
                  <Grid container className={classesOuter.alignLeftCss}>
                    <>
                      <Grid item xs={12}>
                        <Box
                          style={{
                            padding: "10px",
                            marginBottom: "10px",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar
                            src={this.renderUserImage()}
                            style={{
                              width: "25px",
                              height: "25px",
                              marginRight: "10px",
                            }}
                          />
                          <Typography
                            variant="body1"
                            style={{
                              maxWidth: "60%",
                              height: "auto",
                              position: "relative",
                              background: "rgba(60, 170, 73, 0.9)",
                              color: "white",
                              borderRadius: "0 10px 10px 10px",
                              fontFamily:"Segoe UI",
                              fontSize: "18px",
                              padding: "16px",
                              gap: "10px",
                              wordWrap:"break-word",
                            
                            }}
                          >
                            {this.handleMessageSendLink(message)}
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "6px",
                                fontFamily:"Segoe UI",
                                right: "4px",
                                color: "white",
                              }}
                            >
                              {new Date(
                                message.timestamp.toString()
                              ).toLocaleString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  </Grid>
                </>
              )}
            </>
          );
        })}
      </>
    );
  }

  renderUserImage = () => {
    const userNameFromLocalStorage = localStorage.getItem("fullName");

    return userNameFromLocalStorage ==
      this.state.chatInformation?.attributes?.participants[0].name
      ? this.state.chatInformation?.attributes?.participants[1].image
      : this.state.chatInformation?.attributes?.participants[0].image;
  };
  renderSentUserImage = () => {
    const userNameFromLocalStorage = localStorage.getItem("fullName");

    return userNameFromLocalStorage ==
      this.state.chatInformation?.attributes?.participants[0].name
      ? this.state.chatInformation?.attributes?.participants[0].image
      : this.state.chatInformation?.attributes?.participants[1].image;
  };
  renderUserName = () => {
    const userNameFromLocalStorage = localStorage.getItem("fullName");

    return userNameFromLocalStorage ==
      this.state.chatInformation?.attributes?.participants[0].name
      ? this.state.chatInformation?.attributes?.participants[1].name
      : this.state.chatInformation?.attributes?.participants[0].name;
  };
  messaging() {
    return (
     
      <p style={{ color: "grey", textAlign: "center" }}>
        Get started by typing your message at the bottom.
    </p>
  
    )
  };

  MessageInput = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 20px 20px 20px",
            backgroundColor: "rgba(236, 247, 237, 1)",
            borderRadius: "2px",
          }}
        >
          <InputBase
            data-test-id="chatInput"
            value={this.state.messageText}
            onChange={this.handleInputChange}
            onKeyDown={this.keyUp}
            placeholder="Type here"
            fullWidth
            style={{
              flex: 1,
              paddingLeft: "20px",
              paddingRight: "40px",
              outline: "none",
              borderRadius: "4px",
              wordWrap:"break-word"
            }}
          />
          <IconButton
            aria-label="send"
            disableRipple
            onClick={this.sendMessage}
          >
            <img
              src={imageSend}
              alt="Send"
              style={{ width: "30px", height: "30px" }}
            />
          </IconButton>
        </div>
      </>
    );
  };
  Header = () => {
    return (
      <Box style={{ margin: "0 10px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "0.5px solid lightgray",
            position: "sticky",
            top: 0,
            zIndex: 2,
            padding: "5px 10px",
          }}
        >
          <Avatar
            src={this.renderUserImage()}
            style={{ width: "40px", height: "40px", marginRight: "10px" }}
          />
          <Typography
            variant="h6"
            gutterBottom
            style={{
              background: "white",
              justifyContent: "center",
              lineHeight: "38px",
              display: "flex",
              fontSize: "24px",
              fontFamily: "Segoe UI Bold",
              zIndex: 2,
              paddingLeft: "15px",
            }}
          >
            {this.renderUserName()}
          </Typography>
        </div>
      </Box>
    );
  };

  render() {
    const { classes, theme }: any = this.props;
  
    return (
      <ThemeProvider theme={theme}>
        <Paper
          elevation={1}
          style={{
            height: "88vh",
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            width: "100%",
            scrollbarColor: "rgba(60, 170, 73, 0.9) rgba(60, 170, 73, 0)",
            borderRadius:"0px",
          }}
        >
          {this.Header()}
          <div
            style={{
              padding: "10px",
              flex: 1,
              overflowY: "auto",
              height: "70%",
              scrollbarWidth:"none"
            }}
            ref={this.scrollViewRef}
          >
            {this.state.loading ? (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  display: "flex",
                }}
              >
                <CircularProgress style={{ color: "rgba(60, 170, 73, 0.9)" }} />
              </div>
            ) : (
              <List>
                {Object.keys(this.state.messages).length > 0 ? (
                  <>
                    {Object.keys(this.state.messages).map((dateKey) => (
                      <>
                        {this.renderMsgs(
                          this.state.messages[dateKey].data,
                          classes,
                          "classesOuter"
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                  {this.messaging()}
                  </>
                )}
              </List>
            )}
          </div>

          {this.MessageInput()}
          {this.state.warningMessage && (
            <div
              style={{
                borderRadius: "5px",
                margin: "-5px 20px 20px 20px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                fontSize: "12px",
                background: "#FE40231A",
                fontFamily: "Segoe UI",
                fontWeight: 400,
                padding: "10px",
                height: "26px",
                width: "450px",
                zIndex: 9999,
              }}
            >
              For the safety of our users, sensitive information such as{" "}
              <span style={{ color: "red" }}>Phone number</span>,{" "}
              <span style={{ color: "red" }}>Email ID</span>, and{" "}
              <span style={{ color: "red" }}>Bank details</span> cannot be
              shared through chat.
            </div>
          )}
        </Paper>
      </ThemeProvider>
    );
  }
}

ViewMyChat.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles as any, { withTheme: true })(
  ViewMyChat as any
);
export { ViewMyChat };

// Customizable Area End
