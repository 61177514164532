// Customizable Area End
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";


const criteria_amendments_attributes =
{
    criteria_id: "",
    status: "",
    reason: ""
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area End
    amendment_deals: {
        id: number;
        initiate_deal_id: number;
        amendment_type: string;
        status: string;
        reason: string | null;
        created_at: string;
        updated_at: string;
    }[];
    // Customizable Area Start
}


export interface S {
    // Customizable Area Start
    showTextAreas: boolean | string | number;
    buttonClickeds: boolean;
    negotiateButtons: boolean;
    showTextAreaCommissions: boolean;
    showTextAreaApproval: boolean;
    showTextAreaPrefrences: boolean;
    buttonClickedCommissions: any;
    negotiateButtonCommissions: any;
    prefrenceButtonCommissions: boolean;
    ApprovalCriteriaButton: boolean;
    ApprovalCriteriaDenyButton: boolean;
    prefrenceDenyButtonCommissions: boolean;
    selecteds: any,
    selectedDescriptions: any;
    criteria_or_termss: any
    datas: any;
    activeButtonss: any;
    showTextAreass: any;
    userDetailss: any;
    userDetailsIDs: any
    amendmentDealsAttributess: any;
    acceptOrDenys: string;
    negotiatedReasons: string;
    textAreaValues: string;
    commissionStatuss: string;
    commissionReasons: string;
    preferenceStatuss: string;
    preferenceReasons: string;
    accountIDss: string;
    showPopupsLogoutNegotiations: boolean;
    popupNegotiates: boolean;
    amendment_dealss: any;
    criteria_amendmentss: any;
    buttonStatess: any,
    buttonStatesAccept: any,
    budgetStatuss: string;
    budgetReasons: string;
    criteria_amendment: any[];
    criteriaIds: any;
    statusAmedent: string;
    ReasonsAmedent: any;
    InitaiateId: any;
    LoaderFormApproval: boolean;
    finalApprovalForm: any[];
    buttonDisabled: boolean,
    sellerCheck: any,
    buyerCheck: any,
    listingTypes: string,
    statusPayment: string,
    finalizeStatus: string;
    SellerNameLooking : {
        account_id: number | string | null;
        user_name: string;
    },
    BuyerNameSelling: {
        account_id: number | string | null;
        user_name: string;
    },

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class FormApprovalController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    FromApprovalDataApiData: string = "";
    ApprovalFormApiDataPost: string = "";
    FromApprovalDataCancel: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            showTextAreas: false,
            buttonClickeds: false,
            negotiateButtons: false,
            showTextAreaCommissions: false,
            showTextAreaApproval: false,
            showTextAreaPrefrences: false,
            buttonClickedCommissions: false,
            negotiateButtonCommissions: false,
            prefrenceButtonCommissions: false,
            prefrenceDenyButtonCommissions: false,
            ApprovalCriteriaButton: false,
            ApprovalCriteriaDenyButton: false,
            selecteds: null,
            selectedDescriptions: null,
            datas: "",
            userDetailsIDs: null,
            criteria_or_termss: [],
            activeButtonss: Array(9).fill(null),
            showTextAreass: Array(9).fill(false),
            userDetailss: null,
            amendmentDealsAttributess: [],
            acceptOrDenys: '',
            negotiatedReasons: "",
            textAreaValues: '',
            commissionStatuss: 'accept',
            commissionReasons: '',
            preferenceStatuss: 'accept',
            preferenceReasons: '',
            accountIDss: '',
            showPopupsLogoutNegotiations: false,
            popupNegotiates: false,
            amendment_dealss: [],
            criteria_amendmentss: [],
            buttonStatess: false,
            buttonStatesAccept: {},
            budgetStatuss: 'accept',
            budgetReasons: '',
            criteria_amendment: [],
            statusAmedent: "accept",
            ReasonsAmedent: {},
            InitaiateId: {},
            LoaderFormApproval: false,
            criteriaIds: null,
            finalApprovalForm: [criteria_amendments_attributes],
            buttonDisabled: false,
            sellerCheck: "",
            buyerCheck: "",
            listingTypes: "",
            statusPayment: "",
            finalizeStatus: "",
            SellerNameLooking : {
                account_id:  null,
                user_name: ""
            },
            BuyerNameSelling: {
                account_id:  null,
                user_name: ""
            },

            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.FromApprovalDataApiData) {
                this.handleNegotitiatonResponseData(responseJson);
            }
            if (apiRequestCallId === this.ApprovalFormApiDataPost) {
                if (responseJson && !responseJson.errors) {
                    this.setState({
                        popupNegotiates: true,
                        buttonDisabled: true
                    });
                }
            }

            if (apiRequestCallId === this.FromApprovalDataCancel) {
                this.handleNegotitiationPopup(responseJson)
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo(0, 0);
        const contentss = this.props.navigation.getParam('id');
        await this.FromApprovalDatas(contentss);
        const auth = localStorage.getItem("authToken");
        if (!auth) {
            localStorage.setItem('redirectUrl', window.location.pathname);
            this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
            const redirectUrl = localStorage.getItem("redirectUrl");
            if (redirectUrl) {
                localStorage.removeItem("redirectUrl");
                this.props.navigation.navigate(redirectUrl);
            }
        }
    }
    
   creatfunction = (sellerCheck: string | number, listingTypes: string | null) => {
        const contentId1 = this.props.navigation.getParam('id');
        const userNameFromLocalStorages = localStorage.getItem("id");
        const datasFile = userNameFromLocalStorages == sellerCheck;
    
        const navigateTo = listingTypes === "looking_for_something"
            ? (datasFile ? "PaymentBuyer" : "PaymentSeller")
            : (datasFile ? "PaymentSeller" : "PaymentBuyer");
        localStorage.setItem(`isCongrats-${contentId1}`, "true");
        this.props.navigation.navigate(navigateTo, { id: contentId1 });
    };
    
    handleAcceptClickfunctions = (id: any, criteria_idss: any, index: number, reason: string) => {
        const { buttonStatesAccept } = this.state;
        this.setState({
            buttonStatesAccept: {
                ...buttonStatesAccept,
                [id]: true
            },
            buttonStatess: false,
            ApprovalCriteriaButton: true,
            ApprovalCriteriaDenyButton: false,
            showTextAreaApproval: false,
            criteriaIds: criteria_idss
        });

        const updatedFormValues = [...this.state.finalApprovalForm];

        updatedFormValues[index] = {
            ...updatedFormValues[index],
            status: "accept",
            criteria_id: criteria_idss,
            reason: reason
        };
        this.setState({ finalApprovalForm: updatedFormValues })
    };

    handleNegotiateClickfunctions = (id: any, criteria_id: any, index: number) => {
        const { buttonStatess } = this.state;
        this.setState({
            buttonStatess: {
                ...buttonStatess,
                [id]: true
            },
            buttonStatesAccept: false,
            ApprovalCriteriaButton: false,
            ApprovalCriteriaDenyButton: true,
            criteriaIds: criteria_id,
            showTextAreaApproval: id,
        })

        const updatedFormValues = [...this.state.finalApprovalForm];

        updatedFormValues[index] = {
            ...updatedFormValues[index],
            status: "deny",
        };
        this.setState({ finalApprovalForm: updatedFormValues })
    };

    handleTextAreaChangess(e: any, index: number, criteriaId: number) {
        const { value } = e.target;
        const updatedFormValues = [...this.state.finalApprovalForm];

        updatedFormValues[index] = {
            ...updatedFormValues[index],
            reason: value ?? null,
            status: "deny",
            criteria_id: criteriaId
        };
        this.setState({ finalApprovalForm: updatedFormValues })
    }

    handleShowName = () => {
        const currentUserId = localStorage.getItem("id");
        return currentUserId?.toString() === this.state.BuyerNameSelling.account_id?.toString()
            ? this.state.SellerNameLooking.user_name
            : this.state.BuyerNameSelling.user_name;
    }

    handleNegotitiatonResponseData(responseJson: any) {
        if(responseJson.errors && responseJson.errors[0] && responseJson.errors[0].deal.includes("Record with id")){
            toast.success("This deal is cancelled!");
            this.props.navigation.navigate("LandingPage");
            return;
        }
        if (responseJson && !responseJson.errors && responseJson.data?.attributes) {
            if(responseJson.data.attributes.status === "payment_done"){
                this.props.navigation.navigate("LandingPage");
                return;
            } else if(responseJson.data.attributes.status === "finalise_deal") {
               const sellerCheck = responseJson.data.attributes.listing_detail.data.attributes.account_id;
               const listingTypes = responseJson.data.attributes.listing_detail.data.attributes.listing_type;
                this.creatfunction(sellerCheck, listingTypes);
                return;
            }
            this.setState({ LoaderFormApproval: false })
            const id = responseJson.data.attributes.listing_detail.data.id
            const InitaiateId = Number(id);
            this.setState({
                datas: responseJson.data.attributes.listing_detail.data.attributes,
                userDetailss: responseJson.data.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name,
                criteria_or_termss: responseJson.data.attributes.listing_detail.data.attributes.criteria_or_terms,
                amendment_dealss: responseJson.data.attributes.amendment_deals,
                criteria_amendmentss: responseJson.data.attributes.criteria_amendments,
                accountIDss: responseJson.data.attributes.listing_detail.data.attributes.account_id,
                InitaiateId: InitaiateId,
                sellerCheck: responseJson.data.attributes.listing_detail.data.attributes.account_id,
                listingTypes: responseJson.data.attributes.listing_detail.data.attributes.listing_type,
                statusPayment: responseJson.data.attributes.status,
                SellerNameLooking: responseJson.data.attributes.user_detail.seller.data.attributes,
                BuyerNameSelling: responseJson.data.attributes.user_detail.buyer.data.attributes,
            });
        }
    }
    handleNegotitiationPopup(responseJson: any) {
        if (responseJson && !responseJson.errors) {
            if (responseJson.message) {
                this.props.navigation.navigate("ViewListing", { id: this.state.InitaiateId })
            }
        }
    }

    handleAcceptClick = () => {
        const updatedAmendmentDealsAttributes = [
            { amendment_type: "budget", status: "accept", reason: "" },
            { amendment_type: "commission", status: "accept", reason: "" },
            { amendment_type: "preferences", status: "accept", reason: "" }
        ];
        this.setState({ buttonClickeds: true, negotiateButtons: false, showTextAreas: false, amendmentDealsAttributess: updatedAmendmentDealsAttributes, budgetStatuss: 'accept' });
    };

    handleNegotiateClick = () => {
        const updatedAmendmentDealsAttributes = [
            { amendment_type: "budget", status: "negotiate" }
        ];
        this.setState({ negotiateButtons: true, buttonClickeds: false, showTextAreas: true, amendmentDealsAttributess: updatedAmendmentDealsAttributes, budgetStatuss: 'negotiate' });
    };
    handleDeclineClick = () => {
        this.setState({
            showTextAreas: true,
        });
    };

    handleAcceptClickBudget = (reason: string) => {
        this.setState({
            showTextAreas: false,
            negotiateButtons: false,
            buttonClickeds: true,
            budgetReasons: reason,
            budgetStatuss: 'accept',
        });
    };

    handleNegotiateClickBudget = (id: string | number) => {
        this.setState({
            showTextAreas: id,
            negotiateButtons: true,
            buttonClickeds: false,
            budgetStatuss: 'negotiate'
        });
    };

    handleAcceptClickCommissions = (id: any, reason: any) => {
        this.setState({
            buttonClickedCommissions: true,
            negotiateButtonCommissions: false,
            showTextAreaCommissions: false,
            commissionStatuss: 'accept',
            commissionReasons: reason
        });
    };


    handleNegotiateClickCommissions = (id: any) => {
        this.setState({
            negotiateButtonCommissions: true,
            buttonClickedCommissions: false, showTextAreaCommissions: id, commissionStatuss: 'negotiate'
        });
    };
    handlePrefrenceClickCommissions = (id: any, reason: any) => {
        this.setState({
            prefrenceButtonCommissions: true, prefrenceDenyButtonCommissions: false, showTextAreaPrefrences: false, preferenceStatuss: 'accept',
            preferenceReasons: reason
        });
    };

    handlePrefrenceDenyClickCommissions = (id: any) => {
        this.setState({ prefrenceButtonCommissions: false, prefrenceDenyButtonCommissions: true, showTextAreaPrefrences: id, preferenceStatuss: 'deny' });
    };
    resetFunctions = () => {
        this.setState({
            buttonDisabled: false,
        })
        window.scrollTo(0, 0);
    }
    NegotiationOnClickss = () => {
        this.setState({ showPopupsLogoutNegotiations: false })
    }
    handleRefreshClickReload = () => {
        if(this.state.finalizeStatus === "counter_offer") {
            this.props.navigation.navigate("UserProfileBasicBlock", '?tab=negotiation');
        } else {
            window.location.reload();
        }
    }
    NegotiationOnClicksPopups = () => {
        this.setState({ popupNegotiates: false })
        this.handleRefreshClickReload()
    }
    openPopups = () => {
        this.setState({ showPopupsLogoutNegotiations: true })
    }
    NegotiationOnClickCancles = () => {
        this.setState({ showPopupsLogoutNegotiations: false })
    }
    FromApprovalDatas = async (id: any) => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FromApprovalDataApiData = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/initiate_deals/${id}`

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        this.setState({ LoaderFormApproval: true })
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
    FromApprovalDataCancelButtons = async () => {

        const token = localStorage.getItem("authToken") as string;
        const showCancelIds = this.props.navigation.getParam('id');
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FromApprovalDataCancel = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/initiate_deals/${showCancelIds}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };


    updateApiForFormApproval = async (finalise:any) => {
        this.setState({ finalizeStatus:  finalise });
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.ApprovalFormApiDataPost = requestMessage.messageId;
                const contentId = this.props.navigation.getParam('id');
        const showContent = this.props.navigation.getParam('itemId');
        const filteredPayloadTerms = Array.isArray(this.state.finalApprovalForm)
        ? this.state.finalApprovalForm.filter((item: any) =>
            item && typeof item === 'object' &&
            item.criteria_id !== null &&
            item.status.trim() !== "" &&
            item.reason.trim() !== ""
          )
        : [];
       
        const amendmentDealsAttributes = [
            {
                amendment_type: "budget",
                status: this.state.budgetStatuss,
                reason: this.state.budgetReasons
            },
            {
                amendment_type: "commission",
                status: this.state.commissionStatuss,
                reason: this.state.commissionReasons
            },
            {
                amendment_type: "preferences",
                status: this.state.preferenceStatuss,
                reason: this.state.preferenceReasons
            },
        ];
        const filteredPayload = amendmentDealsAttributes.filter(item => item.status !== "" && item.reason !== "");
                const isDealChanged = filteredPayloadTerms.length > 0 || filteredPayload.length > 0;
        let Body: { deal: {}, listing_type?: {} } = {
            deal: {
                status: finalise,
            },
        };
        if (isDealChanged) {
        Body = {
            deal: {
                status: finalise,
                local_listing_ad_id: showContent,
                criteria_amendments_attributes: filteredPayloadTerms,
                amendment_deals_attributes: filteredPayload,
            },
            listing_type: (this.state.datas.listing_type === "looking_for_something") ? 'looking' : 'selling',
            };
        }

        if (this.state.datas.listing_type === "looking_for_something") {
            //@ts-ignore
            Body.deal.buyer_id = this.state.datas.account_id;
        } else {
            //@ts-ignore
            Body.deal.seller_id = this.state.datas.account_id;
        }

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_locallistingads/initiate_deals/${contentId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify({
        "Content-Type": "application/json",
        token: token
        }));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(Body));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        this.resetFunctions()
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };


    // Customizable Area End
}