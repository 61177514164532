// Customizable Area Start
import React from "react";
import NegotiationController from "./NegotiationController";
import { Box, CircularProgress, Typography, styled, } from "@material-ui/core";
import { Look, Sell } from "../../locallistingads/src/assets";
import { profiles } from "./assets";
import { NegotiationEmpty } from "../../../components/src/NegotiationEmpty.web";
const ShoppingCardsNegotiate = styled(Box)({
    position: "relative",
    width: "185px",
    maxWidth: "185px",
    borderRadius: "5px",
    padding: "20px 20.5px",
    justifyContent: 'space-between',
    backgroundColor: "#FFFFFF",
    flexWrap: 'wrap',
    cursor: "pointer",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    "& .sold": {
        position:"absolute",
        top:"0px",
        right:"0px",
        color: "#fff",
        fontSize: "14px",
        borderTopRightRadius: "5px",
        borderBottomLeftRadius: "5px",
        padding: "2px 12px",
        paddingTop: "4px",
        backgroundColor: "#5cad5c",
    },
});
const CardImageNegotiate = styled("img")({
    width: "100%",
    height: "211px",
    objectFit: "contain",
    borderRadius: "5px",
    "@media(max-width:1150px)": {
        width: '100%',
    },
});
const BodyTypographyNegotiate = styled("p")({
    marginTop: 10,
    fontFamily: "Segoe UI Bold",
    color: '#676767',
    fontSize: "16px",
    lineHeight: "20px",
    margin: 0,
    display: '-webkit-box',
    height: '44px',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

});
const BudgetTypographyNegotiate = styled("p")({
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "22px",
    margin: "5px 0",
    color: "#676767"
});
const StyledSpanNegotiate = styled("span")({
    color: "#3CAA49",
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "Segoe UI Bold",
    margin: 0
});
const CommissionTypographyNegotiate = styled("p")({
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#676767",
    margin: 0
})
const MainDivNegoition = styled("div")({
    paddingLeft: '25px',
    display: "grid",
    gridGap: '15px',
    justifyContent: 'flex-start',
    gridTemplateColumns: "auto auto auto auto",
    "@media(min-width:1150px) and (max-width: 1260px)": {
        gridTemplateColumns: "auto auto auto"

    },
    "@media(max-width:960px)": {
        gridTemplateColumns: "auto auto auto",
    },
    "@media(max-width:767px)": {
        gridTemplateColumns: "auto auto"
    },
    "@media(max-width:450px)": {
        gridTemplateColumns: "auto",
        justifyContent: 'center',
    },
});
const TextLookingAllNe = styled("div")({
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: 450,
    "@media(max-width:450px)": {
        padding: 5
    }
});

const Loader = styled(Box)({
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "rgb(247, 245, 245)"
});

export default class Negotiation extends NegotiationController {
    renderEmptyTitles(message: any) {
        return <TextLookingAllNe>{message}</TextLookingAllNe>;
    }
    RenderTitle = (item:any) => (
        <BodyTypographyNegotiate>{item.title}</BodyTypographyNegotiate>
      );

      renderPhotos = (photos: any) => {
        const MAX_DISPLAY_PHOTOS = 2;
        const photosCount = photos?.length || 0;
        let ongoingDealsCount = 0;
    
        if (photosCount > MAX_DISPLAY_PHOTOS) {
            ongoingDealsCount = photosCount - MAX_DISPLAY_PHOTOS;
        }
    
        return (
            <div style={{ display: "flex" }}>
                {photos?.slice(0, MAX_DISPLAY_PHOTOS)?.map((photoUrl: any, index: any) => (
                    <img
                        style={{
                            width: '28px',
                            height: '28px',
                            borderRadius: '50%',
                            marginLeft: index !== 0 ? "-10px" : "0",
                        }}
                        key={index}
                        src={photoUrl === null ? profiles : photoUrl}
                        alt=""
                    />
                ))}
                {ongoingDealsCount > 0 && (
                    <div style={{ fontSize: '12px', color: "#676767", fontFamily: "Segoe UI", lineHeight: "24px", marginLeft: "10px" }}>
                        +{ongoingDealsCount} ongoing deals
                    </div>
                )}
            </div>
        );
    };
    
    getStyle = (status: string | null | undefined, isBuyer: boolean) => {
        return status === "sold" && isBuyer ? { cursor: 'default', filter: "grayscale(100%)" } : {};
    }

    render() {
        const { LoaderNegotiation } = this.state;
        if (LoaderNegotiation) {
            return (
                <Loader>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress style={{ color: '#4fa94d' }} />
                    </Box>
                </Loader>
            );
        }
        const NegotiationStyle = {
            link: {
                textDecoration: 'none',
                color: 'inherit'
            },
            mainContainer: {
                height: "calc(100% - 128px)",
                backgroundColor: "rgb(247, 245, 245)",
                padding: '40px 35px 40px 0px'
            },
            cardMainStyle: {
                width: "80%",
                height: "359px",
                borderRadius: "8px",
                padding: "20px 21.5px 17px 21.5px",
                justifyContent: 'space-between',
                backgroundColor: "#FFFFFF",
                flexWrap: 'wrap',
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                cursor: "pointer"
            },
            listingTye: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: "space-between",
                margin: '0'
            },
            looking: {
                paddingRight: '10px',
                width: '17px',
                height: '17px'
            },
            lookingText: {
                fontSize: '12px',
                color: "#676767",
                fontFamily: "Segoe UI",
                lineHeight: "24px",
            },
            userPhoto: {
                width: '28px',
                height: '28px',
                borderRadius: '50%'
            },
            userMain: {
                margin: '15px 0'
            }
        }
        return (
            this.state.setInitiateData.length === 0 ?  <NegotiationEmpty navigation={this.props.navigation.navigate} /> :
                <>
                    <Box style={NegotiationStyle.mainContainer}>
                        <MainDivNegoition>
                            {this.state.setInitiateData?.map((item: any) => {
                                const isBuyer = item.listing_type === "selling_for_something"
                                ? item.buyer_id?.toString() === localStorage.getItem("id")
                                : item.seller_id?.toString() === localStorage.getItem("id");

                                return (
                                    <a 
                                        data-test-id="handleCLick"
                                        style={NegotiationStyle.link}
                                        href={ `/NegotiationUserData/${item.local_listing_ad_id}`}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.onLinkClick(item.listing_status, item.local_listing_ad_id, isBuyer);
                                        }}
                                    >
                                    <ShoppingCardsNegotiate style={this.getStyle(item.listing_status, isBuyer)}>
                                        {item.listing_status === "sold" && <Box className="sold">Sold</Box>}
                                        <Box style={NegotiationStyle.listingTye}>
                                            {item.listing_type === "looking_for_something" ?
                                                <Box display="flex" alignItems="center">
                                                    <img src={Look} alt="" style={NegotiationStyle.looking} />
                                                    <Typography style={NegotiationStyle.lookingText}>Looking for something</Typography>
                                                </Box> 
                                                : <Box display="flex" alignItems="center">
                                                    <img src={Sell} alt="" style={NegotiationStyle.looking} />
                                                    <Typography style={NegotiationStyle.lookingText}>Selling something</Typography>
                                                </Box>
                                            }
                                        </Box>
                                        <div style={{ display: 'flex' }}>
                                            <Box>
                                                {item.listing_type === "looking_for_something" ? (
                                                    <Typography style={NegotiationStyle.userMain}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {this.renderPhotos(item.photo)}
                                                    </div>
                                                </Typography>
                                                ) : (
                                                    <Typography style={NegotiationStyle.userMain}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {this.renderPhotos(item.photo)}
                                                    </div>
                                                </Typography>
                                                )}
                                            </Box>
                                        </div>

                                        <CardImageNegotiate
                                            src={item.listing_image}
                                            alt="Card Image"
                                        />
                                        {this.RenderTitle(item)}
                                        <BudgetTypographyNegotiate>
                                            {item.listing_type === "looking_for_something" ? "Budget" : "Price"} <StyledSpanNegotiate>£ {item.price}</StyledSpanNegotiate>
                                        </BudgetTypographyNegotiate>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <CommissionTypographyNegotiate>
                                                Reward
                                                <StyledSpanNegotiate> £ {item.commission}</StyledSpanNegotiate>
                                            </CommissionTypographyNegotiate>
                                        </div>
                                    </ShoppingCardsNegotiate></a>
                                )
                            })}
                        </MainDivNegoition>
                    </Box>
                </>
        );
    }
}
// Customizable Area End
