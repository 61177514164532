import React, { ChangeEvent, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { styled, Tabs, Tab, AppBar, Box, Typography } from "@material-ui/core";
import MyListingWeb from '../../blocks/user-profile-basic/src/MyListing.web';
import SavedListingWeb from '../../blocks/user-profile-basic/src/SavedListing.web';
import OtherListing from '../../blocks/user-profile-basic/src/OtherListing.web';
import Reviews from '../../blocks/reviews/src/Reviews';
import MyReviewsWeb from '../../blocks/reviews/src/MyReviews.web';

const StyledTab = styled(Tab)({
    justifyContent: 'space-between',
    fontFamily: 'Segoe UI',
    fontSize: '18px',
    verticalAlign: 'middle',
    textTransform: 'none',
    lineHeight: '24px',
    '@media(max-width: 767px)': {
        fontSize: '14px',
    },
    '@media(max-width: 500px)': {
        fontSize: '12px',
    }
});
const CustomTabs = styled(Tabs)({
    '& .MuiTab-root': {
        minWidth: 'unset',
        padding: 6,
        '@media(max-width: 767px)': {
            padding: 0,
        },
        "@media(min-width:1350px)": {
            minWidth: '135px',
        },
    },
    '& .MuiTabs-flexContainer': {
        justifyContent: 'start',
        gap: 40,
        '@media(max-width: 1050px)': {
            gap: '20px',
            justifyContent: 'unset',
        },
        '@media(max-width: 1150px)': {
            gap: 'unset',
            justifyContent: 'space-between',
            paddingInline: 15
        },
        '@media(min-width: 1150px) and (max-width: 1350px)': {
            paddingInline: 15
        },
        '@media(min-width: 1440px)': {
            justifyContent: 'unset',
            paddingInline: 'unset'
        }
    },
    '@media(max-width: 767px)': {
        minHeight: '40px',
        height: '40px',
    }

})

function capitalizeFirstLetter(string: string) {
    if (typeof string === "string" && string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}

export interface Props {
    navigation: any;
    id: string;
    tabNavigate: any;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#3CAA49',
        },
    },
});

function ReviewListing(props: any) {
    const { value, setValue } = props
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentUrl).search).get("tab");
    const parsedUrl = new URL(currentUrl);
    const pathSegments = parsedUrl.pathname.split('/');
    const id = pathSegments.length > 1 ? pathSegments[pathSegments.length - 1] : null;

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        if (searchParams === "savedlist") {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            url.searchParams.delete('tab');
            window.history.replaceState({}, '', url.toString());
        }
        setValue(newValue);
    };

    useEffect(() => {
        if (searchParams === "savedlist") {
            setValue(1)
        }
    }, [searchParams]);

    return (
        <>
            <ThemeProvider theme={theme}>
                < AppBar position="static" color="default" style={{ backgroundColor: '#FFFFFF' }}>
                    <CustomTabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <StyledTab label="Listings" {...a11yProps(0)} />
                        <StyledTab label="Reviews" {...a11yProps(1)} />
                    </CustomTabs>
                </AppBar>

            </ThemeProvider>
            <TabPanel value={value} index={0}>
                <OtherListing {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MyReviewsWeb {...props} userId={id} />
            </TabPanel>
           
        
        </>
    );
}

export default ReviewListing;
