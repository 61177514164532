// Customizable Area Start
import React from "react";

import { Box, Typography, Button, Grid, CircularProgress } from "@material-ui/core";
import Popup from 'reactjs-popup';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { ammend, blob2, deal_finalised, image_Green, payment_comp, refresh } from "./assets";
import PaymentBuyerController from "./PaymentBuyerController.web";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Loader from "../../../components/src/Loader.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export default class PaymentBuyer extends PaymentBuyerController {
  initialOptions = {
    clientId:
      "AUFyv07LMsJXbk2z_Wr39HTVZJ1NmctrEnC2zLZcQNnLYo-XeAfla5WB5o3pQep-kfPM1DHCLQLqV6dA",
    currency: "GBP",
    intent: "capture",
  };

  renderUpperSection = () => {
    const { userDetailss, datas } = this.state;

    return (
      <>
        <Box style={{ padding: "10px 60px" }}>
          <Grid container spacing={3} style={{ marginTop: "20px", padding: '0 40px' }}>
            <Grid item xs={12} lg={4} md={6} sm={12} xl={4} >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "right",
                    objectFit: 'cover'
                  }}
                >
                  <img src={this.state.datas.photo?.[0]?.url} alt="" height={350} width="100%" style={webStyle.imgContain as React.CSSProperties} />
                </Grid>
                {datas.photo &&
                  datas.photo.slice(1).map((item: any, index: any) => (
                    <Grid item key={index} xs={6} lg={4} sm={6} md={6} xl={4} >
                      {" "}
                      <img
                        src={item.url}
                        alt=""
                        height={100}
                        width="100%"
                        style={{
                          marginBottom: "10px",
                          marginRight: "1px",
                          objectFit: 'contain'
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>{" "}
            </Grid>
            <Grid item xs={12} lg={8} md={6} sm={12} xl={8} >
              <div style={webStyle.label}>
                <Typography style={webStyle.title as React.CSSProperties}>
                  {" "}
                  {this.state.datas.title}
                </Typography>
                <Typography style={webStyle.listingtype as React.CSSProperties}>
                  @{userDetailss ? userDetailss.user_name : ""}
                </Typography>
                <hr style={webStyle.hr as React.CSSProperties} />
                <Typography style={webStyle.catagory as React.CSSProperties}>
                  <Typography
                    style={webStyle.catagoryData as React.CSSProperties}
                  >
                    <Typography style={{ fontStyle: "italic" }}>
                      Category
                    </Typography>
                    <Typography
                      style={webStyle.catagoryText as React.CSSProperties}>
                      {" "}
                      {this.state.datas.category}
                    </Typography>
                  </Typography>
                  <Typography
                    style={webStyle.catagoryDataSubCategory as React.CSSProperties}>
                    <Typography style={{ fontStyle: "italic" }}>
                      Sub-Category
                    </Typography>
                    <Typography
                      style={webStyle.catagoryTextArea as React.CSSProperties} >
                      {this.state.datas.sub_category}
                    </Typography>
                  </Typography>
                  <Typography style={webStyle.catagoryData as React.CSSProperties} >
                    <Typography style={{ fontStyle: "italic" }}>Location</Typography>
                    <Typography style={webStyle.catagoryText as React.CSSProperties}>
                      {this.state.datas.location}
                    </Typography>
                  </Typography>

                </Typography>
                <hr style={webStyle.hr as React.CSSProperties} />
                <Typography
                  style={webStyle.discrtiption as React.CSSProperties} >
                  Description
                </Typography>
                <Typography
                  style={webStyle.discrtiptionText as React.CSSProperties}
                >
                  {" "}
                  {this.state.datas.description}
                </Typography>
              </div>{" "}
            </Grid>
          </Grid>
          <hr style={{
            backgroundColor: "#E5E7EB",
            border: "none",
            height: "1px",
            marginTop: '10px'
          }} />
        </Box>
      </>
    );
  };
  handleRefreshClick = () => {
    window.location.reload();
  }

  renderCriteriaOrTermsSectionPayment() {
    const {
      criteria_or_termsss,
      showAmendmentsApprovalss,
      criteria_amendmentsss,
    } = this.state;

    return (
      <>
        <Box style={{ padding: "0px 60px" }}>
          <Typography style={StylesNegotiation.secondHeading}>
          {this.state.datas.listing_type === "looking_for_something" ? "Approval Criteria" : "Terms of Purchase"}
          </Typography>
          <Typography style={StylesNegotiation.secondSecionText}>
            Following are the actions by buyer for this list
          </Typography>
          <Grid container spacing={2}>
            {criteria_or_termsss?.map((item: { id: string | number; title: string | null; }, index: number) => {
            const isVisible = criteria_amendmentsss.some((amend: { criteria_id: string | number; status: string; }) => amend.criteria_id === item.id && amend.status !== "accept");
            return(
              <div key={item.id} style={{ width: "100%" }}>
                <div
                  style={{
                    padding: "20px 10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: "6px",
                  }}
                >
                  <span
                    style={{ fontSize: "24px", fontFamily: "Segoe UI", color: "#676767" }}>
                    {index + 1}.
                  </span>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontFamily: "Segoe UI",
                      color: "#676767",
                      width: "100%",
                      maxWidth: "90%",
                      textAlign: "justify",
                      marginLeft: "-50px",
                    }}>
                    {item.title}
                  </Typography>
                  <img style={{ width: "32px", height: "32px" }} src={image_Green} alt="Accepted" />
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={StylesNegotiation.budgetTitle}>
                    {isVisible &&
                    <Typography
                      data-test-id="see2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        fontFamily: "Segoe UI Bold",
                        color: "#3CAA49",
                      }}
                      onClick={() => {
                        this.toggleAmendmentsApproval(item.id);
                      }}
                    >
                      See amendments
                      <img
                        src={ammend}
                        style={{
                          transform: showAmendmentsApprovalss[item.id] ? "rotate(180deg)" : "rotate(0deg)",
                          marginTop: '5px'
                        }}
                        alt="Toggle"
                      />
                    </Typography>}
                        
                    {showAmendmentsApprovalss[item.id] &&
  criteria_amendmentsss
    .filter((ammend: any) => ammend.criteria_id === item.id && ammend.status !== "accept" )
    .map((ammend: any, index: number) => (
        <Box
          key={ammend.id}
          style={{
            borderRadius: "3px",
            margin: "10px",
            maxHeight: "100px",
            background: index % 2 === 0 ? '#E2E8F0' : '#ECF7ED',
            padding: "10px 20px",
            width: "70%",
          }}
        >
          <Typography>{ammend.reason}</Typography>
        </Box>
     
    ))
}

                  </div>
                </div>
                <hr style={StylesNegotiation.hr as React.CSSProperties} />
              </div>
            )})}
          </Grid>
        </Box>
      </>
    );
  }

  renderDealFinaliseds = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 30,
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src={deal_finalised} alt="Payment Completed" />
        <div
          style={{
            marginTop: "10px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
            fontSize: "30px",
            color: "#3CAA49",
          }}
        >
          Congratulations.The deal has been finalised by Seller!{" "}
        </div>
        <div
          style={{
            marginTop: "10px",
            fontFamily: "Segoe UI",
            paddingBottom: "10px",
            fontWeight: 400,
            fontSize: "20px",
            color: "#676767",
          }}
        >
          {" "}
          Continue to payment. You will be redirected to payment page in{" "}
          <span style={{ color: "#3CAA49" }}>
            {this.state.countdown} sec...
          </span>
          <br />
        </div>
      </Box>
    );
  };
  sellerWait = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 30,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={deal_finalised} alt="Payment Completed" />
        <div
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            color: "#676767",
            fontFamily: "Segoe UI",
            marginTop: "10px",
          }}
        >
          Wait for the seller to send link to continue payment{" "}
        </div>
        <Box mt="10px" display="flex" alignItems="center">
        <div
          style={{
            marginTop: "10px",
            fontFamily: "Segoe UI",
            paddingBottom: "10px",
            fontWeight: 400,
            fontSize: "20px",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            onClick={this.handleRefreshClick}
            src={refresh}
            alt="Payment Completed"
          />
        </div>
        <CloseButtonLink
          data-test-id="open-popup"
          onClick={this.openModals}
        >
          Close Negotiation
        </CloseButtonLink>
        </Box>
      </Box>
    );
  };
  renderCommissionSection() {
    const { amendment_dealss } = this.state;
    const commissionDeal = amendment_dealss.filter(
      (deal: any) => deal.amendment_type === "commission" && deal.status !== "accept"
    );
    const budgetDeal = amendment_dealss.filter((deal: { amendment_type: string; status: string; }) => deal.amendment_type === "budget" && deal.status !== "accept");

    return (
      <>
        <Grid container alignItems="center" style={{ padding: "0px 60px" }}>
          <div
            style={{
              marginTop: "10px",
              width: "100%",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={StylesNegotiation.budget}>
              {this.state.datas.listing_type === "looking_for_something" ? "Budget " : "Price "}
              <span style={StylesNegotiation.price}>
                £ {this.state.datas.budget}
              </span>
            </Typography>
            <img
              alt="Accepted"
              style={{ width: "32px", height: "32px" }}
              src={image_Green}
            />
          </div>
          <Box display='flex' flexDirection='column' width="100%">
            <div style={StylesNegotiation.budget}>
              {budgetDeal.length > 0 &&
              <Typography
                style={StylesNegotiation.amendments}
                onClick={this.onPriceAmendmentClick}
                data-test-id="see-amendment"
              >
                See amendments
                <img
                  src={ammend}
                  alt="Payment Completed"
                  style={{
                    marginTop: '5px',
                    transform: this.state.priceAmendments ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </Typography>}
              {this.state.priceAmendments && budgetDeal.map((deal: { id: string | number; reason: string; }, index: number) => {
                const color = index % 2 === 0 ? "#ECF7ED" : "#E2E8F0";
                return (
                  <Box
                    key={deal.id}
                    padding='10px 0px'
                    height='20px'
                    margin='10px'
                    borderRadius='3px'
                    width='20%'
                    style={{ background: color }}
                  >
                    <Typography>{deal.reason}</Typography>
                  </Box>
                )
              }
              )}
            </div>
          </Box>
          <div
            style={{
              marginTop: "10px",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
            }}
          >
            <Typography style={StylesNegotiation.budget}>
              Reward{" "}
              <span style={StylesNegotiation.price}>
                £ {this.state.datas.commission}
              </span>
            </Typography>
            <Box>
              <img
                alt="Accepted"
                style={{ width: "32px", height: "32px" }}
                src={image_Green} />
            </Box>
          </div>{" "}
          <Box display='flex' flexDirection='column' width="100%">
            <div style={StylesNegotiation.budget}>
              {commissionDeal.length > 0 && 
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#3CAA49",
                  cursor: "pointer",
                  fontFamily: "Segoe UI Bold",
                }}
                onClick={() => {
                  this.toggleAmendments();
                  this.toggleRotation();
                }}
                data-test-id="see"
              >
                See amendments
                <img src={ammend}
                  style={{
                    transform: this.state.isRotated ? "rotate(180deg)" : "rotate(0deg)", marginTop: '5px' // Rotate image based on state
                  }} alt="Payment Completed" />
              </Typography>}
              {this.state.showAmendments &&
                commissionDeal.map((deal: any, index:number) => (
                  <Box
                    key={deal.id}
                    style={{
                      height: "20px",
                      padding: "10px 0px",
                      borderRadius: "3px",
                      width: "20%",
                      margin: "10px",
                      background: index % 2 === 0 ? '#ECF7ED' : '#E2E8F0',
                    }}
                  >
                    <Typography>{deal.reason}</Typography>
                  </Box>
                ))}
            </div>
          </Box>
          <hr style={StylesNegotiation.hr as React.CSSProperties} />
        </Grid>
      </>
    );
  }

  renderPreferenceSection() {
    return (
      <>
        <Box style={StylesNegotiation.commonPadding}>
          <Typography style={StylesNegotiation.secondHeading}>
            Preference
          </Typography>
          <div
            style={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography style={{
              fontSize: "24px",
              fontFamily: "Segoe UI",
              color: "#676767",
              width: '100%',
              paddingRight: '20px',
              maxWidth: "93%",
              maxHeight: '350px',
              overflowY: 'auto',
              textAlign: "justify",
              margin: 0,
            }}>
              {this.state.datas.preferences}
            </Typography>
            <img
              alt="Accepted"
              style={{ width: "32px", height: "32px" }}
              src={image_Green}
            />
          </div>
        </Box>
      </>
    );
  }

  paymentCompleted = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 30,
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src={deal_finalised}
          alt="Payment Completed"
        />
        <div
          style={{
            marginTop: "10px",
            fontWeight: 700,
            fontFamily: "Segoe UI Bold",
            fontSize: "32px",
            lineHeight: '24px',
            color: "#3CAA49",
          }}
        >
          {" "}
          Payment Successfully Completed
        </div>
        <div
          style={{
            marginTop: "10px",
            fontFamily: "Segoe UI",
            paddingBottom: "10px",
            fontWeight: 400,
            fontSize: "20px",
            color: "#676767",
          }}
        >
          {" "}
          Redirecting to Product link in{" "}
          <span style={{ color: "#3CAA49" }}>
            {this.state.counter} sec...
          </span>
          <br />
        </div>
      </Box>
    );
  };
  paymentFailed = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 30,
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src={blob2}
          alt="Payment Completed"
        />
        <div
          style={{
            marginTop: "10px",
            fontWeight: 700,
            fontFamily: "Segoe UI Bold",
            fontSize: "32px",
            lineHeight: '24px',
            color: "#3CAA49",
          }}
        >
          {" "}
          Payment Failed.
        </div>
        <Button
          style={{
            marginTop: "10px",
            fontFamily: "Segoe UI Bold",
            paddingBottom: "10px",
            fontWeight: 400,
            fontSize: "20px",
            background: "#3CAA49",
            color: "white",
            cursor: 'pointer',
            textTransform: 'none',
            padding: '5px 50px'
          }}
          onClick={this.handleRefreshClick}
        >
          Click to Retry...
          <br />
        </Button>
      </Box>
    );
  };

  renderButtons() {
    return (
      <Box margin="40px 0px" display="flex" justifyContent="center">
        <React.Fragment>
          <PayPalScriptProvider options={this.initialOptions}>
            <Box zIndex="1" position="relative" width="250px" height="56px" overflow="hidden">
              <PaymentButton>
                Continue to Payment
              </PaymentButton>
              <PayPalButtons
                style={{
                  layout: "horizontal",
                  color: "gold",
                  shape: "rect",
                  label: "pay",
                  tagline: true,
                  height: 55,
                }}
                disabled={!this.state.datas.commission}
                createOrder={this.createOrder}
                onApprove={this.onApprove}
              />
            </Box>
          </PayPalScriptProvider>
          <Loader loading={this.state.isLoading} />
        </React.Fragment>
        <CloseButton
          variant="contained"
          data-test-id="open-popup"
          onClick={this.openModals}
        >
          Close Negotiation
        </CloseButton>
      </Box>
    );
  }

  paymentalreadyComp = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 30,
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src={payment_comp}
          alt="Payment Completed"
        />
        <div
          style={{
            marginTop: "10px",
            fontWeight: 400,
            fontFamily: "Segoe UI Bold",
            fontSize: "32px",
            lineHeight: '24px',
            color: "#3CAA49",
          }}
        >
          {" "}
          Payment Already Completed.
        </div>

      </Box>
    );
  };

  renderDialog = () => {
    return (
      <>
        <Box style={webStyle.deleteDialog as React.CSSProperties}>
          <Popup
            closeOnDocumentClick
            data-test-id="delete-modal"
            open={this.state.isModalOpen}
            contentStyle={webStyle.content}
            overlayStyle={webStyle.overlay}
            onClose={this.closeModal}
          >
            <DeleteDialog>
              <p className="heading">Close Negotiation</p>
              <div>
                <p className="title">Are you sure you want to close this<br/> negotiation?</p>
              </div>
              <Box>
                <Button
                  data-test-id="close-btn"
                  className="close-btn"
                  onClick={this.closeNegotiation}
                >
                  Close
                </Button>
                <Button
                  data-test-id="cancel-btn"
                  className="cancel-btn"
                  onClick={this.closeModal}
                >
                  Cancel
                </Button>
              </Box>
            </DeleteDialog>
          </Popup>
        </Box>
      </>
    );
  }

  render() {
    const theme = createTheme();
    const { redirect } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation.navigate} />
        {!redirect && this.state.isLoading &&
          <Box
            height='100vh'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <CircularProgress style={{ color: '#4fa94d' }} />
          </Box>}
        {redirect && !this.state.isLoading && !this.state.showLink && !this.state.PaymentMade && !this.state.paymentFailed ? (
          this.renderDealFinaliseds()
        ) : (
          <>
            <Box style={{ padding: "12px 48px" }}>
              {!redirect && !this.state.isLoading && !this.state.ispaymentSuccessModalOpen && !this.state.showLink && !this.state.PaymentMade && !this.state.paymentFailed && (
                <>
                  {this.renderUpperSection()}
                  {this.renderCommissionSection()}
                  {this.renderCriteriaOrTermsSectionPayment()}
                  {this.state.datas.preferences && this.renderPreferenceSection()}
                  {this.renderButtons()}
                </>
              )}
            </Box>
          </>
        )}
        {this.state.PaymentMade && this.paymentalreadyComp()}

        {this.state.paymentFailed && this.paymentFailed()}

        {this.state.showLink && this.sellerWait()}
        {this.state.ispaymentSuccessModalOpen && this.paymentCompleted()}
        {this.renderDialog()}
        <AppFooter naviagtion={this.props.navigation.navigate} />
      </ThemeProvider>
    );
  }
}

const PaymentButton = styled(Button)({
  position: "absolute",
  fontFamily: "Segoe UI Bold",
  backgroundColor: "#3CAA49",
  color: "#FFFFFF",
  borderRadius: "5px",
  width: "250px",
  height: "55px",
  fontSize: "18px",
  border: "none",
  zIndex: 9999,
  pointerEvents: "none",
  textTransform: 'none'
});

const DeleteDialog = styled(Box)({
  "& .heading": {
    margin: "0px",
    fontSize: '32px',
    color: '#0F172A',
    lineHeight: '28px',
    fontFamily: "Segoe UI Bold",
  },
  "& .title": {
    margin: "0px",
    fontSize: '20px',
    color: '#676767',
    marginTop: "20px",
    LineHeight: '24px',
    marginBottom: "35px",
    fontFamily: "Segoe UI",
  },
  "& .close-btn": {
    height: "56px",
    width: "115",
    border: "none",
    color: "#ECF7ED",
    fontSize: "20px",
    lineHeight:"24px",
    borderRadius: "4px",
    marginRight: "15px",
    background: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    textTransform: "capitalize",
  },
  "& .cancel-btn": {
    height: "56px",
    width: "122px",
    color: "#3CAA49",
    fontSize: "18px",
    lineHeight:"24px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    fontFamily: "Segoe UI Bold",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
});

const CloseButtonLink = styled(Box)({
  fontStyle:"italic",
  fontWeight:"bold",
  color: '#54b363',
  cursor: "pointer",
  fontSize: "24px",
  marginLeft: "20px",
  borderRedius: '5px',
  textTransform: 'none',
  fontFamily: "Segoe UI",
  backgroundColor: "#FFF !important",
});

const CloseButton = styled(Button)({
  height: "55px",
  color: '#3CAA49',
  fontSize: "18px",
  marginLeft: "20px",
  borderRedius: '5px',
  padding:"15px 25px",
  textTransfer: "none",
  textTransform: 'none',
  fontFamily: "Segoe UI Bold",
  border: '1px solid #3CAA49',
  backgroundColor: "#FFF !important",
  '&.MuiButton-root.Mui-disabled': {
    color: "rgba(0, 0, 0, 0.26)",
    border: '1px solid #BED9C1 !important',
  }
});

const webStyle = {
  imgContain: {
    objectFit: 'contain'
  },
  deleteDialog: {
    width: '100%',
    margin: 'auto',
    padding: '10px 0px',
    textAlign: 'center',
  },
  content: {
    padding: "70px",
    maxWidth: '526px',
    borderRadius: '8px',
    paddingRight:"140px",
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  title: {
    color: "#676767",
    fontFamily: "Segoe UI Bold",
    fontSize: "38px",
  },
  label: {
    paddingLeft: "20px",
  },

  listingtype: {
    fontFamily: "Segoe UI",
    textAlign: "center",
    color: "#676767",
    fontSize: "18px",
    paddingBottom: "10px",
    display: "flex",
    marginTop: "10px",
  },
  budget: {
    width: "100%",
    padding: "15px 0px",
    display: "flex",
    flexDirection: "column",
  },
  budgetText: {
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
    fontSize: "26px",
    padding: "0 20px 0 10px",
  },

  budgetheading: {
    paddingRight: "10px",
    color: "#676767CC",
    fontSize: "20px",
    fontFamily: "Segoe UI",
  },

  catagory: {
    justifyContent: "space-between",
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
  },

  createButtons: {
    fontFamily: "Segoe UI Bold",
    backgroundColor: "#3CAA49",
    color: "#FFFFFF",
    borderRedius: "5px",
    width: "180px",
    height: "56px",
    fontSize: "14px",
    textTransfer: "none",
  },

  createButtons11: {
    textTransfer: "none",
    fontSize: "14px",
    backgroundColor: "rgba(60, 172, 73, 0.3)",
    height: "56px",
    color: "#FFFFFF",
    width: "180px",
    fontFamily: "Segoe UI Bold",
    borderRedius: "5px",
  },

  createButtons2: {
    color: "#3CAA49",
    width: "199px",
    border: "1px solid #3CAA49",
    background: "#FFFFFF",
    height: "56px",
    fontFamily: "Segoe UI Bold",
    fontSize: "14px",
    textTransfer: "none",
    borderRedius: "5px",
  },

  catagoryData: {
    fontSize: "22px",
    fontFamily: "Segoe UI",
    color: "#67676799",
    paddingRight: "50px",
  },
  catagoryDataSubCategory: {
    fontSize: "22px",
    fontFamily: "Segoe UI",
    color: "#67676799",
    paddingRight: "50px",
  },
  catagoryText: {
    paddingBottom: "10px",
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
  },
  catagoryTextArea: {
    paddingBottom: "10px",
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
  },

  discrtiption: {
    fontStyle: "italic",
    color: "#67676799",
    fontFamily: "Segoe UI",
    fontSize: "22px",
  },

  discrtiptionText: {
    fontFamily: "Segoe UI",
    color: "#676767",
    fontSize: "24px",
    maxHeight: "350px",
    overflowY: "auto",
    textAlign: "justify",
    padding: '15px 20px 20px 0' // Align text justified

  },

  Approval: {
    fontStyle: "italic",
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
  },

  ApprovalText: {
    marginTop: "20px",
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
  },

  Preferences: {
    fontStyle: "italic",
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
  },

  PreferencesText: {
    color: "#676767",
    fontSize: "16px",
    marginBottom: "100px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
  },

  hr: {
    backgroundColor: "#E5E7EB",
    border: "none",
    height: "1px",
  },

  hr1: {
    border: "none",
    height: "1px",
    backgroundColor: "#E5E7EB",
    margin: "30px 0",
  },

  photo: {
    // display: "flex",
    // marginTop: "10px",
    // flexWrap: 'wrap',
    // justifyContent:"space-between"
  },

  userPhoto: {
    borderRadius: "50%",
    height: "52px",
    width: "52px%",
  },

  fullName: {
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
    fontSize: "20px",
    padding: "0 10px",
  },

  userName: {
    paddingLeft: "5px",
    color: "#676767",
    fontSize: "20px",
    fontFamily: "Segoe UI",
  },

  cityCountry: {
    fontFamily: "Segoe UI",
    color: "#676767",
    fontSize: "20px",
  },
};
const StylesNegotiation = {
  amendments: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
  },
  containerMain: { maxWidth: "1440px", margin: "0 auto" },
  childContainer: {
    padding: "20px 77px 20px 77px",
  },
  commonPadding: {
    padding: "0px 60px",
    paddingRight: "56px"
  },
  heading: {
    fontSize: "32px",
    fontFamily: "Segoe UI Bold",
    color: "#676767",
  },
  userName: {
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
  },
  hr: {
    width: "100%",
    height: "1px",
    margin: "25px 0",
    backgroundColor: " #E5E7EB",
    border: "none",
  },

  budget: {
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
  },
  budgetTitle: {
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
  },

  price: {
    margin: 0,
    fontSize: "32px",
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
  },

  PreferenceDeny: {
    width: "105px",
    fontSize: "18px",
    color: "#B2B2B2",
    height: "44px",
    border: "1px solid #B2B2B2",
    borderRedius: "4px",
    fontFamily: "Segoe UI Bold",
    textTransform: "none",
  },

  PreferenceOnClick: {
    width: "105px",
    height: "44px",
    fontFamily: "Segoe UI Bold",
    border: "1px solid #3CAA49",
    borderRedius: "4px",
    textTransform: "none",
    fontSize: "18px",
    color: "#3CAA49",
  },

  PreferenceDenyOnClick: {
    width: "105px",
    height: "44px",
    textTransform: "none",
    fontFamily: "Segoe UI Bold",
    border: "1px solid #0085FF",
    borderRedius: "4px",
    fontSize: "18px",
    color: "#0085FF",
  },

  secondHeading: {
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI Bold",
  },

  secondSecionText: {
    fontSize: "24px",
    fontFamily: "Segoe UI",
    color: "#676767",
    padding: "10px 0 40px 0",
    margin: 0,
  },

  dataTitile: {
    fontSize: "24px",
    fontFamily: "Segoe UI",
    color: "#676767",
    width: "100%",
    maxWidth: "90%",
    textAlign: "justify",
    marginLeft: "-50px"
  },
  createButtons: {
    background: "#3CAA49",
    color: "#FFFFFF",
    width: "250px",
    height: "56px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    borderRedius: "5px",
  },
  createButtons2: {
    color: "#3CAA49",
    width: "199px",
    background: "#FFFFFF",
    height: "56px",
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    border: "1px solid #3CAA49",
    borderRedius: "5px",
  },

  textAreaDisables: {
    marginTop: "10px",
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  spanText: {
    color: "#676767",
    fontSize: "20px",
    fontFamily: "Segoe UI",
  },
  spanText2: {
    fontSize: "20px",
    color: "#676767",
    fontFamily: "Segoe UI",
  },
  spanText11: {
    fontSize: "20px",
    color: "#676767",
    fontFamily: "Segoe UI",
  },
  checkText: {
    color: "#676767",
    lineHeight: "24px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
  },
};

// Customizable Area End
