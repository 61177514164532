// Customizable Area Start
import React from "react";
import { Box, Typography, styled, CircularProgress } from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { imageLocations, profiles } from "./assets";
import OtherUserDetailsController from "./OtherUserDetailsController";
import ReviewListing from "../../../components/src/ReviewListing";
const FormGridA = styled(Box)({
    display: 'flex',
});
const CustomBoxA = styled(Box)({
    width: '336px',

    borderRadius: '0px 15px 0px 0px',
    backgroundColor: '#E0DAD3',
    position: 'sticky',
    height: 'calc(100vh - 80px)',
    top: '80px',
    '@media(max-width: 1150px)': {
        display: 'none'
    },
    '@media(min-width: 1150px)': {
        minWidth: '336px',
    }
})
const CustomContent = styled(Box)({
    display: 'columns',
    Width: 'calc(100% - 150px)',
    minHeight: '100vh',
    borderRadius: '0px, 0px, 0px, 32px',
})

const IconsProfileA = styled(Box)({
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,

    '@media (max-width: 767px)': {
        flexDirection: 'column',
    },
});
const ProfileImgA = styled('img')({
    width: 134,
    height: 134,
    borderRadius: 135,
});
const CustomH6A = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "24px",
    alignItems: 'center',
    lineHeight: 1,
    margin:0,
    marginTop: "26px",
    marginBottom: "5px",
});
const CustomHeadingA = styled("h6")({
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: 'center',
    lineHeight: 1,
    margin: 0,
    marginBottom: "8px"
});
const CustomH1A = styled("h1")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: 'center',
    lineHeight: 1,
    display: 'flex',
    margin: 0
});
const LocationBoxA = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: "5px",
    justifyContent: "center"
});
const LocationImgsA = styled('img')({
    width: '21.1px',
    height: '21px',
});
const SellingTextDataA = styled("p")({
    fontFamily: "Segoe UI Bold",
    fontSize: "24px",
    lineHeight: "24px",
    color: "#181818",
    position: "relative",
    margin: "16px",
    "@media(max-width:450px)": {
        fontSize: "16px",
        lineHeight: "16px"
    },
    marginBottom: "40px"
});

export default class OtherUserDetails extends OtherUserDetailsController {
    renderUserNameDatas() {
        return (
            <>
                <CustomH6A>{this.state.useDetailsDataSS?.attributes.full_name}</CustomH6A>
                <CustomHeadingA>@{this.state.useDetailsDataSS?.attributes.user_name}</CustomHeadingA>
            </>
        )
    }
    renderUserNameDatas1() {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ color: '#4fa94d' }} />
            </Box>
        )
    }
    ProfileImgA() {
        return (
            <ProfileImgA src={profiles} alt="Default Profile" />
        )
    }


    render() {
        const { useDetailsDataSS } = this.state;
        return (
            <>
                <Box width="100%">
                    <AppHeader navigation={this.props.navigation.navigate} />
                    {this.state.LoaderOtherUser ?
                        (<div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                            }}
                        >
                          {this.renderUserNameDatas1()}
                        </div>) :

                        <FormGridA>
                            <CustomBoxA>
                                <IconsProfileA>
                                    {useDetailsDataSS && useDetailsDataSS.attributes.photo ? (
                                        <ProfileImgA src={useDetailsDataSS.attributes.photo} alt="Profile" />
                                    ) : (
                                        this.ProfileImgA()
                                    )}

                                    {this.renderUserNameDatas()}
                                    <LocationBoxA>
                                        <LocationImgsA
                                            src={imageLocations}
                                            alt="Logo"
                                        />
                                        <CustomH1A>{useDetailsDataSS && useDetailsDataSS.attributes.city === null ? "__" :  useDetailsDataSS && useDetailsDataSS.attributes.city},<Typography style={{ marginLeft: '7px' }}>{ useDetailsDataSS && useDetailsDataSS.attributes.country === null ? "__" :  useDetailsDataSS && useDetailsDataSS.attributes.country}</Typography></CustomH1A>
                                    </LocationBoxA>
                                </IconsProfileA>
                            </CustomBoxA>
                            <CustomContent flex="1">
                                <ReviewListing
                                    {...this.props}
                                    value={this.state.tabSelectValueOther}
                                    setValue={this.handleTabSelectOther}
                                />
                            </CustomContent>
                        </FormGridA>
                    }
                </Box>

           </>
        );
    }
}
// Customizable Area End
