// Customizable Area Start
import React from "react";
import {
  StyleSheet,
  SafeAreaView,
} from "react-native";

import { Box, styled, Link, Typography, Grid, Card, CardContent, Button, CircularProgress } from "@material-ui/core";
import { imagePopupSaves, imageEmptyhearts, imageFilledHeart, deleteSavingss, searchImageSave, SellingSomeImage, group_CrossBtns } from "./assets";
import Popup from 'reactjs-popup';
const SavedCardsSellings = styled(Card)({
  width: "189px",
  minHeight: "350px",
  cursor: 'pointer',
  padding: "20px",
  marginTop: "25px",
});
const CardImages = styled("img")({
  width: "100%",
  height: "211px",
  objectFit: "cover",
  borderRadius: "5px",
});
const SaveImagess = styled("img")({
  width: "29px",
  height: "29px",
});
const BudgetTypographyTexts = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: "5px 0",
  color: "#676767"
});
const StyledSpanText = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const SearchImageLists = styled("img")({
  width: "25px",
  height: "25px"
});
const Lookingfor = styled("h6")({
  width: "150px",
  fontFamily: "Segoe UI Bold",
  fontSize: "12px",
  lineHeight: "20px",
  color: '#676767',
  margin: 0
});
const CommissionTypographySaves = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});
const BodyTypographySaves = styled("h6")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  color: '#676767',
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
const MainDivFilterSellings = styled("div")({
  paddingLeft: '36px',
  display: "grid",
  gridGap: '15px',
  justifyContent: 'flex-start',
  gridTemplateColumns: "auto auto auto auto",
  "@media (min-width:1150px) and (max-width: 1399px)": {
    gridTemplateColumns: "auto auto auto",
  },
  "@media (min-width:960px) and (max-width: 1150px)": {
    gridTemplateColumns: "auto auto",
  },
  "@media(max-width:960px)": {
    gridTemplateColumns: "auto auto auto"
  },
  "@media(max-width:767px)": {
    gridTemplateColumns: "auto auto"
  },
  "@media(max-width:450px)": {
    gridTemplateColumns: "auto",
    justifyContent: 'center',
  }
});
type LookingSomeDataItemsPhotos = {
  id: number;
  type: string;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  }
}

import SavedListingSellingController from "./SavedListingSellingController";
import { ListingEmptyLooking } from "../../../components/src/ListingEmptyLooking.web";
export default class SavedListingSelling extends SavedListingSellingController {
  renderSelling() {
    return (
      <>
        <Box style={styleD.lookingHeaders as React.CSSProperties}>
          {this.state.someListingSave && this.state.someListingSave.length > 0 && (
            <Typography style={styleD.lookingTextss as React.CSSProperties}>
              {this.state.someListingSave.length} Products
            </Typography>
          )}
        </Box>
      </>
    );
  }
  removeData= () => {
    return (
      <p style={styleD.popuptextsDeletess as React.CSSProperties} >Remove Saved Listing</p>
    )
  }

  renderSellingCards = (item: LookingSomeDataItemsPhotos) => {
    const authTokenSelling = localStorage.getItem("authToken");
    return (
      <SavedCardsSellings data-test-id="viewHome" onClick={() => this.viewDataOnHomess(item.id)}>
        <div style={{ display: "flex", alignItems: 'center', gap: 10, marginBottom: '10px', marginLeft: '15px' }}>
          <SearchImageLists
            src={item.attributes.listing_type === "looking_for_something" ? searchImageSave : SellingSomeImage}
          />
          <Lookingfor>{item.attributes.listing_type === "looking_for_something" ? "Looking for something" : "Selling something"}</Lookingfor>
        </div>
        <CardImages
          src={item.attributes.photo?.[0]} alt="Card Image" />
        <CardContent style={{ padding: '0px' }}>
          <BodyTypographySaves>
            {item.attributes.title}
          </BodyTypographySaves>
          <BudgetTypographyTexts >
          Price <StyledSpanText>£ {item.attributes.budget}</StyledSpanText>
          </BudgetTypographyTexts>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <CommissionTypographySaves>
              Reward
              <StyledSpanText> £ {item.attributes.commission}</StyledSpanText>
            </CommissionTypographySaves>
            <SaveImagess
              data-test-id="viewHome2"
              src={this.state.savedListingsDatas?.includes(item.id)
                ? imageFilledHeart
                : imageEmptyhearts}
              onClick={(e) => {
                e.stopPropagation();
                authTokenSelling ?
                this.datas(item)
                :  this.savelistSelling();
              }}
              alt="Card Image"
            />
          </div>
        </CardContent>
      </SavedCardsSellings>
    );
  }
  SavedListing(){
    return(
      <div >
      <p style={styleD.popuptextsAdd as React.CSSProperties} >Added to Saved Listing!</p>
    </div>
    )
  }
 
  render() {
    return (
      <SafeAreaView style={styles.mainContainers}>
        <Box width="100%" style={{ padding: 0 }}>
          {this.state.LoaderSavedSelling ?
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ color: '#4fa94d' }} />
              </Box>
            </div> : <>
              {this.renderSelling()}
              <Box>

                <div>
                  {this.state.someListingSave?.length === 0 ? 
                    <div style={{margin:"0 auto"}}>
                    <ListingEmptyLooking />
                    </div>
                   : (
                    <MainDivFilterSellings>
                      {this.state.someListingSave?.map((item: LookingSomeDataItemsPhotos) => this.renderSellingCards(item)
                      )}
                    </MainDivFilterSellings>
                  )}
                </div>

                <div style={styleD.modalStyles as React.CSSProperties}>
                  <div style={styleD.actionsStylesa as React.CSSProperties}>
                    <Popup
                      open={this.state.savedPopup}
                      closeOnDocumentClick onClose={this.saveClosePopups}
                      className="popup"
                      contentStyle={styleD.contentSavess}
                      overlayStyle={styleD.overlaysess}
                    >
                      <div>
                        <div style={styleD.popupIMGMainsDiv as React.CSSProperties} >
                          <img src={imagePopupSaves} alt="popup" />
                        </div>
                        {this.SavedListing()}
                        <div>
                          <p style={styleD.popuptextssGoTos as React.CSSProperties} >Go to
                            <Link style={styleD.signlinks as React.CSSProperties}
                              onClick={this.handleSaveListingses}
                            >
                              Saved Listings
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
              </Box>
              <Popup
                open={this.state.removeSavedLists}
                closeOnDocumentClick onClose={this.navigateSavedSomethingsRemove}
                className="popup"
                contentStyle={styleD.contentsDeletess}
                overlayStyle={styleD.overlaysDeletess}
              >
                <div style={styleD.deletePopupDeletess as React.CSSProperties}>
                {this.removeData()}
                  <div>
                    <p style={styleD.popuptextsssa as React.CSSProperties} >Do you want to remove item from Saved Listing?</p>
                  </div>
                  <Box >
                    <Button style={styleD.plateformExploreButtonsa as React.CSSProperties} onClick={this.navigateSavedSomethingsRemove}>Cancel</Button>
                    <Button style={styleD.plateformListingButtondeleteAccountsa as React.CSSProperties}
                      data-test-id="viewHome3"
                      onClick={() => this.deleteSavelistingCalleds(this.state.deleteItemIdss)}
                    >Remove</Button>
                  </Box>
                </div>
              </Popup>
              <Popup
                open={this.state.successRemoveSavings}
                closeOnDocumentClick onClose={this.successpopupSaveded}
                className="popup"
                contentStyle={styleD.contentsPasswordsa}
                overlayStyle={styleD.overlaysBtna}
              >
                <div>
                  <img src={group_CrossBtns} alt="Cross" style={styleD.imgCrossesDone as React.CSSProperties} onClick={this.closePopupPassworded} />
                  <div style={styleD.popupIMGMainssBtnDone as React.CSSProperties} >
                    <img src={deleteSavingss} alt="Crosss" />
                  </div>
                  <p style={styleD.passwordChangesesy as React.CSSProperties}>{this.state.deleteItemss}</p>
                </div>
              </Popup>
            </>
          }
        </Box>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  mainContainers: {
    width: "100%"
  },
});
const styleD = {
  containerss: {
    padding: "0px 56px"
  },
  homeTexts: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    maxWidth: '1296px',
    margin: 'auto'
  },
  checkTextss: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    lineHeight: "24px"
  },
  homeTextbars: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    cursor: "pointer"
  },
  imageHomeCrosses: {
    width: "7.41px",
    height: "12px",
    margin: "0px"
  },
  lookingTexts: {
    color: "#3CAA49",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    cursor: "pointer"
  },
  lookingMainss: {
    margin: "20px 0px"
  },
  lookingHeaders: {
  },
  lookingTextss: {
    fontFamily: "Segoe UI",
    fontSize: "24px",
    color: "#3CAA49",
    lineHeight: 1,
    marginLeft: '30px'
  },
  mapFNs: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  modalStyles: {
    fontSize: '12px',
  },
  actionsStylesa: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  popuptextsAdd: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  overlaysess: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentSavess: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '400px'
  },
  popupIMGs: {
    display: "flex",
    margin: "0 auto",
  },
  popuptextsssa: {
    fontSize: '18px',
    fontFamily: "Segoe UI",
    color: '#676767',
    width: '400px',
    LineHeight: '26px'
  },
  popupIMGMainsDiv: {
    margin: "15px 0px 15px 0px",
    display: "flex",
    justifyContent: "center"
  },
  signlinks: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  popuptextssGoTos: {
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: "#181818"
  },
  arrowCloses: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  arrorMains: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px'
  },
  arrorMainJewellerys: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
  },
  arrorMainClothings: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
  },
  arrorMainInteriors: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
  },
  arrorMainServices: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
    borderBottom: '1px solid lightgray',
    paddingBottom: '20px',
  },
  styleBudgets: {
    display: 'columns',
    borderBottom: '1px solid lightgray',
    paddingBottom: '20px'
  },
  styleCommissions: {
    display: 'columns',
    borderBottom: '1px solid lightgray',
    paddingBottom: '20px'
  },
  contentsDeletess: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '527px',
    borderRadius: '8px',
    height: '322px'
  },
  overlaysDeletess: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  deletePopupDeletess: {
    marginTop: "85px",
    paddingLeft: '52px'
  },
  popuptextsDeletess: {
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '32px',
    margin: '0',
  },
  plateformExploreButtonsa: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "15px",
    textTransform: "capitalize",
  },
  plateformListingButtondeleteAccountsa: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  overlaysBtna: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentsPasswordsa: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '30.5px 43.5px 30.5px 43.5px',
    width: '500px',
    borderRadius: '8px',
    height: '350px'
  },
  popupIMGMainssBtnDone: {
    width: '232px',
    heigth: '232px',
    margin: 'auto',
  },
  passwordChangesesy: {
    fontSize: '30px',
    marginTop: "15px",
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#000000",
    LineHeigth: '32px',
    margin: 'auto',
  },
  imgCrossesDone: {
    position: 'absolute',
    top: 38,
    margin: 'auto',
    right: 36,
    cursor: 'pointer',
  },
}
// Customizable Area End
