import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  // Customizable Area Start
}

export interface S {
  // Customizable Area Start
  otpAuthToken: string;
  data: any[]
  activeGridId: number;
  savesListing: any[];
  somesListing: any[];
  savedListing: any[];
  removeSavedListing: boolean,
  successRemoveSaved: boolean,
  LoaderSaveListing: boolean,
  deleteItemId: any,
  deleteItem: object,
  ListingEmptysSave: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiSaveListingItemCallId : string = "";
  apideleteSaveItemCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      otpAuthToken: "",
      data: [],
      activeGridId: 1,
      savesListing: [],
      somesListing: [],
      savedListing: [],
      removeSavedListing: false,
      successRemoveSaved: false,
      deleteItem: {},
      deleteItemId: null,
      LoaderSaveListing: true,
      ListingEmptysSave: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.apiSaveListingItemCallId) {
        this.setState({LoaderSaveListing: false})
        if (responseJson && !responseJson.errors) {
          if(responseJson.message){
            this.setState({ ListingEmptysSave: responseJson.message})
            }
          else{
          this.setState({
            savedListing: responseJson.data,
          });
        }
        }
      }
      if (apiRequestCallId === this.apideleteSaveItemCallId) {
        this.handleRemoveSaveApiResponse(responseJson);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentDidMount(): any {
    this.saveListingCall()
  }
  viewDataSavedList(id:any){
    this.props.navigation.navigate('ViewListing' ,{ id: id })
  }
  handleRemoveSaveApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        deleteItem: responseJson?.message,
        successRemoveSaved: true,
      });
       this.saveListingCall()
    }
  }
  navigateSavedSomething = (id:any) => {
    this.setState({removeSavedListing: false})
  }
  popupSaved = (id:any) => {
    this.setState({removeSavedListing: true, deleteItemId: id,})
  }
  successpopupSaved = () => {
    this.setState({successRemoveSaved: false})
    if(this.state.ListingEmptysSave=== "No wishlist items found."){
      this.setState({
        savedListing: []
      });
    }
  }
 
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  saveListingCall = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSaveListingItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ "Content-Type":  "application/json",
      token: token})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 deleteSavelistingCall = async (id:any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apideleteSaveItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/bx_block_locallistingads/wishlist_items/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
      token: token})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 
  // Customizable Area End
}
