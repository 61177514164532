// Customizable Area Start
import React from "react";
import { Box, styled, Card, Typography, Button, CircularProgress } from "@material-ui/core";
import Popup from 'reactjs-popup';
import { LogoutImage, imageLocations, profiles, settingImage, image_Green, SellingSomesImage, searchImageSave } from "./assets";
import { imageHomeCross } from "../../landingpage/src/assets";
import { AppHeader } from "../../../components/src/AppHeader";
import moment from "moment";

import OrderDetailsController from "./OrderDetailsController.web";

const FormGridData = styled(Box)({
    display: 'flex',
});
const CustomBoxData = styled(Box)({
    width: '336px',
    borderRadius: '0px 15px 0px 0px',
    backgroundColor: '#E0DAD3',
    position: 'sticky',
    height: 'calc(100vh - 80px)',
    top: '80px',
    '@media(max-width: 1150px)': {
        display: 'none'
    },
    '@media(min-width: 1150px)': {
        minWidth: '336px',
    }
})
const CustomContentData = styled(Box)({
    display: 'columns',
    Width: 'calc(100% - 150px)',
    minHeight: '100vh',
    borderRadius: '0px, 0px, 0px, 32px',
})
const IconsProfileData = styled(Box)({
    textAlign: "center",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,

    '@media (max-width: 767px)': {
        flexDirection: 'column',
    },
});
const LogoImageData = styled("img")({
    width: "28px",
    height: "28px",
});

const LogoTextData = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: "center",
    lineHeight: "26.6px",
    margin: 0,
});
const LogoutLogoImageData = styled("img")({
    width: "28px",
    height: "28px",
});

const LogoutTextData = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: "center",
    lineHeight: "26.6px",
    margin: "0",
});

const ProfileImgData = styled('img')({
    width: 134,
    height: 134,
    borderRadius: 135,
});
const CustomH6Data = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "24px",
    alignItems: 'center',
    lineHeight: 1,
    marginBottom: "5px",
    marginTop: "26px"
});
const CustomHeadingData = styled("h6")({
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: 'center',
    lineHeight:  1,
    margin: "0",
});
const CustomH1Data = styled("h1")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: 'center',
    lineHeight: 1,
    display: 'flex',
    margin: 0
});
const LocationBoxData = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: "5px",
    justifyContent: "center",
    marginTop: "8px"
});
const LocationImgsData = styled('img')({
    width: '21.1px',
    height: '21px',
});
const FlexDivData = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    gap: "10px",
    cursor: 'pointer'
});
const LogoutDivData = styled(Box)({
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    gap: "10px",
    marginBottom: "20px",
    cursor: "pointer",
});
const HomeTextDivsData = styled('div')({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginLeft: "32px",
    "@media(min-width:1440px)": {
        marginLeft: "22px",
        margin: 'auto',
    }
});



const CardImage = styled("img")({
    width: "20%",
    height: "20%",
    objectFit: "cover",
    "@media(max-width:1150px)": {
        width: '100%',
    }
});
const Viewbtn = styled(Button)({
    borderColor: '#3CAA49', 
    color: '#3CAA49',
    maxHeight: '32px', 
    "@media(max-width:767px)": {
        maxHeight: '40px',
      },
      "@media(max-width:450px)": {
        maxHeight: '50px',
      },

});

const ViewText = styled(Typography)({
     color: "#3CAA49", textTransform: "none", "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 700,
     "lineHeight": "24px", "textAlign": "left",
    "@media(max-width:767px)": {
        "fontSize": "12px"
      },
      "@media(max-width:450px)": {
        "fontSize": "10px"
      },

});

const styleDN = {
    homeTextbar: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "24px",
        cursor: "pointer"
    },
    lookingText: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "12px",
        cursor: "pointer",
    },
    imageHomeCrosses: {
        width: "7.41px",
        height: "12px",
        margin: "0px"
    },
    lookingTextsMain: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#3CAA49",
        marginLeft: '24px'
    },


    listingTyes: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0'
    },
    lookings: { paddingRight: '10px', width: '25px', height: '25px' },
    lookingTexts: { paddingTop: '5px', fontSize: '12px' },
    userPhotos: {
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        marginRight: '10px'
    },
    titileMain: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#3CAA49",
    },
    userDetails: {
        fontFamily: "Segoe UI Bold",
        fontSize: "16px",
        color: "#676767",
    },
    userMains: {
        margin: '15px 0'
    },
    photoStyle:{
        color: "#3CAA49", fontSize: "16px", lineHeight: "40px", fontWeight: 700, fontFamily: "Segoe UI"
    },
    photoText:{
        color: "#676767" 
    },
    photoMain:{
    color: "#676767", fontSize: "16px", lineHeight: "40px", fontWeight: 400, fontFamily: "Segoe UI"
    }
}

const Wrapper = styled(Box)({
    maxWidth: "290px", 
    margin: "auto",
    "& .description": {
      margin: "0px",
      marginTop: "16px",
      fontSize: '18px',
      fontFamily: "Segoe UI",
      color: '#676767',
      LineHeight: '26px',
    },
    "& .title": {
      margin: "0px",
      fontSize: '32px',
      color: '#181818',
      lineHeight: '32px',
      fontFamily: "Segoe UI Bold",
    },
    "& .cancel-btn": {
      height: "56px",
      border: "none",
      width: "135px",
      borderRedius: "4px",
      background: "#3CAA49",
      color: "#ECF7ED",
      fontFamily: "Segoe UI Bold",
      fontSize: "20px",
      textTransform: "capitalize",
      marginRight: "20px",
    },
    "& .logout-btn": {
      height: "56px",
      width: "135px",
      borderRedius: "4px",
      color: "#3CAA49",
      fontFamily: "Segoe UI Bold",
      fontSize: "18px",
      textTransform: "capitalize",
      background: "#FFFFFFE5",
      border: "1px solid #3CAA49",
    },
});
  
const webStyle = {
    contentStyle: {
        backgroundColor: '#fff',
        maxWidth: '290px',
        padding: '80px 120px 80px 120px',
        width: '100%',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
    },
    overlayStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};
  
export default class OrderDetails extends OrderDetailsController {
    formatNumber = (number:any) => {
        return new Intl.NumberFormat('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(number);
      };

      logoutDialog = () => {
        return (
          <Popup
            open={this.state.isOpen}
            closeOnDocumentClick
            onClose={this.onModalClose}
            contentStyle={webStyle.contentStyle}
            overlayStyle={webStyle.overlayStyle}
          >
            <Wrapper>
              <p className="title">Logging out</p>
              <div>
                <p className="description">Are you sure you want to leave?</p>
              </div>
              <Box marginTop="40px">
                <Button className="cancel-btn" data-test-id="cancel-btn" onClick={this.onModalClose}>Cancel</Button>
                <Button className="logout-btn" data-test-id="logout-btn" onClick={this.handleButtonClicks}>Log-out</Button>
              </Box>
            </Wrapper>
          </Popup>
        );
    }

    render() {

        const userPhoto = localStorage.getItem("profilePhoto")
        const userId = parseInt(localStorage.getItem("id") || "0", 10);
        const { reviewData } = this.state
        const formattedBudgetOrder = this.formatNumber(reviewData.attributes.listing.budget);
        const formattedCommissionOrder = this.formatNumber(reviewData.attributes.listing.commission);

        return (
            <>

                <Box width="100%">
                    <AppHeader navigation={this.props.navigation.navigate} />
                    <FormGridData>
                        {this.logoutDialog()}
                        <CustomBoxData>
                            <IconsProfileData>
                                <ProfileImgData
                                    src={userPhoto == null  ?  profiles : userPhoto}
                                    alt="Logo Image"
                                />
                                <CustomH6Data>{this.state.useProfiles?.attributes?.full_name}</CustomH6Data>
                                <CustomHeadingData>@{this.state.useProfiles?.attributes?.user_name}</CustomHeadingData>
                                <LocationBoxData>
                                    <LocationImgsData
                                        src={imageLocations}
                                        alt="Logo" />
                                    <CustomH1Data>{this.state.citys == null ? "__" : this.state.citys} ,<Typography style={{ marginLeft: '7px' }}>{this.state.countrys == null ? "__" : this.state.countrys}</Typography></CustomH1Data>
                                </LocationBoxData>
                            </IconsProfileData>
                            <div style={{ position: 'absolute', bottom: 0, left: "50%", transform: 'translateX(-50%)' }}>
                                <FlexDivData data-test-id='handleButtonClickSettings' onClick={this.handleButtonClickSettings}>
                                    <LogoImageData src={settingImage} alt="Logo" />
                                    <LogoTextData>Settings</LogoTextData>
                                </FlexDivData>
                                <LogoutDivData data-test-id='handleButtonClicks' onClick={this.onOpen}>
                                    <LogoutLogoImageData src={LogoutImage} alt="Logout Logo" />
                                    <LogoutTextData>Logout</LogoutTextData>
                                </LogoutDivData>
                            </div>
                        </CustomBoxData>
                        <CustomContentData flex="1" >

                            <>
                                <HomeTextDivsData>
                                    <div onClick={this.handleNavigateHistory}>
                                        <p style={styleDN.homeTextbar as React.CSSProperties}
                                        >Order History</p></div>
                                    <div>
                                        <img src={imageHomeCross} style={styleDN.imageHomeCrosses as React.CSSProperties} alt="" />
                                    </div>
                                    <span style={styleDN.titileMain}>
                                        {reviewData.attributes?.listing.title}
                                    </span>
                                </HomeTextDivsData>
                                {this.state.LoaderNegotiationData ?
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100vh",
                                        }}
                                    >
                                        <Box>
                                            <CircularProgress style={{ color: "#4fa94d" }} />
                                        </Box>
                                    </div> :

                                    <Box style={{ background: '#FCFBFB', padding: "32 60 60 32" }}>

                                        <Card className="review_card" style={{ fontFamily: 'Segoe UI', padding: 32, maxWidth: 992, margin: 2, boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)' }}>

                                            <MainDive>
                                                <div style={{ width: "20%" }}>

                                                    <CardImage
                                                        style={{ width: 178, height: 212, objectFit: "contain" }}
                                                        src={reviewData?.attributes?.listing?.image[0]}
                                                    />
                                                </div>
                                                <div style={{ width: "80%", display: "flex", flexDirection: "column", gap: "16px", maxWidth: "716px" }}>
                                                    <div>
                                                        <Typography data-test-id="title" style={{ "fontFamily": "Segoe UI bold", "fontSize": "24px", "fontWeight": 700, "lineHeight": "20px", "textAlign": "left" }}>{reviewData?.attributes?.listing?.title}</Typography>

                                                        <Typography style={{ marginTop: "12px", "fontFamily": "Segoe UI", "fontSize": "14px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left", color: "#676767" }}> {reviewData?.attributes?.listing?.category}</Typography>
                                                    </div>
                                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                        <CardImage
                                                            style={{ width: 25, height: 25, objectFit: "cover", color: "#676767" }}
                                                            src={reviewData?.attributes?.listing?.listing_type === "looking_for_something" ? SellingSomesImage : searchImageSave}

                                                        />

                                                        <Typography data-test-id={`listing`} style={{ color: "#676767", "fontFamily": "Segoe UI", "fontSize": "12px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left", paddingBottom: "4px" }}>{reviewData?.attributes?.listing?.listing_type === "selling_for_something" ? "Selling Something" : "Looking For Something"}</Typography>

                                                    </div>

                                                    <Typography data-test-id={`listing`} style={{ wordWrap: "break-word", overflowWrap: "break-word", color: "#676767", "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left", paddingBottom: "4px" }}>{reviewData?.attributes?.listing?.description}</Typography>


                                                    <div style={{ display: "flex", }}>

                                                        <div style={{ color: "#676767", display: "flex", justifyContent: "space-between" }}>
                                                            <Typography style={{ "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 400, "lineHeight": "22px", "textAlign": "left" }}>
                                                                {reviewData?.attributes?.listing?.listing_type === "looking_for_something" ? "Budget" : "Price"}</Typography> <Typography style={{ "textAlign": "right", padding: "0 10px", fontWeight: 700, color: "#3CAA49" }}>£ {formattedBudgetOrder}</Typography></div>

                                                        <div style={{ color: "#676767", display: "flex", justifyContent: "space-between" }}>
                                                            <Typography style={{ "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 400, "lineHeight": "22px", "textAlign": "left" }}>
                                                                Reward</Typography> <Typography style={{ "textAlign": "right", padding: "0 10px", fontWeight: 700, color: "#3CAA49" }}>£ {formattedCommissionOrder}</Typography></div>
                                                    </div>

                                                </div>
                                            </MainDive>
                                            <Typography style={{ color: "#676767", "fontFamily": "Segoe UI", "fontSize": "14px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left", marginTop: "10px" }}> Order ID - {reviewData?.attributes?.listing.order_id}</Typography>

                                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <CardImage
                                                        style={{ width: 18, height: 18, objectFit: "cover" }}
                                                        src={image_Green}
                                                    />
                                                    <Typography style={{ "fontFamily": "Segoe UI", "fontSize": "14px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left", padding: "0 10px", color: "#000000" }}>Delivered on  {`${moment(reviewData.attributes.created_at ?? reviewData.attributes.updated_at).format("Do MMMM YYYY")}`}</Typography>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {reviewData.attributes.status !== null ?
                                                        <Button disabled data-test-id={"navtorefund"} ><ViewTextDisabled >Refund {reviewData.attributes.status}</ViewTextDisabled></Button>
                                                        :
                                                        <Button data-test-id={`navtorefund`} onClick={() => this.handleNavigateRefund(reviewData.attributes.listing.order_id)}><ViewText >Refund Request</ViewText></Button>}                                    </div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
                                                <div style={{ maxWidth: "297px", padding: "16px 24px", border: "solid 0.3px #B2B2B2", borderRadius: "8px" }}>
                                                    {
                                                        reviewData.attributes.listing.listing_type === "selling_for_something" ?
                                                            (
                                                                userId == reviewData.attributes.buyer_profile_detail.account_id ?
                                                                 (
                                                                    
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", fontStyle: "italic", fontFamily: "Segoe UI" }}>
                                                                        Seller Details -
                                                                    </Typography>
                                                                ) 
                                                                :
                                                                 (
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", fontStyle: "italic", fontFamily: "Segoe UI" }}>
                                                                        Buyer Details -
                                                                    </Typography>
                                                                )
                                                            )
                                                             :
                                                            (
                                                                userId == reviewData.attributes.seller_profile_detail.account_id ? (
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", fontStyle: "italic", fontFamily: "Segoe UI" }}>
                                                                        Buyer Details -
                                                                    </Typography>
                                                                )
                                                                 : 
                                                                 (
                                                                        <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", fontStyle: "italic", fontFamily: "Segoe UI" }}>
                                                                        Seller Details -
                                                                </Typography>
                                                                )
                                                            )
                                                    }

                                                   
                                                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                        {reviewData.attributes.listing.listing_type === "selling_for_something" ? (
                                                           
                                                                 userId == reviewData.attributes.buyer_profile_detail.account_id ? (
                                                                <>
                                             <img src={reviewData.attributes.seller_profile_detail.photo === null ? profiles : reviewData.attributes.seller_profile_detail.photo} alt="seller" style={{ width: "35px", height: "35px", borderRadius: "135px" }} />
                                                             <Typography style={styleDN.photoStyle as React.CSSProperties}>
                                                                {reviewData.attributes.seller_profile_detail.full_name}
                                                        </Typography>
                                                                     <Typography style={styleDN.photoText as React.CSSProperties}>/</Typography>
                                                                     <Typography style={styleDN.photoMain as React.CSSProperties}>
                                                          @{reviewData.attributes.seller_profile_detail.user_name}
                                                                      </Typography>   </>
                                                      
                                                      ) : (
                                                        
                                                                <>
                                                                    <img src={reviewData.attributes.buyer_profile_detail.photo === null ? profiles : reviewData.attributes.buyer_profile_detail.photo} alt="buyer" style={{ width: "35px", height: "35px", borderRadius: "135px" }} />
                                                                    <Typography style={{ color: "#3CAA49", fontSize: "16px", lineHeight: "40px", fontWeight: 700, fontFamily: "Segoe UI" }}>
                                                                           {reviewData.attributes.buyer_profile_detail.full_name}
                                                                </Typography>
                                                                <Typography style={styleDN.photoText as React.CSSProperties}>/</Typography>
                                                                         <Typography style={styleDN.photoMain as React.CSSProperties}>
                                                                         @{reviewData.attributes.buyer_profile_detail.user_name}
                                                                    </Typography>
                                                                 </>
                                                            )
                                                        ) : 
                                                        (
                                                             userId == reviewData.attributes.buyer_profile_detail.account_id ? (
                                                                <>
                                                                 <img src={reviewData.attributes.seller_profile_detail.photo == null ? profiles : reviewData.attributes.seller_profile_detail.photo} alt="buyer" style={{ width: "35px", height: "35px", borderRadius: "135px" }} />
                                                                      <Typography style={{ color: "#3CAA49", fontSize: "16px", lineHeight: "40px", fontWeight: 700, fontFamily: "Segoe UI" }}>
                                                                         {reviewData.attributes.seller_profile_detail.full_name}
                                                                    </Typography>
                                                                    <Typography style={{ color: "#676767" }}>/</Typography>
                                                                 <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", fontWeight: 400, fontFamily: "Segoe UI" }}>
                                                                        @{reviewData.attributes.seller_profile_detail.user_name}
                                                                    </Typography>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img src={reviewData.attributes.buyer_profile_detail.photo === null ?  profiles : reviewData.attributes.buyer_profile_detail.photo} alt="seller" style={{ width: "35px", height: "35px", borderRadius: "135px" }} />
                                                                    <Typography style={{ color: "#3CAA49", fontSize: "16px", lineHeight: "40px", fontWeight: 700, fontFamily: "Segoe UI" }}>
                                                                        {reviewData.attributes.buyer_profile_detail.full_name}
                                                                    </Typography>
                                                                    <Typography style={{ color: "#676767" }}>/</Typography>
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", fontWeight: 400, fontFamily: "Segoe UI" }}>
                                                                        @{reviewData.attributes.buyer_profile_detail.user_name}
                                                                    </Typography>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                    {
                                                        reviewData.attributes.listing.listing_type === "selling_for_something" ?
                                                            (
                                                                userId == reviewData.attributes.buyer_profile_detail.account_id ? (
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", "fontFamily": "Segoe UI" }}>
                                                                        {reviewData.attributes.seller_profile_detail.city == null ? "__" :reviewData.attributes.seller_profile_detail.city} , {reviewData.attributes.seller_profile_detail.country == null ? "__" :reviewData.attributes.seller_profile_detail.country}</Typography>
                                                                ) : (
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", "fontFamily": "Segoe UI" }}>
                                                                        {reviewData.attributes.buyer_profile_detail.city == null ? "__" :reviewData.attributes.buyer_profile_detail.city} , {reviewData.attributes.buyer_profile_detail.city == null ? "__" : reviewData.attributes.buyer_profile_detail.country}</Typography>
                                                                )
                                                            ) :
                                                            (
                                                                userId == reviewData.attributes.seller_profile_detail.account_id ? (
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", "fontFamily": "Segoe UI" }}>
                                                                        {reviewData.attributes.buyer_profile_detail.city == null ? "__" : reviewData.attributes.buyer_profile_detail.city} , {reviewData.attributes.buyer_profile_detail.country == null ? "__" :reviewData.attributes.buyer_profile_detail.country}</Typography>
                                                                ) : (
                                                                    <Typography style={{ color: "#676767", fontSize: "16px", lineHeight: "40px", "fontFamily": "Segoe UI" }}>
                                                                        {reviewData.attributes.seller_profile_detail.city == null ? "__" : reviewData.attributes.seller_profile_detail.city} , {reviewData.attributes.seller_profile_detail.country == null ? "__" :reviewData.attributes.seller_profile_detail.country}</Typography>
                                                                )
                                                            )
                                                    }

                                    </div>

                                    <Viewbtn data-test-id="invoice" variant="outlined" onClick={()=>this.handleNavigateInvoice(reviewData?.id)}><ViewText >View Invoice</ViewText></Viewbtn>


                                </div>

                            </Card>
                        </Box>}
                        </>
                    </CustomContentData>
                </FormGridData>
            </Box>


        </>
        );
    }

}

const MainDive = styled('div')({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "initial",
    gap: "32px",

    '@media(max-width: 730px)': {
        flexDirection: "column",
    }
});


const ViewTextb = styled(Typography)({
    color: "#3CAA49", 
    textTransform: "none", "fontFamily": "Segoe UI", "fontSize": "16px",
     "fontWeight": 700, "lineHeight": "24px", "textAlign": "left",
    "@media(max-width:767px)": {
      "fontSize": "14px"
    },
    "@media(max-width:450px)": {
      "fontSize": "12px"
    },
  
  });
  
  const ViewTextDisabled = styled(Typography)({
    color: "#676767", textTransform: "none", 
    "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 700, "lineHeight": "24px", 
    "textAlign": "left",
    "@media(max-width:767px)": {
      "fontSize": "14px"
    },
    "@media(max-width:450px)": {
      "fontSize": "12px"
    },
  
  });
// Customizable Area End
