import React from "react";

import {
  Button,
  Typography,
  Box,
  Grid,
  Link,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {scrollToElements}  from "../../../components/src/RefundPolicy.web";
// Customizable Area End

import RefundPolicyController, {
  Props,
} from "./RefundPolicyController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { refundPolicy } from "./assets";

export default class RefundManagement extends RefundPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
      this.containerRef = React.createRef<HTMLDivElement>();
    // Customizable Area End
  }

  // Customizable Area Start
  containerRef: React.RefObject<HTMLDivElement>
  refundchangeStyleOnclicks(item: any) {
    const handleClicks = (e: any) => {
       e.preventDefault();
       this.refundchangeStyleOnclick(item.id);
    };
    return (
      <>
        <div key={item.id}>
          <Link href={`#${item.id}`} onClick={handleClicks}>
            <Button
              data-testid="firstStep"
              style={this.state.chnageColor === item.id ? RefundwebStyle.labelRefund as React.CSSProperties : RefundwebStyle.labelDataRefund as React.CSSProperties}
              onClick={() => this.refundchangeStyleOnclick(item.id)}
            >
              {item.title}
            </Button>
          </Link>
        </div>
      </>
    );
  }
  refundchangeStyleOnclick(item: any) {
    scrollToElements(item, this.containerRef);
    this.setState({ chnageColor: item });
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div
          style={{ margin: "0" }}
        >
          <AppHeader navigation={this.props.navigation.navigate} />
          <Box>
          </Box>
          <Box>
            <img
              style={RefundwebStyle.imgRefund}
              src={refundPolicy}
              width={"100%"}
              height={"60%"}
            />
          </Box>
          <div>
            <Box m={5}>
              <Grid container>
                <Grid item sm={4} style={{ width: "100%" }}>
                  <div style={RefundwebStyle.definationMainRefund as React.CSSProperties}>
                    <Box style={{padding: "48px 40px 48px 40px", display: "flex", flexDirection: "column",gap: "25px"}}>
                      {
                        this.state.refundPolicyData?.map((item: any) => {
                          return (
                            this.refundchangeStyleOnclicks(item)
                          )
                        }
                        )
                      }
                    </Box>
                  </div>
                </Grid>
                <Grid item sm={8}>
                  <div ref={this.containerRef} className="scroll-container" style={{ paddingInline:"32px", height: "704px", overflowY: "scroll", scrollbarWidth:"none" }}>
                    <Typography style={RefundwebStyle.labelRightsRefund as React.CSSProperties}>
                      Refund Policy for Item Find Ltd.
                    </Typography>
                    <Typography style={RefundwebStyle.labelRightRefund as React.CSSProperties}>
                      At Item Find, we strive to provide a reliable platform for trading internet links to access hard-to-find products or services. We value our customers and aim to ensure a positive experience with every transaction. However, we understand that circumstances may arise where a refund is necessary. This Refund Policy outlines the conditions and procedures for requesting and processing refunds. By using our website and engaging in link trades, you agree to this policy.
                    </Typography>
                    {
                      this.state.refundPolicyData?.map((item: any) => {
                        return (
                          <Box id={item.id} key={item.id}>
                            <Typography style={RefundwebStyle.labelRightHeadRefund as React.CSSProperties}>
                              {item.title}
                            </Typography>
                            <Typography style={RefundwebStyle.labelRightRefund as React.CSSProperties}>
                              {item.description}
                            </Typography>
                          </Box>
                        )
                      }
                      )
                    }
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <Box>
          <AppFooter naviagtion={this.props.navigation.navigate} />
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RefundwebStyle = {

  definationMainRefund: {
    backgroundColor: "#ECF7ED",
    minHeight: "704px"
  },
  labelRefund: {
    color: "#3CAA49",
    fontSize: "24px",
    fontFamily: "Segoe UI Bold",
    border: "none",
    background: "none",
    textAlign: "left",
    cursor: "pointer",
    textTransform: 'none'
  },
  labelDataRefund: {
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
    border: "none",
    textAlign: "left",
    background: "none",
    cursor: "pointer",
    textTransform: 'none'
  },
  labelRightRefund: {
    padding: "20px 0px",
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    whiteSpace: "pre-wrap",
    lineHeight: "31.92px"
  },
  labelRightsRefund: {
    padding: "0px 0px 20px 0px",
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    whiteSpace: "pre-wrap",
    lineHeight: "31.92px"
  },
  labelRightHeadRefund: {
    fontSize: "24px",
    fontFamily: "Segoe UI Bold",
    color: "#676767",
    whiteSpace: "pre-wrap",
    lineHeight: "31.92px"
  },
  imgRefund: {
    maxWidth: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  }

};
// Customizable Area End
