import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Link, CircularProgress, Typography,styled } from "@material-ui/core";
import { imgTearmsnCondition } from "./assest";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import {scrollToElement}  from "../../../components/src/ScrollBar.web";
const StyledSpanSaved = styled(Button)({
    fontSize: "24px",
    fontFamily: "Segoe UI",
    border: "none",
    background: "none",
    textAlign: "left",
    cursor: "pointer",
    textTransform: 'none',
});
const StyledMainBox = styled(Box)({
  padding: "47px 40px 47px 40px", display: "flex", flexDirection: "column", gap: "32px" ,
  '@media(max-width: 1260px)': {
    padding: "20px"
  },
  '@media(max-width: 960px)': {
    padding: "20px 12px"
  }
});
const SideDiv = styled('div')({
  paddingInline:"32px" ,height: "782px", overflowY: "scroll", scrollbarWidth: "none",
  '@media (max-width: 1540px)': {
    height: 838
  },
  '@media (max-width: 1404px)': {
    height: 866
  },
  '@media (max-width: 1263px)': {
    height: 894
  },
  '@media (max-width: 1260px)': {
    height: 810
  },
  '@media (max-width: 1143px)': {
    height: 838
  },
  '@media (max-width: 1103px)': {
    height: 866
  }
})

// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
      this.containerRef = React.createRef<HTMLDivElement>();
    // Customizable Area End
  }

  // Customizable Area Start
  containerRef: React.RefObject<HTMLDivElement>

  changeStyleOnclick = (itemId: any) => {
    scrollToElement(itemId, this.containerRef)
    this.setState({ chnageColor: itemId });
  };

  changeStyleOnclicks = (item: any) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      this.changeStyleOnclick(item.id);
    };

    return (
      <div key={item.id}>
        <Link data-testid="firstSteplink" href={`#${item.id}`} onClick={handleClick}>
          <StyledSpanSaved
            data-testid="firstStep"
            style={this.state.chnageColor === item.id ? webStyle.label as React.CSSProperties : webStyle.labelData as React.CSSProperties}
          >
            {item.title}
          </StyledSpanSaved>
        </Link>
      </div>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <div
          style={{ margin: "0" }}
        >
          <AppHeader navigation={this.props.navigation.navigate} />
          <Box>
            {this.state.LoaderListing ?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress style={{ color: '#4fa94d' }} />
                </Box>
              </div> : <></>}
          </Box>
          <Box>
            <img
              style={webStyle.img}
              src={imgTearmsnCondition}
              width={"100%"}
              height={"60%"}
            />
          </Box>
          <div>
            <Box m={5}>
              <Grid container>
                <Grid item sm={4} style={{ width: "100%" }}>
                  <div style={webStyle.definationMain}>
                    <StyledMainBox>
                      {this.state.termsConds?.map((item: any) => this.changeStyleOnclicks(item))}
                    </StyledMainBox>
                  </div>
                </Grid>
                <Grid item sm={8}>
                  <SideDiv ref={this.containerRef} className="scroll-container" >
                    <Typography style={webStyle.labelRights  as React.CSSProperties}>
                      Terms and Conditions for Item Find Ltd.
                    </Typography>
                    <Typography style={webStyle.labelRight  as React.CSSProperties}>
                      Welcome to Item Find. These Terms and Conditions govern your use of our online platform, where users can trade links for items online. By accessing or using our platform, you agree to be bound by these Terms and Conditions. Please read them carefully before using the service.
                    </Typography>
                    {this.state.termsConds?.map((item: any) => (
                      <Box id={item.id} key={item.id}>
                        <Typography style={webStyle.labelRightHead  as React.CSSProperties}>
                          {item.title}
                        </Typography>
                        <Typography style={webStyle.labelRight  as React.CSSProperties}>
                          {item.description}
                        </Typography>
                      </Box>
                    ))}
                  </SideDiv>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <Box>
          <AppFooter naviagtion={this.props.navigation.navigate} />
        </Box>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {

  definationMain: {
    backgroundColor: "#ECF7ED",
    minHeight: "704px"
  },
  label: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    lineHeight: "28px"
  },
  labelData: {
    color: "#676767",
    lineHeight: "28px"
  },
  labelRight: {
    padding: "20px 0px",
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    whiteSpace: "pre-wrap",
    lineHeight: "31.92px"
  },
  labelRights: {
    padding: "0px 0px 20px 0px",
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    whiteSpace: "pre-wrap",
    lineHeight: "31.92px"
  },
  labelRightHead: {
    fontFamily: "Segoe UI Bold",
    color: "#676767",
    fontSize: "24px",
    whiteSpace: "pre-wrap",
    lineHeight: "31.92px"
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  }

};

// Customizable Area End
