Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";
exports.btnExampleTitles = "Text 4";
exports.btnExampleTitlesType = "Listing Details";
exports.btnExampleTitlesTypes = "Listing type is important";
exports.btnExampleTitlesTypes = "Listing Details main";
exports.btnExampleTitlesTypes = "Listing Details main Text";
exports.btnExampleList = "Listing Details main item";
exports.btnExampleTitlesTypess = "Listing Details main Text Data";
exports.btnExampleTitle = "Text 1";
exports.btnExampleTitle = "Text 2";
exports.btnExampleTitle = "Text 44344";
// Customizable Area End