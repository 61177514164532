// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  Button,
  Grid,
  Dialog,
  Paper,
  FormLabel,
  FormControl,
  TextField,
  IconButton,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { payment_link, refresh, Thanks } from "./assets";
import PaymentSuccessController from "./PaymentSuccessController.web";
import { Rating } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

export default class PaymentSuccess extends PaymentSuccessController {
  refreshPage = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 30,
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "20px",
        }}
      >
        <img src={payment_link} alt="Payment Completed" />
        <div
          style={{
            margin: "10px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
            fontSize: "25px",
            color: "#676767",
          }}
        >
          The link will be with you very shortly. We appreciate your patience.
          <br />
        </div>
        <img
          style={{ cursor: "pointer" }}
          src={refresh}
          onClick={this.handleRefreshClick}
          alt="Payment Completed"
        />
      </Box>
    );
  };
  SendLinkSection = () => {
    const { datas } = this.state;

    return (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            margin: 90,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={10}>
              <div style={webStyle.label}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {datas.image && datas.image.length > 0 && (
                    <img src={datas.image[0]} alt="" width={100} height={100} style={webStyle.imgContain as React.CSSProperties} />
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px 30px 10px 30px",
                    }}
                  >
                    <Typography style={webStyle.title}>
                      {datas.title}
                    </Typography>

                    <Typography
                      style={webStyle.listingtype as React.CSSProperties}
                    >
                      {datas.category_name}
                    </Typography>
                  </div>
                </div>

                <hr style={webStyle.hr} />
                <Typography style={webStyle.catagoryText}>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontFamily: "Segoe UI Bold",
                      padding: "20px 0px",
                    }}
                  >
                    Product link
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a
                      href={datas.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "green", textDecoration: "underline" }}
                    >
                      {datas.link}
                    </a>
                  </div>
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      textTransform: "none",
                      fontSize: "18px",
                      fontFamily: "Segoe UI Bold",
                      padding: "8px 40px",
                      background:  this.state.reviewStatus ? 'rgba(60, 170, 73, 0.3)' :"#3CAA49",
                      color: "white",
                    }}
                    disabled={this.state.reviewStatus}  
                    data-test-id="SendLink1"
                    onClick={this.toggleReviewPopup}
                  >
                    Submit Review
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  handleRatingChange = (value: any) => {
    this.setState({ rating: value });
  };
  handleReviewDescriptionChange = (event: any) => {
    this.setState({ reviewDescription: event.target.value });
  };
  reviewPopup = () => {
    const { isReviewSubmitted } = this.state;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {!isReviewSubmitted && (
          <Dialog
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow:
                " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
            }}
            onClose={this.toggleReviewPopup}
            data-test-id="editmodal"
            open={this.state.isReviewPopupOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper elevation={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                  padding: "50px 50px",
                }}
              >
                <Typography
                  id="headerText"
                  style={{
                    fontWeight: 700,
                    fontSize: "24px",
                    fontFamily: "Segoe UI bold",
                  }}
                >
                  Share your feedback on this deal
                </Typography>

                <FormLabel
                  style={{
                    fontSize: "14px",
                    fontWeight: 200,
                    fontFamily: "Segoe UI",
                  }}
                >
                  {" "}
                  Rate your experience
                </FormLabel>
                <Rating
                  value={this.state.rating}
                  data-test-id="Rating"
                  onChange={(event, newValue) => {
                    this.handleRatingChange(newValue);
                  }}
                />

                <FormControl style={{ width: "100%", marginBottom: "8px" }}>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontWeight: 200,
                      fontFamily: "Segoe UI",
                      marginBottom: "8px",
                    }}
                  >
                    What was your experience like?
                  </FormLabel>
                  <TextField
                    name="ein"
                    type="text"
                    minRows={4}
                    variant="outlined"
                    multiline={true}
                    data-test-id="emailvalue"
                    value={this.state.reviewDescription}
                    style={{ backgroundColor: "#ffffff" }}
                    onChange={this.handleReviewDescriptionChange}
                    color="primary"
                    InputProps={{
                      placeholder:
                        "Tell us your experience on this website and with this user",
                      style: { borderRadius: "8px", fontFamily: "Segoe UI" },
                    }}
                    fullWidth
                  />
                </FormControl>
                <div style={{ gap: "10px" }}>
                  <Button
                    test-id="popupApproval"
                    disabled={this.state.reviewButton}
                    variant="contained"
                    style={{
                      border: "2px solid #3CAA49",
                      background: "#3CAA49",
                      color: "#FFFFFF",
                      padding: "10px 20px",
                      height: "50px",
                      fontSize: "14px",
                      fontFamily: "Segoe UI Bold",
                      borderRadius: "4px",
                    }}
                    data-test-id="SendLink2"
                    onClick={this.reviewsAdd}
                  >
                    <span style={{ textTransform: "none" }}>Submit Review</span>
                  </Button>
                  <Button
                    test-id="popupApproval"
                    variant="contained"
                    style={{
                      border: "2px solid #3CAA49",
                      background: "#FFFFFF",
                      color: "#3CAA49",
                      height: "45px",
                      padding: "10px 20px",
                      fontSize: "15px",
                      marginLeft: "15px",
                      fontFamily: "Segoe UI Bold",
                      borderRadius: "4px",
                    }}
                    data-test-id="SendLink"
                    onClick={this.toggleReviewPopup}
                  >
                    <span style={{ textTransform: "none" }}>Cancel</span>
                  </Button>
                </div>
              </div>
            </Paper>
          </Dialog>
        )}
        {isReviewSubmitted && (
          <Dialog
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow:
                " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
            }}
            onClose={this.toggleReviewSubmittedPopup}
            open={this.state.isReviewSubmittedPopupOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <IconButton
      style={{ position: 'absolute', top: '10px', right: '10px',  }}
      onClick={this.handleRefreshClick}
    >
      <CloseIcon />
    </IconButton>
            <Paper elevation={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  padding: "40px 40px",
                }}
              >
                <img src={Thanks} width={300} height={300} />
                <Typography
                  id="headerText"
                  style={{
                    fontWeight: 700,
                    fontSize: "24px",
                    fontFamily: "Segoe UI bold",
                  }}
                >
                  Thank you for your valuable feedback
                </Typography>
              </div>
            </Paper>
          </Dialog>
        )}
      </div>
    );
  };
 

  render() {
    const theme = createTheme();
    return (
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation.navigate} />
        {this.state.showLink ? (
          <>{this.SendLinkSection()}</>
        ) : (
          <>{this.refreshPage()}</>
        )}
        {this.reviewPopup()}

        <AppFooter naviagtion={this.props.navigation.navigate} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  imgContain: {
    objectFit: 'contain'
  },
  title: {
    fontFamily: "Segoe UI Bold",
    fontSize: "38px",
    color: "#676767",
  },
  label: {
    paddingLeft: "20px",
  },
  listingtype: {
    marginTop: "10px",
    color: "#676767",
    fontSize: "18px",
    textAlign: "center",
    fontFamily: "Segoe UI",
    paddingBottom: "10px",
    display: "flex",
  },
  budget: {
    flexDirection: "column",
    padding: "15px 0px",
    display: "flex",
    width: "100%",
  },
  budgetText: {
    padding: "0 20px 0 10px",
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
    fontSize: "26px",
  },
  budgetheading: {
    color: "#676767CC",
    fontSize: "20px",
    paddingRight: "10px",
    fontFamily: "Segoe UI",
  },
  catagory: {
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  createButtons: {
    backgroundColor: "#3CAA49",
    color: "#FFFFFF",
    fontFamily: "Segoe UI Bold",
    width: "180px",
    height: "56px",
    fontSize: "14px",
    textTransfer: "none",
    borderRedius: "5px",
  },
  createButtons11: {
    backgroundColor: "rgba(60, 172, 73, 0.3)",
    color: "#FFFFFF",
    borderRedius: "5px",
    width: "180px",
    height: "56px",
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
  },
  createButtons2: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    width: "199px",
    background: "#FFFFFF",
    height: "56px",
    fontSize: "14px",
    textTransfer: "none",
    border: "1px solid #3CAA49",
    borderRedius: "5px",
  },
  catagoryData: {
    color: "#67676799",
    paddingRight: "50px",
    fontSize: "22px",
    fontFamily: "Segoe UI",
  },

  discrtiption: {
    color: "#67676799",
    fontSize: "22px",
    fontFamily: "Segoe UI",
    fontStyle: "italic",
  },
  catagoryText: {
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
    paddingBottom: "10px",
  },
  discrtiptionText: {
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    width: "70%",
  },
  Approval: {
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
    fontStyle: "italic",
  },
  ApprovalText: {
    fontSize: "16px",
    marginTop: "20px",
    fontFamily: "Segoe UI",
    color: "#676767",
  },
  Preferences: {
    fontStyle: "italic",
    fontFamily: "Segoe UI",
    color: "#67676799",
    fontSize: "16px",
    marginTop: "20px",
  },
  PreferencesText: {
    fontSize: "16px",
    marginBottom: "100px",
    fontFamily: "Segoe UI",
    color: "#676767",
    marginTop: "20px",
  },
  hr: {
    border: "none",
    backgroundColor: "#E5E7EB",
    height: "1px",
  },
  hr1: {
    backgroundColor: "#E5E7EB",
    border: "none",
    margin: "30px 0",
    height: "1px",
  },

  userPhoto: {
    height: "52px",
    borderRadius: "50%",
    width: "52px%",
  },
  fullName: {
    padding: "0 10px",
    color: "#3CAA49",
    fontSize: "20px",
    fontFamily: "Segoe UI Bold",
  },
  userName: {
    paddingLeft: "5px",
    fontSize: "20px",
    color: "#676767",
    fontFamily: "Segoe UI",
  },
  cityCountry: {
    fontFamily: "Segoe UI",
    color: "#676767",
    fontSize: "20px",
  },
};

// Customizable Area End
