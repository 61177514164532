import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  token: string
  expandedItems: { [key: string]: boolean };
  faqsData: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InteractivefaqsControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  interactive_faqs: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.interactive_faqs = "";

    this.state = {
      name: "",
      token: '',
      expandedItems: {},
      faqsData: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTokens();
    // Customizable Area Start
    this.getFaqsDats()
    window.scrollTo(0,0);
    // Customizable Area End
  }

  getTokens = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.interactive_faqs) {
        this.setState({
          faqsData: responseJson?.data,
        })
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleAccordion = (id: string) => {
    this.setState((prevState) => ({
      expandedItems: {
        ...prevState.expandedItems,
        [id]: !prevState.expandedItems[id],
      },
    }));
  };
  ContactusNavigationAdd = () => {
    this.props.navigation.navigate("AddContactus")
  }
  getFaqsDats = () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.interactive_faqs = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `interactive_faqs/interactive_faqs?${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
