import React from 'react';
import { Box, styled } from "@material-ui/core";
import { login } from '../../blocks/user-profile-basic/src/assets';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch.web';


export function AppHeader(props:any) {
  const { navigation } = props;
  const storedFullName = localStorage.getItem("fullName");
  const auth = localStorage.getItem("authToken");
  const userPhoto = localStorage.getItem("profilePhoto")
  const CustomBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    color: '#676767',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Segoe UI',
    position: 'relative',
    gap: '10px',
    justifyContent: 'space-between',

  });
  const IconsImage = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer'
  });
  const IconsPlus = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px',
    cursor: "pointer",
  });
  const SearchInput = styled('input')({
    fontSize: '16px',
    color: 'black',
    fontFamily: 'Segoe UI',
    minHeight: '31px',
    width: 435,
    border: '1px solid lightgray',
    borderRadius: '5px',
    paddingLeft: '10px',
    paddingRight: '35px',
    lineHeight: 2,
    '&:focus-visible': {
      outline: 'none !important',
    },
    '@media(max-width: 1399px)': {
      width: '300px',
    },
    '@media(max-width: 1000px)': {
      width: 'unset',
    },
    '@media(max-width: 400px)': {
      width: '150px',
    }
  });
  const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 'auto',
    height: 80,
    backgroundColor: '#FFFFFF',
    position: 'sticky',
    top: 0,
    zIndex: 11111,
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      height: 'unset',
      gap: '15px'
    }
  });
  const Imagelogo = styled('img')({
    width: 231,
    paddingLeft: 72,
    cursor: "pointer",
    '@media (max-width: 767px)': {
      display: 'none',
    },
    '@media (max-width: 1350px)': {
      paddingLeft: 20,
      width: 150,
      height: 'unset',
    },
  });
  const Imagelogo1 = styled('img')({
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'inline-block'
    },
    '@media (max-width: 1350px)': {
      paddingLeft: 20,
      width: 120,
      height: 'unset',
    },
  });
  const AllCaterogies = styled('div')({
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  });
  const AllCaterogies1 = styled('div')({
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'inline-block'
    },
  });
  const SearchContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 'none',
    position: 'relative',
  });
  const ImagesSearch = styled('img')({
    width: '22px',
    height: '22px',
    position: 'absolute',
    right: '10',
  });

  const IconAllImage = styled('div')({
    display: 'flex',
    gap: 20,
    paddingRight: 72,
    cursor: 'pointer',
    '@media (max-width: 1350px)': {
      paddingRight: 20,
    },
  });
  const ImageChildPlus = styled('img')({
    width: 25,
    height: 25.1,
    '@media (max-width: 1199px)': {
      fontSize: '14px',
      width: 15,
      height: 15.1,
    },
  });
  const ProfileHeader = styled('p')({
    margin: 0,
    fontFamily: "Segoe UI Bold",
    fontSize: '18px',
    color: '#000000',
    paddingTop: "5px",
    lineHeight: '24px',
    '@media (max-width: 1199px)': {
      fontSize: '12px',

    },
  });
  const ImageShape = styled('img')({
    width: 25,
    height: 25.1,
    '@media (max-width: 1199px)': {
      fontSize: '14px',
      width: 15,
      height: 15.1,
    },
  });
  const Wishlist = styled('p')({
    margin: 0,
    fontSize: '22px',
    color: '#676767',
    fontFamily: 'Segoe UI',
    lineHeight: '24px',
    '@media (max-width: 1199px)': {
      fontSize: '14px',
    },
    '@media (max-width: 400px)': {
      fontSize: '10px',
    },
  });
  const CreateListing = styled('p')({
    margin: 0,
    fontSize: '22px',
    color: '#676767',
    fontFamily: 'Segoe UI',
    lineHeight: '24px',
    '@media (max-width: 1199px)': {
      fontSize: '14px',
    },
    '@media (max-width: 400px)': {
      fontSize: '10px',
    },
  });
  const LogoBox = styled(Box)({
    display: 'none',
    '@media (max-width: 767px)': {
      width: '100%',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px',
      paddingRight: '20px'
    },
  });

 
  return (
    <Box style={{ boxShadow: '0px 5px 9px #80808030', position: 'sticky', top: 0, zIndex: 99999, backgroundColor: "FFFFFF" }}>
      <Container style={{ maxWidth: '1440px', margin: 'auto' }}>
        <Imagelogo src={require('./logo.jpg')} alt="Logo" onClick={() => { 
          auth ? 
          navigation("LandingPage")  :
          navigation("LandingPageMain")
          
          }} />
        <AllCaterogies>
          <CustomBox>
          </CustomBox>

          <AdvancedSearch navigation={navigation} id={''}  handleShowCross={props.handleShowCross}/>
     
          {/* <SearchContainer>
            <SearchInput
            type="text"
            // autoFocus
            placeholder="Search"
            // value={props.searchText}
            // onChange={(e:any)=>props.onChange(e)} 
            /> 
          </SearchContainer> */}
        </AllCaterogies>
        <LogoBox>
          <Imagelogo1 src={require('./logo.jpg')} alt="Logo" />
          <AllCaterogies1>
            <CustomBox>
            </CustomBox>
            <SearchContainer>
              <SearchInput type="text" placeholder="Search" />
              <ImagesSearch
                src={require('./imageSearch.png')}
                alt="Search Icon"
              />
            </SearchContainer>
          </AllCaterogies1>
        </LogoBox>
        <IconAllImage>
          {auth ? <>
            <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/UserProfileBasicBlock?tab=savedlist`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      navigation("UserProfileBasicBlock", "?tab=savedlist") 
                    }
                  }}>
            <IconsImage
              >
              <ImageShape
                src={require('./imageShape.png')}
                alt="Logo"
              />
              <Wishlist>Saved Listings</Wishlist>
            </IconsImage></a>
          </> :

<a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/EmailAccountLoginBlock`} onClick={(event) => {
  if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
    event && event.preventDefault();
    navigation("EmailAccountLoginBlock")
  }
}}>
            <IconsImage >
              <ImageShape
                src={require('./imageShape.png')}
                alt="Logo"
              />
              <Wishlist>Saved Listings</Wishlist>
            </IconsImage></a>
          }
          {auth ? <>
            <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/CreateListing`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      navigation("CreateListing") 
                    }
                  }}>
            <IconsPlus>
              <ImageChildPlus
                src={require('./imagePlus.png')}
                alt="Logo"
              />
              <CreateListing>Create Listing</CreateListing>
            </IconsPlus></a>
          </> : <>
          <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/EmailAccountLoginBlock`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      navigation("EmailAccountLoginBlock")
                    }
                  }}>
            <IconsPlus>
              <ImageChildPlus
                src={require('./imagePlus.png')}
                alt="Logo"
              />
              <CreateListing>Create Listing</CreateListing>
            </IconsPlus> </a>
          </>}
          {auth ? <>
            <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/UserProfileBasicBlock?tab=mylisting`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      navigation("UserProfileBasicBlock", "?tab=mylisting") 
                    }
                  }}>
            <img style={{
              width: 40,
              height: 40,
              borderRadius: '50%'
            }}

              //@ts-ignore
              src={userPhoto == null ? require('./profile.jpeg'): userPhoto} alt="" /></a>
               <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/UserProfileBasicBlock?tab=mylisting`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      navigation("UserProfileBasicBlock", "?tab=mylisting") 
                    }
                  }}>
            <ProfileHeader >{storedFullName}</ProfileHeader></a></>
            :
            <>
              <div>
              <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/EmailAccountLoginBlock`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      navigation("EmailAccountLoginBlock")
                    }
                  }}>
                <div  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <div>
                    <img src={login} alt="" />
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <p style={styles.Myaccount}>My Account</p>
                    <p style={styles.register}>Register / Login</p>
                  </div>
                </div>
                </a>
              </div>
            </>
          }
        </IconAllImage>
      </Container>
    </Box>
  );
}

const styles = {
  Myaccount: {
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    color: "#676767",
    margin: '0'
  },
  register: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    color: "#B2B2B2",
    margin: '0'
  }
};
