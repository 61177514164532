import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  Link,
  styled,
  CircularProgress
} from "@material-ui/core";
import { createTheme, ThemeProvider, } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { logo } from "./assets";
import ForgotPasswordWeb from "../../forgot-password/src/ForgotPassword.web";

const FormGrid = styled(Grid)({
  height: '100vh',
  overflow: 'hidden',
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  '@media(max-width: 960px)': {
    overflow: "auto",
  },
});
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: '8px 15px',
    fontSize: 14
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});
const placeholderStyle = {
  fontSize: "18px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};
const AgreeBoxError = styled(Box)({
  borderLeft: "3px solid #FE4023",
  background: "rgba(254, 64, 35, 0.20)",
  padding: "14px 12px",
  fontSize: 16,
  lineHeight: "20px",
  color: "#FE4023",
  fontFamily: "'Segoe UI",
  marginBottom: 32
});
const FormDataGrid = styled(Grid)({
  '@media(max-width: 960px)': {
    width: '100vw',
    height: 'unset'
  },
  alignItems: "center"
});
const Customimg = styled(Grid)({
  '@media(max-width: 960px)': {
    display: 'none'
  },
  alignItems: "center",
});
const LogoImg = styled('img')({
  cursor: "pointer",
  position: "absolute",
  top: "43px",
  width: "220px",
  marginBottom: "15px",
  marginLeft: "72px",
  '@media(max-width: 1260px)': {
    position: "unset",
    marginTop: "50px",
  },
});
const LeftMainDiv = styled(Box)({
  maxWidth: '360px',
  margin: '0 auto',
  padding: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media(max-width: 1260px)': {
    top: "60px",
  },
  '@media(min-width: 1260px)': {
    minHeight: '100vh',
  },
});

const webStyle = {
  loader: {
    color: "#fff",
    marginTop: "3px",
    marginLeft: "10px",
  },
  leftBox: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },

  label: {
    fontSize: "20px",
    marginTop: "16px",
    letterSpacing: "0em",
    lineHeight: "24px",
    color: "#676767",
    textAlign: "left",
    fontFamily: "Segoe UI Bold",
    paddingBottom: 4
  },
  btnLogin: {
    borderRadius: "8px",
    width: "100%",
    fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    height: "40px",
    marginTop: "20px",
    backgroundColor: '#3CAA49',
    fontSize: "16px",
    cursor: "pointer"
  },

  haveAcc: {
    color: "#0F172A",
    fontSize: "18px",
    fontFamily: "Segoe UI",
    lineHeight: "24px",
  },
  rightHeading: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "25px",
    letterSpacing: "0em",
    lineHeight: "42px",
    textAlign: "left",
    marginBottom: 20
  },
  signlink: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer"
  },
  signlinks: {
    color: "#3CAA49",
    fontFamily: "Segoe UI",
    marginLeft: "5px",
    fontSize: "16px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  Imglogo: {
    textAlign: "right"
  },
  errorMessage: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  errorMessages: {
    color: "#6767673",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
};

const LoginButton = styled(Button)({
  ...webStyle.btnLogin,
  cursor: "pointer",
  textTransform: "initial",
  color: "#fff",
  "&.MuiButton-root:hover": {
    backgroundColor: '#3CAA49',
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "rgb(110 207 121)",
  }
});
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
    this.state = {
      ...this.state,
      password: "",
      showPassword: false,
      passwordMatchError: false,
      email: "",
      emailError: false,
      passwordError: false,
      emailFocused: false,
      emailBlurred: false,
      finishedTyping: false,
    };    
    // Customizable Area End
}
 // Customizable Area Start
 renderErrorMessage(message: any) {
  if (message !== "No account registered with this email") {
    return (
      <Typography style={webStyle.errorMessage as React.CSSProperties}>
        {message}
      </Typography>
    );
  }
  else return null
}
data() {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress style={{ color: '#4fa94d' }} />
      </Box>
    </>
  )
}
PasswordsData() {
  return (
    <Typography style={webStyle.label as React.CSSProperties}>
      Password <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
    </Typography>
  )
}
renderPasswordError() {
  if (this.state.passwordError) {
    return this.renderErrorMessage('Password is required');
  }
  return null;
} 
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box>

          <FormGrid container>
            <FormDataGrid item xs={12} md={6} >
              <div>
                <LogoImg src={logo} data-test-id="redirect" onClick={this.onRedirect}/>
              </div>
              <LeftMainDiv>
                <Typography style={webStyle.rightHeading as React.CSSProperties}>
                  Welcome Back!
                </Typography>
                {this.state.checkboxErrorAgree && this.state.noAccountregistered == "No account registered with this email" ? (
                  <AgreeBoxError>
                    {this.state.noAccountregistered}
                  </AgreeBoxError>
                ) :
                  null
                }
                <Typography style={webStyle.label as React.CSSProperties}>
                  Email<span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                </Typography>

                <CustomTextField
                  variant="outlined"
                  placeholder="Your email"
                  type="email"
                  value={this.state.email}
                  style={{
                    width: "100%"
                  }}
                  onChange={this.handleEmailChange}
                  onFocus={this.handleEmailFocus}
                  InputProps={{
                    style: placeholderStyle,
                  }}
                />
                <>
                  {this.state.emailErrorMsg && (
                    <Typography
                      style={webStyle.errorMessage as React.CSSProperties}
                    >
                      {this.state.emailErrorMsg}
                    </Typography>
                  )}
                </>
                {this.PasswordsData()}
                <CustomTextField
                  variant="outlined"
                  placeholder="Your password"
                  type={this.state.showPassword ? "text" : "password"}
                  style={{ width: "100%" }}
                  onChange={this.handlePasswordChange}
                  value={this.state.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.togglePasswordVisibility}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: placeholderStyle,
                  }}
                />
                {this.renderPasswordError()}
                {this.renderErrorMessage(this.state.noAccountregistered)}


                <Typography style={{ textAlign: 'end', marginTop: 5 }}>
                  <Link style={webStyle.signlinks as React.CSSProperties}
                    onClick={this.handleForgotPassword}>
                    Forgot password?
                  </Link>
                </Typography>
                <LoginButton
                  disabled={this.state.loading}
                  onClick={this.handleCreateAccount}
                >
                  Log in
                 {this.state.loading && <CircularProgress size={13} style={webStyle.loader} />}
                </LoginButton>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    alignContent: "center",
                    justifyContent: "center"
                  }}
                >
                  <Typography style={webStyle.haveAcc}>
                    Don’t have an account?
                  </Typography>
                  <Typography
                    style={webStyle.signlink as React.CSSProperties}
                    onClick={this.handleClickLogin}
                  >
                    Sign up
                  </Typography>
                </div>
              </LeftMainDiv>
            </FormDataGrid>
            <Customimg item xs={12} md={6} style={webStyle.leftBox}>
            {this.state.LoaderImages ?
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.data}
          </div> :
              <div style={webStyle.Imglogo as React.CSSProperties}>
                <img
                  src={this.state.mainImage}
                  style={{ width: '100%',height: '100vh', objectFit: 'cover'}}
                />
              </div>
  }
            </Customimg>
          </FormGrid>
        </Box>
        <ForgotPasswordWeb
          navigation={this.props.navigation}
          dataPass={this.state.passModalOPen}
          closeModal={this.handleClosePass}
        />
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
