import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  // Customizable Area Start
}

export interface S {
  // Customizable Area Start
  otpAuthToken: string;
  data: any[]
  myListing: any[];
  imagePreviews: any,
  selectedFiles: any,
  listingTitle: string,
  otherdata: string,
  budgetData: any,
  commissionData: any,
  categoryData: any,
  subcategoryData: any,
  locationData: any,
  descriptionData: any,
  preferencesData: any,
  selectedCategory: any,
  selectedSubCategory: any,
  categoryApiData: any[],
  subCategoryApiData: any[],
  categoryErrorData: any,
  subCategoryErrorData: any,
  criteriaList: any[],
  termsPurchase: any,
  listingType: any,
  storeIdSubDeletes: object,
  storeIdSubDelete: object,
  listingTitleError: string,
  budgetDataError: string,
  budgetDataErrorMessage: string,
  commissionDataErrorMessage: string,
  commissionDataError: string,
  commissionDataMessage: string,
  categoryDataError: string,
  subcategoryDataError: string,
  locationDataError: string,
  descriptionDataError: string,
  preferencesDataError: string,
  selectedCategoryError: string,
  selectedSubCategoryError: string,
  categoryApiDataError: string,
  subCategoryApiDataError: string,
  otherdataError: string,
  termsPurchaseError: string,
  selectedCriteriaListError: string,
  imagePreviewsError: string,
  isCreateButtonClicked: boolean,
  showDeletePopup: boolean,
  LoaderSelling: boolean,
  showPopup: boolean;
  storeIdss: any;
  popUpMsg: any;
  categoryIDs: any,
  subCategoryIDs: any,
  DeteleItems: any[],
  criteriaAdd: any[],
  criteriaTitle: any,
  removeImage: any,
  [key: string]: any;
  listingTitlesAlreadys: string[],
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SellingSomethingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiSellingSomethingCallId: string = "";
  apiCategoryCallId: string = "";
  apiSubCategoryCallId: string = "";
  apiEditListingSomethingCallId: string = "";
  apiViewListingItemCallId: string = "";
  apiDeletesListingCallIds: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      otpAuthToken: "",
      data: [],
      myListing: [],
      imagePreviews: [],
      imagePreviewsError: "",
      selectedFiles: [],
      listingTitle: "",
      otherdataError: "",
      otherdata: "",
      budgetData: "",
      commissionData: "",
      categoryData: "",
      subcategoryData: "",
      locationData: "",
      descriptionData: "",
      preferencesData: "",
      selectedCategory: "",
      selectedSubCategory: "",
      listingType: "",
      termsPurchase: "",
      categoryApiData: [],
      subCategoryApiData: [],
      criteriaList: [''],
      storeIdSubDeletes: {},
      storeIdSubDelete: {},
      categoryErrorData: "",
      subCategoryErrorData: "",
      listingTitleError: "",
      budgetDataError: "",
      budgetDataErrorMessage: "",
      commissionDataErrorMessage: "",
      commissionDataError: "",
      categoryDataError: "",
      commissionDataMessage: "",
      subcategoryDataError: "",
      locationDataError: "",
      descriptionDataError: "",
      preferencesDataError: "",
      selectedCategoryError: "",
      selectedSubCategoryError: "",
      categoryApiDataError: "",
      subCategoryApiDataError: "",
      termsPurchaseError: "",
      selectedCriteriaListError: "",
      showDeletePopup: false,
      isCreateButtonClicked: false,
      LoaderSelling: false,
      popUpMsg: "",
      storeIdss: 0,
      showPopup: false,
      DeteleItems: [],
      criteriaAdd: [],
      criteriaTitle: [],
      removeImage: [],
      categoryIDs: localStorage.getItem("selecteds") || null,
      subCategoryIDs: localStorage.getItem("selectedCategoriess") || null,
      listingTitlesAlreadys: [],
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiSellingSomethingCallId) {
        this.handleSellingSomethingResponse(responseJson);
      } else if (apiRequestCallId === this.apiCategoryCallId) {
        this.handleCategoryResponse(responseJson);
      } else if (apiRequestCallId === this.apiSubCategoryCallId) {
        this.handleSubCategoryResponse(responseJson);
      }
      else if (apiRequestCallId === this.apiViewListingItemCallId) {
        this.handleViewSellingListingResponses(responseJson)
      }
      else if (apiRequestCallId === this.apiEditListingSomethingCallId) {
        this.handleSellingEditResponses(responseJson)
      }
      else if (apiRequestCallId === this.apiDeletesListingCallIds) {
        this.handleSellingDeleteResponses(responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    window.scrollTo(0, 0);
    const content = this.props.navigation.getParam('id');
    this.categoryItem();
    if (content) {
      this.getJobsAppliedExecutiveDatasid(content)
    }
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleSellingSomethingResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ isCreateButtonClicked: true });
      this.setState({
        criteriaList: responseJson.data?.attributes.criteria_or_terms,
        isCreateButtonClicked: true
      });
    }
    if (responseJson.errors) {
      this.setState({listingTitlesAlreadys: responseJson.errors[0].title})
      if(this.state.listingTitlesAlreadys)
      toast.error("Title has been already taken");
    }
    if (responseJson?.meta?.success) {
      this.setState({ popUpMsg: responseJson?.meta?.message, showPopup: true });
      this.setState({
        listingTitle: "",
        budgetData: "",
        commissionData: "",
        categoryData: "",
        subcategoryData: "",
        locationData: "",
        descriptionData: "",
        preferencesData: "",
        selectedCategory: "",
        selectedSubCategory: "",
        imagePreviews: "",
        termsPurchase: [],
        categoryApiData: [],
        subCategoryApiData: [],
        criteriaList: [''],
        selectedFiles: []
      })

    }
  }

  handleCategoryResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        categoryApiData: responseJson?.data,
      });
      const content = this.props.navigation.getParam('id');
      this.getJobsAppliedExecutiveDatasid(content)
    }
  }

  handleSubCategoryResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        subCategoryApiData: responseJson?.data
      });
    }
  }

  handleViewSellingListingResponses(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState({ LoaderSelling: false });
      this.setState({
        listingTitle: responseJson.data.attributes.title,
        budgetData: responseJson.data.attributes.budget,
        commissionData: responseJson.data.attributes.commission,
        locationData: responseJson.data.attributes.location,
        descriptionData: responseJson.data.attributes.description,
        preferencesData: responseJson.data.attributes.preferences,
        selectedCategory: responseJson.data.attributes.category,
        selectedSubCategory: responseJson.data.attributes.sub_category,
        criteriaList: responseJson.data.attributes.criteria_or_terms,
        imagePreviews: responseJson.data.attributes.photo,
        listingType: responseJson.data.attributes.listing_type,
      }, () => {
        const categoryIddatas = this.state?.categoryApiData?.find((item) => item?.attributes?.name === responseJson?.data?.attributes?.category)?.id;
        this.subCategoryItem(categoryIddatas)
      }

      )
    }
  }
  handleSellingEditResponses(responseJson: any) {
    if(responseJson.message){
      toast.error(responseJson.message);
      return;
    }
    const content = this.props.navigation.getParam('id');
    this.setState({
      storeIdSubDeletes: responseJson.data
    }
      , () => this.props.navigation.navigate("ViewListing", { id: content })
    )
  }
  handleSellingDeleteResponses(responseJson: any) {
    this.setState({
      storeIdSubDelete: responseJson.data
    }, () =>
      this.props.navigation.navigate("UserProfileBasicBlock")
    )
  }
  updateDynamicState = (update: { [key: string]: any }) => {
    this.setState(update as S);
  };
  handleMyListing = () => {
    this.props.navigation.navigate("UserProfileBasicBlock");
  };

  handleKeepListSelling = () => {
    this.setState({ showDeletePopup: false });
  }

  handleCancel = () => {
    const datalook = this.props.navigation.getParam('id');
    if (datalook) {
      this.setState({ showDeletePopup: true });
    } else {
      this.props.navigation.navigate("CreateListing")
    }
    this.setState({ showDeletePopup: true });
  };

  handleDeleteSelling = () => {
    const content = this.props.navigation.getParam('id');
    this.changeDeleteOnclick(content);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  closePopup = () => {
    this.setState({ isCreateButtonClicked: false });
    this.props.navigation.navigate("UserProfileBasicBlock");
  };
  closePopupDeletePopup() {
    this.setState({ showPopup: false });
  };
  handleDelete = (index: number) => {
    if (this.state.criteriaList.length > 1) {
      const { criteriaList, DeteleItems } = this.state;
      const updatedCriteriaList = [
        ...criteriaList.slice(0, index),
        ...criteriaList.slice(index + 1)
      ];
      const updatedDeleteItems = [...DeteleItems, { id: criteriaList[index] }];
      this.setState({
        criteriaList: updatedCriteriaList,
        DeteleItems: updatedDeleteItems
      });
    }

  };

  handleCreateListing = () => {
    const requiredFields = [
      { name: "listingTitle", label: "Listing Title" },
      { name: "descriptionData", label: "Description" },
      { name: "budgetData", label: "Budget" },
      { name: "commissionData", label: "Reward" },
      { name: "locationData", label: "Location" },
      { name: "categoryData", label: "Category" },
      { name: "subCategoryData", label: "SubCategory" },
      { name: "imagePreview", label: "Image" },
    ];
    let hasEmptyString = this.state.criteriaList.some((criteria) => !criteria.title || criteria.title.trim() === "");

    if (hasEmptyString) {
      this.setState({ selectedCriteriaListError: "Term of purchase is required" });
    }
    if (this.state.selectedCategory.length == 0) {
      this.setState({ selectedCategoryError: "Category is required" })
    }
    if (this.state.selectedSubCategory.length == 0) {
      this.setState({ selectedSubCategoryError: "Sub Category is required" })
    }
    if (this.state.imagePreviews.length == 0) {
      this.setState({ imagePreviewsError: "Image is required" })
    }
    requiredFields.forEach((field: any) => {
      let name = field.name as keyof S;
      const fieldValue = this.state[name];
      if (!fieldValue || fieldValue.trim() === "") {
        this.updateDynamicState({ [name + "Error"]: `${field.label} is required` });
      }
    });

    if (
      this.state.listingTitle.trim() !== "" &&
      this.state.budgetData.trim() !== "" &&
      this.state.commissionData.trim() !== "" &&
      this.state.locationData.trim() !== "" &&
      this.state.selectedCategory.trim() !== "" &&
      this.state.selectedSubCategory.trim() !== "" &&
      this.state.descriptionData.trim()!== "" &&
      this.state.budgetDataErrorMessage === "" &&
      this.state.commissionDataErrorMessage === "" &&
      !hasEmptyString &&
      this.state.imagePreviews.length > 0

    ) {

      const content = this.props.navigation.getParam('id');
      if (content) {
        this.changeIdOnclickSelling(content);
      }
      else {
        this.sellingSomething()
        window.scrollTo(0, 0);
      }
    }
  };
  removeImage = (id: number | string | undefined | null, idx: number) => {
    if(!id) {
      const selectedFiles = [...this.state.selectedFiles];
      const imagePreviews = [...this.state.imagePreviews];
      const len = imagePreviews.filter((file) => !!file.id).length;
      imagePreviews.splice(idx, 1);
      selectedFiles.splice(idx - len, 1);
      this.setState({ imagePreviews, selectedFiles });
      return;
    }
    const index = this.state.imagePreviews?.findIndex((file: any) => file.id === id);
    if (index === -1 || !this.state.imagePreviews) {
      return;
    }
    const newImagePreviews = [...this.state.imagePreviews];
    newImagePreviews.splice(index, 1);

    this.setState({
      imagePreviews: newImagePreviews,
      removeImage: [...this.state.removeImage, { id }]
    });
  };

  handleAddCriteria = () => {
    const { criteriaList } = this.state;
    criteriaList.push('');
    this.setState({
      criteriaList,
    });

  };
  handleCriteriaChange = (index: number, value: any) => {
    this.setState((prevState) => {
      const criteriaList = Array.isArray(prevState.criteriaList) ? [...prevState.criteriaList] : [];
      while (criteriaList.length <= index) {
        criteriaList.push("");
      }
      criteriaList[index] = { ...criteriaList[index], title: value };
      const hasEmptyString = criteriaList.some((criteria) => !criteria.title || criteria.title.trim() === "");
  
      return {
        criteriaList,
        selectedCriteriaListError: hasEmptyString ? "Term of purchase is required" : "",
      };
    });
  };



  handleInputChange = (event: any) => {
    if (!event.target.name) {
      return;
    }

    const { name, value } = event.target;
    let datasss = name === 'selectedCategory' ? this.state.categoryApiData : this.state.subCategoryApiData;

    const selectedDatas = datasss.find((item) => item?.attributes?.name === value);
    const categoryIds = selectedDatas?.id ?? "";
    if (name == 'selectedCategory') {
      this.setState(
        {
          selectedCategory: categoryIds,
          selectedSubCategory: '',
          categoryIDs: categoryIds,
        },
        () => {
          localStorage.setItem("selecteds", categoryIds)
          this.subCategoryItem(categoryIds);
        }
      );
    }
    if (name == 'selectedSubCategory') {
      this.setState(
        {
          subCategoryIDs: categoryIds,
        }, () => {
          localStorage.setItem("selectedCategoriess", categoryIds)
        }
      );
    }
    const requiredFields = [
      { name: "listingTitle", label: "Listing Title" },
      { name: "descriptionData", label: "Description" },
      { name: "budgetData", label: "Budget" },
      { name: "commissionData", label: "Reward" },
      { name: "locationData", label: "Location" },
      { name: "categoryData", label: "Category" },
      { name: "subCategoryData", label: "SubCategory" },
      { name: "imagePreview", label: "Image" },
    ];

    const handleListingTitleChange = (value:string) => {
      const regex = /^[A-Za-z0-9][A-Za-z0-9\s]*$|^$/;
      if (regex.test(value)) {
        this.setState({ listingTitle: value, listingTitleError: value.trim() === "" ? "Listing Title is required" : "" });
      }
    };

  const handleBudgetCommissionChange = (value: string) => {
    const regexBudget = /^\d+(\.\d{0,2})?$/;
    if (!regexBudget.test(value) && value !== "") {
      return;
    }

    if (name === 'budgetData') {
      const budget = parseFloat(value || '0');
      if (budget > 1000000) {
        this.setState({ budgetData: value, budgetDataErrorMessage: "Budget should not be more than £1,000,000" });
        return;
      } else {
        this.setState({ budgetDataErrorMessage: "" });
      }
    }

    if (name === 'commissionData') {
      const commission = parseFloat(value || '0');
      if (commission > 100000) {
        this.setState({ commissionData: value, commissionDataErrorMessage: "Reward should not be more than £100,000" });
        return;
      } else {
        this.setState({ commissionDataErrorMessage: "" });
      }
      const budget = parseFloat(this.state.budgetData || '0');
      if (commission > budget) {
        this.setState({ commissionData: value, commissionDataErrorMessage: "Reward should not be more than the budget" });
        return;
      } else {
        this.setState({ commissionDataErrorMessage: "" });
      }
    }

    let updates: any = { [name]: value, [name + "Error"]: "" };
    this.setState(updates);
  };

    const handleOtherFieldChange = (value: string) => {
      let values: any = { [name]: value };
      this.setState({ ...values, [name + "Error"]: "" });
    };
    const handleRequiredFields = (value: string) => {
      const field = requiredFields.find((field) => field.name === name);
      if (value === "" && field) {
        this.updateDynamicState({ [name + "Errors"]: `${field.label} is required` });

      } else {
        let values: any = { [name + "Errors"]: "" }
        this.setState(values);
      }
    };
    this.setState({listingTitlesAlreadys : []})


    switch (name) {
      case "listingTitle":
        handleListingTitleChange(value);
        break;
      case "budgetData":
      case "commissionData":
        handleBudgetCommissionChange(value);
        break;
      default:
        handleOtherFieldChange(value);
    }
    handleRequiredFields(value);
  };


  sellingSomething = async () => {
    const token = localStorage.getItem("authToken") as string;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSellingSomethingCallId = requestMessage.messageId
    const formData = new FormData();

    for (const selectedFile of this.state.selectedFiles) {
      formData.append('photo[]', selectedFile);
    }
    this.state.criteriaList.forEach((criteria, index) => {
      if( criteria.title !== undefined)
      formData.append(`criterias_attributes[${index}][title]`, criteria.title);
    });

    formData.append("title", this.state.listingTitle);
    formData.append("budget", this.state.budgetData);
    formData.append("commission", this.state.commissionData);
    formData.append("location", this.state.locationData);
    formData.append("description", this.state.descriptionData);
    formData.append("preferences", this.state.preferencesData);
    formData.append("category_id", this.state.categoryIDs);
    formData.append("sub_category_id", this.state.subCategoryIDs);
    formData.append("listing_type", "selling_for_something");
    formData.append("token", token);


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_locallistingads/local_listing_ads"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Authorization": token,
      }));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  categoryItem = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCategoryCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/categories/categories"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  subCategoryItem = async (categoryId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubCategoryCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_categories/categories/${categoryId}/show_subcategories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addFormData = (formData1: FormData) => {
    this.state.categoryIDs && formData1.append("category_id", this.state.categoryIDs);
    this.state.subCategoryIDs && formData1.append("sub_category_id", this.state.subCategoryIDs);
  }

  changeIdOnclickSelling = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Authorization": `Bearer ${token}`,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditListingSomethingCallId = requestMessage.messageId
    const formData1 = new FormData();
    for (const selectedFile of this.state.selectedFiles) {
      formData1.append('photo[]', selectedFile);
    }
    formData1.append("title", this.state.listingTitle);
    formData1.append("budget", this.state.budgetData);
    formData1.append("commission", this.state.commissionData);
    formData1.append("location", this.state.locationData);
    formData1.append("description", this.state.descriptionData);
    formData1.append("preferences", this.state.preferencesData);
    this.addFormData(formData1);
    if (this.state.criteriaList.length > 0) {
      this.state.criteriaList.forEach((criteria, index) => {
        if (criteria.id) {
          formData1.append(`criterias_attributes[${index}][title]`, criteria.title);
          formData1.append(`criterias_attributes[${index}][id]`, criteria.id);
        } else if (!criteria.id && criteria.title) {
          formData1.append(`criterias_attributes[${index}][title]`, criteria.title);
        }
      });
    }
    if (this.state.DeteleItems.length > 0) {
      this.state.DeteleItems.forEach((criteria, index) => {
        if (criteria.id) {
          formData1.append(`criterias_attributes[${index}][id]`, criteria.id);
          formData1.append(`criterias_attributes[${index}][_destroy]`, "true");
        }
      });
    }
    if (this.state.removeImage.length > 0) {
      this.state.removeImage.forEach((ids: any) => {
        if (ids.id) {
          formData1.append(`remove_imgs[]`, ids.id);
        }
      });
    }

    formData1.append("listing_type", this.state.listingType);
    formData1.append("token", token);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/local_listing_ads/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData1
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getJobsAppliedExecutiveDatasid = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewListingItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/local_listing_ads/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ LoaderSelling: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  changeDeleteOnclick = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeletesListingCallIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/local_listing_ads/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}