import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  SafeAreaView,
} from "react-native";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { Box, Button, styled, Card, Link, Typography, Grid, CircularProgress, } from "@material-ui/core";
import { Jewellery, LookBG, SellBG, car,others, cloths, help, findCollection, imageBorder, imageBorderRight, lamp, sky, imageFilledHeart, imageEmptyhearts } from "./assets";
//@ts-ignore
import Slider from "react-slick";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import Carousel from 'react-elastic-carousel';
const ShoppingCardsAdd = styled(Box)({
  maxWidth: "186px",
  width: '100%',
  borderRadius: "8px",
  padding: "20px 21.5px 17px 21.5px",
  justifyContent: 'space-between',
  backgroundColor: "#FFFFFF",
  flexWrap: 'wrap',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
});
const ShoppingCardsSellingAdd = styled(Box)({
  width: "80%",
  height: "359px",
  borderRadius: "8px",
  padding: "20px 21.5px 17px 21.5px",
  justifyContent: 'space-between',
  backgroundColor: "#FFFFFF",
  flexWrap: 'wrap',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer"
});
const CardImageAdd = styled("img")({
  width: "100%",
  height: "211px",
  objectFit: "contain",
  borderRadius: "5px",
  "@media(max-width:1150px)": {
    width: '100%',
  },
});
const BodyTypographyMain = styled("p")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  color: '#676767',
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
const BudgetTypographyAdd = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: "5px 0",
  color: "#676767"
});
const StyledSpanAdd = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const CommissionTypographyAdd = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});
const BodyTypographyAdd = styled("p")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  color: '#676767',
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const LookingForSomethingAdd = styled('div')({
  borderColor: '#CBD5E1',
  borderRadius: "8px",
  height: "500px",
  backgroundImage: `url(${LookBG})`,
  boxShadow: "inset 10px -12px 100px 119px rgba(0,0,0,0.59)",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  border: '0.5px solid #CBD5E1',
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  alignSelf: "end",
  color: "#FFFFFF",
  position: 'relative',
  zIndex: 1

});
const CommissionTypographyPageAdd = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});
const SellingForSomethingAdd = styled('div')({
  height: "500px",
  backgroundImage: `url(${SellBG})`,
  boxShadow: "inset 10px -12px 100px 119px rgba(0,0,0,0.59)",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: "8px",
  backgroundRepeat: 'no-repeat',
  border: '0.5px solid #CBD5E1',
  alignSelf: "end",
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  color: "#FFFFFF",
  position: 'relative',
  zIndex: 1
});
const WishlistTextAdd = styled('div')({
  marginTop: "20px",
  fontSize: "16px",
  color: "#FFFFFF"
});
const StyledSpanPageAdd = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const LookingTextAdd = styled("p")({
  width: '100%',
  fontFamily: "Segoe UI Bold",
  fontSize: "28px",
  lineHeight: "54px",
  margin: 0,
});
const SaveImagesAdd = styled("img")({
  width: "29px",
  height: "29px",
  cursor: "pointer",
});
const ExperienceTextAdd = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "18px",
  lineHeight: "24px",
  marginTop: '10px',
  margin: 0,
  color: "#FFFFFF",
  textAlign: 'start',
});
const ContactusAdd = styled(Box)({
  height: "450px",
  backgroundImage: `url(${help})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  "@media(max-width:768px) and (min-width: 426px)": {
    height: "700px",
  },
  "@media(max-width:960px) and (min-width: 769px)": {
    height: "800px",
  },
  "@media(max-width:425px) and (min-width: 0px)": {
    height: "800px",
  },
});

const ContactusGridAdd = styled(Grid)({
  "@media(max-width:958px) and (min-width: 0px)": {
    textAlign: "center",
  },
});
const CreateListingButtonssAdd = styled(Button)({
  borderRadius: "4px",
  width: "100%",
  maxWidth: '279px',
  fontFamily: "Segoe UI Bold",
  color: "#FFFFFF",
  padding: '10px 44px 10px 44px',
  marginTop: "20px",
  backgroundColor: '#3CAA49',
  fontSize: "18px",
  cursor: "pointer",
  textTransform: 'none',
  gap: 10,
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#3CAA49',
  },
  "@media (max-width: 767px)": {
    padding: "10px 20px",
    fontSize: "14px",
    width: "unset",
  },
  "@media (max-width: 450px)": {
    padding: "10px 10px",
    fontSize: "12px",
    width: "unset",
  },
});
const CreateListingButtonsssAdd = styled(Button)({
  borderRadius: "4px",
  width: "100%",
  maxWidth: '279px',
  fontFamily: "Segoe UI Bold",
  color: "#FFFFFF",
  padding: '10px 44px 10px 44px',
  marginTop: "20px",
  backgroundColor: '#3CAA49',
  fontSize: "18px",
  cursor: "pointer",
  gap: 10,
  alignItems: 'center',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#3CAA49',
  },
  "@media (max-width: 767px)": {
    padding: "10px 20px",
    fontSize: "14px",
    width: "unset",
  },
  "@media (max-width: 450px)": {
    padding: "10px 10px",
    fontSize: "12px",
    width: "unset",
  },
});

const OverLayBox = styled(Box)({
  background: 'linear-gradient(63.47deg, #000000 14.99%, rgba(24, 24, 24, 0.03) 81.22%)',
  width: '100%',
  height: '100%',
  position: 'absolute',
  borderRadius: "12px",
});
const OverLayBoxCards = styled(Box)({
  background: 'linear-gradient(63.47deg, #000000 14.99%, rgba(24, 24, 24, 0.03) 81.22%)',
  width: '100%',
  height: '100%',
  position: 'absolute',
});

interface ArrowProps {
  type: 'PREV' | 'NEXT';
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  isEdge: boolean;
}
// Customizable Area End

import LandingPageMainController, {
  Props,
} from "./LandingPageMainController";

export default class LandingPageMain extends LandingPageMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLookingForSomethingSectionMain() {
    const lookingForSomethingData = this.state.LookingSomeDataAdd?.filter(item => item.attributes.listing_type === "looking_for_something").slice(0, 5);
    return (<>
      <Box style={styleD.mapFNAdd as React.CSSProperties}>
        <Box style={styleD.lookLoadMain}>
          {this.state.LoaderListingAdd ?
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '30vh',
                margin: '0 auto'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ color: '#4fa94d' }} />
              </Box>
            </div> : 
            <>
              {
                 (!lookingForSomethingData || lookingForSomethingData.length === 0) ? (
                  <div style={{ fontFamily: "Segoe UI",fontSize: "20px", lineHeight: "31.92px", color: "#676767", margin: '0 auto',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                  height: "300px",
                }}>You haven't created any list of Looking for something.</div>
                ) :
                (
              lookingForSomethingData?.map((item: any) => {
                return (
                  <>
                      <ShoppingCardsAdd key={item.id} >
                      <a  key={item.id} data-testid="viewHome" style={{textDecoration:"none"}} href={`/ViewListing/${item.id}`} onClick={(event) => {
                             
                                event && event.preventDefault();
                                this.viewDataOnHomeAdd(item.id)
                              
                            }}>
                        <CardImageAdd
                          src={item.attributes?.photo[0]?.url}
                          alt="Card Image"
                        />
                        <BodyTypographyAdd>{item.attributes.title}</BodyTypographyAdd>
                        <BudgetTypographyAdd>
                          Budget <StyledSpanAdd>£ {item.attributes.budget}</StyledSpanAdd>
                        </BudgetTypographyAdd></a>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <CommissionTypographyAdd>
                            Reward
                            <StyledSpanAdd> £ {item.attributes.commission}</StyledSpanAdd>
                          </CommissionTypographyAdd>
                          <SaveImagesAdd
                            src={this.state.savedListingsDataAdd?.includes(item.id)
                              ? imageFilledHeart
                              : imageEmptyhearts}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.viewDataOnHomeAddWishlist()
                            }}
                            alt="Card Image"
                            data-testid="save-image12"
                          />
                        </div>
                      </ShoppingCardsAdd>
                  </> 
                )
              })
                )
              }
              </>
              }

        </Box>
      </Box>
    </>)
  }
  renderSellingSomethingSectionMain() {
    const sellingSomethingData = this.state.someListingDataAdd?.filter(item => item.attributes.listing_type === "selling_for_something").slice(0, 6);

    return (<>
      <Grid container style={{ marginTop: "20px" }} spacing={4}>
        <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
          <img src={findCollection} alt="" style={styleD.imgSellAdd as React.CSSProperties} />
        </Grid>
        <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            {this.state.LoaderListingAdd ?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  margin: '0 auto'
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress style={{ color: '#4fa94d' }} />
                </Box>
              </div> : <>
                {
                    (!sellingSomethingData || sellingSomethingData.length === 0) ? (
                      <div style={{ fontFamily: "Segoe UI",fontSize: "20px", lineHeight: "31.92px", color: "#676767", margin: '0 auto',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'center',
                     marginTop: "400px"
                    }}>You haven't created any list of selling something.</div>
                    ) : 
                  (
                  sellingSomethingData?.map((item: any) => {
                    return (
                      <>
                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                          <ShoppingCardsSellingAdd key={item.id}
                           >
                               <a  key={item.id}  data-testid="viewHomesell" style={{textDecoration:"none"}} href={`/ViewListing/${item.id}`} onClick={(event) => {
                             
                                event && event.preventDefault();
                                this.viewDataOnHomeAdd(item.id)
                             
                            }}>
                            <CardImageAdd
                              src={item.attributes?.photo[0]?.url}
                              alt="Card Image"
                            />
                            <BodyTypographyMain>{item.attributes.title}</BodyTypographyMain>
                            <BudgetTypographyAdd>
                              Price <StyledSpanPageAdd> £ {item.attributes.budget}
                              </StyledSpanPageAdd>
                            </BudgetTypographyAdd></a>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                              }}
                            >
                              <CommissionTypographyPageAdd>
                                Reward
                                <StyledSpanPageAdd> £ {item.attributes.commission}
                                </StyledSpanPageAdd>
                              </CommissionTypographyPageAdd>
                              <SaveImagesAdd
                                src={this.state.savedListingsDataAdd?.includes(item.id)
                                  ? imageFilledHeart
                                  : imageEmptyhearts}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.viewDataOnHomeAddWishlist()
                                }}
                                alt="Card Image"
                                data-testid="save-image11"
                              />
                            </div>
                          </ShoppingCardsSellingAdd>
                        </Grid>
                      </>
                    )
                  })
                  )
                  }

              </>}
          </Grid>

        </Grid>
      </Grid>
    </>)
  }
  renderLandingText() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
        <Typography data-test-id="test-0" style={styleD.menuMainAdd} onClick={(e)=> {e.preventDefault(); this.AllFilterLandingPagesMains1()}}> <Link href="/FilterAllCategory" style={styleD.menuItemAdd as React.CSSProperties}>All</Link></Typography>
        <Typography data-test-id="test-8" style={styleD.menuMainAdd} onClick={(e)=> {e.preventDefault(); this.AllFilterLandingPagesMains(13)}}> <Link href="/FilterAllCategory/13" style={styleD.menuItemAdd as React.CSSProperties}>Vehicles</Link></Typography>
        <Typography data-test-id="test-7" style={styleD.menuMainAdd} onClick={(e)=> {e.preventDefault(); this.AllFilterLandingPagesMains(7)}}> <Link href="/FilterAllCategory/7" style={styleD.menuItemAdd as React.CSSProperties}>Jewellery</Link></Typography>
        <Typography data-test-id="test-6" style={styleD.menuMainAdd} onClick={(e)=> {e.preventDefault(); this.AllFilterLandingPagesMains(8)}}> <Link href="/FilterAllCategory/8" style={styleD.menuItemAdd as React.CSSProperties}>Clothing</Link></Typography>
        <Typography data-test-id="test-9" style={styleD.menuMainAdd} onClick={(e)=> {e.preventDefault(); this.AllFilterLandingPagesMains(9)}}> <Link href="/FilterAllCategory/9" style={styleD.menuItemAdd as React.CSSProperties}>Interiors</Link></Typography>
        <Typography data-test-id="test-10" style={styleD.menuMainAdd} onClick={(e)=> {e.preventDefault(); this.AllFilterLandingPagesMains(10)}}> <Link href="/FilterAllCategory/10" style={styleD.menuItemAdd as React.CSSProperties}>Services</Link></Typography>
        <Typography data-test-id="test-11" style={styleD.menuMainAdd} onClick={(e)=> {e.preventDefault(); this.AllFilterLandingPagesMains(11)}}> <Link href="/FilterAllCategory/11" style={styleD.menuItemAdd as React.CSSProperties}>Others</Link></Typography>
      </div>
    )
  }
  renderDiscover = () => {
    return (
      <Box style={styleD.plateformContainerAdd as React.CSSProperties}>
        <span style={styleD.discoverAdd as React.CSSProperties}>Discover Your</span> <span style={styleD.dreamAdd as React.CSSProperties}>Dream Collection</span>
      </Box>
    )
  }

  renderArrow = ({ type, onClick, isEdge }: ArrowProps) => {
    const pointer = type === "PREV" ? <ArrowBackIosOutlinedIcon /> : <ArrowForwardIosOutlinedIcon/>;
    return (
      <Button onClick={onClick} disabled={isEdge} className="carousel-button">
        <Box className={`arrow ${isEdge && "isDisabled"}`}>
          {pointer}
        </Box>
      </Button>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    this.handleFullNameMain()
    // Customizable Area End
    return (
      <SafeAreaView style={styles.mainContainer}>
        {/* Customizable Area Start */}
        <AppHeader navigation={this.props.navigation.navigate} />
        <Box style={{ maxWidth: "1440px", margin: '0 auto' }}>
          {this.renderLandingText()}

          <Box style={styleD.MainPlateformAdd as React.CSSProperties}>
            {this.renderDiscover()}
            <Typography style={styleD.findAdd as React.CSSProperties}>Find your all favourites at single platform</Typography>
            <Box style={{ textAlign: "center" }}>
            <a data-testid="explore" href={`/FilterAllCategory`} onClick={(event) => {
              event.preventDefault();
              this.handleListingExploreClick()
            }}>
              <Button  style={styleD.plateformExploreBtnAdd as React.CSSProperties} >Explore</Button></a>
              <a  data-testid="handleListing" href={`/CreateListing`} onClick={(event) => {
                event.preventDefault();
                this.handleListingAdd()
              }}>
              <Button style={styleD.plateformListingBtnAdd as React.CSSProperties}>Start Listing</Button></a>
            </Box>
          </Box>
          <Grid container>
            <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
            <a href="/FilterAllCategory/10"  onClick={(e) => {e.preventDefault(); this.AllFilterLandingPagesMains(10)}} data-test-id="nav-10">

              <Box>
                <Card style={styleD.cardMainAdd as React.CSSProperties}>
                  <img src={sky} alt="" style={styleD.imgCardAdd as React.CSSProperties} />
                  <Typography style={styleD.imgTextAdd as React.CSSProperties}>Services</Typography>
                </Card>
              </Box></a>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
            <a href="/FilterAllCategory/9" onClick={(e) => {e.preventDefault(); this.AllFilterLandingPagesMains(9)}} data-test-id="nav-9">
              <Box>
                <Card style={styleD.cardMainAdd as React.CSSProperties}>
                  <img src={lamp} alt="" style={styleD.imgCardAdd as React.CSSProperties} />
                  <Typography style={styleD.imgTextAdd as React.CSSProperties}>Interiors</Typography>
                </Card>
              </Box></a>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
            <a href="/FilterAllCategory/13" onClick={(e) => {e.preventDefault(); this.AllFilterLandingPagesMains(13)}} data-test-id="nav-8">

              <Box>
                <Card style={styleD.cardMainAdd as React.CSSProperties}>
                  <img src={car} alt="" style={styleD.imgCardAdd as React.CSSProperties} />
                  <Typography style={styleD.imgTextAdd as React.CSSProperties}>Vehicles</Typography>
                </Card>
              </Box></a>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
            <a href="/FilterAllCategory/7"  onClick={(e) => {e.preventDefault(); this.AllFilterLandingPagesMains(7)}} data-test-id="nav-7">

              <Box>
                <Card style={styleD.cardMainAdd as React.CSSProperties}>
                  <img src={Jewellery} alt="" style={styleD.imgCardAdd as React.CSSProperties} />
                  <Typography style={styleD.imgTextAdd as React.CSSProperties}>Jewellery</Typography>
                </Card>
              </Box></a>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
            <a href="/FilterAllCategory/8"  onClick={(e) => {e.preventDefault(); this.AllFilterLandingPagesMains(8)}} data-test-id="nav-6">

              <Box>
                <Card style={styleD.cardMainAdd as React.CSSProperties}>
                  <img src={cloths} alt="" style={styleD.imgCardAdd as React.CSSProperties} />
                  <Typography style={styleD.imgTextAdd as React.CSSProperties}>Clothing</Typography>
                </Card>
              </Box></a>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
            <a href="/FilterAllCategory/11" onClick={(e) => {e.preventDefault(); this.AllFilterLandingPagesMains(11)}} data-test-id="nav-11">

              <Box>
                <Card style={styleD.cardMainAdd as React.CSSProperties}>
                  <img src={others} alt="" style={styleD.imgCardAdd as React.CSSProperties} />
                  <Typography style={styleD.imgTextAdd as React.CSSProperties}>Others</Typography>
                </Card>
              </Box></a>
            </Grid>
          </Grid>
          <Box style={{ marginTop: "70px" }}>
            <Box style={styleD.containersAdd as React.CSSProperties}>
              <Grid container spacing={5} style={{ marginTop: "20px" }}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                  <LookingForSomethingAdd>
                    <OverLayBox />
                    <div style={{ padding: "30px", zIndex: 1, position: "relative"}}>
                    <LookingTextAdd>Looking for Something?</LookingTextAdd>
                    <ExperienceTextAdd>Experience the ultimate convenience of finding all your favourites in one place.
                      <WishlistTextAdd>
                        <li>Create a Wishlist. </li>
                        <li>Connect with Provisioner for purchase. </li>
                        <li>Get your product. Enjoy!</li>
                      </WishlistTextAdd>
                    </ExperienceTextAdd>
                    <div>
                    <a   data-testid="looking" style={{textDecoration:"none"}} href={`/LookingSomething`} onClick={(event) => {
                      event.preventDefault(); 
                      this.LookingNavigationAdd()
                    }}>
                      <CreateListingButtonssAdd
                        key={"1"}
                      
                      >
                        Create Listing
                      </CreateListingButtonssAdd></a>
                    </div>
                    </div>
                  </LookingForSomethingAdd>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <SellingForSomethingAdd>
                    <OverLayBox />
                    <div style={{ padding: "30px", zIndex: 1, position: "relative"}}>
                    <LookingTextAdd>Selling Something?</LookingTextAdd>
                    <ExperienceTextAdd>Facilitate a seamless journey for individuals to achieve their dream Wishlist effortlessly.
                      <WishlistTextAdd>
                        <li>Create a product List.</li>
                        <li>Get in contact with buyer.</li>
                        <li> Provide hassle-free service. Get Reviews.</li>
                      </WishlistTextAdd>
                    </ExperienceTextAdd>
                    <div style={{}}>
                    <a    data-testid="seeling" style={{textDecoration:"none"}} href={`/SellingSomething`} onClick={(event) => {
                      event.preventDefault();
                      this.SellingNavigationAdd();
                    }}>
                      <CreateListingButtonsssAdd
                        key={"2"}
                       
                     
                      >
                        Create Listing
                      </CreateListingButtonsssAdd></a>
                    </div>
                    </div>
                  </SellingForSomethingAdd>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box style={styleD.lookingMainAdd as React.CSSProperties}>
            <Box style={styleD.containersAdd as React.CSSProperties}>
              <Grid style={styleD.lookingHeaderAdd as React.CSSProperties}>
                <Typography style={styleD.lookingTextAdd as React.CSSProperties}>Looking For Something</Typography>
                <a data-testid="viewAll1" style={styleD.linkViewAdd as React.CSSProperties} href="/FilterAllCategory?lookingViewAll=true" onClick={(event) => {
                event.preventDefault();
                this.handleLookingAdds()
              
            }}>
                <Link style={styleD.linkViewAdd as React.CSSProperties}  >View all</Link></a>
              </Grid>
              <hr style={styleD.hrAdd as React.CSSProperties} />
              {this.renderLookingForSomethingSectionMain()}
            </Box>
          </Box>
          <Box>
            <Box style={styleD.containersAdd as React.CSSProperties}>
              <Box style={styleD.lookingHeaderAdd as React.CSSProperties}>
                <Typography style={styleD.lookingTextAdd as React.CSSProperties}>Selling Something</Typography>
                <a  data-testid="viewAll2" style={styleD.linkViewAdd as React.CSSProperties} href="/FilterAllCategory?sellingViewAll=true" onClick={(event) => {
                event.preventDefault();
                this.handleLookingAdd();
              
            }}>
                <Link style={styleD.linkViewAdd as React.CSSProperties} >View all</Link></a>
              </Box>
              <hr style={styleD.hrAdd as React.CSSProperties} />
              {this.renderSellingSomethingSectionMain()}
            </Box>
            <Box style={styleD.whyMainAdd as React.CSSProperties} >
              <Box>
                <span style={styleD.whyChooseeAdd as React.CSSProperties}><span style={styleD.whyChooseeAddWText as React.CSSProperties}>Why</span> choose</span><span style={styleD.whyChooseeFeeAdd as React.CSSProperties}>Item Find</span><span style={styleD.whyChooseeAdd as React.CSSProperties}>?</span>

              </Box>
              <Box >
                <Typography style={styleD.whyChooseTextAdd}>It serves as a platform where individuals seeking a particular item can connect with those who are capable of locating a source for that item.</Typography>
              </Box>
            </Box>
            {this.state.reviewLoading &&
              <Box
                pb="100px"
                display='flex'
                justifyContent='center'
                alignItems='center'
                margin='0 auto'
              >
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress style={{ color: '#4fa94d' }} />
                </Box>
              </Box>
            }
            {!this.state.reviewLoading && this.state.customerReview.length > 0 &&
              <Box style={styleD.whyMainAdd as React.CSSProperties} >
                <span style={styleD.testimonialsAdd as React.CSSProperties}>Testi</span><span style={styleD.testimonialsAddOther as React.CSSProperties}>monials</span>
                <Typography style={styleD.whyChooseTextAdd}>Hear What Our Customers Are Saying</Typography>
                <Testimonial>
                  <CustomCarousel data-test-id="carousel" pagination={false} itemsToShow={1} renderArrow={this.renderArrow}>
                    {this.state.customerReview.map((customer: any, index: number) => {
                      return (
                        <Box className="box" width="100%">
                          <Grid container className="left-grid">
                            <Grid item xs={12} md={3} className="left-section">
                              <img src={imageBorder} alt="" className="left-border" />
                              {customer.attributes.image && <img className="customer-img" src={customer.attributes.image} alt="" />}
                            </Grid>
                            <Grid item xs={12} md={9} className="right-section">
                              <Typography style={styleD.testTextAdd}>
                                {customer.attributes.description}
                              </Typography>
                              <Typography style={styleD.hannaAdd}>
                                {customer.attributes.customer_name}
                              </Typography>
                              <Typography style={styleD.customerAdd}>
                                Customer
                              </Typography>
                              <img src={imageBorderRight} alt="" className="right-border" />
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                  </CustomCarousel>
                </Testimonial>
              </Box>}
            <ContactusAdd>
              <OverLayBoxCards />
              <Box style={styleD.containersAdd as React.CSSProperties}>
                <ContactusGridAdd container>
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12} style={styleD.contactusTextAdd as React.CSSProperties}>
                    <Typography style={styleD.contactTextTAdd as React.CSSProperties}>Help people find
                    </Typography>
                    <Typography style={styleD.contactTextTAdd as React.CSSProperties}> there Dream Collection
                    </Typography>
                    <div style={{ display: 'flex' }}>
                    <a   data-testid="contactus1" style={{textDecoration:"none"}} href={`/EmailAccountLoginBlock`} onClick={(event) => {
                                event && event.preventDefault();
                                this.siginNavigationAdd()
                            }}>
                      <Button style={styleD.plateformListingBtnssAdd as React.CSSProperties} >Sign-in</Button></a>
                      <a  data-testid="contactus2" style={{textDecoration:"none"}} href={`/EmailAccountRegistration`} onClick={(event) => {
                                event && event.preventDefault();
                                this.registerNavigationAdd()
                            }}>
                      <Button style={styleD.plateformListingBtnsAdd as React.CSSProperties}  >Register</Button></a>
                    </div>
                  </Grid>
                </ContactusGridAdd>
              </Box>
            </ContactusAdd>
          </Box>
        </Box>
        <Box>
          <AppFooter naviagtion={this.props.navigation.navigate} />
        </Box>

        {/* Customizable Area End */}
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const CustomCarousel = styled(Carousel)({
  "& .rec-item-wrapper": {
    height: "100%"
  },
  "& .carousel-button": {
    height: "fit-content",
    minWidth: "fit-content",
    alignSelf: "center",
    padding: "0px",
    "& .arrow": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#3DAA49",
      height: "30px",
      width: "30px",
      borderRadius: "8px",
      color: "#fff",
      "& svg": {
        fontSize: "18px"
      }
    },
    "& .isDisabled": {
      backgroundColor: "#b2c7b5"
    },
  }
});

const Testimonial = styled(Box)({
    maxWidth: "1000px",
    width: '90%',
    margin: '0 auto',
    "& .box": {
      padding: "25px"
    },
  "& .customer-img": {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    marginTop: "60px",
    "@media(max-width: 960px)": {
      marginTop: "0px",
      width: "200px",
      height: "200px",
    },
  },
  "& .left-grid": {
    height: "100%",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: '0px 8px 50px 0px #0000002E'
  },
  "& .left-section": {
    background: '#9DD5A4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "20px 30px",
    position: "relative"
  },
  "& .right-section": {
    padding: "60px 40px",
    textAlign: "left",
    position: "relative",
    "@media(max-width: 960px)": {
      padding: "30px 40px"
    }
  },
  "& .left-border": {
    position: "absolute",
    top: 0,
    left: 0
  },
  "& .right-border": {
    position: "absolute",
    bottom: 0,
    right: 0
  }
});

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%"
  },
});
const styleD = {
  containersAdd: {
    padding: "0px 56px",
    position: 'relative',
    zIndex: 1
  },
  menuMainAdd: {
    display: "flex",
    flexWarp: "warp",
    height: "150px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  hrAdd: {
    width: "98px",
    height: "3px",
    backgroundColor: " #3CAA49",
    border: "none",
    margin: "10px 0"
  },
  menuItemAdd: {
    padding: "17px 47px",
    fontFamily: "Segoe UI",
    fontSize: "20px",
    color: "#676767"
  },
  divAdd: {
    width: "200px",
    height: '44px',
    fontFamily: "Segoe UI Bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#676767",
    display: 'flex',
    alignItems: 'center'
  },
  MainPlateformAdd: {
    height: "450px",
    background: "#ECF7ED",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  plateformContainerAdd: {
    marginBottom: "30px"
  },
  plateformExploreBtnAdd: {
    height: "60px",
    width: "150px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  plateformListingBtnAdd: {
    height: "60px",
    width: "220px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "1px solid #3CAA49",
    textTransform: "capitalize",
  },
  whyChooseAdd: {

  },
  cardMainAdd: {
    width: "98%",
    height: "350px",
    position: 'relative',
  },
  imgCardAdd: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imgTextAdd: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    color: 'white',
    padding: '8px',
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    textAlign: 'center',
  },
  lookingMainAdd: {
    margin: "100px 0px"
  },
  lookingHeaderAdd: {
    margin: "30px 0 5px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  lookingTextAdd: {
    fontFamily: "Segoe UI Bold",
    fontSize: "30px",
    color: "#181818",
  },
  mapFNAdd: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  ContactuscontainersAdd: {
    padding: "0px 56px",
    "@media(max-width:958px) and (min-width: 0px)": {
      textAlign: "center",
    },
  },
  linkViewAdd: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    cursor: "pointer"
  },
  imgSellAdd: {
    width: "100%",
    height: "810px",
  },
  contactusTextAdd: {
    alignSelf: "center",
    marginTop: "10%",
    marginBottom: "10%"
  },
  contactTextTAdd: {
    fontFamily: "Segoe UI Bold",
    fontSize: "48px",
    color: "#FFFFFF"
  },

  plateformListingBtnsAdd: {
    height: "56px",
    width: "138px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "1px solid #3CAA49",
    textTransform: 'none'
  },
  plateformListingBtnssAdd: {
    height: "56px",
    width: "138px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#FFFFFF",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "1px solid #3CAA49",
    textTransform: 'none',
    marginRight: '10px'
  },
  testLeftAdd: {
    background: '#9DD5A4',
    height: '525px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "0px 42px"
  },
  testLeftAddsMain: {
    background: '#9DD5A4',
    height: '525px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: "80px 42px",
    position: "relative"
  },
  discoverAdd: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "60px",
  },
  dreamAdd: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "60px",
    paddingLeft: '5px'
  },
  findAdd: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "32px",
    padding: "10px 0 40px 0"
  },
  whyMainAdd: {
    margin: "60px 0",
    textAlign: 'center',
    padding: '0 56px',
  },
  whyChooseeAdd: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "48px",
  },
  whyChooseeAddWText: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "48px",
    borderBottom: "4px solid #3CAA49",
    paddingBottom: "10px"
  },
  whyChooseeFeeAdd: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "48px",
    padding: ' 0 20px'
  },
  whyChooseTextAdd: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "32px",
    fontStyle: "italic",
    padding: "20px 0",
    marginTop: "20px"
  },
  testimonialsAdd: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "48px",
    borderBottom: "4px solid #3CAA49",
    paddingBottom: "10px",
  },
  testimonialsAddOther: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "48px",
  },
  testTextAdd: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "22px",
    paddingBottom: '20px'
  },
  hannaAdd: {
    color: "#676767",
    fontFamily: "Segoe UI Bold",
    fontSize: "36px",
  },
  customerAdd: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
  },
  customerAddStyle: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    paddingBottom: '20px'
  },
  imageBorderRights: {
    position: "absolute", 
    bottom: 0, 
    right: 0
  },
  imageBorders: {
    position: "absolute", 
    top: 0, 
    left: 0 
  },
  lookLoadMain: { 
    display: 'flex', 
    gap: 32,
    width: '100%'
   },
}
// Customizable Area End
