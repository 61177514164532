import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  contactNumber: string;
  fullName: string;
  subject: string;
  details: string;
  emailError: boolean;
  contactError: boolean;
  passwordError: boolean;
  emailErrorMsg: string;
  contactErrorMsg: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  emailErrortakenMsg: string;
  contactErrortakeMsg: string;
  popUpMsg: any;
  isVisible: boolean;
  finishedTyping: boolean;
  showPopup: boolean;
  ContactBlurred: boolean;
  emailExists: boolean;
  emailBlurred: boolean;
  contactExists: boolean;
  userNametakenMsg: string;
  emailFocused: boolean;
  contactFocused: boolean;
  isSnackbarOpen: boolean;
  userNameError: boolean;
  fullNameError: boolean;
  subjectError: boolean;
  isPrivacyPolicyModalOpen: boolean;
  detailsError: boolean
  noContactusAccountregistered: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusNewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  apiSignup_informationCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      passwordError: false,
      emailError: false,
      contactError: false,
      contactFocused: false,
      ContactBlurred: false,
      popUpMsg: "",
      email: "",
      emailErrortakenMsg: "",
      contactErrortakeMsg: "",
      isSnackbarOpen: false,
      emailErrorMsg: "",
      phoneNumber: "",
      subject: "",
      showPopup: false,
      isPrivacyPolicyModalOpen: false,
      detailsError: false,
      details: "",
      comments: "",
      enableField: false,
      token: "",
      contactErrorMsg: "",
      userNametakenMsg: "",
      emailExists: false,
      contactExists: false,
      noContactusAccountregistered: "",
      contactUsList: [],
      activeId: 0,
      contactNumber: "",
      subjectError: false,
      userNameError: false,
      activeName: "",
      activeEmail: "",
      fullName: "",
      fullNameError: false,
      emailFocused: false,
      emailBlurred: false,
      finishedTyping: false,
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTokens();
    // Customizable Area Start
    window.scrollTo(0,0);
    // Customizable Area End
  }

  getTokens = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.apiSignup_informationCallId) {
        if (responseJson?.message) {
          this.setState({ popUpMsg: responseJson.message, showPopup: true, fullName: "", email: "", contactNumber: "", details: "", subject: "" });
        } else if (responseJson && responseJson.errors
          && Array.isArray(responseJson.errors)
          && responseJson.errors[0]
          && responseJson.errors[0].contact
        ) {
          this.setState({ contactErrorMsg: "Invalid contact number" });
        }
      }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.props.navigation.goBack();
        }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCreateContact() {
    const {
      fullName,
      email,
      contactNumber,
      subject,
      details,
    } = this.state;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const contactNumberPattern = /^\+?\d+$/;

    if (fullName.trim() === "") {
      this.setState({ fullNameError: true });
    } else {
      this.setState({ fullNameError: false });
    }
    if (email.trim() === "") {
      this.setState({ emailErrorMsg: "Email is required" });
    } else if (!emailPattern.test(email)) {
      this.setState({ emailErrorMsg: "Invalid email format" });
    }
    if (contactNumber.trim() === "") {
      this.setState({ contactErrorMsg: "Contact number is required" });
    } else if (!contactNumberPattern.test(contactNumber)) {
      this.setState({ contactErrorMsg: "Invalid contact format" });
    }
    if (subject.trim() === "") {
      this.setState({ subjectError: true });
    } else {
      this.setState({ subjectError: false });
    }
    if (details.trim() === "") {
      this.setState({ detailsError: true });
    } else {
      this.setState({ detailsError: false });
    }
    if (
      fullName.trim() !== "" &&
      email.trim() !== "" &&
      contactNumber.trim() !== "" &&
      subject.trim() !== "" &&
      details.trim() !== "" &&
      emailPattern.test(email) &&
      contactNumberPattern.test(contactNumber)
    ) {
      this.contactUsfomationDetails();
    }
  }
  handleLogin = () => {
    this.setState({
      isSnackbarOpen: true
    });
  };
  handleEmailBlur = () => {
    const { email } = this.state;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    this.setState({
      emailBlurred: true,
      finishedTyping: true,
      emailError: !emailPattern.test(email)
    });
  };
  handleFullNameChanges = (event: { target: { value: string } }) => {
    let fullName = event.target.value;
    fullName = fullName.replace(/[^A-Za-z\s]/g, '');
    this.setState({ fullName: fullName });
    if (fullName.trim() === "") {
      this.setState({ fullNameError: true });
    } else {
      this.setState({ fullNameError: false });
    }
  };

  handleEmailChanges = (event: { target: { value: string } }) => {
    const email = event.target.value;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.setState({
      email,
      emailError: !emailPattern.test(email),
      emailErrorMsg: email.length == 0 ? "Email is Required" : "",
      noContactusAccountregistered: ""
    });
  };

  handleEmailFocus = () => {
    this.setState({
      emailFocused: true,
      emailBlurred: false,
      finishedTyping: false
    });
  };

  handleContactChange = (event: { target: { value: string } }) => {
    const contactNumber = event.target.value;
    const contactNumberPattern = /^\+?\d+$/;
    const isValidContactNumber = contactNumberPattern.test(contactNumber) || contactNumber === "+" || contactNumber === "";

    if(isValidContactNumber){
      this.setState({
        contactNumber: contactNumber,
        contactError: !contactNumberPattern.test(contactNumber),
        contactErrorMsg: contactNumber.length == 0 ? "Contact is Required" : "",
        noContactusAccountregistered: ""
      });
    }
  };
  handleContactFocus = () => {
    this.setState({
      contactFocused: true,
      ContactBlurred: false,
      finishedTyping: false
    });
  };

  handleSubjectChange = (event: { target: { value: string } }) => {
    let subject = event.target.value;
    this.setState({ subject: subject });
    if (subject.trim() === "") {
      this.setState({ subjectError: true });
    } else {
      this.setState({ subjectError: false });
    }
  };

  handleDetailsChange = (event: { target: { value: string } }) => {
    let details = event.target.value;
    this.setState({ details: details });
    if (details.trim() === "") {
      this.setState({ detailsError: true });
    } else {
      this.setState({ detailsError: false });
    }
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.fullName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) 
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }
  };

  handleNavigateRefund=()=>{
    this.props.navigation.navigate("RefundManagement")
  }



  contactUsfomationDetails = async () => {

    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSignup_informationCallId = requestMessage.messageId;
    let Body = {
      data: {
        name: this.state.fullName,
        email: this.state.email,
        phone_number: this.state.contactNumber,
        subject: this.state.subject,
        details: this.state.details,
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_contact_us/contacts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  // Customizable Area End
}
