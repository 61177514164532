// Customizable Area Start
import React from "react";
import { imgSourceFeeLogo, resetSuccess } from "./assets";

import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    styled,
    Dialog,
    Paper
} from "@material-ui/core";
import {
    Visibility,
    VisibilityOff,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckBox as CheckBoxIcon
} from "@material-ui/icons";


const FormDataGrid = styled(Grid)({
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '@media(max-width: 960px)': {
        height: 'unset'
    }
});



import ForgotPasswordControllerWeb from "./ForgotPasswordController.web";

const FormGrid = styled(Grid)({
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)"
    }
});

export default class NewPasswordWeb extends ForgotPasswordControllerWeb {


    renderErrorMessage(message: string) {
        return (
            <Typography style={webStyle.errorMessage as React.CSSProperties}>
                {message}
            </Typography>
        );
    }
    ResetPassword() {
        return (
            <Typography style={webStyle.rightHeading as React.CSSProperties}>
            Reset Password
        </Typography>
        );
    }

    renderPasswordError() {
       if(this.state.passwordError) {
            return this.renderErrorMessage('Password is required');
        }
        return null;
    }


    renderConfirmPasswordError() {
        const { confirmPasswordError, passwordMatchError } = this.state;

        if (confirmPasswordError) {
            return this.renderErrorMessage('Confirm Password is required');
        } else if (passwordMatchError) {
            return this.renderErrorMessage('Passwords do not match.');
        }
        return null;
    }


    render() {

      
        const {

            showPassword,
            passwordMatchError,
            showConfirmPassword,
            password,
            confirmPassword,
            containsCapital,
            containsNumber,
            containsLowercase,
            hasMinLength,
            spacialChar

        } = this.state;

        const passwordInputType = showPassword ? "text" : "password";
        const confirmPasswordInputType = showConfirmPassword ? "text" : "password";
        const passwordMatchErrorBorderColor = passwordMatchError
            ? "#FF0404"
            : undefined;
      


        return (

            <Box width="100%">
                <FormGrid container>
                    <FormDataGrid item xs={12} md={12} style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                        <div style={webStyle.Imglogohead as React.CSSProperties}>
                            <img src={imgSourceFeeLogo} alt="applogo" style={{ height: "50px" }} />
                        </div>
                        <Box style={webStyle.rightContent}>
                        {this.state.resetError && <Typography style={{ color: "#FE4023", backgroundColor: "#ffded6", padding: "12px", width: "100%", borderLeft: "2px solid #FE4023" , fontFamily: "Segoe UI" }}>{this.state.resetError}</Typography>}

                          
                            {this.ResetPassword()}

                            <Typography style={{fontSize:"18px",fontFamily:"Segoe UI",color:"#676767"}}>
                                Enter New Password
                            </Typography>

                            <Typography style={webStyle.label as React.CSSProperties}>
                                New Password
                            </Typography>
                            <CustomTextField
                                variant="outlined"
                                placeholder="Enter new password"
                                data-test-id="password"
                                type={passwordInputType}
                                style={{ width: "100%" }}
                                onChange={this.handlePasswordChange}
                                value={password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.togglePasswordVisibility}
                                                edge="end"
                                                data-test-id="showPass"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: placeholderStyle,
                                }}
                            />
                            {this.renderPasswordError()}

                            <Typography style={webStyle.label as React.CSSProperties}>
                                Confirm Password
                            </Typography>
                            <CustomTextField
                                variant="outlined"
                                placeholder="Re-Enter password"
                                data-test-id="confirmpassword"
                                type={confirmPasswordInputType}
                                style={{
                                    width: "100%",
                                    borderColor: passwordMatchErrorBorderColor,
                                
                                }}
                                value={confirmPassword}
                                onChange={this.handleConfirmPasswordChange}
                                error={passwordMatchError}
                            
                                InputProps={{
                                    
                                    endAdornment: (
                                        
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle confirm password visibility"
                                                onClick={this.toggleConfirmPasswordVisibility}
                                                edge="end"
                                               
                                            >
                                                {showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: placeholderStyle,
                                }}
                            />
                            {this.renderConfirmPasswordError()}


                            <div style={{ marginTop: "5px" }}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <CustomCheckboxs
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={
                                                    <CheckBoxIcon style={{ color: "#059669" }} />
                                                }
                                                checked={containsCapital}
                                            />
                                        }
                                        label={
                                            <span style={webStyle.checkText as React.CSSProperties}>
                                                At least one capital letter.
                                            </span>
                                        }
                                    />
                                </div>

                                <div>
                                    <FormControlLabel
                                        control={
                                            <CustomCheckboxs
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={
                                                    <CheckBoxIcon style={{ color: "#059669" }} />
                                                }
                                                checked={containsLowercase}
                                            />
                                        }
                                        label={
                                            <span style={webStyle.checkText as React.CSSProperties}>
                                                At least one lowercase letter.
                                            </span>
                                        }
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <CustomCheckboxs
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={
                                                    <CheckBoxIcon style={{ color: "#059669" }} />
                                                }
                                                checked={containsNumber}
                                            />
                                        }
                                        label={
                                            <span style={webStyle.checkText as React.CSSProperties}>
                                                At least one number.
                                            </span>
                                        }
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <CustomCheckboxs
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={
                                                    <CheckBoxIcon style={{ color: "#059669" }} />
                                                }
                                                checked={spacialChar}
                                            />
                                        }
                                        label={
                                            <span style={webStyle.checkText as React.CSSProperties}>
                                             At least one special character.
                                            </span>
                                        }
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <CustomCheckboxs
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={
                                                    <CheckBoxIcon style={{ color: "#059669" }} />
                                                }
                                                checked={hasMinLength}
                                            />
                                        }
                                        label={
                                            <span style={webStyle.checkText as React.CSSProperties}>
                                                Minimum character length is 8.
                                            </span>
                                        }
                                    />
                                </div>
                            </div>

                            <Button
                                style={{
                                    ...webStyle.btnLogin,
                                    backgroundColor: this.getButtonBackgroundColor(),
                                    textTransform: "initial",
                                    cursor: "pointer"
                                }}
                                onClick={this.handlePrint}
                                data-test-id="resetButton"
                            >
                               Reset Password
                            </Button>

                        </Box>
                    </FormDataGrid>

                </FormGrid>


                <Dialog
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}

                    data-test-id="editmodal"
                    open={this.state.resetPasswordPopup}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Paper
                        elevation={4}

                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px", padding: "30px 48px" }}>
                            <img src={resetSuccess} alt="resetSuccess" style={{ height: "50%", width: "50%" }} />
                            <Typography id="headerText" style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Segoe UI bold" }}>Password Changed Successfully</Typography>
                            <Typography style={{ textAlign: "center", width: "100%", fontFamily: "Segoe UI ", fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button data-test-id ="handleHome" onClick={() => this.navigateToLogin()} style={{ fontFamily: "Segoe UI", fontWeight: 700, fontSize: "18px", textTransform: "none", color: "#3CAA49" }}>
                                    <Typography style={{ fontFamily: "Segoe UI bold " }}>Re-Login</Typography></Button>to the account</Typography>
                        </div>
                    </Paper>
                </Dialog>
            </Box>

        );
    }
}



const placeholderStyle = {
    fontSize: "16px",
    color: "#64748B",fontFamily: "Segoe UI",
    lineHeight: "22px",
};
const CustomCheckboxs = styled(Checkbox)({
    padding: "0px", paddingLeft: "10px",
    paddingRight: "5px",
    "& .MuiSvgIcon-root": {
        color: "#CBD5E1!important"
    },
    "&.MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root": {
        color: "#3CAA49!important"
    }
});

const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-input": {
        padding: '8px 15px',
        fontSize: 14, height:"30px",
     
    },
    '&.redBorder': {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: 'red !important'
        }
    }
});

const webStyle = {


    rightContent: {
        maxWidth: "360px",
        margin: "0 auto",
        padding: 5,
    },
    label: {
        fontSize: "16px",
        marginTop: "32px",
        letterSpacing: "0em",
        lineHeight: "16px", color: "#676767",
        textAlign: "left",
        fontFamily: "Segoe UI Bold",
        paddingBottom: 2
    },
    btnLogin: {
        borderRadius: "8px",
        backgroundColor: "#273567",
        width: "100%",
        fontFamily: "Segoe UI Bold",color: "#FFFFFF",
        height: "45px",
        marginTop: "10px", fontSize: "20px",
        cursor: "pointer",
        padding:"10px"
    },
 
    rightHeading: {
        color: "#000000",
        fontFamily: "Segoe UI Bold",
        fontSize: "24px",
        letterSpacing: "0em",  
        lineHeight: 1,
        textAlign: "left",
        marginBottom: 24
    },
  
    checkText: {
        color: "#676767", fontFamily: "Segoe UI",
        fontSize: "14px",
        lineHeight: "24px"
    },
  
    Imglogohead: {
        textAlign: "right",
        height: "100px",display: "flex",
        borderBottom: "2px solid #3CAA49",
        alignItems: "center",  
        marginBottom: "52px",
        paddingLeft:"72px"


    },
    errorMessage: {
        color: "#FE4023", marginTop: "0px",
        fontFamily: "Segoe UI",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0em",
    },
  
};

// Customizable Area End
