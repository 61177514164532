import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { settingImages , imageSettingss} from "./assets";
import SettingFlow from "../../../components/src/SettingFlow.web.";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box width="100%">
        <AppHeader navigation={this.props.navigation.navigate} />
        <FormGrids>
          <CustomBoxs>
            <div style={{ position: 'absolute', top: 0, left: "50%", transform: 'translateX(-50%)',marginTop:"30px" }}>
              <FlexDivs data-test-id='SettingButton'>
              <LogoGo src={imageSettingss} alt="Logo" onClick={this.handleChangeSetting} />
                <LogoImages src={settingImages} alt="Logo" />
                <LogoTexts>Settings</LogoTexts>
              </FlexDivs>
            </div>
          </CustomBoxs>
          <CustomContents flex="1">
            <SettingFlow {...this.props} />
          </CustomContents>
        </FormGrids>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const FormGrids = styled(Box)({
  display: 'flex',
});
const CustomBoxs = styled(Box)({
  width: '336px',

  borderRadius: '0px 15px 0px 0px',
  backgroundColor: '#E0DAD3',
  position: 'sticky',
  height: 'calc(100vh - 80px)',
  top: '80px',
  '@media(max-width: 1150px)': {
    display: 'none'
  },
  '@media(min-width: 1150px)': {
    minWidth: '336px',
  }
})
const CustomContents = styled(Box)({
  display: 'columns',
  Width: 'calc(100% - 150px)',
  minHeight: '100vh',
  borderRadius: '0px, 0px, 0px, 32px',
})
const LogoImages = styled("img")({
  width: "28px",
  height: "28px",
});
const LogoGo= styled("img")({
  width: "24px",
  height: "20px",
});
const LogoTexts = styled("h6")({
  color: "#000000",
  fontFamily: "Segoe UI",
  fontSize: "16px",
  alignItems: "center",
  lineHeight: "26.6px",
  margin: 0,
});
const FlexDivs = styled(Box)({
  display: "flex",
  alignItems: "center",
  position: 'relative',
  bottom: 0,
  justifyContent: 'center',
  gap: "10px",
  cursor: 'pointer'
});
// Customizable Area End
