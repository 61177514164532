import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedButton: string;
  addData: (object | number)[];
  index: number;
  header: string;
  before_title: string;
  after_title: string;
  before_description: string;
  after_description: string;
  in_the_bottom: string;
  active: boolean;
  selected: number;
  name: string;
  color: string;
  lists: (object | number)[];
  item: string;
  showParticularAdCallId: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

export default class LocalListingAdsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // showParticularAdCallId: string;
  showParticularAdCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.showParticularAdCallId = "";
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      selectedButton: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      index: 0,
      addData: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
      header: "",
      before_title: "",
      after_title: "",
      before_description: "",
      after_description: "",
      in_the_bottom: "",
      active: false,
      selected: 0,
      name: "bob",
      color: "white",
      item: " ",
      showParticularAdCallId: "",
      lists: [
        { id: 1, title: "S" },
        { id: 2, title: "M" },
        { id: 3, title: "L" },
        { id: 4, title: "XL" },
        { id: 5, title: "XXL" },
      ],

      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.showParticularAdFunc();
  }

  
    // Customizable Area Start
    async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
      runEngine.debugLog("API Message Recived", message);
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
  

      // if (apiRequestCallId != null) {
      //   if (responseJson && !responseJson.errors) {
      //     if (apiRequestCallId === this.showParticularAdCallId) {
            this.setState({
              header: responseJson.header,
              before_title: responseJson.before_title,
              after_title: responseJson.after_title,
              before_description: responseJson.before_description,
              after_description: responseJson.after_description,
              in_the_bottom: responseJson.in_the_bottom,
            });
      //     }
      //   }
      // }
    }
  }
    // Customizable Area End
   
  handlePress = (button: string) => {
    this.setState({ selectedButton: button });
  };
 


 

  // Customizable Area Start

  showParticularAdFunc = async () => {
    this.showParticularAdCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_locallistingads/local_listing_ads",
    });
  };
 
  SellingNavigation = () => {
    this.props.navigation.navigate("SellingSomething")
  }
  LookingNavigation = () => {
    this.props.navigation.navigate("LookingSomething")
  }
  apiCall = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
  }) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
  // Customizable Area End
}
