export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imageSetting = require("../assets/image_setting.png");
export const imageLocations = require("../assets/imageLocation.png");
export const imageCard = require("../assets/imageDrawing.png");
export const editImage = require("../assets/editImages.png");
export const settingImage = require("../assets/settingImages.png");
export const LogoutImage = require("../assets/LogoutImages.png");
export const searchImageSave = require("../assets/searchSaveListing.png");
export const shapeSave= require("../assets/shapeSaveListing.png");
export const dummyImg= require("../assets/dummy_img.png")
export const imagePopupSomething = require("../assets/imagePopupSomething.png")
export const imageDirectSending = require("../assets/imageDirectSend.png")
export const deleteSaveds = require("../assets/deleteSaved.png")
export const group_CrossButton = require("../assets/group_Cross.png")
export const login = require("../assets/login.jpeg")
export const profiles = require("../assets/profile.jpeg");
export const SellingSomesImage = require("../assets/SellingSomeImages.png");
export const deleteSavedss = require("../assets/deleteSaved.png")
export const group_CrossButtons = require("../assets/group_Cross.png")
export const refresh = require("../assets/refresh.jpeg")
export const image_clock = require("../assets/image_clock.png")
export const red_circle = require("../assets/image_info-circle.png")
export const image_Gray = require("../assets/imageGray.png")
export const image_Green = require("../assets/imageGreen.png")
export const orderH = require("../assets/ListingEmptyImage.png")