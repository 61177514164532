import React from 'react';
import { Box, styled } from "@material-ui/core";

export function ListingEmptyLooking() {
  const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '350px',
    maxWidth: 'auto',
    margin: '30 auto 10 auto',
  });
  const HeadingText = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    fontSize: '24px',
    fontFamily: 'Segoe UI Bold',
    lineHeight: '24px',
    color: '#3CAA49',
  });
  const HeadingTextMain = styled('div')({
    marginTop: "10px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10 auto',
    fontSize: '18px',
    fontFamily: 'Segoe UI',
    lineHeight: '20px',
    color: '#676767',
  });

  return (
    <Box >
      <Container>
        <img src={require('./ProductEmpty.png')} alt="Logo" />
      </Container>
      <div>
        <HeadingText>
        No products found 
        </HeadingText>
        <HeadingTextMain>
        Sorry, it seems we don’t have the product you are looking for.
        </HeadingTextMain>
       <HeadingTextMain>
        </HeadingTextMain>
      </div>
    </Box>
  );
}

