import React from "react";
import LocalListingAdsController, { Props } from "./LocalListingAdsController";
import {
  FaArrowLeft,
  FaRegHeart,
  FaMinusCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { girl } from "./assets";
import { IconContext } from "react-icons/lib";
// Customizable Area Start
// Customizable Area End

export default class LocalListingAds extends LocalListingAdsController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  renderHeader = () => {
    return (
      <div className="row" style={{ flexDirection: "row", display: "flex" }}>
        <div
          className="left-arrow-icon"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "4%",
          }}
        >
          <IconContext.Provider value={{ color: "purple", size: "40px" }}>
            <button data-testid="increment">
              <FaArrowLeft />
            </button>
          </IconContext.Provider>
        </div>

        <div className="right-arrow-icon" style={{ marginLeft: "85%" }}>
          <IconContext.Provider value={{ color: "purple", size: "40px" }}>
            <button data-testid="increment">
              <FaRegHeart />
            </button>
          </IconContext.Provider>
        </div>
      </div>
    );
  };

  renderHeaderImage = () => {
    return (
      <>
        <img
          className="swiper-image"
          src={girl}
          style={{ width: "70%", height: "19%", marginLeft: "14%" }}
        />
        <h1 className="dots" style={{ marginLeft: "47%" }}>
          .....
        </h1>
      </>
    );
  };

  renderSnoopy = () => {
    return (
      <>
        <div style={{ marginLeft: "8%" }}>
          <h1>Snoopy T-Shirt</h1>
        </div>

        <div
          className="add-delete-icon"
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginRight: "5%",
          }}
        >
          <IconContext.Provider value={{ color: "grey", size: "40px" }}>
            <button
              className="add-button"
              style={{ marginRight: "2%", cursor: "pointer" }}
              data-testid="plusIcon"
            >
              <FaMinusCircle />
            </button>
            <h1 className="count"> 1 </h1>
            <button
              className="delete-button"
              style={{ marginLeft: "2%", cursor: "pointer" }}
              data-testid="minusIcon"
            >
              <FaPlusCircle />
            </button>
          </IconContext.Provider>
        </div>

        <div />
        <div
          className="color-button"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "8%",
            marginBottom: "1.5%",
          }}
        >
          <div>
            <button
              className="button1"
              data-testid="colorIcon1"
              style={{
                backgroundColor: "#f11e1e",
                border: "none",
                color: "white",
                padding: "20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "50%",
              }}
            />
            <button
              className="button2"
              data-testid="colorIcon2"
              style={{
                backgroundColor: "#13d8ea",
                border: "none",
                color: "white",
                padding: "20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "50%",
              }}
            />
            <button
              className="button3"
              data-testid="colorIcon3"
              style={{
                backgroundColor: "#101011",
                border: "none",
                color: "white",
                padding: "20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className="tick-mark" data-testid="increment">
            <IconContext.Provider value={{ color: "Black", size: "50px" }}>
              <IoCheckmarkCircleOutline />
            </IconContext.Provider>
          </div>
        </div>
      </>
    );
  };

  renderSize = () => {
    return (
      <>
        <div style={{ overflowX: "auto", marginLeft: "8%" }}>
          <div style={{ display: "flex" }}>
            <button
              style={{
                backgroundColor:
                  this.state.selectedButton === "button1" ? "red" : "grey",
                padding: 1,
                borderRadius: 4,
                marginRight: 10,
                width: 100,
                height: 40,
              }}
              data-test-id="sizeButton1"
              onClick={() => this.handlePress("button1")}
            >
              <span style={{ color: "white" }}>S</span>
            </button>
            <button
              style={{
                backgroundColor:
                  this.state.selectedButton === "button2" ? "red" : "grey",
                padding: 1,
                borderRadius: 4,
                marginRight: 10,
                width: 100,
                height: 40,
              }}
              data-test-id="sizeButton2"
              
            >
              <span style={{ color: "white" }}>M</span>
            </button>
            <button
              style={{
                backgroundColor:
                  this.state.selectedButton === "button3" ? "red" : "grey",
                padding: 1,
                borderRadius: 4,
                marginRight: 10,
                width: 100,
                height: 40,
              }}
              data-test-id="sizeButton3"
              
            >
              <span style={{ color: "white" }}>L</span>
            </button>
            <button
              style={{
                backgroundColor:
                  this.state.selectedButton === "button4" ? "red" : "grey",
                padding: 1,
                borderRadius: 4,
                marginRight: 10,
                width: 100,
                height: 40,
              }}
              data-test-id="sizeButton4"
              
            >
              <span style={{ color: "white" }}>XL</span>
            </button>
          </div>
        </div>
      </>
    );
  };

  renderDescription = () => {
    return (
      <div style={{ marginLeft: "8%" }}>
        <h2>Discription:-</h2>
        <text
          className="discription"
          style={{
            marginRight: "8%",
            display: "block",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ornare
          vitae tellus ac congue. Mauris dictum, justo sit amet varius placerat,
          elit magna laoreet ante, sit amet sodales ex sapien ut velit, Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Donec ornare vitae
          tellus ac congue. Mauris dictum, justo sit amet varius placerat, elit
          magna laoreet ante, sit amet sodales ex sapien ut velit. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Donec ornare vitae tellus
          ac congue. Mauris dictum, justo sit amet varius placerat, elit magna
          laoreet ante, sit amet sodales ex sapien ut velit.
        </text>
      </div>
    );
  };

  renderCart = () => {
    return (
      <div
        className="card"
        style={{
          width: "22%",
          height: "4.5%",
          backgroundColor: "#dcdcdc",
          borderRadius: "8px",
          marginBottom: "15px",
          marginTop: "15px",
          overflow: "hidden",
          marginLeft: "8%",
        }}
      >
        <div className="card-body" style={{ padding: "3px" }}>
          <div
            className="card-price-tag"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0%",
            }}
          >
            <h3
              className="card-title"
              style={{
                marginTop: "18px",
                marginBottom: "0px",
                marginLeft: "19px",
                fontSize: "30px",
              }}
            >
              {" "}
              $10.9{" "}
            </h3>
            <h5
              className="card-sub-title"
              style={{
                textDecorationLine: "line-through",
                marginTop: "35px",
                marginLeft: "2%",
                color: "rgb(133, 133, 133)",
                marginRight: "23%",
              }}
            >
              {" "}
              ($12.00){" "}
            </h5>
            <button
              data-testid="addToCartButton"
              style={{
                backgroundColor: "#782688",
                width: "155px",
                height: "49px",
                margin: "1",
                borderRadius: 15,
                color: "#f8f8ff",
                marginTop: "6%",
                fontSize: "17px",
                marginRight: "2%",
              }}
            >
              Add to cart
            </button>
          </div>
          <p
            className="card-description"
            style={{
              marginTop: "0",
              marginBottom: "110",
              color: "#a94bbc",
              marginLeft: "25px",
            }}
            data-testid="increment"
          >
            {" "}
            (10% OFF){" "}
          </p>
        </div>
      </div>
    );
  };

  renderBottom = () => {
    return (
      <h2 data-testid="increment" style={{ marginLeft: "8%" }}>
        Recently Viewed:-
      </h2>
    );
  };

  renderFlatlistData = () => {
    return (
      <>
        <div
          className="recently-card"
          style={{ display: "flex", flexDirection: "row", marginLeft: "7%" }}
        >
          <div className="card1">
            <img className="card-image" data-testid="increment" src={girl} />
            <h3 className="image-title" data-testid="increment">
              American Eagle fintops
            </h3>
            <div className="card-body1">
              <div className="card-price-tag1">
                <h3 className="card-title1" data-testid="increment">
                  {" "}
                  $9.9{" "}
                </h3>
                <h5 className="card-sub-title1" data-testid="increment">
                  {" "}
                  ($12.00)
                </h5>
              </div>

              <p className="card-description1" data-testid="increment">
                {" "}
                (10% OFF){" "}
              </p>
              <button
                data-testid="increment"
                style={{
                  backgroundColor: "#fee2e2",
                  width: "209px",
                  height: "40px",
                  margin: "2",
                  marginLeft: "30",
                  borderRadius: 15,
                  fontSize: "16px",
                  fontWeight: 59,
                  color: "#f11e1e",
                  marginBottom: "13px",
                }}
              >
                Add to cart
              </button>
            </div>
          </div>
          <div className="card1">
            <img className="card-image" data-testid="increment" src={girl} />
            <h3 className="image-title" data-testid="increment">
              American Peter England
            </h3>
            <div className="card-body1">
              <div className="card-price-tag1">
                <h3 className="card-title1" data-testid="increment">
                  {" "}
                  $8.9{" "}
                </h3>
                <h5 className="card-sub-title1" data-testid="increment">
                  {" "}
                  ($11.00)
                </h5>
              </div>

              <p className="card-description1" data-testid="increment">
                {" "}
                (12% OFF){" "}
              </p>
              <button
                data-testid="increment"
                style={{
                  backgroundColor: "#fee2e2",
                  width: "209px",
                  height: "40px",
                  margin: "2",
                  marginLeft: "30",
                  borderRadius: 15,
                  fontSize: "16px",
                  fontWeight: 59,
                  color: "#f11e1e",
                  marginBottom: "13px",
                }}
              >
                Add to cart
              </button>
            </div>
          </div>
          <div className="card1">
            <img className="card-image" data-testid="increment" src={girl} />
            <h3 className="image-title" data-testid="increment">
              American H and M
            </h3>
            <div className="card-body1" data-testid="increment">
              <div className="card-price-tag1">
                <h3 className="card-title1" data-testid="increment">
                  {" "}
                  $9.0{" "}
                </h3>
                <h5 className="card-sub-title1" data-testid="increment">
                  {" "}
                  ($13.00)
                </h5>
              </div>

              <p className="card-description1" data-testid="increment">
                {" "}
                (11% OFF){" "}
              </p>
              <button
                data-testid="increment"
                style={{
                  backgroundColor: "#fee2e2",
                  width: "209px",
                  height: "40px",
                  margin: "2",
                  marginLeft: "30",
                  borderRadius: 15,
                  fontSize: "16px",
                  fontWeight: 59,
                  color: "#f11e1e",
                  marginBottom: "13px",
                }}
              >
                Add to cart
              </button>
            </div>
          </div>
          <div className="card1">
            <img className="card-image" data-testid="increment" src={girl} />
            <h3 className="image-title" data-testid="increment">
              American Eagle fintops
            </h3>
            <div className="card-body1">
              <div className="card-price-tag1">
                <h3 className="card-title1" data-testid="increment">
                  {" "}
                  $9.9{" "}
                </h3>
                <h5 className="card-sub-title1" data-testid="increment">
                  {" "}
                  ($14.00)
                </h5>
              </div>

              <p className="card-description1" data-testid="increment">
                {" "}
                (15% OFF){" "}
              </p>
              <button
                style={{
                  backgroundColor: "#fee2e2",
                  width: "209px",
                  height: "40px",
                  margin: "2",
                  marginLeft: "30",
                  borderRadius: 15,
                  fontSize: "16px",
                  fontWeight: 59,
                  color: "#f11e1e",
                  marginBottom: "13px",
                }}
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="layout">
        <div>
          {this.renderHeader()}
          {this.state.header}
          {this.renderHeaderImage()}
          {this.state.before_title}
          {this.renderSnoopy()}
          {this.state.after_title}
          {this.renderSize()}
          {this.state.before_description}
          {this.renderDescription()}
          {this.state.after_description}
        </div>
        {this.renderCart()}
        {this.renderBottom()}
        {this.renderFlatlistData()}
        {this.state.in_the_bottom}
      </div>
    );
  }
}

// Customizable Area Start
const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  slide: {
    textAlign: "center",
    background: "#fff",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "0 auto",
  },

  layout: {
    paddingLeft: "9%",
  },
};
// Customizable Area End
