import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Popup from 'reactjs-popup';
import { imgContactUs, imgPopup } from "./assets";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
const FormGrid = styled(Grid)({
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
});
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: '10px 15px',
    fontSize: 14
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});
const placeholderStyle = {
  fontSize: "18px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};
const FormDataGrid = styled(Grid)({
  '@media(max-width: 960px)': {
    overflow: 'hidden',
    width: '100vw',
    height: 'unset'
  }
});
const Customimg = styled(Grid)({
  '@media(max-width: 960px)': {
    display: 'none'
  }
});
const Styles = {
  leftBox: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },

  rightContentUs: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: 5,
  },
  labelus: {
    fontSize: "16px",
    marginTop: "16px",
    letterSpacing: "0em",
    lineHeight: "24px",
    color: "#676767",
    textAlign: "left",
    fontFamily: "Segoe UI Bold",
    paddingBottom: 4
  },
  btnLoginus: {
    borderRadius: "8px",
    width: "100%",
    fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    height: "40px",
    marginTop: "20px",
    backgroundColor: '#3CAA49',
    fontSize: "16px",
    cursor: "pointer"
  },
  rightHeadingus: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "25px",
    letterSpacing: "0em",
    lineHeight: "42px",
    textAlign: "left",
    marginBottom: 20
  },
  signlinkus: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer",
    borderBottom: "1px solid #3CAA49"
  },
  errorMessage: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  errorMessages: {
    color: "#6767673",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '400px'
  },
  modalStyle: {
    fontSize: '12px',
  },

  headerStyle: {
    width: '100%',
    borderBottom: '1px solid gray',
    fontSize: '18px',
    textAlign: 'center',
    padding: '5px',
  },

  contentStyle: {
    width: '100%',
    padding: '10px 5px',
  },
  popupIMG: {
    display: "flex",
    margin: "0 auto",
  },
  popupIMGMain: {
    margin: "30px 0px 30px 0px"
  },
  actionsStyle: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  popuptext: {
    fontSize: '24px',
    textAlign: "center",
    fontWeight: "700"
  },
  closeStyle: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: '2px 5px',
    lineHeight: '20px',
    right: '-10px',
    top: '-10px',
    fontSize: '24px',
    background: '#ffffff',
    borderRadius: '18px',
    border: '1px solid #cfcece',
  }
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End
import ContactusNewController, { Props } from "./AddContactusNewController";
export default class ContactuNewsWeb extends ContactusNewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleCreateContact = this.handleCreateContact.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  closePopup = () => {
    this.setState({ showPopup: false });
  };
  renderErrorMessage(message: any) {
    return (
      <Typography style={Styles.errorMessage as React.CSSProperties}>
        {message}
      </Typography>

    );
  }
  renderFullNameError() {
    if (this.state.fullNameError) {
      return this.renderErrorMessage('Full name is required');
    }
    return null;
  }


  renderSubjectError() {
    if (this.state.subjectError) {
      return this.renderErrorMessage('Subject is required');
    }
    return null; // Render nothing if there's no error
  }

  renderDetailsError() {
    if (this.state.detailsError) {
      return this.renderErrorMessage('Details is required');
    }
    return null; // Render nothing if there's no error
  }
  PoppupMessage() {
    return(
    <div>
    <p style={Styles.popuptext as React.CSSProperties} >{this.state.popUpMsg}</p>
  </div>
    )}
  // Customizable Area End
  render() {
    const {
      fullName,
      email,
      contactNumber,
      emailBlurred,
      emailError,
      emailExists,
      subject,
      details,
      finishedTyping,
      contactError,
      contactExists,
      ContactBlurred
    } = this.state;

    const showError = (emailError || emailExists) && finishedTyping;
    const contError = (contactError || contactExists) && finishedTyping;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation.navigate} />
        <Box>
          <FormGrid container>
            <FormDataGrid item xs={12} md={6} style={{ height: 'auto', display: "flex", alignItems: "center",marginBottom:"80px" }}>
              <Box style={Styles.rightContentUs}>
                <Typography style={Styles.rightHeadingus as React.CSSProperties}>
                  Talk to us
                </Typography>
                <Box mb={3} style={{ fontFamily: "Segoe UI", fontSize: "16px", color: "#676767" }}>
                  Fill in the form below, and we will get back to you
                  as soon as possible!
                </Box>
                <Typography style={Styles.labelus as React.CSSProperties}>
                  Full name
                </Typography>
                <CustomTextField
                  variant="outlined"
                  test-id="fullName"
                  placeholder="Your full name"
                  type="text"
                  value={fullName}
                  style={{
                    width: "100%"
                  }}
                  onChange={this.handleFullNameChanges}
                  InputProps={{
                    style: placeholderStyle,
                  }}
                />
                {this.renderFullNameError()}
                <Typography style={Styles.labelus as React.CSSProperties}>
                  Email
                </Typography>
                <CustomTextField
                  variant="outlined"
                  test-id="email"
                  placeholder="Your email"
                  type="email"
                  value={email}
                  error={showError && emailBlurred}
                  style={{
                    width: "100%"
                  }}
                  onChange={this.handleEmailChanges}
                  onFocus={this.handleEmailFocus}
                  InputProps={{
                    style: placeholderStyle,
                  }}
                />
                <>
                  {this.state.emailErrorMsg && (
                    <Typography
                      style={Styles.errorMessage as React.CSSProperties}
                    >
                      {this.state.emailErrorMsg}
                    </Typography>
                  )}
                  {this.state.emailErrortakenMsg.length > 0 && this.renderErrorMessage(this.state.emailErrortakenMsg)}
                </>
                <Typography style={Styles.labelus as React.CSSProperties}>
                  Contact number
                </Typography>
                <CustomTextField
                  variant="outlined"
                  test-id="contactNumber"
                  placeholder="Your contact number"
                  type="text"
                  value={contactNumber}
                  error={contError && ContactBlurred}
                  style={{
                    width: "100%"
                  }}
                  onChange={this.handleContactChange}
                  onFocus={this.handleContactFocus}
                  InputProps={{
                    style: placeholderStyle,
                  }}
                />
                <>
                  {this.state.contactErrorMsg && (
                    <Typography
                      style={Styles.errorMessage as React.CSSProperties}
                    >
                      {this.state.contactErrorMsg}
                    </Typography>
                  )}
                  {this.state.contactErrortakeMsg.length > 0 && this.renderErrorMessage(this.state.contactErrortakeMsg)}
                </>
                <Typography style={Styles.labelus as React.CSSProperties}>
                  Subject
                </Typography>
                <CustomTextField
                  variant="outlined"
                  test-id="subject"
                  placeholder="What do you want to talk about?"
                  type="text"
                  value={subject}
                  style={{
                    width: "100%"
                  }}
                  onChange={this.handleSubjectChange}
                  InputProps={{
                    style: placeholderStyle,
                  }}
                />
                {this.renderSubjectError()}
                <Typography style={Styles.labelus as React.CSSProperties}>
                  Details
                </Typography>
                <CustomTextField
                  variant="outlined"
                  placeholder="Any details you would like to share?"
                  type="text"
                  value={details}
                  style={{
                    width: "100%"
                  }}
                  onChange={this.handleDetailsChange}
                  InputProps={{
                    style: placeholderStyle,
                  }}
                />
                {this.renderDetailsError()}
                <Button
                  style={{
                    ...Styles.btnLoginus,
                    textTransform: "initial",
                    cursor: "pointer"
                  }}
                  onClick={this.handleCreateContact}
                >
                  Send Message
                </Button>
                <div style={Styles.modalStyle as React.CSSProperties}>
                  <div style={Styles.actionsStyle as React.CSSProperties}>
                    <Popup
                      open={this.state.showPopup}
                      test-id="popup"
                      closeOnDocumentClick onClose={this.closePopup}
                      className="popup"
                      contentStyle={Styles.content}
                      overlayStyle={Styles.overlay}
                    >

                      <div>
                        <button style={Styles.closeStyle as React.CSSProperties} onClick={this.closePopup}>
                          &times;
                        </button>

                        <div style={Styles.popupIMGMain as React.CSSProperties} >
                          <img style={Styles.popupIMG as React.CSSProperties} src={imgPopup} alt="" />
                        </div>
                        {this.PoppupMessage()}
                      </div>
                    </Popup>
                  </div>
                </div>
              </Box>
            </FormDataGrid>
            <Customimg item xs={12} md={6} style={Styles.leftBox}>
              <div>
                <img
                  src={imgContactUs}
                  style={{ width: '80%', height: '100%' }}
                />
              </div>
            </Customimg>
          </FormGrid>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
