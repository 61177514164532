// Customizable Area Start
import React from "react";
import { Box, styled, TextField, Typography, Button, Grid, CssBaseline, ThemeProvider, createTheme, Link, CircularProgress } from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import LookingSomthingControllerWeb from "./LookingSomethingControllerWeb";
import { imagePopupSomething, dummyImg, imageDirectSending, deletesIcon, arrowDown, ImageCrossIcon } from "./assets";
import Popup from 'reactjs-popup';

const ListingCreations = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "32px",
  lineHeight: "24px",
  color: "#676767",
  alignItems: 'center',
  margin: 0,
});
const DescribeProductText = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#676767",
  alignItems: 'center',
});
const InformationTexts = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#676767",
  alignItems: 'center',
});
const SelectTextFields = styled('select')({
  appearance: 'none',
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
  color: "#64748B",
  zIndex: 1111,
  position: 'relative',
  "& .MuiOutlinedInput-input": {
    padding: '10px 8px 10px 8px',
    fontSize: 14,
    gap: '8px',
    borderRadius: '0.5px',
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});
const DescribeProductTexts = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#676767",
  alignItems: 'center',
});
const ListingTitles = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  lineHeight: "22px",
  color: "#676767",
});
const CustomTextFields = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: '10px 8px 10px 8px',
    fontSize: 14,
    gap: '8px',
    borderRadius: '0.5px',
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});
const placeholderStyles = {
  fontSize: "18px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};
const placeholderStylesLocation = {
  fontSize: "18px",
  lineHeight: "22px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  marginRight: "8px"
};
const BudgetFields = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  lineHeight: "22px",
  color: "#676767",
});
const CommissionFields = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  lineHeight: "22px",
  color: "#676767",
});
const webStyles = {
  labels: {
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#676767",
    marginTop: "10px"
  },
  Selects: {
    padding: '9.82px 8px',
    fontSize: "13.13px",
    gap: '8px',
    borderRadius: '5.5px',
    width: '100%',
    border: '1px solid #CBD5E1',
    marginTop: '14px',
    background: 'none',
  },
  descriptionTexts: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    resize: 'none',
    padding: '10px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    marginTop: '15px',
    width: "100%",
    height: "213px"
  },
  customfileuploads: {
    display: 'inlineblock',
    backgroundColor: 'none',
    color: '#3CAA49',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  customfileuploadHeadings: {
    color: "#3CAA49",
    fontFamily: "Segoe UI",
    fontSize: "22px",
    lineHeight: "14px",
    paddingTop: "10px",
    paddingBottom: "25px",
    cursor: "pointer",
    width: "auto",
    textAlign: "center",
    alignItems: "center",
    display: "flex"
  },
  labelss: {
    color: "#676767CC",
    fontSize: "20px",
    lineHeight: "19px",
    fontFamily: "Segoe UI",
  },
  criterias: {
    color: "#3CAA49",
    fontFamily: "Segoe UI",
    fontSize: "22px",
    lineHeight: "25px",
    paddingTop: "0px",
    paddingBottom: "25px",
    cursor: "pointer",
    width: "auto"
  },
  criteriaTexts: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    resize: 'none',
    padding: '10px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    marginTop: '15px',
    width: '100%',
    height: "88px"
  },
  buttonContainers: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    marginTop: "30px"
  },
  createButtons: {
    backgroundColor: '#3CAA49',
    color: '#FFFFFF',
    width: '180px',
    height: '56px',
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none"
  },
  cancelButtons: {
    backgroundColor: '#FFFFFF',
    border: "1px solid #3CAA49",
    height: '56px',
    color: '#3CAA49',
    fontSize: "14px",
    width: '124px',
    fontFamily: "Segoe UI Bold",
    textTransfer: "none"
  },
  errorMessagess: {
    color: "#6767673",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  errorMessages: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  errorMessagesError: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    paddingLeft: "8px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  overlays: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contents: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '566px',
    borderRadius: '8px',
    height: '350px'
  },
  contentsMainpopup: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    padding: "39.5px 171.5px 39.5px 171.5px",
    borderRadius: '8px',
    maxWidth: '580px',
  },
  modalStyles: {
    fontSize: '12px',
  },
  popupIMGs: {
    display: "flex",
    margin: "0 auto",
  },
  popupIMGMains: {
    margin: "0px",
    display: "flex",
    justifyContent: "center"
  },
  actionsStyles: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  popuptexts: {
    margin: "0px",
    fontSize: '32px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  deletePopup: {
    marginTop: "85px"
  },
  popuptextsss: {
    fontSize: '20px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: '#181818',
    LineHeight: '24px',
  },
  plateformExploreBtn: {
    height: "60px",
    width: "166px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "none",
    marginRight: "20px",
    LineHeight: '24px',
  },
  plateformListingBtn: {
    height: "60px",
    width: "120px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  signlinks: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  popuptextss: {
    fontSize: '18px',
    textAlign: "center",
    fontWeight: "Segoe UI",
    color: "#181818",
    marginTop: "5px"
  },
  popuptextsGoToS: {
    margin: "5px",
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: '##000000',
    lineHeight: '24px'
  },
  popuptextListingCreating: {
    fontSize: '28px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#181818",
    LineHeight: "24px"
  },
  closeStyle: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: '2px 5px',
    lineHeight: '20px',
    right: '-10px',
    top: '-10px',
    fontSize: '24px',
    background: '#ffffff',
    borderRadius: '18px',
    border: '1px solid #cfcece',
  },
  imageses: {
    width: '100%',
    display: "flex",
    flexDirection: "row"
  }
}

export default class LookingSomething extends LookingSomthingControllerWeb {
  renderErrorMessage=(message: any) =>{

    return (
      <>
        {<Typography style={webStyles.errorMessages as React.CSSProperties}>
          {message}
        </Typography>}
      </>

    );
  }
  renderErrorMessages=(message: any) =>{
    return (
      <>
        {<Typography style={webStyles.errorMessagesError as React.CSSProperties}>
          {message}
        </Typography>}
      </>

    );
  }
  renderErrorMessageExploreButton() {

    return (
      <>
        <Box style={{ textAlign: "center" }}>
          <Button style={webStyles.plateformExploreBtn as React.CSSProperties} onClick={this.handleKeepListing}>Keep Listing</Button>
          <Button style={webStyles.plateformListingBtn as React.CSSProperties} data-testid="handleListing" onClick={this.handleDeleteData}>Delete</Button>
        </Box>
      </>

    );
  }
  handleFileChanges = (e:any) => {
    this.setState({ imagePreviewssErrors: "" });
    const files = e.target.files;
    const validFiles = Array.from(files).filter((file:any) => file.type.includes('image/'));

    if (validFiles.length > 0) {
      const newSelectedFiles = [...this.state.selectedFiless, ...validFiles];
      this.setState({ selectedFiless: newSelectedFiles });

      const newImagePreviews = [...this.state.imagePreviewss, ...validFiles.map((file:any) => URL.createObjectURL(file))];
      this.setState({ imagePreviewss: newImagePreviews });
    }
  };

  renderErrorMessageDiv() {

    return (
      <>
        <p style={webStyles.popuptextsss as React.CSSProperties} >Are you sure you want to delete ?</p>
      </>
    );
  }

  renderListingCreated() {
    return (
      <div style={webStyles.popuptextListingCreating as React.CSSProperties} >
        Listing Created!
      </div>
    )
  }
  renderSubCategoryOptionss() {
    return this.state.subCategoryApiDatas?.map((item) => {
      return (
        <option key={item.attributes.id} value={item.attributes.name}>
          {item.attributes.name}
        </option>

      )
    });
  }
  renderCategoryOptions() {
    return this.state.categoryApiDatas?.map((item) => (
      <option key={item.attributes.id} value={item.attributes.name}>
        {item.attributes.name}
      </option>
    ));
  }
  render() {
    const datacontent = this.props.navigation.getParam('id');
    return (
      <>
        <Box width="100%">
          <AppHeader navigation={this.props.navigation.navigate} />
          <div style={{ alignSelf: 'center', padding: 72 }}>
            {this.state.LoaderLooking && datacontent ?
              (<div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress style={{ color: '#4fa94d' }} />
                </Box>
              </div>) :
              <Grid container>
                <Grid item xs={12}>
                  <ListingCreations>
                    Listing Creation
                  </ListingCreations>
                </Grid>
                <Grid item xs={12}>
                  <InformationTexts>
                    Enter the required information below to create a listing.
                  </InformationTexts>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                  <DescribeProductTexts>
                    Describe the product
                  </DescribeProductTexts>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={webStyles.labels as React.CSSProperties}>
                    Listing Title <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                  </Typography>
                  <CustomTextFields
                    variant="outlined"
                    placeholder=""
                    type="text"
                    name="listingTitles"
                    value={this.state.listingTitles}
                    onChange={this.handleInputChanges}
                    style={{
                      width: "100%",
                      marginTop: "15px"
                    }}
                    InputProps={{
                      style: placeholderStyles,
                    }}
                  />
                  {this.renderErrorMessage(this.state.listingTitlesErrors)}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyles.labels as React.CSSProperties}>
                      Budget <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                    </Typography>
                    <CustomTextFields
                      variant="outlined"
                      name="budgetDatas"
                      placeholder=""
                      type="text"
                      style={{
                        width: "100%",
                        marginTop: "15px"
                      }}
                      InputProps={{ style: placeholderStyles }}
                      value={this.state.budgetDatas}
                      onChange={this.handleInputChanges}
                    />
                    {this.state.selectedCriteriaListErrorss === "Budget should not be more than £1,000,000" ? 
                    this.renderErrorMessage(this.state.selectedCriteriaListErrorss): 
                    this.renderErrorMessage(this.state.budgetDatasErrors)}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyles.labels as React.CSSProperties}>
                      Reward <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                    </Typography>
                    <CustomTextFields
                      variant="outlined"
                      name="commissionDatas"
                      placeholder=""
                      type="text"
                      style={{
                        width: "100%",
                        marginTop: "15px"
                      }}
                      InputProps={{ style: placeholderStyles }}
                      value={this.state.commissionDatas}
                      onChange={this.handleInputChanges}
                    />
                    { this.renderErrorMessage(this.state.commissionDatasErrors)}
                    {
                    this.state.commissionDatasErrorsRequired === "Reward should not be more than £100,000" || "Reward should not be more than the budget"?
                    this.renderErrorMessage(this.state.commissionDatasErrorsRequired) :
                    this.renderErrorMessage(this.state.commissionDatasErrors)
                  }

                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyles.labels as React.CSSProperties}>
                      Category <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                    </Typography>
                    <Box style={{ position: 'relative' }}>
                      <img src={arrowDown} style={{ position: 'absolute', top: 25, right: 10 }} />
                      <SelectTextFields
                        name="selectedCategorys"
                        style={webStyles.Selects}
                        value={this.state.selectedCategorys}
                        onChange={this.handleInputChanges}
                      >
                        {this.state.selectedCategorys.length == 0 && <option  hidden></option>}
                        {this.renderCategoryOptions()}
                      </SelectTextFields>
                    </Box>
                    {this.renderErrorMessage(this.state.categoryApiDatasErrors)}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyles.labels as React.CSSProperties}>
                      Sub-category <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                    </Typography>
                    <Box style={{ position: 'relative' }}>
                      <img src={arrowDown} style={{ position: 'absolute', top: 25, right: 10 }} />
                      <SelectTextFields
                        name="selectedSubCategorys"
                        style={webStyles.Selects}
                        value={this.state.selectedSubCategorys}
                        onChange={this.handleInputChanges}
                      >
                        {this.state.selectedSubCategorys?.length == 0 && <option  hidden></option>}
                        {this.renderSubCategoryOptionss()}
                      </SelectTextFields>
                    </Box>
                    {this.renderErrorMessage(this.state.subCategoryApiDatasErrors)}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={webStyles.labels as React.CSSProperties}>
                    Location <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                  </Typography>
                  <CustomTextFields
                    name="locationDatas"
                    variant="outlined"
                    placeholder=""
                    type="text"
                    style={{
                      width: "100%",
                      marginTop: "15px",
                      marginRight: "8px"
                    }}
                    InputProps={{ style: placeholderStylesLocation }}
                    value={this.state.locationDatas}
                    onChange={this.handleInputChanges}
                  />
                  {this.renderErrorMessage(this.state.locationDatasErrors)}
                </Grid>
                <Grid item xs={12}>
                  <Typography style={webStyles.labels as React.CSSProperties}>
                    Description <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                  </Typography>
                  <textarea
                    name="descriptionDatas"
                    style={{...webStyles.descriptionTexts as React.CSSProperties, color: "#64748B"}}
                    placeholder="Type here..."
                    value={this.state.descriptionDatas}
                    onChange={this.handleInputChanges}
                  >
                  </textarea>
                  {this.renderErrorMessage(this.state.descriptionDatasErrors)}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={webStyles.labels as React.CSSProperties}>
                      Upload product images <span style={webStyles.errorMessagess as React.CSSProperties}> *</span>
                    </Typography>
                  </Grid>
                  <div style={{
                    display: "flex", flexDirection: "column", marginLeft: "12px"
                  }}>
                      <div style={{ display: "flex" }}>
                      {this.state.imagePreviewss?.length > 0 ? (
                        <Grid item xs={12} style={{ width: '100%', display: 'flex', flexDirection: 'row',  flexWrap:"wrap" }}>
                          {this.state.imagePreviewss.map((url:any, index:number) => (
                            <div key={index} style={{ position: 'relative', margin: '8px' }}>
                              <img src={url.url?url.url:url} alt={`Preview ${index}`} style={{ width: '200px', height: '200px', objectFit: "contain" }} />
                              <img
                                src={ImageCrossIcon}
                                data-test-id={`remove-${index}`}
                                onClick={() => this.removeImages(url.id, index)}
                                style={{
                                  position: 'absolute', top: '-15px', right: '-17px', cursor: 'pointer', width: "44px", height: "44px"
                                }}
                              />
                            </div>
                          ))}
                        </Grid>
                      ) : (
                        <img src={dummyImg} alt="" />
                      )}
                    </div>
                    {this.renderErrorMessage(this.state.imagePreviewssErrors)}
                    <div>
                      <div>
                        <Grid item xs={12} sm={6}>
                          <label style={webStyles.customfileuploads as React.CSSProperties}>
                            <Typography style={webStyles.customfileuploadHeadings as React.CSSProperties}>
                              <img src={imageDirectSending} alt="" style={{ paddingRight: "10px" }} /> Upload
                            </Typography>
                            <input type="file" accept="image/*" multiple onChange={this.handleFileChanges} style={{ display: 'none' }} />
                          </label>
                        </Grid>
                      </div>
                    </div>
                  </div>
                  <Grid item xs={12}>
                    <hr style={{ background: '#E5E7EB' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <DescribeProductText>
                      Add your approval criterion
                    </DescribeProductText>
                    <Typography style={webStyles.labels as React.CSSProperties}>
                      Define your specific approval criteria to ensure efficient decision-making.

                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    {this.state.criteriaLists?.map((criteria: any, index: any) => {
                      return (
                        <>
                          <React.Fragment key={index}>
                            <Grid item xs={11}>
                              <CustomTextFields
                                name={`termsPurchase-${index}`}
                                data-test-id="successbuttonchange"
                                variant="outlined"
                                placeholder=""
                                type="text"
                                style={{
                                  width: '100%',
                                  paddingTop: '10px',
                                }}
                                InputProps={{
                                  style: placeholderStyles,
                                }}
                                value={criteria.title}
                                onChange={(event) => this.handleCriteriaChanges(index, event.target.value)}
                              />
                              
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              container
                              justifyContent="center"
                              alignItems="center"
                              style={{ cursor: index === 0 ? 'not-allowed' : 'pointer' }}
                            >
                              <img
                                src={deletesIcon}
                                alt=""
                                onClick={() => this.handleDeletes(index)}
                                style={{ cursor: 'pointer', width: "32px", height: '32px' }}
                                data-test-id="LookingListingButton"
                              />
                            </Grid>
                          </React.Fragment>
                        </>
                      )
                    }
                    )}
                     {this.state.selectedCriteriaListErrors && (
                            this.renderErrorMessages(this.state.selectedCriteriaListErrors)
                      )}
                    <Grid item xs={12}>
                      <div onClick={this.handleAddCriterias}>
                        <Typography style={webStyles.criterias as React.CSSProperties}>
                          + add new criterion
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <hr style={{ background: '#E5E7EB' }} />
                  </Grid>
                </Grid>


                <Grid item xs={12}>
                  <DescribeProductTexts>
                    Add your preferences
                  </DescribeProductTexts>
                  <Typography style={webStyles.labelss as React.CSSProperties}>
                    Please add your personal preferences if any.
                  </Typography>
                  <textarea
                    name="preferencesDatas"
                    style={{...webStyles.criteriaTexts as React.CSSProperties, color: "#64748B"}}
                    placeholder="Write your preferences here..."
                    value={this.state.preferencesDatas}
                    onChange={this.handleInputChanges}
                  >
                  </textarea>
                </Grid>
                <Grid item xs={12} style={webStyles.buttonContainers}>
                  <Button
                    variant="contained" style={webStyles.createButtons}
                    onClick={this.handleCreateListings}
                    data-test-id="createListingButton"
                  >
                    {datacontent ? 
                    (<span style={{ textTransform: 'none' }}>Save Listing</span>) :
                    (<span style={{ textTransform: 'none' }}>Create Listing</span>)}
                  </Button>
                  <div style={webStyles.modalStyles as React.CSSProperties}>
                    <div style={webStyles.actionsStyles as React.CSSProperties}>
                      <Popup
                        open={this.state.isCreateButtonClickeds}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.closePopups}
                        className="popup"
                        contentStyle={webStyles.contentsMainpopup}
                        overlayStyle={webStyles.overlays}
                      >

                        <div>
                          <div style={webStyles.popupIMGMains as React.CSSProperties} >
                            <img src={imagePopupSomething} />
                          </div>
                          <div>
                            <p style={webStyles.popuptexts as React.CSSProperties} >{this.state.popUpMsgs}</p>
                          {this.renderListingCreated()}
                            <p style={webStyles.popuptextsGoToS as React.CSSProperties} >Go to
                              <Link style={webStyles.signlinks as React.CSSProperties}
                                onClick={this.handleMyListings}>
                                My Listing
                              </Link>
                            </p>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </div>
                  <Button id="create-listing-button" variant="contained" style={webStyles.cancelButtons} onClick={this.handleCancelListing}>
                    <span style={{ textTransform: 'none' }}>{datacontent ? 'Delete' : 'Cancel'}</span>
                  </Button>
                  <div style={webStyles.modalStyles as React.CSSProperties}>
                    <div style={webStyles.actionsStyles as React.CSSProperties}>
                      <Popup
                        open={this.state.showPopupsDelete}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.closePopupsDelete}
                        className="popup"
                        contentStyle={webStyles.contents}
                        overlayStyle={webStyles.overlays}
                      >

                        <div style={webStyles.deletePopup as React.CSSProperties}>
                          <p style={webStyles.popuptexts as React.CSSProperties} > Delete Confirmation</p>
                          <div>
                            {this.renderErrorMessageDiv()}
                          </div>
                          {this.renderErrorMessageExploreButton()}
                        </div>
                      </Popup>
                    </div>
                  </div>
                </Grid>
              </Grid>
            }
          </div>
        </Box>
        <Box>
          <AppFooter naviagtion={this.props.navigation.navigate} />
        </Box>
      </>
    );
  }
}
// Customizable Area End