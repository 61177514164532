Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.errorReTypePasswordNotValids = "Re-type password not valids.";
exports.hintCountryCode = "Select Country";
exports.hintCode = "Country";
exports.negotiationdataapi = "/bx_block_profile/profiles/show_user_profile";
exports.errorBlankField = "can't be blank";
exports.errorBlankFieldData = "show error";
exports.errorBlankFields = "Empty field is can't to be blank";
exports.errorBlankFieldsEmpty = "Text area";
exports.errorBlankFields = "can't be blanks";
exports.errorBlankFieldss = "Field is filled";
exports.listingTypes = "Listing Type";
exports.errorBlankFieldsTexts = "Field is filledss Texts";
exports.errorBlankFieldsText = "Field is filledss";
exports.errorBlankFieldsTextStart = "Field is filleds Empty";
exports.validationApiMethodTypes = "this is correct";
exports.validationApiMethodTypesw = "this is corrects";
exports.amedentApiMethodTypes = "the adementdeals";
exports.errorEmailNotValid = "Email is not valid.";
exports.orderHistoryApiEndPoint = "/bx_block_paypal/order_history";
// Customizable Area End

