// Customizable Area Start
import React from "react";
import { Box, Breadcrumbs, Button, Grid, CircularProgress, Link, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import ViewListingController from "./ViewListingController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { Look, Sell, editImages, profiles } from "./assets";
export default class ViewListingWeb extends ViewListingController {
  changeEditOnclicks() {
    return (

      this.state.listing_type === "looking_for_something" ? <img src={editImages} style={{
        width: "29.1px",
        height: "29px",
        cursor: 'pointer'
      }} alt="Card Image" data-testid="editButton" onClick={this.handlEditClick.bind(this)} /> : <img src={editImages} style={{
        width: "29.1px",
        height: "29px",
        cursor: 'pointer'
      }} alt="Card Image" data-testid="editButtonselling" onClick={this.handlEditClickSelling.bind(this)} />
    );
  }
  buttonSave() {
    return (
      <span style={{ textTransform: 'none' }}>Message Lister</span>
    )
  }
  buttonSaveLocation() {
    return (
      <Typography style={webStyle.catagoryText as React.CSSProperties}>
        {this.state.cityData == null ? "__" : this.state.cityData} , {this.state.location == null? "__" : this.state.location}
      </Typography>
    )
  }
formatNumber = (number:number) => {
    return new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  render() {
    const theme = createTheme();
    const { budget, commission } = this.state;
    const formattedBudget = this.formatNumber(budget);
    const formattedCommission = this.formatNumber(commission);
    return (
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation.navigate} />
        <Box style={{ padding: '12px 48px' }}>
          {this.state.LoaderView ?
            (<div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ color: '#4fa94d' }} />
              </Box>
            </div>) :
            <>
              <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href="/" style={{ color: '#67676799' }}>
                    Home
                  </Link>
                  <Typography color="textPrimary" style={{ color: '#67676799' }}>
                    {this.state.category}
                  </Typography>
                  <Typography color="textPrimary" style={{ color: '#67676799' }}>
                    {this.state.sub_category}
                  </Typography>
                  <Typography color="textPrimary" style={{ color: '#3CAA49' }}>
                    {this.state.title}
                  </Typography>
                </Breadcrumbs>
                {this.state.account_id == this.state.idsLocal ? <>{
                  this.changeEditOnclicks()
                }</> : ""}
              </Box>
              <Box >
                <Grid container spacing={3} style={{ marginTop: '20px' }}>
                  <Grid item xs={12} lg={4} md={6} sm={12} xl={4}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "right",
                          objectFit: 'cover'
                        }}
                      >
                        <img style={webStyle.imgCover as React.CSSProperties} src={this.state?.photo[0]?.url} alt="" height= {350} width="100%"/>
                      </Grid>
                      <Grid container spacing={2}>
                        {this.state.photo.slice(1).map((item:any, index:any) => {
                          return (
                            <Grid item key={index} xs={6} lg={4} sm={6} md={6} xl={4}>
                              <img
                                src={item.url}
                                alt=""
                                height={100}
                                width="100%"
                                style={{
                                  marginBottom: '10px',
                                  marginRight: '1px',
                                  objectFit: "contain"
                                }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={8} md={6} sm={12} xl={8}>
                    <div style={webStyle.label}>
                      <Typography style={webStyle.title as React.CSSProperties} >
                        {this.state.title}
                      </Typography>
                      <div style={{
                        borderBottom: "0.5px solid #E5E7EB"
                      }}>
                        <Typography style={webStyle.listingtype as React.CSSProperties} >
                          {
                            this.state.listing_type === "looking_for_something" ? <> <img src={Look} alt="" style={{ paddingRight: '20px' }} /><Typography style={{ paddingTop: '5px' }}>Looking for something</Typography></> : <> <img src={Sell} alt="" style={{ paddingRight: '20px' }} /><Typography style={{ paddingTop: '5px' }}>Selling something</Typography></>
                          }
                        </Typography>
                        <hr style={webStyle.hr as React.CSSProperties} />
                        <Typography style={webStyle.budget as React.CSSProperties} >
                          {this.state.listing_type === "looking_for_something" ? <Typography style={webStyle.budgetheading as React.CSSProperties}>
                            Budget
                            <span style={webStyle.budgetText as React.CSSProperties}>
                              £ {formattedBudget}
                            </span>
                          </Typography> : <Typography style={webStyle.budgetheading as React.CSSProperties}>
                            Price
                            <span style={webStyle.budgetText as React.CSSProperties}>
                              £ {formattedBudget}
                            </span>
                          </Typography>}

                          <Typography style={webStyle.budgetheading as React.CSSProperties}>
                            Reward
                            <span style={webStyle.budgetText as React.CSSProperties}>
                              £ {formattedCommission}
                            </span>
                          </Typography>
                        </Typography>
                      </div>

                      <Typography style={webStyle.catagory as React.CSSProperties} >
                        <Typography style={webStyle.catagoryData as React.CSSProperties}>
                          <Typography style={{ fontStyle: "italic" }}>Category</Typography>
                          <Typography style={webStyle.catagoryText as React.CSSProperties}>
                            {this.state.category}
                          </Typography>
                        </Typography>
                        <Typography style={webStyle.catagoryData as React.CSSProperties}>
                          <Typography style={{ fontStyle: "italic" }}>Sub-Category</Typography>
                          <Typography style={webStyle.catagoryText as React.CSSProperties}>
                            {this.state.sub_category}
                          </Typography>
                        </Typography>
                        <Typography style={webStyle.catagoryData as React.CSSProperties}>
                          <Typography style={{ fontStyle: "italic" }}>Location</Typography>
                          {this.buttonSaveLocation()}
                        </Typography>
                      </Typography>
                      <hr style={webStyle.hr as React.CSSProperties} />

                      <Typography style={webStyle.discrtiption as React.CSSProperties}>
                        Description
                      </Typography>
                      <Typography style={webStyle.discrtiptionText as React.CSSProperties}>
                        {this.state.description}
                      </Typography>

                    </div>
                  </Grid>
                  <Grid style={{ width: "100%" }}>
                    <hr style={webStyle.hr1 as React.CSSProperties} />
                    <Box>
                      <Typography style={webStyle.discrtiption as React.CSSProperties}>
                        Lister
                      </Typography>
                      <Grid container style={{ padding: '20px 0', alignItems: 'center' }}>
                        <Grid item><img style={webStyle.userPhoto} src={this.state.userDetails.photo === null ?  profiles : this.state.userDetails.photo} alt="" /></Grid>
                        <Grid item>
                          <Typography><span style={webStyle.fullName} data-test-id="redirect" onClick={this.onOtherUserRedirection}>{this.state.userDetails.full_name}</span>/<span style={webStyle.userName}>@{this.state.userDetails.user_name}</span></Typography>
                        </Grid>
                      </Grid>
                      <Typography style={webStyle.cityCountry}>{this.state.userDetails.city == null ? "__" : this.state.userDetails.city} , <span style={webStyle.cityCountry}>{this.state.userDetails.country == null ? "__" : this.state.userDetails.country}</span></Typography>
                    </Box>
                    <hr style={webStyle.hr1 as React.CSSProperties} />
                    {
                      this.state.listing_type === "looking_for_something" ? <><Typography style={webStyle.Approval as React.CSSProperties}>
                        Approval Criterion
                      </Typography></> : <>
                        <Typography style={webStyle.Approval as React.CSSProperties}>
                          Terms of Purchase
                        </Typography>
                      </>
                    }
                    <Typography style={webStyle.ApprovalText as React.CSSProperties}>
                      {this.state.approval_criterion?.map((item: any, index: any) => (
                        <>
                          <div key={index} style={{
                            borderBottom: "0.5px solid #E5E7EB", padding: "15px", textAlign: "justify",
                          }}>
                            <span style={{ marginRight: "10px" }}>{index + 1}.</span>
                            {item.title == "undefined" ? "" : item.title}
                          </div>
                        </>
                      ))}
                    </Typography>
                    <Box mb={!this.state.preferences && this.state.account_id !== this.state.idsLocal && "75px"} />
                    {this.state.preferences &&
                      <>
                        <Typography style={webStyle.Preferences as React.CSSProperties}>
                          Preferences
                        </Typography>
                        <Box mb={this.state.account_id !== this.state.idsLocal && "75px"}>
                          <Typography style={webStyle.PreferencesText as React.CSSProperties}>
                            {this.state.preferences}
                          </Typography>
                        </Box>
                      </>
                    }
                  </Grid>
                </Grid>
              </Box>
              {this.state.account_id == this.state.idsLocal ? <>
              </>
                : <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0 80px 0', marginTop: "40px" }}>
                  <div style={{ marginRight: '10px' }}>
                    <Button
                      variant="contained" style={this.state.intiatedDeals ? webStyle.createButtons11 : webStyle.createButtons}
                      disabled={this.state.intiatedDeals}
                      onClick={() => {
                        this.handleClickForBuyer()
                      }}
                      data-testid="dealButton"
                    >
                      <span style={{ textTransform: 'none' }}>
                        {this.state.intiatedDeals ? "Initiate Deal Already Taken" : "Initiate Deal"}
                      </span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="contained" style={webStyle.createButtons2}
                      onClick={() => {
                        this.MessageNpProfile(this.state.id)
                      }}
                      data-testid="RemoveButton"
                    >
                      {this.buttonSave()}
                    </Button>
                  </div>
                </div>

              }
            </>
          }
        </Box>
        <AppFooter naviagtion={this.props.navigation.navigate} />
      </ThemeProvider>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
  imgCover: {
    objectFit: "contain"
  },
  title: {
    fontSize: "38px",
    color: "#676767",
    fontFamily: "Segoe UI Bold",
  },
  label: {
    paddingLeft: "20px"
  },
  listingtype: {
    color: "#676767",
    fontSize: "18px",
    fontFamily: "Segoe UI",
    paddingBottom: "10px",
    display: 'flex',
    textAlign: 'center',
    marginTop: "10px"
  },
  budget: {
    padding: "15px 0px",
    display: "flex"
  },
  budgetText: {
    color: "#3CAA49",
    fontSize: "26px",
    fontFamily: "Segoe UI Bold",
    padding: "0 20px 0 10px"
  },
  budgetheading: {
    color: "#676767CC",
    fontSize: "20px",
    fontFamily: "Segoe UI",
    paddingRight: "10px",
  },
  catagory: {
    padding: "15px 0px",
    display: "flex",
    gap: "96px"
  },
  createButtons: {
    backgroundColor: '#3CAA49',
    color: '#FFFFFF',
    width: '180px',
    height: '56px',
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    borderRedius: '5px'
  },
  createButtons11: {
    backgroundColor: 'rgba(60, 172, 73, 0.3)',
    color: '#FFFFFF',
    width: '180px',
    height: '56px',
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    borderRedius: '5px'
  },
  createButtons2: {
    color: '#3CAA49',
    width: '199px',
    background: "#FFFFFF",
    height: '56px',
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    border: '1px solid #3CAA49',
    borderRedius: '5px'
  },
  catagoryData: {
    color: "#67676799",
    // paddingRight: "50px",
    fontSize: "16px",
    fontFamily: "Segoe UI",

  },
  catagoryText: {
    color: "#676767",
    fontSize: "18px",
    fontFamily: "Segoe UI",
    paddingBottom: "10px",
  },
  discrtiption: {
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    fontStyle: "italic"
  },
  discrtiptionText: {
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    textAlign: "justify",
    overflowY: "auto",
    maxHeight: "300px",
    paddingRight: "20px"
  },
  Approval: {
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
    fontStyle: "italic"
  },
  ApprovalText: {
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
  },
  Preferences: {
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
    fontStyle: "italic"
  },
  PreferencesText: {
    marginTop: "20px",
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    textAlign: "justify",
  },
  hr: {
    border: "none",
    height: "1px",
    backgroundColor: "#E5E7EB",
  },
  hr1: {
    border: "none",
    height: "1px",
    backgroundColor: "#E5E7EB",
    margin: "20px 0px",
  },
  photo: {
    // display: "flex",
  },
  userPhoto: {
    height: '52px',
    width: '52px',
    borderRadius: '50%'
  },
  fullName: {
    cursor: "pointer",
    color: "#3CAA49",
    fontSize: "20px",
    fontFamily: "Segoe UI Bold",
    padding: '0 10px'
  },
  userName: {
    color: "#676767",
    fontSize: "20px",
    fontFamily: "Segoe UI",
    paddingLeft: '5px'
  },
  cityCountry: {
    color: "#676767",
    fontSize: "20px",
    fontFamily: "Segoe UI",
  }
};

// Customizable Area End