import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  // Customizable Area Start
}

export interface S {
  // Customizable Area Start
  otpAuthToken: string;
  data: any[]
  title: any;
  listing_type: any;
  budget: any;
  commission: any;
  category: any;
  sub_category: any;
  location: any;
  description: any;
  account_id: string;
  id: string;
  idsLocal: any;
  userDetails:any;
  photo: any;
  approval_criterion: any[];
  preferences: any;
  cityData: string;
  someListing: any[];
  alreadyIntitiated: any[];
  intiatedDeals : boolean,
  LoaderView: boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiViewListingItemCallIdss: string = "";
  addLookingSomthingCallIds: string = "";
  initiate_dealsCallIDss: string = "";
  getMessageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      otpAuthToken: "",
      data: [],
      title: "",
      listing_type: "",
      budget: "",
      commission: "",
      category: "",
      sub_category: "",
      account_id: "",
      id:"",
      idsLocal: null,
      location: "",
      cityData: "",
      description: "",
      photo: [],
      approval_criterion: [],
      preferences: "",
      someListing: [],
      userDetails:{},
      alreadyIntitiated: [],
      intiatedDeals : false,
      LoaderView: false,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiViewListingItemCallIdss) {
        if (responseJson && !responseJson.errors) {
          this.setState({ LoaderView: false });
          this.setState({
            title: responseJson.data.attributes.title,
            listing_type: responseJson.data.attributes.listing_type,
            budget: responseJson.data.attributes.budget,
            commission: responseJson.data.attributes.commission,
            category: responseJson.data.attributes.category,
            sub_category: responseJson.data.attributes.sub_category,
            location: responseJson.data.attributes.user_details.data.attributes.country,
            cityData: responseJson.data.attributes.user_details.data.attributes.city,
            description: responseJson.data.attributes.description,
            photo: responseJson.data.attributes.photo,
            approval_criterion: responseJson.data.attributes.criteria_or_terms,
            preferences: responseJson.data.attributes.preferences,
            account_id: responseJson.data.attributes.account_id,
            id: responseJson.data.id,
            userDetails:responseJson.data.attributes.user_details.data.attributes
          });
        }
      }
      if (apiRequestCallId === this.initiate_dealsCallIDss) {
        if (responseJson && !responseJson.errors) {
          this.setState({ alreadyIntitiated: responseJson.data})
        }
      }
      if (apiRequestCallId === this.getMessageApiCallId) {
        this.setState({ LoaderView: false });
        if (responseJson && !responseJson.errors){
        {localStorage.setItem("navMsgId",responseJson.data.id)}
          this.props.navigation.navigate("UserProfileBasicBlock", "?tab=inbox");
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const contents = this.props.navigation.getParam('id');
    const isTrue = localStorage.getItem("urlRedirect") === window.location.pathname;
    if(isTrue){
      localStorage.removeItem("urlRedirect");
      this.MessageNpProfile(contents, true);
      return;
    }
    this.viewDataListing(contents)
    const localID = await localStorage.getItem('id')
    this.setState({ idsLocal: localID })
    this.initiateDealsIndexss()
    window.scrollTo(0, 0);
  }

  onOtherUserRedirection = () => {
    this.props.navigation.navigate("OtherUserDetails", { id: this.state.userDetails.account_id });
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handlEditClick() {
    const id = this.props.navigation.getParam('id');
    const userToken = localStorage.getItem("authToken");
    if(!userToken){
    localStorage.setItem("LookingForSomething", "LookingSomethings");
    localStorage.setItem("ids", id);
    }
    this.props.navigation.navigate('LookingSomethings', { id: id })
  }
  handlEditClickSelling() {
   
    const id = this.props.navigation.getParam('id');
    const userToken = localStorage.getItem("authToken");
    if(!userToken){
    localStorage.setItem("LookingForSomething", "SellingSomethings");
    localStorage.setItem("ids", id);
    }
    this.props.navigation.navigate('SellingSomethings', { id: id })
  }
  handleClickForBuyer() {
    const id = this.props.navigation.getParam('id');
    const userToken = localStorage.getItem("authToken");
    const routeName = "ViewListing";

    if(!userToken){
    localStorage.setItem("LookingForSomething", routeName);
    localStorage.setItem("ids", id);
    }
    const arrNegotiate = this.state.alreadyIntitiated?.map((item) => {
        return item.local_listing_ad_id;
    });
    const idToCheck = typeof arrNegotiate?.[0] === 'number' ? parseInt(id) : id;
    if(arrNegotiate?.includes(idToCheck))
    {
     this.setState({intiatedDeals : true})
    }
    else {
      this.props.navigation.navigate('NegotiationSellerToBuyer', { id: id })
    }
}
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  viewDataListing = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewListingItemCallIdss = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/local_listing_ads/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ LoaderView: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  initiateDealsIndexss = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.initiate_dealsCallIDss = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/initiate_deals"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  MessageNpProfile = (id: string, loader?: boolean) => {
    this.setState({ LoaderView: !!loader });
    const token = localStorage.getItem("authToken");
    if(!token) {
      localStorage.setItem("urlRedirect", `${window.location.pathname}`);
      this.props.navigation.navigate("EmailAccountLoginBlock");
      return;
    }
    // localStorage.setItem("navMsgId",id)
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const httpBody = {
      local_listing_ad_id: id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_chat/chats/message_lister`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
