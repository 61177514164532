import React from 'react';
import { Box, Container, Grid, Typography, styled } from "@material-ui/core";

export function AppFooter({ naviagtion }: any) {
    const auth = localStorage.getItem("authToken");
    return (
        <Box style={styles.container}>
            <Box
            >
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" >
                        <Grid item xs={12} md={4}>
                            <Typography >
                                <img
                                    src={require('./newLogo.png')}
                                    onClick={() => { auth ? naviagtion("LandingPage") : naviagtion("LandingPageMain") }}
                                    alt="Logo"
                                    style={styles.image}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="textSecondary" variant="subtitle1">
                                <CustomUl>
                                <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/AddContactusNew`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      naviagtion("AddContactus")
                    }
                  }}>
                                    <li style={{ listStyleType: "none", cursor: "pointer" }} >Contact us</li></a>
                                    <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/Interactivefaqs`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      naviagtion("Interactivefaqs")
                    }
                  }}>
                                    <li style={{ listStyleType: "none", cursor: "pointer" }} >FAQ</li></a>
                                    <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/RefundPolicy`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      naviagtion("RefundPolicy")
                    }
                  }}>
                                    <li style={{ listStyleType: "none", cursor: "pointer" }} >Refund policy</li></a>
                                    <a style={{ textDecoration: 'none', color: 'inherit',display:"flex" }}   href={`/TermsConditionsDetail`} onClick={(event) => {
                    if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
                      event && event.preventDefault();
                      naviagtion("TermsConditionsDetail")
                    }
                  }}>
                                    <li style={{ listStyleType: "none", cursor: "pointer" }} > Terms and Conditions</li></a>
                                </CustomUl>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ alignSelf: "end", paddingBottom: "20" }}>
                            <Box display="flex" alignItems="center" justifyContent={{ sm: "center", md: "right" }}>
                                <Box>
                                    <li style={{ listStyleType: "none", margin: "0px 5px" }}><a target='_blank' href="https://x.com/"><img width="35px" height="35px" src={require('./x.png')} alt="" /></a> </li>
                                </Box>
                                <Box>
                                    <li style={{ listStyleType: "none", margin: "0px 5px" }}><a target='_blank' href="https://www.tiktok.com/"><img src={require('./tiktok.png')} alt="" /></a> </li>
                                </Box>
                                <Box>
                                    <li style={{ listStyleType: "none", margin: "0px 5px" }}><a target='_blank' href="https://www.instagram.com/"><img src={require('./instagram.png')} alt="" /></a> </li>
                                </Box>
                                <Box>
                                    <li style={{ listStyleType: "none",margin: "0px 5px" }}><a target='_blank' href="https://www.youtube.com/"><img src={require('./youtube.png')} alt="" /></a> </li>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <hr style={styles.hrTag} />
                    <Grid >
                        <Typography color="textSecondary" align="center">
                            © {new Date().getFullYear()} All rights reserved to Item Find Ltd.
                        </Typography>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

const CustomUl = styled("ul")({
    marginLeft: "70px",
    "@media(max-width: 960px)": {
        marginLeft: "0px",  
    }
});

const styles = {
    container: {
        height: "244px",
        backgroundColor: '#E0DAD3',
    },
    image: {
        width: 320,
        cursor:"pointer"
    },
    imageSearch: {
        width: 22,
        height: 22,
        marginLeft: -30,
    },

    imagePlus: {
        width: 25,
        height: 25.1,
    },
    imageShape: {
        width: 25,
        height: 25.1,
    },
    imageProfile: {
        width: 40,
        height: 40,
    },
    input: {
        fontSize: '14px',
        color: '#B2B2B2',
        fontFamily: 'Segoe UI',
        minHeight: '30px',
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid lightgray',
        borderRadius: 'none',
    },
    iconsImage: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '5px'
    },
    listingText: {
        margin: 0,
        fontSize: '24px',
        color: '#676767',
        fontFamily: 'Segoe UI',
        lineHeight: '24px'
    },
    hrTag: {
        color: "rgb(232, 232, 232)"
    },
    listingProfile: {
        margin: 0,
        fontFamily: "Segoe UI Bold",
        fontSize: '18px',
        color: '#000000',
        lineHeight: '24px'
    },
    footerwrap: {
        backgroundColor: '#E0DAD3',
        width: "100%",
        bottom: "o",
        margin: "1.5em 0.5em",
        paddingTop: "1em"
    }
};
