import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area End
    // Customizable Area Start
}

export interface S {
    // Customizable Area Start
    useDetailsDataS: any[];
    useDetailsDataSelling: any[];
    setInitiateDatasS: string[];
    loading: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class OtherListingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    otherUserDetails_data: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            useDetailsDataS: [],
            useDetailsDataSelling: [],
            setInitiateDatasS: [],
            loading: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJsonS = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallIdS = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallIdS === this.otherUserDetails_data) {
                if (responseJsonS && !responseJsonS.errors) {
                    const filteredDataListing = responseJsonS.data.filter((item:any) => item.attributes.listing_type === "looking_for_something")
                    const filteredSellingListing = responseJsonS.data.filter((item:any) => item.attributes.listing_type !== "looking_for_something")
                    this.setState({
                        useDetailsDataS: filteredDataListing,
                        useDetailsDataSelling: filteredSellingListing,

                    });
                }
                this.setState({  loading: false });
            }

        }

        // Customizable Area End
    }

    // Customizable Area Start

    componentDidMount(): any {
        this.setState({ loading: true });
        this.otherUserDetails()
    }
    viewDataSId(id: number) {
        this.props.navigation.navigate('ViewListing', { id: id })
    }
    otherUserDetails = async () => {
        const contentss = this.props.navigation.getParam('id');
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.otherUserDetails_data = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/local_listing_ads?user_id=${contentss}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                local_listing_ad_id: contentss
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // Customizable Area End
}