// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { CreateOrderData, CreateOrderActions, OnApproveData, OnApproveActions } from '@paypal/paypal-js';
import { History, LocationState } from 'history';
import { toast } from "react-toastify";
export const configJSON = require("./config");

interface IPrimaryData {
  id: string;
}

interface IAccount {
  data?: IPrimaryData;
}

export interface ITransactionPayload {
  account: IAccount;
  id: string;
  deliveryMethodId: string;
  orderId: string;
  payableAmount: number;
  zipcode?: string;
  address?: string;
  phoneNumber?: string;
}

export type TResponseJson = ITransactionSuccessResponse & ITransactionFailureResponse;

interface ITransactionFailureResponse {
  message: string;
}
interface ITransactionSuccessResponse {
  order: IOrder;
}

interface IOrder {
  data: IPrimaryData
}

export interface Props {
  navigation: any;
  id: string;
  history: History<LocationState>;
  amendment_deals: {
    reason: string | null;
    updated_at: string;
    id: number;
    amendment_type: string;
    status: string;
    initiate_deal_id: number;
    created_at: string;
  }[];
}

interface S {
  countdown: any;
  counter:any;
  enableField: boolean;
  accessToken: string;
  approvalUrl: string;
  paymentId: number;
  redirect: boolean;
  showTextAreas: boolean;
  buttonClickeds: boolean;
  ApprovalCriteriaButton: boolean;
  negotiateButtons: boolean;
  showTextAreaCommissions: boolean;
  showTextAreaApproval: boolean;
  showTextAreaPrefrences: boolean;
  prefrenceDenyButtonCommissions: boolean;
  buttonClickedCommissions: boolean;
  negotiateButtonCommissions: boolean;
  prefrenceButtonCommissions: boolean;
  ApprovalCriteriaDenyButton: boolean;
  selecteds: any;
  selectedDescriptions: any;
  criteria_or_termsss: any;
  datas: any;
  activeButtonss: any;
  showTextAreass: any;
  userDetailss: any;
  userDetailsIDs: any;
  amendmentDealsAttributess: any;
  acceptOrDenys: string;
  popupNegotiates: boolean;
  negotiatedReasons: string;
  textAreaValues: string;
  commissionStatuss: string;
  showPopupsLogoutNegotiations: boolean;
  showAmendments:boolean;
  priceAmendments: boolean;
  showAmendmentsApprovalss: any;
  commissionReasons: string;
  preferenceStatuss: string;
  criteria_amendment: any[];
  preferenceReasons: string;
  accountIDss: string;
  amendment_dealss: any;
  criteria_amendmentsss: any;
  buttonStatess: any;
  budgetReasons: string;
  statusAmedent: string;
  ReasonsAmedent: string;
  InitaiateId: any;
  LoaderFormApproval: boolean;
  budgetStatuss: string;
  openPopup:boolean;
  txtInputValue: string;
  txtSavedValue: string;
  ispaymentFailureModalOpen: boolean;
  ispaymentSuccessModalOpen: boolean;
  currOrderId: string | number;
  isLoading: boolean;
  showLink:boolean;
  PaymentMade:boolean;
  isRotated:boolean;
  paymentFailed:boolean;
  isModalOpen: boolean;
  dealId: null | number | string;
}

interface SS {
  id: any;}

export default class PaymentBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  FromApprovalDataCancel: string = "";
  accessTokenCallId: string = "";
  paymentdetailCallId: string = "";
  FromApprovalDataApiData: string = "";
  ApprovalFormApiDataPost: string = "";
  PaymentExecuteCallId: string = "";
  postOrderNumberId: string = "";
  ShowLinkApidata: string ="";
  closeNegotiationApiCallId: string = "";
  interval: number | undefined;
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      buttonClickeds: false,
      negotiateButtons: false,
      showTextAreaCommissions: false,
      showTextAreaApproval: false,
      showTextAreaPrefrences: false,
      negotiateButtonCommissions: false,
      prefrenceButtonCommissions: false,
      ApprovalCriteriaButton: false,
      showTextAreas: false,
      selecteds: null,
      selectedDescriptions: null,
      datas: "",
      prefrenceDenyButtonCommissions: false,
      userDetailsIDs: null,
      criteria_or_termsss: [],
      ApprovalCriteriaDenyButton: false,
      activeButtonss: Array(9).fill(null),
      buttonClickedCommissions: false,
      userDetailss: null,
      amendmentDealsAttributess: [],
      acceptOrDenys: "",
      negotiatedReasons: "",
      commissionStatuss: "accept",
      showTextAreass: Array(9).fill(false),
      commissionReasons: "",
      preferenceStatuss: "accept",
      isRotated:false,
      preferenceReasons: "",
      accountIDss: "",
      budgetStatuss: "accept",
      showPopupsLogoutNegotiations: false,
      popupNegotiates: false,
      amendment_dealss: [],
      textAreaValues: "",
      criteria_amendmentsss: [],
      LoaderFormApproval: false,
      buttonStatess: {},
      budgetReasons: "",
      criteria_amendment: [],
      statusAmedent: "accept",
      ReasonsAmedent: "",
      InitaiateId: {},
      showAmendments:false,
      priceAmendments: false,
      showAmendmentsApprovalss: {},
      enableField: false,
      accessToken: "",
      approvalUrl: "",
      redirect: false,
      countdown: 3,
      counter:6,
      paymentId: 0,
      openPopup:false,
      txtInputValue: "",
      txtSavedValue: "A",
      ispaymentFailureModalOpen: false,
      ispaymentSuccessModalOpen: false,
      currOrderId: "",
      isLoading: true,
      showLink:false,
      PaymentMade:false,
      paymentFailed:false,
      dealId: null,
      isModalOpen: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message)
   {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage) );
      if(apiRequestCallId === this.closeNegotiationApiCallId){
        if (responseJson && !responseJson.errors) {
          if (responseJson.message === "deal deleted.") {
            this.props.navigation.navigate("LandingPage");
          }
        } else if(responseJson.errors && responseJson.errors[0] && responseJson.errors[0].deal.includes("Record with id")){
          toast.success("This deal is cancelled!");
          this.props.navigation.navigate("LandingPage");
          return;
        }
      } else if (apiRequestCallId === this.FromApprovalDataApiData) {
        this.handleNegotitiatonResponseData(responseJson); }
        if (apiRequestCallId === this.postOrderNumberId) {
          if(responseJson.status === 'ok'){
          this.setRedirectTimer1(this.state.InitaiateId);
          this.setState({ ispaymentSuccessModalOpen: true });
          }
          else if (responseJson.status !== 'ok' || responseJson.errors) {
            this.setState({ paymentFailed: true,});
        }
        }
        if (apiRequestCallId === this.ShowLinkApidata) {
          if(responseJson.data && responseJson.data.is_send_link === true && !responseJson.data.link){
            const id = this.getIdFromUrl(window.location.href);
            if (localStorage.getItem(`isCongrats-${id}`) === "true") {
              this.setState({ redirect: true, isLoading: false }, () => {
                this.setRedirectTimer();
                localStorage.removeItem(`isCongrats-${id}`);
              });
            }
          }
          if (responseJson.data && responseJson.data.is_send_link === false) {
              this.setState({ showLink: true });
          } else if (responseJson.data && responseJson.data.link !== null) {
            toast.success("The Payment for this deal is already completed.");
            this.props.navigation.navigate("LandingPage");
          }
      } 
      this.setState({ isLoading: false });
    }
  }

  async componentDidMount() 
  {

    const url = window.location.href;
const id = this.getIdFromUrl(url);
this.Paymentlink(id)

    this.FromApprovalDatas(id);
    const auth = localStorage.getItem("authToken");
    this.setState({ dealId: id });
    if (!auth) 
    {
      localStorage.setItem("redirectUrl", window.location.pathname);
      this.props.navigation.navigate("EmailAccountLoginBlock");}
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  getIdFromUrl(url:any) 
  {
    const splitUrl = url.split("/");
    const ids = splitUrl[splitUrl.length - 1];
    return ids; 
  }

     handleOpenModal = () => 
     {
      this.setState({openPopup: true})
    };
  

  setRedirectTimer() {
   this.interval = setInterval(() => {
      if (this.state.countdown > 0) 
      {this.setState((prevState) => ({
          countdown: prevState.countdown - 1,
        }));
      } 
      else 
      {
        clearInterval(this.interval);
        const id = this.getIdFromUrl(window.location.href);
        localStorage.removeItem(`isCongrats-${id}`);
        this.setState({ redirect: false });
      }
      },
    1000) as unknown as number;
  }

    setRedirectTimer1(id: any) {
      let intId =setInterval(() => {
        if (this.state.counter > 0) {
          this.setState((prevState) => ({
            counter: prevState.counter - 1,
          }));
        } else {
          this.props.navigation.navigate('PaymentSuccess', { id: id });
          clearInterval(intId);
        }
      }, 1000);
    }

    toggleRotation = () => {
      this.setState((prevState) => ({
        isRotated: !prevState.isRotated
      }));
    };

  openModals = () => {
    this.setState({ isModalOpen: true });
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  }

  handleNegotitiatonResponseData(responseJson: any) {
    if(responseJson.errors && responseJson.errors[0] && responseJson.errors[0].deal.includes("Record with id")){
      toast.success("This deal is cancelled!");
      this.props.navigation.navigate("LandingPage");
      return;
    }
    if (responseJson && !responseJson.errors && responseJson.data?.attributes) {
      const listingDetail = responseJson.data.attributes.listing_detail.data.attributes;
      const id = responseJson.data.id;
      const InitaiateId = Number(id);
      this.setState({
        datas: listingDetail,
        InitaiateId: InitaiateId,
        criteria_or_termsss:
          responseJson.data.attributes.listing_detail.data.attributes
            .criteria_or_terms,
        amendment_dealss: responseJson.data.attributes.amendment_deals,
        userDetailss: listingDetail === 'looking_for_something' ?
        responseJson.data.attributes.user_detail.buyer.data.attributes :
        responseJson.data.attributes.user_detail.seller.data.attributes,
        criteria_amendmentsss: responseJson.data.attributes.criteria_amendments,
        accountIDss:
          responseJson.data.attributes.listing_detail.data.attributes
            .account_id,
        
      }); }
  }
  toggleAmendments = () => {
    this.setState((prevState) => ({
      showAmendments: !prevState.showAmendments,
    }));
  };

  onPriceAmendmentClick = () => {
    this.setState((prevState) => ({
      priceAmendments: !prevState.priceAmendments,
    }));
  }

  toggleAmendmentsApproval(id:any) {
    this.setState(prevState => ({
      showAmendmentsApprovalss: {
        ...prevState.showAmendmentsApprovalss,
        [id]: !prevState.showAmendmentsApprovalss[id], // Toggle visibility for the specific item
      },
    }));
  }

  closeNegotiation = () => {
    const header = JSON.stringify({
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.closeNegotiationApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_locallistingads/initiate_deals/${this.state.dealId}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  FromApprovalDatas = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message
    (
      getName(MessageEnum.RestAPIRequestMessage));

    this.FromApprovalDataApiData = requestMessage.messageId;

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/initiate_deals/${id}` );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,}) );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    this.setState({ 
      LoaderFormApproval: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  createOrder = (data: CreateOrderData, actions: CreateOrderActions) => {
    const { amendment_dealss } = this.state;
    const commissionDeal = amendment_dealss.filter(
      (deal: any) => deal.amendment_type === "commission"
    );
    const lastCommissionDeal = commissionDeal[commissionDeal.length - 1];
    const amountValue = lastCommissionDeal ? lastCommissionDeal.reason : '0.00'; // Default value if lastCommissionDeal is null



    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [{
        amount: {
          value: `${amountValue}`,
          currency_code: 'GBP',
        },
        
      }],
    });
  }

  onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    await this.validateApprovedData(data);
  }

  validateApprovedData = async (data: OnApproveData) => {  
    
    const orderId = data?.orderID;
  

    this.beginTransaction(orderId.toString());

  }


  beginTransaction = async (orderId: any) => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("authToken") as string;
    const commissionDeal = this.state.amendment_dealss.filter(
      (deal: { amendment_type: string; }) => deal.amendment_type === "commission");
    const lastCommissionDeal = commissionDeal[commissionDeal.length - 1];
    const amountValue = lastCommissionDeal ? lastCommissionDeal.reason : '0.00';

    const budgetDeal = this.state.amendment_dealss.filter(
      (deal: { amendment_type: string; }) => deal.amendment_type === "budget");
    const lastBudgetDeal = budgetDeal[budgetDeal.length - 1];
    const budget = lastBudgetDeal ? lastBudgetDeal.reason : '0.00';

    const requestMessage = new Message
    (
      getName(MessageEnum.RestAPIRequestMessage));

    this.postOrderNumberId = requestMessage.messageId;
    const payload = {
      "order_id": orderId,
      "initiate_deal_id": this.state.InitaiateId ,
      "amount": amountValue,
      "budget": budget
    };


    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_paypal/capture` );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,}) );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST");
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  Paymentlink = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.ShowLinkApidata = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/initiate_deals/${id}/view_product_link`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,}
        )
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;};





}

// Customizable Area End
