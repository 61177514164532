import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import InteractivefaqsControllerWeb, { Props } from "./interactiveFaqsControllerWeb"
import { contactus, faqs } from "./assets";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AddSharp, RemoveSharp } from "@material-ui/icons";
const ContactusFaq = styled(Box)({
    height: "450px",
    background: "#EFFFF7",
    "@media(max-width:768px) and (min-width: 426px)": {
        height: "700px",
    },
    "@media(max-width:960px) and (min-width: 769px)": {
        height: "800px",
    },
    "@media(max-width:425px) and (min-width: 0px)": {
        height: "800px",
    },
});
const ContactusImgFaq = styled("img")({
    height: "450px",
    "@media(max-width:425px) and (min-width: 0px)": {
        width: "300px"
    },

});
const ContactusGridFaq = styled(Grid)({
    "@media(max-width:958px) and (min-width: 0px)": {
        textAlign: "center"
    },
});
// Customizable Area End


export default class Interactivefaqsweb extends InteractivefaqsControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    render() {

        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation.navigate} />
                <Box>
                    <Grid item lg={12}>
                        <Box>
                            <img width={"100%"} height={"60%"} style={styleFaq.image as React.CSSProperties} src={faqs} alt="" />
                        </Box>
                    </Grid>
                </Box>
                <Box style={styleFaq.headingMain as React.CSSProperties}>
                    <Typography style={styleFaq.heading as React.CSSProperties}>Have questions? Worry not, we have answers.</Typography>
                </Box>
                <Grid container lg={12} style={styleFaq.itemContainer}>
                    <Box >
                        {this.state.faqsData.map((item: any) => (
                               

                            <Accordion
                                style={ this.state.expandedItems[item.id] ?styleFaq.itemonCheck:styleFaq.itemMain}
                                key={item.id}
                                expanded={this.state.expandedItems[item.id] || false}
                                onChange={() => this.toggleAccordion(item.id)}
                            >
                                <AccordionSummary
                                    aria-controls={`panel${item.id}-content`}
                                    id={`panel${item.id}-header`}
                                    style={{ alignItems: 'center'}}
                                >
                                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                                    <Typography data-test-id="title" style={styleFaq.title}>{item.attributes.title}</Typography>
                                    {this.state.expandedItems[item.id]  ?<RemoveSharp/>:<AddSharp/>}
                                    </div>
                                </AccordionSummary>
                                <hr style={styleFaq.hr as React.CSSProperties} />
                                <AccordionDetails>
                                    <Typography style={styleFaq.content}>
                                        {item.attributes.content}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Grid>
                <ContactusFaq>
                    <Box style={styleFaq.containers as React.CSSProperties}>
                        <ContactusGridFaq container>
                            <Grid item xl={8} lg={8} md={6} xs={12} sm={12} style={styleFaq.contactusText as React.CSSProperties}>
                                <Typography style={styleFaq.contactTextT as React.CSSProperties}>Still have questions ?
                                </Typography>
                                <Button style={styleFaq.plateformListingBtns as React.CSSProperties} onClick={this.ContactusNavigationAdd} data-testid="contactus">Contact us</Button>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                                <ContactusImgFaq src={contactus} alt=""></ContactusImgFaq>
                            </Grid>
                        </ContactusGridFaq>
                    </Box>
                </ContactusFaq>
                <Box>
                    <AppFooter naviagtion={this.props.navigation.navigate} />
                </Box>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const styleFaq = {
    containers: {
        padding: "0px 56px"
    },
    image: {
        maxWidth: "100%",
        height: "auto",
        padding: 0,
        margin: 0,
    },
    headingMain: {
        padding: "50px 0 10px 0"
    },
    heading: {
        fontSize: "40px",
        fontFamily: "Segoe UI",
        textAlign: 'center',
        color: '#676767',
        fontStyle: "italic",
        lineHeight:"40px",
        paragraph:"8px",
    },
    itemMain: {
        border: '1px solid #9DD5A4',
        padding: '18px 32px',
        alignItems: 'center',
        margin: '30px 0'
    },
    itemonCheck:{
        border: '1px solid #3CAA49',
        padding: '18px 32px',
        alignItems: 'center',
        margin: '30px 0',
        backgroundColor:"#ECF7ED33"
    },
    itemContainer: {
        padding: '0 150px',
        margin: '30px 0'
    },
    hr: {
        width: "100%",
        height: "1px",
        backgroundColor: " #CBD5E1",
        border: "none",
        margin: "10px 0"
    },
    title: {
        fontFamily: "Segoe UI",
        fontSize: "28px",
        color:"#181818",
        maxWidth:"90%"
    },
    content: {
        fontFamily: "Segoe UI",
        fontSize: "28px",
        color: '#676767E5',
        maxWidth:"98%"
    },
    contactusText: {
        alignSelf: "center",
        marginTop: "10%",
    },
    contactTextT: {
        fontFamily: "Segoe UI Bold",
        fontSize: "30px",
        color: "#676767"
    },
    plateformListingBtns: {
        height: "60px",
        width: "220px",
        borderRedius: "4px",
        background: "#FFFFFFE5",
        color: "#3CAA49",
        fontFamily: "Segoe UI Bold",
        fontSize: "20px",
        border: "1px solid #3CAA49",
        marginTop: "3%",
        textTransform: 'none'
    },
}
// Customizable Area End