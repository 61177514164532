// Customizable Area Start
import React from "react";
import { SafeAreaView, StyleSheet } from "react-native";
import { styled, Typography, Slider, TextField, FormControlLabel, Grid, Button, Checkbox, Box, createTheme, ThemeProvider, CircularProgress } from "@material-ui/core";
import { arrowDown, imageHomeCross } from "./assets";
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from "@material-ui/icons";
import FilterAllCategoryController from "./FilterAllCategoryController";
import SavedListingLooking from "./SavedListingLooking";
import SavedListingSelling from "./SavedListingSelling";
import { AppHeader } from "../../../components/src/AppHeader.web";
import AllListings from "./AllListings.web";
const SidebarBox = styled(Box)({
    height: '1239px', overflowY: 'auto', overflowX: 'hidden', scrollbarColor: '#3CAA49 #f5f5f5', scrollbarWidth: 'thin',
    paddingRight: "10px",
    minWidth: '250px',
    '&::-webkit-scrollbar': {
        width: '5px',
        borderRadius: 6,
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: 6,
        background: '#888',
    }
})
const CustomCheckboxs = styled(Checkbox)({
    padding: "0px",
    paddingLeft: "10px",
    paddingRight: "5px",
    "& .MuiSvgIcon-root": {
        color: "#CBD5E1"
    },
    "&.MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root": {
        color: "#3CAA49"
    }
});
const BoxMainDiv = styled(Box)({
    display: "flex",
    paddingInline: "72px",
    "@media(min-width:1440px)": {
        maxWidth: '1296px',
        margin: 'auto',
        width: '100%',
    },
})
const HomeTextDiv = styled('div')({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginLeft: "72px",
    "@media(min-width:1440px)": {
        maxWidth: '1296px',
        margin: 'auto',
        width: '100%',
    }
})
const CustomTextFieldsMain = styled(TextField)({
    marginTop: "0px",
    "& .MuiOutlinedInput-input": {
        padding: '10px 8px 10px 8px',
        fontSize: 14,
        gap: '8px',
        borderRadius: '1px',
    },
    '&.redBorder': {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: 'red !important'
        }
    }
});
const StyledCustomBox = styled(Box)({
    marginTop: "25px",
    width: '96%',
    paddingLeft: 5,
    '& .MuiSlider-markLabel': {
        fontSize: "12px",
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
        left: 'unset !important',
        right: '-40px'
    },
    '& .MuiSlider-valueLabel': {
        left: 'calc(-50% - -8px)',
        top: -20,
    },
    '& .MuiSlider-valueLabel>span': {
        transform: 'unset',
        borderRadius: "5px",
        width: 'fit-content',
        height: 20,
        padding: "0px 3px"
    },
    '& .MuiSlider-valueLabel>span>span': {
        transform: 'unset',
        fontSize: "10px"
    },
});
const CustomTextFieldsMainSlide = styled(TextField)({
    marginTop: "0px",
    "& .MuiOutlinedInput-input": {
        padding: '10px 8px 10px 8px',
        fontSize: 14,
        gap: '8px',
        borderRadius: '1px',
    },
    '&.redBorder': {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: 'red !important'
        }
    }
});
const StyledCustomBoxSlide = styled(Box)({
    marginTop: "25px",
    width: '96%',
    paddingLeft: 5,
    '& .MuiSlider-markLabel': {
        fontSize: "12px",
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
        left: 'unset !important',
        right: '-40px'
    },
    '& .MuiSlider-valueLabel': {
        left: 'calc(-50% - -8px)',
        top: -20,
    },
    '& .MuiSlider-valueLabel>span': {
        transform: 'unset',
        borderRadius: "5px",
        width: 'fit-content',
        height: 20,
        padding: "0px 3px"
    },
    '& .MuiSlider-valueLabel>span>span': {
        transform: 'unset',
        fontSize: "10px"
    },
});

const GreenSliders = styled(Slider)({
    color: '#3CAA49',
});

const placeholderStylesCustoms = {
    fontSize: "12px",
    color: "#64748B",
    fontFamily: "Segoe UI",
    lineHeight: "24px",
};

const GreenSlider = styled(Slider)({
    color: '#3CAA49',
});

const placeholderStylesCustom = {
    fontSize: "12px",
    color: "#64748B",
    fontFamily: "Segoe UI",
    lineHeight: "24px",
};

type LookingFilterData = {
    id: number;
    type: string;
    attributes: {
        id: number;
        name: string;

    }
}
type LookSubcategory = {
    id: "48",
    type: "sub_category",
    attributes: {
        "id": 48,
        "name": "Watches",
        "created_at": "2023-10-11T07:29:37.000Z",
        "updated_at": "2023-10-11T07:29:37.000Z"
    }
}
const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 1000000,
        label: '£ 1,000,000',
    },
];
const marksCommission = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 100000,
        label: '£ 1,00,000',
    },
];

export default class FilterAllCategory extends FilterAllCategoryController {
    getSelectedCategoriesText() {
        let selectedCategoriesText = "";
        if (this.state.categortText && this.state.categortText.length > 0) {
            selectedCategoriesText = this.state.categortText.map(categoryId => {
                const category = this.state.categoryApiFilterAll?.find(cat => cat.id === categoryId);
                return category ? category.attributes.name : "";
            }).join(", ");
        }
        return selectedCategoriesText;
    }
    getLookingText() {
        let lookingText = "All";
        const selectedCategory = this.state.categoryApiFilterAll?.find(category => this.state.categortText.includes(category.id));
        if (this.state.lookingForSomethingChecked && !selectedCategory) {
            lookingText = "Looking for something";
        } else if (this.state.sellingSomethingChecked && !selectedCategory) {
            lookingText = "Selling for something";
        } else if (this.state.categoryApiFilterAll && !this.state.categoryLoader) {
            if (selectedCategory) {
                lookingText = selectedCategory.attributes.name;
            }
        } else if(this.state.categoryLoader) {
            return <CircularProgress size={24} style={{ color: '#4fa94d' }} />;
        }

        return lookingText;
    }
    getVisibleSectionIds = () => {
        return Object.entries(this.state.sectionsVisibles)
            .reduce((accumulator: string[], [key, value]) => {
                if (value) {
                    accumulator.push(key);
                }
                return accumulator;
            }, []);
    };
    applyButtonListing = () => {
        if (this.state.lookingForSomethingChecked ) {
            this.setState({
                ShowHide: true,
                isTypeChanges: true,
                keyCount: !this.state.keyCount,
                showLookingSomeData:true,
                showSellingSomeData:false
            });
        }

        if ( this.state.sellingSomethingChecked) {
            this.setState({
                ShowHide: true,
                isTypeChanges: true,
                keyCount: !this.state.keyCount,
                showSellingSomeData:true,
                showLookingSomeData:false,
            });
        }
    }
    applyButtonListingBudgets = () => {
        if (this.state.budgetMin || this.state.budgetMax) {
            this.setState({
                ShowBudget
                    : true,
                budgetMaxTemp: true,
                isTypeChanges: false,
                keyCount: !this.state.keyCount,
                showBudgetData:!this.state.showBudgetData
            });
        }
    }
    applyButtonListingCommissions = () => {
        if (this.state.budgetMinc || this.state.budgetMaxc) {
            this.setState({
                ShowCommission
                    : true,
                budgetMaxTemp: true,
                isTypeChanges: false,
                keyCount: !this.state.keyCount,
                showCommissionData:!this.state.showCommissionData
            });
        }
    }

    getAllPassDatas = () => {
        return (
            <div>
               
                    < AllListings {...this.props}
                        data-test-id="viewAlltoggles"
                        keyCount={this.state.keyCount}
                        isTypeChanges={this.state.isTypeChanges}
                        lookingForSomethingChecked={this.state.lookingForSomethingChecked}
                        sellingSomethingChecked   ={ this.state.sellingSomethingChecked}
                        categortText={this.state.categortText}
                        subCategoryApiDatasAll={this.state.subCategoryApiDatasAll}
                        budgetMin={this.state.budgetMin}
                        budgetMax={this.state.budgetMax}
                        budgetMinc={this.state.budgetMinc}
                        budgetMaxc={this.state.budgetMaxc}
                       onChange={(e: any) => this.setState({ lookingForSomethingChecked: e.target.checked })}
                       key={this.state.ShowDataAll}
                       showBudgetData={this.state.showBudgetData}
                       showCommissionData={this.state.showCommissionData}
                       showLookingSomeData={this.state.showLookingSomeData}
                       showSellingSomeData={this.state.showSellingSomeData}
                    />

            </div>
        );
    }
    calculateSectionDisplayStyle = () => {
        return this.state.sectionsVisibles ? 'block' : 'none';
    };
    renderStyledName(name: string, visible: any) {
        const fontFamily = visible ? 'Segoe UI Bold' : 'Segoe UI';
        return (
            <span style={{ ...styleD.checkTexts, fontFamily }}>
                {name}
            </span>
        );
    }

   
    renderCheckbox = (label: string, items: any, categoryIds: number, itemData: any) => {
        return (

            <div style={{ flexDirection: 'column', marginTop: '15px' }}>
                <FormControlLabel
                    control={
                        <CustomCheckboxs
                            checked={!!itemData?.isChecked}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon style={{ color: "#059669 !important" }} />}
                            data-test-id ={`test-${categoryIds}`}
                            name={label}
                            onChange={(e) => {
                                let arrCopy = [...this.state.subCategoryApiDatasAll[categoryIds]];
                                this.onChnageFilter(e,label,items,categoryIds,arrCopy)
                            }}
             
                        />
                    }
                    label={<span style={styleD.checkTextsAll}>{label}</span>}
                />
            </div>
        );
    };
    renderSubCategoryCheckboxes = (sectionInterior: object, categoryId: number, item: any) => {
        if (!Array.isArray(item)) {
            return null;
        }
        let itemArr = [...item];
        return this.state.sectionsVisibles[categoryId] && itemArr && itemArr.map((item: LookSubcategory) => (
            <div key={item.id}>
                {this.renderCheckbox(item.attributes.name, item.id, categoryId, item )}
            </div>
        ));
    };
    clearAll = () => {

        this.setState({
            isTypeChanges: false,
            keyCount: !this.state.keyCount,
            lookingForSomethingChecked: false,
            sellingSomethingChecked: false,
            subCategoryApiDatasAll: [],
            categortText: [],
            isCheckedList: false,
            budgetMin: "",
            budgetMax: "",
            budgetMinc: "",
            budgetMaxc: "",
            minError:"",
            maxError:"",
            maxErrors:"",
            minErrors:"",
            sectionsVisibles: {},
            ShowHide: false,
         showBudgetData:!this.state.showBudgetData,
         showLookingSomeData:false,
         showSellingSomeData:false,
        
        });
        localStorage.removeItem("catId")
    }
    handleclearAll = () => {
        this.setState({
            lookingForSomethingChecked: false,
            sellingSomethingChecked: false,
            ShowHide: false,
            showLookingSomeData:false,
            isTypeChanges: false,
            keyCount: !this.state.keyCount,
            showSellingSomeData: false,

           
        });
        localStorage.removeItem("catId")
    }
    anyCheckboxChecked = () => {
        return Object.values(this.state.subCategoryApiDatasAll).some((category: any) =>
            category.some((subcategory: any) => subcategory.isChecked)
        ) ||
            this.state.lookingForSomethingChecked ||
            this.state.sellingSomethingChecked ||
            this.state.budgetMin !== "" ||
            this.state.budgetMax !== "" ||
            this.state.budgetMinc !== "" ||
            this.state.budgetMaxc !== "" ||
            Object.keys(this.state.sectionsVisibles).length > 0 ||
            this.state.ShowHide;
    };
    anyCheckboxListing = () => {
        return this.state.lookingForSomethingChecked || this.state.sellingSomethingChecked;
    };
    anyCheckboxBudget = () => {
        return  parseInt(this.state.budgetMin) > 0 && parseInt(this.state.budgetMin) < parseInt(this.state.budgetMax )
    };



    anyCheckboxCommission = () => {
       
        return  parseInt(this.state.budgetMinc) > 0 && parseInt(this.state.budgetMinc) < parseInt( this.state.budgetMaxc);
    };
    handleLookingForSomethingChange = (e:any) => {
            this.setState({ 
                lookingForSomethingChecked: e.target?.checked, 
                ShowHide: false ,
                sellingSomethingChecked:false
            });
    };
    handleCheckboxChange = (e:any) => {
            this.setState({ 
                sellingSomethingChecked: e.target?.checked, 
                ShowHide: false ,
                lookingForSomethingChecked:false
            });
    };
    handleValueChange = (value:any) => {
        const numericValue = value.replace(/[^\d.]/g, '');
        const parts = numericValue.split('.');
        if (parts.length > 2) {
            return parts[0] + '.' + parts.slice(1).join('');
        }

        return numericValue;
    };  


  

    render() {
      
        const sectionDisplayStyle = this.calculateSectionDisplayStyle();
        const id = this.getVisibleSectionIds();
        const { value, budgetMin, budgetMax, minError, maxError, budgetMinc, budgetMaxc, minErrors, maxErrors } = this.state;
        const lookingText = this.getLookingText();
        const renderLookingText = <p style={styleD.lookingText as React.CSSProperties}>{lookingText}</p>;
        const getButtonStyle = (isChecked:any) => {
            const backgroundColor = isChecked ? '#3CAA49' : '#B2B2B2';
            const borderColor = isChecked ? '#3CAA49' : '#B2B2B2B2';

            return {
                ...styleD.button as React.CSSProperties,
                color: backgroundColor,
                border: `1px solid ${borderColor}`,
            };
        };

        const clearAllButtonStyle = {
            ...styleD.clearAll,
            color: this.anyCheckboxChecked() ? '#3CAA49' : '#B2B2B2'
        };

        const applylistingButton = {
            ...styleD.buttonApply as React.CSSProperties,
            background: this.anyCheckboxListing() ? '#3CAA49' : '#B2B2B2B2'
        };

        const applylistingBudget = getButtonStyle(this.anyCheckboxBudget());
        const applylistingCommission = getButtonStyle(this.anyCheckboxCommission());
        const theme = createTheme({
            palette: {
              primary: {
                main: "#3CAA49",
                contrastText: "#fff"
              }
            }
          });

     
        return (
            <ThemeProvider theme={theme}>
            <SafeAreaView style={styles.mainContainer} >
                <AppHeader data-test-id="app-header" navigation={this.props.navigation.navigate} searchText={this.state.searchText} onChange={(e: any) => this.setState({ searchText: e.target.value })} handleShowCross={()=>this.setState({ ShowDataAll: this.state.ShowDataAll+1 })}/>
                <HomeTextDiv>
                    <p style={styleD.homeTextbar as React.CSSProperties} onClick={this.homePageGo}
                    >Home</p>
                    <div>
                        <img src={imageHomeCross} style={styleD.imageHomeCrosses as React.CSSProperties} alt="" />
                    </div>
                    {renderLookingText}
                </HomeTextDiv>
                <BoxMainDiv>
                    <SidebarBox>
                        <Box>
                            <div style={styleD.arrowClosess as React.CSSProperties}>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: "10px" }}>
                                    <div style={styleD.filters as React.CSSProperties}>Filters</div>
                                    <div style={clearAllButtonStyle} onClick={this.clearAll}>CLEAR ALL</div>
                                </div>
                                <div style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <div style={styleD.arrowClose as React.CSSProperties}>
                                        <Typography style={styleD.arrowCloseText as React.CSSProperties} >
                                            Listing Type
                                        </Typography>
                                    </div>
                                    <div style={{ borderBottom: '5px solid #E5E7EB', display: sectionDisplayStyle, padding: "16px 10px 16px 20px" }}>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <CustomCheckboxs
                                                        data-test-id="viewAlltoggles6"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checkedIcon={<CheckBoxIcon style={{ color: "#3CAA49" }} />}
                                                        checked={this.state.lookingForSomethingChecked}
                                                        onChange={this.handleLookingForSomethingChange}
                                                        name="lookingForSomethingChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <span style={styleD.checkTexts as React.CSSProperties}>
                                                        Looking for something
                                                    </span>
                                                }
                                            />
                                        </div>

                                        <div style={{ marginTop: '10px' }}>
                                            <FormControlLabel
                                                control={
                                                    <CustomCheckboxs
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        data-test-id="sellingCategory"
                                                        checkedIcon={<CheckBoxIcon style={{ color: "#3CAA49" }} />}
                                                        checked={this.state.sellingSomethingChecked}
                                                        onChange={this.handleCheckboxChange}
                                                        name="sellingSomethingChecked"
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <span style={styleD.checkTexts as React.CSSProperties}>
                                                        Selling something
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: "10px 0px 10px 20px", gap: "8px" }}>
                                        <Button style={styleD.buttonApplyCancel as React.CSSProperties} onClick={this.handleclearAll} >Cancel</Button>
                                        <Button style={applylistingButton} onClick={this.applyButtonListing}>Apply</Button>
                                    </div>
                                </div>
                                <div style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', marginTop: "20px" }}>
                                    <div style={{
                                        marginTop: "10px", fontFamily: "Segoe UI", fontSize: "20px", color: "#000000", lineHeight: "30px", background: "#F7F7F7",
                                        padding: "16px"
                                    }}>Categories</div>
                                    <div style={{ display: 'columns' }}>
                                        <div style={{ padding: "16px" }}>
                                            {this.state.categoryApiFilterAll?.map((item: LookingFilterData) => {
                                                return (
                                                    <div key={item.id} style={styleD.arrorMainFilter as React.CSSProperties}>
                                                        <div data-test-id="viewAlltoggle" style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.toggleSectionsVisibilitys(item.id);
                                                            }}>
                                                            {this.renderStyledName(item.attributes.name, this.state.sectionsVisibles[item.id])}
                                                            <img
                                                                style={{ width: '20px', height: '20px', transform: `rotate(${this.state.sectionsVisibles[item.id] ? '180deg' : '0deg'})`, cursor: 'pointer' }}
                                                                src={arrowDown}
                                                                alt="Card Imagess"
                                                            />
                                                        </div>
                                                        <div>
                                                           
                                                            {this.state.subCategoryApiDatasAll && Object.keys(this.state.subCategoryApiDatasAll).length > 0 &&
                                                                id.map((i: any) => {
                                                                    return item.id == i && this.renderSubCategoryCheckboxes(this.state.sectionInterior, item.id, this.state.subCategoryApiDatasAll[i]);
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderTop: '1px solid #E5E7EB', marginTop: "20px" }}></div>
                                <div style={{ display: 'columns' }}>
                                    <div style={{ paddingBottom: '10px' }}></div>
                                    <div style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                        <div style={styleD.styleBudget as React.CSSProperties}>
                                            <Typography style={styleD.heading}>
                                                Budget
                                            </Typography>
                                            <div style={{ padding: "16px" }}>
                                                <div style={styleD.inputContainer}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography style={styleD.labels}>
                                                                Min
                                                            </Typography>
                                                            <CustomTextFieldsMain
                                                                variant="outlined"
                                                                name="budgetDatas"
                                                                placeholder="£ 1"
                                                                type="text"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                InputProps={{ style: placeholderStylesCustom }}
                                                                value={budgetMin}
                                                                onChange={(event) => this.handleChange([event.target.value, budgetMax])}
                                                            />
                                                            {minError !== '' && <Typography style={styleD.errorMessagess as React.CSSProperties}>{minError}</Typography>}
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography style={styleD.labels}>
                                                                Max
                                                            </Typography>
                                                            <CustomTextFieldsMain
                                                                variant="outlined"
                                                                name="commissionDatas"
                                                                placeholder="£ 100000"
                                                                type="text"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                InputProps={{ style: placeholderStylesCustom }}
                                                                value={budgetMax}
                                                                onChange={(event) => this.handleChange([budgetMin, this.handleValueChange(event.target.value)])}
                                                            />
                                                            {maxError !== '' && <Typography style={styleD.errorMessagess as React.CSSProperties}>{maxError}</Typography>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <StyledCustomBox>
                                                    <GreenSlider
                                                        aria-label="Budget slider"
                                                        defaultValue={[1, 1000000]}
                                                        step={10}
                                                        min={1}
                                                        max={1000000}
                                                        marks={marks}
                                                        value={value}
                                                        onChange={(event, newValue) => this.handleChange(newValue)}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={this.valuetext}
                                                    />
                                                </StyledCustomBox>
                                                <Button style={applylistingBudget} disabled={!this.anyCheckboxBudget()}  onClick={this.applyButtonListingBudgets}>Apply</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', marginTop: "10px" }}>
                                        <div style={styleD.styleCommission as React.CSSProperties}>
                                            <Typography style={styleD.headingCommission}>
                                                Reward
                                            </Typography>
                                            <div style={{ padding: "16px" }}>
                                                <div style={styleD.inputContainers}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography style={styleD.labelSlide}>
                                                                Min
                                                            </Typography>
                                                            <CustomTextFieldsMainSlide
                                                                variant="outlined"
                                                                name="budgetDatas"
                                                                placeholder="£ 1"
                                                                type="text"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                InputProps={{ style: placeholderStylesCustoms }}
                                                                value={budgetMinc}
                                                              
                                                                onChange={(event) => this.handleChanges([(event.target.value), budgetMaxc])}
                                                            />
                                                            {minErrors !== '' && <Typography style={styleD.errorMessagessCommission as React.CSSProperties}>{minErrors}</Typography>}
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography style={styleD.labelSlide}>
                                                                Max
                                                            </Typography>
                                                            <CustomTextFieldsMainSlide
                                                                variant="outlined"
                                                                name="commissionDatas"
                                                                placeholder="£ 100000"
                                                                type="text"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                InputProps={{ style: placeholderStylesCustoms }}
                                                                value={budgetMaxc}
                                                                onChange={(event) => this.handleChanges([budgetMinc, (event.target.value)])}

                                                            />
                                                            {maxErrors !== '' && <Typography style={styleD.errorMessagessCommission as React.CSSProperties}>{maxErrors}</Typography>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <StyledCustomBoxSlide>
                                                    <GreenSliders
                                                        aria-label="Budget slider"
                                                        defaultValue={[1, 100000]}
                                                        step={10}
                                                        min={1}
                                                        max={100000}
                                                        marks={marksCommission}
                                                        value={this.state.values}
                                                        onChange={(event, newValue) => this.handleChanges(newValue)}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={this.valuetexts}
                                                    />
                                                </StyledCustomBoxSlide>
                                                <Button style={applylistingCommission} disabled={!this.anyCheckboxCommission()} onClick={this.applyButtonListingCommissions}>Apply</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </SidebarBox>
                    <div style={{ width: "100%" }}>
                        {this.getAllPassDatas()}
                    </div>
                </BoxMainDiv>
            </SafeAreaView >
            </ThemeProvider>

        );
    }
}

const styles = StyleSheet.create({
    mainContainer: {
        width: "100%"
    },
});
const styleD = {
    checkTexts: {
        color: "#676767",
        fontFamily: "Segoe UI Bold",
        fontSize: "16px",
        lineHeight: "24px"
    },
    checkTextsAll: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "16px",
        lineHeight: "24px"
    },
    checkTextsLooking: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "16px",
        lineHeight: "24px"
    },
    checkTextsSelling: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "16px",
        lineHeight: "24px"
    },
    arrowClose: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: "#F7F7F7",
        padding: "16px"
    },
    arrowCloseText: {
        fontFamily: 'Segoe UI',
        fontSize: '18px',
        color: "#000000",
        lineHeight: "30px"
    },
    arrorMainFilter: {
        display: 'columns',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: '15px'
    },
    arrorMainJewelleryFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: '15px',
    },
    arrorMainClothingFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: '15px',
    },
    arrorMainInteriorFilter: {
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: '15px',
    },
    arrorMainServicesFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: '15px',
        borderBottom: '1px solid lightgray',
        paddingBottom: '20px',
    },
    homeTextbar: {
        lineHeight: "32px",
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "24px",
        cursor: "pointer"
    },
    lookingText: {
        color: "#3CAA49",
        fontFamily: "Segoe UI",
        fontSize: "24px",
        lineHeight: "32px",
        cursor: "pointer",
    },
    imageHomeCrosses: {
        width: "10.41px",
        height: "15px",
        margin: "0px"
    },
    lookingTextsMain: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#3CAA49",
        marginLeft: '24px'
    },
    arrowClosess: {
    },
    labels: {
        fontFamily: "Segoe UI Bold",
        fontSize: "12px",
        lineHeight: "22px",
        color: "#676767",
        marginTop: "10px"
    },
    errorMessagess: {
        color: "red",
        marginTop: "0px",
        fontFamily: "Segoe UI",
        fontSize: "12px",
        lineHeight: "18px",
        letterSpacing: "0em",
        textAlign: "left"
    },
    filters: {
        fontFamily: "Segoe UI Bold",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#676767",
    },
    clearAll: {
        fontFamily: "Segoe UI",
        fontSize: "14px",
        lineHeight: "24px",
        cursor: "pointer",
    },
    styleBudget: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '20px',
    },
    heading: {
        fontFamily: 'Segoe UI',
        fontSize: '18px',
        color: "#000000",
        marginTop: '8px',
        lineHeight: "16px",
        background: "#F7F7F7",
        padding: "16px"
    },
    inputContainer: {
        marginTop: '15px'
    },
    input: {
        width: "100%",
        marginTop: "15px"
    },
    button: {
        height: "32px",
        width: "100%",
        borderRadius: "4px",
        fontFamily: "Segoe UI Bold",
        fontSize: "12px",
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "15px",
    },
    buttonApply: {
        height: "32px",
        width: "72px",
        borderRadius: "4px",
        color: "#ECF7ED",
        fontFamily: "Segoe UI Bold",
        fontSize: "10px",
        border: "none",
        textTransform: "capitalize",
    },
    buttonApplyCancel: {
        height: "32px",
        width: "72px",
        borderRadius: "4px",
        color: "#676767",
        fontFamily: "Segoe UI Bold",
        fontSize: "10px",
        border: "0.5px solid #676767",
        textTransform: "capitalize",
    },
    styleCommission: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '20px',
    },
    headingCommission: {
        fontFamily: 'Segoe UI',
        fontSize: '18px',
        color: "#000000",
        marginTop: '8px',
        lineHeight: "16px",
        background: "#F7F7F7",
        padding: "16px"
    },
    inputContainers: {
        marginTop: '15px'
    },
    labelSlide: {
        fontFamily: "Segoe UI Bold",
        fontSize: "12px",
        lineHeight: "22px",
        color: "#676767",
        marginTop: "10px"
    },
    buttonCommission: {
        height: "32px",
        width: "100%",
        borderRadius: "4px",
        fontFamily: "Segoe UI Bold",
        fontSize: "12px",
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "15px",
    },
    errorMessagessCommission: {
        color: "red",
        marginTop: "5px",
        fontFamily: "Segoe UI",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        letterSpacing: "0em",
        textAlign: "left"
    }

}
// Customizable Area End
