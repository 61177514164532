import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  fullName: string;
  ConfirmPasswordRequired : string;
  ConfirmPasswordRequiredError: boolean,
  email: string;
  userName: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  signupInformation: any;
  signupInformationError: any;
  phone: string;
  containsCapital: boolean;
  containsLowercase: boolean;
  containsNumber: boolean;
  showAgreeError: boolean;
  hasMinLength: boolean;
  fullNameError: boolean;
  showPassword: boolean;
  passwordMatchError: boolean;
  fullNameTouched: false;
  confirmPassword: string;
  emailError: boolean;
  activeGridId: number;
  emailExists: boolean;
  checkboxChecked: boolean;
  showConfirmPassword: boolean;
  passwordError: boolean;
  confirmPasswordError: boolean;
  userNameError: boolean;
  isSnackbarOpen: boolean;
  createAccountButtonDisabled: boolean;
  isTermsAndConditionsChecked: boolean;
  checkboxError: boolean;
  snackbarOpen: boolean;
  emailFocused: boolean;
  emailBlurred: boolean;
  finishedTyping: boolean;
  LoaderSignup: boolean;
  termsData: unknown[];
  policyData: unknown[];
  mainImages: any,
  isPrivacyPolicyModalOpen: boolean;
  emailErrorMsg: string;
  fullNameErrorMsg: string;
  userNametakenMsg: string;
  emailErrortakenMsg: string;
  userNameData: string;
  loading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  apiSignup_informationCallId: string = "";
  apiImage_informationCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      fullName: "",
      ConfirmPasswordRequired : "",
      ConfirmPasswordRequiredError: false,
      email: "",
      userName: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      containsCapital: false,
      containsLowercase: false,
      containsNumber: false,
      hasMinLength: false,
      showPassword: false,
      passwordMatchError: false,
      LoaderSignup: false,
      confirmPassword: "",
      emailError: false,
      signupInformation: [],
      signupInformationError: [],
      mainImages: '',
      showAgreeError: false,
      activeGridId: 1,
      emailExists: false,
      checkboxChecked: false,
      userNameError: false,
      passwordError: false,
      confirmPasswordError: false,
      showConfirmPassword: false,
      isSnackbarOpen: false,
      createAccountButtonDisabled: true,
      checkboxError: false,
      isTermsAndConditionsChecked: false,
      fullNameError: false,
      fullNameTouched: false,
      snackbarOpen: false,
      emailFocused: false,
      emailBlurred: false,
      finishedTyping: false,
      termsData: [],
      policyData: [],
      isPrivacyPolicyModalOpen: false,
      emailErrorMsg: "",
      fullNameErrorMsg: "",
      userNametakenMsg: "",
      emailErrortakenMsg: "",
      userNameData: "",
      loading: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(apiRequestCallId === this.apiSignup_informationCallId){
        if (responseJson?.meta?.token) {
          toast.success("Email verification link has been sent to your email. Please activate the account to login.");
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
        this.setState({
          userNametakenMsg: responseJson?.errors?.[0]?.user_name
            ? responseJson.errors[0].user_name
            : "",
          emailErrortakenMsg: responseJson?.errors?.[0]?.account
            ? responseJson.errors[0].account
            : ""
        });
      }
      this.setState({ loading: false });
      return;
    }
      this.signupImageResponse(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.signupImageAPI();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }
  signupImageResponse = (responseJson: any) => {
    this.setState({ LoaderSignup: false})
    if (responseJson && responseJson.data && Array.isArray(responseJson.data)) {
      const imgWithSignupName = responseJson.data.find((img : any) => img.attributes.name === "signup");
      const photo = imgWithSignupName ? imgWithSignupName.attributes.photo : "";
      this.setState({
            mainImages: photo
          });
    }
  }
  handleCreateAccount() {
    const { checkboxChecked } = this.state;
    const {
      fullName,
      userName,
      confirmPassword,
      ConfirmPasswordRequired,
      password,
      email,
      emailErrorMsg,
      userNametakenMsg,
      emailErrortakenMsg
    } = this.state;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!checkboxChecked) {
      this.setState({ checkboxError: true });
      this.openSnackbar();
    }

    if (fullName.trim() === "") {
      this.setState({ fullNameError: true });
    } else {
      this.setState({ fullNameError: false });
    }
    if (ConfirmPasswordRequired.trim() === "") {
      this.setState({ ConfirmPasswordRequiredError: true });
    } else {
      this.setState({ ConfirmPasswordRequiredError: false });
    }
    this.setState({ userName: userName });
    if (userName.trim() === "") {
      this.setState({ userNameError: true });
    } else {
      this.setState({ userNameError: false });
    }
    if (password.trim() === "") {
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }
    if (email.trim() === "") {
      this.setState({ emailErrorMsg: "Email is required" });
    } else if (!emailPattern.test(email)) {
      this.setState({ emailErrorMsg: "Invalid email format!" });
    }
    if (confirmPassword.trim() === "") {
      this.setState({ confirmPasswordError: true });
    } else {
      this.setState({ confirmPasswordError: false });
    }
    if (
      fullName.trim() !== "" &&
      userName.trim() !== "" &&
      email.trim() !== "" &&
      password.trim() !== "" &&
      confirmPassword.trim() !== "" &&
      emailPattern.test(email) &&
      emailErrorMsg.trim() === "" &&
      userNametakenMsg.trim() === "" &&
      emailErrortakenMsg.trim() === "" &&
      checkboxChecked
    ) {
      this.signupInfomationDetails();
    }
  }
  openSnackbar = () => {
    this.setState({
      isSnackbarOpen: true
    });
  };
  handleLogin = () => {
    this.setState({
      isSnackbarOpen: true
    });
  };
  handleFullNameChange = (event: { target: { value: string } }) => {
    let fullName = event.target.value;
    fullName = fullName.replace(/[^A-Za-z\s]/g, '');
    this.setState({ fullName: fullName });
    if (fullName.trim() === "") {
      this.setState({ fullNameError: true });
    } else {
      this.setState({ fullNameError: false });
    }
  };

  handleEmailChange = (event: { target: { value: string } }) => {
    const email = event.target.value;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.setState({
      email,
      emailError: !emailPattern.test(email),
      emailErrorMsg: email.length == 0 ? "Email is Required" : "",
      emailErrortakenMsg: ""
    });
  };
  handleEmailBlur = () => {
    const { email } = this.state;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    this.setState({
      emailBlurred: true,
      finishedTyping: true,
      emailError: !emailPattern.test(email)
    });
  };
  handleUsernameChange = (event: { target: { value: string } }) => {
    const userName = event.target.value;
    this.setState({ userName: userName, userNametakenMsg: "" });

    if (userName.trim() === "") {
      this.setState({ userNameError: true });
    } else {
      this.setState({ userNameError: false });
    }
  };

  onRedirect = () => {
    this.props.navigation.navigate("LandingPageMain");
  }

  handleConfirmPasswordChange = (event: { target: { value: string } }) => {
    const confirmPassword = event.target.value;
    this.setState({ confirmPassword });
    const { password } = this.state;
    const passwordMatchError = password !== confirmPassword;

    this.setState({
      confirmPassword,
      passwordMatchError
    });

    if (confirmPassword.trim() === "") {
      this.setState({ confirmPasswordError: true });
    } else {
      this.setState({ confirmPasswordError: false });
    }
  };

  handlePasswordChange = (event: any) => {
    const password = event.target.value;
    if (password.trim() === "") {
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }
    const containsCapital = /[A-Z]/.test(password);
    const containsLowercase = /[a-z]/.test(password);
    const containsNumber = /\d/.test(password);
    const hasMinLength = password.length >= 8;

    this.setState({
      password,
      containsCapital,
      containsLowercase,
      containsNumber,
      hasMinLength
    });
  };
  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }));
  };
  openPrivacyPolicyModal = () => {
    this.setState({ isPrivacyPolicyModalOpen: true });
  };
  closePrivacyPolicyModal = () => {
    this.setState({ checkboxChecked: true });
    this.setState({ checkboxError: false });
    this.setState({ isPrivacyPolicyModalOpen: false });
  };
  opensPrivacyPolicyModal = () => {
    this.setState({ checkboxChecked: false });
    this.setState({ checkboxError: true });
    this.setState({ isPrivacyPolicyModalOpen: false });
  };
  handleClickLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };
  handleClickTermsAndCondition = () => {
    window.open("/TermsConditionsDetail", "_blank");
  };
  handleCheckboxChange = () => {
    this.setState(prevState => ({
      checkboxChecked: !prevState.checkboxChecked,
      checkboxError: false
    }));
  };
  handleprivacy = () => {
    this.props.navigation.navigate("Privacy");
  };
  handleEmailFocus = () => {
    this.setState({
      emailFocused: true,
      emailBlurred: false,
      finishedTyping: false
    });
  };

  getButtonBackgroundColor = () => {
    return "#3CAA49";
  };
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.fullName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.userName) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      full_name: this.state.fullName,
      userName: this.state.userName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  signupInfomationDetails = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSignup_informationCallId = requestMessage.messageId;
    let Body = {
      data: {
        type: "email_account",
        attributes: {
          full_name: this.state.fullName,
          email: this.state.email,
          user_name: this.state.userName,
          password: this.state.password,
          password_confirmation: this.state.confirmPassword,
          terms: true
        }
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  signupImageAPI = async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiImage_informationCallId = requestMessages.messageId;

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_content_management/photos"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ LoaderSignup: true})
    runEngine.sendMessage(requestMessages.id, requestMessages);
  };


  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };
  handlePrivacyPolicyClick = () => {
    this.setState({ isPrivacyPolicyModalOpen: true });
  };

  handleClosePrivacyPolicyModal = () => {
    this.setState({ isPrivacyPolicyModalOpen: false });
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };
  // Customizable Area End
}
