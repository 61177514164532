// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

}

interface S {
  loading: boolean;
  paypalEmail: string;
}

interface SS {
  id: any;
}

export default class PaypalDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  showPaypalDetailApiCallId: string = "";
  timer: number | undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
     
    ];

    this.state = {
      loading: false,
      paypalEmail: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.showPaypalDetailApiCallId) {
        if(responseJson && responseJson.data && !responseJson.errors){
          this.setState({ paypalEmail: responseJson.data.paypal_email });
        }
        this.setState({ loading: false });
      }
    }
  }

  async componentDidMount() {
    this.paymentLink();
  }

  async componentWillUnmount() {
    clearInterval(this.timer);
  }

  loginWithPaypalAccount = () => {
    const baseUrl = "https://www.sandbox.paypal.com/signin/authorize";
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('client_id', 'AUFyv07LMsJXbk2z_Wr39HTVZJ1NmctrEnC2zLZcQNnLYo-XeAfla5WB5o3pQep-kfPM1DHCLQLqV6dA');
    urlSearchParams.append('response_type', 'code');
    urlSearchParams.append('redirect_uri', `${window.origin}/success`);
    urlSearchParams.append('scope', 'openid profile email');
    const url = `${baseUrl}?${urlSearchParams.toString()}`;
    const height = 700;
    const width = 600;
    const left = (window.innerWidth - width) / 2;
    const right = (window.innerHeight - height) / 2;
    const option = `width=${width},height=${height},left=${left},right=${right}`;
    const popup = window.open(url, "paypalLogin", option);
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      if (popup?.closed) {
        clearInterval(this.timer);
        this.paymentLink();
      }
    }, 1000) as unknown as number;
  }

  paymentLink = async () => {
    this.setState({ loading: true });
    const header = JSON.stringify({
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.showPaypalDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/paypal_acc_details`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}
  // Customizable Area End
