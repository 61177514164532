import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export type LookingSomeData = {
  id: number;
  type: string;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
    account_id: number;
    location: string;
    description: string;
    preferences: string;
    category: string;
    sub_category: string;
    criteria_or_terms: string[];
  }
}
export type LookingSomeDataSaves = {
  id: number;
  type: string;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
    listing:object;
    account_id: number;
    location: string;
    description: string;
    preferences: string;
    category: string;
    sub_category: string;
    approval_criterion: string[];
    terms_of_purchase: string[]
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title: string;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
    image: string[];
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showAllDataShopping: boolean;
  showAllSellingSomethingData: boolean;
  showAllSavedListingData: boolean;
  LookingSomeData: LookingSomeData[],
  someListingData: LookingSomeData[],
  savedListingData: LookingSomeDataSaves[],
  LoaderListing: boolean,
  LoaderListingS: boolean,
  sellingViewAll: boolean,
  savedData: object,
  profileText: any,
  savedListingsData: any,
  removeSavedList: boolean,
  successRemoveSaving: boolean,
  lookingViewAll:boolean;
  deleteItemIds: number,
  deleteItems: object,
  savedPopupLanding: boolean,
  savedPopups: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiLookingSomeItemCallId: string = "";
  apiSavedListingCallId: string = "";
  apiImageResponse: string = "";
  apiCallLookingIds: string = "";
  apiSave_ListingCallIdssMain: string = "";
  apideleteSavingLandingCallIdMain: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      showAllDataShopping: false,
      showAllSellingSomethingData: false,
      showAllSavedListingData: false,
      savedData: {},
      LookingSomeData: [],
      someListingData: [],
      savedListingData: [],
      profileText: [],
      LoaderListing: true,
      LoaderListingS: true,
      lookingViewAll:false,
      savedListingsData: [],
      removeSavedList: false,
      successRemoveSaving: false,
      sellingViewAll: false,
      deleteItemIds: 0,
      deleteItems: {},
      savedPopupLanding: false,
      savedPopups: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiLookingSomeItemCallId) {
        this.setState({ LoaderListingS: false })
        this.handleSaveListingResponseAll(responseJson);
      }
      if (apiRequestCallId === this.apiSavedListingCallId) {
        this.handleSaveListingResponseTitle(responseJson);
      }
      else if (apiRequestCallId === this.apiCallLookingIds) {
        this.handleSaveListingResponseLookings(responseJson);
      }
      else if (apiRequestCallId === this.apiImageResponse) {
        this.handleSaveListingResponsePhoto(responseJson);
      }
      else if (apiRequestCallId === this.apideleteSavingLandingCallIdMain) {
        this.handleSaveListingDeleteResponse(responseJson);
      }
      if (apiRequestCallId === this.apiSave_ListingCallIdssMain) {
        this.setState({ LoaderListing: false })
        this.handleSaveListingResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getJobsAppliedExecutiveDatasMain()
    this.getsavedListingDats()
    this.userProfileCall()
    this.ShowImages()
    localStorage.removeItem("catId")
  }
  handleSaveListingResponsePhoto(responseJson: any) {
      localStorage.setItem("userProfile", responseJson?.data?.attributes?.photo)
    
  }
  handleSaveListingResponseLookings(responseJson: any) {
      this.setState({
        profileText: responseJson?.data
      });
  }
  AllFilterAdds = () => {
    this.props.navigation.navigate("FilterAllCategory")
  }
  AllFilterLandingPages = (id:any) => {
    this.props.navigation.navigate("FilterAllCategory",{ id: id })
    // localStorage.setItem("catId",id)
  }
  AllFilterLandingPages1 = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    this.props.navigation.navigate("FilterAllCategory")
  }
  handleSaveListingResponseTitle(responseJson: any) {
    const titles = responseJson.data?.map((item:any)=> item.attributes.listing.data.id)
      this.setState({
        savedListingData: responseJson?.data,
        savedListingsData: titles,
      })
  }
  handleSaveListingResponseAll(responseJson: any) {
      this.setState({
        LookingSomeData: responseJson?.data,
        someListingData: responseJson?.data,
      })
    
  }
  handleFullName() {
    const fullName = this.state.profileText?.attributes?.full_name;
    const email = this.state.profileText?.attributes?.email;
    const photo = this.state.profileText?.attributes?.photo;
    if (fullName) {
      localStorage.setItem("fullName", fullName);
    }
    if (email) {
      localStorage.setItem("email", email);
    }
    if (photo) {
      localStorage.setItem("profilePhoto", photo);
    }
  }
  handleSaveListingResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        savedData: responseJson.message,
        savedPopupLanding: true,
        savedPopups: true
      })
      this.getsavedListingDats()
    }
  }
  handleSaveListingDeleteResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        deleteItems: responseJson?.message,
        successRemoveSaving: true,
      });
      this.getsavedListingDats()
    }
  }
handleLooking = () => {
  const url = new URL('/UserProfileBasicBlock', window.location.href);
  url.searchParams.set('tab', 'savedlist');
  window.history?.pushState({}, '', url.toString());
}

handleLookings = () => {
  this.setState({ lookingViewAll: true }, () => {
    const url = new URL(window.location.href);
    url.searchParams.set('lookingViewAll', 'true');
    window.history?.pushState({}, '', url.toString());
    this.props.navigation.navigate('FilterAllCategory', '?lookingViewAll=true');
  });
}

  handleSelling = () => {
    this.setState({ sellingViewAll: true }, () => {
      const url = new URL(window.location.href);
      url.searchParams.set('sellingViewAll', 'true');
      window.history?.pushState({}, '', url.toString());
      this.props.navigation.navigate("FilterAllCategory", "?sellingViewAll=true" )
  });
  }
  viewDataOnHome(id: number) {
    const url = new URL(window.location.href);
      url.searchParams.set('ViewListing', `${id}`);
      window.history?.pushState({}, '', url.toString());
    this.props.navigation.navigate('ViewListing', { id: id })
  }
  popupSaveds = (id: number) => {
    this.setState({ removeSavedList: true, deleteItemIds: id, })
  }
  handleListing = () => {
    this.props.navigation.navigate("CreateListing")
  }
  handleListingExplores = () => {
    this.props.navigation.navigate("FilterAllCategory")
  }
  LookingNavigation = () => {
    this.props.navigation.navigate("LookingSomething")
  }
  SellingNavigation = () => {
    this.props.navigation.navigate("SellingSomething")
  }
  saveClosePopups = () => {
    this.setState({ savedPopupLanding: false })
  }
  saveClosePopupss = () => {
    this.setState({ savedPopups: false })
  }
  handleSaveListings = () => {
    this.props.navigation.navigate("UserProfileBasicBlock", "?tab=savedlist")
  }
  ContactusNavigation = () => {
    this.props.navigation.navigate("AddContactus")
  }
  navigateSavedSomethingLanding = (id: any) => {
    this.setState({ removeSavedList: false })
  }
  successpopupSavedLanding = (id: any) => {
    this.setState({ successRemoveSaving: false })
  }
  closePopupPasswordLanding = () => {
    this.setState({ successRemoveSaving: false });
  };

  getJobsAppliedExecutiveDatasMain = () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLookingSomeItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/local_listing_ads"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        // token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getsavedListingDats = () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSavedListingCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  userProfileCall = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallLookingIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/show_user_profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  ShowImages = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiImageResponse = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/show_user_profile"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "token": token,
      }));
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
    return true;
  };
  savedlistApiCallMain = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSave_ListingCallIdssMain = requestMessage.messageId;
    let Body = {
      "data": {
        "local_listing_ad_id": id
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteSavelistingCallMain = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apideleteSavingLandingCallIdMain = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_locallistingads/wishlist_items/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
