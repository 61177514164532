// Customizable Area Start
import React from "react";
import { Box, styled, Button, CircularProgress } from "@material-ui/core";
import SavedListingController from "./SavedListingController";
import { searchImageSave, shapeSave, deleteSaveds, group_CrossButton, SellingSomesImage } from "./assets";
import { SavedListingEmpty } from "../../../components/src/SavedListingEmpty.web";
import Popup from 'reactjs-popup';

const CardListing = styled(Box)({
  width: "185px",
  maxWidth: "185px",
  borderRadius: "5px",
  padding: "20px 20.5px",
  justifyContent: 'space-between',
  backgroundColor: "#FFFFFF",
  flexWrap: 'wrap',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
});
const CardImageList = styled("img")({
  width: "180px",
  height: "211px",
  objectFit: "contain",
  borderRadius: "5px",
  marginTop: 15,
});
const EditImageLists = styled("img")({
  width: "29.1px",
  height: "29px",
  cursor: "pointer",
});
const SearchImageLists = styled("img")({
  width: "25px",
  height: "25px"
});
const BodyTypographySaveList = styled("h6")({
  marginTop: 10,
  color: '#676767',
  fontFamily: "Segoe UI Bold",
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

});
const Lookingfor = styled("h6")({
  width: "150px",
  fontFamily: "Segoe UI Bold",
  fontSize: "12px",
  lineHeight: "20px",
  color: '#676767',
  margin: 0
});
const BudgetTypographySaving = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: 0,
  color: "#676767"
});
const StyledSpanListShowing = styled("span")({
  color: "#3CAA49",
  fontFamily: "Segoe UI Bold",
  fontSize: "14px",
  lineHeight: "22px",
  margin: 0
});
const CommissionTypographyShowList = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});
const SaveLists = styled("div")({
  display: "grid",
  gridGap: '20px',
  paddingLeft: '20px',
  justifyContent: 'flex-start',
  paddingTop: "20px",
  gridTemplateColumns: "auto auto auto auto",
  "@media(max-width:960px)": {
    gridTemplateColumns: "auto auto auto"
  },
  "@media(max-width:767px)": {
    gridTemplateColumns: "auto auto"
  },
  "@media(max-width:450px)": {
    gridTemplateColumns: "auto",
    justifyContent: 'center',
  }
});

const Loader = styled(Box)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "rgb(247, 245, 245)"
});

export default class SavedListingWeb extends SavedListingController {
  budgetData(item:any){
    return (
      <BudgetTypographySaving>
      Budget <StyledSpanListShowing> £ {item}</StyledSpanListShowing>
    </BudgetTypographySaving>
    )
  }
  budgetDataPasswors(){
    return (
      <p style={webstyle.passwordChange as React.CSSProperties}>{this.state.deleteItem}</p>
    )
  }
  removeSaveListing(){
    return(
      <p style={webstyle.popuptextsss as React.CSSProperties} >Do you want to remove item from Saved Listing?</p>
    )
  }

  getText = (type: string | null) => {
    return type === "looking_for_something" ? "Budget" : "Price";
  }
  
  render() {
    return (
      <Box style={webstyle.wrapper}>
        {this.state.LoaderSaveListing ?
          <Loader>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress style={{ color: '#4fa94d' }} />
            </Box>
          </Loader> : <>
            {this.state.savedListing.length == 0 ? <SavedListingEmpty navigation={this.props.navigation.navigate} /> :
              <SaveLists>
                {this.state.savedListing?.map((item) => (
                  
                  <CardListing  key={item.attributes.id} 
                  >
                     <a data-test-id="mylist1"  style={{ textDecoration: 'none', color: 'inherit' }}   href={`/ViewListing/${item.attributes.listing.data.id}`} onClick={(event) => {
                      event.preventDefault();
                      this.viewDataSavedList(item.attributes.listing.data.id)
                  }}>
                    <div style={{ display: "flex", alignItems: 'center', gap: 10 }}>
                      <SearchImageLists
                        src={item.attributes.listing.data.attributes.listing_type === "looking_for_something" ? searchImageSave : SellingSomesImage}
                        alt={item.attributes.listing.data.attributes.listing_type === "looking_for_something" ? "Looking for something" : "Card Image"}
                      />
                      <Lookingfor>{item.attributes.listing.data.attributes.listing_type === "looking_for_something" ? "Looking for something" : "Selling something"}</Lookingfor>
                    </div>
                    <CardImageList src={item.attributes.listing.data.attributes.photo?.[0].url} alt="Card Image" /> 
                    <BodyTypographySaveList>{item.attributes.listing.data.attributes.title}</BodyTypographySaveList>
                    <BudgetTypographySaving>
                    {this.getText(item.attributes.listing.data.attributes.listing_type)} <StyledSpanListShowing> £ {item.attributes.listing.data.attributes.budget}</StyledSpanListShowing>
                    </BudgetTypographySaving>
                    </a>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <CommissionTypographyShowList>
                      Reward<StyledSpanListShowing> £ {item.attributes.listing.data.attributes.commission}</StyledSpanListShowing>
                      </CommissionTypographyShowList>
                      <EditImageLists  data-test-id="mylistSavedEdit" src={shapeSave} alt="Card Image" onClick={(e) => { e.stopPropagation(); this.popupSaved(item.attributes.listing.data.id);}} />
                    </div>
                  </CardListing>
                 
                ))}
                <div style={webstyle.actionsStyles as React.CSSProperties}>
                  <Popup
                    open={this.state.removeSavedListing}
                    test-id="popup"
                    closeOnDocumentClick onClose={this.navigateSavedSomething}
                    className="popup"
                    contentStyle={webstyle.contentsDelete}
                    overlayStyle={webstyle.overlaysDelete}
                  >
                    <div style={webstyle.deletePopupDelete as React.CSSProperties}>
                      <p style={webstyle.popuptextsDelete as React.CSSProperties} >Remove Saved Listing</p>
                      <div>
                       {this.removeSaveListing()}
                      </div>
                      <Box >
                        <Button style={webstyle.plateformExploreBtnss as React.CSSProperties} onClick={this.navigateSavedSomething}>Cancel</Button>
                        <Button style={webstyle.plateformListingBtndeleteAccount as React.CSSProperties} data-test-id="handleListing"
                          onClick={() => this.deleteSavelistingCall(this.state.deleteItemId)}
                        >Remove</Button>
                      </Box>
                    </div>
                  </Popup>
                </div>
                <div>
                  <Popup
                    open={this.state.successRemoveSaved}
                    test-id="popup"
                    closeOnDocumentClick onClose={this.successpopupSaved}
                    className="popup"
                    contentStyle={webstyle.contentsPassword}
                    overlayStyle={webstyle.overlayss}
                  >
                    <div>
                      <img src={group_CrossButton} alt="Crosses" style={webstyle.imgCross as React.CSSProperties} onClick={this.successpopupSaved} />
                      <div style={webstyle.popupIMGMainss as React.CSSProperties} >
                        <img src={deleteSaveds} alt="Saved"/>
                      </div>
                     {this.budgetDataPasswors()}
                    </div>
                  </Popup>
                </div>

              </SaveLists>
            }
          </>
        }
      </Box>
    );
  }
}
const webstyle = {
  wrapper: {
    padding: "0px",
    width: "100%",
    height: "calc(100% - 49px)",
    backgroundColor: "rgb(247, 245, 245)",
  },
  actionsStyles: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  contentsDelete: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '527px',
    borderRadius: '8px',
    height: '322px'
  },
  deletePopupDelete: {
    marginTop: "85px",
    paddingLeft: '52px'
  },
  overlaysDelete: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popuptextsDelete: {
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '32px',
    margin: '0',
  },
  popuptextsss: {
    fontSize: '18px',
    fontFamily: "Segoe UI",
    color: '#676767',
    width: '400px',
    LineHeight: '26px'
  },
  plateformExploreBtnss: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "15px",
    textTransform: "capitalize",
  },
  plateformListingBtndeleteAccount: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  contentsPassword: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '30.5px 43.5px 30.5px 43.5px',
    width: '500px',
    borderRadius: '8px',
    height: '350px'
  },
  overlayss: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popupIMGMainss: {
    width: '232px',
    heigth: '232px',
    margin: 'auto',
  },
  passwordChange: {
    fontSize: '30px',
    marginTop: "15px",
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#000000",
    LineHeigth: '32px',
    margin: 'auto',
  },
  imgCross: {
    position: 'absolute',
    top: 38,
    margin: 'auto',
    right: 36,
    cursor: 'pointer',
  },
}
// Customizable Area End
