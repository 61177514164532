import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type LookingFilterDatas = {
  map(arg0: (item: {
    id: number; type: string; attributes: { id: number; name: string; };
  }) => JSX.Element): unknown;
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
  }
}
export type LookingSomeDatas = {
  "id": number;
  "type": string;
  "attributes": {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  keyCount: boolean;
  isTypeChanges: boolean;
  title: string;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  };
  categortText: any;
  isCheckedList: string[];
  subCategoryApiDatasAll: any;
  budgetMin: any;
  budgetMax: any;
  budgetMinc: any;
  budgetMaxc: any;
  lookingForSomethingChecked: boolean;
  sellingSomethingChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showBudgetData:boolean;
  showCommissionData:boolean;
  showLookingSomeData:boolean;
  showSellingSomeData:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sectionsVisibles: { [key: string]: boolean },
  sectionInterior: object,
  showAllCheckbox: boolean,
  showAntiquesCheckbox: boolean,
  categoryApiFilterAll: LookingFilterDatas[],
  subCategoryApiDatasAll: any | LookingFilterDatas[],
  fiterQueryData: LookingFilterDatas[],
  selectedCategoryId: number,
  categoryType: string,
  categoryText: number[],
  isChecked: boolean,
  lookingForSomethingChecked: boolean,
  sellingSomethingChecked: boolean,
  applyButtonClicked: boolean,
  searchText: string,
  searchSelling: string,
  filtersData: boolean,
  categortText: any[];
  subCategoryDataChecked: boolean;
  isNamed: string;
  [key: string]: any;
  isCheckedList: any;
  isAllCategory: boolean;
  isData: any;
  isLoading: boolean;
  budgetMin: any;
  budgetMax: any;
  budgetSliders: any;
  value: any[],
  minError: string,
  budgetMinTemp: string,
  budgetMaxTemp: boolean,
  maxError: string,
  budgetMinc: any;
  budgetMaxc: any;
  budgetSlidersc: any;
  values: any[],
  minErrors: string,
  maxErrors: string,
  ShowHide: boolean,
  ShowBudget: boolean,
  ShowCommission: boolean,
   ShowDataAll:number,
   showBudgetData:boolean,
   showCommissionData:boolean,
   clearAllData:boolean,
   showLookingSomeData:boolean
   showSellingSomeData:boolean,
   categoryLoader: boolean;
   keyCount: boolean;
   isTypeChanges: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilterAllCategoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCategoryCallIdsAll: string = "";
  apiSubCategoryCallIdsAll: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      sectionsVisibles: {},
      lookingForSomethingChecked: false,
      sectionInterior: {},
      showAllCheckbox: false,
      showAntiquesCheckbox: false,
      categoryApiFilterAll: [],
      subCategoryApiDatasAll: [],
      fiterQueryData: [],
      selectedCategoryId: 0,
      isChecked: false,
      sellingSomethingChecked: false,
      applyButtonClicked: false,
      categoryType: "",
      categoryText: [],
      searchText: "",
      searchSelling: "",
      filtersData: false,
      categortText: [],
      subCategoryDataChecked: false,
      isNamed: "",
      isCheckedList: [],
      isAllCategory: false,
      isData: [],
      isLoading: false,
      budgetSliders: null,
      budgetMin: "",
      budgetMax: "",
      budgetMinTemp: '',
      budgetMaxTemp: false,
      value: [1, 1000000],
      minError: "",
      maxError: "",
      budgetSlidersc: null,
      budgetMinc: "",
      budgetMaxc: "",
      values: [1, 100000],
      minErrors: "",
      maxErrors: "",
      ShowHide: false,
      ShowBudget: false,
      ShowCommission: false,
      ShowDataAll:0,
      showBudgetData:false,
      showCommissionData:false,
      clearAllData:false,
      showLookingSomeData:false,
      showSellingSomeData:false,
      categoryLoader: false,
      keyCount: false,
      isTypeChanges: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiCategoryCallIdsAll) {
        this.handleCategoryResponsesFilterAll(responseJson);
      }
      else if (apiRequestCallId === this.apiSubCategoryCallIdsAll) {
        this.handleSubCategoryResponsesFilterAll(responseJson);
      
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    
   let data = this.props.navigation.getParam('id')
   if(data){
     this.toggleSectionsVisibilitys(data)
   }
    this.categoryItemsAllFilter();
    if (typeof window !== 'undefined') {

      const currentUrl = window.location.href;
      const searchParamsLooking = new URLSearchParams(new URL(currentUrl).search).get("lookingViewAll");
      const searchParamsSelling = new URLSearchParams(new URL(currentUrl).search).get("sellingViewAll");

      if (searchParamsLooking === "true") {
        this.setState({
          ShowHide: true,
          keyCount: !this.state.keyCount,
          isTypeChanges: true,
          showLookingSomeData:true,
          showSellingSomeData:false
      });
        const lookingViewAll = !!searchParamsLooking;
        const url = new URL(currentUrl);
        this.setState({ lookingForSomethingChecked: lookingViewAll });
        url.searchParams.delete("lookingViewAll");
        window.history?.replaceState({}, '', url.toString());
      }

      if (searchParamsSelling === "true") {
        this.setState({
          ShowHide: true,
          keyCount: !this.state.keyCount,
          isTypeChanges: false,
          showSellingSomeData:true,
          showLookingSomeData:false,
      });
        const sellingViewAll = !!searchParamsSelling;
        const url = new URL(currentUrl);
        this.setState({ sellingSomethingChecked: sellingViewAll });
        url.searchParams.delete("sellingViewAll");
        window.history?.replaceState({}, '', url.toString());
      }
      if(!data && !searchParamsLooking && !searchParamsSelling){
        this.setState({ keyCount: !this.state.keyCount, isTypeChanges: false });
      }
    }
  

  }
  handleCategoryResponsesFilterAll(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        categoryApiFilterAll: responseJson?.data
      });
    }
    this.setState({ categoryLoader: false });
  }

  handleSubCategoryResponsesFilterAll(responseJson: any) {
    this.setState({ isLoading: false });
    if (responseJson && !responseJson.errors && responseJson.data) {
      let id = Object.entries(this.state.sectionsVisibles).reduce((accumulator, [key, value]) => {
        if (value) {
          accumulator.push(key);
        }
        return accumulator;
      }, [] as string[]);

      id.forEach((i, idx) => {
        let a = [...responseJson.data]
        let add = {
          id: idx,

          "type": "category",
          "attributes": {
            "id": "all",
            "name": "All",
          }
        } as any;
        a.unshift(add)
        a.forEach((subCategory) => { subCategory.isChecked = true });
        if (!this.state.subCategoryApiDatasAll[i as any]) {

          this.setState({
            keyCount: !this.state.keyCount,
            isTypeChanges: false,
            subCategoryApiDatasAll: { ...this.state.subCategoryApiDatasAll, [i]: a },
          });
        }
      });
    }
  }

  toggleSectionsVisibilitys = (categoryId: any) => {
   
    const newVisibility = !this.state.sectionsVisibles[categoryId];
    const updatedCategortText = [categoryId];
    this.setState({ categortText: updatedCategortText })
    if (!this.state.subCategoryApiDatasAll[categoryId]) {
      this.subCategoryItemAllFilter(categoryId);
    } else {
      this.setState({ keyCount: !this.state.keyCount, isTypeChanges: false });
    }
    Object.keys(this.state.sectionsVisibles).forEach((i: any) => {
     

      if (this.state.sectionsVisibles[i]) {
        this.state.subCategoryApiDatasAll[categoryId] && this.state.subCategoryApiDatasAll[categoryId].map((i: any) => {
        i.isChecked=true
        localStorage.removeItem("catId")

        })
      }
    })

    if(!newVisibility) {
      this.setState({ categortText: [] });
    }
    this.setState((prevState) => {
      const newSectionsVisibles = { ...prevState.sectionsVisibles };
      Object.keys(newSectionsVisibles).forEach((key) => {
        newSectionsVisibles[key] = false;
        localStorage.removeItem("catId")
    });
      newSectionsVisibles[categoryId] = newVisibility;
      return { sectionsVisibles: newSectionsVisibles };
    });

  };

  toggleAllCheckboxVisibility = () => {
    this.setState((prevState) => ({
      showAllCheckbox: !prevState.showAllCheckbox,
      showAntiquesCheckbox: false,
    }));
  };
  homePageGo = () => {
    const token = localStorage.getItem("auth");
    if(token){
    this.props.navigation.navigate('LandingPage')
    } else {
      this.props.navigation.navigate('LandingPageMain');
    }
  }
  valuetext = (value: any) => {
    return `${value}€`;
  }

  handleChange = (newValue: any) => {
    const [newMin, newMax] = newValue;
    let minError = "";
    let maxError = "";
  
    if (!isNaN(newMin) && !isNaN(newMax)) {
      if (parseInt(newMin) < 1) {
        minError = "Minimum amount is £1";
      } else if (parseInt(newMin) >= parseInt(newMax)) {
        minError = "Minimum amount must be less than the maximum amount";
      }
  
      if (newMax === "" || newMax > 1000000) {
        maxError = "Maximum amount is £1,000,000";
      }
  
      this.setState({
        value: newValue,
        budgetMin: newMin,
        budgetMax: newMax,
        minError: minError,
        maxError: maxError,
        ShowBudget: false,
        ShowHide: true
      });
    } else {
      this.setState({
        ShowBudget: false,
        ShowHide: true
      });
    }
  }

  handleChanges = (newValue: any) => {
    const [newMins, newMaxs] = newValue;
    let minErrors = "";
    let maxErrors = "";
  
    if (!isNaN(newMins) && !isNaN(newMaxs)) {
      if (parseInt(newMins) < 1) {
        minErrors = "Minimum amount is £1";
      } else if (parseInt(newMins) >= parseInt(newMaxs)) {
        minErrors = "Minimum amount must be less than the maximum amount";
      }
  
      if (newMaxs === "" || newMaxs > 1000000) {
        maxErrors = "Maximum amount is £1,000,000";
      }
  
      this.setState({
        values: newValue,
        budgetMinc: newMins,
        budgetMaxc: newMaxs,
        minErrors: minErrors,
        maxErrors: maxErrors,
        ShowCommission: false,
        ShowHide: true
      });
    } else {
      this.setState({
        ShowCommission: false,
        ShowHide: true
      });
    }
  }
  
  
  valuetexts = (value: any) => {
    return `${value}€`;
  }


  

  toggleAntiquesCheckboxVisibility = () => {
    this.setState((prevState) => ({
      showAntiquesCheckbox: !prevState.showAntiquesCheckbox,
      showAllCheckbox: false,
    }));
  };
  categoryItemsAllFilter = async () => {
    this.setState({ categoryLoader: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCategoryCallIdsAll = requestMessage.messageId
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/categories/categories"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  subCategoryItemAllFilter = async (categoryId: number) => {
    this.setState({ selectedCategoryId: categoryId, isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubCategoryCallIdsAll = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_categories/categories/${categoryId}/show_subcategories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  updateSelectedData() {
    const selectedData :any= [];
    Object.keys(this.state.subCategoryApiDatasAll).forEach(categoryId => {
        this.state.subCategoryApiDatasAll[categoryId].forEach((subCategory:any) => {
            if (subCategory.isChecked) {
                selectedData.push(subCategory);
            }
        });
    });

    this.setState({ selectedSubCategoriesData: selectedData });
}


onChnageFilter =(e:any,label:string,items:any,categoryIds:any,arrCopy:any)=>{
                            
  if (label === 'All') {
      arrCopy?.forEach((i:any) => (i.isChecked = e.target.checked));
      this.setState(
          {  keyCount: !this.state.keyCount, isTypeChanges: false, subCategoryApiDatasAll: { ...this.state.subCategoryApiDatasAll, [categoryIds]: arrCopy } },
          () => this.updateSelectedData()
      );
      return;
  }
  arrCopy.forEach((i:any) => {
      if (i.id === items) {
          i.isChecked = e.target.checked;
      }
  });
  if (!e.target?.checked) {
      arrCopy.forEach((i:any) => {
          if (i.attributes?.name === 'All') {
              i.isChecked = false;
          }
      });
  }

  this.setState(
      {
          keyCount: !this.state.keyCount,
          isTypeChanges: false,
          subCategoryApiDatasAll: { ...this.state.subCategoryApiDatasAll, [categoryIds]: arrCopy },
          sectionsVisibles: { ...this.state.sectionsVisibles, [categoryIds]: true },
      },
      () => this.updateSelectedData()
  );
}

  // Customizable Area End
}
