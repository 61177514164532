// Customizable Area Start
import React from "react";
import { Box, Grid, ThemeProvider, Typography, CircularProgress, Button, createTheme } from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import FinalProductsDetailsController from "./FinalProductsDetailsController";
import { Look, Sell } from "../../locallistingads/src/assets";
import { refresh, profiles } from "./assets";
export default class FinalProductsDetails extends FinalProductsDetailsController {
    renderContent = () => {
        const userNameMatches = this.state.LocalData === this.state.fullName;

        return (
            <>
                {userNameMatches ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' }}>
                        <>{this.state.statusSeller === "finalise_deal" ?
                            <Box style={webStyleData.review}>
                                <span style={webStyleData.fullNameData}>@{this.state.listing_typeData == "looking_for_something" ?
                                    this.state.SellerUserName : this.state.BuyerUserName}</span>
                                <span style={webStyleData.underReview}>finalised the deal.</span>
                            </Box>
                            :
                            this.state.statusSeller === "under_review" ?
                                <Box style={webStyleData.review}>
                                    <span style={webStyleData.fullNameData}>@{this.state.listing_typeData == "looking_for_something" ?
                                        this.state.SellerUserName : this.state.BuyerUserName}</span>
                                    <span style={webStyleData.underReview}>has sent a counter-offer</span>
                                </Box> :
                                <Box style={webStyleData.review}>
                                    <span style={webStyleData.fullNameData}>@{this.state.listing_typeData == "looking_for_something" ?
                                        this.state.SellerUserName : this.state.BuyerUserName}
                                    </span>
                                    <span style={webStyleData.underReview}>has sent a counter-offer</span>
                                </Box>
                        }
                         <a data-test-id="openPopup"  style={{ textDecoration: 'none', color: 'inherit' }} href={`/FromApprovalSeller/${ this.props.navigation.getParam('id')}`} onClick={(event) => {
          event && event.preventDefault();
          this.handleClickRefresh()
      }}>
                            <Button
                                variant="contained" style={webStyleData.createButtons2}
                                // onClick={this.handleClickRefresh}
                            >
                                <span style={{ textTransform: 'none' }}>Go to Negotiation</span>
                            </Button></a>
                        </>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' }}>
                        {this.state.statusSeller === "under_review" ? (
                            <>
                                <Box style={webStyleData.review}>
                                    <span style={webStyleData.underReview}>Negotiation is under review with</span>
                                    <span style={webStyleData.fullNameData}>@{this.state.UserName}</span>
                                </Box>
                                <Box data-test-id="handleClickRefresh" style={{ cursor: 'pointer' }} onClick={this.handleRefreshClick}>
                                    <img src={refresh} alt="Refresh" />
                                </Box>
                            </>
                        ) : (
                            <>
                                {
                                    this.state.statusSeller === "counter_offer" ?
                                        <Box style={webStyleData.review}>
                                            <span style={webStyleData.fullNameData}>@{this.state.UserName}</span>
                                            <span style={webStyleData.underReview}>has sent a counter-offer</span>
                                        </Box>

                                        :
                                        <Box style={webStyleData.review}>
                                            <span style={webStyleData.fullNameData}>@{this.state.UserName}</span>
                                            <span style={webStyleData.underReview}>finalised the deal. </span>
                                        </Box>
                                }
                                <Button
                                    variant="contained"
                                    style={webStyleData.createButtons2}
                                    onClick={this.handleClickRefresh}
                                >

                                    <span style={{ textTransform: 'none' }}>Go to Negotiation</span>
                                </Button>
                            </>
                        )}
                    </div>

                )}
            </>
        );
    }
    renderDeal = () => {
        return (
            <Typography style={webStyleData.listingData}>Deal Status</Typography>
        )
    }

    renderContentData = () => {
        const userNameMatches = localStorage.getItem("email");
        const userNameFromLocalStorage = userNameMatches === this.state.email;
    
        return (
            <>
                <Box style={webStyleData.boxList}>
                    {this.state.listing_typeData === "looking_for_something" ? (
                        userNameFromLocalStorage ? (
                            <Box>
                                <a
                                    data-test-id="handleClickData21"
                                      style={{ textDecoration: 'none', color: 'inherit' }}
                                href={`/OtherUserDetails/${this.state.AccountSeller}`}
                                onClick={(event) => 
                                    {
                                       
                                         event && event.preventDefault();
                                       
                                     this.userDetailsIds(this.state.AccountSeller);
                                    }}
                                
                                >
                                     <div style={webStyleData.SellerPhotos}>
                                         {this.state.SellerPhoto == null ? (
                                        <img style={webStyleData.userPhotosData} src={profiles} alt="Default Profile" />
                                         ) : ( 
                                        <img style={webStyleData.userPhotosData} src={this.state.SellerPhoto} alt="" />
                                        )
                                        }
                                         <span style={webStyleData.fullnamDatae}>{this.state.SellerName}</span>
                                         <span style={webStyleData.userDetailsData}>/ @{this.state.SellerUserName}</span>
                                    </div>
                                </a>
                          
                            </Box>
                        ) : (
                            <Box>
                                <a
                                    data-test-id="handleClickData21"
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                    
                                    href={`/OtherUserDetails/${this.state.AccountBuyer}`}
                                    onClick={(event) => {
                                         event && event.preventDefault();
                                           this.userDetailsIds(this.state.AccountBuyer);
                                    }}
                                >
                                    <div  style={webStyleData.SellerPhotos}>
                                        {this.state.BuyerPhoto == null ? 
                                        (
                                          <img style={webStyleData.userPhotosData} src={profiles} alt="Default Profile" />
                                        ) 
                                        : 
                                        (
                                              <img style={webStyleData.userPhotosData} src={this.state.BuyerPhoto} alt="" />
                                        )}
                                         <span style={webStyleData.fullnamDatae}>{this.state.BuyerName}</span> /
                                         <span style={webStyleData.userDetailsData}>@{this.state.BuyerUserName}</span>
                                     </div>
                                </a>
                            </Box>
                        )
                    ) : (
                      userNameFromLocalStorage ? (
                            <Box>
                                <a
                                    data-test-id="handleClickData41"
                                     style={{ textDecoration: 'none', color: 'inherit' }}
                                     href={`/OtherUserDetails/${this.state.AccountBuyer}`}
                                    onClick={(event) => {
                                        event && event.preventDefault();
                                        
                                        this.userDetailsIds(this.state.AccountBuyer);
                                    }}
                                >
                                    <div style={webStyleData.BuyerPhotos}>
                                        {this.state.BuyerPhoto == null ? 
                                        (
                                          <img style={webStyleData.userPhotosData} src={profiles} alt="Default Profile" />
                                        )  
                                         : 
                                        (
                                          <img style={webStyleData.userPhotosData} src={this.state.BuyerPhoto} alt="" />
                                        )}
                                        <span style={webStyleData.fullnamDatae}>{this.state.BuyerName}</span> /
                                        <span style={webStyleData.userDetailsData}>@{this.state.BuyerUserName}</span>
                                    </div>
                                </a>
                            </Box>
                        ) : (
                            <Box>
                                <a
                                    data-test-id="handleClickData41"
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                    href={`/OtherUserDetails/${this.state.AccountSeller}`}
                                    onClick={(event) => {
                                        event && event.preventDefault();
                                        this.userDetailsIds(this.state.AccountSeller);
                                    }}
                                >
                                    <div style={webStyleData.userDetailsDatass}>
                                        {this.state.SellerPhoto == null ? (
                                            <img style={webStyleData.userPhotosData} src={profiles} alt="Default Profile" />
                                        ) : (
                                            <img style={webStyleData.userPhotosData} src={this.state.SellerPhoto} alt="" />
                                        )}
                                        <span style={webStyleData.fullnameData}>{this.state.SellerName}</span> /
                                        <span style={webStyleData.userDetailData}>@{this.state.SellerUserName}</span>
                                   
                                    </div>
                                </a>
                            </Box>
                        )
                    )}
               
                <div style={{ paddingTop: '10px' }}>
                    <span style={webStyleData.userDetailsData}>
                        {userNameFromLocalStorage ?
                            (this.state.listing_typeData == "looking_for_something" ? (this.state.SellerCity== null? "__": this.state.SellerCity) : (this.state.BuyerCity == null ?  "__" : this.state.BuyerCity)) 
                            :
                            (this.state.listing_typeData == "looking_for_something" ? (this.state.BuyerCity == null? "__": this.state.BuyerCity) : (this.state.SellerCity  == null ? "__" : this.state.SellerCity))
                        }
                        ,
                        {" " + (userNameFromLocalStorage ?
                            (this.state.listing_typeData == "looking_for_something" ? (this.state.SellerLocation  == null ? "__" : this.state.SellerLocation) : (this.state.BuyerLocation==null ? "__" : this.state.BuyerLocation))
                             :
                            (this.state.listing_typeData == "looking_for_something" ? (this.state.BuyerLocation == null ? "__" : this.state.BuyerLocation) : (this.state.SellerLocation==null ? "___" : this.state.SellerLocation))
                        )}
                    </span>
                </div>
                
                </Box>
            </>
        );
    }
    renderContentDataUserName = () => {
        const userNameFromLocalStorage = localStorage.getItem("fullName");
        const userNameMatches = userNameFromLocalStorage !== this.state.fullName;
        return (
            <>
                {userNameMatches ?
                    this.state.UserName :
                    (this.state.listing_typeData === "looking_for_something" ? this.state.SellerUserName : this.state.BuyerUserName)
                }
            </>
        );
    }
   
    renderNuyerToSeller = () => {
        const userNameFromLocalStorage = localStorage.getItem("fullName");
        const userNameMatches = userNameFromLocalStorage !== this.state.fullName;
        const { listing_typeData } = this.state;
        return (
          <Typography style={webStyleData.listingData as React.CSSProperties}>
            {listing_typeData === "selling_for_something" ? (
              userNameMatches ? "Provider" : "Buyer"
            ) : (
              userNameMatches ? "Buyer" : "Provider"
            )}
          </Typography>
        );
      }
    renderImageGridItem = (item: any, index: any) => (
        <Grid item key={index} xs={6} lg={4} sm={6} md={6} xl={4}>
            <img
                src={item.url}
                alt=""
                height={100}
                width="100%"
                style={{
                    marginBottom: "10px",
                    marginRight: "1px",
                    objectFit: "contain"
                }}
            />
        </Grid>
    );
    renderLoaderPage = () => {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ color: '#4fa94d' }} />
            </Box>

        )
    }


    render() {
        const theme = createTheme();
        const { photoData } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <AppHeader navigation={this.props.navigation.navigate} />
                <Box style={{ padding: '12px 48px' }}>
                    <Box style={{ marginBottom: '100px' }}>
                        {this.state.LoaderFinalProduct ?
                            (<div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100vh',
                                }}
                            >
                                {this.renderLoaderPage()}
                            </div>) :
                            <Grid container spacing={3} style={{ marginTop: "20px", padding: '0 40px' }}>
                                <Grid item xs={12} lg={4} md={6} sm={12} xl={4} >
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                alignItems: "right",
                                                objectFit: 'cover'
                                            }}
                                        >
                                            <img style={webStyleData.imgCover as React.CSSProperties} src={this.state.photoData[0]?.url} alt="" height={350} width="100%" />
                                        </Grid>
                                        <Grid container spacing={3}>
                                            {photoData?.slice(1).flat().map((item, index) => this.renderImageGridItem(item, index))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={8} md={6} sm={12} xl={8}>
                                    <div style={webStyleData.labelData}>
                                        <Typography style={webStyleData.titleData as React.CSSProperties} >
                                            {this.state.titleData}
                                        </Typography>
                                        <Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <p style={webStyleData.userDetailsData}>@{this.renderContentDataUserName()}</p>
                                            </div>
                                        </Typography>
                                        <hr style={webStyleData.hr1Data as React.CSSProperties} />
                                        <Typography style={webStyleData.catagoryData as React.CSSProperties} >
                                            <Typography >
                                                <Typography style={{ fontStyle: "italic", color: "#67676799" }}>Category</Typography>
                                                <Typography style={webStyleData.catagoryTextData as React.CSSProperties}>
                                                    {this.state.categoryData}
                                                </Typography>
                                            </Typography>
                                            <Typography >
                                                <Typography style={{ fontStyle: "italic", color: "#67676799" }}>Sub-Category</Typography>
                                                <Typography style={webStyleData.catagoryTextData as React.CSSProperties}>
                                                    {this.state.sub_categoryData}
                                                </Typography>
                                            </Typography>
                                            <Typography>
                                                <Typography style={{ fontStyle: "italic", color: "#67676799" }}>Location</Typography>
                                                <Typography style={webStyleData.catagoryTextData as React.CSSProperties}>
                                                    {this.state.locationData}
                                                </Typography>
                                            </Typography>
                                        </Typography>
                                        <hr style={webStyleData.hrData as React.CSSProperties} />
                                        <Typography style={webStyleData.discrtiptionData as React.CSSProperties}>
                                            Description
                                        </Typography>
                                        <Typography style={webStyleData.discrtiptionTextData as React.CSSProperties}>
                                            {this.state.descriptionData}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid style={{ width: "100%" }}>
                                    <hr style={webStyleData.hr1Data as React.CSSProperties} />
                                    <p style={webStyleData.listingData}>Listing Category</p>
                                    <Typography style={webStyleData.listingtypeData as React.CSSProperties} >
                                        {

                                            this.state.listing_typeData === "looking_for_something" ? <> <img src={Look} alt="" style={{ paddingRight: '20px' }} /><Typography style={webStyleData.looks}>Looking for something</Typography></> : <> <img src={Sell} alt="" style={{ paddingRight: '20px' }} /><Typography style={webStyleData.looks}>Selling something</Typography></>

                                        }
                                    </Typography>
                                    <hr style={webStyleData.hr1Data as React.CSSProperties} />
                                    <Box style={{ marginTop: '20px' }}>
                                        {this.renderNuyerToSeller()}
                                        {this.renderContentData()}

                                    </Box>
                                    <hr style={webStyleData.hr1Data as React.CSSProperties} />
                                    <Box>
                                        {this.renderDeal()}
                                        {this.renderContent()}
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Box>






                </Box>
                <AppFooter naviagtion={this.props.navigation.navigate} />
            </ThemeProvider>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const webStyleData = {
    imgCover: {
        objectFit: "contain"
    },
    titleData: {
        fontSize: "38px",
        color: "#676767",
        fontFamily: "Segoe UI Bold",
        wordBreak: 'break-all',
    },
    labelData: {
        paddingLeft: "20px"
    },
    listingtypeData: {
        color: "#676767",
        fontSize: "18px",
        fontFamily: "Segoe UI",
        paddingBottom: "10px",
        display: 'flex',
        textAlign: 'center',
        marginTop: "10px"
    },
    SellerPhotos:{
        display: 'flex', 
        alignItems: 'center', 
        cursor: "pointer"
    },
    BuyerPhotos:{
        display: 'flex', 
        alignItems: 'center', 
        cursor: "pointer"
    },
    budgeDatat: {
        padding: "15px 0px",
        display: "flex"
    },
    budgetText: {
        color: "#3CAA49",
        fontSize: "26px",
        fontFamily: "Segoe UI Bold",
        padding: "0 20px 0 10px"
    },
    budgetheadingData: {
        color: "#676767CC",
        fontSize: "20px",
        fontFamily: "Segoe UI",
        paddingRight: "10px",
    },
    catagoryData: {
        padding: "15px 0px",
        display: "flex",
        gap: 96
    },
    createButtonsData: {
        backgroundColor: '#3CAA49',
        color: '#FFFFFF',
        width: '180px',
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        borderRedius: '5px'
    },
    createButtons2Data: {
        color: '#3CAA49',
        width: '199px',
        background: "#FFFFFF",
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        border: '1px solid #3CAA49',
        borderRedius: '5px'
    },
    catagoryDataData: {
        color: "#67676799",
        paddingRight: "50px",
        fontSize: "16px",
        fontFamily: "Segoe UI",

    },
    catagoryTextData: {
        color: "#676767",
        fontSize: "18px",
        fontFamily: "Segoe UI",
        paddingBottom: "10px",
    },
    discrtiptionData: {
        color: "#67676799",
        fontSize: "16px",
        fontFamily: "Segoe UI",
        fontStyle: "italic",
        marginBottom: "10px"
    },
    discrtiptionTextData: {
        color: "#676767",
        fontSize: "16px",
        fontFamily: "Segoe UI",
        textAlign: "justify",
        overflowY: "auto",
        maxHeight: "300px",
        paddingRight: "20px"
    },
    ApprovalData: {
        color: "#67676799",
        fontSize: "16px",
        fontFamily: "Segoe UI",
        marginTop: "20px",
        fontStyle: "italic"
    },
    ApprovalTextData: {
        color: "#676767",
        fontSize: "16px",
        fontFamily: "Segoe UI",
        marginTop: "20px",
    },
    PreferencesData: {
        color: "#67676799",
        fontSize: "16px",
        fontFamily: "Segoe UI",
        marginTop: "20px",
        fontStyle: "italic"
    },
    PreferencesTextData: {
        color: "#676767",
        fontSize: "16px",
        fontFamily: "Segoe UI",
        marginTop: "20px",
        marginBottom: "100px"
    },
    hrData: {
        border: "none",
        height: "1px",
        backgroundColor: "#E5E7EB",
    },
    hr1Data: {
        border: "none",
        height: "1px",
        backgroundColor: "#E5E7EB",
        margin: "30px 0",
    },
    userPhotoData: {
        height: '52px',
        width: '52px%',
        borderRadius: '50%'
    },
    fullNameData: {
        color: "#3CAA49",
        fontSize: "20px",
        fontFamily: "Segoe UI Bold",
        padding: '0 10px'
    },
    userNameData: {
        color: "#676767",
        fontSize: "20px",
        fontFamily: "Segoe UI",
        paddingLeft: '5px'
    },
    cityCountryData: {
        color: "#676767",
        fontSize: "20px",
        fontFamily: "Segoe UI",
    },
    userPhotosData: {
        width: '52px',
        height: '52px',
        borderRadius: '50%',
        marginRight: '10px'
    },
    userDetailsData: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#676767",
        margin: 0
    },
    userDetailsDatass: {
        display: 'flex',
        alignItems: 'center', 
        cursor: "pointer" 
    },
    fullnamDatae: {
        fontFamily: "Segoe UI Bold",
        fontSize: "24px",
        color: '#3CAA49',
        paddingRight: '5px',
        paddingLeft: '10px'
    },
    fullnameData: {
        fontFamily: "Segoe UI Bold",
        fontSize: "24px",
        color: '#3CAA49',
        paddingRight: '5px',
        paddingLeft: '10px'
    },
    userDetailData: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#676767",
        margin: 0
    },
    listingData: {
        fontFamily: "Segoe UI Bold",
        fontSize: "24px",
        color: "#676767",
        margin: 0,
    },
    looks: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#676767",
    },
    boxList: {
        border: '1px solid #E5E7EB',
        width: 'max-content',
        borderRadius: '8px',
        marginTop: "10px",
        padding: '24px 32px'
    },
    review: {
        border: 'none',
        padding: '19.5px 24px 19.5px 24px',
        borderRadius: '8px',
        background: '#ECF7ED',
        display: 'flex',
        alignItems: 'center'
    },
    underReview: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#676767",
    },
    srcData: {
        marginBottom: '10px',
        marginRight: '1px',
        width: "100%",
        height: '120px'
    },
    createButtons2: {
        color: '#3CAA49',
        width: '199px',
        background: "#FFFFFF",
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        border: '1px solid #3CAA49',
        borderRedius: '5px'
    },
};

// Customizable Area End