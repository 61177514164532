// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import * as Yup from 'yup';
import { toast } from "react-toastify";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  amendment_deals: {
    id: number;
    initiate_deal_id: number;
    amendment_type: string;
    status: string;
    reason: string | null;
    created_at: string;
    updated_at: string;
  }[];
}

interface S {
  errorMsg: string;
  enableField: boolean;
  accessToken: string;
  approvalUrl: string;
  paymentId: number;
  redirect: boolean;
  countdown: any;
  showTextAreas: boolean;
  buttonClickeds: boolean;
  negotiateButtons: boolean;
  showTextAreaCommissions: boolean;
  showTextAreaApproval: boolean;
  showTextAreaPrefrences: boolean;
  buttonClickedCommissions: boolean;
  negotiateButtonCommissions: boolean;
  prefrenceButtonCommissions: boolean;
  ApprovalCriteriaButton: boolean;
  ApprovalCriteriaDenyButton: boolean;
  prefrenceDenyButtonCommissions: boolean;
  selecteds: any;
  selectedDescriptions: any;
  criteria_or_termss: any;
  datas: any;
  linkId: any;
  activeButtonss: any;
  showTextAreass: any;
  userDetailss: any;
  userDetailsIDs: any;
  amendmentDealsAttributess: any;
  acceptOrDenys: string;
  negotiatedReasons: string;
  textAreaValues: string;
  commissionStatuss: string;
  commissionReasons: string;
  preferenceStatuss: string;
  preferenceReasons: string;
  accountIDss: string;
  showPopupsLogoutNegotiations: boolean;
  popupNegotiates: boolean;
  amendment_dealss: any;
  criteria_amendmentss: any;
  buttonStatess: any;
  budgetStatuss: string;
  budgetReasons: string;
  criteria_amendment: any[];
  statusAmedent: string;
  ReasonsAmedent: string;
  InitaiateId: any;
  LoaderFormApproval: boolean;
  showAmendments:boolean;
  priceAmendments: boolean;
  showSendLinkSection: boolean;
  upper: boolean, // Set to true to initially show this section
  commission: boolean,
  criteriaOrTerms: boolean,
  preference: boolean,
  linktext: any,
  linkSentSuccess:boolean,
  showLink: boolean,
  rating:any,
  isReviewPopupOpen: boolean,
  isReviewSubmitted: boolean,
  isReviewSubmittedPopupOpen:boolean,
  reviewDescription:any,
  showAmendmentsApproval: any,
  isRotated:boolean,
  isLoading: boolean,
  reviewButton:boolean
  isModalOpen: boolean;
  dealId: null | number | string;
}

interface SS {
  id: any;
}

export default class PaymentSellerController extends BlockComponent<
  Props,
  S,
  SS
> {
  accessTokenCallId: string = "";
  paymentdetailCallId: string = "";
  PaymentExecuteCallId: string = "";
  FromApprovalDataApiData: string = "";
  SendLinkApidata: string ="";
  ApprovalFormApiDataPost: string = "";
  FromApprovalDataCancel: string = "";
  ShowLinkApidata: string = "";
  addReviewsId: string = "";
  closeNegotiationDealApiCallId: string = "";
  interval: number | undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      errorMsg: "Link is required",
      showTextAreas: false,
      buttonClickeds: false,
      negotiateButtons: false,
      showTextAreaCommissions: false,
      showTextAreaApproval: false,
      showTextAreaPrefrences: false,
      buttonClickedCommissions: false,
      negotiateButtonCommissions: false,
      prefrenceButtonCommissions: false,
      prefrenceDenyButtonCommissions: false,
      ApprovalCriteriaButton: false,
      ApprovalCriteriaDenyButton: false,
      selecteds: null,
      selectedDescriptions: null,
      datas: "",
      linkId:"",
      userDetailsIDs: null,
      criteria_or_termss: [],
      activeButtonss: Array(9).fill(null),
      showTextAreass: Array(9).fill(false),
      userDetailss: null,
      amendmentDealsAttributess: [],
      acceptOrDenys: "",
      negotiatedReasons: "",
      textAreaValues: "",
      commissionStatuss: "accept",
      commissionReasons: "",
      preferenceStatuss: "accept",
      preferenceReasons: "",
      accountIDss: "",
      showPopupsLogoutNegotiations: false,
      popupNegotiates: false,
      amendment_dealss: [],
      criteria_amendmentss: [],
      buttonStatess: {},
      budgetStatuss: "accept",
      budgetReasons: "",
      criteria_amendment: [],
      statusAmedent: "accept",
      ReasonsAmedent: "",
      InitaiateId: {},
      LoaderFormApproval: false,
      enableField: false,
      accessToken: "",
      approvalUrl: "",
      paymentId: 0,
      redirect: false,
      countdown: 3,
      showAmendments:false,
      priceAmendments: false,
      showSendLinkSection: false,
      upper: false,
      commission: true,
      criteriaOrTerms: true,
      preference: true,
      linktext:'',
      linkSentSuccess:false,
      showLink:false,
      rating:0,
      isReviewPopupOpen: false,
      isReviewSubmitted:false,
      isReviewSubmittedPopupOpen:false,
      reviewDescription:"",
      showAmendmentsApproval: {},
      isRotated:false,
      isLoading: true,
      reviewButton:false,
      isModalOpen: false,
      dealId: null

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.closeNegotiationDealApiCallId){
        if (responseJson && !responseJson.errors) {
          if (responseJson.message === "deal deleted.") {
            this.props.navigation.navigate("LandingPage");
          }
        } else if(responseJson.errors && responseJson.errors[0] && responseJson.errors[0].deal.includes("Record with id")){
          toast.success("This deal is cancelled!");
          this.props.navigation.navigate("LandingPage");
          return;
        }
      } else if (apiRequestCallId === this.FromApprovalDataApiData) {
        this.handleNegotitiatonResponseData(responseJson);
      }
      if (apiRequestCallId === this.SendLinkApidata) {
        this.setState({linkSentSuccess: true, showSendLinkSection:false})
      }
      if (apiRequestCallId === this.ShowLinkApidata) {
        if (responseJson.data && responseJson.data.is_send_link === false && !this.interval) {
          const id = this.getIdFromUrl(window.location.href);
          if(localStorage.getItem(`isCongrats-${id}`) === "true"){
            this.setRedirectTimer();
            localStorage.removeItem(`isCongrats-${id}`);
          } else {
            this.setState({ upper: true });
          }
        } else if (responseJson.data && responseJson.data.is_send_link === true && responseJson.data.link === null) {
          this.setState({ linkSentSuccess: true, showLink:false, upper:false});
      }
        else if (responseJson.data && responseJson.data.link !== null && responseJson.data.is_send_link === true) {
            this.setState({ showLink: true, });
        }
    }
    if (apiRequestCallId === this.addReviewsId) {
      if(responseJson.data && !responseJson.error){
      this.setState({ isReviewSubmitted: true, isReviewSubmittedPopupOpen: true });

      }
    }
    this.setState({ isLoading: false });
      }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const url = window.location.href;
const id = this.getIdFromUrl(url);
    this.setState({ dealId: id });
    this.FromApprovalDatas(id);
    this.PaymentRecieve(id)
    const auth = localStorage.getItem("authToken");
    if (!auth) {
      localStorage.setItem("redirectUrl", window.location.pathname);
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }

  }

  async componentWillUnmount() {
    const id = this.getIdFromUrl(window.location.href);
    localStorage.removeItem(`isCongrats-${id}`);
  }

  getIdFromUrl(url:any) {
    const urlParts = url.split("/");
    const id = urlParts[urlParts.length - 1];
    return id;
  }


  

  setRedirectTimer() {
    this.setState({ redirect: true },() => {
      this.interval = setInterval(() => {
        if (this.state.countdown > 0) {
          this.setState((prevState) => ({
            countdown: prevState.countdown - 1,
          }));
        } else {
          clearInterval(this.interval);
          this.setState({ redirect: false, upper: true });
        }
      }, 1000) as unknown as number;
    });
  }

  onPriceAmendmentClick = ()=>{
    this.setState((prevState) => ({
      priceAmendments: !prevState.priceAmendments,
    }));
  }

  toggleAmendments = () => {
    this.setState((prevState) => ({
      showAmendments: !prevState.showAmendments,
    }));
  };

  toggleRotation = () => {
    this.setState((prevState) => ({
      isRotated: !prevState.isRotated
    }));
  };


  
  toggleReviewSubmittedPopup = () => {
    this.props.navigation.navigate("LandingPage")
  };
  toggleSendLinkSection = () => {
    this.setState((prevState) => ({
      showSendLinkSection: !prevState.showSendLinkSection,
      upper: false, 
    }));
  };

  handleNegotitiatonResponseData(responseJson: any) {
    if(responseJson.errors && responseJson.errors[0] && responseJson.errors[0].deal.includes("Record with id")){
      toast.success("This deal is cancelled!");
      this.props.navigation.navigate("LandingPage");
      return;
    }
    if (responseJson && !responseJson.errors && responseJson.data?.attributes) {
      const listingDetail = responseJson.data.attributes.listing_detail.data.attributes;
      const id = responseJson.data.attributes.listing_detail.data.id;
      const InitaiateId = Number(id);
      this.setState({
        datas: listingDetail,
        linkId:responseJson.data.attributes.id,
        userDetailss: listingDetail === 'looking_for_something' ?
        responseJson.data.attributes.user_detail.buyer.data.attributes :
        responseJson.data.attributes.user_detail.seller.data.attributes,
        criteria_or_termss:
          responseJson.data.attributes.listing_detail.data.attributes
            .criteria_or_terms,
        amendment_dealss: responseJson.data.attributes.amendment_deals,
        criteria_amendmentss: responseJson.data.attributes.criteria_amendments,
        accountIDss:
          responseJson.data.attributes.listing_detail.data.attributes
            .account_id,
        InitaiateId: InitaiateId,
      });
    }
  }



  toggleReviewPopup = () => {
    this.setState((prevState) => ({
      isReviewPopupOpen: !prevState.isReviewPopupOpen,
    }));
  };

  toggleAmendmentsApproval(id:any) {
    this.setState(prevState => ({
      showAmendmentsApproval: {
        ...prevState.showAmendmentsApproval,
        [id]: !prevState.showAmendmentsApproval[id], // Toggle visibility for the specific item
      },
    }));
  }
  reviewsAdd = () =>{
    this.setState({reviewButton:true})
    this.addReviews()

  }

  modalOpen = () => {
    this.setState({ isModalOpen: true });
  }

  modalClose = () => {
    this.setState({ isModalOpen: false });
  }

  onProductLinkChange = async (value: string) => {
    this.setState({ linktext: value });
    let schema = Yup.object().shape({
      name: Yup.string().required('Link is required!').url('Invalid URL format!')
    });
    try {
      await schema.validate({ name: value });
      this.setState({ errorMsg: "" });
    } catch (err) {
      this.setState({ errorMsg: (err as { message: string }).message });
    }
  }

  onCloseNegotiation = () => {
    const header = JSON.stringify({
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.closeNegotiationDealApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_locallistingads/initiate_deals/${this.state.dealId}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  FromApprovalDatas = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FromApprovalDataApiData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/initiate_deals/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ LoaderFormApproval: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  SendLink = async (id: any, link:any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SendLinkApidata = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/initiate_deals/${id}/send_product_link`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,
      })
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage), 
      JSON.stringify({
        "link": link
      })
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    this.setState({ LoaderFormApproval: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  PaymentRecieve = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.ShowLinkApidata = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/initiate_deals/${id}/view_product_link`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,}
        )
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;};

    addReviews = async () => {
      const token = localStorage.getItem("authToken") as string;
      const requestMessage = new Message
      (getName(MessageEnum.RestAPIRequestMessage));
  
      this.addReviewsId = 
      requestMessage.messageId;
      const payload = 
      {
        "initiate_deal_id": this.state.linkId ,
        "rating": this.state.rating,
        "description": this.state.reviewDescription
      };
  
  
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_locallistingads/deal_feedbacks` );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify({
          "Content-Type": "application/json",
          token: token,}) );
  
          requestMessage.addData
          (getName(MessageEnum.RestAPIRequestBodyMessage), 
          JSON.stringify(payload));
  
  
      requestMessage.addData
      (getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST");
     
      runEngine.sendMessage
      (requestMessage.id, requestMessage);
      return true;};

}

// Customizable Area End
