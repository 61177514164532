// Customizable Area Start
import React from "react";
import {
  StyleSheet,
  SafeAreaView,
} from "react-native";
import { Box, styled, Link, Typography, Grid, Card, CardContent, CircularProgress, Button } from "@material-ui/core";
import { imagePopupSaves, imageEmptyhearts, imageFilledHeart, searchImageSave, SellingSomeImage } from "./assets";
import { RemoveListingPopup } from "../../../components/src/RemoveListingPopup";
import Popup from 'reactjs-popup';
const SavedCards = styled(Card)({
  width: "189px",
  minHeight: "350px",
  cursor: 'pointer',
  padding: "20px",
  marginTop: "25px",
});
const CardImage = styled("img")({
  width: "100%",
  height: "211px",
  objectFit: "cover",
  borderRadius: "5px",
});
const SaveImage = styled("img")({
  width: "29px",
  height: "29px",
});
const BudgetTypographyTextSaved = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: "5px 0",
  color: "#676767"
});
const StyledSpanTextSaved = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const SearchImageListsSave = styled("img")({
  width: "25px",
  height: "25px"
});
const LookingforSaved = styled("h6")({
  width: "150px",
  fontFamily: "Segoe UI Bold",
  fontSize: "12px",
  lineHeight: "20px",
  color: '#676767',
  margin: 0
});
const CommissionTypographySave = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});
const BodyTypographySave = styled("h6")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  color: '#676767',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
const ContactusGridCancelButtons = styled(Button)({
  height: "56px",
  width: "170px",
  borderRedius: "4px",
  background: "#3CAA49",
  color: "#ECF7ED",
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  border: "none",
  marginRight: "15px",
  textTransform: "capitalize",
});
const ContactusGridDeleteButtons = styled(Button)({
  height: "56px",
  width: "170px",
  borderRedius: "4px",
  background: "#FFFFFFE5",
  color: "#3CAA49",
  fontFamily: "Segoe UI Bold",
  fontSize: "18px",
  textTransform: "capitalize",
  border: "1px solid #3CAA49",
});
const MainDivFilterLookings = styled("div")({
  paddingLeft: '36px',
  display: "grid",
  gridGap: '15px',
  justifyContent: 'flex-start',
  gridTemplateColumns: "auto auto auto auto",
  "@media (min-width:1150px) and (max-width: 1399px)": {
    gridTemplateColumns: "auto auto auto",
  },
  "@media (min-width:960px) and (max-width: 1150px)": {
    gridTemplateColumns: "auto auto",
  },
  "@media(max-width:960px)": {
    gridTemplateColumns: "auto auto auto"
  },
  "@media(max-width:767px)": {
    gridTemplateColumns: "auto auto"
  },
  "@media(max-width:450px)": {
    gridTemplateColumns: "auto",
    justifyContent: 'center',
  }
});

type LookingSomeDataItemPhoto = {
  id: number;
  type: string;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  }
}


import SavedlistingLookingController from "./SavedListingLookingController";
import { ListingEmptyLooking } from "../../../components/src/ListingEmptyLooking.web";
export default class SavedListingLooking extends SavedlistingLookingController {
  BodyTypographySave(item:any) {
    return (
      <>
        <BodyTypographySave>{item}</BodyTypographySave>
      </>
    )
  }
  renderBox() {
    return (
      <>
        <Box style={styleD.lookingHeaders as React.CSSProperties}>
          {this.state.LookingSomeDatas && this.state.LookingSomeDatas.length > 0 && (
            <Typography style={styleD.lookingTextss as React.CSSProperties}>
              {this.state.LookingSomeDatas.length} Products
            </Typography>
          )}
        </Box>
      </>
    )
  }
  renderSavedCard = (item: any) => {
    const authToken = localStorage.getItem("authToken");
    return (

      <SavedCards key={item.id} data-test-id="viewAll" onClick={() => this.viewDataOnHomes(item.id)}>
        <div style={{ display: "flex", alignItems: 'center', gap: 10, marginBottom: '15px' }}>
          <SearchImageListsSave src={item.attributes?.listing_type === "looking_for_something" ? searchImageSave : SellingSomeImage} />
          <LookingforSaved>{item.attributes?.listing_type === "looking_for_something" ? "Looking for something" : "Selling something"}</LookingforSaved>
        </div>
        <CardImage src={item.attributes?.photo?.[0]} alt="Card Image" />
        <CardContent style={{ padding: '0px' }}>
          {this.BodyTypographySave(item.attributes?.title)}
          <BudgetTypographyTextSaved>
            Budget <StyledSpanTextSaved>£ {item.attributes?.budget}</StyledSpanTextSaved>
          </BudgetTypographyTextSaved>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <CommissionTypographySave>
              Reward <StyledSpanTextSaved> £ {item.attributes?.commission}</StyledSpanTextSaved>
            </CommissionTypographySave>
            <SaveImage
              data-test-id='viewAll2'
              src={this.state.savedListingsData?.includes(item.id) ? imageFilledHeart : imageEmptyhearts}
              onClick={(e) => {
                e.stopPropagation();
                authToken ?
                this.state.savedListingsData?.includes(item.id) ?
                  this.popupSaveds(item.id) :
                  this.savedlistApiCallLooking(item.id)
                  :  this.savelistLooking();
              }}
              alt="Card Image"
            />
          </div>
        </CardContent>
      </SavedCards>
    );
  }
  AddList(){
    return(
      <div >
      <p style={styleD.popuptexts as React.CSSProperties} >Added to Saved Listing!</p>
    </div>
    )
  }

  render() {

    return (
      <SafeAreaView style={styles.mainContainer}>
        <Box width="100%" style={{ padding: 0 }}>
          {this.state.LoaderSavedLooking ?
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ color: '#4fa94d' }} />
              </Box>
            </div> : <>
              {this.renderBox()}
              <Box>
                <div>
                  {this.state.LookingSomeDatas?.length === 0 ? (
                    <div style={{ margin: "0 auto" }}>
                      <ListingEmptyLooking />
                    </div>
                  ) : (
                    <MainDivFilterLookings>
                      {this.state.LookingSomeDatas?.map((item: LookingSomeDataItemPhoto) => this.renderSavedCard(item))}
                    </MainDivFilterLookings>
                  )}
                </div>

                <div style={styleD.modalStyles as React.CSSProperties}>
                  <div>
                    <Popup
                      open={this.state.savedPopup}
                      test-id="popup"
                      closeOnDocumentClick onClose={this.saveClosePopups}
                      className="popup"
                      contentStyle={styleD.contentSave}
                      overlayStyle={styleD.overlayses}
                    >

                      <div>
                        <div style={styleD.popupIMGMains as React.CSSProperties} >
                          <img src={imagePopupSaves} alt="popup" />
                        </div>
                        {this.AddList}
                        <div>
                          <p style={styleD.popuptextss as React.CSSProperties} >Go to
                            <Link style={styleD.signlinks as React.CSSProperties}
                              onClick={this.handleSaveListings}
                            >
                              Saved Listings
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
              </Box>
              <Popup
                open={this.state.successRemoveSaving}
                test-id="popup"
                closeOnDocumentClick onClose={this.successpopupSaved}
                className="popup"
                contentStyle={styleD.contentsPasswords}
                overlayStyle={styleD.overlaysBtn}
              >
                <div style={styleD.deletePopupDeletesHomess as React.CSSProperties}>
                  <RemoveListingPopup navigation={this.props.navigation.navigate} />
                  <Box >
                    <ContactusGridCancelButtons onClick={this.closePopupPassword} data-testid="save-navigate">Cancel</ContactusGridCancelButtons>
                    <ContactusGridDeleteButtons data-testid="save-navigate1"
                      onClick={this.deleteSavelistingCallMainLooking.bind(this, this.state.deleteItemIds)}
                    >Remove</ContactusGridDeleteButtons >
                  </Box>
                </div>
              </Popup>
            </>
          }
        </Box>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%"
  },
});
const styleD = {
  containerss: {
    padding: "0px 56px"
  },
  homeText: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    maxWidth: '1296px',
    margin: 'auto'
  },
  homeTextbar: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    cursor: "pointer"
  },
  imageHomeCrosses: {
    width: "7.41px",
    height: "12px",
    margin: "0px"
  },
  lookingText: {
    color: "#3CAA49",
    fontFamily: "Segoe UI",
    fontSize: "24px",
    cursor: "pointer"
  },
  lookingMains: {
    margin: "20px 0px"
  },
  lookingHeaders: {
  },
  lookingTextss: {
    fontFamily: "Segoe UI",
    fontSize: "24px",
    lineHeight: 1,
    color: "#3CAA49",
    marginLeft: '30px'
  },
  mapFNs: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  linkViews: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    cursor: "pointer"
  },
  modalStyles: {
    fontSize: '12px',
  },
  popuptexts: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  overlayses: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentSave: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '400px'
  },
  popupIMGs: {
    display: "flex",
    margin: "0 auto",
  },
  popuptextsss: {
    fontSize: '18px',
    fontFamily: "Segoe UI",
    color: '#676767',
    width: '400px',
    LineHeight: '26px'
  },
  popupIMGMains: {
    margin: "15px 0px 15px 0px",
    display: "flex",
    justifyContent: "center"
  },
  deletePopupDeletesHomess: {
    marginTop: "85px",
    paddingLeft: '52px'
  },
  signlinks: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  popuptextss: {
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: "#181818"
  },
  arrowClose: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  arrorMain: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px'
  },
  arrorMainJewellery: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
  },
  arrorMainClothing: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
  },
  arrorMainInterior: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
  },
  arrorMainServices: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginTop: '15px',
    borderBottom: '1px solid lightgray',
    paddingBottom: '20px',
  },
  styleBudget: {
    display: 'columns',
    borderBottom: '1px solid lightgray',
    paddingBottom: '20px'
  },
  styleCommission: {
    display: 'columns',
    borderBottom: '1px solid lightgray',
    paddingBottom: '20px'
  },
  contentsDeletes: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '527px',
    borderRadius: '8px',
    height: '322px'
  },
  overlaysDeletes: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  deletePopupDeletes: {
    marginTop: "85px",
    paddingLeft: '52px'
  },
  popuptextsDeletes: {
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '32px',
    margin: '0',
  },
  plateformExploreButton: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "15px",
    textTransform: "capitalize",
  },
  plateformListingButtondeleteAccount: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  overlaysBtn: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentsPasswords: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '30.5px 43.5px 30.5px 43.5px',
    width: '500px',
    borderRadius: '8px',
    height: '350px'
  },
  popupIMGMainssBtn: {
    width: '232px',
    heigth: '232px',
    margin: 'auto',
  },
  passwordChanges: {
    fontSize: '30px',
    marginTop: "15px",
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#000000",
    LineHeigth: '32px',
    margin: 'auto',
  },
  imgCrosses: {
    position: 'absolute',
    top: 38,
    margin: 'auto',
    right: 36,
    cursor: 'pointer',
  },
}
// Customizable Area End
