export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const settingImages = require("../assets/settingImages.png");
export const DeleteIcon = require("../assets/DeleteIcon.jpeg");
export const loginImg = require("../assets/loginImg.jpeg");
export const image_trash = require("../assets/image_trash.jpeg");
export const imgPopup = require("../assets/popup.jpeg");
export const profile = require("../assets/profile.jpeg");
export const image_Cross = require("../assets/group_Cross.png");
export const image_PopupChair = require("../assets/PopupChair.png");
export const imageSettingss = require("../assets/imageSettings.png");
export const PayPal = require("../assets/Paypal.png");