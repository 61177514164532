// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.eHolder = "Text";
exports.labelTitleText = "PaypalIntegration";
exports.labelBodyText = "PaypalIntegration Body";
exports.labelBodyTexts = "PaypalIntegration Bodys";
exports.labelBodyTexts1 = "PaypalIntegration Bodys1";
exports.labelBodyTexts2 = "PaypalIntegration Bodys2";

exports.btnExampleTitle = "CLICK ME";
exports.labelBodyTextsDisabled = "PaypalIntegration Text Area";
exports.itle = "CLICK";
exports.ytle = "CLICK";
exports.webstateincludesURL="https://datingappdemo-260453-ruby.b260453.dev.eastus.az.svc.builder.cafe/bx_block_paypal/capture";
exports.captureApiURL="https://datingappdemo-260453-ruby.b260453.dev.eastus.az.svc.builder.cafe/bx_block_paypal/capture"
exports.webstateincludesURLstage="https://datingappdemo-260453-ruby.b260453.stage.eastus.az.svc.builder.ai/bx_block_paypal/capture";
exports.captureApiURLstage="https://datingappdemo-260453-ruby.b260453.stage.eastus.az.svc.builder.ai//bx_block_paypal/capture"

// Customizable Area End
