import { Button } from '@material-ui/core';
import React from 'react';

export function CustomButton(props: any) {
    return (
        <Button onClick={props.onClick}
            className={props.className}
            style={props.style}>
            {props.label}
        </Button>
    );
}
