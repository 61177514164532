// Customizable Area Start
import React from "react";
import { Box, Button, styled } from "@material-ui/core";
import { imageLocations, settingImage, LogoutImage, profiles } from "./assets";
import { AppHeader } from "../../../components/src/AppHeader.web";
import AppMylisting from "../../../components/src/AppMylisting.web";
import Popup from 'reactjs-popup';
import UserProfileBasicWebController, {
  Props
} from "./UserProfileBasicController";

export default class UserProfileBasicBlockWeb extends UserProfileBasicWebController {
  constructor(props: Props) {
    super(props);
  }

  renderDatss(){
    return (
      <LogoutText>Logout</LogoutText>
    )
  }
  SetttingsFlow(){
    return (
      <LogoText>Settings</LogoText>
    )
  }
  LogoutData(){
    return(
      <LogoutLogoImage src={LogoutImage} alt="Logout Logo" />
    )
  }

  logoutDialog = () => {
    return (
      <Popup
        open={this.state.isOpen}
        closeOnDocumentClick
        onClose={this.onClose}
        contentStyle={webStyle.contentStyle}
        overlayStyle={webStyle.overlayStyle}
      >
        <Wrapper>
          <p className="title">Logging out</p>
          <div>
            <p className="description">Are you sure you want to leave?</p>
          </div>
          <Box marginTop="40px">
            <Button className="cancel-btn" data-test-id="cancel-btn" onClick={this.onClose}>Cancel</Button>
            <Button className="logout-btn" data-test-id="logout-btn" onClick={this.handleButtonClick}>Log-out</Button>
          </Box>
        </Wrapper>
      </Popup>
    );
  }

  render() {
    const userPhoto = localStorage.getItem("profilePhoto")
    return (

      <Box width="100%">
        <AppHeader navigation={this.props.navigation.navigate}/>
        <FormGrid>
          <CustomBox>
            <IconsProfile>

              <ProfileImg
                //@ts-ignore
                src={userPhoto == null ? profiles: userPhoto}
                alt="Logo Image"
              />
              <CustomH6>{this.state.useProfile?.attributes?.full_name}</CustomH6>
              <CustomHeading>@{this.state.useProfile?.attributes?.user_name}</CustomHeading>
              <LocationBox>
                <LocationImgs
                  src={imageLocations}
                  alt="Logo" />
                <CustomH1>{this.state.city == null ? "___": this.state.city},{"  "}{this.state.country == null ? "___" : this.state.country}</CustomH1>
              </LocationBox>
            </IconsProfile>
            <div style={{ position: 'absolute', bottom: 0, left: "50%", transform: 'translateX(-50%)' }}>

            <a data-test-id='SettingButton'  style={{ textDecoration: 'none', color: 'inherit' }} href="/Settings2" onClick={(event) => {
              event.preventDefault();
          this.handleButtonClickSetting()
      }}>
              <FlexDiv  >
                <LogoImage src={settingImage} alt="Logo" />
                {this.SetttingsFlow()}
              </FlexDiv></a>
              <LogoutDiv data-test-id='DeleteButton' onClick={this.onOpen}>
               {this.LogoutData()}
               {this.renderDatss()}
              </LogoutDiv>
            </div>
          </CustomBox>
          <CustomContent flex="1">
            <AppMylisting {...this.props} value={this.state.tabSelectValue} setValue={(e:number)=>this.handleTabSelect(e)}/>
          </CustomContent>
        </FormGrid>
        {this.logoutDialog()}
      </Box>
    );
  }
}

const Wrapper = styled(Box)({
  maxWidth: "290px", 
  margin: "auto",
  "& .description": {
    margin: "0px",
    marginTop: "16px",
    fontSize: '18px',
    fontFamily: "Segoe UI",
    color: '#676767',
    LineHeight: '26px',
  },
  "& .title": {
    margin: "0px",
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
    color: '#181818',
    lineHeight: '32px'
  },
  "& .cancel-btn": {
    height: "56px",
    width: "135px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  "& .logout-btn": {
    height: "56px",
    width: "135px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
});

const webStyle = {
  contentStyle: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '290px',
    padding: '80px 120px 80px 120px',
    width: '100%',
    borderRadius: '8px',
  },
  overlayStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
};

const FormGrid = styled(Box)({
  display: 'flex',
});
const CustomBox = styled(Box)({
  width: '336px',

  borderRadius: '0px 15px 0px 0px',
  backgroundColor: '#E0DAD3',
  position: 'sticky',
  height: 'calc(100vh - 80px)',
  top: '80px',
  '@media(max-width: 1150px)': {
    display: 'none'
  },
  '@media(min-width: 1150px)': {
    minWidth: '336px',
  }
})
const CustomContent = styled(Box)({
  display: 'columns',
  Width: 'calc(100% - 150px)',
  minHeight: '100vh',
  borderRadius: '0px, 0px, 0px, 32px',
})
const IconsProfile = styled(Box)({
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 50,

  '@media (max-width: 767px)': {
    flexDirection: 'column',
  },
});
const LogoImage = styled("img")({
  width: "28px",
  height: "28px",
});

const LogoText = styled("h6")({
  color: "#000000",
  fontFamily: "Segoe UI",
  fontSize: "16px",
  alignItems: "center",
  lineHeight: "26.6px",
  margin: 0,
});
const LogoutLogoImage = styled("img")({
  width: "28px",
  height: "28px",
});

const LogoutText = styled("h6")({
  color: "#000000",
  fontFamily: "Segoe UI",
  fontSize: "16px",
  alignItems: "center",
  lineHeight: "26.6px",
  margin: "0",
});

const ProfileImg = styled('img')({
  width: 134,
  height: 134,
  borderRadius: 135,
});
const CustomH6 = styled("h6")({
  color: "#000000",
  fontFamily: "Segoe UI Bold",
  fontSize: "24px",
  alignItems: 'center',
  marginBottom: "5px",
  marginTop: "26px",
  lineHeight: 1
});
const CustomHeading = styled("h6")({
  color: "#676767",
  fontFamily: "Segoe UI",
  fontSize: "16px",
  alignItems: 'center',
  margin: "0",
  lineHeight: 1,
  marginBottom: "8px"
});
const CustomH1 = styled("h1")({
  color: "#000000",
  fontFamily: "Segoe UI",
  fontSize: "16px",
  alignItems: 'center',
  display:'flex',
  margin: 0,
  lineHeight: 1
});
const LocationBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: "5px",
  textAlign: "center",
  justifyContent: "center"
});
const LocationImgs = styled('img')({
  width: '21.1px',
  height: '21px',
});
const FlexDiv = styled(Box)({
  display: "flex",
  alignItems: "center",
  position: 'relative',
  bottom: 0,
  justifyContent: 'center',
  gap: "10px",
  cursor: 'pointer'
});
const LogoutDiv = styled(Box)({
  marginTop: 10,
  display: "flex",
  alignItems: "center",
  position: 'relative',
  bottom: 0,
  justifyContent: 'center',
  gap: "10px",
  marginBottom: "20px",
  cursor: "pointer",
});

// Customizable Area End
