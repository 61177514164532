import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface ItemAttributes {
  listing_type: string;
  account_id: number;
  title: string;
  budget: number;
  commission: number;
  location: string;
  description: string;
  photo: string;
  preferences: string;
  category: string;
  sub_category: string;
  approval_criterion: string[];
  terms_of_purchase: string[];
  // Add other properties if needed
}

interface Item {
  id: string; // or whatever type your id is
  attributes: ItemAttributes;
  title:string;
  data:string;
  type:string
}

interface ProfileAttributes {
  full_name: string;
  email: string;
  // Add other properties if needed
}
interface ApiResponse<T> {
  data: [];
  errors?: string[];
  message:{}
}
interface MyData {
  savedDataAdd:string
}
interface ProfileData {
  attributes: ProfileAttributes;
  // Add other properties if needed
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface CustomerReview {
  id: string | number;
  type: string;
  attributes: {
    id: number | string;
    customer_name: string;
    description: string | null;
    image: string | null;
  }
}

interface S {
  // Customizable Area Start
  showAllDataShoppingAdd: boolean;
  showAllSellingSomethingDataAdd: boolean;
  showAllSavedListingDataAdd: boolean;
  LookingSomeDataAdd: Item[];
  someListingDataAdd: Item[];
  savedListingDataAdd: Item[];
  savedListingsData: string[]; // Assuming it's an array of strings
  deleteItemIdsAdd: number;
  savedDataAdd: object; // You might want to define a specific type for savedDataAdd
  removeSavedListAdd: boolean;
  savedPopupLandingAdd: boolean;
  savedListingsDataAdd: any; // Assuming it's an array of strings
  savedPopupsAdd: boolean;
  profileTextAdd: ProfileData | null; // Assuming it's an object of type ProfileData
  currentIndexAdd: number;
  LoaderListingAdd: boolean;
  lookingViewAllMain: boolean,
  sellingViewAllMain: boolean,
  ListingEmptysAdd: string;
  reviewLoading: boolean;
  customerReview: CustomerReview[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiLookingSomeItemCallIdAdd: string = "";
  apiSavedListingCallIdAdd: string = "";
  apiImageResponseAdd: string = "";
  apiSave_ListingCallIdssMainAdd: string = "";
  apiCallLookingIdsAdd: string = "";
  testimonialApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      showAllDataShoppingAdd: false,
      showAllSellingSomethingDataAdd: false,
      showAllSavedListingDataAdd: false,
      LookingSomeDataAdd: [],
      someListingDataAdd: [],
      savedListingDataAdd: [],
      savedListingsData: [],
      deleteItemIdsAdd: 0,
      profileTextAdd: null,
      savedListingsDataAdd:[],
      savedDataAdd: {},
      LoaderListingAdd: true,
      lookingViewAllMain: false,
      sellingViewAllMain: false,
      removeSavedListAdd:false,
      savedPopupsAdd: false,
      ListingEmptysAdd: "",
      savedPopupLandingAdd: false,
      currentIndexAdd: 0,
      reviewLoading: false,
      customerReview: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonAdd = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallIdAdd = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(apiRequestCallIdAdd === this.testimonialApiCallId){
        if(responseJsonAdd.data && !responseJsonAdd.errors){
          this.setState({ customerReview: responseJsonAdd.data });
        }
        this.setState({ reviewLoading: false });
      } else if (apiRequestCallIdAdd === this.apiLookingSomeItemCallIdAdd) {
        this.setState({LoaderListingAdd: false})
        this.setState({
          LookingSomeDataAdd: responseJsonAdd.data,
          someListingDataAdd: responseJsonAdd.data,
        })
      }
      if (apiRequestCallIdAdd === this.apiSavedListingCallIdAdd) {
        this.handleSaveListingResponseTitleAdd(responseJsonAdd);
      }
      if (apiRequestCallIdAdd === this.apiSave_ListingCallIdssMainAdd) {
        this.handleSaveListingResponseAdd(responseJsonAdd);
      }
      else if (apiRequestCallIdAdd === this.apiCallLookingIdsAdd) {
        this.handleresponseJsonAdd(responseJsonAdd)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    const userToken = localStorage.getItem("authToken");
    if(userToken){
    this.props.navigation.navigate("LandingPage")
    }
    this.getJobsAppliedExecutiveDatasAdd()
    this.getsavedListingDatsAdd()
    this.userProfileCallAdd()
    this.ShowImagesAdd()
    this.getTestimonial();
  }
  handleLookingAdd = () => {
    this.setState({ lookingViewAllMain: true }, () => {
      this.props.navigation.navigate("FilterAllCategory", "?sellingViewAll=true")
    });
  }
  handleresponseJsonAdd = (responseJsonAdd: any) => {
  if (responseJsonAdd && !responseJsonAdd.errors) {
    this.setState({
      profileTextAdd: responseJsonAdd.data
    });
  }
  }
  AllFilterLandingPagesMains = (id:any) => {
    this.props.navigation.navigate("FilterAllCategory",{ id: id })
  }

  AllFilterLandingPagesMains1 = () => {
    this.props.navigation.navigate("FilterAllCategory")
  }

  viewDataOnHomeAdd(id: number) {
    this.props.navigation.navigate('ViewListing', { id: id })
  }
  viewDataOnHomeAddWishlist() {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  handleListingAdd = () => {
    const userToken = localStorage.getItem("authToken");
    if(!userToken){
    localStorage.setItem("LookingForSomething", "CreateListing");
    this.props.navigation.navigate("EmailAccountLoginBlock")
    }
}
handleListingExploreClick = () => {
  this.props.navigation.navigate("FilterAllCategory")
}

  LookingNavigationAdd = () => {
    const userToken = localStorage.getItem("authToken");
    if(!userToken){
    localStorage.setItem("LookingForSomething", "LookingSomething");
    this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  }
  handleSaveListingResponseAdd = (responseJson: ApiResponse<MyData>): void => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        savedDataAdd: responseJson.message,
        savedPopupLandingAdd: true,
        savedPopupsAdd: true
      })
      this.getsavedListingDatsAdd()
    }
  }
  popupSavedsAdd = (id: number) => {
    this.setState({ removeSavedListAdd: true, deleteItemIdsAdd: id, })
  }
  saveClosePopupssAdd = () => {
    this.setState({ savedPopupsAdd: false })
  }
  saveClosePopupsAdd = () => {
    this.setState({ savedPopupLandingAdd: false })
  }
  navigateSavedSomethingLandingAdd = () => {
    this.setState({ removeSavedListAdd: false })
  }
  SellingNavigationAdd = () => {
    const userToken = localStorage.getItem("authToken");
    if(!userToken){
    localStorage.setItem("LookingForSomething", "SellingSomething");
    this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  }
  handleSaveListingResponseTitleAdd(responseJson:any ) {
    const titles = responseJson.data?.map((item:any)=>item.attributes.listing?.data.id)
      this.setState({
        savedListingDataAdd: responseJson.data,
        savedListingsDataAdd: titles,
      })
  }
  sellingSomethings = () => {
    this.setState({ sellingViewAllMain: true }, () => {
      this.props.navigation.navigate("FilterAllCategory", "?sellingViewAll=true" )
  });
  }
  	
handleLookingAdds = () => {
  this.setState({ lookingViewAllMain: true }, () => {
    this.props.navigation.navigate("FilterAllCategory", "?lookingViewAll=true")
  });
}
  handleFullNameMain= () => {
       const fullName = this.state.profileTextAdd?.attributes?.full_name;
    const email = this.state.profileTextAdd?.attributes?.email;
    if (fullName) {
      localStorage.setItem("fullName", fullName);
    }
    if (email) {
      localStorage.setItem("email", email);
    }
  }
  siginNavigationAdd = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  registerNavigationAdd = () => {
    this.props.navigation.navigate("EmailAccountRegistration")
  }

  getTestimonial = () => {
    this.setState({ reviewLoading: true });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.testimonialApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"/bx_block_landingpage2/testimonials");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobsAppliedExecutiveDatasAdd = () => {
    const tokenAdd = localStorage.getItem("authToken") as string;
    const requestMessageAdd = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLookingSomeItemCallIdAdd = requestMessageAdd.messageId

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/local_listing_ads"
    );

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageAdd.id, requestMessageAdd);
    return true;
  };
  savedlistApiCallMainAdd = async (id: number) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSave_ListingCallIdssMainAdd = requestMessage.messageId;
    let Body = {
      "data": {
        "local_listing_ad_id": id
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getsavedListingDatsAdd = () => {
    const tokenAdd = localStorage.getItem("authToken") as string;
    const requestMessageAdd = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSavedListingCallIdAdd = requestMessageAdd.messageId

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/wishlist_items"
    );

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: tokenAdd
      })
    );

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageAdd.id, requestMessageAdd);
    return true;
  }
  userProfileCallAdd = async () => {
    const tokenAdd = localStorage.getItem("authToken") as string;
    const requestMessageAdd = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallLookingIdsAdd = requestMessageAdd.messageId

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/show_user_profile"
    );

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: tokenAdd
      })
    );

    requestMessageAdd.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageAdd.id, requestMessageAdd);
    return true;
  }
  ShowImagesAdd = async () => {
    const tokenAdd = localStorage.getItem("authToken") as string;
    const requestMessagesAdd = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiImageResponseAdd = requestMessagesAdd.messageId
    requestMessagesAdd.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/show_user_profile"
    );

    requestMessagesAdd.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "token": tokenAdd,
      }));
    requestMessagesAdd.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessagesAdd.id, requestMessagesAdd);
    return true;
  };
  // Customizable Area End
}
