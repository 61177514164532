// Customizable Area Start
import React from "react";
import { Box, CircularProgress, styled } from "@material-ui/core";
import OtherListingController from "./OtherListingController";

const SellingTextDataOther = styled("p")({
    fontFamily: "Segoe UI Bold",
    fontSize: "24px",
    lineHeight: "24px",
    color: "#181818",
    position: "relative",
    margin: "16px",
    marginTop: "0px",
    paddingTop: "16px",
    "@media(max-width:450px)": {
        fontSize: "16px",
        lineHeight: "16px"
    },
    marginBottom: "40px"
});
const GreenLineDataAOther = styled("div")({
    position: "absolute",
    bottom: -10,
    left: 0,
    width: 120,
    borderBottom: "2px solid #3CAA49"
});
const MainDivDataOther = styled("div")({
    paddingLeft: '25px',
    display: "grid",
    gridGap: '15px',
    justifyContent: 'flex-start',
    gridTemplateColumns: "auto auto auto auto",
    "@media(min-width:1150px) and (max-width: 1260px)": {
        gridTemplateColumns: "auto auto auto"

    },
    "@media(max-width:960px)": {
        gridTemplateColumns: "auto auto auto",
    },
    "@media(max-width:767px)": {
        gridTemplateColumns: "auto auto"
    },
    "@media(max-width:450px)": {
        gridTemplateColumns: "auto",
        justifyContent: 'center',
    }
});
const TextLookingDataAOther = styled("div")({
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: "300px",
    "@media(max-width:450px)": {
        padding: 5
    }
});

const CardDataAOther = styled(Box)({
    width: "185px",
    maxWidth: "185px",
    borderRadius: "5px",
    padding: "20px 20.5px",
    justifyContent: 'space-between',
    backgroundColor: "#FFFFFF",
    flexWrap: 'wrap',
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: 'pointer',
});


const CardImageDataAImgs = styled("img")({
    width: "180px",
    height: "211px",
    objectFit: "contain",
    borderRadius: "5px",
    "@media(max-width:1150px)": {
        width: '100%',
    }
});

const BodyTypographyDataTexts = styled("h6")({
    width: "200px",
    marginTop: 10,
    fontFamily: "Segoe UI Bold",
    fontSize: "16px",
    lineHeight: "20px",
    margin: 0,
    maxHeight: '44px',
    minHeight: '44px',
    textOverflow: 'ellipsis',
    overFlow: 'hidden'
});
const BudgetTypographyDataTexts = styled("p")({
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "22px",
    margin: 0,
    color: "#676767"
});
const StyledSpanDataSpan = styled("span")({
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "14px",
    lineHeight: "22px",
    margin: 0
});
const CommissionTypographyDataTextss = styled("p")({
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#676767",
    margin: 0
});
export default class OtherListing extends OtherListingController {
    renderEmptyTextDataMessage(message: any) {
        return <TextLookingDataAOther>{message}</TextLookingDataAOther>;
    }


    renderLookingForSomethingCardOther(item: any) {
        return (
            <CardDataAOther key={item.id} data-test-id="viewDataS" onClick={() => this.viewDataSId(item.id)}>
                <CardImageDataAImgs src={item.attributes.photo[0]?.url} alt="Card Image" />
                <BodyTypographyDataTexts>{item.attributes.title}</BodyTypographyDataTexts>
                <BudgetTypographyDataTexts>Budget <StyledSpanDataSpan>£ {item.attributes.budget}</StyledSpanDataSpan></BudgetTypographyDataTexts>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CommissionTypographyDataTextss>Reward <StyledSpanDataSpan> £ {item.attributes.commission}</StyledSpanDataSpan></CommissionTypographyDataTextss>

                </div>
            </CardDataAOther>
        );
    }

    renderSellingForSomethingCardOther(item: any) {
        return (
            <CardDataAOther key={item.id} data-test-id="viewDataS1" onClick={() => this.viewDataSId(item.id)}>
                <CardImageDataAImgs src={item.attributes.photo[0]?.url} alt="Card Image" />
                <BodyTypographyDataTexts>{item.attributes.title}</BodyTypographyDataTexts>
                <BudgetTypographyDataTexts>Budget <StyledSpanDataSpan>£ {item.attributes.budget}</StyledSpanDataSpan></BudgetTypographyDataTexts>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CommissionTypographyDataTextss>Reward <StyledSpanDataSpan> £ {item.attributes.commission}</StyledSpanDataSpan></CommissionTypographyDataTextss>
                </div>
            </CardDataAOther>
        );
    }
    renderData(){
        return (
            <hr style={{ width: "988px", height: "0.8px", color: '#E5E7EB', backgroundColor: "#E5E7EB", border: "none", margin: "20px 0px 10px 20px" }} />
        )
    }
    SellingData(){
        return (
            <SellingTextDataOther>Looking for something<GreenLineDataAOther /></SellingTextDataOther>
        )
    }
    LookingData(){
        return (
            <SellingTextDataOther>Selling something<GreenLineDataAOther /></SellingTextDataOther>
        )
    }
    render() {
        const { useDetailsDataS, useDetailsDataSelling } = this.state;
        if (this.state.loading) {
            return (
              <CustomBox>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress style={{ color: '#4fa94d' }} />
                </Box>
              </CustomBox>
            );
        }
        return (
            <>
                <Box width="100%">
                    {
                    
                            <Wrapper>
                                <div>
                                    {this.SellingData()}
                                    {useDetailsDataS?.length === 0
                                        ? this.renderEmptyTextDataMessage("Looking for something listing is empty")
                                        : (
                                            <MainDivDataOther>
                                                {useDetailsDataS?.map((item) =>
                                                    item.attributes.listing_type === "looking_for_something" ? this.renderLookingForSomethingCardOther(item) : null)}
                                            </MainDivDataOther>
                                        )}
                                  {this.renderData()}
                                   {this.LookingData()}
                                    {useDetailsDataSelling?.length === 0
                                        ? this.renderEmptyTextDataMessage("Selling for something listing is empty")
                                        : (
                                            <MainDivDataOther>
                                                {useDetailsDataSelling?.map((item) =>
                                                    item.attributes.listing_type === "selling_for_something" ? this.renderSellingForSomethingCardOther(item) : null)}
                                            </MainDivDataOther>
                                        )}
                                </div>
                            </Wrapper>
                      
                    }

                </Box>
            </>
        );
    }
}

const CustomBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: "rgb(247, 245, 245)"
});

const Wrapper = styled(Box)({
    width: "100%",
    padding: 0,
    height: "calc(100% - 50px)",
    backgroundColor: "rgb(247, 245, 245)"
});
// Customizable Area End