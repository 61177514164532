import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  // Customizable Area Start
}

export interface S {
  // Customizable Area Start
  otpAuthToken: string;
  data: any[]
  signupInformation: any;
  signupInformationError: any
  activeGridId: number;
  emailExists: boolean;
  checkboxChecked: boolean;
  isSnackbarOpen: boolean;
  checkboxError: boolean;
  snackbarOpen: boolean;
  termsData: unknown[];
  policyData: unknown[];
  isPrivacyPolicyModalOpen: boolean;
  emailErrorMsg: string;
  myListing: any[];
  ListingEmptys: any;
  someListing: any[];
  LoaderListing: boolean;
  showAllData: boolean;
  showAllSellingSomething: boolean;
  imagePreviews: any,
  categoryApiData: any[],
  subCategoryApiData: any[],
  criteriaList: any[],
  storeId: any,
  showAllDataFilterSelling: boolean;
  showAllSellingSomethingAll: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class MyListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiMyListingItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      otpAuthToken: "",
      data: [],
      signupInformation: [],
      signupInformationError: [],
      activeGridId: 1,
      emailExists: false,
      checkboxChecked: false,
      isSnackbarOpen: false,
      checkboxError: false,
      snackbarOpen: false,
      termsData: [],
      policyData: [],
      isPrivacyPolicyModalOpen: false,
      emailErrorMsg: "",
      ListingEmptys: "",
      myListing: [],
      someListing: [],
      LoaderListing: true,
      showAllData: false,
      showAllSellingSomething: false,
      imagePreviews: [],
      categoryApiData: [],
      subCategoryApiData: [],
      criteriaList: [''],
      storeId: "",
      showAllDataFilterSelling: false,
      showAllSellingSomethingAll: false,
      
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.apiMyListingItemCallId) {
        this.setState({LoaderListing: false})
        if (responseJson && !responseJson.errors) 
        {
          if(responseJson.message){
            this.setState({ ListingEmptys: responseJson.message})
            }
          else{
            const filteredData = responseJson.data.filter((item:any) => item.attributes.listing_type === "looking_for_something");
            const filteredDataSelling = responseJson.data.filter((item:any) => item.attributes.listing_type !== "looking_for_something");
            this.setState({
                myListing:  filteredData,
                someListing: filteredDataSelling,
                showAllDataFilterSelling:false,
                showAllSellingSomethingAll: false,
            });
        }
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getJobsAppliedExecutiveData()
  }
  saveSellingAll() {
    this.setState({ showAllDataFilterSelling: true })
  }
  myListingAll() {
    this.setState({ showAllSellingSomethingAll: true })
  }
  navigateComponent = (id:number) => {
    this.props.navigation.navigate('LookingSomethings',{id:id})
  }
  navigateComponentSomething = (id:number) => {
    this.props.navigation.navigate('SellingSomethings',{id:id})
  }
  navigateComponentListings = () => {
    this.setState({ showAllData: true })
  }
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  viewData(id:number){
    this.props.navigation.navigate('ViewListing' ,{ id: id })
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  getJobsAppliedExecutiveData = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyListingItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/local_listing_ads/get_user_listings"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
