// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleRounded';
import CancelOutlinedIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import PaypalAccountController from "./PaypalAccountController.web";

export default class PaypalAccount extends PaypalAccountController {
  render() {
    return (
      <Wrapper>
        {this.state.loading &&
          <Box className="loader">
            <CircularProgress className="color" />
          </Box>
        }
        {!this.state.loading && this.state.isSuccess &&
          <Box className="center">
              <CheckCircleOutlineIcon className="success" />
            <Box fontFamily="Segoe UI Bold" display="flex" alignItems="center" fontSize={26} fontWeight="bold" color="#2bb32b">
              Success !
            </Box>
            <Box className="msg">Your PayPal Account is now associated with this email.</Box>
            <Button
              className="btn"
              data-test-id="close-btn"
              onClick={() => window.close()}
            >
              <ClearIcon className="clear" />
              Close
            </Button>
          </Box>
        }
        {!this.state.loading && !this.state.isSuccess &&
          <Box className="center">
            <CancelOutlinedIcon className="fail" />
            <Box fontFamily="Segoe UI Bold" display="flex" alignItems="center" fontSize={26} fontWeight="bold" color="#f94b4b">
              Failed !
            </Box>
          <Box className="msg">Oops, something went wrong!</Box>
          <Button 
            className="btn red"
            data-test-id="close-btn"
            onClick={() => window.close()}
          >
            <ClearIcon className="clear" />
            Close
          </Button>
        </Box>
        }
      </Wrapper>
    );
  }
}

const Wrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  background: "rgb(247, 245, 245)",
  "& .center": {
    width: "100%",
    maxWidth: "500px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    background: "#fff",
    padding: "40px 10px",
    margin: "0px 60px",
    borderRadius: "10px",
  },
  "& .loader": {
    "& .color": {
      color: '#4fa94d'
    }
  },
  "& .success": {
    fontFamily: "Segoe UI Bold",
    fontSize: "70px",
    color: "#2bb32b"
  },
  "& .fail": {
    marginRight: "11px",
    fontFamily: "Segoe UI Bold",
    fontSize: "70px",
    color: "#f94b4b"
  },
  "& .msg": {
    fontFamily: "Segoe UI",
    fontSize: "18px",
    textAlign: "center",
    margin: "15px 10px 24px 10px",
  },
  "& .btn": {
    color: '#FFF',
    fontSize: "18px",
    borderRadius: '60px',
    padding: "4px 23px",
    paddingTop: "6px",
    paddingBottom: "8px",
    textTransform: 'none',
    fontFamily: "Segoe UI Bold",
    backgroundColor: "#2bb32b",
    "&:hover": {
      backgroundColor: "#20a520"
    },
     "& .clear": {
      fontSize: "21px",
      paddingRight: "4px",
     }
  },
  "& .red": {
    backgroundColor: "#f94b4b",
    "&:hover": {
      backgroundColor: "#f73f3f !important"
    }
  }
});
// Customizable Area End
