import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  changePassword: boolean;
  ChangesSuccessfully: boolean;
  showConfirmPasswords: boolean,
  showPasswords: boolean;
  confirmPasswords: string;
  passwordErrors: boolean;
  confirmPasswordErrors: boolean;
  passwordMatchErrors: boolean;
  passwords: string;
  reTypePasswords: string;
  containsCapitals: boolean;
  containsLowercases: boolean;
  containsNumbers: boolean;
  hasMinLengths: boolean;
  setData: object;
  confirmPasswordSetting: string;
  confirmPasswordErrorSetting: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSettingFlowCallIds: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      changePassword: false,
      ChangesSuccessfully: false,
      showConfirmPasswords: false,
      passwordErrors: false,
      showPasswords: false,
      confirmPasswords: "",
      confirmPasswordErrors: false,
      passwordMatchErrors: false,
      passwords: "",
      reTypePasswords: "",

      containsCapitals: false,
      containsLowercases: false,
      containsNumbers: false,
      hasMinLengths: false,
      setData: {},
      confirmPasswordErrorSetting: false,
      confirmPasswordSetting: ""
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiSettingFlowCallIds) {
        this.handleSettingflowResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSettingflowResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.message) {
      this.setState({
        changePassword: false,
        setData: responseJson?.message,
        ChangesSuccessfully: true,
      });
    }
  }
  closePopupPassword = () => {
    this.setState({ changePassword: false });
    this.resetStates()
  };
  handleChangeSuccessfully = () => {
  }
  closeChangeSuccessfully = () => {
    this.setState({ ChangesSuccessfully: false });
    localStorage.removeItem("fullName");
    localStorage.removeItem("authToken");
    localStorage.removeItem("profilePhoto")
    sessionStorage.removeItem("auth");
    this.props.navigation.navigate("EmailAccountLoginBlock")
  };
  handleChandePassword = () => {
    this.setState({ changePassword: true })
  }
  handleChangePasswords = () => {
    localStorage.removeItem("fullName");
    localStorage.removeItem("authToken");
    localStorage.removeItem("profilePhoto")
    sessionStorage.removeItem("auth");
    this.props.navigation.navigate("EmailAccountLoginBlock")
  };
  handleConfirmPasswordChangess = (event: { target: { value: string } }) => {
    const confirmPasswords = event.target.value;
    this.setState({ confirmPasswords });
    const { passwords } = this.state;
    const passwordMatchErrors = passwords !== confirmPasswords;

    this.setState({
      confirmPasswords,
      passwordMatchErrors,
    });

    if (confirmPasswords.trim() === "") {
      this.setState({ confirmPasswordErrors: true });
    } else {
      this.setState({ confirmPasswordErrors: false });
    }
    if (this.state.confirmPasswordSetting.trim() === "") {
      this.setState({ confirmPasswordErrorSetting: true });
    } else {
      this.setState({ confirmPasswordErrorSetting: false });
    }
  };
  resetStates = () => {
    this.setState({
      confirmPasswords: "",
      passwords: "",
      containsCapitals: false,
      containsLowercases: false,
      containsNumbers: false,
      hasMinLengths: false,
      showPasswords: false,
      passwordMatchErrors: false,
      showConfirmPasswords: false,
    });
  };

  handlePasswordChangess = (event: any) => {
    const passwords = event.target.value;
    if (passwords.trim() === "") {
      this.setState({ passwordErrors: true });
    } else {
      this.setState({ passwordErrors: false });
    }
    const containsCapitals = /[A-Z]/.test(passwords);
    const containsLowercases = /[a-z]/.test(passwords);
    const containsNumbers = /\d/.test(passwords);
    const hasMinLengths = passwords.length >= 8;

    this.setState({
      passwords,
      containsCapitals,
      containsLowercases,
      containsNumbers,
      hasMinLengths
    });
  };
  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      showPasswords: !prevState.showPasswords
    }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState(prevState => ({
      showConfirmPasswords: !prevState.showConfirmPasswords
    }));
  };
  getButtonBackgroundColor = () => {
    return "#3CAA49";
  };
  handleCreateAccounts = () => {
    const {
      passwords,
      confirmPasswords,
      confirmPasswordSetting,
      containsCapitals,
      containsLowercases,
      containsNumbers,
      hasMinLengths,
      passwordErrors,
    } = this.state;


    if (passwords.trim() === "") {
      this.setState({ passwordErrors: true });
    } else {
      this.setState({ passwordErrors: false });
    }
    if (confirmPasswords.trim() === "") {
      this.setState({ confirmPasswordErrors: true });
    } else {
      this.setState({ confirmPasswordErrors: false });
    }
    if (confirmPasswordSetting.trim() === "") {
      this.setState({ confirmPasswordErrorSetting: true });
    } else {
      this.setState({ confirmPasswordErrorSetting: false });
    }
    if (
      passwords.trim() !== ""
      &&
      confirmPasswords.trim() !== "" && containsCapitals &&containsLowercases && containsNumbers && hasMinLengths
      && !passwordErrors 
    ) {
      this.LookingSettingflow();
    }
  }
  LookingSettingflow = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSettingFlowCallIds = requestMessages.messageId
    const formDatas = new FormData();

    formDatas.append("password", this.state.passwords);
    formDatas.append("password_confirmation", this.state.confirmPasswords);
    formDatas.append("token", token);

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/change_password"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Authorization": token,
      }));
    requestMessages.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formDatas
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
    return true;
  };
  // Customizable Area End
}
