import React from "react";
// Customizable Area Start
import {

  Container,


  InputAdornment,


  TextField,


  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { search } from "./assets";
import Close from "@material-ui/icons/Close";
const theme1 = createTheme({
  palette: {
    primary: {
      main: "#3CAA49",
      contrastText: "#fff"
    }
  }
});

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme1}>
        <Container maxWidth="md">
          {/* Customizable Area Start */}


       <SearchContainer>
         
          <SearchInput
            type="search"
            id="search"
            data-test-id="inputFirstNameSearchText"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                   {this.state.searchParam.length ==0?<img src={search} alt="search"  style={{width:"20px",height:"20px",cursor:"pointer"}}  onClick={this.AllFilterLandingPages} data-test-id="img"/>
                  :<Close data-test-id="closebtn" style={{cursor:"pointer"}} onClick={this.handleShwAll}/>}
                </InputAdornment>
              ),
              style:{maxHeight:"40px"}
            
            }}
            placeholder="Search"
            variant="outlined"
            onChange={this.handleSearch} 
            onKeyPress={this.handleKeyPress}
            value={this.state.searchParam}
          />
         </SearchContainer>

          {/* Customizable End Start */}
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 'none',
  position: 'relative',
});



const SearchInput = styled(TextField)({
  fontSize: '16px',
  color: 'black',
  fontFamily: 'Segoe UI',
  minHeight: '31px',
  width: '370px',
  borderRadius: '5px',
  
  lineHeight: 2,
  '&:focus-visible': {
    outline: 'none',
  },
  '&:hover': {
    outline: 'none', 
  },
  '@media(max-width: 1399px)': {
    width: '300px',
  },
  '@media(max-width: 1000px)': {
    width: 'unset',
  },
  '@media(max-width: 400px)': {
    width: '150px',
  }
});

const customStyles = {
  fontSize: '16px',
  color: 'black',
  fontFamily: 'Segoe UI',
  maxHeight: '44px',
  width: '370px',
  borderRadius: '5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  lineHeight: 2,
  '&:focus': {
    outline: 'none',
  },
  '@media(max-width: 1399px)': {
    width: '300px',
  },
  '@media(max-width: 1000px)': {
    width: 'unset',
  },
  '@media(max-width: 400px)': {
    width: '150px',
  },
};
// Customizable Area End
