// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const SucessIcon = require("../assets/success.png");
export const payment_comp = require("../assets/payment_comp.png");
export const payment_link = require("../assets/payment_link.png");
export const refresh_image = require("../assets/refresh_image.png");
export const deal_finalised = require("../assets/deal_finalised.png");
export const image_Green = require("../assets/imageGreen.png")
export const refresh = require("../assets/refresh.jpeg")
export const Thanks = require("../assets/ThankYou.png")
export const ammend = require("../assets/ammend.png")
export const blob2 = require("../assets/blob2.jpeg")




// Customizable Area End
