// Customizable Area Start
import React from "react";
import { Box, styled, TextField, Typography, Select, Button, Grid, CssBaseline, Link, ThemeProvider, createTheme, CircularProgress } from "@material-ui/core";
import SellingSomethingController from "./SellingSomethingController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { imagePopupSomething, arrowDown, deletesIcon, dummyImg, imageDirectSending, ImageCrossIcon } from "./assets";
import Popup from 'reactjs-popup';
import { AppFooter } from "../../../components/src/AppFooter.web";

const ListingCreation = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "32px",
  lineHeight: "24px",
  color: "#676767",
  alignItems: 'center',
  margin: 0,
});
const InformationText = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#676767",
  alignItems: 'center',
});
const DescribeProductText = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#676767",
  alignItems: 'center',
});
const ListingTitle = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  lineHeight: "22px",
  color: "#676767",
});
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: '10px 8px 10px 8px',
    fontSize: 14,
    gap: '8px',
    borderRadius: '0.5px',
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});
const SelectTextField = styled('select')({
  appearance: 'none',
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
  zIndex: 1111,
  position: 'relative',
  color: "#64748B",
  "& .MuiOutlinedInput-input": {
    padding: '10px 8px 10px 8px',
    fontSize: 14,
    gap: '8px',
    borderRadius: '0.5px',
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});
const placeholderStyle = {
  fontSize: "18px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};
const placeholderStyleLocation = {
  fontSize: "18px",
  color: "#64748B",
  lineHeight: "22px",
  fontFamily: "Segoe UI",
  marginRight: "8px"
};
const BudgetField = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  lineHeight: "22px",
  color: "#676767",
});
const CommissionField = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  lineHeight: "22px",
  color: "#676767",
});
const webStyle = {
  label: {
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#676767",
    marginTop: "10px"
  },
  Select: {
    padding: '9.82px 8px',
    fontSize: "13.13px",
    gap: '8px',
    borderRadius: '5.5px',
    width: '100%',
    border: '1px solid #CBD5E1',
    marginTop: '15px',
    background: 'none',
  },
  descriptionText: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    resize: 'none',
    padding: '10px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    marginTop: '15px',
    width: "100%",
    height: "213px"
  },
  customfileupload: {
    display: 'inlineblock',
    backgroundColor: 'none',
    color: '#3CAA49',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  customfileuploadHeading: {
    color: "#3CAA49",
    fontFamily: "Segoe UI",
    fontSize: "22px",
    lineHeight: "14px",
    paddingTop: "10px",
    paddingBottom: "25px",
    cursor: "pointer",
    width: "auto",
    textAlign: "center",
    alignItems: "center",
    display: "flex"
  },
  labels: {
    color: "#676767CC",
    fontSize: "20px",
    lineHeight: "19px",
    fontFamily: "Segoe UI",
  },
  criteria: {
    color: "#3CAA49",
    fontFamily: "Segoe UI",
    fontSize: "22px",
    lineHeight: "25px",
    paddingTop: "0px",
    paddingBottom: "25px",
    cursor: "pointer",
    width: "auto"
  },
  criteriaText: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    resize: 'none',
    padding: '10px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    marginTop: '15px',
    width: '100%',
    height: "88px"
  },
  deletePopupList: {
    marginTop: "85px"
  },
  popuptextSelling: {
    fontSize: '20px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: '#181818',
    LineHeight: '24px',
  },
  plateformDeleteBtn: {
    height: "60px",
    width: "166px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "none",
    marginRight: "20px",
    LineHeight: '24px',
  },
  plateformSellingBtn: {
    height: "60px",
    width: "120px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  signlinks: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  buttonContainers: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    marginTop: "30px"
  },
  createButtons: {
    backgroundColor: '#3CAA49',
    color: '#FFFFFF',
    width: '180px',
    height: '56px',
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none"
  },
  cancelButtons: {
    backgroundColor: '#FFFFFF',
    border: "1px solid #3CAA49",
    height: '56px',
    color: '#3CAA49',
    fontSize: "14px",
    width: '124px',
    fontFamily: "Segoe UI Bold",
    textTransfer: "none"
  },
  errorMessages: {
    color: "#6767673",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  errorMessage: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  errorMessageSelling: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    paddingLeft: "8px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '566px',
    borderRadius: '8px',
    height: '350px'
  },
  contentDiv: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    padding: "39.5px 171.5px 39.5px 171.5px",
    borderRadius: '8px',
    maxWidth: '580px',
  },
  modalStyle: {
    fontSize: '12px',
  },
  popupIMG: {
    display: "flex",
    margin: "0 auto",
  },
  popupIMGMain: {
    margin: "0px",
    display: "flex",
    justifyContent: "center"
  },
  actionsStyle: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  popuptext: {
    fontSize: '24px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
  },
  popuptexts: {
    fontSize: '32px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  popuptextsGoTO: {
    margin: "5px",
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: '##000000',
    lineHeight: '24px'
  },
  popuptextListingCreatings: {
    fontSize: '28px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#181818",
    LineHeight: "24px"
  },
  closeStyle: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: '2px 5px',
    lineHeight: '20px',
    right: '-10px',
    top: '-10px',
    fontSize: '24px',
    background: '#ffffff',
    borderRadius: '18px',
    border: '1px solid #cfcece',
  }
}


export default class SellingSomething extends SellingSomethingController {
  renderErrorMessage(message: any) {

    return (
      <>
        {<Typography style={webStyle.errorMessage as React.CSSProperties}>
          {message}
        </Typography>}
      </>

    );
  }
  renderErrorMessageSelling(message: any) {
    return (
      <>
        {<Typography style={webStyle.errorMessageSelling as React.CSSProperties}>
          {message}
        </Typography>}
      </>

    );
  }

  renderSubCategoryOptions() {
    return this.state?.subCategoryApiData?.map((item) => {
      return (

        <option key={item.attributes.id} value={item.attributes.name}>
          {item.attributes.name}
        </option>

      )
    })
  }

  renderCategoryOptions() {
    return this.state?.categoryApiData?.map((item) => (
      <option key={item.attributes.value} value={item.attributes.name}>
        {item.attributes.name}
      </option>
    ));
  }
  renderConfirm() {
    return (
      <>
        <p style={webStyle.popuptexts as React.CSSProperties} > Delete Confirmation</p>
        <div>
          <p style={webStyle.popuptextSelling as React.CSSProperties} >Are you sure you want to delete ?</p>
        </div>
      </>
    )
  }
  renderErrorMessageKeepListing() {
    return (
      <Box style={{ textAlign: "center" }}>
        <Button style={webStyle.plateformDeleteBtn as React.CSSProperties} onClick={this.handleKeepListSelling}>Keep Listing</Button>
        <Button style={webStyle.plateformSellingBtn as React.CSSProperties} data-testid="handleListing" onClick={this.handleDeleteSelling}>Delete</Button>
      </Box>

    );
  }
  createListingSomething() {
    return (
      <div style={webStyle.popuptextListingCreatings as React.CSSProperties} >
        Listing Created!
      </div>
    )
  }

  handleFileChange = (e:any) => {
    this.setState({ imagePreviewsError: "" });
    const files = e.target.files;
    const validFiles = Array.from(files).filter((file:any) => file.type.includes('image/'));

    if (validFiles.length > 0) {
      const newSelectedFiles = [...this.state.selectedFiles, ...validFiles];
      this.setState({ selectedFiles: newSelectedFiles });

      const newImagePreviews = [...this.state.imagePreviews, ...validFiles.map((file:any) => URL.createObjectURL(file))];
      this.setState({ imagePreviews: newImagePreviews });
    }
  };



  render() {
    const datacontents = this.props.navigation.getParam('id');
    return (
      <>
        <Box width="100%">
          <AppHeader navigation={this.props.navigation.navigate} />
          <div style={{ alignSelf: 'center', padding: 72 }}>
            {this.state.LoaderSelling && datacontents ?
              (<div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress style={{ color: '#4fa94d' }} />
                </Box>
              </div>) :
              <Grid container>
                <Grid item xs={12}>
                  <ListingCreation>
                    Listing Creation
                  </ListingCreation>
                </Grid>
                <Grid item xs={12}>
                  <InformationText>
                    Enter the required information below to create a listing.
                  </InformationText>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                  <DescribeProductText>
                    Describe the product
                  </DescribeProductText>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={webStyle.label as React.CSSProperties}>
                    Listing Title <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                  </Typography>
                  <CustomTextField
                    variant="outlined"
                    placeholder=""
                    type="text"
                    name="listingTitle"
                    value={this.state.listingTitle}
                    onChange={this.handleInputChange}
                    style={{
                      width: "100%",
                      marginTop: "15px"
                    }}
                    InputProps={{
                      style: placeholderStyle,
                    }}
                  />
                  {this.renderErrorMessage(this.state.listingTitleError)}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.label as React.CSSProperties}>
                      Price <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                    </Typography>
                    <CustomTextField
                      variant="outlined"
                      name="budgetData"
                      placeholder=""
                      type="text"
                      style={{
                        width: "100%",
                        marginTop: "15px"
                      }}
                      InputProps={{ style: placeholderStyle }}
                      value={this.state.budgetData}
                      onChange={this.handleInputChange}
                    />
                    {this.renderErrorMessage(this.state.budgetDataError)}
                    {this.state.budgetDataErrorMessage === "Budget should not be more than £1,000,000" ? 
                    this.renderErrorMessage(this.state.budgetDataErrorMessage): 
                    this.renderErrorMessage(this.state.budgetDatasErrors)}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.label as React.CSSProperties}>
                      Reward <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                    </Typography>
                    <CustomTextField
                      name="commissionData"
                      variant="outlined"
                      placeholder=""
                      type="text"
                      style={{
                        width: "100%",
                        marginTop: "15px"
                      }}
                      InputProps={{ style: placeholderStyle }}
                      value={this.state.commissionData}
                      onChange={this.handleInputChange}
                    />
                    {this.renderErrorMessage(this.state.commissionDataError)}
                    {
                    this.state.commissionDataErrorMessage === "Reward should not be more than £100,000" || "Reward should not be more than the budget"?
                    this.renderErrorMessage(this.state.commissionDataErrorMessage) :
                    this.renderErrorMessage(this.state.commissionDatasErrors)
                  }

                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.label as React.CSSProperties}>
                      Category <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                    </Typography>
                    <Box style={{ position: 'relative' }}>
                      <img src={arrowDown} style={{ position: 'absolute', top: 25, right: 10 }} />
                      <SelectTextField
                        name="selectedCategory"
                        style={webStyle.Select}
                        value={this.state.selectedCategory ?? "Select category"}
                        onChange={this.handleInputChange}
                      >
                        {this.state.selectedCategory?.length == 0 && <option hidden></option>}
                        {this.renderCategoryOptions()}
                      </SelectTextField>
                    </Box>
                    {this.renderErrorMessage(this.state.selectedCategoryError)}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.label as React.CSSProperties}>
                      Sub-category <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                    </Typography>
                    <Box style={{ position: 'relative' }}>
                      <img src={arrowDown} style={{ position: 'absolute', top: 25, right: 10 }} />
                      <SelectTextField
                        name="selectedSubCategory"
                        style={webStyle.Select}
                        value={this.state.selectedSubCategory ?? "Select category"}
                        onChange={this.handleInputChange}
                      >
                        {this.state.selectedSubCategory?.length == 0 && <option hidden></option>}
                        {this.renderSubCategoryOptions()}
                      </SelectTextField>
                    </Box>
                    {this.renderErrorMessage(this.state.selectedSubCategoryError)}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={webStyle.label as React.CSSProperties}>
                    Location <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                  </Typography>
                  <CustomTextField
                    name="locationData"
                    variant="outlined"
                    placeholder=""
                    type="text"
                    style={{
                      width: "100%",
                      marginTop: "15px",
                      marginRight: "8px"
                    }}
                    InputProps={{ style: placeholderStyleLocation }}
                    value={this.state.locationData}
                    onChange={this.handleInputChange}
                  />
                  {this.renderErrorMessage(this.state.locationDataError)}
                </Grid>
                <Grid item xs={12}>
                  <Typography style={webStyle.label as React.CSSProperties}>
                    Description <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                  </Typography>
                  <textarea
                    name="descriptionData"
                    style={{...webStyle.descriptionText as React.CSSProperties, color: "#64748B"}}
                    placeholder="Type here..."
                    value={this.state.descriptionData}
                    onChange={this.handleInputChange}
                  >
                  </textarea>
                  {this.renderErrorMessage(this.state.descriptionDataError)}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={webStyle.label as React.CSSProperties}>
                      Upload product images <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                    </Typography>
                  </Grid>
                  <div style={{
                    display: "flex", flexDirection: "column", marginLeft: "12px"
                  }}>
                    <div style={{ display: "flex" }}>
                      {this.state.imagePreviews?.length > 0 ? (
                        <Grid item xs={12} style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap:"wrap" }}>
                          {this.state.imagePreviews.map((url:any, index:number) => (
                            <div key={index} style={{ position: 'relative', margin: '8px' }}>
                              <img src={url?.url?url?.url:url} alt={`Preview ${index}`} style={{ width: '200px', height: '200px', objectFit: "contain" }} />
                              <img
                                data-test-id={`remove-${index}`}
                                onClick={() => this.removeImage(url.id, index)}
                                style={{
                                  position: 'absolute', top: '-15px', right: '-17px', cursor: 'pointer', width: "44px", height: "44px"
                                }}
                                src={ImageCrossIcon}
                              />
                            </div>
                          ))}
                        </Grid>
                      ) : (
                        <img src={dummyImg} alt="" />
                      )}
                    </div>
                    {this.renderErrorMessage(this.state.imagePreviewsError)}
                    <div>
                      <div>
                        <Grid item xs={12} sm={6}>
                          <label style={webStyle.customfileupload as React.CSSProperties}>
                            <Typography style={webStyle.customfileuploadHeading as React.CSSProperties}>
                              <img src={imageDirectSending} alt="" style={{ paddingRight: "10px" }} /> Upload
                            </Typography>
                            <input type="file" accept="image/*" multiple onChange={this.handleFileChange} style={{ display: 'none' }} />
                          </label>
                        </Grid>
                      </div>
                    </div>
                  </div>
                  <Grid item xs={12}>
                    <hr style={{ background: '#E5E7EB' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <DescribeProductText>
                      Add your terms of purchase
                      <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                    </DescribeProductText>
                    <Typography style={webStyle.labels as React.CSSProperties}>
                      Define your specific approval criteria to ensure efficient decision-making.

                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    {this.state.criteriaList?.map((criteria: any, index: any) => (
                      <React.Fragment key={index}>
                        <Grid item xs={11}>
                          <CustomTextField
                            name={`termsPurchase-${index}`}
                            variant="outlined"
                            placeholder=""
                            type="text"
                            style={{
                              width: '100%',
                              paddingTop: '10px',
                            }}
                            InputProps={{
                              style: placeholderStyle,
                            }}
                            value={criteria.title}
                            onChange={(event) => this.handleCriteriaChange(index, event.target.value)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{ cursor: index === 0 ? 'not-allowed' : 'pointer' }}
                        >
                          <img
                            src={deletesIcon}
                            alt=""
                            onClick={() => this.handleDelete(index)}
                            style={{ cursor: 'pointer', width: "32px", height: '32px' }}
                            data-test-id="LookingListingButton"
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                      {this.state.selectedCriteriaListError && (
                            this.renderErrorMessageSelling(this.state.selectedCriteriaListError)
                          )}
                    <Grid item xs={12}>
                      <div onClick={this.handleAddCriteria}>
                        <Typography style={webStyle.criteria as React.CSSProperties}>
                          + add new term of purchase
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <hr style={{ background: '#E5E7EB' }} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DescribeProductText>
                    Add your preferences
                  </DescribeProductText>
                  <Typography style={webStyle.labels as React.CSSProperties}>
                    Please add your personal preferences if any.
                  </Typography>
                  <textarea
                    name="preferencesData"
                    style={{...webStyle.criteriaText as React.CSSProperties, color: "#64748B"}}
                    placeholder="Write your preferences here..."
                    value={this.state.preferencesData}
                    onChange={this.handleInputChange}
                  >
                  </textarea>
                </Grid>
                <Grid item xs={12} style={webStyle.buttonContainers}>
                  <Button
                    variant="contained" style={webStyle.createButtons}
                    onClick={() => {
                      this.handleCreateListing();
                    }}
                    data-test-id="createListingButton"
                  >
                    {datacontents ?
                      (<span style={{ textTransform: 'none' }}>Save Listing</span>) :
                      (<span style={{ textTransform: 'none' }}>Create Listing</span>)}
                  </Button>
                  <div style={webStyle.modalStyle as React.CSSProperties}>
                    <div style={webStyle.actionsStyle as React.CSSProperties}>
                      <Popup
                        open={this.state.isCreateButtonClicked}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.closePopup}
                        className="popup"
                        contentStyle={webStyle.contentDiv}
                        overlayStyle={webStyle.overlay}
                      >

                        <div>
                          <div style={webStyle.popupIMGMain as React.CSSProperties} >
                            <img src={imagePopupSomething} />
                          </div>
                          <div>
                            {this.createListingSomething()}
                            <p style={webStyle.popuptextsGoTO as React.CSSProperties} >Go to
                              <Link style={webStyle.signlinks as React.CSSProperties}
                                onClick={this.handleMyListing}>
                                My Listing
                              </Link>

                            </p>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </div>
                  <Button id="create-listing-button" variant="contained" style={webStyle.cancelButtons} onClick={this.handleCancel}>
                    <span style={{ textTransform: 'none' }}>{datacontents ? 'Delete' : 'Cancel'}</span>
                  </Button>
                  <div style={webStyle.modalStyle as React.CSSProperties}>
                    <div style={webStyle.actionsStyle as React.CSSProperties}>
                      <Popup
                        open={this.state.showDeletePopup}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.closePopupDeletePopup}
                        className="popup"
                        contentStyle={webStyle.content}
                        overlayStyle={webStyle.overlay}
                      >

                        <div style={webStyle.deletePopupList as React.CSSProperties}>
                          {this.renderConfirm()}
                          {this.renderErrorMessageKeepListing()}
                        </div>
                      </Popup>
                    </div>
                  </div>
                </Grid>
              </Grid>
            }
          </div>
        </Box>
        <Box>
          <AppFooter naviagtion={this.props.navigation.navigate} />
        </Box>
      </>
    );
  }
}
// Customizable Area End