import React from 'react';
import { Box, styled, Button } from "@material-ui/core";

export function SavedListingEmpty({navigation}:any) {
  const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '350px',
    maxWidth: 'auto',
    margin: '30 auto 10 auto',
    marginTop: "0px",
    paddingTop: "3px"
  });
  const HeadingText = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    fontSize: '22px',
    fontFamily: 'Segoe UI',
    lineHeight: '20px',
    color: '#3CAA49',
  });
  const HeadingTextMain = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10 auto',
    fontSize: '18px',
    fontFamily: 'Segoe UI',
    lineHeight: '20px',
    color: '#676767',
  });
  const CreateListingButtons = styled(Button)({
    borderRadius: "4px",
    width: "100%",
    maxWidth: '200px',
    fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    padding: '10px',
    marginTop: "20px",
    backgroundColor: '#3CAA49',
    fontSize: "18px",
    cursor: "pointer",
    gap: 10,
    alignItems: 'center',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#3CAA49',
    },
    "@media (max-width: 767px)": {
        padding: "10px 20px",
        fontSize: "14px",
        width: "unset",
    },
    "@media (max-width: 450px)": {
        padding: "10px 10px",
        fontSize: "12px",
        width: "unset",
    },
});

  const CustomBox = styled(Box)({
    height: "calc(100% - 68px)",
    paddingBottom: "20px",
    backgroundColor: "rgb(247, 245, 245)",
  });

  return (
    <CustomBox>
      <Container>
        <img src={require('./ListingEmptyImage.png')} alt="Logo" />
      </Container>
      <div>
        <HeadingText>
        Saved Listing is empty!
        </HeadingText>
        <HeadingTextMain>
          Seems like you haven’t created any listing yet
        </HeadingTextMain>
       <HeadingTextMain>
       <CreateListingButtons  onClick={()=>{navigation("FilterAllCategory")}}>
       Browse
        </CreateListingButtons>
        </HeadingTextMain>
      </div>
    </CustomBox>
  );
}

