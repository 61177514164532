import React from "react";

import {
  Input,
  Button,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  // Customizable Area Start
  styled,
  Grid,
  TextField,
  Box,
  InputAdornment,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { imgContactUs, imgPopup, PicImg } from "./assets";
import { AppHeader } from "../../../components/src/AppHeader.web";
import Popup from 'reactjs-popup';


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const FormGrid = styled(Grid)({
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
});
const CustomTextField = styled(TextField)({

  "& .MuiOutlinedInput-input": {
    padding: '10px 15px',
    fontSize: 14,
    height: "30px"
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});

const CustomTextArea = styled("textarea")({
  padding: '10px 15px',
  fontSize: 14,
  width: '100%',
  boxSizing: 'border-box',
  border: '1px solid #c4ccc4',
  borderRadius: 4,
  '&:focus': {
    borderColor: '#c4ccc4',
    borderWidth: '1px',
    outline: '1.5px solid #c4ccc4',
  },
  '&.redBorder': {
    '&:not(:focus)': {
      borderColor: 'red !important',
    }
  },
  '&::placeholder': {
    fontSize: "14px",
    color: "#64748B",
    fontFamily: "Segoe UI",
    lineHeight: "22px",
  },


});

const SelectListingType = styled("p")({
  color: "#000000",
  fontFamily: "Segoe UI Bold",
  fontSize: "25px",
  letterSpacing: "0em",
  lineHeight: "42px", textAlign: "left",
  marginBottom: 20,

  "@media(max-width:767px)": {
    fontSize: "22px",
  },
  "@media(max-width:450px)": {
    fontSize: "20px",
  },
});

const placeholderStyle = {
  fontSize: "14px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};


const FormDataGrid = styled(Grid)({
  '@media(max-width: 960px)': {
    overflow: 'hidden',
    width: '100vw',
    height: 'unset'
  }
});
const Customimg = styled(Grid)({
  '@media(max-width: 960px)': {
    display: 'none'
  }
});


// Customizable Area End

import RefundManagementController, {
  Props,
} from "./RefundManagementController.web";

export default class RefundManagement extends RefundManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderErrorMessage(message: any) {
    return (
      <Typography data-test-id="errormsg" style={Styles.errorMessage as React.CSSProperties}>
        {message}
      </Typography>

    );
  }
  renderOrderIdError() {
    if (this.state.orderIDError) {
      return this.renderErrorMessage('Order ID required!');
    }
    return null;
  }

  renderReasonError() {
    if (this.state.IssueReasonError) {
      return this.renderErrorMessage('Reason/Issue required!');
    }
    return null;
  }

  rendeDetailError() {
    if (this.state.issueDetailError) {
      return this.renderErrorMessage('Detail description required!');
    }
    return null;
  }

  rendeEvidenceError() {
    if (this.state.selectedFileError) {
      return this.renderErrorMessage('Evidence required!');
    }
    return null;
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      // <ThemeProvider theme={theme}>
      <>

        <ThemeProvider theme={theme}>
          <AppHeader navigation={this.props.navigation.navigate} />
          <Box>
            <FormGrid container>
              <FormDataGrid item xs={12} md={6} style={{ height: 'auto', display: "flex", alignItems: "center", marginBottom: "80px" }}>
                <Box style={Styles.rightContentUs}>
                  {/* <Typography style={Styles.rightHeadingus as React.CSSProperties}> */}
                  <SelectListingType>  Refund Request</SelectListingType>

                  {/* </Typography> */}
                  <Box mb={3} style={{ fontFamily: "Segoe UI", fontSize: "16px", color: "#676767" }}>
                    Fill in the form below to get the refund.
                  </Box>
                  <Typography style={Styles.labelus as React.CSSProperties}>
                    Order ID
                  </Typography>
                  <CustomTextField
                    variant="outlined"
                    data-test-id="orderid"
                    placeholder="Enter Order ID"
                    type="text"
                    value={this.state.orderId}
                    style={{
                      width: "100%"
                    }}
                    onChange={this.handleOrderIdChanges}
                    InputProps={{
                      style: placeholderStyle,
                    }}
                  />
                  {this.renderOrderIdError()}
                  {this.state.orderIdErrorRes && <Typography style={Styles.errorMessage as React.CSSProperties}>
                    {this.state.orderIdErrorRes}
                  </Typography>}
                  <Typography style={Styles.labelus as React.CSSProperties}>
                    Issue/Reason for refund
                  </Typography>
                  <CustomTextField
                    variant="outlined"
                    data-test-id="issue"
                    placeholder="Tell us your issue/reason"
                    type="text"
                    value={this.state.IssueReason}

                    style={{
                      width: "100%"
                    }}
                    onChange={this.handleReasonChange}

                    InputProps={{
                      style: placeholderStyle,
                    }}
                  />
                  {this.renderReasonError()}
                  <Typography style={Styles.labelus as React.CSSProperties}>
                    Evidence
                  </Typography>
                  <input
                    id="fileInput"
                    data-test-id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={this.handleFileChange}
                    multiple
                  />

                  <CustomTextField
                    variant="outlined"
                    data-test-id="selectedfile"
                    placeholder={"Add images or files"}
                    disabled
                    value={this.state.selectedFileName.join(", ")}
                    style={{ width: "100%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            data-test-id="browse"
                            component="label"
                            htmlFor="fileInput"
                            variant="contained"
                            style={{ backgroundColor: "#3CAA49" }}
                          >
                            <Typography
                              style={{
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: 700,
                                color: "#ffffff",
                              }}
                            >
                              Browse
                            </Typography>
                            <input
                              id="fileInput"
                              type="file"
                              style={{ display: "none" }}
                              onChange={this.handleFileChange}
                              multiple
                            />
                          </Button>
                        </InputAdornment>
                      ),
                      style: placeholderStyle,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={PicImg} style={{ width: "20px", height: "20px" }} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  {this.rendeEvidenceError()}
                  <Typography style={Styles.labelus as React.CSSProperties}>
                    Detailed Description of the Issue
                  </Typography>
                  <CustomTextArea
                    data-test-id="detail"
                    placeholder="Type here"
                    value={this.state.issueDetail}
                    style={{
                      width: "100%",
                      ...placeholderStyle,
                      minHeight: "129px"
                    }}
                    onChange={this.handleIssueDetailChange}
                  />
                  {this.rendeDetailError()}

                  <Button
                    style={{
                      ...Styles.btnLoginus,
                      textTransform: "initial",
                      cursor: "pointer"
                    }}
                    data-test-id="requestbtn"
                    onClick={this.handleCreateRefundRequest}
                  >
                    Send Request
                  </Button>
                  <div style={Styles.modalStyle as React.CSSProperties}>
                    <div style={Styles.actionsStyle as React.CSSProperties}>
                      <Popup
                        open={this.state.refundPopUpshow}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.handleClosePopup}
                        className="popup" contentStyle={Styles.content}
                        overlayStyle={Styles.overlay}
                      >

                        <div>
                          <div style={Styles.closeStyle as React.CSSProperties}
                            onClick={this.handleClosePopup} >
                            &times;
                          </div>

                          <div style={Styles.popupIMGMain as React.CSSProperties} >
                            <img style={Styles.popupIMG as React.CSSProperties}
                              src={imgPopup} alt="" />
                          </div>
                          <div>
                            <p data-test-id="popmsg" style={Styles.popuptext as React.CSSProperties} >{this.state.popUpMsg}</p>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      alignContent: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Typography style={{ display: "flex", fontFamily: "Segoe UI", fontSize: "16px" }}>Go back to
                      <Typography data-test-id="OrderHIstory"
                        style={Styles.signlinkus as React.CSSProperties}
                        onClick={this.handleNavigateOrderHIstory}
                      >
                        Order history
                      </Typography></Typography>
                  </div>
                </Box>
              </FormDataGrid>
              <Customimg item xs={12} md={6} style={Styles.leftBox}>
                <div>
                  <img
                    src={imgContactUs}
                    style={{ width: '80%', height: '100%' }}
                  />
                </div>
              </Customimg>
            </FormGrid>
          </Box>
        </ThemeProvider>
      </>
      // </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Styles = {
  leftBox: {
    backgroundRepeat: "no-repeat", backgroundSize: "cover"
  },

  rightContentUs: {
    maxWidth: "360px",
    margin: "0 auto", padding: 5,
    width: "360px"
  },
  labelus: {
    fontSize: "16px",
    marginTop: "16px",
    letterSpacing: "0em",
    lineHeight: "24px", color: "#676767",
    textAlign: "left",
    fontFamily: "Segoe UI Bold",
    paddingBottom: 4
  },
  btnLoginus: {
    borderRadius: "8px",
    width: "100%", fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    height: "40px",
    marginTop: "20px",
    backgroundColor: '#3CAA49',
    fontSize: "16px", cursor: "pointer"
  },
  rightHeadingus: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "25px",
    letterSpacing: "0em",
    lineHeight: "42px", textAlign: "left",
    marginBottom: 20,

  },
  signlinkus: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px", fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer",

  },
  errorMessage: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI", fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0em", textAlign: "left"
  },
  errorMessages: {
    color: "#6767673",
    marginTop: "0px",
    fontFamily: "Segoe UI", fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    padding: '20px 80px 20px 80px',
    borderRadius: '8px',
  },
  modalStyle: {
    fontSize: '12px',
  },

  contentStyle: {
    width: '100%',
    padding: '10px 5px',
  },
  popupIMG: {
    display: "flex",
    margin: "0 auto",
  },
  popupIMGMain: {
    margin: "30px 0px 0px 0px"
  },
  actionsStyle: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto', textAlign: 'center',
  },
  popuptext: {
    fontFamily: "Segoe UI Bold",
    marginTop: "0px",
    fontSize: '28px',
    textAlign: "center", fontWeight: "700"
  },
  closeStyle: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: '2px 5px',
    lineHeight: '20px', right: '30px',
    top: '16px', fontSize: '24px',
    background: '#ffffff',


  }
};
// Customizable Area End
