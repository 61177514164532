// Customizable Area Start
import React from "react";
import { Box, styled, CircularProgress, Button, Link, CardContent, Card, Typography } from "@material-ui/core";
import Popup from 'reactjs-popup';
import AllListingsControllers from "./AllListingsControllers";
import { imageEmptyhearts, imagePopupSaves, group_CrossBtns, deleteSavingss, imageFilledHeart, emptyData, searchImageSave, SellingSomeImage } from "./assets";
import { RemoveListingPopup } from "../../../components/src/RemoveListingPopup";

const SellingTextAll = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#181818",
  position: "relative",
  margin: "0px 32px 36px",
  "@media(max-width:450px)": {
    fontSize: "16px",
    lineHeight: "16px"
  },
  marginBottom: "40px"
});
const SellingTextAlls = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#181818",
  position: "relative",
  margin: "40px 32px 36px",
  "@media(max-width:450px)": {
    fontSize: "16px",
    lineHeight: "16px"
  },
  marginBottom: "40px"
});
const GreenLineAll = styled("div")({
  position: "absolute",
  bottom: -10,
  left: 0,
  width: 120,
  borderBottom: "2px solid #3CAA49"
});

const MainDivFilter = styled("div")({
  paddingLeft: '36px',
  display: "grid",
  gridGap: '15px',
  justifyContent: 'flex-start',
  gridTemplateColumns: "auto auto auto auto",
  "@media (min-width:1150px) and (max-width: 1399px)": {
    gridTemplateColumns: "auto auto auto",
  },
  "@media (min-width:960px) and (max-width: 1150px)": {
    gridTemplateColumns: "auto auto",
  },
  "@media(max-width:960px)": {
    gridTemplateColumns: "auto auto auto"
  },
  "@media(max-width:767px)": {
    gridTemplateColumns: "auto auto"
  },
  "@media(max-width:450px)": {
    gridTemplateColumns: "auto",
    justifyContent: 'center',
  }
});
const ViewDataAllText = styled("div")({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: "Segoe UI Bold",
  fontSize: "16px",
  color: '#3CAA49',
  lineHeight: "24px",
  cursor: "pointer",
});
const SavedCardsAll = styled(Card)({
  width: "189px",
  minHeight: "350px",
  cursor: 'pointer',
  padding: "20px 21.5px 17px 21.5px"
});
const AllCardImage = styled("img")({
  width: "100%",
  height: "211px",
  objectFit: "contain",
  borderRadius: "5px",
});
const AllSaveImage = styled("img")({
  width: "29px",
  height: "29px",
});
const BudgetTypographyTextAll = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: "5px 0",
  color: "#676767"
});
const StyledSpanTextAll = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const SearchImageListsAll = styled("img")({
  width: "25px",
  height: "25px"
});
const CommissionTypographySave = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});
const BodyTypographySave = styled("h6")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  color: '#676767',
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
const Lookingfor = styled("h6")({
  width: "150px",
  fontFamily: "Segoe UI Bold",
  fontSize: "12px",
  lineHeight: "20px",
  color: '#676767',
  margin: 0
});
const ContactusGridCancels = styled(Button)({
  height: "56px",
  width: "170px",
  borderRedius: "4px",
  background: "#3CAA49",
  color: "#ECF7ED",
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  border: "none",
  marginRight: "15px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#3CAA49"
  }
});
const ContactusGridDeletes = styled(Button)({
  height: "56px",
  width: "170px",
  borderRedius: "4px",
  background: "#FFFFFFE5",
  color: "#3CAA49",
  fontFamily: "Segoe UI Bold",
  fontSize: "18px",
  textTransform: "capitalize",
  border: "1px solid #3CAA49",
});
const TextLookingAll = styled("div")({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  height: "300px",
  "@media(max-width:450px)": {
    padding: 5
  }
});
const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '350px',
  maxWidth: 'auto',
  margin: '30 auto 10 auto',
});
const HeadingText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  fontSize: '24px',
  fontFamily: 'Segoe UI Bold',
  lineHeight: '24px',
  color: '#3CAA49',
});
const HeadingTextMain = styled('div')({
  marginTop: "10px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '10 auto',
  fontSize: '18px',
  fontFamily: 'Segoe UI',
  lineHeight: '20px',
  color: '#676767',
});



type ViewDatas = (id: number) => void;



export default class AllListings extends AllListingsControllers {
  renderBoxMain() {

    return (
      <>
        <Box style={{ lineHeight: "1.0px" }}>
          {this.state.AllDataListing && this.state.AllDataListing.length > 0 && (
            <Typography style={styleD.lookingTextsMain as React.CSSProperties}>
              {this.handleCountProduct()} Products
            </Typography>
          )}
        </Box>

      </>
    )
  }
  renderCardAllListings = (item: any, viewData: ViewDatas) => {
    const { savedListingsDataAll } = this.state;
    const token = localStorage.getItem("authToken");
    return (
    

      <SavedCardsAll >
        <div style={{ display: "flex", alignItems: 'center', gap: 10, marginBottom: '15px' }}>
          <SearchImageListsAll
            src={item.attributes.listing_type === "looking_for_something" ? searchImageSave : SellingSomeImage}
          />
          <Lookingfor data-test-id={`listingType-${item.id}`}>
         
            {item.attributes.listing_type === "looking_for_something" ? "Looking for something" : "Selling something"}
          </Lookingfor>
        </div>
        <a data-test-id="viewAll"   style={{ textDecoration: 'none', color: 'inherit' }}   href={`/ViewListing/${item.id}`} onClick={(event) => {
        if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
          event && event.preventDefault();
          this.viewData(item.id)
        }
      }}>
        <AllCardImage src={item.attributes?.photo?.[0]?.url} alt="Card Image" /> 
        <CardContent style={{ padding: '0px' }}>
          <BodyTypographySave>
            {item.attributes.title}
          </BodyTypographySave>
          <BudgetTypographyTextAll>
            Budget <StyledSpanTextAll>£ {item.attributes.budget}</StyledSpanTextAll>
          </BudgetTypographyTextAll>
         
        </CardContent>
        </a>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <CommissionTypographySave>
              Reward <StyledSpanTextAll> £ {item.attributes.commission}</StyledSpanTextAll>
            </CommissionTypographySave>
            <AllSaveImage
              data-test-id='viewAll2'
              src={savedListingsDataAll?.includes(item.id) ? imageFilledHeart : imageEmptyhearts}
              onClick={(e) => {
                e.stopPropagation();
                token
                    ? this.state.savedListingsDataAll?.includes(item.id)
                        ? this.popupSaveds(item.id)
                        : this.savedlistApiCallAllList(item.id)
                    : this.handleListingAddAll();
            }}
            
              alt="Card Image"
            />
          </div>
      </SavedCardsAll>
     
    );
  };

  renderEmptyTextAll(message: string) {
    return (
      <Box >
        <Container>
          <img src={emptyData} alt="Logo" style={{ width: "90%" }} />
        </Container>
        <div>
          <HeadingText>
            No products found
          </HeadingText>
          <HeadingTextMain>
            {message}
          </HeadingTextMain>
          <HeadingTextMain>
          </HeadingTextMain>
        </div>
      </Box>
    );
  }
  renderHomeCard = (item: any, viewData: ViewDatas) => {
    const { savedListingsDataAll } = this.state;
    const token = localStorage.getItem("authToken");
    return (
   
      <SavedCardsAll  >
        <div style={{ display: "flex", alignItems: 'center', gap: 10, marginBottom: '10px', marginLeft: '15px' }}>
          <SearchImageListsAll src={item.attributes.listing_type === "looking_for_something" ? searchImageSave : SellingSomeImage} />
       
          <Lookingfor  data-test-id={`sell-${item.id}`}>{item.attributes.listing_type === "looking_for_something" ? "Looking for something" : "Selling something"}</Lookingfor>
        </div>
        <a data-test-id="viewHome" style={{ textDecoration: 'none', color: 'inherit' }}   href={`/ViewListing/${item.id}`} onClick={(event) => {
        if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
          event && event.preventDefault();
          this.viewData(item.id)
        }
      }}>
   
        <AllCardImage src={item.attributes.photo?.[0]?.url} alt="Card Image" />
        <CardContent style={{ padding: '0px' }}>
          <BodyTypographySave>
            {item.attributes.title}
          </BodyTypographySave>
          <BudgetTypographyTextAll>
          Price <StyledSpanTextAll>£ {item.attributes.budget}</StyledSpanTextAll>
          </BudgetTypographyTextAll>
        </CardContent>
        </a>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <CommissionTypographySave>
              Reward <StyledSpanTextAll> £ {item.attributes.commission}</StyledSpanTextAll>
            </CommissionTypographySave>
            <AllSaveImage
              data-test-id="viewHome4"
              src={savedListingsDataAll?.includes(item.id) ? imageFilledHeart : imageEmptyhearts}
              onClick={(e) => {
                e.stopPropagation();
                token ?
                savedListingsDataAll?.includes(item.id) ? this.popupSaveds(item.id) : this.savedlistApiCallAllList(item.id)
             : this.handleListingAddAll();
              }}
              alt="Card Image"
            />
          </div>
      </SavedCardsAll>
    );
  };
  SellingSomeData() {
    return (
      <SellingTextAll>
        Looking for something
        <GreenLineAll />
      </SellingTextAll>
    )
  }


  showSellingProduct= () => {
    return (
        <>
            {this.state.someListingSaveAll.length > 7 && !this.state.showAllSellingSomethingAll && (
                <ViewDataAllText data-test-id="mySelling6" onClick={this.saveSellingAll.bind(this)}>View all</ViewDataAllText>
            )}
        </>
    );
};


showLookingProduct= () => {
  return (
      <>
          {this.state.LookingSomeDatasAll.length > 7 && !this.state.showAllDataFilter && (
                                    <ViewDataAllText data-test-id="successbutton" onClick={this.saveLooking.bind(this)}>
                                      View all</ViewDataAllText>
                                )}
      </>
  );
};

  lookingSellingShowState = (product: any, products: any) => {
    const { showLookingSomeData, showSellingSomeData } = this.props;
    const {  someListingSaveAll } = this.state;

    let viewState;
    if (showLookingSomeData && !showSellingSomeData) {
        viewState = "showLookingOnly";
    } else if (!showLookingSomeData && showSellingSomeData) {
        viewState = "showSellingOnly";
    } else {
        viewState = "showBoth";
    }

    switch (viewState) {
        case "showLookingOnly":
            return (
                <div>
                    {this.renderBoxMain()}
                    {this.SellingSomeData()}
                    {product.length === 0 ?
                     this.renderEmptyTextAll("Sorry, it seems we don’t have the product you are looking for.") : (
                        <>
                            <MainDivFilter>
                                {product.map((item: any) => this.renderCardAllListings(item, this.viewData))}
                               {this.showLookingProduct()}
                            </MainDivFilter>
                        </>
                    )}
                </div>
            );

        case "showSellingOnly":
            return (
                <div>
                    {this.renderBoxMain()}
                    <SellingTextAlls>
                        Selling something
                        <GreenLineAll />
                    </SellingTextAlls>
                    {products.length === 0 ? this.renderEmptyTextAll("Sorry, it seems don’t have the product for sale.") 
                    : 
                    (
                        <>
                            <MainDivFilter>
                                {products.map((item: any) => this.renderHomeCard(item, this.viewData))}
                               {this.showSellingProduct()}
                            </MainDivFilter>
                        </>
                    )}
                </div>
            );

        case "showBoth":
        default:
            return (
                <div>
                    {this.renderBoxMain()}
                    {this.SellingSomeData()}
                    {product.length === 0 ? this.renderEmptyTextAll("Sorry, it seems we don’t have the product you are looking for.") : 
                    (
                        <>
                            <MainDivFilter>
                                {product.map((item: any) => this.renderCardAllListings(item, this.viewData))}
                               {this.showLookingProduct()}
                            </MainDivFilter>
                        </>
                    )}

                    <SellingTextAlls>
                        Selling something
                        <GreenLineAll />
                    </SellingTextAlls>

                    {someListingSaveAll.slice(0, 7).length === 0 ? 
                    this.renderEmptyTextAll("Sorry, it seems don’t have the product for sale.") : (
                        <>
                            <MainDivFilter>
                                {products.map((item: any) => this.renderHomeCard(item, this.viewData))}
                                {this.showSellingProduct()}
                            </MainDivFilter>
                        </>
                    )}
                </div>
            );
    }
};



  render() {
    const product = this.state.showAllDataFilter ? this.state.LookingSomeDatasAll : this.state?.LookingSomeDatasAll?.slice(0, 7);
    const products = this.state.showAllSellingSomethingAll ? this.state.someListingSaveAll : this.state.someListingSaveAll?.slice(0, 7);
    return (
      <Box width="100%" style={{ padding: 0 }}>
        {this.state.LoaderListingData ?
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              margin: '0px auto'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CircularProgress style={{ color: '#4fa94d' }} />
            </Box>
          </div> :
          <>
         
            {this.lookingSellingShowState(product,products)}
          
           
          </>
        }
        <div style={styleD.modalStylesLandingss as React.CSSProperties}>
          <div style={styleD.actionsStylesMain as React.CSSProperties}>
            <Popup
              open={this.state.savedPopupLandingAll}
              test-id="popup"
              closeOnDocumentClick onClose={this.saveClosePopupsAll}
              className="popup"
              contentStyle={styleD.contentSaves}
              overlayStyle={styleD.overlaysess}
            >
              <div>
                <div style={styleD.popupIMGMainsLandings as React.CSSProperties} >
                  <img src={imagePopupSaves} alt="popup" />
                </div>
                <div >
                  <p style={styleD.popuptextsAdds as React.CSSProperties} >Added to Saved Listing!</p>
                </div>
                <div>
                  <p style={styleD.popuptextssGoTos as React.CSSProperties} >Go to
                    <Link style={styleD.signlinkssGoTo as React.CSSProperties}
                      onClick={this.handleSaveListingssAll}
                    >
                      Saved Listings
                    </Link>
                  </p>
                </div>
              </div>
            </Popup>
          </div>
        </div>
        <div style={styleD.modalStylesSellingAll as React.CSSProperties}>
          <div style={styleD.actionsStylesSellingAll as React.CSSProperties}>
            <Popup
              open={this.state.savedPopupsAll}
              test-id="popup"
              closeOnDocumentClick onClose={this.saveClosePopupss}
              className="popup"
              contentStyle={styleD.contentSaveSellingss}
              overlayStyle={styleD.overlaysesSellingss}
            >
              <div>
                <div style={styleD.popupIMGMainss as React.CSSProperties} >
                  <img src={imagePopupSaves} alt="popup" />
                </div>
                <div >
                  <p style={styleD.popuptextsAdd as React.CSSProperties} >Added to Saved Listing!</p>
                </div>
                <div>
                  <p style={styleD.popuptextssGoTo as React.CSSProperties} >Go to
                    <Link style={styleD.signlinkssGoTos as React.CSSProperties}
                      onClick={this.handleSaveListingssAll}
                    >
                      Saved Listings
                    </Link>
                  </p>
                </div>
              </div>
            </Popup>
          </div>
        </div>
        <div style={styleD.actionsStylesRemoves as React.CSSProperties}>
          <Popup
            open={this.state.removeSavedList}
            test-id="popup"
            closeOnDocumentClick onClose={this.navigateSavedSomethingLandingAll}
            className="popup"
            contentStyle={styleD.contentsDeleteHomes}
            overlayStyle={styleD.overlaysDeletesHomes}
          >
            <div style={styleD.deletePopupDeletesHomes as React.CSSProperties}>
              <RemoveListingPopup navigation={this.props.navigation.navigate} />
              <Box >
                <ContactusGridCancels onClick={this.navigateSavedSomethingLandingAll} data-testid="save-navigate">Cancel</ContactusGridCancels>
                <ContactusGridDeletes data-testid="save-navigate1"
                  onClick={() => this.deleteSavelistingCallMainApi(this.state.deleteItemIdsAll)}
                >Remove</ContactusGridDeletes >
              </Box>
            </div>
          </Popup>
        </div>
        <div>
          <Popup
            open={this.state.successRemoveSavingAll}
            test-id="popup"
            closeOnDocumentClick onClose={this.successpopupSavedLandingAll}
            className="popup"
            contentStyle={styleD.contentsPasswordsLandingAll}
            overlayStyle={styleD.overlaysBtnLandingAll}
          >
            <div>
              <img src={group_CrossBtns} alt="Cross" style={styleD.imgCrossesLsAll as React.CSSProperties} onClick={this.closePopupPasswordLandingAll} />
              <div style={styleD.popupIMGMainssBtnLsAll as React.CSSProperties} >
                <img src={deleteSavingss} alt="Crosss" />
              </div>
              <p style={styleD.passwordChangesLsAll as React.CSSProperties}>{this.state.deleteItemsAll}</p>
            </div>
          </Popup>
        </div>


      </Box>
    );
  }
}
const styleD = {
  lookingTextsMain: {
    fontFamily: "Segoe UI",
    fontSize: "24px",
    color: "#3CAA49",
    marginLeft: '30px',
    marginBottom: "20px",
    lineHeight: "24px"
  },
  contentsPasswordsLandingAll: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '30.5px 43.5px 30.5px 43.5px',
    width: '500px',
    borderRadius: '8px',
    height: '350px'
  },
  overlaysBtnLandingAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  passwordChangesLsAll: {
    fontSize: '30px',
    marginTop: "15px",
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#000000",
    LineHeigth: '32px',
    margin: 'auto',
  },
  popuptextsAdd: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  popuptextsAdds: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  popupIMGMainssBtnLsAll: {
    width: '232px',
    heigth: '232px',
    margin: 'auto',
  },
  imgCrossesLsAll: {
    position: 'absolute',
    top: 38,
    margin: 'auto',
    right: 36,
    cursor: 'pointer',
  },
  overlaysesSellingss: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  actionsStylesMain: {
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  actionsStylesRemoves: {
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  contentsDeleteHomes: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '527px',
    borderRadius: '8px',
    height: '322px'
  },
  overlaysDeletesHomes: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  deletePopupDeletesHomes: {
    marginTop: "85px",
    paddingLeft: '52px'
  },
  modalStylesSellingAll: {
    fontSize: '12px',
  },
  popuptextssGoTos: {
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: "#181818"
  },
  popuptextssGoTo: {
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: "#181818"
  },
  actionsStylesSellingAll: {
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  contentSaveSellingss: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '400px'
  },
  popupIMGMainss: {
    margin: "15px 0px 15px 0px",
    display: "flex",
    justifyContent: "center"
  },
  signlinkssGoTo: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  signlinkssGoTos: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  modalStylesLandingss: {
    fontSize: '12px',
  },
  contentSaves: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '400px'
  },
  overlaysess: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popupIMGMainsLandings: {
    margin: "15px 0px 15px 0px",
    display: "flex",
    justifyContent: "center"
  },
  signlinksAll: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },

}
// Customizable Area End
