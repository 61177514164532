import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  Login_details: any;
  signupInformationError: any;
  isSnackbarOpen: boolean,
  showAgreeError: boolean;
  checkboxErrorAgree: boolean;
  showPassword: boolean;
  passwordMatchError: boolean;
  checkboxChecked: boolean;
  emailError: boolean;
  passwordError: boolean;
  emailFocused: boolean;
  emailBlurred: boolean;
  finishedTyping: boolean;
  LoaderImages: boolean;
  emailErrorMsg: string;
  noAccountregistered: string;
  mainImage: any;
  emailErrortakenMsg: string;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  passModalOPen:boolean
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  apiLogin_informationCallId: string = "";
  apiImage_informationCallId: string = "";
  labelTitle: string = "";
  apiCallLookingIds: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      showPassword: false,
      passwordMatchError: false,
      emailError: false,
      Login_details: [],
      signupInformationError: [],
      showAgreeError: false,
      passwordError: false,
      emailFocused: false,
      checkboxChecked: false,
      emailBlurred: false,
      finishedTyping: false,
      checkboxErrorAgree: false,
      isSnackbarOpen: false,
      emailErrorMsg: "",
      emailErrortakenMsg: "",
      noAccountregistered: "",
      mainImage: '',
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      LoaderImages: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      passModalOPen:false,
      loading : false
    };

    this.emailReg = new RegExp("");
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.loginImageAPi();
    // Customizable Area End
  }

  // Customizable Area Start
  onRedirect = () => {
    this.props.navigation.navigate("LandingPageMain");
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  handleCreateAccount() {
    const {
      password,
      email,
      emailErrorMsg,
      emailErrortakenMsg,
      checkboxChecked,
    } = this.state;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!checkboxChecked) {
      this.setState({ checkboxErrorAgree: true });
      this.openSnackbar();
    }

    if (password.trim() === "") {
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }
    if (email.trim() === "") {
      this.setState({ emailErrorMsg: "Email is required" });
    } else if (!emailPattern.test(email)) {
      this.setState({ emailErrorMsg: "Invalid email format" });
    }
    if (
      email.trim() !== "" &&
      password.trim() !== "" &&
      emailPattern.test(email) &&
      emailErrorMsg.trim() === "" &&
      emailErrortakenMsg.trim() === ""
    ) {
      this.loginInfomationDetails();
    }
  }
  handleEmailChange = (event: { target: { value: string } }) => {
    const email = event.target.value;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.setState({
      email,
      emailError: !emailPattern.test(email),
      emailErrorMsg: email.length == 0 ? "Email is Required" : "",
      noAccountregistered: ""
    });
  };
  handleEmailFocus = () => {
    this.setState({
      emailFocused: true,
      emailBlurred: false,
      finishedTyping: false
    });
  };
  loginImageResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && Array.isArray(responseJson.data)) {
      this.setState({ LoaderImages: false })
      const imgWithSignupName = responseJson.data.find((img: any) => img.attributes?.name === "login");
      const photo = imgWithSignupName ? imgWithSignupName.attributes.photo : "";
      this.setState({
        mainImage: photo
      });
    }
  }


  handlePasswordChange = (event: any) => {
    const password = event.target.value;
    this.setState({ noAccountregistered: "", password })
    if (password.trim() === "") {
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }
  };
  openSnackbar = () => {
    this.setState({
      isSnackbarOpen: true
    });
  };
  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,

    };

    const requestMessagess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body
      ? requestMessagess.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessagess.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessagess.id, requestMessagess);
    return requestMessagess.messageId;
  };
  handleClickLogin = () => {
    this.props.navigation.navigate("EmailAccountRegistration");
  };
  handleForgotPassword = () => {
  
    this.setState({passModalOPen:true})
  };
  handleOpenPass=()=>{
    this.setState({passModalOPen:true})
  }

  handleClosePass=()=>{
    this.setState({passModalOPen:false})
  }
  userProfileCall = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallLookingIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/show_user_profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  loginInfomationDetails = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json"
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLogin_informationCallId = requestMessages.messageId;
    let Body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": this.state.email,
          "password": this.state.password,
        }
      }
    };
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_login/logins"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
  };
  loginImageAPi = async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiImage_informationCallId = requestMessages.messageId;

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_content_management/photos"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ LoaderImages: true })
    runEngine.sendMessage(requestMessages.id, requestMessages);
  };


  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiLogin_informationCallId) {
        if (responseJson.meta?.token) {
          localStorage.setItem("authToken", responseJson.meta.token);
        localStorage.setItem("id", responseJson.meta.id);
        localStorage.setItem("auth", responseJson.meta.token);
         this.userProfileCall()
        }
        else if(responseJson.errors && responseJson.errors[0]?.message) {
          toast.error(responseJson.errors[0]?.message);
          this.setState({ loading: false });
        }
        else {
          this.setState({
            loading: false,
            noAccountregistered: responseJson.errors[0]?.failed_login
              ? responseJson.errors[0].failed_login
              : "",
          });
        }
      } 
      else if (apiRequestCallId === this.apiCallLookingIds) {
        const fullName = responseJson?.data?.attributes?.full_name;
        const email = responseJson?.data?.attributes?.email;
        const photo = responseJson?.data?.attributes?.photo;
      
          localStorage.setItem("fullName", fullName);
          localStorage.setItem("email", email);
          if (photo !== null) {
            localStorage.setItem("profilePhoto", photo);
            }
          const lookingForSomething = localStorage.getItem("LookingForSomething");
          const lookingForSomethingIds = localStorage.getItem("ids");
          const redirectUrl = localStorage.getItem("urlRedirect") || localStorage.getItem('redirectUrl');

          if (redirectUrl) {
            localStorage.removeItem('redirectUrl');
            sessionStorage.removeItem('redirectUrl');
            const [route, id] = redirectUrl.split('/').filter(Boolean).slice(-2);
            this.props.navigation.navigate(route, { id: id });
          }
          else if (lookingForSomethingIds) {
            this.props.navigation.navigate(lookingForSomething, { id: lookingForSomethingIds });
            localStorage.removeItem("LookingForSomething");
            localStorage.removeItem("ids");
          } else if (lookingForSomething) {
            this.props.navigation.navigate(lookingForSomething);
            localStorage.removeItem("LookingForSomething");
          }
          else {
            this.props.navigation.navigate("LandingPage");
          }
          this.setState({ loading: false });
      }
      
      this.loginImageResponse(responseJson);
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
