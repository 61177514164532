import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Popup from 'reactjs-popup';
import ChangePassword from "./ChangePassword.web";
import { imageDeleteIcons } from "./assets";
// Customizable Area End

import SettingFlowAccountController, {
  Props,
} from "./SettingFlowAccountController";
import { DeleteIcon, image_trash, imgPopup, loginImg, profile, image_Cross } from "../../settings2/src/assets";

export default class SettingFlowAccount extends SettingFlowAccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDataArea() {
    const userPhoto = localStorage.getItem("profilePhoto")
    return (
      userPhoto == null ? <><img src={profile} alt="" style={webstyle.profileImg as React.CSSProperties} /></> : <><img src={userPhoto} alt="" style={webstyle.profileImg as React.CSSProperties} /></>
    )
  }
  DeleteConfirm() {
    return (
      <div>
        <p style={webstyle.popuptextsss as React.CSSProperties} >Be advised, account deletion is final. There will be no way to restore your account.</p>
      </div>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const handleFileChanges = (e: any) => {
      const files = e.target.files;
      const validFiles = Array.from(files).filter((file: any) => file.type.includes('image/'));
      const newSelectedFiles = validFiles.length > 0 ? [validFiles[validFiles.length - 1]] : [];
      this.setState({ selectedFiless: newSelectedFiles });
      const newImagePreviews = newSelectedFiles.map((file) => {
        return URL.createObjectURL(file as File);
      });
      this.setState({ imagePreviewss: newImagePreviews, errorMessage: "" });
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box width="100%">
        {this.state.LoaderFormSetting ?
          (<div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              background: "rgb(247, 245, 245)",
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CircularProgress style={{ color: '#4fa94d' }} />
            </Box>
          </div>) :
          <Box>
            <Box style={webstyle.profileMain as React.CSSProperties}>
              <Box >
                <Grid container style={webstyle.profileHero as React.CSSProperties}>
                  <Grid item lg={3}>
                    <Typography style={webstyle.profileHeading as React.CSSProperties}>Profile Picture</Typography>
                  </Grid>
                  <Grid item lg={2} style={{ alignSelf: 'center' }}>
                    <Box style={webstyle.buttonMain as React.CSSProperties}>
                      {
                        this.renderDataArea()
                      }
                    </Box>
                  </Grid>
                  <Grid item lg={7} style={webstyle.profileImgText as React.CSSProperties}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box>
                        <Button style={webstyle.plateformExploreBtn as React.CSSProperties}
                          data-testid="changepicture"
                          onClick={() => {
                            this.handleChangePicture();
                          }}
                        >Change Picture</Button>
                        <div style={webstyle.modalStyles as React.CSSProperties}>
                          <div style={webstyle.actionsStyles as React.CSSProperties}>
                            <Popup
                              open={this.state.showPopups}
                              data-testid="popup"
                              closeOnDocumentClick
                              onClose={() => this.handleCLose()}
                              className="popup"
                              contentStyle={webstyle.contents}
                              overlayStyle={webstyle.overlays}
                            >
                              <Box style={webstyle.poupMain}>
                                <Typography style={webstyle.popupHeading}>Profile Picture</Typography>
                                <div>{this.state.imagePreviewss.length > 0 ? (
                                  <Grid item xs={12} sm={6} style={webstyle.imgPre as React.CSSProperties}>
                                    {this.state.imagePreviewss?.map((url: any, index: any) => (
                                      <img key={index} src={url} alt={`Preview ${index}`} style={webstyle.imgPres} />
                                    ))}
                                  </Grid>
                                ) : <> {this.state.imageStore === null ? <img src={profile} alt="" style={webstyle.profileImgse as React.CSSProperties} /> : <img src={this.state.imageStore} alt="" style={webstyle.profileImgs as React.CSSProperties} />}</>}</div>
                                <Typography style={webstyle.validateMessage as React.CSSProperties}>{this.state.errorMessage}</Typography>
                                <Typography style={webstyle.uploadText}>Upload a picture in JPG or PNG format. </Typography>
                                <Typography style={webstyle.uploadText}>Image size must be from 1 MB - 10 MB.</Typography>
                                <hr style={webstyle.hr as React.CSSProperties} />
                                <Box display={'flex'} alignItems={'center'}>
                                  <label>
                                    <Typography style={webstyle.popupBtn as React.CSSProperties}>
                                      Select
                                    </Typography>
                                    <input type="file" accept="image/*" data-testid="handleFileChanges" onChange={handleFileChanges} style={{ display: 'none' }} />
                                  </label>
                                  <Button style={webstyle.popupBtnSave as React.CSSProperties} data-testid="save" onClick={() => this.AddImages()}>Save</Button>
                                  <img src={image_trash} onClick={() => this.handleDeleteProfile()} data-testid="deletes" alt="" style={webstyle.deleteImgpopup as React.CSSProperties} />
                                </Box>
                              </Box>

                            </Popup>
                          </div>
                        </div>
                        <div style={webstyle.modalStyles as React.CSSProperties}>
                          <div style={webstyle.actionsStyles as React.CSSProperties}>
                            <Popup
                              open={this.state.showPopupsMessage}
                              data-testid="popups"
                              closeOnDocumentClick
                              onClose={() => this.handleCLosee()}
                              className="popup"
                              contentStyle={webstyle.contentss}
                              overlayStyle={webstyle.overlays}
                            >
                              <Box style={webstyle.poupMain}>
                                <Box style={webstyle.leftBoxs as React.CSSProperties}>
                                  <div style={{ textAlign: 'center' }}>
                                    <img
                                      src={imgPopup}
                                      style={{ width: '232px', height: '232px', }}
                                    />
                                  </div>
                                </Box>
                                <Typography style={webstyle.uploadTextsPopup as React.CSSProperties}> {this.state.popUpMsgsForImage}</Typography>
                                <Typography style={{ textAlign: 'center', marginTop: '20px', cursor: "pointer" }} data-testid="setting" onClick={() => this.goTOSetting()}>
                                  <span style={webstyle.goto as React.CSSProperties}>Go to</span><span style={webstyle.setting as React.CSSProperties}>Settings</span>
                                </Typography>
                              </Box>
                            </Popup>
                          </div>
                        </div>
                      </Box>
                      <Box>
                        <img src={DeleteIcon} onClick={() => this.handleDeleteProfile()} data-testid="delete" alt="" style={webstyle.deleteImg as React.CSSProperties} />
                      </Box>

                    </Box>
                  </Grid>
                </Grid>
                <hr style={webstyle.hrs as React.CSSProperties} />
                <Grid container>
                  <Grid item lg={3}>
                    <Typography style={webstyle.profileHeading as React.CSSProperties}>Personal Details</Typography>
                  </Grid>
                  <Grid item lg={9} style={{ alignSelf: 'center', }}>
                    <Box>
                      <form>
                        <Typography>
                          <Typography style={{ marginBottom: '10px' }}>
                            <Typography style={webstyle.formHeading as React.CSSProperties}>Name</Typography>
                            <Typography>
                              <input
                                type="text"
                                style={webstyle.formInput as React.CSSProperties}
                                value={this.state.fullName}
                                data-testid="handleChange"
                                onChange={this.handleChange}
                              />
                            </Typography>
                            <Typography style={webstyle.validateMessage as React.CSSProperties}>{this.state.fullNameError}</Typography>
                          </Typography>
                          <Typography style={{ marginBottom: '10px' }}>
                            <Typography style={webstyle.formHeading as React.CSSProperties}>Email address</Typography>
                            <Typography >
                              <input type="email" style={webstyle.formInput as React.CSSProperties}
                                //@ts-ignore
                                value={this.state.email}
                                disabled
                              />
                            </Typography>
                          </Typography>
                          <Typography style={{ marginBottom: '12px' }}>
                            <Box display={'flex'}>
                              <Box mr={3}>
                                <Typography style={webstyle.formHeading as React.CSSProperties}>Country</Typography>
                                <input type="text" style={webstyle.formInputs as React.CSSProperties} value={this.state.country} onChange={this.changeForCountry} />
                                <Typography style={webstyle.validateMessage as React.CSSProperties}>{this.state.errorMessageForcountry}</Typography>
                              </Box>
                              <Box>
                                <Typography style={webstyle.formHeading as React.CSSProperties}>City</Typography>
                                <input type="text" style={webstyle.formInputs as React.CSSProperties} value={this.state.city} onChange={this.changeForCity} />
                                <Typography style={webstyle.validateMessage as React.CSSProperties}>{this.state.errorMessageForcity}</Typography>
                              </Box>
                            </Box>

                            <Button
                              disabled={
                                !this.hasProfileChanged()
                              }
                              style={
                                this.hasProfileChanged() ?
                                  webstyle.plateformExploreBtnssAdd :
                                  webstyle.plateformExploreBtnssAddDisabled as React.CSSProperties
                              }
                              onClick={this.handleUpdateProfile}
                            >
                              <span style={{ textTransform: 'none', margin: 0 }}>Save</span>
                            </Button>

                          </Typography>
                        </Typography>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
                <div style={webstyle.modalStyles as React.CSSProperties}>
                  <div style={webstyle.actionsStyles as React.CSSProperties}>
                    <Popup
                      open={this.state.savePopupOpen}
                      data-testid="popups1"
                      closeOnDocumentClick
                      onClose={() => this.handleCLosee()}
                      className="popup"
                      contentStyle={webstyle.contentss}
                      overlayStyle={webstyle.overlays}
                    >
                      <Box style={webstyle.poupMain}>
                        <Box style={webstyle.leftBoxs as React.CSSProperties}>
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src={imgPopup}
                              style={{ width: '232px', height: '232px', }}
                            />
                          </div>
                        </Box>
                        <Typography style={webstyle.uploadTextsPopup as React.CSSProperties}> {this.state.successMessage}</Typography>
                        <Typography style={{ textAlign: 'center', marginTop: '20px', cursor: "pointer" }} data-testid="setting1" onClick={() => this.goTOSetting1()}>
                          <span style={webstyle.goto as React.CSSProperties}>Go to</span><span style={webstyle.setting as React.CSSProperties}>Settings</span>
                        </Typography>
                      </Box>
                    </Popup>
                  </div>
                </div>
                <hr style={webstyle.hr as React.CSSProperties} />
                <Grid container style={webstyle.profileHero as React.CSSProperties}>
                  <Grid item lg={3}>
                    <Typography style={webstyle.profileHeading as React.CSSProperties}>Change Password</Typography>
                  </Grid>
                  <Grid item lg={9} style={webstyle.profileImgTexts as React.CSSProperties}>
                    <Typography style={{ marginBottom: '12px' }}>
                      <Box display={'flex'}>
                        <Box>
                          <Typography style={webstyle.formHeading as React.CSSProperties}>Password</Typography>
                          <input type="text" style={webstyle.formInputsPass as React.CSSProperties} />
                        </Box>
                        <ChangePassword {...this.props} />
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <hr style={webstyle.hr as React.CSSProperties} />
                <Grid container style={webstyle.profileHero as React.CSSProperties}>
                  <Grid item lg={3}>
                    <Typography style={webstyle.profileHeading as React.CSSProperties}>Account Actions</Typography>
                  </Grid>
                  <Grid item lg={2}>
                    <Button style={webstyle.buttonMain as React.CSSProperties} onClick={this.closePopups}>
                      <Box>
                        <img src={loginImg} alt="" style={webstyle.deleteImg as React.CSSProperties} />
                      </Box>
                      <Box pl={3}>
                        <Typography style={webstyle.logoutText as React.CSSProperties}>Logout</Typography>
                      </Box>
                    </Button>
                    <div style={webstyle.actionsStyles as React.CSSProperties}>
                      <Popup
                        open={this.state.showPopupsLogout}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.closePopupssLogout}
                        className="popup"
                        contentStyle={webstyle.contentslogout}
                        overlayStyle={webstyle.overlayslogout}
                      >

                        <div style={{maxWidth: "290px", margin: "auto"}}>
                          <p style={webstyle.popuptextslog as React.CSSProperties} > Logging out</p>
                          <div>
                            <p style={webstyle.popuptextssslog as React.CSSProperties} >Are you sure you want to leave?</p>
                          </div>
                          <Box style={{ marginTop: "40px" }}>
                            <Button style={webstyle.plateformExploreBtns as React.CSSProperties} onClick={this.handleKeepListing}>Cancel</Button>
                            <Button style={webstyle.plateformListingBtn as React.CSSProperties} data-testid="handleListing" onClick={this.handleDeleteData}>Log-out</Button>
                          </Box>
                        </div>
                      </Popup>
                    </div>


                    <Button style={webstyle.buttonMain as React.CSSProperties} onClick={this.handleKeepAccount}>
                      <Box>
                        <img src={image_trash} alt="" style={webstyle.deleteImg as React.CSSProperties} />
                      </Box>
                      <Box pl={3}>
                        <Typography style={webstyle.logoutText as React.CSSProperties}>Delete Account</Typography>
                      </Box>
                    </Button>
                    <div style={webstyle.actionsStyles as React.CSSProperties}>
                      <Popup
                        open={this.state.deleteDataAccounts}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.closedeletePopups}
                        className="popup"
                        contentStyle={webstyle.contentsDelete}
                        overlayStyle={webstyle.overlaysDelete}
                      >
                        <div >
                          <p style={webstyle.popuptextsDelete as React.CSSProperties} >Delete Confirmation</p>
                          {this.DeleteConfirm()}
                          <Box style={{ marginTop: "30px" }}>
                            <Button style={webstyle.plateformExploreBtnss as React.CSSProperties} onClick={this.closedeletePopups}>Keep Account</Button>
                            <Button style={webstyle.plateformListingBtndeleteAccount as React.CSSProperties} data-testid="handleListing" onClick={this.handleDeleteAccount}>Delete Account</Button>
                          </Box>
                        </div>
                      </Popup>
                    </div>
                    <div style={webstyle.actionsStyles as React.CSSProperties}>
                      <Popup
                        open={this.state.handleDeletePopup}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.handleDeleteAccountData}
                        className="popup"
                        contentStyle={webstyle.contentsRemoveSuccess}
                        overlayStyle={webstyle.overlayslogout}
                      >

                        <div>
                          <img src={image_Cross} alt="" style={webstyle.imgCross as React.CSSProperties} onClick={this.closePopupPassword} />
                          <Box style={{ padding: '68px 65px 20px', textAlign: "center", }}>
                            <div style={webstyle.popupIMGMainss as React.CSSProperties} >
                              <img src={imageDeleteIcons} />
                            </div>
                            <Typography style={webstyle.accountDelete as React.CSSProperties}>Account Deleted Successfully!</Typography>
                            <p style={webstyle.weAccountDelete as React.CSSProperties}>We are sad to let you go.</p>
                          </Box>
                        </div>
                      </Popup>
                    </div>
                  </Grid>
                  <Grid item lg={7} style={webstyle.profileImgText as React.CSSProperties}>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        }
      </Box >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  profileMain: {
    background: "rgb(247, 245, 245)",
    height: "calc(100% - 111px)",
    padding: "30px 50px"
  },
  profileHero: {
    height: "113px"
  },
  profileHeading: {
    fontSize: "22px",
    fontFamily: "Segoe UI",
  },
  profileImgMain: {
    alignSelf: 'center'
  },
  profileImg: {
    height: "150px",
    width: "170px",
    borderRadius: "50%"
  },
  profileImgs: {
    width: "232px",
    height: "232px"
  },
  profileImgpopup: {
    height: "150px",
    width: "150px",
    margin: '30px 0'
  },
  profileImgText: {
    alignSelf: 'center',
    paddingLeft: '35px',
    paddingTop: '15px'
  },
  profileImgTexts: {

  },
  plateformExploreBtn: {
    height: "60px",
    width: "180px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    textTransform: "capitalize",
    marginTop: '30px'
  },
  plateformExploreBtnssAdd: {
    height: "60px",
    width: "124px",
    borderRadius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    marginTop: '30px'
  },
  plateformExploreBtnssAddDisabled: {
    height: "60px",
    width: "124px",
    borderRadius: "4px",
    backgroundColor: 'rgba(60, 170, 73, 0.3)',
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    marginTop: '30px'
  },
  plateformExploreBtns: {
    height: "56px",
    width: "135px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  plateformListingBtn: {
    height: "56px",
    width: "135px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  plateformExploreBtnss: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "15px",
    textTransform: "capitalize",
  },
  plateformListingBtndeleteAccount: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  plateformListingBtns: {
    height: "60px",
    width: "150px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  plateformListingBtnss: {
    height: "60px",
    width: "150px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  imgPre: { width: '100%', display: "flex", flexDirection: "row" },
  popupBtn: {
    height: "54px",
    width: "140px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    textTransform: "capitalize",
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: "pointer"
  },
  validateMessage: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  popupBtnSave: {
    height: "54px",
    width: "140px",
    borderRedius: "4px",
    background: "#FFFFFF",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "1px solid #3CAA49",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  buttonMain: {
    display: 'flex',
    textAlign: 'center',
    margin: '0',
    cursor: 'pointer',
    background: "none"
  },
  hr: {
    border: "none",
    height: "1px",
    backgroundColor: "rgba(203, 213, 225, 1)",
    margin: "30px 0",
  },
  hrs: {
    border: "none",
    height: "1px",
    backgroundColor: "rgba(203, 213, 225, 1)",
    margin: "55px 0",
  },
  formHeading: {
    fontSize: "16px",
    fontFamily: "Segoe UI Bold",
    color: 'rgba(103, 103, 103, 1)',
    paddingBottom: '5px'
  },
  imgPres: { width: '200px', height: "200px", margin: "8px 20px 0px 20px" },
  formInput: {
    width: "580px",
    height: "56px",
    borderRadius: '8px',
    border: '1px solid rgba(203, 213, 225, 0.7)',
    padding: '10px',
    background: 'rgba(255, 255, 255, 1)',
    color: "#676767"
  },
  formInputs: {
    width: "282px",
    height: "56px",
    borderRadius: '8px',
    border: '1px solid rgba(203, 213, 225, 0.7)',
    padding: '10px',
    background: 'rgba(255, 255, 255, 1)',
    color: "#676767"
  },
  formInputsPass: {
    width: "282px",
    height: "56px",
    borderRadius: '8px',
    padding: '10px',
    border: '1px solid rgba(203, 213, 225, 0.7)',
    background: 'rgba(255, 255, 255, 1)',
    color: "#676767",
    marginTop: "3px"
  },
  deleteImg: {
    cursor: 'pointer'
  },
  profileImgse: {
    height: "200px",
    width: "200px",
  },
  leftBoxs: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  deleteImgpopup: {
    height: '30px',
    width: '30px',
    cursor: 'pointer'
  },
  logoutText: {
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: 'rgba(60, 170, 73, 1)',
    width: "200px",
    textAlign: "left",
    textTransform: 'none'
  },
  accountDelete: {
    marginTop: "20px",
    fontSize: "28px",
    fontFamily: "Segoe UI Bold",
    color: '#181818',
    lineHeight: "24px"
  },
  weAccountDelete: {
    fontSize: "20px",
    fontFamily: "Segoe UI",
    color: '#000000',
    lineHeight: "24px"
  },
  modalStyles: {
    fontSize: '12px',
  },
  popupIMGs: {
    display: "flex",
    margin: "0 auto",
  },
  popupIMGMains: {
    margin: "15px 0px 15px 0px",
    display: "flex",
    justifyContent: "center"
  },
  actionsStyles: {
    width: '100%',
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  popuptexts: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  overlays: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contents: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '460px'
  },
  contentss: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '560px',
    borderRadius: '8px',
    height: '400px'
  },
  poupMain: {
    padding: "10px 20px 20px 20px"
  },
  popupHeading: {
    fontSize: '28px',
    fontFamily: "Segoe UI Bold",
    color: '#000000',
  },
  uploadText: {
    fontSize: '20px',
    fontFamily: "Segoe UI",
    color: '#676767',
  },
  uploadTexts: {
    fontSize: '20px',
    fontFamily: "Segoe UI",
    color: '#676767',
    textAlign: 'center'
  },
  uploadTextsPopup: {
    fontSize: '30px',
    fontFamily: "Segoe UI Bold",
    color: '#181818',
    textAlign: 'center',
    paddingTop: "20px"
  },
  goto: {
    fontSize: '16px',
    fontFamily: "Segoe UI",
    color: '#181818',
    textAlign: 'center',
    paddingTop: "20px"
  },
  setting: {
    fontSize: '16px',
    fontFamily: "Segoe UI Bold",
    color: '#3CAA49',
    textAlign: 'center',
    paddingTop: "20px",
    paddingLeft: '10px'
  },
  contentslogout: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '290px',
    padding: '80px 120px 80px 120px',
    width: '100%',
    borderRadius: '8px',
  },
  contentsDelete: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '372px',
    padding: '67px 77px 67px 77px',
    width: '100%',
    borderRadius: '8px',
  },
  contentsRemoveSuccess: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '580px',
    width: '100%',
    borderRadius: '8px',
  },
  popuptextsss: {
    fontSize: '18px',
    fontFamily: "Segoe UI",
    color: '#676767',
    LineHeight: '26px'
  },
  popuptextssslog: {
    fontSize: '18px',
    fontFamily: "Segoe UI",
    color: '#676767',
    LineHeight: '26px',
    margin: 0,
    marginTop: "16px"
  },
  contentsPassword: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '30.5px 43.5px 30.5px 43.5px',
    width: '580px',
    borderRadius: '8px',
    height: '398px'
  },
  overlayslogout: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },

  overlaysDelete: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  deletePopup: {
    marginTop: "85px",

  },
  deletePopuplog: {
    marginTop: "85px",
    paddingLeft: '100px',
  },

  popuptextsDelete: {
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '32px',
    margin: '0',
  },
  popuptextslog: {
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
    color: '#181818',
    lineHeight: '32px',
    margin: 0
  },
  imgCross: {
    position: 'absolute',
    top: 26,
    margin: 'auto',
    right: 36,
    cursor: 'pointer',
  },
  popupIMGMainss: {
    width: '232px',
    heigth: '232px',
    margin: 'auto',
  },
}
// Customizable Area End
