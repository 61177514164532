import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export type LookingSomeDatas = {
  "id": number;
  "type": string;
  "attributes": {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  };
}
import FilterAllCategoryController from "./FilterAllCategoryController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title: string;
  categortText: any;
  isCheckedList: string[];
  lookingForSomethingChecked: boolean;
  sellingSomethingChecked: boolean;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAllCategory: boolean;
  budgetMin: number;
  budgetMax: number;
  budgetMinc: number;
  budgetMaxc: number;
  subCategoryApiDatasAll: string[];
  showBudgetData:boolean,
  showCommissionData:boolean,
  showLookingSomeData:boolean, 
  showSellingSomeData:boolean,
  keyCount: boolean,
  isTypeChanges: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  LookingSomeDatas: LookingSomeDatas[],
  savedData: object,
  savedPopup: boolean,
  savedListings: LookingSomeDatas[];
  savedListingsData: string[];
  removeSavedList: boolean,
  successRemoveSaving: boolean,
  LoaderSavedLooking: boolean;
  deleteItemIds: number,
  deleteItems: object,

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SavedListingLookingController extends FilterAllCategoryController {
  // Customizable Area Start
  apiLookingSomeItemCallId: string = "";
  apiSave_ListingCallId: string = "";
  apiSaveListingItemsCallIdLooking: string = "";
  apideleteSavingLandingLooking: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      LookingSomeDatas: [],
      savedData: {},
      savedPopup: false,
      savedListings: [],
      savedListingsData: [],
      removeSavedList: false,
      successRemoveSaving: false,
      LoaderSavedLooking: true,
      deleteItemIds: 0,
      deleteItems: {},
    } as any;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiLookingSomeItemCallId) {
        this.setState({ LoaderSavedLooking: false })
        this.handleLookingAPiResponse(responseJson);
      }
      else if (apiRequestCallId === this.apiSave_ListingCallId) {
        this.handleSaveListingResponse(responseJson);
      }
      else if (apiRequestCallId === this.apiSaveListingItemsCallIdLooking) {
        this.handleSaveApiResponse(responseJson);
      }
      else if (apiRequestCallId === this.apideleteSavingLandingLooking) {
        this.handleSaveListingDeleteResponseLooking(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.saveListingApiCallLooking()
    this.getJobsDatasSavingsLooking(
      this.props.lookingForSomethingChecked
    )
  }
  saveClosePopups = () => {
    this.setState({ savedPopup: false })
  }
  handleSaveListingResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        savedData: responseJson.message,
        savedPopup: true
      })
      this.saveListingApiCallLooking()
    }
  }
  handleSaveApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      const titles = responseJson?.data?.map((item: any) => item.attributes.listing.data.id);
      this.setState({
        savedListings: responseJson?.data,
        savedListingsData: titles,
      });
    }
  }
  handleSaveListingDeleteResponseLooking(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        deleteItems: responseJson?.message,
        successRemoveSaving: true,
      });
      this.saveListingApiCallLooking()
    }
  }
  navigateSavedSomethings = () => {
    this.setState({ removeSavedList: false })
  }
  popupSaveds = (id: number) => {
    this.setState({ removeSavedList: true, deleteItemIds: id, })
  }
  successpopupSaved = () => {
    this.setState({ successRemoveSaving: false })
  }
  closePopupPassword = () => {
    this.setState({ successRemoveSaving: false });
  };

  handleLookingAPiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        LookingSomeDatas: responseJson.data?.filter((item: { attributes?: { listing_type?: string, category?: string[], sub_category?: string[], budget?: string[], commission?: string[] } }) =>
          item.attributes?.listing_type === "looking_for_something"
        )
      });

    }
  }
  componentDidUpdate(prevProps: any) {

    if (prevProps.subCategoryApiDatasAll != this.props.subCategoryApiDatasAll) {
      this.getJobsDatasSavingsLooking(this.props.subCategoryApiDatasAll, this.props.categortText);
    }
    if (prevProps.budgetMin !== this.props.budgetMin || prevProps.budgetMax !== this.props.budgetMax) {
      this.setState({
        budgetMin: this.props.budgetMin,
        budgetMax: this.props.budgetMax,
      });
      this.getJobsDatasSavingsLooking(
        this.props.budgetMin,
        this.props.budgetMax
      );
    }
    if (prevProps.budgetMinc !== this.props.budgetMinc || prevProps.budgetMaxc !== this.props.budgetMaxc) {
      this.setState({
        budgetMinc: this.props.budgetMinc,
        budgetMaxc: this.props.budgetMaxc,
      });
      this.getJobsDatasSavingsLooking(
        this.props.budgetMinc,
        this.props.budgetMaxc
      );
    }
  }

  viewDataOnHomes(id: number) {
    this.props.navigation.navigate('ViewListing', { id: id })
  }
  savelistLooking = () => {
    localStorage.setItem("LookingForSomething", "FilterAllCategory");
    this.props.navigation.navigate("EmailAccountLoginBlock")
}
  handleSaveListingHome = () => {
    this.props.navigation.navigate("LandingPage")
  }
  handleSaveListings = () => {
    this.props.navigation.navigate("UserProfileBasicBlock", "?tab=savedlist")
  }
  getJobsDatasSavingsLooking = (search?: boolean, categories?: any, subCategories?: string[][], minBudget?: number, maxBudget?: number) => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLookingSomeItemCallId = requestMessage.messageId;

    const searchs = search ? "looking_for_something" : "";
    let url = `bx_block_locallistingads/local_listing_ads`;
    if (searchs) {
      url += `?listing_type=${searchs}`;
    };

    let category = Object.keys(this.props.subCategoryApiDatasAll)[0];

    if (categories) {
      url += `&category=${categories[0]}`;
    }

    if (category !== undefined && category.length > 0) {
      const subCategoryApiDatas = this.props.subCategoryApiDatasAll[categories?.[0]];
      const startIndex = subCategoryApiDatas?.[0].isChecked ? 1 : 0;
      const relevantSubcategories = subCategoryApiDatas?.slice(startIndex);
  
      relevantSubcategories?.forEach((subcategory: any) => {
          if (subcategory.isChecked) {
              url += `&sub_categories[]=${subcategory.id}`;
          }
      });
  }

    if (this.props.budgetMin) {
      url += `&min_budget=${this.props.budgetMin}`;
    }

    if (this.props.budgetMax) {
      url += `&max_budget=${this.props.budgetMax}`;
    }
    if (this.props.budgetMinc) {
      url += `&min_commission=${this.props.budgetMinc}`;
    }

    if (this.props.budgetMaxc) {
      url += `&max_commission=${this.props.budgetMaxc}`;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  saveListingApiCallLooking = () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSaveListingItemsCallIdLooking = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  deleteSavelistingCallMainLooking = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apideleteSavingLandingLooking = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/wishlist_items/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  savedlistApiCallLooking = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSave_ListingCallId = requestMessage.messageId;
    let Body = {
      "data": {
        "local_listing_ad_id": id
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}


