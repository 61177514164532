// Customizable Area Start
import React from "react";
import { Box, Button, Grid, CircularProgress, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import NegotiationSellerToBuyerController from "./NegotiationSellerToBuyerController";
import Popup from 'reactjs-popup';
import CloseIcon from '@material-ui/icons/Close';
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { CustomButton } from "../../../components/src/CustomButton";
import { popImages } from "./assets";
export default class NegotiationSellerToBuyer extends NegotiationSellerToBuyerController {
    render() {
        const theme = createTheme();
        
        return (
            <ThemeProvider theme={theme}>
                <AppHeader navigation={this.props.navigation.navigate} />
                <Box style={StylesNegotiations.containerMain}>
                {this.state.LoaderFormApprovalBuyer ?
                        (<div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                            }}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{ color: '#4fa94d' }} />
                            </Box>
                        </div>) :
                    <Box style={StylesNegotiations.childContainer}>
                        {this.renderHeader()}

                        <hr style={StylesNegotiations.hrs as React.CSSProperties} />
                        <Box style={StylesNegotiations.commonPaddings}>
                            {this.renderBudgetSection()}
                            {this.renderCommissionSection()}
                            <hr style={StylesNegotiations.hrs as React.CSSProperties} />
                            {this.renderCriteriaOrTermsSection()}
                            {this.state.data.preferences && this.renderPreferenceSection()}
                        </Box>
                        {this.renderButtons()}
                        {this.renderPopups()}
                    </Box>
    }
                </Box>
                <AppFooter naviagtion={this.props.navigation.navigate} />
            </ThemeProvider>
        );
    }

    renderHeader() {
        return (
            <Box>
                <Typography style={StylesNegotiations.headings}>
                    {this.state.data.title}
                </Typography>
                <Typography style={StylesNegotiations.userNames}>
                    @{this.state.userDetails}
                </Typography>
            </Box>
        );
    }
    renderDatas() {
        return (
            <>
                {this.state.preferenceError && <Typography style={StylesNegotiations.errorMessages}>{this.state.preferenceError}</Typography>}
            </>
        )
    }

    renderBudgetSection() {
                return (
            <Grid container alignItems="center">
                <Grid item xl={7} lg={7}>
                    <Typography style={StylesNegotiations.budgetss}>{this.state.data.listing_type === "looking_for_something" ? "Budget" : "Price" } <span style={StylesNegotiations.pricess}>£ {this.state.data.budget}</span></Typography>
                </Grid>
                <Grid item xl={5} lg={5}>
                    <Grid container spacing={2} justifyContent="flex-start" style={StylesNegotiations.mr27}>
                        <Grid item>
                            <CustomButton
                                label={this.state.buttonClicked ? "Accepted" : "Accept"}
                                style={this.state.buttonClicked ? StylesNegotiations.acceptButtonOnclicks : StylesNegotiations.acceptButtonss}
                                data-testid="handleAcceptClick"
                                onClick={this.handleAcceptClick}

                            ></CustomButton>
                        </Grid>
                        <Grid item>
                            <CustomButton
                                label="Negotiate"
                                style={this.state.negotiateButton ? StylesNegotiations.negotiateButtonOnClicks : StylesNegotiations.negotiateButtons}
                                data-testid="handleNegotiateClick"
                                onClick={this.handleNegotiateClick}
                            ></CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.showTextArea && (
                    <textarea
                        placeholder="Type here..."
                        style={{
                            ...StylesNegotiations.textAreaStyles,
                            backgroundColor: this.state.negotiatedReason ? '#ECF7ED' : '',
                            color: this.state.negotiatedReason ? '#676767' : '',
                            border: this.state.negotiatedReason ? 'none' : '1px solid #E5E7EB',
                            borderRadius: this.state.negotiatedReason ? '8px' : '',
                        } as React.CSSProperties}
                        value={this.state.negotiatedReason}
                        onChange={(e) => this.handleChangeData(e)}
                        data-testid="handleChangeDatas"
                    />
                )}

                {this.state.budgetError && <Typography style={StylesNegotiations.errorMessages}>{this.state.budgetError}</Typography>}
            </Grid>
        );
    }

    renderCommissionSection() {
        return (
            <Grid container alignItems="center" style={{ marginTop: '20px' }}>
                <Grid item xl={7} lg={7}>
                    <Typography style={StylesNegotiations.budgetss}>Reward <span style={StylesNegotiations.pricess}>£ {this.state.data.commission}</span></Typography>
                </Grid>
                <Grid item xl={5} lg={5}>
                    <Grid container spacing={2} justifyContent="flex-start" style={StylesNegotiations.mr27}>
                        <Grid item>
                            <CustomButton
                                label={this.state.buttonClickedCommission ? "Accepted" : "Accept"}
                                style={this.state.buttonClickedCommission ? StylesNegotiations.acceptButtonOnclicks : StylesNegotiations.acceptButtonss}
                                onClick={this.handleAcceptClickCommission}
                                data-testid="handleAcceptClickCommission"
                            ></CustomButton>
                        </Grid>
                        <Grid item>
                            <CustomButton
                                label="Negotiate"
                                style={this.state.negotiateButtonCommission ? StylesNegotiations.negotiateButtonCommissionOnClicks : StylesNegotiations.negotiateButtonCommissions}
                                onClick={this.handleNegotiateClickCommission}
                                data-testid="handleNegotiateClickCommission"
                            ></CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.showTextAreaCommission && (
                    <textarea
                        placeholder="Type here..."
                        style={{
                            ...StylesNegotiations.textAreaStyles,
                            backgroundColor: this.state.commissionReason ? '#ECF7ED' : '',
                            color: this.state.commissionReason ? '#676767' : '',
                            border: this.state.commissionReason ? 'none' : '1px solid #E5E7EB',
                            borderRadius: this.state.commissionReason ? '8px' : '',
                        } as React.CSSProperties}
                        value={this.state.commissionReason}
                        onChange={(e) => this.handleCommissionReason(e)}
                        onPaste={(e) => {
                            e.preventDefault();
                        }}
                        data-testid="handleCommissionReason"
                    />
                )}
                {this.state.commissionError && <Typography style={StylesNegotiations.errorMessages}>{this.state.commissionError}</Typography>}
            </Grid>
        );
    }

    renderCriteriaOrTermsSection() {
        return (
            <>
                <Typography style={this.state.data.listing_type === "looking_for_something" ? StylesNegotiations.secondHeadings as React.CSSProperties : StylesNegotiations.secondHeadings}>{this.state.data.listing_type === "looking_for_something" ? "Approval Criteria" : "Terms of Purchase"}</Typography>
                <Typography style={StylesNegotiations.secondSecionTexts as React.CSSProperties}>Kindly specify if the product or service you want aligns with the following criteria.</Typography>
                <Grid container spacing={2}>
                    {this.state.criteria_or_terms.map((item: any, index: any) => (
                        <Grid container key={index} style={StylesNegotiations.detailss}>
                            <Grid item xl={12} lg={12} style={{width: "100%"}}>
                                <Typography style={StylesNegotiations.dataTitile as React.CSSProperties}> <span style={{ marginRight: "20px" }}>{index + 1}.</span>{item.title}</Typography>
                            </Grid>
                            <Grid item xl={12} lg={12}>
                                <Grid container spacing={2} justifyContent="flex-end">
                                    <Grid item>
                                        <CustomButton

                                            label={this.state.activeButtons[index] === 'accept' ? "Accepted" : "Accept"}
                                            style={this.state.activeButtons[index] === 'accept' ? StylesNegotiations.acceptButtonOnclicks : StylesNegotiations.acceptButtonss}
                                            onClick={() => this.handleButtonClick(item, index, 'accept')}
                                            data-testid="handleButtonClick"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomButton
                                            label={this.state.activeButtons[index] === "deny" ? "Denied" : "Deny"}
                                            style={this.state.activeButtons[index] === 'deny' ? StylesNegotiations.denyButtonOnClick : StylesNegotiations.denyButton}
                                            onClick={() => this.handleButtonClick(item, index, 'deny')}
                                            data-testid="handleButtonClicks"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomButton
                                            label={this.state.activeButtons[index] === 'amendment' ? "Amendment Requested": "Request Amendment"}
                                            style={this.state.activeButtons[index] === 'amendment' ? StylesNegotiations.requestButtonOnClick : StylesNegotiations.requestButtons}
                                            onClick={() => this.handleButtonClick(item, index, 'amendment')}
                                            data-testid="handleButtonClickss"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {this.state.showTextAreas[index] && (
                                <textarea
                                    placeholder="Type here..."
                                    onChange={(e) => this.handleTextAreaChange(e, index)}
                                    data-testid="criteria_or_termsss"
                                    value={this.state.criteria_or_terms[index].reason}
                                    style={{
                                        ...StylesNegotiations.textAreaStyles,
                                        backgroundColor: this.state.criteria_or_terms[index].reason ? '#ECF7ED' : '',
                                        color: this.state.criteria_or_terms[index].reason ? '#676767' : '1px solid #E5E7EB',
                                        borderRadius: this.state.preferenceReason ? '8px' : '',
                                    } as React.CSSProperties}
                                />
                            )}
                              {this.state.criteriaErrors[index] && <Typography style={StylesNegotiations.errorMessages}>{this.state.criteriaErrors[index]}</Typography>}
                            <hr style={StylesNegotiations.termHrs as React.CSSProperties} />
                        </Grid>

                    ))}
                </Grid>
            </>
        );
    }

    renderPreferenceSection() {
        return (
            <>

                <Box style={StylesNegotiations.preCommonPaddings}>
                    <Typography style={StylesNegotiations.secondHeadings}>Preference</Typography>
                    <Typography style={StylesNegotiations.preSecondSecionTexts as React.CSSProperties}>{this.state.data.preferences}</Typography>
                </Box>
                <Grid container spacing={2} justifyContent="flex-end" >
                    <Grid item>
                        <CustomButton label={this.state.prefrenceButtonCommission ? "Accepted" : "Accept"}
                            style={
                                this.state.prefrenceButtonCommission
                                    ? StylesNegotiations.PreferenceOnClicks
                                    : StylesNegotiations.Preferencess
                            }
                            onClick={this.handlePrefrenceClickCommission
                            }
                            data-testid="handlePrefrenceClickCommission"
                        />
                    </Grid>
                    <Grid item>
                        <CustomButton label={this.state.prefrenceDenyButtonCommission ? "Denied" : "Deny"}
                            style={
                                this.state.prefrenceDenyButtonCommission
                                    ? StylesNegotiations.PreferenceDenyOnClicks
                                    : StylesNegotiations.PreferenceDenyss
                            }
                            onClick={this.handlePrefrenceDenyClickCommission
                            }
                            data-testid="handlePrefrenceDenyClickCommission"
                        />
                    </Grid>
                    {
                        this.state.showTextAreaPrefrence && (
                            <textarea
                                placeholder="Type here..."
                                style={{
                                    ...StylesNegotiations.textAreaStyles,
                                    backgroundColor: this.state.preferenceReason ? '#ECF7ED' : '',
                                    color: this.state.preferenceReason ? '#676767' : '',
                                    border: this.state.preferenceReason ? 'none' : '1px solid #E5E7EB',
                                    borderRadius: this.state.preferenceReason ? '8px' : '',
                                } as React.CSSProperties}
                                value={this.state.preferenceReason}
                                onChange={(e) => this.handlepreferenceReason(e)}
                                data-testid="handlepreferenceReasons"
                            />
                        )
                    }
                </Grid>
                {this.renderDatas()}
            </>

        );
    }

    renderButtons() {
          const criteriaAmendmentsactiveButtons = this.state.activeButtons.length
          const criteriaAmendmentsAttributes = this.state.criteria_or_terms.map((criteria: any) => ({
              criteria_id: criteria.id,
          }));
          const isButtonEnableds = () => {
            if (criteriaAmendmentsAttributes.length === criteriaAmendmentsactiveButtons) {
              return this.state.activeButtons.every((item) => item === "accept");
            } else {
              return false;
            }
          };

        let isTrue = this.state.budgetStatus === "accept" && this.state.commissionStatus === "accept";
        if(this.state.data.preferences){
            isTrue = isTrue && this.state.preferenceStatus === "accept";
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0 80px 0' }}>

                <div style={{ marginRight: '10px' }}>
                    {isTrue && isButtonEnableds()
                            ?
                            <Button
                                variant="contained"
                                disabled={(!this.state.buttonDisabled && !this.isButtonActive()) || this.state.isDisabled}
                                style={
                                    this.state.buttonDisabled || this.state.isDisabled
                                        ? StylesNegotiations.createButtons111
                                        : this.isButtonActive()
                                            ? StylesNegotiations.createButtons
                                            : StylesNegotiations.createButtons111
                                }
                                data-testid="createApiForNegotiate11"

                                onClick={() => {
                                    this.setState({ isFinalize: true },() => {
                                        this.handleSendToReviewClick();
                                    });
                                }}
                            >
                                {
                                    <span style={{ textTransform: 'none' }}
                                    >Finalise Deal</span>
                                }
                            </Button> :
                            <Button
                                variant="contained"
                                disabled={(!this.state.buttonDisabled && !this.isButtonActive()) || this.state.isDisabled}
                                style={
                                    this.state.buttonDisabled || this.state.isDisabled
                                        ? StylesNegotiations.createButtons111
                                        : this.isButtonActive()
                                            ? StylesNegotiations.createButtons
                                            : StylesNegotiations.createButtons111
                                }

                                onClick={() => {
                                    this.setState({ isFinalize: false },() => {
                                        this.handleSendToReviewClick();
                                    });
                                }}
                                data-testid="createApiForNegotiate"
                            >
                                {
                                    <span style={{ textTransform: 'none' }}>Send to Review</span>
                                }
                            </Button>
                    }

                </div>


                {this.state.intialDealsId &&
                <div>
                    <CloseButton
                        variant="contained"
                        onClick={() => { this.openPopup() }}
                        data-testid="openPopup"
                    >
                        <span style={{ textTransform: 'none' }}>Close Negotiation</span>
                    </CloseButton>
                </div>}
            </div>
        );
    }

    renderPopups() {
        return (
            <>
                <div style={StylesNegotiations.actionsStyles as React.CSSProperties}>
                    <Popup
                        open={this.state.showPopupsLogoutNegotiation}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.NegotiationOnClicks}
                        data-testid="NegotiationOnClicks"
                        className="popup"
                        contentStyle={StylesNegotiations.contentsNegotiations}
                        overlayStyle={StylesNegotiations.overlaysNegotiationss}
                    >
                        <div style={StylesNegotiations.deletePopuplogNegotiations as React.CSSProperties}>
                            <p style={StylesNegotiations.popuptextslogNegotiations as React.CSSProperties}>Close Negotiation</p>
                            <div>
                                <p style={StylesNegotiations.popuptextssslogNegotiations as React.CSSProperties}>Are you sure you want to close this negotiation? </p>
                            </div>
                            <Box>
                                <Button style={StylesNegotiations.plateformExploreBtnssNegotiations as React.CSSProperties}
                                    onClick={() => {
                                        this.FromApprovalDataCancelButtonsDelete()
                                    }}
                                    data-testid="NegotiationOnClick"
                                >Close</Button>
                                <Button style={StylesNegotiations.plateformListingBtnsNegotiations as React.CSSProperties}
                                    onClick={this.NegotiationOnClickCancle}
                                    data-testid="NegotiationOnClickCancle"
                                >Cancel</Button>
                            </Box>
                        </div>
                    </Popup>
                </div>
                <div style={StylesNegotiations.actionsStyles as React.CSSProperties}>
                    <Popup
                        open={this.state.popupNegotiate}
                        test-id="popup"
                        closeOnDocumentClick onClose={this.NegotiationOnClicksPopupss}
                        data-testid="NegotiationOnClicksPopup"
                        className="popup"
                        contentStyle={StylesNegotiations.contentsNegotiationss}
                        overlayStyle={StylesNegotiations.overlaysNegotiationss}
                    >
                        <Box style={StylesNegotiations.poupMainNegotiationss}>
                            <Box display="flex" justifyContent="right">
                                <CloseIcon style={StylesNegotiations.close} onClick={this.NegotiationOnClicksPopupss} />
                            </Box>
                            <Box style={StylesNegotiations.leftBoxsNegotiationss as React.CSSProperties}>
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        src={popImages}
                                        style={{ width: '232px', height: '232px', }}
                                    />
                                </div>
                            </Box>
                            <Typography style={StylesNegotiations.uploadTextsPopupNegotiationss as React.CSSProperties}> Sent to Review Successfully!</Typography>
                            <Typography style={{ textAlign: 'center', cursor: "pointer" }} data-testid="setting1" >
                                <span style={StylesNegotiations.gotoNegotiationss as React.CSSProperties}>@{this.state.userDetails} is currently reviewing your negotiations</span>
                            </Typography>
                        </Box>
                    </Popup>
                </div>
            </>
        );
    }

}
// Customizable Area End

// Customizable Area Start
const CloseButton = styled(Button)({
    color: '#3CAA49',
    width: '199px',
    backgroundColor: "#FFF !important",
    height: '56px',
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    border: '1px solid #3CAA49',
    borderRedius: '5px',
    '&.MuiButton-root.Mui-disabled': {
        color:"rgba(0, 0, 0, 0.26)",
        border: '1px solid #BED9C1 !important',
    }
});

const StylesNegotiations = {
    close: {
        color:"gray",
        cursor: "pointer"
    },
    mr27: {
        marginLeft: "27px"
    },
    containerMain: { maxWidth: "1440px",width: "100%", margin: '0 auto' },
    childContainer: {
        padding: "20px 65px"
    },
    commonPaddings: {
        padding: '20px 0'
    },
    preCommonPaddings: {
        padding: '20px 0px',
        paddingTop: '0px'
    },
    headings: {
        fontSize: '32px',
        fontFamily: "Segoe UI Bold",
        color: '#676767',
    },
    userNames: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
    },
    hrs: {
        width: "100%",
        height: "1px",
        backgroundColor: " #E5E7EB",
        border: "none",
        margin: "25px 0"
    },
    termHrs: {
        width: "100%",
        height: "1px",
        backgroundColor: " #E5E7EB",
        border: "none",
        marginTop: "25px"
    },
    budgetss: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
    },
    pricess: {
        fontSize: '32px',
        fontFamily: "Segoe UI Bold",
        color: '#3CAA49',
    },
    acceptButtonss: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    Preferencess: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    PreferenceDenyss: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    PreferenceOnClicks: {
        width: '105px',
        height: '44px',
        border: '1px solid #3CAA49',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#3CAA49',
        textTransform: 'none',
    },
    PreferenceDenyOnClicks: {
        width: '105px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    acceptButtonOnclicks: {
        width: '105px',
        height: '44px',
        border: '1px solid #3CAA49',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#3CAA49',
        textTransform: 'none',
    },
    negotiateButtons: {
        width: '121px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    negotiateButtonOnClicks: {
        width: '121px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    requestButtons: {
        width: '227px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRedius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    textAreaStyles: {
        fontFamily: "Segoe UI",
        fontSize: "20px",
        resize: 'none',
        marginTop: "20px",
        padding: "10px",
        width: "100%",
        height: "92px",
        color: "black",
        border: "1px solid #E5E7EB",
        borderRedius: '8px'
    },
    negotiateButtonCommissions: {
        width: '121px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    negotiateButtonCommissionOnClicks: {
        width: '121px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    secondHeadings: {
        fontSize: '24px',
        fontFamily: "Segoe UI Bold",
        color: '#676767',
    },
    secondSecionTexts: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
        width: "100%",
        minWidth: "90%",
        display: "flex",
        textAlign: "justify",
        margin: 0,
        marginBottom: "20px"
    },
    preSecondSecionTexts: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        width: "100%",
        color: '#676767',
        minWidth: "90%",
        display: "flex",
        textAlign: "justify",
        margin: 0,
    },
    dynamicDatas: {
        justifyContent: 'end'
    },
    detailss: {
        padding: '20px 10px',
        paddingTop: "0px",
        alignItems: 'center'
    },
    Amendments: {
        width: '224px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    AmendmentOnClick: {
        width: '224px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    denyButtonOnClick: {
        width: '105px',
        height: '44px',
        border: '1px solid #FE4023',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#FE4023',
        textTransform: 'none',
    },
    denyButton: {
        width: '105px',
        height: '44px',
        border: '1px solid #B2B2B2',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#B2B2B2',
        textTransform: 'none',
    },
    requestButtonOnClick: {
        width: '227px',
        height: '44px',
        border: '1px solid #0085FF',
        borderRadius: '4px',
        fontSize: '18px',
        fontFamily: "Segoe UI Bold",
        color: '#0085FF',
        textTransform: 'none',
    },
    dataTitile: {
        fontSize: '24px',
        fontFamily: "Segoe UI",
        color: '#676767',
        width: "100%",
        minWidth: "90%",
        display: "flex",
        flexDirection: "columns",
        textAlign: "justify",
        margin: 0,
        marginBottom: "10px"
    },
    createButtons: {
        backgroundColor: '#3CAA49',
        color: '#FFFFFF',
        width: '180px',
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        borderRedius: '5px'
    },
    createButtons111: {
        backgroundColor: 'rgba(60, 170, 73, 0.3)',
        color: '#FFFFFF',
        width: '180px',
        height: '56px',
        fontSize: "14px",
        fontFamily: "Segoe UI Bold",
        textTransfer: "none",
        borderRedius: '5px'
    },
    actionsStyles: {
        width: '100%',
        padding: '10px 5px',
        margin: 'auto',
        textAlign: 'center',
    },
    popuptextsNegotiationss: {
        fontSize: '26px',
        textAlign: "center",
        fontFamily: "Segoe UI Bold",
        color: '#0F172A',
        lineHeight: '28px'
    },
    overlaysNegotiationss: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    contentsNegotiationss: {
        backgroundColor: '#fff',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        maxWidth: '80%',
        padding: '20px',
        width: '540px',
        borderRadius: '8px',
        minHeight: '350px'
    },
    contentsNegotiations: {
        backgroundColor: '#fff',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        maxWidth: '80%',
        padding: '20px',
        width: '526px',
        borderRadius: '8px',
        height: '322px'
    },
    deletePopuplogNegotiations: {
        marginTop: "85px",
        paddingLeft: '50px',
    },
    popuptextslogNegotiations: {
        fontSize: '26px',
        fontFamily: "Segoe UI Bold",
        color: '#0F172A',
        lineHeight: '28px'
    },
    popuptextssslogNegotiations: {
        fontSize: '20px',
        fontFamily: "Segoe UI",
        color: '#181818',
        LineHeight: '24px',
    },
    plateformExploreBtnssNegotiations: {
        height: "56px",
        width: "170px",
        borderRedius: "4px",
        background: "#3CAA49",
        color: "#ECF7ED",
        fontFamily: "Segoe UI Bold",
        fontSize: "20px",
        border: "none",
        marginRight: "15px",
        textTransform: "capitalize",
    },
    plateformListingBtnsNegotiations: {
        height: "60px",
        width: "150px",
        borderRedius: "4px",
        background: "#FFFFFFE5",
        color: "#3CAA49",
        fontFamily: "Segoe UI Bold",
        fontSize: "18px",
        textTransform: "capitalize",
        border: "1px solid #3CAA49",
    },
    poupMainNegotiationss: {
        padding: "10px 20px 20px 20px"
    },
    leftBoxsNegotiationss: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    uploadTextsPopupNegotiationss: {
        fontSize: '30px',
        fontFamily: "Segoe UI Bold",
        color: '#181818',
        textAlign: 'center',
        paddingTop: "20px"
    },
    gotoNegotiationss: {
        fontSize: '18px',
        fontFamily: "Segoe UI",
        color: '#676767',
        textAlign: 'center',
        paddingTop: "20px"
    },
    errorMessages: {
        color: "#FE4023",
        marginTop: "0px",
        fontFamily: "Segoe UI",
        fontSize: "12px",
        lineHeight: "24px",
        letterSpacing: "0em",
    },
}
// Customizable Area End