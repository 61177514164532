// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: string;
  id: string;
}

interface S {
  loading: boolean;
  isSuccess: boolean;
}

interface SS {
  id: string;
}

export default class PaypalAccountController extends BlockComponent<
  Props,
  S,
  SS
> {

  authCodeApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      loading: false,
      isSuccess: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.authCodeApiCallId) {
        if(responseJson && responseJson.message && responseJson.message === "Paypal email added successfully."){
          this.setState({ isSuccess: true });
        } else {
          this.setState({ isSuccess: false });
        }
        this.setState({ loading: false });
      }
    }
  }

  async componentDidMount() {
    this.sendAuthCode();
  }

  sendAuthCode = async() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (!code) {
      this.setState({ isSuccess: false });
      return;
    }
    this.setState({ loading: true });
    const header = JSON.stringify({
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.authCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_paypal/callback?code=${code}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End
