//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Box, TextField, Typography, Button, CircularProgress,styled } from "@material-ui/core";
import {
 
  image_Green,
  profileImg
} from "./assets";
const HeadingText = styled(Typography)({
  fontFamily: "Segoe UI Bold",
  color: "#000000",
  fontWeight: 700,
  fontSize: "32px",
  marginBottom: "39px",
  // lineHeight:"24px",
  "@media(max-width:767px)": {
      fontSize: "28px",
  },
  "@media(max-width:450px)": {
      fontSize: "20px",
  },
});

const ProductImageData = styled("img")({
  width: "169px",
  height: "169px",
  objectFit: "contain",
  "@media(max-width:767px)": {
    width: "120px",
    height:"120px"
},
"@media(max-width:450px)": {
  width: "100px",
  height:"100px"
},
});

const TitleText = styled(Typography)({
  fontFamily: "Segoe UI Bold",
      color: "rgba(0, 0, 0, 1)",
      fontWeight: 700,
      fontSize: "32px",
      textTransform: "capitalize",
  "@media(max-width:767px)": {
      fontSize: "24px",
  },
  "@media(max-width:450px)": {
      fontSize: "20px",
  },
});

const CategoryText = styled(Typography)({
  fontFamily: "Segoe UI",
  color:"#676767",
  fontWeight: 400,
  fontSize: "24px",
  textTransform: "capitalize",
  paddingRight:"2rem",
  wordWrap:"break-word",
  "@media(max-width:767px)": {
      fontSize: "18px",
  },
  "@media(max-width:450px)": {
      fontSize: "16px",
  },
});
const SpanCategoryText = styled("span")({
  fontFamily: "Segoe UI",
  color:"#676767",
  fontWeight: 400,
  fontSize: "24px",
  textTransform: "capitalize",
  paddingRight:"2rem",
  wordWrap:"break-word",
  "@media(max-width:767px)": {
      fontSize: "18px",
  },
  "@media(max-width:450px)": {
      fontSize: "16px",
  },
});

const LabelCategoryText = styled(Typography)({
  fontFamily: "Segoe UI Bold",
  color: "rgba(60, 170, 73, 1)",
  fontWeight: 700,
  fontSize: "24px",
  "@media(max-width:767px)": {
      fontSize: "18px",
  },
  "@media(max-width:450px)": {
      fontSize: "16px",
  },
});

const SelerbuyerText = styled(Typography)({
  fontFamily: "Segoe UI",
      color: "rgba(103, 103, 103, 1)",
      fontWeight: 400,
      fontSize: "24px",
      fontStyle: "italic",
      paddingBottom:"24px",
  "@media(max-width:767px)": {
      fontSize: "20px",
  },
  "@media(max-width:450px)": {
      fontSize: "18px",
  },
});

const TermsText = styled(Typography)({
  fontFamily: "Segoe UI",
  color: "#67676799",
  fontWeight: 400,
  fontSize: "24px",
  fontStyle: "italic",
  paddingTop:"24px",
  "@media(max-width:767px)": {
      fontSize: "20px",
  },
  "@media(max-width:450px)": {
      fontSize: "18px",
  },
});

const TermsPurchaseText = styled(Typography)({
  fontFamily: "Segoe UI",
  color: "rgba(103, 103, 103, 1)",
  fontWeight: 400,
  fontSize: "24px",
  margin: "24px 0px",
  paddingLeft: "0.5em",
  "@media(max-width:767px)": {
      fontSize: "18px",
  },
  "@media(max-width:450px)": {
      fontSize: "16px",
  },
});

const CheckImage = styled("img")({
  width: "32px",
  height: "32px",
  marginLeft:"64px",
  "@media(max-width:767px)": {
    width: "24px",
    height:"24px",
    marginLeft:"32px",
},
"@media(max-width:450px)": {
  width: "18px",
  height:"18px",
  marginLeft:"20px",
},
});

const TableText = styled(Typography)({
  fontFamily: "Segoe UI Bold",
  color: "#676767",
  fontWeight: 700,
  fontSize: "24px",
  textTransform: "capitalize",
  textAlign:"start",
  "@media(max-width:850px)": {
      fontSize: "20px",
  },
  "@media(max-width:600px)": {
      fontSize: "18px",
  },
});

const BillText = styled(Typography)({
  fontFamily: "Segoe UI",
      color:"#676767",
      fontWeight: 400,
      textTransform: "capitalize",
      wordWrap:"break-word",
      fontSize: "24px",
  "@media(max-width:767px)": {
      fontSize: "18px",
  },
  "@media(max-width:450px)": {
      fontSize: "16px",
  },
});
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";
import { Close } from "@material-ui/icons";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderUserNameDatas1() {
    return (
        <Box sx={{ display: "flex" }}>
            <CircularProgress style={{ color: "#4fa94d" }} />
        </Box>
    );
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { billingInvoiceData } = this.state;
    const sellerphoto =billingInvoiceData?.attributes?.seller_profile_detail?.photo
    const buyerPhoto =billingInvoiceData?.attributes?.buyer_profile_detail?.photo
    return (
      <>
        <Box width="100%" >
          {this.state.LoaderOtherUser ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              {this.renderUserNameDatas1()}
            </div>
          ) : (
            <Box style={webStyle.mainWrapper}>
              <div id="pdf-content" ref={this.scrollViewRef} >
                <Box style={{display:"flex",justifyContent:"space-between"}}>
                  <HeadingText >
                    {`Invoice (${billingInvoiceData?.attributes?.invoice_number})`}
                  </HeadingText>
                  <Close  style={{cursor:"pointer"}}onClick={()=>this.handleBack(billingInvoiceData?.id)}/>
                  {/* <Box>X</Box> */}
                </Box>
                <hr style={webStyle.hr} />
                <Box>
                  <Box style={webStyle.bodyBox}>
                    <ProductImageData alt="invoiceImg" src={billingInvoiceData?.attributes?.listing?.image[0]}  />
                    <Box style={{ marginLeft: "24px", maxWidth: "calc(100% - 169px)" }}>
                      <TitleText>
                        {billingInvoiceData?.attributes?.listing?.title}
                      </TitleText>
                      <CategoryText >{billingInvoiceData?.attributes?.listing?.category}</CategoryText>
                      <CategoryText style={{marginTop:"1rem"}}>
                        {billingInvoiceData?.attributes?.listing?.description}
                      </CategoryText>
                    </Box>
                  </Box>
                  <hr style={webStyle.hr} />

                  <Box style={{ margin: "39px 0px 39px 0px" }}>
                    <LabelCategoryText >
                      Order ID :{" "}
                      <SpanCategoryText style={webStyle.bodyStyle as React.CSSProperties}>{billingInvoiceData?.attributes?.listing?.order_id}</SpanCategoryText>
                    </LabelCategoryText>
                    <LabelCategoryText >
                      Date :{" "}
                      <SpanCategoryText style={webStyle.bodyStyle as React.CSSProperties}>{this.timeFormat()}</SpanCategoryText>
                    </LabelCategoryText>
                    <LabelCategoryText >
                      Invoice Number :{" "}
                      <SpanCategoryText style={webStyle.bodyStyle as React.CSSProperties}>{billingInvoiceData?.attributes?.invoice_number}</SpanCategoryText>
                    </LabelCategoryText>
                  </Box>
                  <hr style={webStyle.hr} />

                  <div style={webStyle.backgroundBodyBox}>
                    <SelerbuyerText >
                      Seller Details -
                    </SelerbuyerText>
                    <Box style={webStyle.profileBoxStyle}>
                      <Box style={{ display: "flex", alignItems: "center", gap: '10px' }}>
                        <img alt="sellerprofile" src={sellerphoto != null ?sellerphoto:profileImg} style={webStyle.profileBoxStyle.profileImg} />
                        <LabelCategoryText >
                          {billingInvoiceData?.attributes?.seller_profile_detail?.full_name}
                        </LabelCategoryText>
                        <SpanCategoryText style={webStyle.bodyStyle as React.CSSProperties}>{`/@${billingInvoiceData?.attributes?.seller_profile_detail?.user_name}`}</SpanCategoryText>
                      </Box>
                      <Typography style={webStyle.bodyStyle1 as React.CSSProperties}>
                        {`${billingInvoiceData?.attributes?.seller_profile_detail?.country !== null ? billingInvoiceData?.attributes?.seller_profile_detail.country : ''}, ${billingInvoiceData?.attributes?.seller_profile_detail?.city !== null ? billingInvoiceData?.attributes?.seller_profile_detail.city : ''}`.trim()
}
                      </Typography>

                    </Box>
{billingInvoiceData?.attributes?.listing.listing_type =="selling_for_something"?
                    <Box>
                      <TermsText >
                        Terms of Purchase 
                      </TermsText>
                      {billingInvoiceData?.attributes?.term_of_purchases.map((item: any, index: number) => (
                        <Box key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "0.8px solid #E5E7EB" }}>
                          <TermsPurchaseText
                          >
                            <span>{index + 1}.</span> {item?.term}
                          </TermsPurchaseText>
                          <CheckImage alt="image_Green" src={image_Green}  />
                        </Box>

                      ))}
                    </Box>:null}
                  </div>
                  <div
                    style={webStyle.backgroundBodyBox}
                  >
                    <SelerbuyerText >

                      Buyer Details -
                    </SelerbuyerText>

                    <Box style={webStyle.profileBoxStyle}>
                      <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <img alt="buyerprofile" src={buyerPhoto != null ?buyerPhoto:profileImg} style={webStyle.profileBoxStyle.profileImg} />
                        <LabelCategoryText >
                          {billingInvoiceData?.attributes?.buyer_profile_detail?.full_name}
                        </LabelCategoryText>
                        <SpanCategoryText style={webStyle.bodyStyle as React.CSSProperties}>{`/@${billingInvoiceData?.attributes?.buyer_profile_detail?.user_name}`}</SpanCategoryText>
                      </Box>
                      <Typography style={webStyle.bodyStyle1 as React.CSSProperties}>
                      {`${billingInvoiceData?.attributes?.buyer_profile_detail?.country !== null ? billingInvoiceData?.attributes?.buyer_profile_detail.country : ''}, ${billingInvoiceData?.attributes?.buyer_profile_detail?.city !== null ? billingInvoiceData?.attributes?.buyer_profile_detail.city : ''}`.trim()
  }
                      </Typography>

                    </Box>
                    {billingInvoiceData?.attributes?.listing.listing_type =="looking_for_something"?
                    <Box>
                      <TermsText >
                        {billingInvoiceData?.attributes?.listing.listing_type === "looking_for_something" ? "Approval Criteria" : "Terms of Purchase"}
                      </TermsText>
                      {billingInvoiceData?.attributes?.term_of_purchases.map((item: any, index: number) => (
                        <Box key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "0.8px solid #E5E7EB" }}>
                          <TermsPurchaseText
                          >
                            <span>{index + 1}.</span> {item?.term}
                          </TermsPurchaseText>
                          <CheckImage alt="image_Green" src={image_Green}  />
                        </Box>

                      ))}
                    </Box>:null}
                  </div>
                  <hr style={webStyle.hr} />

                  <Box style={{ margin: "39px 0px" }}>
                    <TableText >
                    £ {`${billingInvoiceData?.attributes?.listing?.commission} payment done on ${this.timeFormat()}`}  </TableText>
                  </Box>
                  <hr style={webStyle.hr} />

                  <table style={{ width: '50%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderBottom: '0.3px solid #000000' }}>
                        <th style={{ width: "50%", padding: "20px 0px" }} > <TableText >
                          Description
                        </TableText></th>
                        <th style={{ width: "25%", padding: "20px 0px" }} >  <TableText >
                          {billingInvoiceData?.attributes?.listing?.listing_type === "looking_for_something" ? "Budget" : "Price"}
                        </TableText></th>
                        <th style={{ width: "25%", padding: "20px 0px" }} > <TableText>
                          Reward
                        </TableText></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'start', padding: "20px 0px" }}> <TableText >
                          {billingInvoiceData?.attributes?.listing?.title}
                        </TableText></td>
                        <td style={{ textAlign: 'start', padding: "20px 0px" }}> <BillText >
                        £ {billingInvoiceData?.attributes?.listing?.budget}{" "}
                        </BillText></td>
                        <td style={{ textAlign: 'start', padding: "20px 0px" }}> <BillText >
                        £ {billingInvoiceData?.attributes?.listing?.commission}
                        </BillText></td>
                      </tr>

                    </tbody>

                  </table>
                </Box>
              </div>
              <Box>
                <Button
                  onClick={this.downloadPdf}
                  style={webStyle.invoiceButtonStyle as React.CSSProperties}
                >
                  Download Invoice
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
      padding: "72px",
      background: "#FCFBFB",
  },
  headerStyle: {
      fontFamily: "Segoe UI Bold",
      color: "#000000",
      fontWeight: 700,
      fontSize: "32px",
      marginBottom: "39px",
      lineHeight:"24px"
  },
  bodyBox: {
      display: "flex",
      margin: "39px 0px 39px 0px",
      maxWidth:"100%"
  },
  bodyHeaderStyle: {
      fontFamily: "Segoe UI Bold",
      color: "rgba(0, 0, 0, 1)",
      fontWeight: 700,
      fontSize: "32px",
      textTransform: "capitalize",
  },
  bodyStyle: {
      fontFamily: "Segoe UI",
      color:"#676767",
      fontWeight: 400,
      textTransform: "capitalize",
      paddingRight:"2rem",
      wordWrap:"break-word"
  },

  bodyStyle1: {
    fontFamily: "Segoe UI",
    color:"#676767",
    fontWeight: 400,
    textTransform: "capitalize",
    paddingRight:"2rem",
    wordWrap:"break-word",
    marginTop:"4px"
},

  bodylabelStyle: {
      fontFamily: "Segoe UI Bold",
      color: "rgba(60, 170, 73, 1)",
      fontWeight: 700,
      fontSize: "24px",
  },
  bodyTitleStyle: {
      fontFamily: "Segoe UI",
      color: "rgba(103, 103, 103, 1)",
      fontWeight: 400,
      fontSize: "24px",
      fontStyle: "italic",
      paddingBottom:"24px"
  },
  bodyTitleStyle1: {
      fontFamily: "Segoe UI",
      color: "#67676799",
      fontWeight: 400,
      fontSize: "24px",
      fontStyle: "italic",
      paddingTop:"24px"

  },
  bodyTextStyle: {
      fontFamily: "Segoe UI Bold",
      color: "#676767",
      fontWeight: 700,
      fontSize: "24px",
      textTransform: "capitalize",
      textAlign:"start"
  },
 
  profileBoxStyle: {
      background: "rgb(236, 247, 237)",
      maxWidth: "439px",
      borderRadius: "8px",
      padding: "12px 36px 12px 36px",
      profileImg: {
          width: "52px",
          height: "52px",
          borderRadius: "31px",
      },
  },
  invoiceButtonStyle: {
      fontFamily: "Segoe UI Bold",
      color: "rgba(60, 170, 73, 1)",
      fontWeight: 700,
      fontSize: "20px",
      textTransform: "none",
      border: "1px solid rgb(60, 170, 73)",
      width: "207px",
      height: "56px",
      borderRadius: "4px",
      padding: "14px 115px 14px 115px",
      whiteSpace: "nowrap",
  },
  image: {
      width: "169px",
      height: "169px",
  },
  hr: {
      border: "none",
      height: "0.8px",
      margin: "0px",
      width: "98%",
      backgroundColor: "rgba(229, 231, 235, 1)",
  },
  backgroundBodyBox: {
      background: "rgb(255, 255, 255)",
      borderRadius: "8px",
      border: "0.3px solid rgba(178, 178, 178, 0.3)",
      margin: "39px 0px 39px 0px",
      padding: "24px 32px 24px 32px",
  },
};
// Customizable Area End
