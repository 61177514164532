// Customizable Area Start
import React from "react";
import {
  Box,
  CircularProgress,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import VerifyAccountController from "./VerifyAccountController.web";

export default class VerifyAccount extends VerifyAccountController {
  render() {
    return (
      <Wrapper>
        {this.state.loading ?
          <Box className="loader">
            <CircularProgress className="color" />
          </Box> :
          <>
            {this.state.status === "activate" &&
              <Box className="center">
                <VerifiedUserOutlinedIcon className="success" />
                <Box className="heading green">
                  Verified !
                </Box>
                <Box className="msg mb0">Your account verified successfully.</Box>
                <p className="text">
                  <Box
                    className="button"
                    data-test-id="login-btn"
                    onClick={() => this.navigateToLogin()}
                  >
                    <p className="info">Login</p>
                  </Box>
                  <span>to your account</span>
                </p>
              </Box>
            }
            {this.state.status === "alreadyActivated" &&
              <Box className="center">
                <CheckCircleOutlineOutlinedIcon className="success" />
                <Box className="heading green">
                  Activated !
                </Box>
                <Box className="msg mb0">Your account is already Activated.</Box>
                <p className="text">
                  <Box
                    className="button"
                    data-test-id="login-btn"
                    onClick={() => this.navigateToLogin()}
                  >
                    <p className="info">Login</p>
                  </Box>
                  <span>to your account</span>
                </p>
              </Box>
            }
            {this.state.status === "notActivate" &&
              <Box className="center">
                <CancelOutlinedIcon className="fail" />
                <Box className="heading red">
                  Failed !
                </Box>
                <Box className="msg">Oops, something went wrong, please try again.</Box>
              </Box>
            }
            {this.state.status === "expired" &&
              <Box className="center">
                <SentimentDissatisfiedOutlinedIcon className="fail" />
                <Box className="heading red">
                  Expired !
                </Box>
                <Box className="msg mb0">
                  <Box mb="5px">Your account activation link has been expired.</Box>
                  <Box>Please
                    <Box
                      className="button inline"
                      data-test-id="login-btn"
                      onClick={() => this.navigateToLogin()}
                    >
                      <p className="info">Login</p>
                    </Box>
                    again to receive a new link.
                  </Box>
                </Box>
              </Box>
            }
          </>
        }
      </Wrapper>
    );
  }
}

const Wrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  background: "rgb(247, 245, 245)",
  "& .loader": {
    "& .color": {
      color: '#4fa94d'
    }
  },
  "& .center": {
    width: "100%",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    background: "#fff",
    padding: "40px 10px",
    margin: "0px 60px",
    borderRadius: "10px",
    "& .heading": {
      marginTop: "5px",
      display: "flex",
      alignItems: "center",
      fontSize: "30px",
      fontFamily: "Segoe UI Bold",
    },
    "& .green": {
      color: "#2bb32b",
    },
    "& .red": {
      color: "#f94b4b",
    }
  },
  "& .success": {
    fontFamily: "Segoe UI Bold",
    fontSize: "70px",
    color: "#2bb32b"
  },
  "& .fail": {
    marginRight: "11px",
    fontFamily: "Segoe UI Bold",
    fontSize: "70px",
    color: "#f94b4b"
  },
  "& .text": {
    margin: "0px",
    display: "flex",
    fontSize: "17px",
    marginTop: "10px",
    textAlign: "center",
    alignItems: "center",
    fontFamily: "Segoe UI",
  },
  "& .msg": {
    fontSize: "18px",
    textAlign: "center",
    fontFamily: "Segoe UI",
    margin: "15px 10px 24px 10px",
  },
  "& .mb0": {
    marginBottom: "0px"
  },
  "& .button": {
    cursor: "pointer",
    fontFamily: "Segoe UI",
    padding: "0px",
    fontWeight: 700,
    fontSize: "18px",
    color: "#3CAA49",
    marginRight: "5px"
  },
  "& .info": {
    margin: "0px",
    fontFamily: "Segoe UI bold ",
    borderBottom: "1px solid #3CAA49",
  },
  "& .inline": {
    marginLeft: "5px",
    display: "inline-block",
  }
});
// Customizable Area End