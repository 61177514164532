// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Typography,
  CircularProgress,
  Card,
  Grid,
} from "@material-ui/core";
import { TiStar } from "react-icons/ti";

import MyReviewsController from "./MyReviewsController.web";
import moment from "moment";
import { ReviewEmpty, profiles } from "./assets";
import { Rating } from "@material-ui/lab";

const CardImage = styled("img")({
  objectFit: "cover",
  "@media(max-width:1150px)": {
    width: "100%",
  },
});

interface FeedbackItem {
  id: string;
  attributes: {
    user: {
          name: string;
          image: string;
        };
    description: string;
    rating: number;
    listing: {
          image: [string]
          title: string;
        };
    created_at: string; // Assuming it's a date string
    updated_at?: string; // Assuming it's a date string
  };
}
const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '350px',
  maxWidth: 'auto',
  margin: '30 auto 10 auto',
});
const HeadingText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  fontSize: '22px',
  fontFamily: 'Segoe UI',
  lineHeight: '20px',
  color: '#3CAA49',
});
const HeadingTextMain = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '10 auto',
  fontSize: '18px',
  fontFamily: 'Segoe UI',
  lineHeight: '20px',
  color: '#676767',
});

const Loader = styled(Box)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "rgb(247, 245, 245)"
});

export default class MyReviewsWeb extends MyReviewsController {



  
  ReviewCard() {
    return (
      <Box style={{ background: "#f7f5f5", padding: 50,minHeight:'100vh',  }}>
        <Grid
          container
          spacing={3}
          className="box_main"
          style={{ display: "flex", gap: "30px" }}
        >
          {this.state.feedbackList.map((item: FeedbackItem, index: number) => (
            <>
              <Card
                className="review_card"
                data-testId="handleCLickCard"
                style={{
                  padding: 18,
                  // minWidth: 275,
                  maxWidth:"33%",
                  margin: 2,
                  borderRadius: "10px",
                  width: "25%",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  cursor:"pointer"
                }}
                data-test-id={`card-${item.id}`}
                onClick={() => this.handleCLick(item.id)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap:"10%"
                  }}
                >
                  <div style={{ width: "20%" }}>
                    {" "}
                    <CardImage
                      style={{ width: 60, height: 60, objectFit: "cover" }}
                      src={item.attributes.user.image == null ? profiles : item.attributes.user.image}
                    />{" "}
                  </div>
                  <div style={{ width: "80%" }}>
                    {" "}
                    <Typography
                      style={{
                        fontSize: "16px",
                        paddingLeft:"15px",
                        fontFamily: "Segoe UI Bold",
                        maxWidth: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textTransform: "uppercase",
                      }}
                      className="review_title"
                    >
                      {item.attributes.user.name}
                    </Typography>
                  </div>
                </div>
                <p style={{ fontFamily: "Segoe UI", fontSize: "15px",color:"#676767" }}  data-test-id={`show-${item.id}`}>
                  { this.state.showMore[item.id]
                    ? item.attributes.description
                    : `${item.attributes.description
                        .split(" ")
                        .slice(0, 25)
                        .join(" ")}...`}
                  {item.attributes.description.split(" ").length > 25 && (
                    <span
                      style={{ color: "#3CAA49", cursor: "pointer",fontSize: "15px" }}
                      onClick={()=>this.toggleShowMore(item.id)}
                      data-test-id={`show1-${item.id}`}
                    >
                     
                      {this.state.showMore[item.id]? " See less" : " See more"}
                    </span>
                  )}
                </p>
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid #e3e4e6",
                    borderBottom: "1px solid #e3e4e6",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-5",
                    gap:"3%",
                  cursor:"pointer"
                  }}
                 
                  data-test-id={`showmore-${item.id}`}
                  onClick={() => this.handleCLick(item.id)}
                >
                  <div style={{ width: "25%" }}>
                    <CardImage
                      className="product_image"
                      style={{
                        maxWidth: "100%",
                       
                        objectFit: "contain",
                      }}
                      src={item.attributes.listing.image[0]}
                    />
                  </div>
                  <div style={{paddingLeft:'18px', width: "75%" }}>
                    <Typography className="product_name"
                      style={{fontFamily: "Segoe UI Bold",
                        fontSize: "16px", color:'#676767' }}
                    >
                      {item.attributes.listing.title}
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "-5",
                  }}
                >
 <Rating name="read-only" value={item.attributes.rating} readOnly size="small" precision={0.25}/>
                
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "#B2B2B2",
                  }}
                >
                  {
                 `${moment(item.attributes.created_at ?? item.attributes.updated_at).format("Do MMMM YYYY, HH:mm")}`
              }
                </div>
              </Card>
            </>
          ))}
          {this.state.feedbackList.length == 0 && !this.state.LoaderListing ? (
            <Box style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%"}}>
            <Container>
              <img src={ReviewEmpty} alt="Logo" />
            </Container>
            <div>
              <HeadingText>
              No reviews yet
              </HeadingText>
              <HeadingTextMain>
              Seems like you haven’t received any review yet
              </HeadingTextMain>
            
            </div>
          </Box>
          ) : null}
        </Grid>
      </Box>
    );
  }

  render() {
    const { LoaderListing } = this.state;

    if (LoaderListing) {
      return (
        <Loader>
          <Box>
            <CircularProgress style={{ color: "#4fa94d" }} />
          </Box>
        </Loader>
      );
    }

    return <> {this.ReviewCard()}</>;
  }
}
// Customizable Area End
