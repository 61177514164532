import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
interface Listing {
    listing_type: string;
    title: string;
    description: string;
    budget: string;
    commission: string;
    category: string;
    order_id: string;
    image: string[];
  }
  
  interface Attributes {
    id: string;
    created_at: string;
    updated_at: string;
    listing: Listing;
    status:string
  }
  
  interface Data {
    id: string;
    type: string;
    attributes: Attributes;
  }
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  // Customizable Area Start
}



export interface S {
  // Customizable Area Start

  LoaderListing: boolean;
  feedbackList:Data[];
  currentPage:number
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderHistoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  OrderHIstoryApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
    
      feedbackList:[{
        id: "",
        type: "",
        attributes: {
                    id:"",
                    created_at: "",
                   updated_at: "",
                   status:"",
                    listing:{
                       listing_type: "",
    
                        title: "",
        description: "",
        budget: "",
        commission: "",
        category: "",
        order_id: "",
        image: []
      }
      }
      }],
      currentPage:1,
      LoaderListing:true
      // Customizable Area End
    };

    // Customizable Area Start
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.OrderHIstoryApiCallId) {
        if(responseJson !== undefined){
            this.setState({ LoaderListing: false, feedbackList:responseJson.data });
      
      }}
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getOrderHistoryData();
    window.scrollTo(0, 0);
  }

 
  getOrderHistoryData = async () => {
    const token = await getStorageData("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.OrderHIstoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderHistoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  
  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({currentPage:page});
  };

  handleNavigate= ( orderId?: string) => {

    const navigateMessage: Message = new Message(
        getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "RefundManagement"
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );

    const navigateToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationRefundManagementMessage)
    );
    navigateToLoginMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    
    
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
        raiseMessage.addData(getName(MessageEnum.InfoPageBodyMessage), orderId);
        navigateMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
    
    
    this.send(navigateMessage)
      }

  getCurrentResults = () => {
    const resultsPerPage = 3; // You can adjust this value as needed
    const startIndex = (this.state.currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    return this.state.feedbackList.slice(startIndex, endIndex);
  }

  getTotalPage=()=>{
    const totalPage = Math.ceil(this.state.feedbackList.length / 3)
    return totalPage
  }

 
  handleCLick=(id:string)=>{
    this.props.navigation.navigate("OrderDetails",{id:id})
  }






  // Customizable Area End
}
