import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import FilterAllCategoryController from "./FilterAllCategoryController";
import { getStorageData } from "../../../framework/src/Utilities";
export type LookingSomeDatasStore = {
  "id": number;
  "type": string;
  "attributes": {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  }
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title: string;
  lookingForSomethingChecked: boolean;
  sellingSomethingChecked: boolean;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  };
  categortText: number[];
  isCheckedList: string[];
  subCategoryApiDatasAll: any;
  budgetMin: number;
  budgetMax: number;
  budgetMinc: number;
  budgetMaxc: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showBudgetData:boolean;
  showCommissionData:any;
  showLookingSomeData:boolean;
  showSellingSomeData:boolean
  keyCount: boolean;
  isTypeChanges: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  savedListingsAll: LookingSomeDatasStore[];
  AllDataListing: LookingSomeDatasStore[];
  queryAll: LookingSomeDatasStore[];
  removeSavedList: boolean;
  savedPopup: boolean,
  savedData: object,
  successRemoveSavingAll: boolean,
  LoaderListingData: boolean;
  showAllDataFilter: boolean;
  showAllSellingSomethingAll: boolean;
  savedListingsDataAll: string[];
  deleteItemsAll: object,
  deleteItemIdsAll: number,
  searchText: string,
  LookingSomeDatasAll: any;
  someListingSaveAll: any;
  savedPopupsAll: boolean,
  savedPopupLandingAll: boolean,
  LoaderListingAll: boolean,

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AllListingsControllers  extends FilterAllCategoryController {
  // Customizable Area Start
  apiLookingSomeItemCallIds: string = "";
  apiSave_ListingCallIds: string = "";
  apiSaveListingItemsCallIdLookings: string = "";
  apideleteSavingLandingCallIdMainAll: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      removeSavedList: false,
      savedData: {},
      savedPopup: false,
      someListingSaveAll: [],
      LookingSomeDatasAll: [],
      LoaderListingData: true,
      showAllDataFilter: false,
      showAllSellingSomethingAll: false,
      savedListingsDataAll: [],
      successRemoveSavingAll: false,
      savedListingsAll: [],
      AllDataListing: [],
      queryAll: [],
      deleteItemsAll: {},
      deleteItemIdsAll: 0,
      searchText:"",
      savedPopupsAll: false,
      savedPopupLandingAll: false,
      LoaderListingAll: true,
     
      // Customizable Area End
    } as any;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        
          if (apiRequestCallId === this.apiLookingSomeItemCallIds) {
            this.setState({LoaderListingData: false})
            this.handleLookingAPiResponseAll(responseJson);
          }
          else if (apiRequestCallId === this.apiSave_ListingCallIds) {
            this.handleSaveListingResponseData(responseJson);
          }
          else if (apiRequestCallId === this.apiSaveListingItemsCallIdLookings) {
            this.handleSaveApiResponses(responseJson);
          }
          else if (apiRequestCallId === this.apideleteSavingLandingCallIdMainAll) {
            this.handleSaveListingDeleteResponseData(responseJson);
          }
        }
    }

    // Customizable Area End
  }

  // Customizable Area Start



  componentDidMount(): any {
    this.getJobsDatasSavingsLookingss(this.props.categortText);
    this.saveListingApiCallLookingAllItem()
  }
  navigateComponent = (id: number) => {
    this.props.navigation.navigate('LookingSomethings', { id: id })
  }
  handleListingAddAll = () => {
    localStorage.setItem("LookingForSomething", "FilterAllCategory");
    this.props.navigation.navigate("EmailAccountLoginBlock")
}
  navigateComponentSomething = (id: number) => {
    this.props.navigation.navigate('SellingSomethings', { id: id })
  }
  handleSaveListingssAll = () => {
    this.props.navigation.navigate("UserProfileBasicBlock", "?tab=savedlist")
  }
  saveClosePopupss = () => {
    this.setState({ savedPopupsAll: false })
  }
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleLookingAPiResponseAll(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
       LookingSomeDatasAll: responseJson.data?.filter((item: { attributes?: { listing_type?: string, category?: string[], sub_category?: string[] , budget?: string[], commission?: string[] } }) => item.attributes?.listing_type === "looking_for_something"),
        someListingSaveAll:  responseJson.data?.filter((item: { attributes?: {listing_type?: string, category?: string[], sub_category?: string[] , budget?: string[], commission?: string[] } }) => item.attributes?.listing_type !== "looking_for_something"),
        AllDataListing: responseJson.data
      })
    }
  }
  handleSaveListingResponseData(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        savedData: responseJson.message,
        savedPopupLandingAll: true,
        savedPopup: true
      })
      this.saveListingApiCallLookingAllItem()
    }
  }
  handleSaveListingDeleteResponseData(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        deleteItemsAll: responseJson?.message,
        successRemoveSavingAll: true,
      });
      this.saveListingApiCallLookingAllItem()
    }
  }
  handleSaveApiResponses(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      const titles = responseJson.data?.map((item: any) => item.attributes.listing.data.id);
      this.setState({
        savedListingsAll: responseJson?.data,
        savedListingsDataAll: titles,
      });
    }
  }
  viewData(id: number) {
    this.props.navigation.navigate('ViewListing', { id: id })
  }
  saveLooking() {
    this.setState({ showAllDataFilter: true })
  }
  popupSaveds = (id: number) => {
    this.setState({ removeSavedList: true, deleteItemIdsAll: id, })
  }
  saveClosePopupsAll = () => {
    this.setState({ savedPopupLandingAll: false })
  }
  saveSellingAll() {
    this.setState({ showAllSellingSomethingAll: true })
  }
  successpopupSavedLandingAll = (id: any) => {
    this.setState({ successRemoveSavingAll: false })
  }
  closePopupPasswordLandingAll = () => {
    this.setState({ successRemoveSavingAll: false });
  };
  navigateSavedSomethingLandingAll = (id: any) => {
    this.setState({ removeSavedList: false })
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  async componentDidUpdate(prevProps: any) {
    if (prevProps.keyCount !== this.props.keyCount) {
      if(!this.props.isTypeChanges){
        this.setState({ showAllDataFilter: false, showAllSellingSomethingAll: false });
      }
      this.getJobsDatasSavingsLookingss(this.props.categortText);
    }
  }


  handleCountProduct = () => {
    if (this.props.showLookingSomeData) {
      return this.state.LookingSomeDatasAll.length;
    } else if (this.props.showSellingSomeData) {
      return this.state.someListingSaveAll.length;
    } else {
      return this.state.AllDataListing.length;
    }
  };
  


  saveListingApiCallLookingAllItem= ()  => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSaveListingItemsCallIdLookings = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  savedlistApiCallAllList = async (id: number) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSave_ListingCallIds = requestMessage.messageId;
    let Body = {
      "data": {
        "local_listing_ad_id": id
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_locallistingads/wishlist_items"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteSavelistingCallMainApi = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apideleteSavingLandingCallIdMainAll = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_locallistingads/wishlist_items/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  isCheckboxChecked = ()=> {
    let checked = false;
    const subCategoryApiDatassAll = this.props.subCategoryApiDatasAll[this.props.categortText?.[0]];
    subCategoryApiDatassAll && subCategoryApiDatassAll.forEach((arr: { isChecked: boolean; }) => {
      if(arr.isChecked) {
        checked = true;
      }
    });
    return checked;
  }

  getJobsDatasSavingsLookingss = (categories?: any) => {
    this.setState({LoaderListingData:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiLookingSomeItemCallIds = requestMessage.messageId;
  
    const searchsBoth = "both";
    const queryParams = new URLSearchParams(window.location.search).get("search") || ''; 
    
    let urlAll = `bx_block_locallistingads/local_listing_ads`;
   
    const { showLookingSomeData, showSellingSomeData } = this.props;

    
    switch (true) {
        case showLookingSomeData:
            urlAll += `?listing_type=looking_for_something&search=${queryParams}`;
            break;
        case showSellingSomeData:
            urlAll += `?listing_type=selling_for_something&search=${queryParams}`;
            break;
        default:
            urlAll += `?listing_type=${searchsBoth}&search=${queryParams}`;
            break;
    }

  
    let isChecked = this.isCheckboxChecked();
    if(isChecked && categories?.[0]) {
      urlAll += `&category=${categories[0]}`;
    }

    let categoryAll=Object.keys(this.props.subCategoryApiDatasAll)[0];
   
if (categoryAll !== undefined && categoryAll.length > 0) {
  const subCategoryApiDatassAll = this.props.subCategoryApiDatasAll[categories?.[0]];
subCategoryApiDatassAll && subCategoryApiDatassAll.forEach((subcategory: any) => {
      if (categories && subcategory.isChecked) {
       
 urlAll += `&sub_categories[]=${subcategory.id}`;
      }
  });
}
  if (this.props.budgetMin ) {
    urlAll += `&min_budget=${this.props.budgetMin}`;
  }
  if (this.props.budgetMax ) {
    urlAll += `&max_budget=${this.props.budgetMax}`;
  }
  if (this.props.budgetMinc) {
    urlAll += `&min_commission=${this.props.budgetMinc}`;
  }
  if (this.props.budgetMaxc) {
    urlAll += `&max_commission=${this.props.budgetMaxc}`;
  }
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        urlAll,
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
};
  
  // Customizable Area End
}
