export const scrollToElement = (id: string, containerRef:any) => {
    const container = containerRef.current;
    if (!container) return;

    const element = document.getElementById(id);
    if (element) {
      const parentRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      const offset = elementRect.top - parentRect.top + container.scrollTop;
      
      container.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };