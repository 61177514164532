export const lamp = require("../assets/lamp.png")
export const car = require("../assets/car.png")
export const cloths = require("../assets/cloths.png")
export const Jewellery = require("../assets/Jewellery.png")
export const sky = require("../assets/sky.png")
export const findCollection = require("../assets/findCollection.jpeg")
export const LookBG = require("../assets/LookBG.png")
export const SellBG = require("../assets/SellBG.png")
export const contactusnew = require("../assets/contactusnew.png")
export const carD = require("../assets/carD.png")
export const imageEmptyhearts = require("../assets/imageEmptyheart.png")
export const imagePopupSaves = require("../assets/imagePopupSave.png")
export const imageHomeCross = require("../assets/HomeCross.png")
export const arrowDown = require("../assets/arrow-down.png")
export const imageFilledHeart = require("../assets/shapeSaveListing.png")
export const test = require("../assets/test.jpeg")
export const help = require("../assets/help.png")
export const searchImageSave = require("../assets/searchSaveListing.png");
export const SellingSomeImage = require("../assets/SellingSomeImage.png");
export const deleteSavingss = require("../assets/deleteSaved.png")
export const group_CrossBtns = require("../assets/group_Cross.png")
export const others = require("../assets/others.png")
export const arrow_left = require("../assets/arrow-left.png")
export const arrow_right = require("../assets/arrow-rights.png")
export const emptyData = require("../assets/emptyData.png")
export const imageBorder = require("../assets/imageBorder.png")
export const imageBorderRight = require("../assets/imageBorderRight.png")
export const imageDeleteIcons = require("../assets/imageDeleteIcon.png")
