// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    dataPass: any;
    closeModal: any;

}

interface S {

    emailValue: string;
    showResendBox: boolean;
    emailError: string;
    errorMsg: string;
    showPassword:boolean;
    showConfirmPassword:boolean;
    passwordMatchError:boolean;
    password:string;
    confirmPassword:string;
    passwordError:boolean;
    containsCapital: boolean;
  containsNumber: boolean;
    containsLowercase: boolean;
    hasMinLength:boolean;
    confirmPasswordError: boolean;
    urlToken:string;
    resetPasswordPopup:boolean;
    resetError:string;
    spacialChar:boolean;
    passwError:string
   

}

interface SS {

    navigation: any;

}



export default class ForgotPasswordControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {

    forgotPasswordAPIcallId:any 
    resetPasswordAPIcallId:any


    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)

        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


        this.state = {

            emailValue: "",
            showResendBox: false,
            emailError: "",
            errorMsg: "",
            showPassword:false,
            showConfirmPassword:false,
            passwordMatchError:false,
            password:'',
            confirmPassword:"",
            passwordError:false,
            containsCapital:false,
            containsLowercase:false,
            containsNumber:false,
            hasMinLength:false,
            confirmPasswordError:false,
            urlToken:"",
            resetPasswordPopup:false,
            resetError:"",
            spacialChar:false,
            passwError:""
        };

    }

    async componentDidMount() {
        super.componentDidMount();
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            this.setState({ urlToken: token });
        }

    }

    componentDidUpdate(prevProps: { dataPass: boolean }) {

        if (prevProps.dataPass && !this.props.dataPass) {


            this.setState({ showResendBox: false, emailValue: '', emailError: '', errorMsg: "" });
        }
    }


    getButtonBackgroundColor = () => {
        return "#3CAA49";
      };



      PasswordFormSchema = () => {
        return Yup.object().shape({
          password: Yup.string()
            .trim()
            .required('Password is required!')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/\d/, 'Password must contain at least one number')
            .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, 'Password must contain at least one special character')
            .min(8, 'Password must be at least 8 characters long'),
        });
      };
   

    
      handlePasswordChange = (event: any) => {
        const value = event?.target?.value ?? '';
      
        this.PasswordFormSchema()
          .validate({ password: value })
          .then(() => {
            this.setState({ passwError: '',});
          })
          .catch((error) => {
            this.setState({ passwError: error.message, resetError: "",passwordError:false});
          });
      
        this.setState({ password: value }, () => {
          // Update password-related state properties after setting the password state
          const containsCapital = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /\d/.test(value);
          const spacialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
          const hasMinLength = value.length >= 8;
      
          this.setState({
            containsCapital,
            containsLowercase,
            containsNumber,
            hasMinLength,
            spacialChar
          });
        });
      };
      
    


      handleConfirmPasswordChange = (event: { target: { value: string } }) => {
        const confirmPassword = event.target.value;
        this.setState({ confirmPassword });
        const { password } = this.state;
        const passwordMatchError = password !== confirmPassword;
    
        this.setState({
          confirmPassword,
          passwordMatchError
        });
    
        if (confirmPassword.trim() === "") {
          this.setState({ confirmPasswordError: true,resetError:"" });
        } else {
          this.setState({ confirmPasswordError: false });
        }
      };
      togglePasswordVisibility = () => {
        this.setState(prevState => ({
          showPassword: !prevState.showPassword
        }));
      };
      toggleConfirmPasswordVisibility = () => {
        this.setState(prevState => ({
          showConfirmPassword: !prevState.showConfirmPassword
        }));
      };


        handlePrint=()=>{
            const {password ,confirmPassword,passwordError,containsCapital,containsLowercase,containsNumber,hasMinLength,spacialChar,passwordMatchError} = this.state
          
            if (password.trim() === "") {
                this.setState({ passwordError: true });
              } else {
                this.setState({ passwordError: false });
              }

              if (confirmPassword.trim() === ""  ) {
                this.setState({ confirmPasswordError: true });
              } else {
                this.setState({ confirmPasswordError: false });
              }

              if (
               
                password.trim() !== "" &&
                confirmPassword.trim() !== "" && containsCapital &&containsLowercase && containsNumber && hasMinLength && spacialChar
               && !passwordMatchError && !passwordError
              ){
                this.handleResetPassword()
              }
        }



    SignUpFormSchema = () => {
        return Yup.object().shape({
            email: Yup.string()
                .email(configJSON.pleaseEnterAValidEmail)
                .required(configJSON.emailIsRequired)
        })
    }

    handleEmailChange = (event: { target: { value: string }}) => {
        const { value } = event.target;

        this.SignUpFormSchema()
            .validate({ email: value })
            .then(() => {

                this.setState({ emailError: '' });
            })
            .catch((error) => {

                this.setState({ emailError: error.message, errorMsg: "" });
            });

        this.setState({ emailValue: value });
    };

    handleCloseModal = () => {
        this.props.closeModal()
    }

    showResendbox = () => {
        const { emailValue } = this.state;

        const emailSchema = this.SignUpFormSchema();

        emailSchema
            .validate({ email: emailValue })
            .then(() => {

                this.setState({ emailError: "" });
                this.handleResetLink()
            })
            .catch(error => {

                this.setState({ emailError: error.message });
            });

     
    };

    handleResetLink = async () => {


        const data = {
            "data":
            {
                "attributes": {
                    "email": this.state?.emailValue
                }
            }
        }

        this.forgotPasswordAPIcallId = await this.ForgotPasswordAPICall({

            ContentType: 'application/json',
            methods: 'POST',
            endPoint: 'bx_block_forgot_password/send_reset_link',

            Body: data,


        })
    }

    handleResetPassword = async () => {


        const data = 
        {
            "data":{
            "token": this.state.urlToken,
            "new_password": this.state.password
          }
        }

        this.resetPasswordAPIcallId = await this.ForgotPasswordAPICall({

            ContentType: 'application/json',
            methods: 'POST',
            endPoint: 'forgot_password/password',

            Body: data,


        })
    }


    navigateToLogin() {
        const navigateToLoginMessage: Message = new Message(
          getName(MessageEnum.NavigationEmailLogInMessage)
        );
        navigateToLoginMessage.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(navigateToLoginMessage);
      }

    ForgotPasswordAPICall = async (data: any) => {
        const { ContentType, methods, endPoint, Body, token } = data;
        const Header = {
            "Content-Type": ContentType,
            token,
        };
        const apiRequestMessageforForgotPassword = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        apiRequestMessageforForgotPassword.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(Header)
        );
        apiRequestMessageforForgotPassword.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        apiRequestMessageforForgotPassword.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methods
        );
        Body &&
            apiRequestMessageforForgotPassword.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),

                JSON.stringify(Body)
            );
        runEngine.sendMessage(apiRequestMessageforForgotPassword.id, apiRequestMessageforForgotPassword);
        return apiRequestMessageforForgotPassword.messageId;
    };

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            if (apiRequestCallId === this.forgotPasswordAPIcallId) {

                if (!responseJson.errors) {
                    this.setState({ showResendBox: true })
                }
                else {
                    this.setState({ errorMsg: responseJson.errors[0].otp, })


                }
            }

            if (apiRequestCallId === this.resetPasswordAPIcallId) {

                if (!responseJson.errors) {
                    this.setState({ resetPasswordPopup: true })
                }
                else {
                    this.setState({ resetError: responseJson.errors[0].otp, })


                }
               
            }

        }
    }


}
// Customizable Area End