import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area End
    // Customizable Area Start
}

export interface S {
    // Customizable Area Start
    otpAuthToken: string;
    useProfiles: any;
    countrys: any;
    citys: any;
    setInitiateDatas: any;
    listingTypes: string;
    setBuyerSeller: any;
    setBuyerImg: any;
    setSellerImg: any;
    setTitle: string;
    setBudget: string;
    setCommission: string;
    LoaderNegotiationData: boolean;
    setDatasFullName: any[];
    isOpen: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class NegotiationUserDataController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    userProfileApiCallIds: string = "";
    initiate_dealsCallIDs: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            otpAuthToken: "",
            useProfiles: [],
            countrys: '',
            citys: '',
            setInitiateDatas: [],
            listingTypes: '',
            setBuyerSeller: {},
            setBuyerImg: '',
            setSellerImg: '',
            setTitle: '',
            setCommission: '',
            setBudget: '',
            LoaderNegotiationData: true,
            setDatasFullName:[],
            isOpen: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallId === this.userProfileApiCallIds) {
                if (responseJson && !responseJson.errors) {
                    this.setState({
                        useProfiles: responseJson.data,
                        countrys: responseJson.data.attributes.country,
                        citys: responseJson.data.attributes.city
                    });
                }
            }
            if (apiRequestCallId === this.initiate_dealsCallIDs) {
                this.setState({ LoaderNegotiationData: false })
                if (responseJson && !responseJson.errors) {
                    this.setState({
                        setInitiateDatas:responseJson.data,
                    })
                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    componentDidMount(): any {
        this.userProfileCalls()
        this.initiateDealsIndexs()
    }

    onPopUpClose = () => {
        this.setState({ isOpen: false });
    }

    onOpen = () => {
        this.setState({ isOpen: true });
    }

    onGoBack = () => {
        this.props.navigation.goBack();
    }

    handleButtonClicks = () => {
        localStorage.clear();
        sessionStorage.clear();
        this.props.navigation.navigate("LandingPageMain");
    };
    handleButtonClickSettings = () => {
        this.props.navigation.navigate("Settings2")
    };
    truncateUsername(username: any) {
        if (username.length > 15) {
            return username.substring(0, 15) + '...';
        }
        return username;
    }
    
    userDetails = (id: any) => {
        this.props.navigation.navigate(`OtherUserDetails`, { id: id })
    }
    handleClickAllProducts = (item: any) => {
        const id = item.id
        this.props.navigation.navigate(`FinalProductsDetails`, { id: id })
    }
    userProfileCalls = async () => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userProfileApiCallIds = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.negotiationdataapi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    initiateDealsIndexs = async () => {
        const token = localStorage.getItem("authToken") as string;
        const contentss = this.props.navigation.getParam('id');
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.initiate_dealsCallIDs = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/initiate_deals/get_listing_deals?local_listing_ad_id=${contentss}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                local_listing_ad_id: contentss,
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // Customizable Area End
}
