// Customizable Area Start
import { Box, CircularProgress, Typography, styled, Button, Grid, Card } from "@material-ui/core";
import { image_Green, SellingSomesImage, searchImageSave ,orderH} from "./assets";
import React from 'react';
import OrderHistoryController from "./OrderHistoryController.web";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
const CardImage = styled("img")({
  objectFit: "cover",
  "@media(max-width:1150px)": {
    width: "100%",
  },
});


interface Listing {
  listing_type: string;
  title: string;
  description: string;
  budget: string;
  commission: string;
  category: string;
  order_id: string;
  image: string[];
}

interface Attributes {
  id: string;
  created_at: string;
  updated_at: string;
  status:string;
  listing: Listing;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

const Loader = styled(Box)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "rgb(247, 245, 245)"
});

export default class OrderHistory extends OrderHistoryController {

  listingType =(item:Data,index:number)=>{
return (
  <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
  <CardImage
    style={{ width: 25, height: 25, objectFit: "cover", color: "#676767" }}
    src={item?.attributes?.listing?.listing_type === "looking_for_something" ? SellingSomesImage : searchImageSave}
  />

  <Typography data-test-id={`listing-${index}`} style={{
    "fontFamily": "Segoe UI",
    "fontSize": "12px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left", color: "#676767"
  }}>{item?.attributes?.listing?.listing_type ==="selling_for_something" ? "Selling Something":"Looking For Something"}</Typography>

</div>
)
  }

  ReviewCard() {

    return (
      <Box style={{ background: "#f7f5f5", padding: "32 60 60 32", minHeight: '100vh',}}>
        <Grid
          container
          spacing={1}
          className="box_main"
          style={{ display: "flex", gap: "30px" }}
        >
          {this.getCurrentResults().map((item: Data, index: number) => (

            <>
              <Card
                key={index}
                className="review_card"
                data-testId="handleCLickCard"
                style={{
                  padding: 18,
                  maxWidth: "100%",
                  margin: 2,
                  borderRadius: "10px",
                  width: "100%",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <MainDive

                >
                  <div style={{ width: "15%", display: "flex", justifyContent: "center" }}>

                    <CardImage
                      style={{ width: 127, height: 144, objectFit: "contain" }}
                      src={item?.attributes?.listing?.image[0]}
                      data-test-id={`image-${index}`}
                    />
                  </div>
                  <div style={{ width: "85%", display: "flex", flexDirection: "column", gap: "4px" }}>

                    {this.listingType(item,index)}
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <Typography style={{ "fontFamily": "Segoe UI bold", "fontSize": "16px", "fontWeight": 700, "lineHeight": "20px", "textAlign": "left" }}>{item?.attributes?.listing?.title}</Typography>
                        <Typography style={{ color: "#676767", "fontFamily": "Segoe UI", "fontSize": "14px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left" }}> {item?.attributes?.listing?.category}</Typography>
                      </div>
                      <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography style={{ color: "#676767", "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 400, "lineHeight": "22px", "textAlign": "left" }}>
                          {item?.attributes?.listing?.listing_type === "looking_for_something" ? "Budget" : "Price"}</Typography> <Typography style={{ "textAlign": "right", padding: "0 10px", fontWeight: 700, color: "#3CAA49" }}>£ {item?.attributes?.listing?.budget}</Typography></div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography style={{ color: "#676767", "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 400, "lineHeight": "22px", "textAlign": "left" }}>
                          Reward</Typography> <Typography style={{ "textAlign": "right", padding: "0 10px", fontWeight: 700, color: "#3CAA49" }}>£ {item?.attributes?.listing?.commission}</Typography></div>
                      </div>
                    </div>
                    <Typography style={{ color: "#676767", "fontFamily": "Segoe UI", "fontSize": "14px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left" }}> Order ID - {item?.attributes?.listing?.order_id}</Typography>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CardImage
                          style={{ width: 18, height: 18, objectFit: "cover" }}
                          src={image_Green}
                        />
                        <Typography style={{ "fontFamily": "Segoe UI", "fontSize": "14px", "fontWeight": 400, "lineHeight": "24px", "textAlign": "left", padding: "0 10px",color:"#000000" }}>
                          Delivered on {`${moment(item.attributes.created_at ?? item.attributes.updated_at).format("Do MMMM YYYY")}`}</Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                      
                        {item.attributes.status !==null ?
                                                <Button disabled data-test-id={`navtorefund-${index}`} ><ViewTextDisabled >Refund {item.attributes.status}</ViewTextDisabled></Button>
                                             : 
                        <Button data-test-id={`navtorefund-${index}`} onClick={()=>this.handleNavigate(item.attributes.listing.order_id)}><ViewText >Refund Request</ViewText></Button>}
                        <div style={{ width: "4px", height: "4px", borderRadius: "50%", backgroundColor: "#3CAA49" }}></div>
                        <Button data-test-id="viewProduct" onClick={() => this.handleCLick(item.attributes.id)}> <ViewText >View Product</ViewText></Button>
                      </div>
                    </div>
                  </div>
                </MainDive>

              </Card>
            </>
          ))}


          {this.state.feedbackList.length == 0 && !this.state.LoaderListing ? (
              <Box style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%"}}>
              <Container>
                <img src={orderH} alt="Logo" />
              </Container>
              <div>
                <HeadingText>
                Order History is empty!
                </HeadingText>
                <HeadingTextMain>
                  Seems like you haven’t created any order yet
                </HeadingTextMain>
              
              </div>
            </Box>
          ) : null}

         

        </Grid>
        {this.state.feedbackList.length != 0 && 
        <Box display="flex" alignItems="center" justifyContent="center" mt={2} style={{ width: "100%",position:"relative",bottom:"-32px" }}>
            <Typography data-test-id="pagination-text" variant="body1" style={{ marginRight: '16px' }}>
              Page {this.state.currentPage} of {this.getTotalPage()}
            </Typography>
            <Pagination
              count={this.getTotalPage()}
              page={this.state.currentPage}
              onChange={this.handlePageChange}
              size="small"
              data-test-id="pagination"
            />
          </Box>}
      
      </Box>
    );
  }


  render() {
    const { LoaderListing } = this.state;

    if (LoaderListing) {
      return (
        <Loader>
          <Box>
            <CircularProgress style={{ color: "#4fa94d" }} />
          </Box>
        </Loader>
      );
    }

    return <> {this.ReviewCard()}
      </>;
  }
}


const MainDive = styled('div')({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "center",
  alignItems: "initial",
  gap: "20px",

  '@media(max-width: 730px)': {
    flexDirection: "column",
    alignItems: "center"
  }
});

const ViewText = styled(Typography)({
  color: "#3CAA49", textTransform: "none", "fontFamily": "Segoe UI", "fontSize": "16px", "fontWeight": 700, "lineHeight": "24px", "textAlign": "left",
  "@media(max-width:767px)": {
    "fontSize": "14px"
  },
  "@media(max-width:450px)": {
    "fontSize": "12px"
  },

});

const ViewTextDisabled = styled(Typography)({
  color: "#676767", textTransform: "none", "fontFamily": "Segoe UI", 
  "fontSize": "16px", "fontWeight": 700, 
  "lineHeight": "24px", "textAlign": "left",
  "@media(max-width:767px)": {
    "fontSize": "14px"
  },
  "@media(max-width:450px)": {
    "fontSize": "12px"
  },

});
const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '350px',
  maxWidth: 'auto',
  margin: '30 auto 10 auto',
});
const HeadingText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  fontSize: '22px',
  fontFamily: 'Segoe UI',
  lineHeight: '20px',
  color: '#3CAA49',
});
const HeadingTextMain = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '10 auto',
  fontSize: '18px',
  fontFamily: 'Segoe UI',
  lineHeight: '20px',
  color: '#676767',
});
// Customizable Area End
