// Customizable Area Start
import React from "react";
import {
  Typography,
  Paper,
  List,
  ListItem,
  Avatar,
  CircularProgress,
  Box
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ViewMyChat from "./ViewMyChat.web";
import MyChatController from "./MyChatController.web";
import moment from "moment";
import { Inbox } from "../../../components/src/Inbox.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const CustomBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  padding: "40px 72px 45px 21px",
  backgroundColor:"rgb(247, 245, 245)"
});

const Loader = styled(Box)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "rgb(247, 245, 245)"
});

export default class MyChat extends MyChatController {
  Header = () => {
    return (
      <Typography
        variant="h6"
        gutterBottom
        style={{
          background: "white",
          justifyContent: "center",
          lineHeight: "30px",
          display: "flex",
          alignItems: "center",
          fontSize: "24px",
          fontFamily: "Segoe UI Bold",
          borderBottom: "0.5px solid lightgray",
          position: "sticky",
          top: 0,
          zIndex: 2,
          padding: "13px",
        }}
      >
        Messages
      </Typography>
    );
  };
  UserList = () => {
    const userNameFromLocalStorage = localStorage.getItem("fullName");
    return (
      <CustomBox>
        
        <Paper
          elevation={1}
          style={{
            padding: "0px 20px 0px 20px",
            maxWidth: "35%",
            width: "30%",
            height: "88vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(60, 170, 73, 0.9) rgba(60, 170, 73, 0)",
            borderRadius:"0px",
          }}
        >
          {this.Header()}
          {
          (
            <List
              component="nav"
              aria-label="message list"
              style={{ marginTop: "-2%" }}
              
            >
              {this.state.chatsList.map((user: any, index: number) => (
              
                <div key={user.id}>
                  <ListItem
                    alignItems="flex-start"
                    style={{
                      padding: "10px 10px",
                      display: "flex",
                      flexDirection: "column",
                      borderBottom: "1px solid lightgray",
                      cursor:"pointer",
                      background:
                        this.state.selectedTab === user.id
                          ? "rgba(236, 247, 237, 1)"
                          : "transparent",
                      transition: "background-color 0.3s ease",
                      position: "relative", // Add position relative to parent ListItem
                    }}
                    onClick={() => this.handleOpen(user)}
                  >
                    {userNameFromLocalStorage ===
                    user.attributes.participants[0].name ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",

                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                        <Avatar
                          alt={user.name}
                          src={user.attributes.participants[1].image}
                          style={{ width: "30px", height: "30px" }}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            marginLeft: "10px",
                            fontFamily: "Segoe UI Bold",
                            fontWeight: 700,
                            fontSize: "14px",
                          }}
                        >
                          {user.attributes.participants[1].name}
                        </Typography>
                      </div>

                        {user.attributes.unread_count > 0 && (
  <div
    style={{
      width: "20px",
      height: "20px",
      backgroundColor: "#3CAA49", // Green color for the box
      borderRadius: "50%", // Make it round
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white", // White color for text
      fontSize: "12px",
      fontWeight: "bold",
    }}
  >
    {user.attributes.unread_count} {""}
  </div>
)}
                      </div>
                      
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={user.name}
                            src={user.attributes.participants[0].image}
                            style={{ width: "32px", height: "32px" }}
                          />
                          <Typography
                            variant="body1"
                            style={{
                              marginLeft: "10px",
                              fontFamily: "Segoe UI Bold",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                          >
                            {user.attributes.participants[0].name}
                          </Typography>
                        </div>

                        {user.attributes.unread_count > 0 &&  (
  <div
    style={{
      width: "20px",
      height: "20px",
      backgroundColor: "#3CAA49", // Green color for the box
      borderRadius: "50%", // Make it round
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white", // White color for text
      fontSize: "12px",
      fontWeight: "bold",
    }}
  >
    {user.attributes.unread_count} {/* Number to display */}
  </div>
)}
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "15px",
                        paddingTop:'10px',
                        borderTop:'0.2px solid rgba(236, 247, 237, 1)',
                      }}
                    >
                   
                      <img
                        src={user.attributes.listing_details.image != null ? user.attributes.listing_details.image[0].url:""}
                        style={{
                          width: "58px",
                          height: "66px",
                          marginRight: "2%",
                          flexShrink: 0,
                        }}
                      />
                      <div
                        style={{
                          flex: "1",
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            alignSelf: "flex-start",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            width: "80%",
                            fontWeight: 400,
                            lineHeight: "13px",
                            fontFamily:"Segoe UI",
                            color:' #000000',
                          }}
                        >
                          {user.attributes.listing_details.product_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            marginRight: "1.5%",
                            alignSelf: "flex-end",
                            position: "absolute",
                            right: 0,
                            fontFamily:"Segoe UI",
                            fontSize: "11px",
                            color: "#676767",
                          }}
                        >
                          {`${moment(user.attributes.last_message_time).format(
                            "HH:mm"
                          )}`}
                        </Typography>
                      </div>
                    </div>
                  </ListItem>
                </div>
              ))}
            </List>
          )}
        </Paper>
        
        {this.state.chatModal === true ? (
          <div style={{ width: "55%" }}>
            <ViewMyChat
              id={""}
              navigation={this.props.navigation}
              client={this.chatClient}
              chatUserInformation={this.state.currentChat}
              getChatList={this.getChatList}
            />
          </div>
        ) : null}
      </CustomBox>
    );
  };

  render() {
    return <ThemeProvider theme={theme}>
       {this.state.loading ? (
          <Loader>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress style={{ color: '#4fa94d' }} />
            </Box>
          </Loader>
          ) : 
         
         this.state.chatsList.length === 0  ? (
          <Inbox  />
        ) :
      
      this.UserList()
         }
      </ThemeProvider>;
  }
}

// Customizable Area End
