// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Link,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@material-ui/core";

import { styled } from "@material-ui/core/styles";
import {
  Visibility,
  VisibilityOff,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from "@material-ui/icons";
import Popup from 'reactjs-popup';
import { image_Cross, image_PopupChair } from "../../settings2/src/assets";
import ChangePasswordController from "./ChangePasswordController";

export default class ChangePassword extends ChangePasswordController {

  renderPasswordError() {
    if (this.state.passwordErrors) {
      return this.renderErrorMessage('Password is required');
    }
    return null;
  }
  renderConfirmPasswordErrorSetting() {
    if (this.state.confirmPasswordErrors) {
      return this.renderErrorMessage('Confirm Password is required');
    }
    return null;
  }
  renderErrorMessage(message: any) {
    return (
      <Typography style={webstyle.errorMessage as React.CSSProperties}>
        {message}
      </Typography>
    );
  }
  accountFunction() {
    return (
      <p style={webstyle.popuptextss as React.CSSProperties} >to the account
      </p>
    );
  }
  accountFunctionAtLest() {
    return (
      <span style={webstyle.checkText as React.CSSProperties}>
      At least one number.
    </span>
    );
  }
  renderErrorMessagesData() {
    return (
      <div style={webstyle.popupIMGMainss as React.CSSProperties} >
      <img src={image_PopupChair} />
    </div>
    );
  }
  

  render() {
    const {
      containsCapitals,
      containsLowercases,
      containsNumbers,
      hasMinLengths,
      showPasswords,
      passwordMatchErrors,
      showConfirmPasswords,
    } = this.state;
    const passwordInputType = showPasswords ? "text" : "password";
    const confirmPasswordInputType = showConfirmPasswords ? "text" : "password";
    const passwordMatchErrorBorderColor = passwordMatchErrors
      ? "#FF0404"
      : undefined;
    const buttonBackgroundColor = this.getButtonBackgroundColor();
    return (
      <Box mt={4} ml={3}>
        <Button style={webstyle.plateformExploreBtns as React.CSSProperties} onClick={this.handleChandePassword}>Change Password</Button>
        <div>

          <FormGrid container>

            <div>
              <Popup
                open={this.state.changePassword}
                test-id="popup"
                closeOnDocumentClick onClose={this.closePopupPassword}
                className="popup"
                contentStyle={webstyle.contentss}
                overlayStyle={webstyle.overlays}
              >

                <div>
                  <form>
                    <img src={image_Cross} alt="" style={webstyle.imgCross as React.CSSProperties} onClick={this.closePopupPassword} />
                    <div style={webstyle.popupIMGMains as React.CSSProperties} >

                      <Typography style={webstyle.namePassword as React.CSSProperties}>
                        Change Password
                      </Typography>
                      <Typography style={webstyle.newPassword as React.CSSProperties}>
                        Enter new password.
                      </Typography>
                      <Typography style={webstyle.labels as React.CSSProperties}>
                        New Password
                      </Typography>
                      <CustomTextField
                        variant="outlined"
                        placeholder="Enter new password"
                        autoComplete=""
                        type={passwordInputType}
                        style={{ width: "100%" }}
                        onChange={this.handlePasswordChangess}
                        value={this.state.passwords}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.togglePasswordVisibility}
                                edge="end"
                              >
                                {showPasswords ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: placeholderStyles,
                        }}
                      />
                      {this.renderPasswordError()}
                      <Typography style={webstyle.label as React.CSSProperties}>
                        Confirm Password
                      </Typography>
                      <CustomTextField
                        variant="outlined"
                        autoComplete=""
                        placeholder="Re-Enter password"
                        type={confirmPasswordInputType}
                        style={{
                          width: "100%",
                          borderColor: passwordMatchErrorBorderColor
                        }}
                        value={this.state.confirmPasswords}
                        onChange={this.handleConfirmPasswordChangess}
                        error={passwordMatchErrors}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle confirm password visibility"
                                onClick={this.toggleConfirmPasswordVisibility}
                                edge="end"
                              >
                                {showConfirmPasswords ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: placeholderStyle,
                        }}
                      />
                      {passwordMatchErrors && this.renderErrorMessage('Passwords do not match.') ||  this.state.confirmPasswordErrorSetting && this.renderConfirmPasswordErrorSetting()}
                      <div style={{ marginTop: "5px",  pointerEvents: 'none' }}>
                        <div>
                          <FormControlLabel
                            control={
                              <CustomCheckboxs
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={
                                  <CheckBoxIcon style={{ color: "#059669" }} />
                                }
                                checked={containsCapitals} />
                            }
                            label={
                              <span style={webstyle.checkText as React.CSSProperties}>
                                At least one capital letter.
                              </span>
                            }
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <CustomCheckboxs
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={
                                  <CheckBoxIcon style={{ color: "#059669" }} />
                                }
                                checked={containsLowercases}
                              />
                            }
                            label={
                              <span style={webstyle.checkText as React.CSSProperties}>
                                At least one lowercase letter.
                              </span>
                            }
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <CustomCheckboxs
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={
                                  <CheckBoxIcon style={{ color: "#059669" }} />
                                }
                                checked={containsNumbers}
                              />
                            }
                            label={
                              this.accountFunctionAtLest()
                            }
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <CustomCheckboxs
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={
                                  <CheckBoxIcon style={{ color: "#059669" }} />
                                }
                                checked={hasMinLengths}
                              />
                            }
                            label={
                              <span style={webstyle.checkText as React.CSSProperties}>
                                Minimum character length is 8.
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <Button
                      style={{
                        ...webstyle.btnLogin,
                        backgroundColor: buttonBackgroundColor,
                        textTransform: "initial",
                        cursor: "pointer"
                      }}
                      onClick={this.handleCreateAccounts}
                    >Change Password
                    </Button>
                  </form>

                </div>
              </Popup>

              <div>
                <div>
                  <Popup
                    open={this.state.ChangesSuccessfully}
                    test-id="popup"
                    closeOnDocumentClick onClose={this.closeChangeSuccessfully}
                    className="popup"
                    contentStyle={webstyle.contentsPassword}
                    overlayStyle={webstyle.overlayss}
                  >
                    <div>
                     {this.renderErrorMessagesData()}
                      <p style={webstyle.passwordChange as React.CSSProperties}>{this.state.setData}</p>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }} >
                        <Link
                          style={webstyle.signlinks as React.CSSProperties}
                          onClick={this.handleChangePasswords}
                        >Re-Login
                        </Link>
                        {this.accountFunction()}
                      </div>
                    </div>
                  </Popup>
                </div>
              </div>
            </div>
          </FormGrid>
        </div>
      </Box>
    );
  }
}

const FormGrid = styled(Grid)({
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
});
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: '8px 15px',
    fontSize: 14
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});
const placeholderStyle = {
  fontSize: "16px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};
const placeholderStyles = {
  fontSize: "16px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};
const CustomCheckboxs = styled(Checkbox)({
  padding: "0px",
  paddingLeft: "10px",
  paddingRight: "5px",
  "& .MuiSvgIcon-root": {
    color: "#CBD5E1!important"
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root": {
    color: "#3CAA49!important"
  }
});
const webstyle = {
  plateformExploreBtns: {
    height: "56px",
    width: "222px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  buttonMain: {
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer'
  },
  overlays: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  overlayss: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentss: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    padding: '118px 100px 60px',
    width: '360px',
    borderRadius: '8px',
  },
  contentsPassword: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '30.5px 43.5px 30.5px 43.5px',
    width: '580px',
    borderRadius: '8px',
    height: '398px'
  },
  popupIMGMains: {
  },
  popupIMGMainss: {
    width: '232px',
    heigth: '232px',
    margin: 'auto',
  },
  errorMessage: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  errorMessages: {
    color: "#6767673",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",

    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  checkText: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "24px"
  },
  namePassword: {
    fontSize: "24px",
    letterSpacing: "0em",
    lineHeight: "32px",
    color: "#000000",
    fontFamily: "Segoe UI Bold",
  },
  newPassword: {
    marginTop: "15px",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "25px",
    color: "#676767",
    fontFamily: "Segoe UI",
  },
  labels: {
    fontSize: "16px",
    marginTop: "15px",
    letterSpacing: "0em",
    lineHeight: "24px",
    color: "#676767",
    textAlign: "left",
    fontFamily: "Segoe UI Bold",
  },
  label: {
    fontSize: "16px",
    marginTop: "15px",
    letterSpacing: "0em",
    lineHeight: "24px",
    color: "#676767",
    textAlign: "left",
    fontFamily: "Segoe UI Bold",
  },
  btnLogin: {
    borderRadius: "8px",
    backgroundColor: "#273567",
    width: "100%",
    fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    height: "35px",
    marginTop: "10px",
    fontSize: "16px",
    cursor: "pointer"
  },
  imgCross: {
    position: 'absolute',
    top: 38,
    margin: 'auto',
    right: 36,
    cursor: 'pointer',
  },
  signlinks: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
  },
  popuptextss: {
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: "#181818",
    lineHeight: "22px",
  },
  popuptexts: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  passwordChange: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#181818",
    LineHeigth: '42.56px',
    margin: 'auto',
  },
}
// Customizable Area End
