import React from 'react';
import { Box, styled, Button } from "@material-ui/core";

export function NegotiationEmpty({navigation}:any) {
  const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '350px',
    maxWidth: 'auto',
    margin: '0 auto 10 auto',
    paddingTop: "30px",
  });
  const HeadingText = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    fontSize: '22px',
    fontFamily: 'Segoe UI',
    lineHeight: '20px',
    color: '#3CAA49',
  });
  const HeadingTextMain = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10 auto',
    fontSize: '18px',
    fontFamily: 'Segoe UI',
    lineHeight: '20px',
    color: '#676767',
  });

  const CustomBox = styled(Box)({
    backgroundColor: "rgb(247, 245, 245)",
    height: "calc(100% - 199px)",
    paddingTop: "80px",
    paddingBottom: "70px"
  });

  return (
    <CustomBox>
      <Container>
        <img src={require('./NegotiationImage.png')} alt="Logo" />
      </Container>
      <div>
        <HeadingText>
        No active deal in progress
        </HeadingText>
        <HeadingTextMain>
        Seems like you haven’t started any deals yet
        </HeadingTextMain>
      </div>
    </CustomBox>
  );
}

