import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area End
    // Customizable Area Start
}

export interface S {
    // Customizable Area Start
    otpAuthToken: string;
    useProfiles: any;
    useProfiless: any;
    countrys: any;
    countryProfile: string | null;
    cityProfile: string | null;
    citys: any;
    useProfileFullName: string | null;
    useProfileUserName: string | null;
    setInitiateDatas: any;
    listingTypes: string;
    setBuyerSeller: any;
    setBuyerImg: any;
    LoaderListingReview: boolean;
    setSellerImg: any;
    reviewData:any;
    setTitle: string;
    setBudget: string;
    setCommission: string;
    LoaderNegotiationData: boolean;
    isOpen: boolean;
    isOtherUserProfile: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class MyReviewsDataController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    userProfileApiCallIds: string = "";
    reviewsDataApiCallIds: string = "";
    userProfileApiCallIdsData: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            otpAuthToken: "",
            useProfiles: [],
            useProfileFullName: null,
            useProfileUserName: null,
            useProfiless: null,
            countrys: '',
            citys: '',
            setInitiateDatas: [],
            reviewData:{},
            listingTypes: '',
            setBuyerSeller: {},
            setBuyerImg: '',
            setSellerImg: '',
            setTitle: '',
            setCommission: '',
            setBudget: '',
            LoaderNegotiationData: true,
            LoaderListingReview: true,
            countryProfile: "",
            cityProfile: "",
            isOpen: false,
            isOtherUserProfile: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallId === this.userProfileApiCallIds) {
                if (responseJson && !responseJson.errors) {
                    this.setState({
                        useProfiles: responseJson.data,
                        countrys: responseJson.data.attributes.country,
                        citys: responseJson.data.attributes.city
                    });
                }
            }
         
            
            if (apiRequestCallId === this.reviewsDataApiCallIds) {
                this.setState({ LoaderNegotiationData: false })
               this.setState({reviewData:responseJson.data})
            }
            if (apiRequestCallId === this.userProfileApiCallIdsData) {
                if (responseJson && !responseJson.errors) {
                    this.setState({ LoaderListingReview: false })
                    this.setState({
                        useProfileFullName: responseJson.data.attributes.full_name,
                        useProfileUserName: responseJson.data.attributes.user_name,
                        useProfiless: responseJson.data.attributes.photo,
                        countryProfile: responseJson.data.attributes.country,
                        cityProfile: responseJson.data.attributes.city
                    });
                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    componentDidMount(): any {
        const currentUrl = window.location.href;
        const param = new URLSearchParams(new URL(currentUrl).search)
        const id = param.get("id")
        const userid = param.get("userid")
        if(userid){
            this.setState({ isOtherUserProfile: true });
        this.userProfileCalls(userid)
        } else{
            this.userProfileCallMyReview()
        }
        if(id){
        this.initiateDealsIndexs(id)
        }
    }

    onClosePopUp = () => {
        this.setState({ isOpen: false });
    }

    onOpen = () => {
        this.setState({ isOpen: true });
    }

    onGoBack = () => {
        this.props.navigation.goBack();
    }

    handleButtonClicks = () => {
        localStorage.clear();
        sessionStorage.clear();
        this.props.navigation.navigate("LandingPageMain");
    };
    handleButtonClickSettings = () => {
        this.props.navigation.navigate("Settings2")
    };
    userProfileCallMyReview = async () => {
      const token = localStorage.getItem("authToken") as string;
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.userProfileApiCallIds = requestMessage.messageId

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "/bx_block_profile/profiles/show_user_profile"
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              token: token
          })
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
 
    userProfileCalls = async (contentId:any) => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userProfileApiCallIdsData = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_profile/profiles/${contentId}/other_user_profile`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    initiateDealsIndexs = async (id:any) => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.reviewsDataApiCallIds = requestMessage.messageId
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/deal_feedbacks/${id}`
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                 token: token,
            })
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return true;
    };
    
    

    // Customizable Area End
}
