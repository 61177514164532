import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  SafeAreaView,
} from "react-native";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { Box, Button, styled, Card, Link, Typography, Grid, CircularProgress, } from "@material-ui/core";
import { Jewellery, LookBG, SellBG, car, others, cloths, contactusnew, findCollection, lamp, sky, imageEmptyhearts, imagePopupSaves, imageFilledHeart, deleteSavingss, group_CrossBtns } from "./assets";
import Popup from 'reactjs-popup';
import { RemoveListingPopup } from "../../../components/src/RemoveListingPopup";
const ShoppingCards = styled(Box)({
  maxWidth: "186px",
  width: '100%',
  borderRadius: "8px",
  padding: "20px 21.5px 17px 21.5px",
  justifyContent: 'space-between',
  backgroundColor: "#FFFFFF",
  flexWrap: 'wrap',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
});
const ShoppingCardsSaved = styled(Box)({
  maxWidth: "186px",
  width: '100%',
  borderRadius: "8px",
  padding: "20px 21.5px 17px 21.5px",
  justifyContent: 'space-between',
  backgroundColor: "#FFFFFF",
  flexWrap: 'wrap',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
});
const ShoppingCardsSelling = styled(Box)({
  width: "80%",
  height: "359px",
  borderRadius: "8px",
  padding: "20px 21.5px 17px 21.5px",
  justifyContent: 'space-between',
  backgroundColor: "#FFFFFF",
  flexWrap: 'wrap',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: "pointer"
});

const BodyTypography = styled("p")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  color: '#676767',
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
const StyledSpan = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const CommissionTypography = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
})
const CardImage = styled("img")({
  width: "100%",
  height: "211px",
  objectFit: "contain",
  borderRadius: "5px",
  "@media(max-width:1150px)": {
    width: '100%',
  },
});
const BodyTypographySaved = styled("p")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  color: '#676767',
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

});
const StyledSpanSaved = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const CommissionTypographySaved = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
})

const CardImageSaved = styled("img")({
  width: "100%",
  height: "211px",
  objectFit: "contain",
  borderRadius: "5px",
  "@media(max-width:1150px)": {
    width: '100%',
  },
});
const BudgetTypography = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: "5px 0",
  color: "#676767"
});
const BudgetTypographySaved = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: "5px 0",
  color: "#676767"
});
const StyledSpanPage = styled("span")({
  color: "#3CAA49",
  fontSize: "14px",
  lineHeight: "28px",
  fontFamily: "Segoe UI Bold",
  margin: 0
});
const CommissionTypographyPage = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});

const LookingForSomething = styled('div')({
  borderColor: '#CBD5E1',
  borderRadius: "8px",
  height: "500px",
  backgroundImage: `url(${LookBG})`,
  boxShadow: "inset 10px -12px 100px 119px rgba(0,0,0,0.59)",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  border: '0.5px solid #CBD5E1',
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  alignSelf: "end",
  color: "#FFFFFF",
  position: 'relative',
  zIndex: 1
});
const SellingForSomething = styled('div')({
  height: "500px",
  backgroundImage: `url(${SellBG})`,
  boxShadow: "inset 10px -12px 100px 119px rgba(0,0,0,0.59)",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: "8px",
  backgroundRepeat: 'no-repeat',
  border: '0.5px solid #CBD5E1',
  alignSelf: "end",
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  color: "#FFFFFF",
  position: 'relative',
  zIndex: 1
});
const WishlistText = styled('div')({
  marginTop: "20px",
  fontSize: "16px",
  color: "#FFFFFF"
});
const LookingText = styled("p")({
  width: '100%',
  fontFamily: "Segoe UI Bold",
  fontSize: "28px",
  lineHeight: "54px",
  margin: 0,
});
const ExperienceText = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "18px",
  lineHeight: "24px",
  marginTop: '10px',
  margin: 0,
  color: "#FFFFFF",
  textAlign: 'start',
});
const Contactus = styled(Box)({
  height: "450px",
  background: "#EFFFF7",
  "@media(max-width:768px) and (min-width: 426px)": {
    height: "700px",
  },
  "@media(max-width:960px) and (min-width: 769px)": {
    height: "800px",
  },
  "@media(max-width:425px) and (min-width: 0px)": {
    height: "800px",
  },
});

const SaveImages = styled("img")({
  width: "29px",
  height: "29px",
  cursor: "pointer",
});
const SaveImagesSaved = styled("img")({
  width: "29px",
  height: "29px",
  cursor: "pointer",
});
const ContactusImg = styled("img")({
  height: "388px",
  "@media(max-width:425px) and (min-width: 0px)": {
    width: "300px"
  },

});
const ContactusGrid = styled(Grid)({
  alignItems: "flex-end",
  "@media(max-width:958px) and (min-width: 0px)": {
    textAlign: "center"
  },
});
const ContactusGridCancel = styled(Button)({
  height: "56px",
  width: "170px",
  borderRedius: "4px",
  background: "#3CAA49",
  color: "#ECF7ED",
  fontFamily: "Segoe UI Bold",
  fontSize: "20px",
  border: "none",
  marginRight: "15px",
  textTransform: "capitalize",
  "&.MuiButton-root:hover": {
    background: "#3CAA49",
  }
});
const ContactusGridDelete = styled(Button)({
  height: "56px",
  width: "170px",
  borderRedius: "4px",
  background: "#FFFFFFE5",
  color: "#3CAA49",
  fontFamily: "Segoe UI Bold",
  fontSize: "18px",
  textTransform: "capitalize",
  border: "1px solid #3CAA49",
});

const PlateformHeading = styled(Typography)({
  fontFamily: "Segoe UI Bold",
  fontSize: "40px",
  "@media(max-width:600px) and (min-width: 0px)": {
    textAlign: "center",
    fontSize: "16px",
  },
  "@media(max-width:1140px) and (min-width: 599px)": {
    textAlign: "center",
    fontSize: "24px",
  },
});
const CreateListingButtonss = styled(Button)({
  borderRadius: "4px",
  width: "100%",
  maxWidth: '279px',
  fontFamily: "Segoe UI Bold",
  color: "#FFFFFF",
  padding: '10px 44px 10px 44px',
  marginTop: "20px",
  backgroundColor: '#3CAA49',
  fontSize: "18px",
  cursor: "pointer",
  textTransform: 'none',
  gap: 10,
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#3CAA49',
  },
  "@media (max-width: 767px)": {
    padding: "10px 20px",
    fontSize: "14px",
    width: "unset",
  },
  "@media (max-width: 450px)": {
    padding: "10px 10px",
    fontSize: "12px",
    width: "unset",
  },
});
const CreateListingButtonsss = styled(Button)({
  borderRadius: "4px",
  width: "100%",
  maxWidth: '279px',
  fontFamily: "Segoe UI Bold",
  color: "#FFFFFF",
  padding: '10px 44px 10px 44px',
  marginTop: "20px",
  backgroundColor: '#3CAA49',
  fontSize: "18px",
  cursor: "pointer",
  gap: 10,
  alignItems: 'center',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#3CAA49',
  },
  "@media (max-width: 767px)": {
    padding: "10px 20px",
    fontSize: "14px",
    width: "unset",
  },
  "@media (max-width: 450px)": {
    padding: "10px 10px",
    fontSize: "12px",
    width: "unset",
  },
});
type LookingSomeDataItem = {
  id: number;
  type: string;
  attributes: {
    listing_type: string;
    title: string;
    budget: string;
    commission: string;
    photo: string[];
  }
}
const OverLayBoxCreate = styled(Box)({
  background: 'linear-gradient(63.47deg, #000000 14.99%, rgba(24, 24, 24, 0.03) 81.22%)',
  width: '100%',
  height: '100%',
  position: 'absolute',
  borderRadius: "12px"
});

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLookingForSomethingSection() {
    const lookingForSomethingDatas = this.state.LookingSomeData.filter(item => item.attributes.listing_type === "looking_for_something").slice(0, 5);
    return (
      <Box style={styleD.lookingMain as React.CSSProperties}>
        <Box style={styleD.containers as React.CSSProperties}>
          <Grid style={styleD.lookingHeader as React.CSSProperties}>
            <Typography style={styleD.lookingText as React.CSSProperties}>Looking For Something</Typography>
            <a data-testid="viewAll1" style={styleD.linkView as React.CSSProperties} href="/FilterAllCategory?lookingViewAll=true" onClick={(event) => {
                event.preventDefault();
                this.handleLookings();
            }}>View All</a>
          </Grid>
          <hr style={styleD.hr as React.CSSProperties} />
          <Box style={styleD.mapFN as React.CSSProperties}>
            <Box style={styleD.lookLoad}>
              {this.state.LoaderListingS ?
                <div
                  style={styleD.lookLoading}
                >
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress style={{ color: '#4fa94d' }} />
                  </Box>
                </div> :
                <>
                  {
                    (!lookingForSomethingDatas || lookingForSomethingDatas.length === 0) ? (
                      <div style={{
                        fontFamily: "Segoe UI", fontSize: "20px", lineHeight: "31.92px", color: "#676767", margin: '0 auto',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'center',
                        height: "300px",
                      }}>You haven't created any list of Looking for something.</div>
                    ) :
                      (
                        lookingForSomethingDatas?.map((item: any) => {
                          return (
                          
                            <>
                             
                                <ShoppingCards key={item.id} data-testid="viewHome">
                                <a data-testid={`lfs-${item.id}`} style={styleD.linkView as React.CSSProperties} href={`/ViewListing/${item.id}`} onClick={(event) => {
                                event.preventDefault();
                                this.viewDataOnHome(item.id)
                            }}>
                                  <CardImage
                                    src={item.attributes.photo[0]?.url}
                                    alt="Card Image"
                                  />
                                  <BodyTypography>{item.attributes.title}</BodyTypography>
                                  <BudgetTypography>
                                    Budget <StyledSpan>£ {item.attributes.budget}</StyledSpan>
                                  </BudgetTypography>
                                  </a>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center"
                                    }}
                                  >
                                    <CommissionTypography>
                                      Reward
                                      <StyledSpan> £ {item.attributes.commission}</StyledSpan>
                                    </CommissionTypography>
                                  
                                    <SaveImages
                                      src={this.state.savedListingsData?.includes(item.id) ? imageFilledHeart : imageEmptyhearts}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.state.savedListingsData?.includes(item.id) ? this.popupSaveds(item.id) : this.savedlistApiCallMain(item.id);
                                      }}
                                      alt="Card Image"
                                      data-testid="save-image1"
                                    />
                                  </div>
                                </ShoppingCards>
                          
                            </>
                          
                          )
                        }
                        ))

                  }
                </>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  renderDataText() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
        <Typography data-test-id="test-0" style={styleD.menuMain} onClick={this.AllFilterLandingPages1}><Link href="/FilterAllCategory" style={styleD.menuItem as React.CSSProperties}>All</Link></Typography>
        <Typography data-test-id="test-8" style={styleD.menuMain} onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(13)}} ><Link href="/FilterAllCategory/13" style={styleD.menuItem as React.CSSProperties}>Vehicles</Link></Typography>
        <Typography data-test-id="test-7" style={styleD.menuMain} onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(7)}}><Link href="/FilterAllCategory/7" style={styleD.menuItem as React.CSSProperties}>Jewellery</Link></Typography>
        <Typography data-test-id="test-6" style={styleD.menuMain} onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(8)}}> <Link href="/FilterAllCategory/8" style={styleD.menuItem as React.CSSProperties}>Clothing</Link></Typography>
        <Typography data-test-id="test-9" style={styleD.menuMain} onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(9)}}> <Link href="/FilterAllCategory/9" style={styleD.menuItem as React.CSSProperties}>Interiors</Link></Typography>
        <Typography data-test-id="test-10" style={styleD.menuMain} onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(10)}}><Link href="/FilterAllCategory/10" style={styleD.menuItem as React.CSSProperties}>Services</Link></Typography>
        <Typography data-test-id="test-11" style={styleD.menuMain} onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(11)}}><Link href="/FilterAllCategory/11" style={styleD.menuItem as React.CSSProperties}>Others</Link></Typography>
      </div>
    )
  }

  renderSellingSomethingSection() {
    const sellingSomethingDatas = this.state.someListingData?.filter(item => item.attributes.listing_type === "selling_for_something").slice(0, 6);
    return (
      <Box>
        <Box style={styleD.containers as React.CSSProperties}>
          <Box style={styleD.lookingHeader as React.CSSProperties}>
            <Typography style={styleD.lookingText as React.CSSProperties}>Selling Something</Typography>
            <a data-testid="viewAll2" style={styleD.linkView as React.CSSProperties} href="/FilterAllCategory?sellingViewAll=true" onClick={(event) => {
                event.preventDefault();
                this.handleSelling();
            }}>View All</a>
          </Box>

          <hr style={styleD.hr as React.CSSProperties} />
          <Grid container style={{ marginTop: "20px" }} spacing={4}>
            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
              <img src={findCollection} alt="" style={styleD.imgSell as React.CSSProperties} />
            </Grid>
            <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                {this.state.LoaderListingS ?
                  <div
                    style={styleD.sellLoad}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress style={{ color: '#4fa94d' }} />
                    </Box>
                  </div> :
                  <>
                    {
                      (!sellingSomethingDatas || sellingSomethingDatas.length === 0) ? (
                        <div style={{
                          fontFamily: "Segoe UI", fontSize: "20px", lineHeight: "31.92px", color: "#676767", margin: '0 auto',
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: 'center',
                          marginTop: "400px",
                        }}>You haven't created any list of selling something.</div>
                      ) : (
                        sellingSomethingDatas?.map((item: any) => {
                          return (
                            <>
                              <Grid item xl={4} lg={4} md={6} sm={6} xs={12} key={item.id}>
                               
                                  <ShoppingCardsSelling
                                    data-testid="viewHomesells"
                                  >
 <a data-testid={`ss-${item.id}`} style={styleD.linkView as React.CSSProperties} href={`/ViewListing/${item.id}`} onClick={(event) => {
                                    event.preventDefault();
                                    this.viewDataOnHome(item.id)
                                }}>
                                    <CardImage
                                      src={item.attributes.photo[0]?.url}
                                      alt="Card Image"
                                    />
                                    <BodyTypography>{item.attributes.title}</BodyTypography>
                                    <BudgetTypography>
                                      Price <StyledSpanPage> £ {item.attributes.budget}</StyledSpanPage>
                                    </BudgetTypography>
                                    </a>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                      }}

                                    >
                                      <CommissionTypographyPage>
                                        Reward <StyledSpanPage> £ {item.attributes.commission}</StyledSpanPage>
                                      </CommissionTypographyPage>
                                      <SaveImages
                                        src={this.state.savedListingsData?.includes(item.id) ? imageFilledHeart : imageEmptyhearts}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.state.savedListingsData?.includes(item.id) ? this.popupSaveds(item.id) : this.savedlistApiCallMain(item.id);
                                        }}
                                        alt="Card Image"
                                        data-testid="save-image2"
                                      />
                                    </div>
                                  </ShoppingCardsSelling>
                            
                              </Grid>
                            </>
                          )
                        })
                      )
                    }
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
  renderFindText() {
    return (
      <Box style={styleD.plateformContainer as React.CSSProperties}>
        <PlateformHeading>
          Find your all favourites at single platform
        </PlateformHeading>
      </Box>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    this.handleFullName()
    // Customizable Area End
    return (
      <SafeAreaView style={styles.mainContainer}>
        {/* Customizable Area Start */}
        <AppHeader navigation={this.props.navigation.navigate} />
        <Box style={{ maxWidth: "1440px", margin: '0 auto' }}>

          <Box>
            {this.renderDataText()}

            <Box style={styleD.MainPlateform as React.CSSProperties}>
              {this.renderFindText()}
              <Box style={{ textAlign: "center" }}>
              <a data-testid="explore" style={styleD.linkView as React.CSSProperties} href={`/FilterAllCategory`} onClick={(event) => {
                                      event.preventDefault();
                                      this.handleListingExplores()
                                  }}>
                <Button style={styleD.plateformExploreBtn as React.CSSProperties} 
                >Explore</Button></a>
                 <a data-testid="startlisting" style={styleD.linkView as React.CSSProperties} href={`/CreateListing`} onClick={(event) => {
                                      event.preventDefault();
                                      this.handleListing()
                                  }}>
                <Button style={styleD.plateformListingBtn as React.CSSProperties}
                  data-testid="handleListing">Start Listing</Button></a>
              </Box>
            </Box>
            <Grid container >
              <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
                <a href="/FilterAllCategory/10"  onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(10)}} data-test-id="nav-10">
                <Box style={{ cursor: "pointer" }}>
                  <Card style={styleD.cardMain as React.CSSProperties}>
                    <img src={sky} alt="" style={styleD.imgCard as React.CSSProperties} />
                    <Typography style={styleD.imgText as React.CSSProperties}>Services</Typography>
                  </Card>
                </Box></a>
              </Grid>

              <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
              <a href="/FilterAllCategory/9" onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(9)}} data-test-id="nav-9">
                <Box style={{ cursor: "pointer" }} >
                  <Card style={styleD.cardMain as React.CSSProperties}>
                    <img src={lamp} alt="" style={styleD.imgCard as React.CSSProperties} />
                    <Typography style={styleD.imgText as React.CSSProperties}>Interiors</Typography>
                  </Card>
                </Box></a>
              </Grid>

              <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
              <a href="/FilterAllCategory/13" onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(13)}} data-test-id="nav-8">

                <Box style={{ cursor: "pointer" }} >
                  <Card style={styleD.cardMain as React.CSSProperties}>
                    <img src={car} alt="" style={styleD.imgCard as React.CSSProperties} />
                    <Typography style={styleD.imgText as React.CSSProperties}>Vehicles</Typography>
                  </Card>
                </Box></a>
              </Grid>

              <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
              <a href="/FilterAllCategory/7"  onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(7)}} data-test-id="nav-7">

                <Box style={{ cursor: "pointer" }}>
                  <Card style={styleD.cardMain as React.CSSProperties}>
                    <img src={Jewellery} alt="" style={styleD.imgCard as React.CSSProperties} />
                    <Typography style={styleD.imgText as React.CSSProperties}>Jewellery</Typography>
                  </Card>
                </Box></a>
              </Grid>

              <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
              <a href="/FilterAllCategory/8"  onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(8)}} data-test-id="nav-6">

                <Box style={{ cursor: "pointer" }}>
                  <Card style={styleD.cardMain as React.CSSProperties}>
                    <img src={cloths} alt="" style={styleD.imgCard as React.CSSProperties} />
                    <Typography style={styleD.imgText as React.CSSProperties}>Clothing</Typography>
                  </Card>
                </Box></a>
              </Grid>

              <Grid item lg={2} md={2} sm={6} xl={2} xs={12}>
              <a href="/FilterAllCategory/11" onClick={(e) => {e.preventDefault(); this.AllFilterLandingPages(11)}} data-test-id="nav-11">
                <Box style={{ cursor: "pointer" }} >
                  <Card style={styleD.cardMain as React.CSSProperties}>
                    <img src={others} alt="" style={styleD.imgCard as React.CSSProperties} />
                    <Typography style={styleD.imgText as React.CSSProperties}>Others</Typography>
                  </Card>
                </Box></a>
              </Grid>
            </Grid>
            {this.renderLookingForSomethingSection()}
            {this.renderSellingSomethingSection()}
            <Box style={{ marginTop: "45px" }}>
              <Box style={styleD.containers as React.CSSProperties}>
                <Box style={styleD.lookingHeader as React.CSSProperties}>
                  <Typography style={styleD.lookingText as React.CSSProperties}>Create a Listing</Typography>
                </Box>
                <hr style={styleD.hr as React.CSSProperties} />
                <Grid container spacing={5} style={{ marginTop: "20px" }}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <LookingForSomething>
                      <OverLayBoxCreate />
                      <div style={{ padding: "30px", zIndex: 1, position: "relative" }}>
                        <LookingText>Looking for Something?</LookingText>
                        <ExperienceText>Experience the ultimate convenience of finding all your favourites in one place.
                          <WishlistText>
                            <li>Create a Wishlist. </li>
                            <li>Connect with Provisioner for purchase. </li>
                            <li>Get your product. Enjoy!</li>
                          </WishlistText>
                        </ExperienceText>
                        <div>
                        <a data-testid="lfslisting" style={styleD.linkView as React.CSSProperties} href={`/LookingSomething`} onClick={(event) => {
                                      event.preventDefault();
                                      this.LookingNavigation()
                                  }}>
                          <CreateListingButtonss
                            key={"1"}
                            data-testid="looking"
                            // onClick={this.LookingNavigation}
                          >
                            Create Listing
                          </CreateListingButtonss>
                          </a>
                        </div>
                      </div>
                    </LookingForSomething>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <SellingForSomething>
                      <OverLayBoxCreate />
                      <div style={{ padding: "30px", zIndex: 1, position: "relative" }}>
                        <LookingText>Selling Something?</LookingText>
                        <ExperienceText>Facilitate a seamless journey for individuals to achieve their dream Wishlist effortlessly.
                          <WishlistText>
                            <li>Create a product List.</li>
                            <li>Get in contact with buyer.</li>
                            <li> Provide hassle-free service. Get Reviews.</li>
                          </WishlistText>
                        </ExperienceText>
                        <div style={{}}>
                        <a data-testid="sslisting" style={styleD.linkView as React.CSSProperties} href={`/SellingSomething`} onClick={(event) => {
                                      event.preventDefault();
                                      this.SellingNavigation()
                                  }}>
                          <CreateListingButtonsss
                            key={"2"}
                            data-testid="seeling"
                            // onClick={this.SellingNavigation}
                          >
                            Create Listing
                          </CreateListingButtonsss>
                          </a>
                        </div>
                      </div>
                    </SellingForSomething>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box style={styleD.lookingMain as React.CSSProperties}>
              <Box style={styleD.containers as React.CSSProperties}>
                <Box style={styleD.lookingHeader as React.CSSProperties}>
                  <Typography style={styleD.lookingText as React.CSSProperties}>Saved Listings</Typography>
                  {/* <Link style={styleD.linkView as React.CSSProperties} onClick={this.handleLooking}>View all</Link> */}
                  <a data-testid="viewAll3" style={styleD.linkView as React.CSSProperties} href="/UserProfileBasicBlock?tab=savedlist" onClick={(event) => {
                      event.preventDefault();
                      this.handleSaveListings();
                  }}>View All</a>

                </Box>
                <hr style={styleD.hr as React.CSSProperties} />
                <Box style={styleD.mapFN as React.CSSProperties}>
                  <Box style={styleD.SavedLoading as React.CSSProperties}>
                    {this.state.LoaderListingS ?
                      <div
                        style={styleD.savedLoad}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress style={{ color: '#4fa94d' }} />
                        </Box>
                      </div> : <>
                        {
                          (!this.state?.savedListingData || this.state.savedListingData.length === 0) ? (
                            <div style={{
                              fontFamily: "Segoe UI", fontSize: "20px", lineHeight: "31.92px", color: "#676767", margin: '0 auto',
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              justifyContent: 'center',
                              height: "300px",
                            }}>You haven't saved any products.</div>
                          ) : (
                            this.state.savedListingData?.slice(0, 5).map((item: any) => {
                              const listingData = item.attributes.listing.data;
                              const listingId = listingData.id;
                              const listingAttributes = listingData.attributes;

                              return (
                                <>
                                 

                                    <ShoppingCardsSaved
                                      // onClick={() => this.viewDataOnHome(listingId)}
                                      data-testid="viewHome1"
                                    >

<a data-testid={`savedlist-${listingId}`} style={styleD.linkView as React.CSSProperties} href={`/ViewListing/${listingId}`} onClick={(event) => {
                                      event.preventDefault();
                                      this.viewDataOnHome(listingId)
                                  }}>
                                      <CardImageSaved
                                        src={listingAttributes.photo[0]?.url}
                                        alt="Card Image"
                                      />

                                      <BodyTypographySaved>{listingAttributes.title}</BodyTypographySaved>
                                      <BudgetTypographySaved>
                                        Budget <StyledSpanSaved>£ {listingAttributes.budget}</StyledSpanSaved>
                                      </BudgetTypographySaved>
                                      </a>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center"
                                        }}
                                      >
                                        <CommissionTypographySaved>
                                          Reward
                                          <StyledSpanSaved> £ {listingAttributes.commission}</StyledSpanSaved>
                                        </CommissionTypographySaved>
                                        <SaveImages
                                          src={
                                            this.state.savedListingsData?.includes(listingId)
                                              ? imageFilledHeart
                                              : imageEmptyhearts
                                          }
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.state.savedListingsData?.includes(listingId)
                                              ? this.popupSaveds(listingId)
                                              : this.savedlistApiCallMain(listingId);
                                          }}
                                          alt="Card Image"
                                          data-testid="save-image3"
                                        />
                                      </div>
                                    </ShoppingCardsSaved>
                                
                                </>
                              );
                            })
                          )
                        }
                      </>}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Contactus>
              <Box style={styleD.containers as React.CSSProperties}>
                <ContactusGrid container>
                  <Grid item xl={8} lg={8} md={6} xs={12} sm={12} style={styleD.contactusText as React.CSSProperties}>
                    <Typography style={styleD.contactTextT as React.CSSProperties}>Got questions or feedback?
                    </Typography>
                    <Typography style={styleD.contactTextT as React.CSSProperties}> Get in touch with us.
                    </Typography>
                    <a data-testid="contactusNav" style={styleD.linkView as React.CSSProperties} href="/AddContactusNew" onClick={(event) => {
                                  event.preventDefault();
                                  this.ContactusNavigation()
                              }}>
                    <Button style={styleD.plateformListingBtns as React.CSSProperties} data-testid="contactus">Contact us</Button></a>
                  </Grid>
                  <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                    <ContactusImg src={contactusnew} alt=""></ContactusImg>
                  </Grid>
                </ContactusGrid>
              </Box>
            </Contactus>
            <div>
              <Popup
                open={this.state.savedPopupLanding}
                test-id="popup"
                closeOnDocumentClick onClose={this.saveClosePopups}
                className="popup"
                contentStyle={styleD.contentSave}
                overlayStyle={styleD.overlayses}
              >
                <div>
                  <div style={styleD.popupIMGMainsLanding as React.CSSProperties} >
                    <img src={imagePopupSaves} alt="popup" />
                  </div>
                  <div >
                    <p style={styleD.popuptexts as React.CSSProperties} >Added to Saved Listing!</p>
                  </div>
                  <div>
                    <p style={styleD.popuptextss as React.CSSProperties} >Go to
                      <Link style={styleD.signlinks as React.CSSProperties}
                        onClick={this.handleSaveListings}
                      >
                        Saved Listings
                      </Link>
                    </p>
                  </div>
                </div>
              </Popup>
            </div>
            <div>
              <Popup
                open={this.state.savedPopups}
                test-id="popup"
                closeOnDocumentClick onClose={this.saveClosePopupss}
                className="popup"
                contentStyle={styleD.contentSaveSelling}
                overlayStyle={styleD.overlaysesSelling}
              >
                <div>
                  <div style={styleD.popupIMGMains as React.CSSProperties} >
                    <img src={imagePopupSaves} alt="popup" />
                  </div>
                  <div >
                    <p style={styleD.popuptexts as React.CSSProperties} >Added to Saved Listing!</p>
                  </div>
                  <div>
                    <p style={styleD.popuptextss as React.CSSProperties} >Go to
                      <Link style={styleD.signlinkss as React.CSSProperties}
                        onClick={this.handleSaveListings}
                      >
                        Saved Listings
                      </Link>
                    </p>
                  </div>
                </div>
              </Popup>
            </div>

            <div>
              <Popup
                open={this.state.removeSavedList}
                test-id="popup"
                closeOnDocumentClick onClose={this.navigateSavedSomethingLanding}
                className="popup"
                contentStyle={styleD.contentsDeleteHome}
                overlayStyle={styleD.overlaysDeletesHome}
              >
                <div style={styleD.deletePopupDeletesHome as React.CSSProperties}>
                  <RemoveListingPopup navigation={this.props.navigation.navigate} />
                  <Box >
                    <ContactusGridCancel onClick={this.navigateSavedSomethingLanding} data-testid="save-navigate">Cancel</ContactusGridCancel>
                    <ContactusGridDelete data-testid="save-navigate1"
                      onClick={() => this.deleteSavelistingCallMain(this.state.deleteItemIds)}
                    >Remove</ContactusGridDelete >
                  </Box>
                </div>
              </Popup>
            </div>
            <div>
              <Popup
                open={this.state.successRemoveSaving}
                test-id="popup"
                closeOnDocumentClick onClose={this.successpopupSavedLanding}
                className="popup"
                contentStyle={styleD.contentsPasswordsLanding}
                overlayStyle={styleD.overlaysBtnLanding}
              >
                <div>
                  <img src={group_CrossBtns} alt="Cross" style={styleD.imgCrossesLs as React.CSSProperties} onClick={this.closePopupPasswordLanding} />
                  <div style={styleD.popupIMGMainssBtnLs as React.CSSProperties} >
                    <img src={deleteSavingss} alt="Crosss" />
                  </div>
                  <p style={styleD.passwordChangesLs as React.CSSProperties}>{this.state.deleteItems}</p>
                </div>
              </Popup>
            </div>

          </Box>
        </Box>
        <Box>
          <AppFooter naviagtion={this.props.navigation.navigate} />
        </Box>

        {/* Customizable Area End */}
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  mainContainer: {
    width: "100%"
  },
});
const styleD = {
  containers: {
    padding: "20px 77px 20px 77px"
  },
  modalStylesLanding: {
    fontSize: '12px',
  },
  modalStylesSelling: {
    fontSize: '12px',
  },
  actionsStyles: {
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  actionsStylesSelling: {
    padding: '10px 5px',
    margin: 'auto',
    textAlign: 'center',
  },
  overlayses: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentSave: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '400px'
  },
  overlaysesSelling: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  SavedLoading: {
    display: 'flex',
    gap: 32,
    width: '100%'
  },
  contentSaveSelling: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '657px',
    borderRadius: '8px',
    height: '400px'
  },
  lookLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    margin: '0 auto'
  },
  popupIMGMains: {
    margin: "15px 0px 15px 0px",
    display: "flex",
    justifyContent: "center"
  },
  savedLoad: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    margin: '0 auto'
  },
  popupIMGMainsLanding: {
    margin: "15px 0px 15px 0px",
    display: "flex",
    justifyContent: "center"
  },
  sellLoad: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: '0 auto'
  },
  popuptexts: {
    fontSize: '26px',
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '28px'
  },
  lookLoad: {
    display: 'flex',
    gap: 32,
    width: '100%'
  },
  popuptextss: {
    fontSize: '18px',
    textAlign: "center",
    fontFamily: "Segoe UI",
    color: "#181818"
  },
  signlinks: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  signlinkss: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",
    textDecoration: 'none',
  },
  menuMain: {
    display: "flex",
    flexWarp: "warp",
    height: "150px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  hr: {
    width: "98px",
    height: "3px",
    backgroundColor: " #3CAA49",
    border: "none",
    margin: "10px 0"
  },
  menuItem: {
    padding: "17px 47px",
    fontFamily: "Segoe UI",
    fontSize: "20px",
    color: "#676767"
  },
  MainPlateform: {
    height: "320px",
    background: "#ECF7ED",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  plateformContainer: {
    marginBottom: "30px"
  },
  plateformExploreBtn: {
    height: "60px",
    width: "150px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "20px",
    textTransform: "capitalize",
  },
  plateformListingBtn: {
    height: "60px",
    width: "220px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "1px solid #3CAA49",
    textTransform: "capitalize",
  },
  div: {
    width: "200px",
    height: '44px',
    fontFamily: "Segoe UI Bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#676767",
    display: 'flex',
    alignItems: 'center'
  },
  cardMain: {
    width: "98%",
    height: "350px",
    position: 'relative',
  },
  imgCard: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imgText: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    color: 'white',
    padding: '8px',
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    textAlign: 'center',
  },
  lookingMain: {
    margin: "0px 0px"
  },
  lookingHeader: {
    margin: "50px 0 5px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  lookingText: {
    fontFamily: "Segoe UI Bold",
    fontSize: "30px",
    color: "#181818",
  },
  mapFN: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  Contactuscontainers: {
    padding: "0px 56px",
    "@media(max-width:958px) and (min-width: 0px)": {
      textAlign: "center",
    },
  },
  linkView: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    cursor: "pointer",
    textDecoration: 'none'
  },
  imgSell: {
    width: "100%",
    height: "810px",
  },
  contactusText: {
    // alignSelf: "center",
    // marginTop: "10%",
    marginBottom: "80px",
  },
  contactTextT: {
    fontFamily: "Segoe UI Bold",
    fontSize: "30px",
    color: "#676767"
  },

  plateformListingBtns: {
    height: "60px",
    width: "220px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "1px solid #3CAA49",
    marginTop: "3%",
    textTransform: 'none'
  },
  contentsDeleteHome: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '20px',
    width: '527px',
    borderRadius: '8px',
    height: '322px'
  },
  overlaysDeletesHome: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  deletePopupDeletesHome: {
    marginTop: "85px",
    paddingLeft: '52px'
  },
  overlaysBtnLanding: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  contentsPasswordsLanding: {
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    maxWidth: '80%',
    padding: '30.5px 43.5px 30.5px 43.5px',
    width: '500px',
    borderRadius: '8px',
    height: '350px'
  },
  popupIMGMainssBtnLs: {
    width: '232px',
    heigth: '232px',
    margin: 'auto',
  },
  passwordChangesLs: {
    fontSize: '30px',
    marginTop: "15px",
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#000000",
    LineHeigth: '32px',
    margin: 'auto',
  },
  imgCrossesLs: {
    position: 'absolute',
    top: 38,
    margin: 'auto',
    right: 36,
    cursor: 'pointer',
  },
}
// Customizable Area End
