// Customizable Area Start
import React from "react";
import { Box, styled,  Card, Typography, CircularProgress, Button} from "@material-ui/core";
import { LogoutImage, imageLocations,profiles, settingImage } from "./assets";
import { TiStar   } from "react-icons/ti";
import { imageHomeCross } from "../../landingpage/src/assets";
import MyReviewsDataController from "./MyReviewsDataController.web";
import { AppHeader } from "../../../components/src/AppHeader";
import moment from "moment";
import { Rating } from "@material-ui/lab";
import Popup from 'reactjs-popup';

const FormGridDataReview = styled(Box)({
    display: 'flex',
});
const CustomBoxDataReview = styled(Box)({
    width: '336px',
    borderRadius: '0px 15px 0px 0px',
    backgroundColor: '#E0DAD3',
    position: 'sticky',
    height: 'calc(100vh - 80px)',
    top: '80px',
    '@media(max-width: 1150px)': {
        display: 'none'
    },
    '@media(min-width: 1150px)': {
        minWidth: '336px',
    }
})
const CustomContentDataReview = styled(Box)({
    display: 'columns',
    Width: 'calc(100% - 150px)',
    minHeight: '100vh',
    borderRadius: '0px, 0px, 0px, 32px',
})
const IconsProfileDataReviews = styled(Box)({
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,

    '@media (max-width: 767px)': {
        flexDirection: 'column',
    },
});
const LogoImageDataReviews = styled("img")({
    width: "28px",
    height: "28px",
});

const LogoTextDataReviews = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: "center",
    lineHeight: "26.6px",
    margin: 0,
});
const LogoutLogoImageDataReviews = styled("img")({
    width: "28px",
    height: "28px",
});

const LogoutTextDataReviews = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: "center",
    lineHeight: "26.6px",
    margin: "0",
});

const ProfileImgDataReviews = styled('img')({
    width: 134,
    height: 134,
    borderRadius: 135,
});
const CustomH6DataReviewss = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "24px",
    alignItems: 'center',
    lineHeight: 1,
    marginBottom: "5px",
    marginTop: "26px",
});
const CustomHeadingDataReviews = styled("h6")({
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: 'center',
    lineHeight: 1,
    margin: "0",
});
const CustomH1DataHeader = styled("h1")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: 'center',
    lineHeight: 1,
    display: 'flex',
    margin: 0,
});
const LocationBoxDataBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    textAlign: "center",
    gap: "5px",
    justifyContent: "center",
    marginTop: "8px"
});
const LocationImgsDataImgImgs = styled('img')({
    width: '21.1px',
    height: '21px',
});
const FlexDivDataCenter = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    gap: "10px",
    cursor: 'pointer'
});
const LogoutDivDataBoxss = styled(Box)({
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    gap: "10px",
    marginBottom: "20px",
    cursor: "pointer",
});

const HomeTextDivsDataDiv = styled('div')({
    display: 'flex',
    gap: '10px',

    alignItems: 'center',
    marginLeft: "22px",
    "@media(min-width:1440px)": {
        marginLeft: "22px",
        margin: 'auto',
    
    }
});

const CardImageImg = styled("img")({
  width: "20%",
  height: "20%",
  objectFit: "cover",
  "@media(max-width:1150px)": {
    width: '100%',
  }
});
const LoaderReview = styled(Box)({
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "rgb(247, 245, 245)"
  });

const styleDN = {
  homeTextbar: {
      color: "#676767",
      fontFamily: "Segoe UI",
      fontSize: "24px",
      cursor: "pointer"
  },
  lookingText: {
      color: "#676767",
      fontFamily: "Segoe UI",
      fontSize: "12px",
      cursor: "pointer",
  },
  imageHomeCrosses: {
      width: "7.41px",
      height: "12px",
      margin: "0px"
  },
  lookingTextsMain: {
      fontFamily: "Segoe UI",
      fontSize: "24px",
      color: "#3CAA49",
      marginLeft: '24px'
  },
  mainContainers: {
      padding: '40px 50px 40px 40px'
  },
  cardMainStyles: {
      width: "80%",
      height: "359px",
      borderRadius: "8px",
      padding: "20px 21.5px 17px 21.5px",
      justifyContent: 'space-between',
      backgroundColor: "#FFFFFF",
      flexWrap: 'wrap',
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      cursor: "pointer"
  },
  listingTyes: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 0'
  },
  lookings: { paddingRight: '10px', width: '25px', height: '25px' },
  lookingTexts: { paddingTop: '5px', fontSize: '12px' },
  userPhotos: {
      width: '28px',
      height: '28px',
      borderRadius: '50%',
      marginRight: '10px'
  },
  titileMain: {
      fontFamily: "Segoe UI",
      fontSize: "24px",
      color: "#3CAA49",
  },
  userDetails: {
      fontFamily: "Segoe UI Bold",
      fontSize: "16px",
      color: "#676767",
  },
  userMains: {
      margin: '15px 0'
  }
}


export default class MyReviewsData extends MyReviewsDataController {

    logoutDialog = () => {
        return (
          <Popup
            open={this.state.isOpen}
            closeOnDocumentClick
            overlayStyle={webStyle.overlayStyle}
            contentStyle={webStyle.contentStyle}
            onClose={this.onClosePopUp}
          >
            <Wrapper>
              <p className="title">Logging out</p>
              <div>
                <p className="description">Are you sure you want to leave?</p>
              </div>
              <Box marginTop="40px">
                <Button className="cancel-btn" data-test-id="cancel-btn" onClick={this.onClosePopUp}>Cancel</Button>
                <Button className="logout-btn" data-test-id="logout-btn" onClick={this.handleButtonClicks}>Log-out</Button>
              </Box>
            </Wrapper>
          </Popup>
        );
    }

    getLocation = () => {
        return (
            <CustomH1DataHeader>
                {this.state.citys ?? "__"},{" "}{this.state.countrys ?? "__"}
            </CustomH1DataHeader>
        );
    }

       ReviewCard () {
        const userPhoto = localStorage.getItem("profilePhoto")
        const {reviewData} =this.state
        const currentUrl = window.location.href;
        const param = new URLSearchParams(new URL(currentUrl).search)
        const userid = param.get("userid")

        return (
<>

            <Box width="100%">
                    <AppHeader navigation={this.props.navigation.navigate} />
                    <FormGridDataReview>
                        <CustomBoxDataReview>
                            <IconsProfileDataReviews>
                           { userid ?
                            <ProfileImgDataReviews
                                    //@ts-ignore
                                    src={  !this.state.useProfiless ? profiles : this.state.useProfiless }
                                    alt="Logo Images"
                                /> :
                                <ProfileImgDataReviews
                                    //@ts-ignore
                                    src={userPhoto == null ? profiles : userPhoto}
                                    alt="Logo Image"
                                />}
                                

                              {  userid ?
                                (
                                <><CustomH6DataReviewss>{this.state.useProfileFullName}</CustomH6DataReviewss>
                                <CustomHeadingDataReviews>@{this.state.useProfileUserName}</CustomHeadingDataReviews>
                                <LocationBoxDataBox>
                                    <LocationImgsDataImgImgs
                                        src={imageLocations}
                                        alt="Logo" />
                                    <CustomH1DataHeader>{this.state.cityProfile == null ? "__" :this.state.cityProfile},<Typography style={{ marginLeft: '7px' }}>{this.state.countryProfile == null ? "__" : this.state.countryProfile}</Typography></CustomH1DataHeader>
                                </LocationBoxDataBox>
                                </>)
                                :
                               ( 
                               <><CustomH6DataReviewss>{this.state.useProfiles?.attributes?.full_name}</CustomH6DataReviewss>
                                <CustomHeadingDataReviews>@{this.state.useProfiles?.attributes?.user_name}</CustomHeadingDataReviews>
                                <LocationBoxDataBox>
                                    <LocationImgsDataImgImgs
                                        src={imageLocations}
                                        alt="Logo" />
                                    {this.getLocation()}
                                </LocationBoxDataBox>
                                </>)}
                            </IconsProfileDataReviews>
                            {!this.state.isOtherUserProfile && <div style={{ position: 'absolute', bottom: 0, left: "50%", transform: 'translateX(-50%)' }}>
                                <FlexDivDataCenter data-test-id='handleButtonClickSettings' onClick={this.handleButtonClickSettings}>
                                    <LogoImageDataReviews src={settingImage} alt="Logo" />
                                    <LogoTextDataReviews>Settings</LogoTextDataReviews>
                                </FlexDivDataCenter>
                                <LogoutDivDataBoxss data-test-id='handleButtonClicks' onClick={this.onOpen}>
                                    <LogoutLogoImageDataReviews src={LogoutImage} alt="Logout Logo" />
                                    <LogoutTextDataReviews>Logout</LogoutTextDataReviews>
                                </LogoutDivDataBoxss>
                            </div>}
                        </CustomBoxDataReview>
                        <CustomContentDataReview flex="1" >
                            <HomeTextDivsDataDiv>
                                <p style={styleDN.homeTextbar as React.CSSProperties}
                                data-test-id="go-back"
                                onClick={this.onGoBack}
                                >Reviews</p>
                                <div>
                                    <img src={imageHomeCross} style={styleDN.imageHomeCrosses as React.CSSProperties} alt="" />
                                </div>
                                <span style={styleDN.titileMain}>
                                    {reviewData.attributes?.user.name}
                                </span>
                            </HomeTextDivsDataDiv>
                            <Box height="calc(100% - 192px)" style={{background: 'rgb(247, 245, 245)',padding:"40px 72px 72px 40px"}}>
            
          <Card className="review_card" style={{ fontFamily:'Segoe UI', padding:32,minWidth: 275 , maxWidth:'100%', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)'}}>

          <div style={{ paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '-5' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {reviewData.attributes?.rating &&<Rating name="read-only" value={reviewData.attributes?.rating } readOnly size="small" precision={0.25}/>}


  <span style={{ marginLeft: '7px', fontSize: '14px', fontWeight:1800,fontFamily: "Segoe UI Bold", }}>{reviewData.attributes?.rating.toFixed(1)}</span>
</div>
 
</div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', borderTop:'1px solid #e3e4e6',paddingTop:'10px' }}>
    <div style={{ width:'8%'}}> 
    <CardImageImg style={{  width:55, height: 55 , objectFit:'cover'}} src={reviewData.attributes?.user.image ==null ? profiles :reviewData.attributes?.user.image}></CardImageImg>   </div>
   
    <div style={{ width:'92%', paddingLeft:'20px'}}> <Typography style={{  textTransform: 'uppercase',  fontFamily: "Segoe UI Bold", fontSize: '19px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{reviewData.attributes?.user.name}</Typography></div>
    <span style={{ fontFamily: "Segoe UI" ,fontSize: '12px', color: '#676767CC' , width:'20%' }}> {
                 `${moment(reviewData.attributes?.created_at ?? reviewData.attributes?.updated_at).format("Do MMMM YYYY, HH:mm")}`
              }</span>
</div>
<p><Typography style={{fontFamily:'Segoe UI', fontSize:'16px',color:"#676767"}}>
                    {reviewData.attributes?.description}</Typography>
                </p>           
                <div style={{ paddingTop: '10px', paddingBottom: '10px',borderTop: '1px solid #e3e4e6', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop:'-5' }}>
                <div ><CardImageImg style={{ width: '100px', height: '100px', objectFit: 'contain' }} src={reviewData.attributes?.listing.image[0] == null ? profiles : reviewData.attributes?.listing.image[0]}></CardImageImg></div>
                <div style={{width:'88%', padding:7}}><Typography style={{fontSize: '20px', fontFamily: "Segoe UI Bold", color:'#676767', textTransform:'capitalize' }}>{reviewData.attributes?.listing.title}</Typography></div>
</div>



          </Card>
          </Box>
                            
                        </CustomContentDataReview>
                    </FormGridDataReview>
                </Box>

         
          </>
        );
      };
  


     


  render() {
    const { LoaderNegotiationData, LoaderListingReview } = this.state;

    if (LoaderNegotiationData && LoaderListingReview) {
      return (
        <LoaderReview>
          <Box>
            <CircularProgress style={{ color: "#4fa94d" }} />
          </Box>
        </LoaderReview>
      );
    }
    return (
        <>
            {this.ReviewCard()}
            {this.logoutDialog()}
        </>
    );
  }
  
  }

const Wrapper = styled(Box)({
    maxWidth: "290px", 
    margin: "auto",
    "& .description": {
      margin: "0px",
      color: '#676767',
      marginTop: "16px",
      fontSize: '18px',
      fontFamily: "Segoe UI",
      LineHeight: '26px',
    },
    "& .title": {
      margin: "0px",
      color: '#181818',
      fontSize: '32px',
      fontFamily: "Segoe UI Bold",
      lineHeight: '32px'
    },
    "& .cancel-btn": {
      height: "56px",
      fontFamily: "Segoe UI Bold",
      width: "135px",
      borderRedius: "4px",
      color: "#ECF7ED",
      fontSize: "20px",
      border: "none",
      marginRight: "20px",
      background: "#3CAA49",
      textTransform: "capitalize",
    },
    "& .logout-btn": {
        color: "#3CAA49",
        width: "135px",
        textTransform: "capitalize",
        borderRedius: "4px",
        background: "#FFFFFFE5",
        height: "56px",
        fontFamily: "Segoe UI Bold",
        fontSize: "18px",
        border: "1px solid #3CAA49",
    },
});

const webStyle = {
    contentStyle: {
      backgroundColor: '#fff',
      maxWidth: '290px',
      padding: '80px 120px 80px 120px',
      width: '100%',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
    },
    overlayStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};
// Customizable Area End
