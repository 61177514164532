import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    termsCondsId: string;
    refundPolicyData: any;
    isLoading: boolean;
    LoaderListing: boolean;
    termsCondsData: any;
    chnageColor: any;
    title: any;
    discription: any
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start

    // Customizable Area End
}

// Customizable Area Start
export interface IRefundPolicy {
    id: string;
    attributes: {
      created_at: string;
      description: string;
      accepted_by: [
        {
          account_id: string;
          accepted_on: string;
          email: string;
        }
      ];
    };
  }
  // Customizable Area End

export default class RefundPolicyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRefundPolicyCallId: string = "";
    addRefundPolicyCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            token: "",
            termsCondsId: "",
            refundPolicyData: [],
            isLoading: false,
            termsCondsData: "",
            LoaderListing: true,
            title: "",
            discription: "",
            chnageColor: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }



    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        const RefundapiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const RefundresponseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            RefundapiRequestCallId === this.getRefundPolicyCallId &&
            RefundresponseJson.data
        ) {
            this.setState({ LoaderListing: false })
            this.getRefundPolicyCallId = "";
            this.setState({
                refundPolicyData: RefundresponseJson.data,
                isLoading: false,
            });
        }
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            RefundapiRequestCallId === this.addRefundPolicyCallId &&
            RefundresponseJson.data
        ) {

            this.setState({
                termsCondsData: RefundresponseJson.data,
                title: RefundresponseJson.data.attributes.title,
                discription: RefundresponseJson.data.attributes.description,
                isLoading: false,
            });
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getRefundPolicyDetails()
        this.RefundPolicygetToken();
    }

    RefundPolicygetToken = () => {
        const message: Message = new Message(
          getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
      };


    // we can get terms and condition details
    getRefundPolicyDetails = () => {
        const requestMessageAdd = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getRefundPolicyCallId = requestMessageAdd.messageId
      
          requestMessageAdd.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_terms_and_conditions/refund_policies"
          );
      
          requestMessageAdd.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
              "Content-Type": "application/json",
            })
          );
      
          requestMessageAdd.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
          );
      
          runEngine.sendMessage(requestMessageAdd.id, requestMessageAdd);
          return true;
    };

    // Customizable Area End

}