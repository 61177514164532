export const leftarrow = require("../assets/leftarrow.png");
export const likeIcn = require("../assets/likeIcn.png");
export const male = require("../assets/male.png");
export const produc = require("../assets/produc.png");
export const girl = require("../assets/girl.png");
export const Rectangle = require("../assets/Rectangle.png");
export const RIGHTTICK = require("../assets/RightTick.png");
export const  imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgPasswordVisible= require("../assets/ic_password_visible.png");
export const createListingIcon= require("../assets/CreateListing.png");
export const cameras= require("../assets/camera.png")
export const imagePopupSomething = require("../assets/imagePopupSomething.png")
export const imageDirectSending = require("../assets/imageDirectSend.png")
export const deletesIcon = require("../assets/deleteIcon.png")
export const arrowDown = require("../assets/arrow-down.png")
export const Look = require("../assets/Look.png")
export const Sell = require("../assets/Sell.png")
export const dummyImg= require("../assets/dummy_img.png")
export const editImages= require("../assets/editImages.png");
export const popImages= require("../assets/pop.jpeg");
export const ImageCrossIcon= require("../assets/image_close.png");
export const profiles= require("../assets/profiles.jpeg");
