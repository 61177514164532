// Customizable Area Start
import React from "react";
import { Typography, Paper, FormControl, FormLabel, TextField, Button, Dialog } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ForgotPasswordControllerWeb from "./ForgotPasswordController.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#3CAA49",
            contrastText: "#fff",
        },
    },
});
export default class ForgotPasswordWeb extends ForgotPasswordControllerWeb {

    ForgotPass = () => {
        const { showResendBox } = this.state
        
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Dialog
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",


                        boxShadow:
                            " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                    }}
                    onClose={this.props.closeModal}
                    data-test-id="editmodal"
                    open={this.props.dataPass}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Paper
                        elevation={4}

                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", padding: "100px 100px" }}>
                            {this.state.errorMsg && <Typography style={{ color: "#FE4023", backgroundColor: "#ffded6", padding: "12px", width: "100%", borderLeft: "2px solid #FE4023" , fontFamily: "Segoe UI" }}>{this.state.errorMsg}</Typography>}
                            <Typography id="headerText" style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Segoe UI bold" }}>{showResendBox ? "Check Your Mail" : "Forgot Password ?"}</Typography>

                            <Typography style={{ fontSize: "18px", fontWeight: 400, fontFamily: "Segoe UI" }}>{showResendBox ? `Please check the email address ${this.state.emailValue} for instructions to reset your password.` : "Enter the email associated with your account, we will send a recovery link to reset your password."}</Typography>

                            {!showResendBox &&
                                <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                                    <FormLabel style={{ fontSize: "20px", fontWeight: 700, fontFamily: "Segoe UI bold", marginBottom: "8px" }}>Email</FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={this.state.emailValue}
                                        style={{ backgroundColor: "#ffffff" }}
                                        name="ein"
                                        data-test-id="emailvalue"
                                        color="primary"
                                        onChange={this.handleEmailChange}
                                        InputProps={{
                                            placeholder: "Your Email",
                                            style: { borderRadius: "8px", fontFamily: "Segoe UI" }
                                        }}

                                        fullWidth
                                    />
                                    {this.state.emailError ? <Typography style={{ fontSize: "12px", color: "red" }}>{this.state.emailError}</Typography> : null}
                                </FormControl>
                            }

                            {this.UserButton()}
                            <Typography style={{ textAlign: "center", width: "100%", fontFamily: "Segoe UI ", fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>Back to <Button onClick={this.handleCloseModal} style={{ fontFamily: "Segoe UI", fontWeight: 700, fontSize: "18px", textTransform: "none", color: "#3CAA49" }}>
                                <Typography style={{ fontFamily: "Segoe UI bold " }}>Log-in</Typography></Button></Typography>
                        </div>
                    </Paper>
                </Dialog>
            </div>
        );
    };


    UserButton = () => {
        const { showResendBox } = this.state;

        const resendButton = (
            <Button fullWidth data-test-id="resendbtn" variant="outlined" style={{ textTransform: "none", padding: "0.8rem", fontFamily: "Segoe UI", borderRadius: "8px", borderColor: "#3CAA49" }} onClick={this.handleResetLink}>
                <Typography style={{ fontSize: "20px", fontWeight: 700, fontFamily: "Segoe UI bold", color: "#3CAA49" }}>Resend Email</Typography>
            </Button>
        );

        const recoveryButton = (
            <Button fullWidth data-test-id="recvryBtn" variant="contained" style={{ backgroundColor: "#3CAA49", textTransform: "none", padding: "0.8rem", fontFamily: "Segoe UI", borderRadius: "8px" }} onClick={this.showResendbox}>
                <Typography style={{ fontSize: "20px", fontWeight: 700, fontFamily: "Segoe UI bold", color: "#ffffff" }}>Send Recovery Link</Typography>
            </Button>
        );

        return (
            showResendBox ? resendButton : recoveryButton
        );
    };

    render() {

        return <ThemeProvider theme={theme}>{this.ForgotPass()}</ThemeProvider>;
    }
}

// Customizable Area End
