import React from 'react';
import { Box, styled } from "@material-ui/core";

export function Inbox() {
  const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '350px',
    maxWidth: 'auto',
    margin: '30 auto 10 auto',
  });
  const HeadingText = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    fontSize: '20px',
    fontFamily: 'Segoe UI',
    lineHeight: '20px',
    color: '#676767',
  });

  return (
    <Box style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",width:"100%", marginBottom: "30px"}}>
      <Container>
      <img src={require('./ListingEmptyImage.png')} alt="Logo" />
      </Container>
      <div>
        <HeadingText>
        Your Inbox is empty 
        </HeadingText>
      </div>
    </Box>
  );
}

