// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
 
}

interface S {
  countdown: any;
  redirect: boolean; 
  showLink: boolean;
  datas: any;
  rating:any
  isReviewPopupOpen: boolean,
  isReviewSubmitted: boolean,
  isReviewSubmittedPopupOpen:boolean,
  reviewDescription:any,
  dealId:any,
  reviewButton: boolean,
  reviewStatus : any


}

interface SS {
  id: any;}

export default class PaymentSuccessController extends BlockComponent<
  Props,
  S,
  SS
> {

    ShowLinkApidata: string ="";
    addReviewsId: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {

      redirect: true,
      countdown: 3,
      showLink: false,
      datas: {},
      rating:0,
      isReviewPopupOpen: false,
      isReviewSubmitted:false,
      isReviewSubmittedPopupOpen:false,
      reviewDescription:"",
      dealId:"",
      reviewButton: false,
      reviewStatus : {}

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.ShowLinkApidata) {
        if (responseJson.data && responseJson.data.link !== null) {
            this.setState({ showLink: true, datas: responseJson.data, dealId:responseJson.data.deal_id ,
              reviewStatus: responseJson.data.is_review_sent
            });
        }
    }
    if (apiRequestCallId === this.addReviewsId) {
      if(responseJson.data && !responseJson.error){
      this.setState({reviewButton:false})
      this.setState({isReviewSubmitted:true, isReviewSubmittedPopupOpen: true})      }
    }
    
      }
  }



  async componentDidMount() 
  {
    const url = window.location.href;

    const urlParts = url.split("/");
    const id = urlParts[urlParts.length - 1];
    this.FromApprovalDatas(id)

  
    }

    handleRefreshClick = () => {
      window.location.reload();
  }
  setRedirectTimer() {
    setInterval(() => {
      if (this.state.countdown > 0) 
      {this.setState((prevState) => ({
          countdown: prevState.countdown - 1,
        }));
      } 
      else 
      {
        this.setState({ redirect: false });}
    }, 
    1000);}

    toggleReviewSubmittedPopup = () => {
      this.handleRefreshClick()      
    };
    toggleReviewPopup = () => {
      this.setState((prevState) => ({
        isReviewPopupOpen: !prevState.isReviewPopupOpen,
      }));
    };
    reviewsAdd = () =>{
      this.setState({reviewButton:true})
      this.addReviews()

    }
    
    FromApprovalDatas = async (id: any) => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.ShowLinkApidata = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_locallistingads/initiate_deals/${id}/view_product_link`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            "Content-Type": "application/json",
            token: token,
          })
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

      addReviews = async () => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message
        (
          getName(MessageEnum.RestAPIRequestMessage));
    
        this.addReviewsId = requestMessage.messageId;
        const payload = {
          "initiate_deal_id": this.state.dealId ,
          "rating": this.state.rating,
          "description": this.state.reviewDescription
        };
    
    
        requestMessage.addData
        (
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_locallistingads/deal_feedbacks` );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            "Content-Type": "application/json",
            token: token,}) );
    
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
    
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST");
       
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };



}

// Customizable Area End