// Customizable Area Start
import React from "react";
import { Box, Button, CircularProgress, Typography, styled, } from "@material-ui/core";
import Popup from 'reactjs-popup';
import { Look, Sell } from "../../locallistingads/src/assets";
import NegotiationUserDataController from "./NegotiationUserDataController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { LogoutImage, imageLocations, profiles, settingImage, image_clock, red_circle } from "./assets";
import { imageHomeCross } from "../../landingpage/src/assets";
const FormGridDataNegotiation = styled(Box)({
    display: 'flex',
});
const MainDivOtherNegotiate = styled("div")({
    paddingLeft: '25px',
    display: "grid",
    gridGap: '15px',
    justifyContent: 'flex-start',
    gridTemplateColumns: "auto auto auto auto",
    "@media(min-width:1150px) and (max-width: 1260px)": {
        gridTemplateColumns: "auto auto auto"

    },
    "@media(max-width:960px)": {
        gridTemplateColumns: "auto auto auto",
    },
    "@media(max-width:767px)": {
        gridTemplateColumns: "auto auto"
    },
    "@media(max-width:450px)": {
        gridTemplateColumns: "auto",
        justifyContent: 'center',
    }
});
const CustomBoxDataMain = styled(Box)({
    width: '336px',

    borderRadius: '0px 15px 0px 0px',
    backgroundColor: '#E0DAD3',
    position: 'sticky',
    height: 'calc(100vh - 80px)',
    top: '80px',
    '@media(max-width: 1150px)': {
        display: 'none'
    },
    '@media(min-width: 1150px)': {
        minWidth: '336px',
    }
})
const CustomContentDatas = styled(Box)({
    display: 'columns',
    Width: 'calc(100% - 150px)',
    minHeight: '100vh',
    borderRadius: '0px, 0px, 0px, 32px',
})
const IconsProfileDataMain = styled(Box)({
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,

    '@media (max-width: 767px)': {
        flexDirection: 'column',
    },
});
const LogoImageDataSetting = styled("img")({
    width: "28px",
    height: "28px",
});

const LogoTextDataSetting = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: "center",
    lineHeight: "26.6px",
    margin: 0,
});
const LogoutLogoImageDatas = styled("img")({
    width: "28px",
    height: "28px",
});

const LogoutTextDataLogouts = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: "center",
    lineHeight: "26.6px",
    margin: "0",
});

const ProfileImgDatas = styled('img')({
    width: 134,
    height: 134,
    borderRadius: 135,
});
const CustomH6Datas = styled("h6")({
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "24px",
    alignItems: 'center',
    lineHeight: 1,
    marginBottom: "5px",
    marginTop: "26px"
});
const CustomHeadingDatas = styled("h6")({
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    alignItems: 'center',
    lineHeight: 1,
    margin: "0",
});
const CustomH1Datas = styled("h1")({
    color: "#000000",
    fontFamily: "Segoe UI",
    fontSize: "16px",
    alignItems: 'center',
    lineHeight: 1,
    display: 'flex',
    margin: 0,
});
const LocationBoxDatas = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: "5px",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "8px"
});
const LocationImgsDatas = styled('img')({
    width: '21.1px',
    height: '21px',
});
const FlexDivDatas = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    gap: "10px",
    cursor: 'pointer'
});
const LogoutDivDatas = styled(Box)({
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    gap: "10px",
    marginBottom: "20px",
    cursor: "pointer",
});
const HomeTextDivsDatas = styled('div')({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginLeft: "22px",
    "@media(min-width:1440px)": {
        marginLeft: "22px",
        margin: 'auto',
    }
});
const ShoppingCardsNegotiatesData = styled(Box)({
    position: "relative",
    width: "193px",
    maxWidth: "193px",
    borderRadius: "5px",
    padding: "20px 18px",
    justifyContent: 'space-between',
    backgroundColor: "#FFFFFF",
    flexWrap: 'wrap',
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: 'pointer',
    "& .sold": {
        position:"absolute",
        top:"0px",
        right:"0px",
        color: "#fff",
        fontSize: "14px",
        borderTopRightRadius: "5px",
        borderBottomLeftRadius: "5px",
        padding: "6px 12px",
        paddingTop: "4px",
        backgroundColor: "#5cad5c",
    },
});
const CardImageNegotiatesData = styled("img")({
    width: "100%",
    height: "211px",
    objectFit: "contain",
    borderRadius: "5px",
    margin: '8px 0',
    "@media(max-width:1150px)": {
        width: '100%',
    },
});
const BodyTypographyNegotiatesData = styled("p")({
    marginTop: 10,
    fontFamily: "Segoe UI Bold",
    color: '#676767',
    fontSize: "16px",
    lineHeight: "20px",
    margin: 0,
    display: '-webkit-box',
    height: '44px',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
const BudgetTypographyNegotiatesData = styled("p")({
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "22px",
    margin: "0",
    color: "#676767"
});
const StyledSpanNegotiatesData = styled("span")({
    color: "#3CAA49",
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "Segoe UI Bold",
    margin: 0
});
const CommissionTypographyNegotiatesData = styled("p")({
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#676767",
    margin: 0
})
const TextWaitings = styled("div")({
    fontFamily: "Segoe UI",
    fontSize: "12px",
    color: "#676767",
    lineHeight: "20px",
})
const TextWaitingss = styled("div")({
    fontFamily: "Segoe UI",
    fontSize: "12px",
    color: "#676767",
    lineHeight: "20px",
})
const TextWaitingsA = styled("div")({
    fontFamily: "Segoe UI",
    fontSize: "12px",
    color: "#676767",
    lineHeight: "20px",
})
const TextWaitingssW = styled("div")({
    fontFamily: "Segoe UI",
    fontSize: "12px",
    color: "#676767",
    lineHeight: "20px",
})
const TextWaitingssMain = styled("div")({
    fontFamily: "Segoe UI",
    fontSize: "12px",
    color: "#676767",
    lineHeight: "20px",
})
const TextWaitingssMainText = styled("div")({
    fontFamily: "Segoe UI",
    fontSize: "12px",
    color: "#676767",
    lineHeight: "20px",
})
const TextWaitingssMains = styled("div")({
    fontFamily: "Segoe UI",
    fontSize: "12px",
    color: "#676767",
    lineHeight: "20px",
})

const Wrapper = styled(Box)({
    maxWidth: "290px", 
    margin: "auto",
    "& .description": {
        marginTop: "16px",
        LineHeight: '26px',
        fontSize: '18px',
        fontFamily: "Segoe UI",
        margin: "0px",
        color: '#676767',
    },
    "& .title": {
      margin: "0px",
      lineHeight: '32px',
      fontSize: '32px',
      fontFamily: "Segoe UI Bold",
      color: '#181818',
    },
    "& .cancel-btn": {
        background: "#3CAA49",
      height: "56px",
      width: "135px",
      borderRedius: "4px",
      fontSize: "20px",
      textTransform: "capitalize",
      color: "#ECF7ED",
      fontFamily: "Segoe UI Bold",
      border: "none",
      marginRight: "20px",
    },
    "& .logout-btn": {
      height: "56px",
      background: "#FFFFFFE5",
      color: "#3CAA49",
      fontFamily: "Segoe UI Bold",
      textTransform: "capitalize",
      width: "135px",
      borderRedius: "4px",
      fontSize: "18px",
      border: "1px solid #3CAA49",
    },
});
  
const webStyle = {
    contentStyle: {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#fff',
      padding: '80px 120px 80px 120px',
      borderRadius: '8px',
      maxWidth: '290px',
      width: '100%',
    },
    overlayStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};
export default class NegotiationUserData extends NegotiationUserDataController {
    renderStatus(item: any, userNameFromLocalStorage: any) {
        return (
            (item.attributes.status === "counter_offer" ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: "6px", marginTop: "5px" }}>
                    <img style={styleDN.userPhotoss} src={red_circle} alt="" />
                    <TextWaitingssMain>Action required</TextWaitingssMain>
                </div>
            ) : (
                item.attributes.status === "under_review" ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: "6px", marginTop: "5px" }}>
                        <img style={styleDN.userPhotoss} src={image_clock} alt="" />
                        <TextWaitingssMains>
                            Waiting on&nbsp;
                            {
                                userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email ?
                                    (
                                        item.attributes.listing_detail.data.attributes.listing_type === "selling_for_something" ?
                                            (
                                                <>
                                                    {item.attributes.user_detail.buyer.data.attributes.user_name ?
                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.buyer.data.attributes.user_name)}</span> :
                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                    }
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {item.attributes.user_detail.seller.data.attributes.user_name ?
                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.seller.data.attributes.user_name)}</span> :
                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                    }
                                                </>
                                            )
                                    ) :
                                    (
                                        item.attributes.listing_detail.data.attributes.listing_type === "selling_for_something" ?
                                            (
                                                <>
                                                    {item.attributes.user_detail.buyer.data.attributes.user_name ?

                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                        :
                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.buyer.data.attributes.user_name)}</span>
                                                    }
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {item.attributes.user_detail.seller.data.attributes.user_name ?
                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                        :
                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.seller.data.attributes.user_name)}</span>
                                                    }
                                                </>
                                            )
                                    )
                            }


                        </TextWaitingssMains>
                    </div>
                ) :
                    (
                        <div style={{ display: 'flex', alignItems: 'center', gap: "6px", marginTop: "5px" }}>
                            <TextWaitingsA>Finalised deal</TextWaitingsA>
                        </div>
                    )

            ))
        )
    };

    formatName = (username: string | null) => {
        if (username && username.length > 14) {
          return username.substring(0, 14) + '...';
        }
        return username;
    }

    logoutDialog = () => {
        return (
          <Popup
            open={this.state.isOpen}
            onClose={this.onPopUpClose}
            contentStyle={webStyle.contentStyle}
            overlayStyle={webStyle.overlayStyle}
            closeOnDocumentClick
          >
            <Wrapper>
              <p className="title">Logging out</p>
              <div>
                <p className="description">Are you sure you want to leave?</p>
              </div>
              <Box marginTop="40px">
                <Button className="cancel-btn" data-test-id="cancel-btn" onClick={this.onPopUpClose}>Cancel</Button>
                <Button className="logout-btn" data-test-id="logout-btn" onClick={this.handleButtonClicks}>Log-out</Button>
              </Box>
            </Wrapper>
          </Popup>
        );
    }

    renderStatusText(item: any, userNameFromLocalStorage: any) {
        return (
            <a  data-test-id='handleButtonClickSettings11'  style={{ textDecoration: 'none', color: 'inherit' }}
             href={
             userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email?
             `/OtherUserDetails/${item.attributes.user_detail.seller?.data.attributes.account_id}`:
             `/OtherUserDetails/${item.attributes.user_detail.buyer?.data.attributes.account_id}`
            }
              onClick={(event) => {
                  event && event.stopPropagation();
                  event.preventDefault();
                  userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email ?
                  this.userDetails(item.attributes.user_detail.seller.data.attributes.account_id) :
                  this.userDetails(item.attributes.user_detail.buyer.data.attributes.account_id)
              }}>
            <div style={{ display: 'flex', alignItems: 'center' }}
               >
                {userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email
                    ? (
                        <>
                            {item.attributes.user_detail.seller.data.attributes.photo ? (
                                <img style={styleDN.userPhotos} src={item.attributes.user_detail.seller.data.attributes.photo} alt="" />
                            ) : (
                                <img style={styleDN.userPhotos} src={profiles} alt="Default Profile" />
                            )}
                            <p style={styleDN.userDetails}>@{this.formatName(item.attributes.user_detail.seller.data.attributes.user_name)}</p>
                        </>
                    ) : (
                        <>
                            {item.attributes.listing_detail.data.attributes.user_details.data.attributes.photo ? (
                                <img style={styleDN.userPhotos} src={item.attributes.listing_detail.data.attributes.user_details.data.attributes.photo} alt="" />
                            ) : (
                                <img style={styleDN.userPhotos} src={profiles} alt="Default Profile" />
                            )}
                            <p style={styleDN.userDetails}>@{this.formatName(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</p>
                        </>
                    )}
            </div></a>
        )
    };
    finalisedData() {
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: "6px", marginTop: "5px" }}>
                <TextWaitingsA>Finalised deal</TextWaitingsA>
            </div>
        )
    }

    finalisedDataa() {
        return (
            <>
                <CustomH6Datas>{this.state.useProfiles?.attributes?.full_name}</CustomH6Datas>
                <CustomHeadingDatas>@{this.state.useProfiles?.attributes?.user_name}</CustomHeadingDatas>
            </>
        )
    }
    

    render() {
        const userPhoto = localStorage.getItem("profilePhoto")
        const userNameFromLocalStorage = localStorage.getItem("email")

        const { LoaderNegotiationData } = this.state;
        if (LoaderNegotiationData) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress style={{ color: '#4fa94d' }} />
                    </Box>
                </div>
            );
        }
        return (

            <>
                <Box width="100%">
                    <AppHeader navigation={this.props.navigation.navigate} />
                    <FormGridDataNegotiation>
                        {this.logoutDialog()}
                        <CustomBoxDataMain>
                            <IconsProfileDataMain>
                                <ProfileImgDatas
                                    //@ts-ignore
                                    src={userPhoto ? userPhoto : profiles}
                                    alt="Logo Image"
                                />
                                {this.finalisedDataa()}
                                <LocationBoxDatas>
                                    <LocationImgsDatas
                                        src={imageLocations}
                                        alt="Logo" />
                                    <CustomH1Datas>{this.state.citys === null ? "___" : this.state.citys },<Typography style={{ marginLeft: '7px' }}>{this.state.countrys === null ? "___" : this.state.countrys}</Typography></CustomH1Datas>
                                </LocationBoxDatas>
                            </IconsProfileDataMain>
                            <div style={{ position: 'absolute', bottom: 0, left: "50%", transform: 'translateX(-50%)' }}>
                                <FlexDivDatas data-test-id='handleButtonClickSettings' onClick={this.handleButtonClickSettings}>
                                    <LogoImageDataSetting src={settingImage} alt="Logo" />
                                    <LogoTextDataSetting>Settings</LogoTextDataSetting>
                                </FlexDivDatas>
                                <LogoutDivDatas data-test-id='handleButtonClicks' onClick={this.onOpen}>
                                    <LogoutLogoImageDatas src={LogoutImage} alt="Logout Logo" />
                                    <LogoutTextDataLogouts>Logout</LogoutTextDataLogouts>
                                </LogoutDivDatas>
                            </div>
                        </CustomBoxDataMain>
                        <CustomContentDatas flex="1">
                            <HomeTextDivsDatas>
                                <p style={styleDN.homeTextbar as React.CSSProperties}
                                data-test-id="go-back"
                                onClick={this.onGoBack}
                                >Negotiations</p>
                                <div>
                                    <img src={imageHomeCross} style={styleDN.imageHomeCrosses as React.CSSProperties} alt="" />
                                </div>
                                <span style={styleDN.titileMain}>
                                    {this.state.setInitiateDatas[0]?.attributes.listing_detail.data.attributes.title}
                                </span>
                            </HomeTextDivsDatas>
                            <Box style={styleDN.mainContainers}>
                                <MainDivOtherNegotiate>
                                    {this.state.setInitiateDatas?.map((item: any) => {
                                        return (
                                            <>

                                                <ShoppingCardsNegotiatesData style={item.attributes.listing_detail.data.attributes.status === "sold" ? { cursor: 'default', filter: "grayscale(100%)" }: {}}>
                                                    {item.attributes.listing_detail.data.attributes.status === "sold" && <Box className="sold">Sold</Box>}
                                                    <Typography>
                                                        {
                                                            item.attributes.listing_detail.data.attributes.listing_type === "selling_for_something"
                                                                ?
                                                                <a  
                                                                    data-test-id='link'
                                                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                                                    href=
                                                                    {userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email ?
                                                                    `/OtherUserDetails/${item.attributes.user_detail.buyer.data.attributes.account_id}`:
                                                                    `/OtherUserDetails/${item.attributes.user_detail.seller.data.attributes.account_id}`}
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        {userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email ?
                                                                            this.userDetails(item.attributes.user_detail.buyer.data.attributes.account_id):
                                                                            this.userDetails(item.attributes.user_detail.seller.data.attributes.account_id)}
                                                                    }}
                                                                >
                                                                <Box display='flex' alignItems='center'>
                                                                    {userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email
                                                                        ? (
                                                                            <>
                                                                                {item.attributes.user_detail.buyer.data.attributes.photo ? (
                                                                                    <img style={styleDN.userPhotos} src={item.attributes.user_detail.buyer.data.attributes.photo} alt="" />
                                                                                ) : (
                                                                                    <img style={styleDN.userPhotos} src={profiles} alt="Default Profile" />
                                                                                )}

                                                                                <p style={styleDN.userDetails}>@{this.formatName(item.attributes.user_detail.buyer.data.attributes.user_name)}</p>

                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name ? (
                                                                                    <img style={styleDN.userPhotos} src={item.attributes.listing_detail.data.attributes.user_details.data.attributes.photo} alt="" />
                                                                                ) : (
                                                                                    <img style={styleDN.userPhotos} src={profiles} alt="Default Profile" />
                                                                                )}
                                                                                <p style={styleDN.userDetails}>@{this.formatName(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</p>
                                                                            </>
                                                                        )}
                                                                </Box></a>
                                                                :
                                                                (this.renderStatusText(item, userNameFromLocalStorage))


                                                        }
                                                    </Typography>
                                                    <Box
                                                        data-test-id='handleClickAllProducts'
                                                        onClick={() => {
                                                            item.attributes.listing_detail.data.attributes.status !== "sold" && this.handleClickAllProducts(item);
                                                        }}
                                                    >

                                                        <Box style={styleDN.listingTyes}>
                                                            {
                                                                item.attributes.listing_detail.data.attributes.listing_type === "looking_for_something" ? <> <img src={Look} alt="" style={styleDN.lookings} />
                                                                    <Typography style={styleDN.lookingText}>Looking for something</Typography></> : <> <img src={Sell} alt="" style={styleDN.lookings} /><Typography style={styleDN.lookingText}>Selling something</Typography></>
                                                            }
                                                        </Box>
                                                        <CardImageNegotiatesData
                                                            src={item.attributes.listing_detail.data.attributes.photo[0]?.url}
                                                            alt="Card Image"
                                                        />
                                                        <BodyTypographyNegotiatesData>{item.attributes.listing_detail.data.attributes.title}</BodyTypographyNegotiatesData>
                                                        <BudgetTypographyNegotiatesData>
                                                            {item.attributes.listing_detail.data.attributes.listing_type === "looking_for_something" ? "Budget " : "Price "}<StyledSpanNegotiatesData>£ {item.attributes.listing_detail.data.attributes.budget}</StyledSpanNegotiatesData>
                                                        </BudgetTypographyNegotiatesData>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <CommissionTypographyNegotiatesData>
                                                                Reward
                                                                <StyledSpanNegotiatesData> £ {item.attributes.listing_detail.data.attributes.commission}</StyledSpanNegotiatesData>
                                                            </CommissionTypographyNegotiatesData>
                                                        </div>
                                                    </Box>


                                                    {
                                                        userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email ? (
                                                            item.attributes.status === "under_review" || item.attributes.status === "counter_offer" ? (
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: "6px", marginTop: "5px" }}>
                                                                    <img style={styleDN.userPhotoss} src={red_circle} alt="" />
                                                                    <TextWaitingsA>Action required</TextWaitingsA>
                                                                </div>
                                                            ) : (
                                                                item.attributes.status === "finalise_deal" ?
                                                                    <div style={{ display: 'flex', alignItems: 'center', gap: "6px", marginTop: "5px" }}>
                                                                        <TextWaitingsA>Finalised deal</TextWaitingsA>
                                                                    </div> :

                                                                    <div style={{ display: 'flex', alignItems: 'center', gap: "6px", marginTop: "5px" }}>
                                                                        <img style={styleDN.userPhotoss} src={image_clock} alt="" />
                                                                        <TextWaitingssW>
                                                                            Waiting on&nbsp;
                                                                            {userNameFromLocalStorage === item.attributes.listing_detail.data.attributes.user_details.data.attributes.email ? (
                                                                                item.attributes.listing_detail.data.attributes.listing_type === "selling_for_something" ? (
                                                                                    item.attributes.user_detail.buyer.data.attributes.user_name ? (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.buyer.data.attributes.user_name)}</span>
                                                                                    ) : (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                                                    )
                                                                                ) : (
                                                                                    item.attributes.user_detail.seller.data.attributes.user_name ? (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.seller.data.attributes.user_name)}</span>
                                                                                    ) : (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                                                    )
                                                                                )
                                                                            ) : (
                                                                                item.attributes.listing_detail.data.attributes.listing_type === "selling_for_something" ? (
                                                                                    item.attributes.user_detail.buyer.data.attributes.user_name ? (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.buyer.data.attributes.user_name)}</span>
                                                                                    ) : (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                                                    )
                                                                                ) : (
                                                                                    item.attributes.user_detail.seller.data.attributes.user_name ? (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.listing_detail.data.attributes.user_details.data.attributes.user_name)}</span>
                                                                                    ) : (
                                                                                        <span style={{ margin: 0 }}>@{this.truncateUsername(item.attributes.user_detail.seller.data.attributes.user_name)}</span>
                                                                                    )
                                                                                )
                                                                            )}
                                                                        </TextWaitingssW>
                                                                    </div>
                                                            )
                                                        ) : (
                                                            this.renderStatus(item, userNameFromLocalStorage)
                                                        )
                                                    }

                                                </ShoppingCardsNegotiatesData >

                                            </>
                                        )
                                    })}
                                </MainDivOtherNegotiate>
                            </Box>
                        </CustomContentDatas>
                    </FormGridDataNegotiation>
                </Box >
            </>
        );
    }
}
const styleDN = {
    homeTextbar: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "24px",
        cursor: "pointer"
    },
    lookingText: {
        color: "#676767",
        fontFamily: "Segoe UI",
        fontSize: "12px",
    },
    imageHomeCrosses: {
        width: "7.41px",
        height: "12px",
        margin: "0px"
    },
    lookingTextsMain: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#3CAA49",
        marginLeft: '24px'
    },
    mainContainers: {
        height: "calc(100% - 162px)",
        backgroundColor: "rgb(247, 245, 245)",
        padding: '40px 50px 40px 0px'
    },
    cardMainStyles: {
        width: "80%",
        height: "359px",
        borderRadius: "8px",
        padding: "20px 21.5px 17px 21.5px",
        justifyContent: 'space-between',
        backgroundColor: "#FFFFFF",
        flexWrap: 'wrap',
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        cursor: "pointer"
    },
    listingTyes: {
        display: 'flex',
        alignItems: 'center',
        margin: 0
    },
    lookings: { paddingRight: '10px', width: '25px', height: '25px' },
    lookingTexts: { paddingTop: '5px', fontSize: '12px' },
    userPhotos: {
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        marginRight: '10px'
    },
    userPhotoss: {
        width: '14px',
        height: '14px',
    },
    titileMain: {
        fontFamily: "Segoe UI",
        fontSize: "24px",
        color: "#3CAA49",
        margin: 0
    },
    userDetails: {
        fontFamily: "Segoe UI Bold",
        fontSize: "16px",
        color: "#676767",
        letterSpacing: 'normal'
    },
    userMains: {
        margin: '15px 0'
    }
}
// Customizable Area End
