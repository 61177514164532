// Customizable Area Start
import React from "react";

// Customizable Area Start
import { logo } from "./assets";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  CircularProgress,
  styled
} from "@material-ui/core";
import {
  Visibility,
  VisibilityOff,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from "@material-ui/icons";


const FormDataGrid = styled(Grid)({
  height: "100vh",
  overflow: "auto",
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',

  '@media(max-width: 960px)': {
    height: 'unset'
  }
});
const Customimg = styled(Grid)({
  '@media(max-width: 960px)': {
    display: 'none'
  }
});
const CustomLogo = styled("img")({
  cursor: "pointer",
  width: "220px"
});
// Customizable Area End

import EmailAccountRegistationController, {
  Props
} from "./EmailAccountRegistrationController";

const FormGrid = styled(Grid)({
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)"
  }
});
const CustomCheckbox = styled(Checkbox)({
  padding: "5px",
  paddingLeft: "10px",
  "& .MuiSvgIcon-root": {
    color: "#CBD5E1!important"
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root": {
    color: "#3CAA49!important"
  }
});
const CustomAgreeCheckbox = styled(Checkbox)({
  marginLeft: -1,
  "& .MuiSvgIcon-root": {
    color: "#CBD5E1!important"
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root": {
    color: "#3CAA49!important"
  }
});

const AcceptBtn = styled(Button)({
  background: "#3CAA49",
  width: "135px",
  height: "56px",
  fontSize: 20,
  borderRadius: "4px",
  color: "#FFFFFF",
  aligntext: "center",
  border: "none",
  textTransform: "capitalize",
  fontWeight: 700,
  "&:hover": {
    border: "1px solid #3CAA49",
    color: "#3CAA49",
    background: "white"
  }
});
const DeclineBtn = styled(Button)({
  width: "135px",
  height: "56px",
  borderRadius: "4px",
  fontSize: 20,
  border: "1px solid #3CAA49",
  color: "#3CAA49",
  background: "white",
  aligntext: "center",
  textTransform: "capitalize",
  fontWeight: 700,
  "&:hover": {
    border: "none",
    color: "#ffffff",
    background: "#3CAA49"
  }
});

export default class EmailAccountRegistrationWeb extends EmailAccountRegistationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
    this.state = {
      ...this.state,
      password: "",
      containsCapital: false,
      containsLowercase: false,
      containsNumber: false,
      hasMinLength: false,
      showPassword: false,
      passwordMatchError: false,
      confirmPassword: "",
      email: "",
      emailError: false,
      fullName: "",
      userName: "",
      checkboxChecked: false,
      showConfirmPassword: false,
      passwordError: false,
      checkboxError: false,
      snackbarOpen: false,
      emailFocused: false,
      emailBlurred: false,
      finishedTyping: false,
      emailExists: false
    };
    // Customizable Area End
  }

  // Customizable Area Start
  CheckBoxGrid = () => {
    return (
      <div
        style={{
          display: "flex",
          marginTop: "2px",
          alignItems: "flex-start"
        }}
      >
        <CustomAgreeCheckboxs
          icon={
            <CheckBoxOutlineBlankIcon
              style={{
                color: this.state.checkboxError ? "#CBD5E1" : "#273567"
              }}
            />
          }
          checkedIcon={
            <CheckBoxIcon
              style={{
                color: this.state.checkboxError ? "#CBD5E1" : "#3CAA49"
              }}
            />
          }
          style={webStyle.squareCheckbox}
          checked={this.state.checkboxChecked}
          onChange={this.handleCheckboxChange}
        />

        <Typography
          style={{
            ...webStyle.terms,
            color: this.state.checkboxError ? "#64748B" : "#676767",
            cursor: "pointer",
          }}
        >
          I agree with{" "}
          <Link onClick={this.handlePrivacyPolicyClick} style={{
            color: "#3CAA49",
            textDecoration: "none",
            borderBottom: "1px solid #3CAA49"
          }}>
            Terms and Conditions
          </Link>
          .
        </Typography>
      </div>

    )
  }
  renderErrorMessage(message: any) {
    return (
      <Typography style={webStyle.errorMessage as React.CSSProperties}>
        {message}
      </Typography>
    );
  }
  renderUserErrorMessages() {
    return (
      <>
        {this.state.userNameError && this.renderErrorMessage('Username is required')}
        {this.state.userNametakenMsg.length > 0 && this.renderErrorMessage(this.state.userNametakenMsg)}
      </>
    );
  }
  getUserNametakenMsgClass() {
    return `Mui-error ${this.state.userNametakenMsg.length > 0 ? 'redBorder' : ''}`;
  }
  getEmailErrorClass() {
    return `Mui-error ${this.state.emailErrortakenMsg ? 'redBorder' : ''}`;
  }
  renderFullNameError() {
    if (this.state.fullNameError) {
      return this.renderErrorMessage('Fullname is required');
    }
    return null; // Render nothing if there's no error
  }
  renderPasswordError() {
  if (this.state.passwordError) {
    return this.renderErrorMessage('Password is required');
  }
  return null; // Render nothing if there's no error
}
renderConfirmPasswordError() {
  if (this.state.confirmPasswordError) {
    return this.renderErrorMessage('Confirm Password is required');
  }
  return null;
}


  // Customizable Area End

  render() {
    const {
      containsCapital,
      containsLowercase,
      containsNumber,
      hasMinLength,
      showPassword,
      passwordMatchError,
      confirmPassword,
      emailError,
      showConfirmPassword,
      emailExists,
      fullName,
      email,
      userName,
      emailBlurred,
      finishedTyping
    } = this.state;

    const passwordInputType = showPassword ? "text" : "password";
    const confirmPasswordInputType = showConfirmPassword ? "text" : "password";
    const passwordMatchErrorBorderColor = passwordMatchError
      ? "#FF0404"
      : undefined;
    const buttonBackgroundColor = this.getButtonBackgroundColor();
    const showError = (emailError || emailExists) && finishedTyping;
    const shouldShowRedBorder = showError && emailBlurred;

    return (
      // Customizable Area Start
      <Box width="100%">
        <FormGrid container style={{height: "100%"}}>
          <FormDataGrid item xs={12} md={6}>
            <div style={webStyle.Imglogohead as React.CSSProperties}>
              <CustomLogo src={logo} data-test-id="redirect" onClick={this.onRedirect}/>
            </div>
            <Box style={webStyle.rightContent}>
              <Typography style={webStyle.rightHeading as React.CSSProperties}>
                Create an account
              </Typography>
              {this.state.checkboxError && (
                <AgreeBoxError>
                  You have to agree with Privacy Policy and Terms and Conditions
                  in order to Sign up.
                </AgreeBoxError>
              )}
              <Typography style={webStyle.label as React.CSSProperties}>
                Full name <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
              </Typography>
              <CustomTextField
                variant="outlined"
                placeholder="Your full name"
                type="text"
                value={fullName}
                style={{
                  width: "100%"
                }}
                onChange={this.handleFullNameChange}
                InputProps={{
                  style: placeholderStyle,
                }}
              />
              {this.renderFullNameError()}

              <Typography style={webStyle.label as React.CSSProperties}>
                Email<span style={webStyle.errorMessages as React.CSSProperties}> *</span>
              </Typography>
              <CustomTextField
                className={this.getEmailErrorClass()}
                variant="outlined"
                placeholder="Your email"
                type="email"
                value={email}
                style={{
                  width: "100%"
                }}
                onChange={this.handleEmailChange}
                onFocus={this.handleEmailFocus}
                error={showError && emailBlurred}
                InputProps={{
                  style: placeholderStyle,
                }}
              />
              <>
                {this.state.emailErrorMsg && (
                  <Typography
                    style={webStyle.errorMessage as React.CSSProperties}
                  >
                    {this.state.emailErrorMsg}
                  </Typography>
                )}
                {this.state.emailErrortakenMsg.length > 0 && this.renderErrorMessage(this.state.emailErrortakenMsg)}
              </>

              <div>
                <Typography style={webStyle.label as React.CSSProperties}>
                  Username <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
                </Typography>
                <CustomTextField
                  className={this.getUserNametakenMsgClass()}
                  variant="outlined"
                  placeholder="Add username"
                  type="text"
                  value={userName}
                  style={{
                    width: "100%",
                    borderColor: shouldShowRedBorder ? "#FF0404" : undefined
                  }}
                  onChange={this.handleUsernameChange}
                  InputProps={{
                    style: placeholderStyle,
                  }}
                />
                {this.renderUserErrorMessages()}
              </div>

              <Typography style={webStyle.label as React.CSSProperties}>
                Password <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
              </Typography>
              <CustomTextField
                variant="outlined"
                placeholder="Your password"
                type={passwordInputType}
                style={{ width: "100%" }}
                onChange={this.handlePasswordChange}
                value={this.state.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: placeholderStyle,
                }}
              />
              {this.renderPasswordError()}

              <Typography style={webStyle.label as React.CSSProperties}>
                Confirm Password <span style={webStyle.errorMessages as React.CSSProperties}> *</span>
              </Typography>
              <CustomTextField
                variant="outlined"
                placeholder="Your password"
                type={confirmPasswordInputType}
                style={{
                  width: "100%",
                  borderColor: passwordMatchErrorBorderColor
                }}
                value={confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                error={passwordMatchError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={this.toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: placeholderStyle,
                }}
              />
              {
               (this.state.ConfirmPasswordRequiredError && this.renderConfirmPasswordError() ) || (passwordMatchError && this.renderErrorMessage('Passwords do not match.'))}
              <div style={{ marginTop: "5px",pointerEvents: 'none' }}>
                <div>
                  <FormControlLabel
                    control={
                      <CustomCheckboxs
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                          <CheckBoxIcon style={{ color: "#059669" }} />
                        }
                        checked={containsCapital}
                      />
                    }
                    label={
                      <span style={webStyle.checkText as React.CSSProperties}>
                        At least one capital letter.
                      </span>
                    }
                  />
                </div>

                <div>
                  <FormControlLabel
                    control={
                      <CustomCheckboxs
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                          <CheckBoxIcon style={{ color: "#059669" }} />
                        }
                        checked={containsLowercase}
                      />
                    }
                    label={
                      <span style={webStyle.checkText as React.CSSProperties}>
                        At least one lowercase letter.
                      </span>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CustomCheckboxs
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                          <CheckBoxIcon style={{ color: "#059669" }} />
                        }
                        checked={containsNumber}
                      />
                    }
                    label={
                      <span style={webStyle.checkText as React.CSSProperties}>
                        At least one number.
                      </span>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CustomCheckboxs
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={
                          <CheckBoxIcon style={{ color: "#059669" }} />
                        }
                        checked={hasMinLength}
                      />
                    }
                    label={
                      <span style={webStyle.checkText as React.CSSProperties}>
                        Minimum character length is 8.
                      </span>
                    }
                  />
                </div>
              </div>
              {this.CheckBoxGrid()}


              <SignUpButton
                disabled={this.state.loading}
                onClick={this.handleCreateAccount}
              >
                Sign up
                {this.state.loading && <CircularProgress size={13} style={webStyle.loader} />}
              </SignUpButton>
              <div
                style={{
                  display: "flex",
                  marginTop: "5px",
                  alignContent: "center",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <Typography style={webStyle.haveAcc}>
                  Already have an account?
                </Typography>
                <Typography
                  style={webStyle.signlink as React.CSSProperties}
                  onClick={this.handleClickLogin}
                >
                  Log in
                </Typography>
              </div>
            </Box>
          </FormDataGrid>
          <Customimg item xs={12} md={6} style={webStyle.leftBox}>
          {this.state.LoaderSignup ?
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CircularProgress style={{ color: '#4fa94d' }} />
            </Box>
          </div> :
            <div style={webStyle.Imglogo as React.CSSProperties}>
              <img
                src={this.state.mainImages}
                style={{ width: '100%',height: '100vh', objectFit: 'cover'}}
              />
            </div>
  }
          </Customimg>
        </FormGrid>
        <Dialog
          open={this.state.isPrivacyPolicyModalOpen}
          onClose={this.closePrivacyPolicyModal}
        >
          <DialogContent>
            <Typography style={webStyle.checkTexts as React.CSSProperties}>Terms and Conditions for Item Find Ltd.</Typography>
            <br />
            <Typography style={webStyle.checkTexts as React.CSSProperties}>
              Welcome to Item Find. These Terms and Conditions govern your
              use of our online platform, where users can trade links for items
              online. By accessing or using our platform, you agree to be bound
              by these Terms and Conditions. Please read them carefully before
              using the service. Terms and Conditions for Item Find Ltd.
              <Link
                style={webStyle.signlink as React.CSSProperties}
                onClick={this.handleClickTermsAndCondition}
              >
                Know more
              </Link>
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                gap: 8
              }}
            >
              <CustomAgreeCheckbox style={{ display: "none" }} />
              <CustomCheckbox style={{ display: "none" }} />
              <AcceptBtn onClick={this.closePrivacyPolicyModal}>
                Accept
              </AcceptBtn>
              <DeclineBtn onClick={this.opensPrivacyPolicyModal}>
                Decline
              </DeclineBtn>
            </Box>
          </DialogContent>
        </Dialog>

      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AgreeBoxError = styled(Box)({
  borderLeft: "3px solid #FE4023",
  background: "rgba(254, 64, 35, 0.20)",
  padding: "14px 12px",
  fontSize: 16,
  lineHeight: "20px",
  color: "#0F172A",
  fontFamily: 'Segoe UI',
  marginBottom: 30
})
const placeholderStyle = {
  fontSize: "16px",
  color: "#64748B",
  fontFamily: "Segoe UI",
  lineHeight: "22px",
};
const CustomCheckboxs = styled(Checkbox)({
  padding: "0px",
  paddingLeft: "10px",
  paddingRight: "5px",
  "& .MuiSvgIcon-root": {
    color: "#CBD5E1!important"
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root": {
    color: "#3CAA49!important"
  }
});
const CustomAgreeCheckboxs = styled(Checkbox)({
  marginLeft: -1,
  paddingRight: 5,
  paddingTop: 0,
  "& .MuiSvgIcon-root": {
    color: "#CBD5E1!important"
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root": {
    color: "#3CAA49!important"
  }
});
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: '8px 15px',
    fontSize: 14
  },
  '&.redBorder': {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red !important'
    }
  }
});

const webStyle = {
  loader: {
    color: "#fff",
    marginTop: "3px",
    marginLeft: "10px" 
  },
  leftBox: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },

  rightContent: {
    maxWidth: "360px",
    margin: "77px auto 81px",
    padding: 5,
  },
  label: {
    fontSize: "16px",
    marginTop: "10px",
    letterSpacing: "0em",
    lineHeight: "16px",
    color: "#676767",
    textAlign: "left",
    fontFamily: "Segoe UI Bold",
    paddingBottom: 2
  },
  btnLogin: {
    borderRadius: "8px",
    backgroundColor: "#273567",
    width: "100%",
    fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    height: "35px",
    marginTop: "10px",
    fontSize: "16px",
    cursor: "pointer"
  },
  haveAcc: {
    color: "#0F172A",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    lineHeight: "24px",
  },
  rightHeading: {
    color: "#000000",
    fontFamily: "Segoe UI Bold",
    fontSize: "24px",
    letterSpacing: "0em",
    lineHeight: "42px",
    textAlign: "left",
    marginBottom: 10
  },
  squareCheckbox: {
    borderRadius: 0,
    paddingLeft: "0px",
    "&$checked": {
      color: "#273567"
    }
  },
  checkText: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "24px"
  },
  checkTexts: {
    color: "#676767",
    fontFamily: 'Segoe UI',
    fontSize: "16px",
    lineHeight: "25px",
  },
  signlink: {
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    marginLeft: "5px",
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer"
  },
  terms: {
    color: "#273567",
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "Segoe UI",
    paddingTop: "0px"
  },
  Imglogo: {
    textAlign: "right"
  },
  Imglogohead: {
    marginTop: "43px",
    marginLeft: "72px"
  },
  errorMessage: {
    color: "#FE4023",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  errorMessages: {
    color: "#6767673",
    marginTop: "0px",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left"
  },
};

const SignUpButton = styled(Button)({
  ...webStyle.btnLogin,
  color: "#fff",
  cursor: "pointer",
  textTransform: "initial",
  backgroundColor: "#3CAA49",
  "&.MuiButton-root:hover": {
    backgroundColor: '#3CAA49',
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "rgb(110 207 121)",
  }
});
// Customizable Area End
// Customizable Area End
