import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface FormDataFormat {
  order_id: string;
  issue: string;
  description: string;
  evidence: File | Blob | string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface MainRoot {
    contentType: string
    method: string
    endPoint: string
    body: Body
}

export interface Body {
    hyperpay_order_id: string
    entity_id: string
    amount: number | string
    currency: string
    payment_type: string
}

export interface Roots {
    id: string
    referencedId: string
    paymentType: string
    amount: string
    currency: string
    descriptor: string
    result: Result
    resultDetails: ResultDetails
    buildNumber: string
    timestamp: string
    ndc: string
}

export interface Result {
    code: string
    description: string
}

export interface ResultDetails {
    ExtendedDescription: string
    ProcStatus: string
    clearingInstituteName: string
    AuthCode: string
    ConnectorTxID1: string
    ConnectorTxID3: string
    ConnectorTxID2: string
    AcquirerResponse: string
    EXTERNAL_SYSTEM_LINK: string
    OrderID: string
    TermID: string
}

export interface ListApiCall {
    contentType: string
    method: string
    endPoint: string
    token: string
}

export interface ItemAttribute {
    id: string
    type: string
    attributes: Attributes
}

export interface Root {
    data: Root[];
    id: string
    type: string
    attributes: Attributes
}

export interface Attributes {
    customer_name: string
    order_id: number | string
    amount_paid: number
    remaining_balance: number
    transaction_id: string
    transaction_status: string
    orders_status: string
    request_refund: string
    reason_of_refund: null
    created_at: string
    updated_at: string
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    data: Array<ItemAttribute>
    isInvalidEmail: boolean;
    amount: string;
    leftamount: number;
    isInvalidAmt: boolean;
    isValue: boolean;
    ismsgShow: boolean;
    acceptButon: boolean;
    item: ItemAttribute;
    // Customizable Area Start
    orderId :string;
    orderIDError:boolean;
    IssueReason:string;
    IssueReasonError:boolean;
    issueDetail:string;
    issueDetailError:boolean;
    selectedFileName:string[];
    selectedFileError:boolean;
    refundPopUpshow:boolean;
    selectedEvidence:FileList |null;
    popUpMsg:string
   orderIdErrorRes:string
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class RefundManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    listofOrdersApiCallID: string = "";
    orderUpdateApiCallID: string = "";
    RefundApiCallID: string = "";
    HYPER_PAY_ACCESS_TOKEN = "OGFjN2E0Y2E4MTIxNGNiZTAxODEyMzkxNjU1NzEwOGV8YTNtZUZDZTY4dA==";
    entityId = "8ac7a4ca81214cbe0181239c3ff010b6";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
           
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
           
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            isInvalidEmail: false,
            data: [],
            amount: "",
            leftamount: 0,
            isInvalidAmt: false,
            isValue: false,
            ismsgShow: false,
            acceptButon: false,
            item: {} as ItemAttribute,
            orderId:"",
            orderIDError:false,
            IssueReason:"",
            IssueReasonError:false,
            issueDetail:"",
            issueDetailError:false,
            selectedFileName:[],
            selectedFileError:false,
            refundPopUpshow:false,
            selectedEvidence:null,
            popUpMsg:"",
   orderIdErrorRes:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
   
    // Customizable Area End

    // Customizable Area Start
   
    // Customizable Area End

    // Customizable Area Start
    async receive(from: string, message: Message) {
      if(getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

        this.handleNavigationPayload(message)
      }
 
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.RefundApiCallID) {
               
              if (responseJson.message) {
               
                this.setState({ popUpMsg: responseJson.message,refundPopUpshow:true, orderId:"",selectedEvidence:null ,IssueReason:"",issueDetail:"",orderIdErrorRes:"",selectedFileName:[]});
              }else{
              
                this.setState({orderIdErrorRes:responseJson.error})
              }
            }
        }
    }

    async componentDidMount() {
        const auth = localStorage.getItem("authToken");
        if (!auth) 
        {
          await setStorageData("redirectUrl", window.location.pathname);
          this.navigateToLogin()}
      
        window.scrollTo(0,0);
    }

    //new code 

    navigateToLogin() {
      const navigateToLoginMessage: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      navigateToLoginMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(navigateToLoginMessage);
    }

    handleOrderIdChanges = (event: { target: { value: string } }) => {
        let orderId = event.target.value;
        this.setState({ orderId: orderId });
        if (orderId.trim() === "") {
          this.setState({ orderIDError: true ,orderIdErrorRes:""});
        } else {
          this.setState({ orderIDError: false });
        }
      };


      handleReasonChange = (event: { target: { value: string } }) => {
        let issue = event.target.value;
        this.setState({ IssueReason: issue });
        if (issue.trim() === "") {
          this.setState({ IssueReasonError: true, });
        } else {
          this.setState({ IssueReasonError: false });
        }
      };

      handleIssueDetailChange = (event: { target: { value: string } }) => {
        let Detailis = event.target.value;
        this.setState({ issueDetail: Detailis });
        if (Detailis.trim() === "") {
          this.setState({ issueDetailError: true });
        } else {
          this.setState({ issueDetailError: false });
        }
      };

      handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as FileList;;
        if (files && files.length > 0) {
            const selectedFileNames = Array.from(files).map(file => file.name);
            this.setState({ 
                selectedFileName: selectedFileNames,
                selectedEvidence: files,
                selectedFileError: false
            });
        } else {
            this.setState({ 
                selectedFileError: true 
            });
        }
    };
    
      

      handleClosePopup=()=>{
        this.setState({refundPopUpshow:false})
      }

      handleCreateRefundRequest=()=> {
        const {
          orderId,
          IssueReason,
          selectedFileName,
          issueDetail,
          
        } = this.state;
        
    
        if (orderId.trim() === "") {
          this.setState({ orderIDError: true ,orderIdErrorRes:""});
        } else {
          this.setState({ orderIDError: false });
        }
       
        if (IssueReason.trim() === "") {
          this.setState({ IssueReasonError: true,orderIdErrorRes:"" });
        } else {
          this.setState({ IssueReasonError: false });
        }

        if (issueDetail.trim() === "") {
            this.setState({ issueDetailError: true,orderIdErrorRes:"" });
          } else {
            this.setState({ issueDetailError: false });
          }


        if (selectedFileName.length===0) {
            this.setState({ selectedFileError: true ,orderIdErrorRes:""});
          } else {
            this.setState({ selectedFileError: false });
          }


        if (
          orderId.trim() !== "" &&
          IssueReason.trim() !== "" &&
          issueDetail.trim() !== "" &&
          selectedFileName.length !== 0 
          
        ) {
           
           this.RefundRequestApiCall()
           
        }
      }

      formDataFormat = (): FormData => {
        const formData = new FormData();
    
    
    
    
        formData.append(`order_id`, this.state.orderId)
        formData.append(`issue`, this.state.IssueReason)
        formData.append(`description`, this.state.issueDetail)
        if (this.state.selectedEvidence) {
          Array.from(this.state.selectedEvidence).forEach(file => {
            formData.append(`evidence`, file);
          });
        }
       
        return formData;
      }

      RefundRequestApiCall = async () => {
        const token = await getStorageData("authToken") as string;
        const header = {
          
          token:token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.RefundApiCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_refund_management/refund_requests"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          this.formDataFormat()
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      };

      handleNavigateOrderHIstory=async()=> {
         
          this.props.navigation.navigate("UserProfileBasicBlock", "?tab=orderhistory")
        
      }
   

    
      
       handleNavigationPayload = (message: Message) => {
      
      const data = message.getData(
        getName(MessageEnum.InfoPageBodyMessage)
      );
      const parsedData = data ;
   this.setState({orderId:parsedData})
    
        }
    

    // Customizable Area End

}