import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPopups: boolean,
  showPopupsMessage: boolean,
  imagePreviewss: any,
  selectedFiless: any,
  popUpMsgsForImage: any,
  errorMessage: any,
  imageStore: any,
  imageDelete: any
  showPopupsLogout: boolean,
  deleteDataAccounts: boolean,
  handleDeletePopup: boolean,
  logoutData: object;
  deleteData: object;
  fullName: any;
  country: any;
  email: any;
  city: any;
  errorMessageForcountry: string;
  errorMessageForcity: string;
  fullNameError: string;
  successMessage: string,
  savePopupOpen: boolean,
  initialFullName: string,
  initialCity: string,
  initialCountry: string,
  LoaderFormSetting: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPopupResponse: string = "";
  apiImageResponse: string = "";
  apiImageDelete: string = "";
  apiLogoutItemCallId: string = "";
  apideleteItemCallId: string = "";
  apiSaveInfoID: string = "";
  errors: string[] = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPopups: false,
      showPopupsMessage: false,
      imagePreviewss: [],
      selectedFiless: [],
      popUpMsgsForImage: "",
      errorMessage: "",
      imageStore: '',
      imageDelete: '',
      showPopupsLogout: false,
      deleteDataAccounts: false,
      handleDeletePopup: false,
      logoutData: {},
      deleteData: {},
      fullName: "",
      country: '',
      city: '',
      email: '',
      errorMessageForcountry: '',
      errorMessageForcity: '',
      fullNameError: '',
      successMessage: '',
      savePopupOpen: false,
      initialFullName: '',
      initialCity: '',
      initialCountry: '',
      LoaderFormSetting: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.apiPopupResponse) {
        localStorage.setItem('profilePhoto', responseJson.data.attributes.photo)
        this.handlemessagepopup(responseJson);
        this.ShowImage();
      }
      if (apiRequestCallId === this.apiImageDelete) {
        localStorage.removeItem("profilePhoto");
        this.setState({ imageDelete: responseJson.message, imageStore: '', });
        this.ShowImage();
        this.handleRefreshClicks()    
      }
      if (apiRequestCallId === this.apiImageResponse) {
        this.setState({
          LoaderFormSetting: false,
          imageStore: responseJson.data.attributes.photo,
          fullName: responseJson.data.attributes.full_name,
          country: responseJson.data.attributes.country,
          city: responseJson.data.attributes.city,
          initialFullName: responseJson.data.attributes.full_name,
          initialCity: responseJson.data.attributes.city,
          initialCountry: responseJson.data.attributes.country,
          
        });
      }
      if (apiRequestCallId === this.apiSaveInfoID) {
        this.setState({ LoaderFormSetting: false })
        this.setState({
          successMessage: responseJson.meta.message,
          savePopupOpen: true,
          fullNameError: "",
          errorMessageForcountry: '',
          errorMessageForcity: '',
        });
      }
      if (apiRequestCallId === this.apiLogoutItemCallId) {
        this.handleLogoutResponse(responseJson);
      }
      if (apiRequestCallId === this.apideleteItemCallId) {
        this.handleDeleteResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  componentDidMount(): any {
    this.ShowImage();
    const email = localStorage.getItem('email')
    this.setState({ email: email })
  }
  // Customizable Area Start

  resetStates = () => {
    this.setState({
      showPopups: false,
      imagePreviewss: [],
      selectedFiless: [],
      errorMessage: "",

    });
  };
  handleLogoutResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.message) {
      this.setState({
        logoutData: responseJson.message
      }
        , () => {
          localStorage.clear();
          sessionStorage.clear();
          this.props.navigation.navigate("LandingPageMain");
        }
      );
    }
  }
  handleKeepListing = () => {
    this.setState({ showPopupsLogout: false });
  }
  handleKeepAccount = () => {
    this.setState({ deleteDataAccounts: true });
  }
  closedeletePopups = () => {
    this.setState({ deleteDataAccounts: false });
  };
  handleRefreshClicks = () => {
    window.location.reload();
}
  handleChangeSuccessfully = () => {
  }
  hasProfileChanged = () => {
    const { fullName, city, country, initialFullName, initialCity, initialCountry } = this.state;
    return (
       fullName !== initialFullName ||
      city !== initialCity ||
      country !== initialCountry
    );
  };
  handleRefreshClickReloadSetting = () => {
    window.location.reload();
}

  closePopups = () => {
    this.setState({ showPopupsLogout: true });
  };
  closePopupssLogout = () => {
    this.setState({ showPopupsLogout: false });
  };
  handleDeleteAccountData = () => {
    this.setState({ handleDeletePopup: false });
    this.props.navigation.navigate("LandingPageMain")
  };
  handleDeleteData = () => {
    this.getLogoutData()
  }
  handleDeleteAccount = () => {
    this.deleteAccount()
  }
  handleDeleteResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.message) {
      this.setState({
        deleteData: responseJson.message
      }, () => {
        localStorage.removeItem("fullName");
        localStorage.removeItem("authToken");
        localStorage.removeItem("profilePhoto")
        sessionStorage.removeItem("auth");
        this.setState({ handleDeletePopup: true });
      });
    }
  }
  closePopupPassword=()=>{
    this.props.navigation.navigate("LandingPageMain")
  }
  handlemessagepopup = (responseJson: any,) => {
    this.setState({ popUpMsgsForImage: responseJson.meta?.message, showPopups: false, });
    this.setState({ showPopupsMessage: true })
    this.resetStates();
  }
  goTOSetting = () => {
    this.setState({ showPopupsMessage: false, savePopupOpen: false, country: '', city: '' });
  }
  goTOSetting1 = () => {
    this.setState({ savePopupOpen: false, country: '', city: '' });
  }
  handleCLose = () => {
    this.setState({ showPopups: false })
  }
  handleCLosee = () => {
    this.setState({ showPopupsMessage: false })
    this.handleRefreshClickReloadSetting()

  }
  handleChange = (event:any) => {
    const errors: string[] = [];
    const value = event.target.value;
    const filteredValuefullName = value.replace(/[^a-zA-Z]/g, '');
    this.setState({ fullName: filteredValuefullName }, () => {
      
      this.validateField(this.state.fullName, "Name is required", errors);
      this.setErrorsInState(errors);
    });
    localStorage.setItem('fullName', filteredValuefullName);
  };
  changeForCountry = (event: any) => {
    const value = event.target.value;
    const filteredValueCountry = value.replace(/[^a-zA-Z]/g, '');
    this.setState({ country: filteredValueCountry }, () => {
      const errors: string[] = [];
      this.validateField(this.state.country, "Country name is required", errors);
      this.setErrorsInState(errors);
    });
  };

  setErrorsInState = (errors: string[]) => {
    this.setState({
      fullNameError: errors.includes("Name is required") ? "Name is required" : "",
      errorMessageForcountry: errors.includes("Country name is required") ? "Country name is required" : "",
      errorMessageForcity: errors.includes("City name is required") ? "City name is required" : "",
    });
  };
  handleUpdateProfile = async () => {
    const data = {
      full_name: this.state.fullName,
      country: this.state.country,
      city: this.state.city
    };
    this.errors = [];
    this.validateField(this.state.fullName, "Name is required", this.errors);
    this.validateField(this.state.country, "Country name is required", this.errors);
    this.validateField(this.state.city, "City name is required", this.errors);

    if (this.errors.length > 0) {
      this.setErrorsInState(this.errors);
    } else {
      await this.updateProfile(data);
    }
  };
  validateField = (value: string, errorMessage: string, errorsArray: string[]) => {
    if (value.length === 0) {
      errorsArray.push(errorMessage);
    }
  };

  changeForCity = (event:any) => {
    const value = event.target.value;
    const filteredValueCity = value.replace(/[^a-zA-Z]/g, '');
    this.setState({ city: filteredValueCity }, () => {
      const errors: string[] = [];
      this.validateField(this.state.city, "City name is required", errors);
      this.setErrorsInState(errors);
    });
  };
  AddImages = async () => {
    if (this.state.selectedFiless.length == 0) {
      this.setState({ errorMessage: "Please select Profile Picture." });
      return;
    }
    const token = localStorage.getItem("authToken") as string;
    const requestMessageses = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPopupResponse = requestMessageses.messageId
    const formData = new FormData();
    for (const selectedFile of this.state.selectedFiless) {
      formData.append('photo', selectedFile);
    }
    formData.append("token", token);
    requestMessageses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/profiles/update_profile_picture"
    );

    requestMessageses.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Authorization": token,
      }));
    requestMessageses.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageses.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessageses.id, requestMessageses);
    return true;
  };
  ShowImage = async () => {
    this.setState({ LoaderFormSetting: true });
    const token = localStorage.getItem("authToken") as string;
    const requestMessagess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiImageResponse = requestMessagess.messageId
    requestMessagess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/show_user_profile"
    );

    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "token": token,
      }));
    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessagess.id, requestMessagess);
    return true;
  };
  handleDeleteProfile = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessagess = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiImageDelete = requestMessagess.messageId
    requestMessagess.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/profiles/destroy_profile_picture"
    );
    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "token": token,
      }));
    requestMessagess.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessagess.id, requestMessagess);
    return true;
  }
  getLogoutData = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLogoutItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_login/logouts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updateProfile = async (data: any) => {

      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("authToken") as string
      };
      const body = {
        profile: data,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiSaveInfoID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_profile/profiles/update_profile"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      this.setState({ LoaderFormSetting: true })

      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };


  deleteAccount = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apideleteItemCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  closePopupss = () => {
    this.setState({ showPopups: false });
  };
  handleChangePicture = () => {
    this.setState({ showPopups: true });
  }
  // Customizable Area End
}
