import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  // Customizable Area Start
}

export interface S {
  // Customizable Area Start
  otpAuthTokens: string;
  datas: any[]
  myListings: any;
  imagePreviewss: any,
  selectedFiless: any,
  listingTitles: any,
  listingTypes: string,
  otherdatas: string,
  budgetDatas: any,
  commissionDatas: any,
  categoryDatas: any,
  subcategoryDatas: any,
  locationDatas: any,
  descriptionDatas: any,
  preferencesDatas: any,
  selectedCategorys: any,
  selectedSubCategorys: any,
  categoryApiDatas: any[],
  subCategoryApiDatas: any[],
  criteriaLists: any[],
  DeleteItemsLooking: any,
  viewData: any
  termsPurchases: any,
  categoryErrorData: any,
  listingTitlesErrors: string,
  budgetDatasErrors: string,
  commissionDatasErrors: string,
  categoryDatasErrors: string,
  subcategoryDatasErrors: string,
  locationDatasErrors: string,
  descriptionDatasErrors: string,
  preferencesDatasErrors: string,
  selectedCategorysErrors: string,
  selectedSubCategorysErrors: string,
  categoryApiDatasErrors: string,
  subCategoryApiDatasErrors: string,
  otherdatasErrors: string,
  termsPurchasesErrors: string,
  imagePreviewssErrors: string,
  isCreateButtonClickeds: boolean,
  LoaderLooking: boolean,
  showPopups: boolean,
  showPopupsDelete: boolean,
  storeId: any,
  storeIdSub: any;
  storeIdSubs: any,
  EditApi: any[],
  popUpMsgs: any,
  categoryID: any,
  subCategoryID: any,
  criteriaAddLooking: any[],
  removeImages: any,
  listingTitlesAlready: string[],
  selectedCriteriaListErrors: string,
  selectedCriteriaListErrorss: string,
  commissionDatasErrorsRequired: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class LookingSomethingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiLookingSomethingCallIds: string = "";
  apiCategoryCallIds: string = "";
  apiSubCategoryCallIds: string = "";
  apiEditListingCallId: string = "";
  apiDeleteListingCallId: string = "";
  apiViewListingItemCallIds: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      otpAuthTokens: "",
      datas: [],
      myListings: "",
      imagePreviewss: [],
      imagePreviewssErrors: "",
      selectedFiless: [],
      listingTitles: "",
      otherdatasErrors: "",
      otherdatas: "",
      budgetDatas: "",
      commissionDatas: "",
      categoryDatas: "",
      subcategoryDatas: "",
      viewData: {},
      locationDatas: "",
      descriptionDatas: "",
      preferencesDatas: "",
      selectedCategorys: "",
      categoryErrorData: "",
      selectedSubCategorys: "",
      termsPurchases: "",
      categoryApiDatas: [],
      subCategoryApiDatas: [],
      EditApi: [],
      listingTitlesErrors: "",
      listingTypes: "",
      DeleteItemsLooking: [],
      budgetDatasErrors: "",
      commissionDatasErrors: "",
      categoryDatasErrors: "",
      subcategoryDatasErrors: "",
      locationDatasErrors: "",
      descriptionDatasErrors: "",
      preferencesDatasErrors: "",
      selectedCategorysErrors: "",
      selectedSubCategorysErrors: "",
      categoryApiDatasErrors: "",
      subCategoryApiDatasErrors: "",
      termsPurchasesErrors: "",
      storeId: 0,
      storeIdSub: {},
      storeIdSubs: {},
      popUpMsgs: "",
      criteriaLists: [''],
      showPopups: false,
      showPopupsDelete: false,
      isCreateButtonClickeds: false,
      LoaderLooking: false,
      removeImages: [],
      criteriaAddLooking: [],
      categoryID: localStorage.getItem("selected") || null,
      subCategoryID: localStorage.getItem("selectedCategories") || null,
      listingTitlesAlready: [],
      selectedCriteriaListErrors: "",
      selectedCriteriaListErrorss: "",
      commissionDatasErrorsRequired: ""
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiLookingSomethingCallIds) {
        this.handleLookingSomethingResponse(responseJson);
      } else if (apiRequestCallId === this.apiCategoryCallIds) {
        this.handleCategoryResponses(responseJson);
      } else if (apiRequestCallId === this.apiSubCategoryCallIds) {
        this.handleSubCategoryResponses(responseJson);
      }
      else if (apiRequestCallId === this.apiViewListingItemCallIds) {
        this.handleViewListingResponses(responseJson)
      }
      else if (apiRequestCallId === this.apiEditListingCallId) {
        this.handleEditResponses(responseJson)
      }
      else if (apiRequestCallId === this.apiDeleteListingCallId) {
        this.handleDeleteResponses(responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    window.scrollTo(0, 0);
    const datalook = this.props.navigation.getParam('id');
    this.categoryItems();
    if (datalook) {
      this.getJobsAppliedExecutiveDatas(datalook)
    }

  }

  goToTermsAndConditions() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleLookingSomethingResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ isCreateButtonClickeds: true });
      this.setState({
        criteriaLists: responseJson.data?.attributes.criteria_or_terms,
        isCreateButtonClickeds: true,
      });
    }
    if (responseJson.errors) {
      this.setState({listingTitlesAlready: responseJson.errors[0].title})
      if(this.state.listingTitlesAlready)
      toast.error("Title has been already taken");
    }
    if (responseJson.meta?.success) {
      this.setState({ popUpMsgs: responseJson.meta.message, showPopups: true });
      this.setState({
        listingTitles: "",
        budgetDatas: "",
        commissionDatas: "",
        categoryDatas: "",
        subcategoryDatas: "",
        locationDatas: "",
        descriptionDatas: "",
        preferencesDatas: "",
        selectedCategorys: "",
        selectedSubCategorys: "",
        imagePreviewss: "",
        termsPurchases: [],
        categoryApiDatas: [],
        subCategoryApiDatas: [],
        criteriaLists: [''],
        selectedFiless: []
      })

    }
  }
  handleViewListingResponses(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState({ LoaderLooking: false });
      this.setState({
        listingTitles: responseJson.data.attributes.title,
        budgetDatas: responseJson.data.attributes.budget,
        commissionDatas: responseJson.data.attributes.commission,
        locationDatas: responseJson.data.attributes.location,
        descriptionDatas: responseJson.data.attributes.description,
        preferencesDatas: responseJson.data.attributes.preferences,
        selectedCategorys: responseJson.data.attributes.category,
        selectedSubCategorys: responseJson.data.attributes.sub_category,
        criteriaLists: responseJson.data.attributes.criteria_or_terms,
        imagePreviewss: responseJson.data.attributes.photo,
        listingTypes: responseJson.data.attributes.listing_type,
      }
        , () => {
          const datas = this.state?.categoryApiDatas?.find((item) => item?.attributes?.name === responseJson?.data?.attributes?.category)?.id;
          this.subCategoryItems(datas);
        }
      )
    }
  }
  handleEditResponses(responseJson: any) {
    if(responseJson.message){
      toast.error(responseJson.message);
      return;
    }
    const datalook = this.props.navigation.getParam('id');
    this.setState({
      storeIdSub: responseJson.data
    },
      () =>
        this.props.navigation.navigate("ViewListing", { id: datalook })
    )
  }
  handleDeleteResponses(responseJson: any) {
    this.setState({
      storeIdSubs: responseJson.data
    }, () =>
      this.props.navigation.navigate("UserProfileBasicBlock")
    )
  }

  handleSubCategoryResponses(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        subCategoryApiDatas: responseJson.data,
      })
    }
  }
  handleCategoryResponses(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({
        categoryApiDatas: responseJson?.data
      });

      const content = this.props.navigation.getParam('id');
      this.getJobsAppliedExecutiveDatas(content)

    }
  }
  updateDynamicStates = (update: { [key: string]: any }) => {
    this.setState(update as S);
  };
  handleMyListings = () => {
    this.props.navigation.navigate("UserProfileBasicBlock");
  };
  handleKeepListing = () => {
    this.setState({ showPopupsDelete: false });
  }
  handleCancelListing = () => {
    const datalook = this.props.navigation.getParam('id');
    if (datalook) {
      this.setState({ showPopupsDelete: true });
    } else {
      this.props.navigation.navigate("CreateListing")
    }
    this.setState({ showPopupsDelete: true });
  };
  handleDeleteData = () => {
    const datalook = this.props.navigation.getParam('id');
    this.changeDeleteOnclick(datalook);
  }
  isStringNullOrBlank(str: string,) {
    return str === null || str.length === 0;
  }
  closePopups= () => {
    this.setState({ isCreateButtonClickeds: false });
    this.props.navigation.navigate("UserProfileBasicBlock");
  };
  closePopupsDelete(){
    this.setState({ showPopupsDelete: false });
  };
  handleDeletes = (index: number) => {
    if (this.state.criteriaLists.length > 1) {
      const { criteriaLists, DeleteItemsLooking } = this.state;
      const updatedCriteriaList = [
        ...criteriaLists.slice(0, index),
        ...criteriaLists.slice(index + 1)
      ];
      const updatedDeleteItems = [...DeleteItemsLooking, { id: criteriaLists[index] }];
      this.setState({
        criteriaLists: updatedCriteriaList,
        DeleteItemsLooking: updatedDeleteItems
      });
    }
  };
  handleCreateListings = () => {
    const requiredFields = [
      { name: "listingTitles", label: "Listing Title" },
      { name: "descriptionDatas", label: "Description" },
      { name: "budgetDatas", label: "Budget" },
      { name: "commissionDatas", label: "Reward" },
      { name: "locationDatas", label: "Location" },
      { name: "selectedCategorys", label: "Category" },
      { name: "selectedSubCategorys", label: "SubCategory" },
      { name: "imagePreviewss", label: "Image" },
    ];
    let hasEmptyStrings = this.state.criteriaLists.some((criteria) => !criteria.title || criteria.title.trim() === "");

    if (hasEmptyStrings) {
      this.setState({ selectedCriteriaListErrors: "Criterion is required" });
    }
    if (this.state.selectedCategorys.length == 0) {
      this.setState({ categoryApiDatasErrors: "Category is required" })
    }
    if (this.state.selectedSubCategorys.length == 0) {
      this.setState({ subCategoryApiDatasErrors: "Sub Category is required" })
    }
    if (this.state.imagePreviewss.length == 0) {
      this.setState({ imagePreviewssErrors: "Image is required" })
    }
    requiredFields.forEach((field: any) => {
      let name = field.name as keyof S;
      const fieldValue = this.state[name];
      if (!fieldValue || fieldValue === "") {
        this.updateDynamicStates({ [name + "Errors"]: `${field.label} is required` });
      }
    });

    if (
      this.state.listingTitles.trim() !== "" &&
      this.state.budgetDatas.trim() !== "" &&
      this.state.commissionDatas.trim() !== "" &&
      this.state.descriptionDatas.trim() !== "" &&
      this.state.locationDatas.trim() !== "" &&
      this.state.selectedCategorys.trim() !== "" &&
      this.state.selectedSubCategorys.trim() !== "" &&
      this.state.commissionDatasErrorsRequired === "" &&
      this.state.selectedCriteriaListErrorss === "" &&
      !hasEmptyStrings &&
      this.state.imagePreviewss.length > 0
    ) {
      const datalook = this.props.navigation.getParam('id');
      if (datalook) {
        this.changeIdOnclick(datalook);
      }
      else {
        this.LookingSomething();
        window.scrollTo(0, 0);
      }
    }
  };
  handleAddCriterias = () => {
    const { criteriaLists } = this.state;
    criteriaLists.push('');
    this.setState({
      criteriaLists,
    });
  };

  handleCriteriaChanges = (index: any, value: any) => {
    this.setState((prevState) => {
      const criteriaLists = Array.isArray(prevState.criteriaLists) ? [...prevState.criteriaLists] : [];
      criteriaLists[index] = { ...criteriaLists[index], title: value };
      const hasEmptyStringLooking = criteriaLists.some((criteria) => !criteria.title || criteria.title.trim() === "");
      return { 
        criteriaLists,
        selectedCriteriaListErrors: hasEmptyStringLooking ? "Criterion is required" : "",
      };
    });
  };
  removeImages = (id: number | string | undefined | null, idx: number) => {
    if(!id) {
      const selectedFiless = [...this.state.selectedFiless];
      const imagePreviewss = [...this.state.imagePreviewss];
      const len = imagePreviewss.filter((file) => !!file.id).length;
      imagePreviewss.splice(idx, 1);
      selectedFiless.splice(idx - len, 1);
      this.setState({ imagePreviewss, selectedFiless });
      return;
    }
    const index = this.state.imagePreviewss?.findIndex((file: any) => file.id === id);
    if (index === -1 || !this.state.imagePreviewss) { 
      return;
    }
    const newImagePreviews = [...this.state.imagePreviewss];
    newImagePreviews.splice(index, 1);
    this.setState({ imagePreviewss: newImagePreviews });
    this.setState({ removeImages: [...this.state.removeImages, {id}]})
};
  handleInputChanges = (event: any) => {
    if (!event.target.name) {
      return;
    }
    const { name, value } = event.target;
    let data = name === 'selectedCategorys' ? this.state.categoryApiDatas : this.state.subCategoryApiDatas;

    const selectedData = data.find((item) => item?.attributes?.name === value);
    const categoryId = selectedData?.id ?? "";
    if (name == 'selectedCategorys') {
      this.setState(
        {
          selectedCategorys: categoryId,
          selectedSubCategorys: '',
          categoryID: categoryId,
          categoryApiDatasErrors: "",
        },
        () => {
          localStorage.setItem("selected", categoryId)
          this.subCategoryItems(categoryId);
        }
      )
    }
    if (name == 'selectedSubCategorys') {
      this.setState(
        {
          subCategoryID: categoryId,
          subCategoryApiDatasErrors: "",
        },
        () => {
          localStorage.setItem("selectedCategories", categoryId);
        }
      )
    }
    const requiredFields = [
      { name: "listingTitles", label: "Listing Title" },
      { name: "descriptionDatas", label: "Description" },
      { name: "budgetDatas", label: "Budget" },
      { name: "commissionDatas", label: "Reward" },
      { name: "locationDatas", label: "Location" },
      { name: "selectedCategorys", label: "Category" },
      { name: "selectedSubCategorys", label: "SubCategory" },
      { name: "imagePreviewss", label: "Image" }
    ]

    const handleListingTitleChanges = (value: string) => {
      const regex = /^[A-Za-z0-9][A-Za-z0-9\s]*$|^$/;
      if (regex.test(value)) {
        this.setState({ listingTitles: value,listingTitlesErrors: value.trim() === "" ? "Listing Title is required" : "" });
      }
    }
    
    const handleBudgetCommissionChanges = (value: string) => {
      const regexBudget = /^\d+(\.\d{0,2})?$/;
      if (!regexBudget.test(value) && value !== "") {
        return;
      }
      if (name === 'budgetDatas') {
        const budget = parseFloat(value || '0');
        if (budget > 1000000) {
          this.setState({  selectedCriteriaListErrorss: "Budget should not be more than £1,000,000" });
          return;
        } else {
          this.setState({ selectedCriteriaListErrorss: "" });
        }
      }
      if (name === 'commissionDatas') {
        const commission = parseFloat(value || '0');
        if (commission > 100000) {
          this.setState({ commissionDatasErrorsRequired: "Reward should not be more than £100,000" });
          return;
        } else {
          this.setState({ commissionDatasErrorsRequired: "" });
        }
        const budget = parseFloat(this.state.budgetDatas || '0');
        if (commission > budget) {
          this.setState({ commissionDatasErrorsRequired: "Reward should not be more than the budget" });
          return;
        } else {
          this.setState({ commissionDatasErrorsRequired: "" });
        }
      }
      let updates: any = { [name]: value, [name + "Error"]: "" };
      this.setState(updates);
    };


    const handleOtherFieldChanges = (value: string) => {
      let values: any = { [name]: value };
      this.setState({...values, [name + "Errors"]: ""});
    };

    const handleRequiredFieldss = (value: string) => {
      const field = requiredFields.find((field) => field.name === name);
      if (value === "" && field) {
        this.updateDynamicStates({ [name + "Errors"]: `${field.label} is required` });

      } else {
        let values: any = { [name + "Errors"]: "" }
        this.setState(values);
      }
    };
    this.setState({listingTitlesAlready : []})

    switch (name) {
      case "listingTitles":
        handleListingTitleChanges(value);
        break;
      case "budgetDatas":
      case "commissionDatas":
        handleBudgetCommissionChanges(value);
        break;
      default:
        handleOtherFieldChanges(value);
    }
    handleRequiredFieldss(value);
  }


  LookingSomething = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLookingSomethingCallIds = requestMessages.messageId
    const formData = new FormData();

    for (const selectedFile of this.state.selectedFiless) {
      formData.append('photo[]', selectedFile);
    }
    this.state.criteriaLists.forEach((criteria, index) => {
      if( criteria.title !== undefined)
      formData.append(`criterias_attributes[${index}][title]`, criteria.title);
    });
    formData.append("title", this.state.listingTitles);
    formData.append("budget", this.state.budgetDatas);
    formData.append("commission", this.state.commissionDatas);
    formData.append("location", this.state.locationDatas);
    formData.append("description", this.state.descriptionDatas);
    formData.append("preferences", this.state.preferencesDatas);
    formData.append("category_id", this.state.categoryID);
    formData.append("sub_category_id", this.state.subCategoryID);
    formData.append("listing_type", "looking_for_something");
    formData.append("token", token);

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_locallistingads/local_listing_ads"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Authorization": token,
      }));
    requestMessages.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
    return true;
  };
  categoryItems = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCategoryCallIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/categories/categories"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  subCategoryItems = async (categoryId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubCategoryCallIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_categories/categories/${categoryId}/show_subcategories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  changeIdOnclick = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Authorization": `Bearer ${token}`,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditListingCallId = requestMessage.messageId
    const formData1 = new FormData();
    for (const selectedFiles of this.state.selectedFiless) {
      formData1.append('photo[]', selectedFiles);
    }
    formData1.append("title", this.state.listingTitles);
    formData1.append("budget", this.state.budgetDatas);
    formData1.append("commission", this.state.commissionDatas);
    formData1.append("location", this.state.locationDatas);
    formData1.append("description", this.state.descriptionDatas);
    formData1.append("preferences", this.state.preferencesDatas);
    this.state.categoryID && formData1.append("category_id", this.state.categoryID);
    this.state.subCategoryID && formData1.append("sub_category_id", this.state.subCategoryID);
    if (this.state.criteriaLists.length > 0) {
      this.state.criteriaLists.forEach((criteria, index) => {
        if (criteria.id) {
          formData1.append(`criterias_attributes[${index}][title]`, criteria.title);
          formData1.append(`criterias_attributes[${index}][id]`, criteria.id);
        } else if (!criteria.id && criteria.title) {
          formData1.append(`criterias_attributes[${index}][title]`, criteria.title);

        }
      });
    }
    if (this.state.DeleteItemsLooking.length > 0) {
    this.state.DeleteItemsLooking.forEach((criteria:any, index:number) => {
      formData1.append(`criterias_attributes[${index}][id]`, criteria.id);
      formData1.append(`criterias_attributes[${index}][_destroy]`, "true")
    });
  }
  if (this.state.removeImages.length > 0) {
    this.state.removeImages.forEach((ids: any) => {
      if (ids.id) {
        formData1.append(`remove_imgs[]`, ids.id);
      }
    });
  }
    formData1.append("listing_type", "looking_for_something");
    formData1.append("token", token);


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/local_listing_ads/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData1
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getJobsAppliedExecutiveDatas = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewListingItemCallIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/local_listing_ads/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ LoaderLooking: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  changeDeleteOnclick = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteListingCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_locallistingads/local_listing_ads/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}