// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: { navigate: (a: string) => void };
  id: string;
}

interface S {
  loading: boolean;
  status: string;
}

interface SS {
  id: string;
}

export default class VerifyAccountController extends BlockComponent<Props, S, SS> {

  verifyAccountApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      loading: false,
      status: "notActivate"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.verifyAccountApiCallId) {
        if (responseJson && responseJson.message && responseJson.message === "Account Activated Successfully") {
          this.setState({ status: "activate" });
        } else if (responseJson && responseJson.message && responseJson.message === "Account Already Activated") {
          this.setState({ status: "alreadyActivated" });
        } else if (
          responseJson
          && responseJson.errors
          && Array.isArray(responseJson.errors)
          && responseJson.errors.length > 0
          && responseJson.errors[0].token === "Token has Expired"
        ) {
          this.setState({ status: "expired" });
        } else {
          this.setState({ status: "notActivate" });
        }
        this.setState({ loading: false });
      }
    }
  }

  async componentDidMount() {
    this.verifyAccount();
  }

  navigateToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  verifyAccount = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (!token) {
      this.setState({ status: "notActivate" });
      return;
    }
    this.setState({ loading: true });
    const header = JSON.stringify({
      "Content-Type": "application/json",
    });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.verifyAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/accounts/email_confirmation?token=${token}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End