// Customizable Area Start
import React from "react";
import { Box, styled, CircularProgress } from "@material-ui/core";
import MyListingController from "./MyListingController";
import { ListingEmpty } from "../../../components/src/ListingEmpty.web";
import { editImage } from "./assets";

const Card = styled(Box)({
  width: "185px",
  maxWidth: "185px",
  borderRadius: "5px",
  padding: "20px 20.5px",
  justifyContent: 'space-between',
  backgroundColor: "#FFFFFF",
  flexWrap: 'wrap',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: 'pointer',
});
const SellingText = styled("p")({
  fontFamily: "Segoe UI Bold",
  fontSize: "24px",
  lineHeight: "24px",
  color: "#181818",
  position: "relative",
  margin: "0px",
  paddingTop: "15px",
  paddingLeft: "20px",
  "@media(max-width:450px)": {
    fontSize: "16px",
    lineHeight: "16px"
  },
  paddingBottom: "40px"
});
const GreenLine = styled("div")({
  position: "absolute",
  top: 48,
  left: 21,
  width: 120,
  borderBottom: "2px solid #3CAA49"
});
const CardImage = styled("img")({
  width: '100%',
  height: "211px",
  objectFit: "contain",
  borderRadius: "5px",
  "@media(max-width:1150px)": {
    width: '100%',
  }
});
const EditImage = styled("img")({
  width: "18px",
  height: "18px",
  cursor: 'pointer',
});
const BodyTypography = styled("h6")({
  marginTop: 10,
  fontFamily: "Segoe UI Bold",
  color: '#676767',
  fontSize: "16px",
  lineHeight: "20px",
  margin: 0,
  display: '-webkit-box',
  height: '44px',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});
const BudgetTypography = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  margin: 0,
  color: "#676767"
});
const StyledSpan = styled("span")({
  color: "#3CAA49",
  fontFamily: "Segoe UI Bold",
  fontSize: "14px",
  lineHeight: "22px",
  margin: 0
});
const CommissionTypography = styled("p")({
  fontFamily: "Segoe UI",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#676767",
  margin: 0
});
const MainDiv = styled("div")({
  paddingLeft: '25px',
  display: "grid",
  gridGap: '15px',
  justifyContent: 'flex-start',
  gridTemplateColumns: "auto auto auto auto",
  "@media(min-width:1150px) and (max-width: 1260px)": {
    gridTemplateColumns: "auto auto auto"

  },
  "@media(max-width:960px)": {
    gridTemplateColumns: "auto auto auto",
  },
  "@media(max-width:767px)": {
    gridTemplateColumns: "auto auto"
  },
  "@media(max-width:450px)": {
    gridTemplateColumns: "auto",
    justifyContent: 'center',
  }
});
const TextLooking = styled("div")({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  height: "300px",
  "@media(max-width:450px)": {
    padding: 5
  }
});
const ViewData = styled("div")({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: "Segoe UI Bold",
  fontSize: "16px",
  color: '#3CAA49',
  lineHeight: "24px",
  cursor: "pointer",
});

const CustomBox = styled(Box)({
  height: "calc(100% - 68px)",
  backgroundColor: "rgb(247, 245, 245)",
  width: "100%",
  padding: "0px"
});

const Loader = styled(Box)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: "rgb(247, 245, 245)"
});

export default class MyListingWeb extends MyListingController {
  changeStyleOnclicks(item: any) {
    return (
      <a data-test-id="mylist6"  style={{ textDecoration: 'none', color: 'inherit' }} href={`/LookingSomethings/${item.id}`} onClick={(event) => {
        if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
          event && event.stopPropagation();
          event.preventDefault();
          this.navigateComponent(item.id)
        }
      }}>
      <EditImage  src={editImage} alt="Card Image"  /></a>
    );
  }
  changeStyleOnclicksSomething(item: any) {
    return (
      <a data-test-id="mylist7"  style={{ textDecoration: 'none', color: 'inherit' }} href={`/SellingSomethings/${item.id}`} onClick={(event) => {
        if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
          event && event.stopPropagation();
          event.preventDefault();
          this.navigateComponentSomething(item.id)
        }
      }}>
      <EditImage  src={editImage} alt="Card Image" /></a>
    );
  }
  renderLookingForSomethingCard(item: any) {
    return (
    
      <Card key={item.id} >
          <a data-test-id="mylist1" style={{ textDecoration: 'none', color: 'inherit' }} href={`/ViewListing/${item.id}`} onClick={(event) => {
        if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
          event && event.preventDefault();
          this.viewData(item.id)
        }
      }}>
        <CardImage src={item.attributes.photo[0]?.url} alt="Card Image" />
        <BodyTypography>{item.attributes.title}</BodyTypography>
        <BudgetTypography>Budget <StyledSpan>£ {item.attributes.budget}</StyledSpan></BudgetTypography>
        </a>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <CommissionTypography>Reward <StyledSpan> £ {item.attributes.commission}</StyledSpan></CommissionTypography>
          {this.changeStyleOnclicks(item)}
        </div>
      </Card>
    );
  }

  renderSellingForSomethingCard(item: any) {
    return (
     
      <Card key={item.id} >
 <a data-test-id="mySelling1" style={{ textDecoration: 'none', color: 'inherit' }} href={`/ViewListing/${item.id}`} onClick={(event) => {
        if (!(event.metaKey || event.ctrlKey || event.shiftKey || event.altKey || event.button === 1)) {
          event && event.preventDefault();
          this.viewData(item.id)
        }
      }}>
        <CardImage src={item.attributes.photo[0]?.url} alt="Card Image" />
        <BodyTypography>{item.attributes.title}</BodyTypography>
        <BudgetTypography>Price <StyledSpan>£ {item.attributes.budget}</StyledSpan></BudgetTypography>
        </a>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <CommissionTypography>Reward <StyledSpan> £ {item.attributes.commission}</StyledSpan></CommissionTypography>
          {this.changeStyleOnclicksSomething(item)}
        </div>
      </Card>
     
    );
  }
  renderViewAllButtonLooking() {
    return (
<>
    {this.state.myListing.length > 7 &&  !this.state.showAllSellingSomethingAll && (
      <ViewData data-test-id="mySelling6" onClick={this.myListingAll.bind(this)}>View all</ViewData>
  )}
  </>
    )
  }

  renderViewAllButton() {
    return (
<>
    {this.state.someListing.length > 7 &&  !this.state.showAllDataFilterSelling && (
      <ViewData data-test-id="mySelling6" onClick={this.saveSellingAll.bind(this)}>View all</ViewData>
  )}
  </>
    )
  }
  renderLine() {
    return (
      <hr style={{ width: "988px", height: "0.8px", color: '#E5E7EB', backgroundColor: "#E5E7EB", border: "none", margin: "20px 0px 10px 20px" }} />
    )
  }

  renderEmptyText(message: any) {
    return <TextLooking>{message}</TextLooking>;
  }
  SellingData() {
    return <SellingText>Selling something<GreenLine /></SellingText>
  }
  render() {
    const { LoaderListing, ListingEmptys, myListing, showAllData, someListing, showAllSellingSomething } = this.state;
    const productLooking = this.state.showAllSellingSomethingAll ? this.state.myListing : this.state.myListing.slice(0, 7);
    const productsSelling = this.state.showAllDataFilterSelling ? this.state.someListing : this.state.someListing.slice(0, 7);

    if (LoaderListing) {
      return (
        <Loader>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress style={{ color: '#4fa94d' }} />
          </Box>
        </Loader>
      );
    }

    return (
      <CustomBox>
        {myListing.length === 0 && someListing.length === 0 ? (
          <ListingEmpty navigation={this.props.navigation.navigate} />
        ) : (
          <>
            <div>
              <SellingText>Looking for something<GreenLine /></SellingText>
              {myListing?.length === 0 ? (
                this.renderEmptyText("Looking for something listing is empty")
              ) : (
                <MainDiv>
                  {productLooking?.map((item) =>
                   this.renderLookingForSomethingCard(item)
                  )}
                  {this.renderViewAllButtonLooking()}
                </MainDiv>
              )}
              {this.renderLine()}
              {this.SellingData()}
              {someListing?.length === 0 ? (
                this.renderEmptyText("Selling for something listing is empty")
              ) : (
                <MainDiv>
                  {productsSelling?.map((item) =>
                   this.renderSellingForSomethingCard(item)
                  )}
                  {this.renderViewAllButton()}
                </MainDiv>
              )}
            </div>
          </>
        )}
      </CustomBox>

    );
  }

}
// Customizable Area End
