import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from 'react';
import moment from "moment";
//@ts-ignore
import { saveAs } from 'file-saver';
//@ts-ignore
import * as domToImage from 'dom-to-image';
import { PDFDocument } from 'pdf-lib';
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  otpAuthTokenS: string;
  billingInvoiceData: any;
  countrysS: any;
  citysS: any;
  setInitiateDatasS: string[];
  listingTypesS: string;
  setBuyerSellerS: any;
  setBuyerImgS: any;
  setSellerImgS: any;
  setTitleS: string;
  setBudgetS: string;
  setCommissionS: string;
  tabSelectValueOther: number;
  LoaderOtherUser: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  get_order_invoice_data: string = "";
  scrollViewRef:React.RefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollViewRef = React.createRef();

    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      invoice: "",
      invoiceError: "",
      invoicePdf: "",
      otpAuthTokenS: "",
      billingInvoiceData: {},
      countrysS: "",
      citysS: "",
      setInitiateDatasS: [],
      listingTypesS: "",
      setBuyerSellerS: {},
      setBuyerImgS: "",
      setSellerImgS: "",
      setTitleS: "",
      setCommissionS: "",
      setBudgetS: "",
      tabSelectValueOther: 0,
      LoaderOtherUser: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonS = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallIdS = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallIdS === this.get_order_invoice_data) {
        if (responseJsonS && !responseJsonS.errors) {
          this.setState({ LoaderOtherUser: false });
          this.setState({
            billingInvoiceData: responseJsonS.data,
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getOrderInvoiceData();
    const id = this.props.navigation.getParam('id');
  }
 onChangeInvoice = (text: string) => {
    if (text === "") {
      this.setState({
        invoice: text,
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ invoice: text, invoiceError: "" });
    }

  };

  getPdfLink = () => {
    if (this.state.invoice === "" || this.state.invoice.length !== 6) {
      this.setState({
        invoiceError: configJSON.invoiceValidation,
      });
    } else {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.pdfApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.exampleAPiEndPoint + this.state.invoice
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  getOrderInvoiceData = async () => {
    const token = await localStorage.getItem("authToken") as string;
    const id = this.props.navigation.getParam("id");
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.get_order_invoice_data = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_paypal/get_order_invoice/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,
      })
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  timeFormat = () => {
    return moment(this.state.billingInvoiceData?.attributes?.created_at).format("MMMM D, YYYY");
  }
  downloadPdf = async () => {
    try {
      const imageBlob = await domToImage.toBlob(this.scrollViewRef.current);
      const image = await imageBlob.arrayBuffer();
  
      const pdfDoc = await PDFDocument.create();
      const pageWidth = this.scrollViewRef.current.offsetWidth;
      const pageHeight = this.scrollViewRef.current.offsetHeight;
      const page = pdfDoc.addPage([pageWidth, pageHeight]);
  
      const imageEmbed = await pdfDoc.embedPng(image);
      const { width, height } = imageEmbed.scale(0.8);
  
      // Calculate the coordinates to center the image on the page
      const x = (pageWidth - width) / 2;
      const y = (pageHeight - height) / 2;
  
      page.drawImage(imageEmbed, {
        x,
        y,
        width,
        height,
      });
  
      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  
      saveAs(pdfBlob, 'screen.pdf');
    } catch (error) {
     
    }
  };
  handleBack=(id:string)=>{
    this.props.navigation.navigate("OrderDetails",{id:id})
  }
  // Customizable Area End
}
