import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  userId: string | null | number | undefined;
  // Customizable Area Start
}

export interface S {
  // Customizable Area Start
  otpAuthToken: string;
  data: [];
  activeGridId: number;
  emailExists: boolean;
  checkboxChecked: boolean;
  isSnackbarOpen: boolean;
  checkboxError: boolean;
  snackbarOpen: boolean;
  termsData: [];
  policyData: [];
  isPrivacyPolicyModalOpen: boolean;
  emailErrorMsg: string;
  myListing: [];
  someListing: [];
  LoaderListing: boolean;
  showAllData: boolean;
  showAllSellingSomething: boolean;
  categoryApiData: [];
  subCategoryApiData: [];
  showMore: { [key: string]: boolean };
  criteriaList: [];
  feedbackList:[];
  toggleId :null|number
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class MyReviewsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiMyListingItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      otpAuthToken: "",
      data: [],
      activeGridId: 1,
      emailExists: false,
      checkboxChecked: false,
      isSnackbarOpen: false,
      checkboxError: false,
      snackbarOpen: false,
      termsData: [],
      feedbackList:[],
      policyData: [],
      isPrivacyPolicyModalOpen: false,
      emailErrorMsg: "",
      myListing: [],
      showMore: {},
      someListing: [],
      LoaderListing: true,
      showAllData: false,
      showAllSellingSomething: false,
      categoryApiData: [],
      subCategoryApiData: [],
      criteriaList: [],
      toggleId:null
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.apiMyListingItemCallId) {
        this.setState({ LoaderListing: false });
        this.setState({ feedbackList:responseJson.data})
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getJobsAppliedExecutiveData();
  }

  handleCLick = (id: string) => {
    const contentss = this.props.navigation.getParam('id');
      let url = `?id=${id}`;
        if(contentss){
          url += `&userid=${contentss}`;
        }
        this.props.navigation.navigate("MyReviewsData",url);
  }
  toggleShowMore = (id:any) => {
    this.setState((prevState) => ({
      showMore: {
        ...prevState.showMore,
        [id]: !prevState.showMore[id],
      },
    }));
  };
  getJobsAppliedExecutiveData = async () => {
    const token = localStorage.getItem("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyListingItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reviewslistingApiEndPoint}?user_id=${this.props.userId ?? localStorage.getItem("id")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
