// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const { Client } = require("@twilio/conversations");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  accountId: number;
  chatName: string;
  chatList: [];
  selectedTab: any;
  chatModal: boolean;
  userEmail: string | null;
  userToken: string | null;
  loading: boolean;
  loadingmsg: boolean;
  currentChat: any;
  chatsList: any;
  messageId: number | string | null;
  chatUpdateId:any
}

interface SS {
  id: string;
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  groupChatListBus: string = "";
  generateTokenMsg: string = "";
  chatClient: any;
  readMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getChatToken = this.getChatToken.bind(this);
    this.handleMessageList = this.handleMessageList.bind(this);
    this.handleChannelUpdated = this.handleChannelUpdated.bind(this);
    this.getParticipantId = this.getParticipantId.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      userEmail: "",
      userToken: "",
      loading: true,
      loadingmsg: false,
      currentChat: {},
      chatModal: false,
      chatsList: [],
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      selectedTab: null,
      messageId: null,
      chatUpdateId:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.generateTokenMsg) {
        this.chatClient = new Client(responseJson.token);
        this.getChatList();
      }
      if (apiRequestCallId === this.groupChatListBus) {
        this.setState({
          loading: false,
          chatsList: responseJson.data,
        });
        let dataid =  localStorage.getItem("navMsgId");
        if(dataid != undefined){
        let userId =responseJson.data.filter((item:any)=>item.id ==dataid)
        this.handleOpen(userId[0])}
        this.addEvents(responseJson.data);
      }
    }
  }

  async componentDidMount() {
    const userEmail = localStorage.getItem("email");
    const userToken = localStorage.getItem("authToken");
    this.setState(
      {
        userEmail,
        userToken,
      },
      () => {
        this.getChatToken();
      }
    );
  }

  handleItemClick = (itemId: any) => {
    this.setState({ selectedTab: itemId, chatModal: true });
  };
  handleOpen = (item: any) => {
    this.setState({
      selectedTab: item.id,
      chatModal: true,
      currentChat: item,
      chatUpdateId:item.id
    });
    this.readMessage(item.id)
  };

  addEvents = (chats: any) => {
    chats.forEach(async (chat: any) => {
      console.log("chatSid", chat.attributes);

      const channelOb = await this.chatClient.getConversationBySid(
        chat.attributes.sid
      );
      console.log("chatSid", channelOb);

      if (channelOb._internalState.status != "joined") {
        try { 
          await channelOb.join();
        } catch(err){}
      }
      channelOb.on("messageAdded", this.handleMessageList);
      channelOb.on("updated", this.handleChannelUpdated);
    });
  };
  handleMessageList = async (messageData: any) => {
    const { chatsList } = this.state;
    await messageData.conversation.updateLastReadMessageIndex(
      messageData.state.index
    );
    let chats: any = [];

    for (const chat of chatsList) {
      if (chat.attributes.sid == messageData.conversation.sid) {
        chats = [{ ...chat }, ...chats];
      } else {
        chats.push(chat);
      }
    }
    this.setState({ chatsList: chats });
  };
  handleChannelUpdated = async (data: {
    conversation: any;
    updateReasons: Array<string>;
  }) => {
    const { updateReasons } = data;
    if (updateReasons.indexOf("attributes") == -1) return;
    let chats: any = [];

    this.setState({
      chatsList: chats,
    });
  };

  getParticipantId = async (channelOb: any, memberData: any) => {
    const participants = await channelOb.getParticipants();
    const oldIds: Array<string> = [];
    memberData.forEach((item: any) => {
      if ((item.email == this.state.userEmail) == item.type.toLowerCase()) {
        oldIds.push(item.participant_id);
      }
    });
    const participant = participants.filter((participant: any) => {
      return (
        participant.state.identity == this.state.userEmail &&
        oldIds.indexOf(participant.state.sid) == -1
      );
    });
    return participant[0].state.sid;
  };

  getChatToken = async () => {
    const { userToken, userEmail } = this.state;

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: userToken,
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chats/token_for_chat?identity=${userEmail}`
    );
    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    this.generateTokenMsg = request.messageId;
    runEngine.sendMessage(request.id, request);
  };

  getChatList = () => {
    const { userToken } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: userToken,
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.groupChatListBus = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_chat/chats/conversation_list`
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(request.id, request);
  };

  readMessage = async (id: any) => {
    const token = localStorage.getItem("authToken") as string;
    localStorage.removeItem("navMsgId")
    const requestMessage = new Message
    (
      getName(MessageEnum.RestAPIRequestMessage));

    this.readMessageId = requestMessage.messageId;
 


    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chats/${id}/read_messages` );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,}
        ) );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT");
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}



// Customizable Area End
