// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface Image {
  url: string;
}

interface Listing {
  listing_type: string;
  title: string;
  description: string;
  budget: string;
  commission: number;
  category: string;
  order_id: string;
  image: string[];
}

interface BuyerProfileDetail {
  id: number;
  account_id: number;
  address: string | null;
  country: string;
  city: string;
  photo: string;
  full_name: string;
  user_name: string;
  email: string;
}
interface SellerProfileDetail {
  id: number;
  account_id: number;
  address: string | null;
  country: string;
  city: string;
  photo: string;
  full_name: string;
  user_name: string;
  email: string;
}

interface Attributes {
  id: number;
  created_at: string;
  updated_at: string;
  listing: Listing;
  status: string
  buyer_profile_detail: BuyerProfileDetail;
  seller_profile_detail: SellerProfileDetail;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes1 {
  id: string;
  account_id: string;
  address: string | null;
  country: string;
  city: string;
  photo: string;
  full_name: string;
  user_name: string;
  email: string;
}

interface ProfileData {
  id: string;
  type: string;
  attributes: Attributes1;
}



// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
  // Customizable Area Start
}

export interface S {
  // Customizable Area Start
  otpAuthToken: string;
  useProfiles: ProfileData;
  countrys: string;
  citys: string;
  reviewData: Data;
  LoaderNegotiationData: boolean;
  isOpen: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userProfileApiCallIds: string = "";
  productDetailsApiCallIds: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      otpAuthToken: "",
      useProfiles: {
        id: "",
        type: "",
        attributes: {
          id: "",
          account_id: "",
          address: null,
          country: "",
          city: "",

          photo: "",
          full_name: "",
          user_name: "",
          email: ""
        }
      },
      countrys: '',
      citys: '',
      reviewData:
      {
        id: "",
        type: "",
        attributes: {
          id: 0,
          created_at: "",
          updated_at: "",
          status: "",
          listing: {
            listing_type: "",
            title: "",
            description: "",
            budget: "",
            commission: 0,
            category: "",
            order_id: "",
            image: []
          },
          buyer_profile_detail: {
            id: 0,
            account_id: 0,
            address: null,
            country: "",
            city: "",
            photo: "",
            full_name: "",
            user_name: "",
            email: ""
          },
          seller_profile_detail: {
            id: 293,
            account_id: 417,
            address: null,
            country: "",
            city: "",
            photo: "",
            full_name: "",
            user_name: "",
            email: ""
          }
        }
      },

      LoaderNegotiationData: true,
      isOpen: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.userProfileApiCallIds) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            useProfiles: responseJson.data,
            countrys: responseJson.data.attributes.country,
            citys: responseJson.data.attributes.city
          });
        }
      }
      if (apiRequestCallId === this.productDetailsApiCallIds) {
        this.setState({ LoaderNegotiationData: false, reviewData: responseJson.data })

      }


    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.userProfileCalls()
    window.scrollTo(0, 0);
    this.initiateDealsIndexs()
  }

  onModalClose = () => {
    this.setState({ isOpen: false });
  }

  onOpen = () => {
    this.setState({ isOpen: true });
  }

  handleButtonClicks = async () => {
    localStorage.clear();
    sessionStorage.clear();
    this.props.navigation.navigate("LandingPageMain");
  };
  handleButtonClickSettings = () => {
    const navigateToOrderDetailsMessage: Message = new Message(
      getName(MessageEnum.NavigationSettings2Message)
    );
    navigateToOrderDetailsMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateToOrderDetailsMessage);

  };

  userProfileCalls = async () => {
    const token = await getStorageData("authToken") as string;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileApiCallIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/profiles/show_user_profile"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: token
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  initiateDealsIndexs = async () => {
    const token = await getStorageData("authToken") as string;
    const id = this.props.navigation.getParam('id');
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productDetailsApiCallIds = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_paypal/get_order/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };



  handleNavigateHistory = () => {
    this.props.navigation.navigate("UserProfileBasicBlock", "?tab=orderhistory")
  }


  handleNavigateInvoice = (id:string) => {
    this.props.navigation.navigate("InvoiceBilling",{id:id})
  }

  handleNavigateRefund = (orderId: string) => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RefundManagement"
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    if (orderId) {
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
      raiseMessage.addData(getName(MessageEnum.InfoPageBodyMessage), orderId);
      navigateMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
    }

    this.send(navigateMessage)
  }



  // Customizable Area End
}
