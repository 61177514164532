// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import React, { createRef } from "react";
export const configJSON = require("./config");

export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number; url: string }[] | null;
  };
}

export interface Props {
  navigation: any;
  id: string;
  client: any;
  chatUserInformation: any;
  getChatList:any
}

interface S {
  pinMessagesData: Array<string>;
  pinModal: boolean;
  mobileView: boolean;
  messages: any;
  messageText: string;
  userToken: string | null;
  currentPage: number;
  perPage: number;
  searchTerm: string;
  chatInformation: any;
  chatInfo: boolean;
  isMobile: boolean;
  showMediaLoading: boolean;
  searchBar: boolean;
  searchResults: any;
  deleteModal: boolean;
  userId: any;
  emojiPicker: boolean;
  selectedEmoji: null;
  chatMessageObj: any;
  currentMessageSelected: string;
  pinUnPinMessageId: string;
  userEmail: string | null;
  currentSearchSelected: number;
  dropDownPin: boolean;
  lastDeliveredMessageIndex: number | null;
  lastReadMessageIndex: number | null;
  loading: boolean;
  showPopup: boolean;
  warningMessage: any;
  userNameFromLocalStorage: any;
}

interface SS {
  id: string;
  client: any;
  chatUserInformation: any;
}

export default class ViewMyChatController extends BlockComponent<Props, S, SS> {
  chatUserInformation: any;
  chatMessagesRef: any;
  channel: any;
  client: any;
  triggerChatId: string = "";
  scrollViewRef:React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.receive = this.receive.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
    this.getUpdatedMessages = this.getUpdatedMessages.bind(this);
    this.handleChannelUpdated = this.handleChannelUpdated.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.keyUp = this.keyUp.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleMessagStructure = this.handleMessagStructure.bind(this);

    this.getMemberChannelId = this.getMemberChannelId.bind(this);
    this.scrollViewRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      currentPage: 1,
      mobileView: true,
      userNameFromLocalStorage: "",
      chatMessageObj: {},
      searchTerm: "",
      userEmail: "",
      messages: [],
      chatInfo: false,
      messageText: "",
      currentSearchSelected: 0,
      showMediaLoading: false,
      userToken: "",
      perPage: 20,
      chatInformation: {},
      pinModal: false,

      isMobile: false,
      searchBar: false,
      dropDownPin: false,
      lastReadMessageIndex: null,
      deleteModal: false,
      loading: true,
      userId: 0,
      selectedEmoji: null,
      emojiPicker: false,
      pinMessagesData: [],
      pinUnPinMessageId: "",
      searchResults: [],
      currentMessageSelected: "",
      lastDeliveredMessageIndex: null,
      showPopup: false,
      warningMessage: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.chatMessagesRef = createRef();
    this.handlePaginationMessages = this.handlePaginationMessages.bind(this);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.scrollToEnd();
    this.client = this.props.client;
    this.chatUserInformation = this.props.chatUserInformation;
    const userEmail = localStorage.getItem("email");
    const userToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("id");
    this.setState({
      userEmail,
      userToken,
      chatInformation: this.props.chatUserInformation,
      userId,
      chatMessageObj: {},
      messages: {},
      pinMessagesData: this.props.chatUserInformation.attributes.pinned,
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.handleUserClick();
    
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.scrollToBottom();
    if (prevProps.chatUserInformation !== this.props.chatUserInformation) {
      this.chatUserInformation = this.props.chatUserInformation;
      this.client = this.props.client;
      this.handleUserClick();
      this.setState({
        chatInformation: this.props.chatUserInformation,
        loading: true,
        messages: {},
        chatMessageObj: {},
      });
    }

    if (prevState.messages.Today?.data.length !== this.state.messages.Today?.data.length) {
      this.props.getChatList()
      this.scrollToEnd();
    }
    if (prevState.messages !== this.state.messages) {
      this.props.getChatList()
    
    }


  }

  async componentWillUnmount() {
    this.channel?.removeListener("messageAdded", this.handleMessageAdded);
    this.channel.removeListener("updated", this.handleChannelUpdated);
  }

  async handlePaginationMessages(event: any) {
    if (event.target.scrollTop <= 20 && this.state.chatMessageObj.hasPrevPage) {
      const objMessage = await this.state.chatMessageObj.prevPage();
      this.setState({ chatMessageObj: objMessage });
      this.setMessages(objMessage, true, this.state.messages, true);
    }
  }

  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 ? true : false });
  }

  getTwilioMessages = async () => {
    this.setState({ messages: [] });

    const objMessage = await this.channel.getMessages();
    this.setState({ chatMessageObj: objMessage });
    if (objMessage.items.length > 0) {
      this.setMessages(objMessage, false, {}, false);
    }
    this.setState({ loading: false });
  };

  setMessages = (
    messagesData: any,
    prevCall: boolean,
    currentMessages: any,
    onScroll: boolean
  ) => {
    let messagesArray: any = [];
    messagesArray = messagesData.items.map(async (message: any) => {
      const messagesState = {
        ...message.state,
        deleteMessage: message.remove,
        services: message.services,
        links: message.links,
        updateAttributes: message.updateAttributes,
      };
      const DiffLabel = this.getDiffLabel(messagesState.timestamp);
      return new Promise(async (resolve, reject) => {
        let messageReturn = {};
        if (messagesState.type == "media") {
          const urlLink = await messagesState.media.getContentTemporaryUrl();
          messageReturn = this.getMessageState(
            messagesState,
            urlLink,
            DiffLabel
          );
        } else {
          messageReturn = this.getMessageState(
            messagesState,
            messagesState.media,
            DiffLabel
          );
        }
        resolve(messageReturn);
      });
    });
    Promise.all(messagesArray).then((values) => {
      this.updateMessagesState(
        values,
        prevCall,
        currentMessages,
        onScroll,
        messagesData
      );
    });
  };

  scrollToEnd = () => {
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollTop = this.scrollViewRef.current.scrollHeight;
    }
  };



  updateMessagesState = async (
    messages: any,
    prevCall: boolean,
    currentMessages: any,
    onScroll: boolean,
    objMessage: any
  ) => {
    const newMessagesState = this.getUpdatedMessages(
      messages,
      currentMessages,
      prevCall
    );
    if (!onScroll) {
      this.scrollToEnd();

    }
    if (Object.keys(newMessagesState).length < 30 && objMessage.hasPrevPage) {
      const newobjMessage = await objMessage.prevPage();
      this.setState({ chatMessageObj: newobjMessage });
      this.setMessages(newobjMessage, true, newMessagesState, false);
    }
  };

  getUpdatedMessages = (
    messages: any,
    currentMessages: any,
    prevCall: boolean
  ) => {
    let newMessagesState: any = {};
    if (prevCall) {
      messages.reverse();
      newMessagesState = currentMessages;
    }
    messages.forEach((message: any) => {
      if (!message) return;
      if (message.DiffLabel in newMessagesState) {
        let messageDataList = [];
        if (prevCall) {
          messageDataList = [
            message,
            ...newMessagesState[message.DiffLabel].data,
          ];
        } else {
          messageDataList = [
            ...newMessagesState[message.DiffLabel].data,
            message,
          ];
        }
        newMessagesState = {
          ...newMessagesState,
          [message.DiffLabel]: { data: messageDataList },
        };
      } else {
        if (prevCall) {
          newMessagesState = {
            [message.DiffLabel]: {
              data: [message],
            },
            ...newMessagesState,
          };
        } else {
          newMessagesState = {
            ...newMessagesState,
            [message.DiffLabel]: {
              data: [message],
            },
          };
        }
      }
    });
    this.setState({ messages: newMessagesState, loading: false });
    return newMessagesState;
  };

  getMessageState = (messageState: any, mediaData: any, DiffLabel: string) => {
    return {
      ...messageState,
      author: messageState.author,
      body: messageState.body,
      memberSid: messageState.memberSid,
      dateUpdated: messageState.dateUpdated.toString(),
      timestamp: messageState.timestamp,
      media: mediaData,
      type: messageState.type,
      customAttributes: messageState.attributes,
      sid: messageState.sid,
      DiffLabel,
      deleteMessage: messageState.deleteMessage,
      index: messageState.index,
    };
  };

  handleMessageAdded = async (message: any) => {
    if (message.conversation.sid != this.channel.sid) return;

    let duplicateMsg = false;
    let { messages } = this.state;
    Object.values(messages).forEach((msgs: any) => {
      if (
        msgs.data.filter((mssg: any) => mssg.sid == message.state.sid).length >
        0
      ) {
        duplicateMsg = true;
      }
    });
    if (duplicateMsg) return;
    let messageState = {
      ...message.state,
      deleteMessage: message.remove,
      services: message.services,
      links: message.links,
      updateAttributes: message.updateAttributes,
    };
    const DiffLabel = this.getDiffLabel(messageState.timestamp);
    let messageObj = {};
    if (messageState.type == "media") {
      const urlLink = await messageState.media.getContentTemporaryUrl();
      messageObj = this.getMessageState(messageState, urlLink, DiffLabel);
    } else {
      messageObj = this.getMessageState(
        messageState,
        messageState.media,
        DiffLabel
      );
    }
    if (DiffLabel in messages) {
      messages = {
        ...messages,
        [DiffLabel]: {
          data: [...messages[DiffLabel].data, messageObj],
        },
      };
    } else {
      messages = {
        ...messages,
        [DiffLabel]: {
          data: [messageObj],
        },
      };
    }
    this.setState({
      showMediaLoading: false,
      messages,
    });
    this.scrollToEnd();
  };

  // Function to handle input change
  handleInputChange = (e: any) => {
    const inputText = e.target.value;
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const bankAccountRegex = /\b\d{10,30}\b/;
    const phoneRegex = /\b\d{10}\b/;

    if (
      emailRegex.test(inputText) ||
      bankAccountRegex.test(inputText) ||
      phoneRegex.test(inputText)
    ) {
      this.setState({
        warningMessage:
          "For the safety of our users, sensitive information such as Phone number, Email ID, and Bank details cannot be shared through chat.",
      });
    } else {
      this.setState({ warningMessage: "" });
    }

    this.setState({ messageText: inputText });
  };

  // Function to handle sending message
  sendMessage = async () => {
    const { messageText: text, warningMessage } = this.state;

    if (warningMessage) {
      return;
    }

    if (text && String(text).trim()) {
      // Send message
      this.sendTwilioMessage(null, text);
      this.setState({
        messageText: "",
      });
      this.scrollToBottom();
    }
  };

  getMemberChannelId = async (channel: any, memberData: any) => {
    const participants = await channel.getParticipants();
    const oldIds: Array<string> = [];
    memberData.forEach((item: any) => {
      if (item.email == this.state.userEmail) {
        oldIds.push(item.participant_id);
      }
    });
    const participantId = participants.filter(
      (participant: any) =>
        participant.state.identity == this.state.userEmail &&
        oldIds.indexOf(participant.state.sid) == -1
    )[0].state.sid;
    return participantId;
  };

  handleUserClick = async () => {
    console.log("qwerty", this.chatUserInformation.attributes.sid);

    this.channel = await this.client.getConversationBySid(
      this.chatUserInformation.attributes.sid
    );
    if (this.channel._internalState.status != "joined") {
      try {
        await this.channel.join();
      } catch(err) {}
    }
    this.channel.on("messageAdded", this.handleMessageAdded);
    this.channel.on("updated", this.handleChannelUpdated);
    this.getTwilioMessages();
  };

  sendTwilioMessage = async (formData: any, textMessage: any) => {
    if (formData) {
      await this.channel
        .prepareMessage()
        .setBody("")
        .setAttributes({
          senderId: this.state.userId,
        })
        .addMedia(formData)
        .build()
        .send();
    } else {
      await this.channel
        .prepareMessage()
        .setBody(textMessage)
        .setAttributes({
          senderId: this.state.userId,
        })
        .build()
        .send();
    }
  };

  keyUp = (e: any) => {
    if (e.keyCode == 13) {
      this.sendMessage();
    }
  };

  scrollToBottom = () => {
    const messagesListingElement = document.getElementById("messagesListing");
    if (messagesListingElement) {
      messagesListingElement.scrollTop = messagesListingElement.scrollHeight;
    }
  };

  getDiffLabel = (messageTime: any) => {
    const date2: any = new Date(messageTime);
    const date1: any = new Date();
    const date3 = new Date();

    date3.setHours(0, 0, 0, 0);
    let DiffLabel = "";
    const DiffDays = Math.floor(
      Math.abs(date2 - date1) / 1000 / (60 * 60 * 24)
    );
    if (DiffDays == 1) {
      DiffLabel = "Yesterday";
    } else if (DiffDays == 0) {
      if (date3 > date2) {
        DiffLabel = "Yesterday";
      } else {
        DiffLabel = "Today";
      }
    } else {
      DiffLabel = new Date(messageTime).toLocaleString("en-IN", {
        day: "numeric",
        month: "short",
      });
      const yearLabel = new Date(messageTime).toLocaleString("en-IN", {
        year: "numeric",
      });
      DiffLabel = DiffLabel + ", " + yearLabel;
    }
    return DiffLabel;
  };

  getClearDates = (type: number) => {
    const currentDate = new Date();
    if (type === 0) {
      return new Date(currentDate).toISOString();
    } else if (type === 1) {
      const oneDay = 24 * 60 * 60 * 1000;
      const prevDay = currentDate.getTime() - oneDay;
      const prevDate = new Date(prevDay);
      return new Date(prevDate).toISOString();
    } else if (type === 2) {
      const oneDay = 7 * 24 * 60 * 60 * 1000;
      const prevDay = currentDate.getTime() - oneDay;
      const prevDate = new Date(prevDay);
      return new Date(prevDate).toISOString();
    } else if (type === 3) {
      const prevDate = new Date(currentDate);
      prevDate.setMonth(currentDate.getMonth() - 1);
      return new Date(prevDate).toISOString();
    }
  };

  handleMessagStructure = (dateKey: string, msgData: any, msg: any) => {
    if (dateKey in msgData) {
      msgData = {
        ...msgData,
        [msg.DiffLabel]: {
          data: [...msgData[msg.DiffLabel].data, msg],
        },
      };
    } else {
      msgData = {
        ...msgData,
        [msg.DiffLabel]: {
          data: [msg],
        },
      };
    }
    return msgData;
  };

  handleChannelUpdated = (data: {
    conversation: any;
    updateReasons: Array<string>;
  }) => {
    const { updateReasons } = data;
    if (updateReasons.indexOf("attributes") == -1) return;
  };
}


// Customizable Area End
