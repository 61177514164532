Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'bx_block_locallistingads/local_listing_ads?search'
exports.errorTitles = 'Errorss'
exports.errorTitlesRequired = 'Errorss data'
exports.errorTitle = 'Error'
// Customizable Area End
