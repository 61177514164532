import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  switchChecked1: boolean;
  switchChecked2: boolean;
  switchChecked3: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscribeNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notificationValue: string = "";
  updateMessage: string = "";
  apiData: any = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      switchChecked1: false,
      switchChecked2: false,
      switchChecked3: false,
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleChangeSwitch1 = this.handleChangeSwitch1.bind(this);
    this.handleChangeSwitch2 = this.handleChangeSwitch2.bind(this);
    this.handleChangeSwitch3 = this.handleChangeSwitch3.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.notificationValue) {
        this.setState({
          loading: false,
          switchChecked1: responseJson.data.chat,
          switchChecked2: responseJson.data.deal,
          switchChecked3: responseJson.data.review,
        });
      }
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  async componentDidMount() {
    this.getSubscribeNotificationDetails();
  }

  handleChangeSwitch1(switchChecked1: any) {
    this.setState({ switchChecked1 });
    this.updateSubscribeNotificationDetails({ chat: switchChecked1 });
  }

  handleChangeSwitch2(switchChecked2: any) {
    this.setState({ switchChecked2 });
    this.updateSubscribeNotificationDetails({ deal: switchChecked2 });
  }

  handleChangeSwitch3(switchChecked3: any) {
    this.setState({ switchChecked3 });
    this.updateSubscribeNotificationDetails({ review: switchChecked3 });
  }

  updateSubscribeNotificationDetails = async (updateValue: any) => {
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let Body = updateValue;
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/update"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.updateMessage = requestMessages.messageId;
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
  };
  getSubscribeNotificationDetails = async () => {
    this.setState({ loading: true });
    const token = localStorage.getItem("authToken") as string;
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.notificationValue = requestMessages.messageId;

    this.apiData = requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/show"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
  };
  // Customizable Area End
}
