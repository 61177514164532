import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area End
    // Customizable Area Start
}

export interface S {
    // Customizable Area Start
    showTextArea: boolean;
    buttonClicked: boolean;
    negotiateButton: boolean;
    showTextAreaCommission: boolean;
    showTextAreaPrefrence: boolean;
    buttonClickedCommission: boolean;
    negotiateButtonCommission: boolean;
    prefrenceButtonCommission: boolean;
    prefrenceDenyButtonCommission: boolean;
    amendment_deals: any;
    selected: any,
    selectedDescription: any;
    criteria_or_terms: any
    data: any;
    activeButtons: any[];
    showTextAreas: any;
    userDetails: any;
    userDetailsID: any
    amendmentDealsAttributes: any;
    acceptOrDeny: string;
    negotiatedReason: string;
    textAreaValue: string;
    commissionStatus: string;
    budgetStatus: string;
    commissionReason: string;
    preferenceStatus: string;
    preferenceReason: string;
    accountID: string;
    showPopupsLogoutNegotiation: boolean;
    popupNegotiate: boolean;
    isDisabled: boolean;
    criteria_amendments: any;
    buttonStates: any,
    buttonDisabled: boolean,
    budgetError: string,
    commissionError: string,
    TermsOfPurchaseError: string,
    preferenceError: string,
    intialDealsId: string,
    sellerCheck: string,
    listingTypes: string,
    LoaderFormApprovalBuyer: boolean,
    criteriaErrors: any[],
    isFinalize: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class NegotiationSellerToBuyer extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    NegotiationSellerToBuyerApiData: string = "";
    NegotiationSellerToBuyerApiDataPost: string = "";
    FromApprovalDataApiData: string = "";
    FromApprovalDataCanceldata: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            isFinalize: false,
            showTextArea: false,
            buttonClicked: false,
            negotiateButton: false,
            showTextAreaCommission: false,
            showTextAreaPrefrence: false,
            buttonClickedCommission: false,
            negotiateButtonCommission: false,
            prefrenceButtonCommission: false,
            prefrenceDenyButtonCommission: false,
            selected: null,
            selectedDescription: null,
            data: "",
            userDetailsID: null,
            criteria_or_terms: [],
            activeButtons: [],
            showTextAreas: Array(9).fill(false),
            userDetails: null,
            amendmentDealsAttributes: [],
            acceptOrDeny: '',
            negotiatedReason: "",
            textAreaValue: '',
            commissionStatus: '',
            budgetStatus: '',
            commissionReason: '',
            preferenceStatus: '',
            preferenceReason: '',
            accountID: '',
            showPopupsLogoutNegotiation: false,
            amendment_deals: [],
            criteria_amendments: [],
            buttonStates: {},
            popupNegotiate: false,
            buttonDisabled: false,
            budgetError: '',
            commissionError: '',
            TermsOfPurchaseError: '',
            preferenceError: '',
            intialDealsId: "",
            sellerCheck: "",
            listingTypes: "",
            LoaderFormApprovalBuyer: false,
            isDisabled: false,
            criteriaErrors: []
            // Customizable Area End
        };

        // Customizable Area Start

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.NegotiationSellerToBuyerApiData) {
                if (responseJson && !responseJson.errors) {
                    this.setState({ LoaderFormApprovalBuyer: false })
                    this.setState({
                        intialDealsId: responseJson.data.attributes.deal_id,
                        data: responseJson.data.attributes,
                        criteria_or_terms: responseJson.data.attributes.criteria_or_terms,
                        userDetails: responseJson.data.attributes.user_details.data.attributes.user_name,
                        userDetailsID: responseJson.data.attributes.user_details.data.attributes.id,
                        accountID: responseJson.data.attributes.account_id,
                    });
                }
            }
            if (apiRequestCallId === this.NegotiationSellerToBuyerApiDataPost) {
                this.handleResponse(responseJson);
            }
            if (apiRequestCallId === this.FromApprovalDataCanceldata) {
                this.handleNegotitiationPopupData(responseJson)
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo(0, 0);
        const contentss = this.props.navigation.getParam('id');
        this.NegotiationSellerToBuyerAll(contentss)
    }

    handleResponse = (responseJson: { errors: { [s: string]: string; }[]; data: { attributes: { id: string; listing_detail: { data: { attributes: { account_id: string; listing_type: string; }; }; }; }; }; }) => {
        if (responseJson && !responseJson.errors) {
            const intialDealsId = responseJson.data.attributes.id;
            const sellerCheck =  responseJson.data.attributes.listing_detail.data.attributes.account_id;
            const listingTypes = responseJson.data.attributes.listing_detail.data.attributes.listing_type;
            this.setState({
                intialDealsId,
                sellerCheck,
                listingTypes,
                popupNegotiate: true,
                buttonDisabled: true,
            },() => {
                if(this.state.isFinalize){
                    this.creatfunction();
                }
            });
        } else if(responseJson.errors){
            responseJson.errors.forEach((error: { [s: string]: string; }) => {
                const key = Object.keys(error)[0];
                const errorMessage = Object.values(error)[0];
                if(key === "paypal_email"){
                    toast.error("Seller must have a paypal account to initiate a deal");
                    this.setState({ isDisabled: true });
                } else if(errorMessage === "has already been taken"){
                    this.setState({ isDisabled: true });
                    toast.error("Deal has been completed for this product.");
                } else {
                    toast.error(errorMessage);
                }
            });
        }
    }

    handleAcceptClick = () => {
        const updatedAmendmentDealsAttributes = [
            { amendment_type: "budget", status: "accept", reason: "" },
            { amendment_type: "commission", status: "accept", reason: "" },
            { amendment_type: "preferences", status: "accept", reason: "" }
        ];
        this.setState({ budgetStatus: 'accept', buttonClicked: true, negotiateButton: false, showTextArea: false, amendmentDealsAttributes: updatedAmendmentDealsAttributes, budgetError: '', negotiatedReason: this.state.data.budget });
    };
    handleNegotitiationPopupData(responseJson: any) {
        if (responseJson && !responseJson.errors) {
            if (responseJson.message) {
                this.NegotiationOnClick()
            }
        }
    }

    handleTextAreaChange(e: any, index: any) {
        const updatedCriteriaOrTerms = [...this.state.criteria_or_terms];

        updatedCriteriaOrTerms[index] && (updatedCriteriaOrTerms[index].reason = e.target.value);
        this.setState({ criteria_or_terms: updatedCriteriaOrTerms });
    }

    handleChangeData = (e: any) => {
        const { value } = e.target;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (value !== '' && !regex.test(value)) {
            return;
        }
        const newVal = parseFloat(value);
        if (value === '' || !isNaN(newVal)) {
            this.setState({ negotiatedReason: value, budgetError: "" });
        }
    }

    handleCommissionReason = (e:any) => {
        const { value } = e.target;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (value !== '' && !regex.test(value)) {
            return;
        }
        const numericValue = parseFloat(value);
        if (value === '' || !isNaN(numericValue)) {
            this.setState({ commissionReason: value });
        }
    };

    handlepreferenceReason = (e: any) => {
        this.setState({ preferenceReason: e.target.value })
    }
    handleNegotiateClick = () => {
        const updatedAmendmentDealsAttributes = [
            { amendment_type: "budget", status: "negotiate" }
        ];
        this.setState({ negotiateButton: true, budgetStatus: 'negotiate', buttonClicked: false, showTextArea: true, amendmentDealsAttributes: updatedAmendmentDealsAttributes, budgetError: '' });
    };
    handleDeclineClick = () => {
        this.setState({
            showTextArea: true,
        });
    };

    checkCondition = () => {
        return (this.state.buttonClicked || (this.state.negotiateButton && this.state.negotiatedReason)) &&
            (this.state.buttonClickedCommission || (this.state.negotiateButtonCommission && this.state.commissionReason));
    }

    isButtonActive = () => {
        if (this.state.buttonDisabled) {
            return true;
        } else {
            const termArr = this.state.criteria_or_terms;
            let termValidCount = 0;
            termArr.map((term: { status: string | undefined | null; reason: string | null | undefined; }) => {
                if (term.status && (term.status === "accept" || (term.status !== "accept" && term.reason))) {
                    termValidCount += 1;
                }
            });
            let isTrue = this.checkCondition();
            if (this.state.data.preferences) {
                isTrue = isTrue && (this.state.prefrenceButtonCommission || (this.state.prefrenceDenyButtonCommission && this.state.preferenceReason));
            }
            return isTrue && (termValidCount === this.state.criteria_or_terms.length);
        }
    }

    handleAcceptClickCommission = () => {
        this.setState({ buttonClickedCommission: true,  negotiateButtonCommission: false, showTextAreaCommission: false, commissionStatus: 'accept', commissionError: '', commissionReason: this.state.data.commission });
    };

    handleNegotiateClickCommission = () => {
        this.setState({ negotiateButtonCommission: true, buttonClickedCommission: false, showTextAreaCommission: true, commissionStatus: 'negotiate', commissionError: '', });
    };
    handlePrefrenceClickCommission = () => {
        this.setState({ prefrenceButtonCommission: true, prefrenceDenyButtonCommission: false, showTextAreaPrefrence: false, preferenceStatus: 'accept', preferenceError: '', preferenceReason: this.state.data.preferences });
    };

    handlePrefrenceDenyClickCommission = () => {
        this.setState({ prefrenceButtonCommission: false, prefrenceDenyButtonCommission: true, showTextAreaPrefrence: true, preferenceStatus: 'deny', preferenceError: '' });
    };
    handleDeclineClickCommission = () => {
        this.setState({
            showTextAreaCommission: true,
        });
    };
    handleButtonClick = (item: any, index: any, action: any) => {
        let activeButtons = [...this.state.activeButtons];
        let showTextAreas = [...this.state.showTextAreas];
        let criteriaErrors = [...this.state.criteriaErrors];

        item.status = action;
        item.criteria_id = item.id;

        if (action === 'accept') {
            activeButtons[index] = 'accept';
            showTextAreas[index] = false;
            criteriaErrors[index] = '';
        } else if (action === 'deny') {
            activeButtons[index] = 'deny';
            showTextAreas[index] = true;
            criteriaErrors[index] = '';
        } else if (action === 'amendment') {
            activeButtons[index] = 'amendment';
            showTextAreas[index] = true;
            criteriaErrors[index] = '';
        }

        this.setState({
            activeButtons: activeButtons,
            showTextAreas: showTextAreas,
            criteriaErrors: criteriaErrors
        });
    }

    handleSendToReviewClick() {
        const { criteria_or_terms } = this.state;
        const errors = [];
        let criteriaErrors = [...this.state.criteriaErrors];
        criteria_or_terms.forEach((obj: { status: string; }, index: number) => {
            if (obj.status !== 'accept' && obj.status !== 'deny' && obj.status !== 'amendment' ) {
                criteriaErrors[index] = 'Please approve or deny or request amendments for this criterion.';
            }  else {
                criteriaErrors[index] = '';
            }
        });

        const criteriaAmendmentsactiveButtons = this.state.activeButtons.length
        const criteriaAmendmentsAttributes = this.state.criteria_or_terms.map((criteria: any) => ({
            criteria_id: criteria.id,
        }));
        const isButtonEnableds = () => {
            if (criteriaAmendmentsAttributes.length === criteriaAmendmentsactiveButtons) {
                return true;
            } else {
                return false;
            }
        };
    
        if (!this.state.buttonClicked && !this.state.negotiateButton && !this.state.showTextArea) {
            errors.push('Please select a budget option.');
        }

        if (!this.state.buttonClickedCommission && !this.state.negotiateButtonCommission && !this.state.showTextAreaCommission) {
            errors.push('Please select a reward option.');
        }

        if (this.state.data.preferences && !this.state.prefrenceButtonCommission && !this.state.prefrenceDenyButtonCommission && !this.state.showTextAreaPrefrence) {
            errors.push('Please select a preference.');
        }
        const budgetError = this.state.buttonClicked || this.state.negotiateButton || this.state.showTextArea ? '' : 'Please select a budget option.';
        const commissionError = this.state.buttonClickedCommission || this.state.negotiateButtonCommission || this.state.showTextAreaCommission ? '' : 'Please select a reward option.';
        const preferenceError = this.state.prefrenceButtonCommission || this.state.prefrenceDenyButtonCommission || this.state.showTextAreaPrefrence ? '' : 'Please select a preference.';
        if (!errors.length  && isButtonEnableds()) {
            this.createApiForNegotiate();
        } else {
            this.setState({
                budgetError,
                commissionError,
                preferenceError,
                criteriaErrors
            });
        }
    }

    creatfunction = () => {
        setTimeout(() => {

            const userNameFromLocalStorages = localStorage.getItem("id");
            const datasFile = userNameFromLocalStorages == this.state.sellerCheck;
            const listingTypes = this.state.listingTypes;

            const navigateTo = listingTypes === "looking_for_something"
                ? (datasFile ? "PaymentBuyer" : "PaymentSeller")
                : (datasFile ? "PaymentSeller" : "PaymentBuyer");
            localStorage.setItem(`isCongrats-${this.state.intialDealsId}`, "true") ;
            this.props.navigation.navigate(navigateTo, { id: this.state.intialDealsId });
        }, 3000);
    }

    NegotiationOnClick = () => {
        const contentsscontentss = this.props.navigation.getParam('id');
        this.props.navigation.navigate("ViewListing", { id: contentsscontentss })
    }
    NegotiationOnClicks = () => {
        this.setState({ showPopupsLogoutNegotiation: false })
    }
    NegotiationOnClicksPopupss = () => {
        this.props.navigation.navigate("UserProfileBasicBlock", "?tab=negotiation");
    }
    openPopup = () => {
        this.setState({ showPopupsLogoutNegotiation: true })
    }
    NegotiationOnClickCancle = () => {
        this.setState({ showPopupsLogoutNegotiation: false })
    }
    NegotiationSellerToBuyerAll = async (id: any) => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.NegotiationSellerToBuyerApiData = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/local_listing_ads/${id}?user_id=${localStorage.getItem("id")}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        this.setState({ LoaderFormApprovalBuyer: true })

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
    FromApprovalDataCancelButtonsDelete = async () => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FromApprovalDataCanceldata = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/initiate_deals/${this.state.intialDealsId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                token: token
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        setTimeout(() => {
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }, 2000);

        return true;
    };


    createApiForNegotiate = async () => {
        const token = localStorage.getItem("authToken") as string;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.NegotiationSellerToBuyerApiDataPost = requestMessage.messageId
        const contentsscontentss = this.props.navigation.getParam('id');
        const amendmentDealsAttributes = [
            { amendment_type: "budget", status: this.state.budgetStatus, reason: this.state.negotiatedReason },
            { amendment_type: "commission", status: this.state.commissionStatus, reason: this.state.commissionReason },
            { amendment_type: "preferences", status: this.state.preferenceStatus, reason: this.state.preferenceReason }
        ];
        const filteredPayloads = amendmentDealsAttributes.filter(item => item.status !== "" && item.reason !== "");
        const criteriaAmendmentsAttributes = this.state.criteria_or_terms.map((criteria: any) => ({
            criteria_id: criteria.id,
            status: criteria.status,
            reason: criteria.reason,
        }));
        const criteriaAmendmentsactiveButtonsLength = this.state.activeButtons.length
          const criteriaAmendmentsAttributess = this.state.criteria_or_terms.map((criteria: any) => ({
              criteria_id: criteria.id,
          }));
          const isButtonEnableds = () => {
            if (criteriaAmendmentsAttributess.length === criteriaAmendmentsactiveButtonsLength) {
              return this.state.activeButtons.every((item) => item === "accept");
            } else {
              return false;
            }
          };

        const statusAccept = (
            this.state.budgetStatus === "accept" &&
            this.state.commissionStatus === "accept" &&
            this.state.preferenceStatus === "accept" && 
            isButtonEnableds()
        )
            ? "finalise_deal" : "under_review";

        let Body = {
            deal: {
                status: statusAccept,
                local_listing_ad_id: contentsscontentss,
                criteria_amendments_attributes: criteriaAmendmentsAttributes,
                amendment_deals_attributes: filteredPayloads
            },
            listing_type: this.state.data.listing_type === "looking_for_something" ? 'looking' : 'selling',
        };
        if (this.state.data.listing_type === "looking_for_something") {
            //@ts-ignore
            Body.deal.buyer_id = this.state.accountID;
        }
        else {
            //@ts-ignore
            Body.deal.seller_id = this.state.accountID;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_locallistingads/initiate_deals`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": "application/json",
                token: token
            })
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(
                Body
            )
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    // Customizable Area End
}
