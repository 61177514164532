// Customizable Area Start
import React from "react";
import { Box, Button, styled } from "@material-ui/core";
import LocalListingAdsController from "./LocalListingAdsController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { createListingIcon } from "./assets";

const MainContainer = styled("div")({
    maxWidth: '1440px',
    margin: 'auto',
    "@media(max-width:1260px)": {
        paddingBottom: '80px'
    },
});
const SelectListingType = styled("p")({
    fontFamily: "Segoe UI Bold",
    fontSize: "26px",
    lineHeight: "24px",
    color: "#3CAA49",
    textAlign: 'center',
    "@media(max-width:767px)": {
        fontSize: "22px",
    },
    "@media(max-width:450px)": {
        fontSize: "20px",
    },
});
const ListingType = styled('div')({
    display: 'flex',
    width: '90%',
    margin: 'auto',
    maxWidth: '1122px',
    gap: 42,
    justifyContent: 'center',
    "@media(max-width:767px)": {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "@media(max-width:450px)": {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
});
const LookingForSomething = styled('div')({
    gap: 42,
    borderColor: '#CBD5E1',
    width: '100%',
    maxWidth: '540px',
    border: '0.5px solid #CBD5E1',
    padding: '79.5px 43px 79.5px 43px',
    "@media(max-width:767px)": {
        padding: '10px 15px',
    },
    "@media(max-width:450px)": {
        width: 'unset',
    }
});
const SellingForSomething = styled('div')({
    gap: 42,
    width: '100%',
    maxWidth: '540px',
    border: '0.5px solid #CBD5E1',
    padding: '79.5px 43px 79.5px 43px',
    "@media(max-width:767px)": {
        padding: '10px 15px',
    },
    "@media(max-width:450px)": {
        width: 'unset',
    }
});
const WishlistText = styled('div')({
    marginTop: "20px",
    fontSize: "16px",
    "@media(max-width:960px)": {
        fontSize: "14px"
    },
    "@media(max-width:450px)": {
        fontSize: "12px"
    },
});
const LookingText = styled("p")({
    width: '100%',
    fontFamily: "Segoe UI Bold",
    fontSize: "28px",
    lineHeight: "54px",
    margin: 0,
    color: "#676767",
    "@media(max-width:960px)": {
        fontSize: "20px",
    },
    "@media(max-width:767px)": {
        fontSize: "16px",
    },
    "@media(max-width:450px)": {
        fontSize: "14px",
    }
});
const ExperienceText = styled("p")({
    fontFamily: "Segoe UI",
    fontSize: "18px",
    lineHeight: "24px",
    marginTop: '10px',
    margin: 0,
    color: "#676767",
    textAlign: 'start',
    "@media(max-width:969px)": {
        fontSize: "16px",
    },
    "@media(max-width:767px)": {
        fontSize: "14px",
    },
    "@media(max-width:450px)": {
        fontSize: "12px",
    }
});
const LogoImg = styled('img')({
    width: '8px',
    paddingLeft: '10px'
});
const CreateListingButton = styled(Button)({
    borderRadius: "4px",
    width: "100%",
    maxWidth: '279px',
    fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    padding: '10px 44px 10px 44px',
    marginTop: "20px",
    backgroundColor: '#3CAA49',
    fontSize: "18px",
    cursor: "pointer",
    gap: 10,
    alignItems: 'center',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#3CAA49',
    },
    "@media (max-width: 767px)": {
        padding: "10px 20px",
        fontSize: "14px",
        width: "unset",
    },
    "@media (max-width: 450px)": {
        padding: "10px 10px",
        fontSize: "12px",
        width: "unset",
    },
});
const CreateListingButtons = styled(Button)({
    borderRadius: "4px",
    width: "100%",
    maxWidth: '279px',
    fontFamily: "Segoe UI Bold",
    color: "#FFFFFF",
    padding: '10px 44px 10px 44px',
    marginTop: "20px",
    backgroundColor: '#3CAA49',
    fontSize: "18px",
    cursor: "pointer",
    gap: 10,
    alignItems: 'center',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#3CAA49',
    },
    "@media (max-width: 767px)": {
        padding: "10px 20px",
        fontSize: "14px",
        width: "unset",
    },
    "@media (max-width: 450px)": {
        padding: "10px 10px",
        fontSize: "12px",
        width: "unset",
    },
});


export default class CreateListing extends LocalListingAdsController {
    renderDatas() {
        return (
            <WishlistText>
                <li>Create a product List.</li>
                <li>Get in contact with buyer.</li>
                <li> Provide hassle-free service. Get Reviews.</li>
            </WishlistText>
        )
    }
    renderDatasListing() {
        return (
            <CreateListingButtons onClick={this.LookingNavigation}>
                Create Listing
                <LogoImg src={createListingIcon} />
            </CreateListingButtons>
        )
    }
    renderDatasListingList() {
        return (
            <CreateListingButtons onClick={this.SellingNavigation}>
                Create Listing
                <LogoImg src={createListingIcon} />
            </CreateListingButtons>
        )
    }
    
    renderDatasListingWishlist() {
        return (
            <WishlistText>
                <li>Create a Wishlist. </li>
                <li>Connect with Provisioner for purchase. </li>
                <li>Get your product. Enjoy!</li>
            </WishlistText>
        )
    }

    render() {


        return (
            <Box width="100%">
                <AppHeader navigation={this.props.navigation.navigate} />
                <MainContainer>
                    <SelectListingType>
                        Select Listing type to create one.
                    </SelectListingType>
                    <ListingType>
                        <LookingForSomething>
                            <LookingText>Looking for Something?</LookingText>
                            <ExperienceText>Experience the ultimate convenience of finding all your favourites in one place.
                               {this.renderDatasListingWishlist()}
                            </ExperienceText>
                            <div style={{}}>
                                {this.renderDatasListing()}
                            </div>
                        </LookingForSomething>
                        <SellingForSomething>
                            <LookingText>Selling Something?</LookingText>
                            <ExperienceText>Facilitate a seamless journey for individuals to achieve their dream Wishlist effortlessly.
                                {this.renderDatas()}
                            </ExperienceText>
                            <div >
                                {this.renderDatasListingList()}
                            </div>
                        </SellingForSomething>
                    </ListingType>
                </MainContainer>
            </Box>
        );
    }
}

// Customizable Area End
