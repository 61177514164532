// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Dialog,
  Paper,
  FormControl,
  FormLabel,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Popup from 'reactjs-popup';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";



import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import {
  ammend,
  deal_finalised,
  image_Green,
  payment_comp,
  payment_link,
  refresh,
  Thanks,
} from "./assets";
import PaymentSellerController from "./PaymentSellerController.web";
import { Rating } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

export default class PaymentSeller extends PaymentSellerController {

Payment(){
  return(
    <Typography style={webStyle.discrtiptionText}>
  Please note, this link will not be available to the buyer until they have completed the payment. You will receive a notification once they have completed
  the payment.
</Typography>
  )
}

  renderUpperSection = () => {
    const { userDetailss, datas } = this.state;

    return (
      <>
        <Box style={{ padding: "10px 60px",}}>
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={12} lg={4} md={6} sm={12} xl={4} >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    objectFit:'cover',
                    display: "flex",
                    alignItems: "right",
                  }}
                >
                  <img src={this.state.datas.photo?.[0]?.url} alt="" height={350} width="100%" style={webStyle.imgContain as React.CSSProperties} />
                </Grid>
                {datas.photo && datas.photo.slice(1).map((item: any, index: any) => (
                    <Grid item key={index} xs={6} lg={4} sm={6} md={6} xl={4} >
                      {" "}
                      <img
                        height={100}
                        src={item.url}
                        alt=""
                        width="100%"
                        style={{
                          marginRight: "1px",
                          marginBottom: "10px",
                          objectFit: 'contain'
                        }}/></Grid> ))}
              </Grid>{" "}
            </Grid>
            <Grid
              item
              xs={12}
              lg={7}
              md={6}
              sm={12}
              xl={8}
             
            >
              <div style={webStyle.label}>
                <Typography style={webStyle.title as React.CSSProperties}>
                  {this.state.datas.title}
                </Typography>
                <Typography style={webStyle.listingtype as React.CSSProperties}>
  @{userDetailss ? userDetailss.user_name : ''}
</Typography>
                <hr style={webStyle.hr as React.CSSProperties} />
                <Typography style={webStyle.catagory as React.CSSProperties}>
                  <Typography
                    style={webStyle.catagoryData as React.CSSProperties}
                  >
                    <Typography style={{ fontStyle: "italic" }}>
                      Category
                    </Typography>
                    <Typography
                      style={webStyle.catagoryText as React.CSSProperties}
                    >
                      {this.state.datas.category}

                    </Typography>
                  </Typography>
                  <Typography
                    style={webStyle.catagoryDataPayment as React.CSSProperties}
                  >
                    <Typography 
                    style={{ fontStyle: "italic" }}
                    >
                      Sub-Category
                    </Typography>
                    <Typography
                       style={webStyle.catagoryText as React.CSSProperties}
                    >
                      {this.state.datas.sub_category}
                    </Typography>
                  </Typography>
                  <Typography
                    style={webStyle.catagoryData as React.CSSProperties}
                  >
                    <Typography style={{ fontStyle: "italic" }}>
                      Location
                    </Typography>
                    <Typography
                      style={webStyle.catagoryText as React.CSSProperties}
                    >
                      {this.state.datas.location}
                    </Typography>
                  </Typography>
                </Typography>
                <hr style={webStyle.hr as React.CSSProperties} />
                <Typography
                  style={webStyle.discrtiptionData as React.CSSProperties}>
                    Description
                </Typography>
                <Typography
                  style={webStyle.discrtiptionText1 as React.CSSProperties}
                >
                  {this.state.datas.description}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <hr style={{backgroundColor: "#E5E7EB",
    border: "none",
    height: "1px",
    marginTop:'10px'}} />
        </Box>
      </>
    );
  };

  SendLinkSection = () => {
    const { userDetailss, linkId, datas } = this.state;

   
    return (
        <>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: 70,
                }}
            >
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        md={6}
                        sm={12}
                        xl={10}
                    >
                        <div style={webStyle.label}>
                            <Typography style={webStyle.title}>
                                {datas.title}
                            </Typography>
                            <Typography style={webStyle.listingtype as React.CSSProperties}>
                                @{userDetailss ? userDetailss.user_name : ''}
                            </Typography>
                            <hr style={webStyle.hr} />
                            <Typography style={webStyle.catagoryText}>
                                <Typography style={{ fontSize: "24px", fontFamily: "Segoe UI Bold", padding: '20px 0px' }}>
                                    Add product link
                                </Typography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        placeholder="Enter product link"
                                        variant="outlined"
                                        value={this.state.linktext}
                                        style={{ marginRight: '10px', width: '100%' }}
                                        data-test-id="link"
                                        onChange={(e) => this.onProductLinkChange(e.target.value)}
                                    />
                                    <CustomButton
                                        variant="contained"
                                        type="button"
                                        disabled={!!this.state.errorMsg}
                                        style={StylesNegotiation.createButtons1}
                                        data-test-id="LinkSend"
                                        onClick={() => this.SendLink(linkId, this.state.linktext)} 
                                    >
                                        Send Link
                                    </CustomButton>
                                </div>
                                {this.state.errorMsg && <Box fontSize="16px" color="red">{this.state.errorMsg}</Box>}
                            </Typography>
                           
                            {this.Payment()}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

paymentCompleted = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        margin: 30,
        flexDirection: "column",
        alignItems: "center",
      }}>
      <img src={payment_comp}
       alt="Payment Completed" 
       />
       <div
        style={{
          marginTop: "10px",
          fontWeight: 400,
          fontFamily: "Segoe UI Bold",
          fontSize: "32px",
          lineHeight:'24px',
          color: "#3CAA49",
        }}
      >
        {" "}
        Payment Received Successfully
        </div>
        <div
        style={{
          marginTop: "10px",
          fontFamily: "Segoe UI",
          paddingBottom: "10px",
          fontWeight: 400,
          fontSize: "20px",
          color: "#676767",
        }}
        data-test-id="SendLink1"
        onClick={this.toggleReviewPopup}
      >
        {" "}
Share your <span style={{cursor:'pointer', color: "#3CAA49", textDecoration:'underline', fontWeight: "bold" }}  > Feedback {" "}
        </span>
        on deal
        <br />
      </div>
    </Box>
  );
};


renderCriteriaOrTermsSection() {
  const {
    criteria_amendmentss,
    criteria_or_termss,
    showAmendmentsApproval
  } = this.state;

  return (
    <>
      <Box style={{ padding: "0px 60px" }}>
        <Typography style={StylesNegotiation.secondHeading}>
        {this.state.datas.listing_type === "looking_for_something" ? "Approval Criteria" : "Terms of Purchase"}
        </Typography>
        <Typography style={StylesNegotiation.secondSecionText}>
          Following are the actions by buyer for this list
        </Typography>
        <Grid container spacing={2}>
          {criteria_or_termss?.map((item: { id: string | number; title: string | null; }, index: number) => {
            const isVisible = criteria_amendmentss.some((amend: { criteria_id: string | number; status: string; }) => amend.criteria_id === item.id && amend.status !== "accept");
            return (
            <div key={item.id} style={{ width: "100%" }}>
              <div
                style={{
                  padding: "20px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: "6px",
                }}
              >
                <span style={{ fontSize: "24px", fontFamily: "Segoe UI", color: "#676767" }}>
                  {index + 1}.
                </span>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontFamily: "Segoe UI",
                    color: "#676767",
                    width: "100%",
                    maxWidth: "90%",
                    textAlign: "justify",
                    marginLeft: "-50px",
                  }}
                >
                  {item.title}
                </Typography>
                <img
                  style={{ width: "32px", height: "32px" }}
                  src={image_Green}
                  alt="Accepted"
                />
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={StylesNegotiation.budget}>
                  {isVisible && 
                  <Typography
                    data-test-id="see2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#3CAA49",
                      fontFamily: "Segoe UI Bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.toggleAmendmentsApproval(item.id);
                    }}
                  >
                    See amendments
                    <img
                      src={ammend}
                      style={{
                        marginTop: '5px',
                        transform: showAmendmentsApproval[item.id] ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                      alt="Toggle"
                    />
                  </Typography>}
                  {showAmendmentsApproval[item.id] &&
                    
                    criteria_amendmentss
                      .filter((ammend:any) => ammend.criteria_id === item.id && ammend.status !== "accept")
                      .map((ammend:any, index:number) => ( 
                          <Box
                            key={ammend.id}
                            style={{
                              maxHeight: "100px",
                              margin: "10px",
                              borderRadius: "3px",
                              background: index % 2 === 0 ? '#ECF7ED' : '#E2E8F0',
                              padding: "10px 20px",
                              width: "70%",
                            }}
                          >
                          
                          <Typography>
                            {ammend.reason}
                            </Typography>
                      </Box>
                     ))}
                 </div>
            </div>
            <hr style={StylesNegotiation.hr as React.CSSProperties} />
            </div>
            )}
          )}
       </Grid>
     </Box>
     </>
   );
 }


  renderDealFinalised = () => {
    return (
      <Box
        style={{
          display: "flex",
          margin: 30,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",}}
      >
        <img src={deal_finalised} 
        alt="Payment Completed" 
        />
        <div
          style={{
            marginTop: "10px",
            fontFamily: "Segoe UI Bold",
            fontSize: "30px",
            color: "#3CAA49",
          }}
        >
          Congratulations.The deal has been finalised!{" "}
        </div>
        <div
          style={{
            marginTop: "10px",
            fontFamily: "Segoe UI",
            fontWeight: 400,
            fontSize: "20px",
            paddingBottom: "10px",
            color: "#676767",
          }}
        >
          {" "}
          Please submit the product link. You will be redirected to submission
          page in{" "}
          <span style={{ color: "#3CAA49" }}>
            {this.state.countdown} sec...
          </span>
          <br />
        </div>
      </Box>
    );
  };
  
renderCommissionSection() {
  const { amendment_dealss } = this.state;
  const commissionDeal = amendment_dealss.filter(
    (deal:any) => deal.amendment_type === "commission" && deal.status !== "accept"
  );

  const lastCommissionDeal = commissionDeal[commissionDeal.length - 1];
  const amountValue = lastCommissionDeal ? lastCommissionDeal.reason : this.state.datas.commission;
  const budgetDeal = amendment_dealss.filter((deal: { amendment_type: string; status: string; }) => deal.amendment_type === "budget" && deal.status !== "accept");

  return (
    <>
      <Grid container alignItems="center" style={{ padding: "0px 60px" }}>
        <div
          style={{
            marginTop: "10px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography style={StylesNegotiation.budget}>
            {this.state.datas.listing_type === "looking_for_something" ? "Budget" : "Price"}
            <span style={StylesNegotiation.price}> £ {this.state.datas.budget}</span>
          </Typography>
          <img
            style={{ width: "32px", height: "32px" }}
            src={image_Green}
            alt="Accepted"
          />
        </div>
        <Box display='flex' flexDirection='column' width="100%">
          <div style={StylesNegotiation.budget}>
            {budgetDeal.length > 0 &&
             <Typography
              onClick={this.onPriceAmendmentClick}
              style={StylesNegotiation.amendments}
              data-test-id="amendments"
            >
              See amendments
              <img
                src={ammend}
                style={{
                  marginTop: '5px',
                  transform: this.state.priceAmendments ? "rotate(180deg)" : "rotate(0deg)",
                }}
                alt="Payment Completed"
              />
            </Typography>}
            {this.state.priceAmendments && budgetDeal.map((deal: { id: string | number; reason: string; }, index:number) => {
              const color = index % 2 === 0 ? "#ECF7ED" : "#E2E8F0";
              return(
                <Box
                  key={deal.id}
                  padding='10px 0px'
                  height='20px'
                  borderRadius='3px'
                  margin='10px'
                  width='20%'
                  style={{ background: color }}
                >
                  <Typography>{deal.reason}</Typography>
                </Box>
              )}
            )}
          </div>
        </Box>
        <div
          style={{
            marginTop: "10px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={StylesNegotiation.budget}>
            Reward{" "}
            <span style={StylesNegotiation.price}>
              £ {this.state.datas.commission}
            </span>
          </Typography>
          <Box>
            <img
              style={{ width: "32px", height: "32px" }}
              src={image_Green}
              alt="Accepted"
            />
          </Box>
        </div>
        <Box display='flex' flexDirection='column' width="100%">
          <div style={StylesNegotiation.budget}>
            {commissionDeal.length > 0 &&
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                fontFamily: "Segoe UI Bold",
                color: "#3CAA49",
              }}
              onClick={() => {
                this.toggleAmendments();
                this.toggleRotation(); // Toggle rotation when clicking "See amendments"
              }}
              data-test-id="see"
            >
              See amendments
              <img
                src={ammend}
                style={{
                  transform: this.state.isRotated ? "rotate(180deg)" : "rotate(0deg)",
                  marginTop: '5px',
                }}
                alt="Payment Completed"
              />
            </Typography>}
            {this.state.showAmendments && commissionDeal.map((deal:any, index:number) => (
              <Box
                key={deal.id}
                style={{
                  height: '20px',
                  padding: '10px 0px',
                  borderRadius: '3px',
                  width: '20%',
                  margin: '10px',
                  background: index % 2 === 0 ? '#ECF7ED' : '#E2E8F0',
                }}
              >
                <Typography>{deal.reason}</Typography>
              </Box>
            ))}
          </div>
          <div style={StylesNegotiation.budget}>
            Receivable Reward <span style={StylesNegotiation.price}>£ {amountValue}</span>
          </div>
        </Box>
        <hr style={StylesNegotiation.hr as React.CSSProperties} />
      </Grid>
    </>
  );
}

  handleRefreshClick = () => {
    window.location.reload();
}

  renderPreferenceSection() {

    return (
      <>
        <Box style={StylesNegotiation.commonPadding}>
          <Typography style={StylesNegotiation.secondHeading}>
            Preference
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
<Typography style={{fontSize: "24px",
    fontFamily: "Segoe UI",
    color: "#676767",
    width:'100%',
    paddingRight:'20px',
    maxWidth: "93%",
    maxHeight:'350px',
    overflowY:'auto',
    textAlign:"justify",
    margin: 0,}}>              {this.state.datas.preferences}
            </Typography>
            <img
              style={{ width: "32px", height: "32px" }}
              src={image_Green}
              alt="Accepted"
            />
          </div>
        </Box>
      </>
    );
  }

  renderButtons() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "40px 0 40px 0",
        }}
      >
        <Button
          test-id="popupApproval"
          variant="contained"
          style={StylesNegotiation.createButtons}
          data-test-id="SendLink"
          onClick={this.toggleSendLinkSection}
        >
          <span style={{ textTransform: "none" }}>Send Link</span>
        </Button>
        <CloseButton
          data-test-id="open-popup"
          variant="contained"
          onClick={this.modalOpen}
          >
          Close Negotiation
        </CloseButton>
      </div>
    );
  }

  refreshPage = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 30,
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: '20px'
        }}>
        <img src={payment_link}
         alt="Payment Completed" 
         />
        <div
          style={{
            margin: "10px",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
            fontSize: "25px",
            color: "#676767",
          }}
        >You will receive payment very shortly. We appreciate your patience.
          <br />
          
        </div>
        <Box mt="10px" display="flex" alignItems="center">
          <div>
        <img src={refresh}
        style={StylesNegotiation.cursor}
        onClick={this.handleRefreshClick}
         alt="Payment Completed" 
         />
         </div>
         <CloseButtonLink
          data-test-id="open-popup"
          onClick={this.modalOpen}
          >
          Close Negotiation
        </CloseButtonLink>
        </Box>
      </Box>
    );
  };
  handleReviewDescriptionChange = (event:any) => {
    this.setState({ reviewDescription: event.target.value });
  };

  handleRatingChange = (value: any) => {
    this.setState({ rating: value });
  };
 
  renderDialog = () => {
    return (
      <>
        <Box style={webStyle.deleteDialog as React.CSSProperties}>
          <Popup
            data-test-id="delete-modal"
            open={this.state.isModalOpen}
            closeOnDocumentClick
            contentStyle={webStyle.content}
            onClose={this.modalClose}
            overlayStyle={webStyle.overlay}
          >
            <DeleteDialog>
              <p className="heading">Close Negotiation</p>
              <div>
                <p className="title">Are you sure you want to close this<br/> negotiation?</p>
              </div>
              <Box>
                <Button
                  data-test-id="close-btn"
                  onClick={this.onCloseNegotiation}
                  className="close-btn"
                >
                  Close
                </Button>
                <Button
                  className="cancel-btn"
                  onClick={this.modalClose}
                  data-test-id="cancel-btn"
                >
                  Cancel
                </Button>
              </Box>
            </DeleteDialog>
          </Popup>
        </Box>
      </>
    );
  }

  reviewPopup = () => {
    const {  isReviewSubmitted } = this.state;
   
  
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
        {!isReviewSubmitted && (
          <Dialog
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow:
                " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
            }}
            onClose={this.toggleReviewPopup}
            data-test-id="editmodal"
            open={this.state.isReviewPopupOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            
            <Paper elevation={4}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", padding: "50px 50px" }}>
                <Typography id="headerText" style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Segoe UI bold" }}>Share your feedback on this deal</Typography>

                <FormLabel style={{ fontSize: "14px", fontWeight: 200, fontFamily: "Segoe UI" }}> Rate your experience</FormLabel>
                <Rating   value={this.state.rating}
                data-test-id="Rating"
                onChange={(event, newValue) =>{
                  this.handleRatingChange(newValue)
                }}   />
                            

                <FormControl style={{ width: "100%", marginBottom: "8px" }}>
                  <FormLabel style={{ fontSize: "14px", fontWeight: 200, fontFamily: "Segoe UI", marginBottom: "8px" }}>What was your experience like?</FormLabel>
                  <TextField
                    type="text"
                    variant="outlined"
                    minRows={4}
                    multiline={true}
                    value={this.state.reviewDescription}
                    // value={this.state.emailValue}
                    style={{ backgroundColor: "#ffffff" }}
                    onChange={this.handleReviewDescriptionChange} // Add onChange handler
                    name="ein"
                    data-test-id="feedback"
                    color="primary"
                    // onChange={this.handleEmailChange}
                    InputProps={{
                      placeholder: "Tell us your experience on this website and with this user",
                      style: { borderRadius: "8px", 
                      fontFamily: "Segoe UI" }}} fullWidth/>
                </FormControl>
                <div style={{gap:'10px'}}>
                  <Button
                   variant="contained"
                    test-id="popupApproval"
                    disabled={this.state.reviewButton}
                    style={{
                      borderRadius: "4px",
                      height: "50px",
                      fontSize: "14px",
                      border: "2px solid #3CAA49",
                      background: "#3CAA49",
                      fontFamily: "Segoe UI Bold",
                      color: "#FFFFFF",
                      padding: '10px 20px',
                    }}
                    onClick={this.reviewsAdd}
                    data-test-id="SendLink2"
                  >
                    <span style={{ textTransform: "none" }}>
                      Submit Review
                      </span></Button>
                  <Button
                    test-id="popupApproval"
                    variant="contained"
                    style={{
                      borderRadius: "4px",
                      border: "2px solid #3CAA49",
                      background: "#FFFFFF",
                      fontSize: "15px",
                      color: "#3CAA49",
                      height: "45px",
                      fontFamily: "Segoe UI Bold",
                      padding: '10px 20px',
                      marginLeft: '15px', }}
                    data-test-id="SendLink3"
                    onClick={this.toggleReviewPopup}>
                    <span style={{ textTransform: "none" }}>
                      Cancel</span>
                  </Button>
                </div>
              </div>
            </Paper></Dialog>
        )}
        {isReviewSubmitted 
        && 
        (
          <Dialog
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow:
                " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
            }}
            open={this.state.isReviewSubmittedPopupOpen}
            onClose={this.toggleReviewSubmittedPopup}
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
          >
            <IconButton
      style={{ position: 'absolute', top: '10px', right: '10px',  }}
      onClick={this.toggleReviewSubmittedPopup}
    >
      <CloseIcon />
    </IconButton>
            <Paper elevation={4}
            >
              <div style={{ 
                display: "flex", 
              flexDirection: "column", 
              alignItems: "center", gap: "16px", padding: "40px 40px" }}>
                <img src={Thanks} width={300} height={300}/>
                <Typography id="headerText" style={{ fontWeight: 700, fontSize: "24px", fontFamily: "Segoe UI bold" }}>Thank you for your valuable feedback</Typography>
               
              </div>
            </Paper>
          </Dialog>
        )}
      </div>
    );
  };

  render() {
    const theme = createTheme();
    const { redirect } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation.navigate} />
         {!redirect && this.state.isLoading &&
          <Box
            height='100vh'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <CircularProgress style={{ color: '#4fa94d' }} />
          </Box>}
        {redirect ? (
          this.renderDealFinalised()
        ) : (
          <>
          {!this.state.isLoading && !redirect &&
          <Box padding="12px 48px">
           {this.state.upper && !this.state.showLink && this.renderUpperSection()}
            {this.state.upper && !this.state.showLink && this.renderCommissionSection()}
            {this.state.upper && !this.state.showLink && this.renderCriteriaOrTermsSection()}
            {this.state.upper && !this.state.showLink && this.state.datas.preferences && this.renderPreferenceSection()}
            {this.state.upper && !this.state.showLink && this.renderButtons()}
            {this.state.showSendLinkSection && this.SendLinkSection()} 
            {!this.state.upper && this.state.linkSentSuccess && !this.state.showLink && this.refreshPage()}
            {this.state.showLink && this.paymentCompleted()}
            {this.renderDialog()}
          </Box>}
          </>
        )}

        {this.reviewPopup()}

        <AppFooter naviagtion={this.props.navigation.navigate} />
      </ThemeProvider>
    );
  }
}

const CloseButtonLink = styled(Box)({
  fontStyle:"italic",
  fontWeight:"bold",
  color: '#54b363',
  cursor: "pointer",
  fontSize: "24px",
  marginLeft: "20px",
  borderRedius: '5px',
  textTransform: 'none',
  fontFamily: "Segoe UI",
  backgroundColor: "#FFF !important",
});

const CloseButton = styled(Button)({
  fontSize: "18px",
  color: '#3CAA49',
  height: "55px",
  marginLeft: "20px",
  padding:"15px 25px",
  borderRedius: '5px',
  textTransform: 'none',
  textTransfer: "none",
  border: '2px solid #3CAA49',
  fontFamily: "Segoe UI Bold",
  backgroundColor: "#FFF !important",
  '&.MuiButton-root.Mui-disabled': {
    border: '1px solid #BED9C1 !important',
    color: "rgba(0, 0, 0, 0.26)",
  }
});

const DeleteDialog = styled(Box)({
  "& .heading": {
    margin: "0px",
    lineHeight: '28px',
    color: '#0F172A',
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
  },
  "& .title": {
    margin: "0px",
    LineHeight: '24px',
    color: '#676767',
    marginTop: "20px",
    marginBottom: "35px",
    fontSize: '20px',
    fontFamily: "Segoe UI",
  },
  "& .close-btn": {
    border: "none",
    height: "56px",
    width: "115",
    color: "#ECF7ED",
    fontSize: "20px",
    lineHeight:"24px",
    marginRight: "15px",
    borderRadius: "4px",
    background: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    textTransform: "capitalize",
  },
  "& .cancel-btn": {
    height: "56px",
    color: "#3CAA49",
    width: "122px",
    lineHeight:"24px",
    fontSize: "18px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    textTransform: "capitalize",
    fontFamily: "Segoe UI Bold",
    border: "1px solid #3CAA49",
  },
});

const CustomButton = styled(Button)({
  backgroundColor: "#3CAA49",
  "&.MuiButton-contained:hover": {
    backgroundColor: "#3CAA49",
  }
});

const webStyle = {
  imgContain: {
    objectFit: 'contain'
  },
  deleteDialog: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    padding: '10px 0px',
  },
  content: {
    padding: "70px",
    paddingRight:"140px",
    maxWidth: '526px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  title: {
    fontSize: "38px",
    color: "#676767",
    fontFamily: "Segoe UI Bold",
  },
  label: {
    paddingLeft: "20px",
  },
  listingtype: {
    color: "#676767",
    fontSize: "18px",
    fontFamily: "Segoe UI",
    paddingBottom: "10px",
    display: "flex",
    textAlign: "center",
    marginTop: "10px",
  },
  budget: {
    padding: "15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  budgetText: {
    color: "#3CAA49",
    fontSize: "26px",
    fontFamily: "Segoe UI Bold",
    padding: "0 20px 0 10px",
  },
  budgetheading: {
    color: "#676767CC",
    fontSize: "20px",
    fontFamily: "Segoe UI",
    paddingRight: "10px",
  },
  catagory: {
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  createButtons: {
    backgroundColor: "#3CAA49",
    color: "#FFFFFF",
    width: "180px",
    height: "56px",
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    borderRedius: "5px",
  },
  createButtons11: {
    backgroundColor: "rgba(60, 172, 73, 0.3)",
    color: "#FFFFFF",
    width: "180px",
    height: "56px",
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    borderRedius: "5px",
  },
  createButtons2: {
    color: "#3CAA49",
    width: "199px",
    background: "#FFFFFF",
    height: "56px",
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    border: "1px solid #3CAA49",
    borderRedius: "5px",
  },
  catagoryData: {
    color: "#67676799",
    paddingRight: "50px",
    fontSize: "22px",
    fontFamily: "Segoe UI",
  },
  catagoryDataPayment: {
    color: "#67676799",
    paddingRight: "50px",
    fontSize: "22px",
    fontFamily: "Segoe UI",
  },
  catagoryText: {
    color: "#676767",
    fontSize: "24px",
    fontFamily: "Segoe UI",
    paddingBottom: "10px",
  },
  discrtiption: {
    color: "#67676799",
    fontSize: "22px",
    fontFamily: "Segoe UI",
    fontStyle: "italic",
  },
  discrtiptionData: {
    color: "#67676799",
    fontSize: "22px",
    fontFamily: "Segoe UI",
    fontStyle: "italic",
  },
  discrtiptionText1: {
    fontFamily: "Segoe UI",
    color: "#676767",
    fontSize: "24px",
    maxHeight: "350px", 
    overflowY: "auto", 
    textAlign: "justify",
    padding:'15px 40px 20px 0' // Align text justified

  },
  discrtiptionText: {
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    width:'70%',

  },
  Approval: {
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
    fontStyle: "italic",
  },
  ApprovalText: {
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
  },
  Preferences: {
    color: "#67676799",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
    fontStyle: "italic",
  },
  PreferencesText: {
    color: "#676767",
    fontSize: "16px",
    fontFamily: "Segoe UI",
    marginTop: "20px",
    marginBottom: "100px",
  },
  hr: {
    border: "none",
    height: "1px",
    backgroundColor: "#E5E7EB",
  },
  hr1: {
    border: "none",
    height: "1px",
    backgroundColor: "#E5E7EB",
    margin: "30px 0",
  },
  photo: {
    // display: "flex",
    // marginTop: "10px",
    // flexWrap: 'wrap',
    // justifyContent:"space-between"
  },
  userPhoto: {
    height: "52px",
    width: "52px%",
    borderRadius: "50%",
  },
  fullName: {
    color: "#3CAA49",
    fontSize: "20px",
    fontFamily: "Segoe UI Bold",
    padding: "0 10px",
  },
  userName: {
    color: "#676767",
    fontSize: "20px",
    fontFamily: "Segoe UI",
    paddingLeft: "5px",
  },
  cityCountry: {
    color: "#676767",
    fontSize: "20px",
    fontFamily: "Segoe UI",
  },
};
const StylesNegotiation = {
  cursor: {
    cursor: "pointer"
  },
  amendments: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
  },
  containerMain: { maxWidth: "1440px", margin: "0 auto" },
  childContainer: {
    padding: "20px 77px 20px 77px",
  },
  commonPadding: {
    padding: "0px 60px",
    paddingRight: "56px"
  },
  heading: {
    fontSize: "32px",
    fontFamily: "Segoe UI Bold",
    color: "#676767",
  },
  userName: {
    fontSize: "24px",
    fontFamily: "Segoe UI",
    color: "#676767",
  },
  hr: {
    width: "100%",
    height: "1px",
    backgroundColor: " #E5E7EB",
    border: "none",
    margin: "25px 0",
  },
  budget: {
    fontSize: "24px",
    fontFamily: "Segoe UI",
    color: "#676767",
  },
  price: {
    fontSize: "32px",
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
    margin: 0,
  },
  acceptButton: {
    width: "105px",
    height: "44px",
    border: "1px solid #B2B2B2",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#B2B2B2",
    textTransform: "none",
    background: "white",
  },
  Preference: {
    width: "105px",
    height: "44px",
    border: "1px solid #B2B2B2",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#B2B2B2",
    textTransform: "none",
  },
  PreferenceDeny: {
    width: "105px",
    height: "44px",
    border: "1px solid #B2B2B2",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#B2B2B2",
    textTransform: "none",
  },
  PreferenceOnClick: {
    width: "105px",
    height: "44px",
    border: "1px solid #3CAA49",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
    textTransform: "none",
  },
  PreferenceDenyOnClick: {
    width: "105px",
    height: "44px",
    border: "1px solid #0085FF",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#0085FF",
    textTransform: "none",
  },
  acceptButtonOnclick: {
    width: "105px",
    height: "44px",
    border: "1px solid #3CAA49",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#3CAA49",
    textTransform: "none",
    background: "white",
  },
  negotiateButton: {
    width: "105px",
    height: "44px",
    border: "1px solid #B2B2B2",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#B2B2B2",
    textTransform: "none",
    background: "white",
  },
  negotiateButtonOnClick: {
    width: "105px",
    height: "44px",
    border: "1px solid #0085FF",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#0085FF",
    textTransform: "none",
    background: "white",
  },
  requestButton: {
    width: "228px",
    height: "44px",
    border: "1px solid #B2B2B2",
    borderRedius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#B2B2B2",
    textTransform: "none",
  },
  textAreaStyle: {
    resize: "none",
    marginTop: "20px",
    padding: "10px",
    width: "100%",
    height: "92px",
    color: "black",
    border: "1px solid #E5E7EB",
    borderRadius: "8px",
  },
  negotiateButtonCommission: {
    width: "121px",
    height: "44px",
    border: "1px solid #B2B2B2",
    borderRadius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#B2B2B2",
    textTransform: "none",
    background: "white",
  },
  negotiateButtonCommissionOnClick: {
    width: "121px",
    height: "44px",
    border: "1px solid #0085FF",
    borderRadius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#0085FF",
    textTransform: "none",
  },
  secondHeading: {
    fontSize: "24px",
    fontFamily: "Segoe UI Bold",
    color: "#676767",
  },
  secondSecionText: {
    fontSize: "24px",
    fontFamily: "Segoe UI",
    color: "#676767",
    padding: "10px 0 40px 0",
    margin: 0,
  },
  dynamicData: {
    justifyContent: "end",
  },
  details: {
    padding: "20px 10px",
    alignItems: "center",
  },
  denyButtonOnClickss: {
    width: "105px",
    height: "44px",
    border: "1px solid #FE4023",
    borderRadius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#FE4023",
    textTransform: "none",
  },
  counterOffer: {
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Segoe UI Bold",
    color: "#676767",
  },
  counterOffers: {
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Segoe UI Bold",
    color: "#676767",
  },
  requestButtonOnClick: {
    width: "228px",
    height: "44px",
    border: "1px solid #0085FF",
    borderRadius: "4px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    color: "#0085FF",
    textTransform: "none",
  },
  dataTitile: {
    fontSize: "24px",
    fontFamily: "Segoe UI",
    color: "#676767",
    width: "100%",
    maxWidth: "90%",
  },
  createButtons: {
    border: "2px solid #3CAA49",
    background: "#FFFFFF",
    color: "#3CAA49",
    width: "180px",
    height: "56px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    borderRedius: "5px",
  },
  createButtons1: {
    color: "#FFFFFF",
    width: "250px",
    height: "56px",
    fontSize: "18px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    borderRedius: "5px",
  },
  createButtons2: {
    color: "#3CAA49",
    width: "199px",
    background: "#FFFFFF",
    height: "56px",
    fontSize: "14px",
    fontFamily: "Segoe UI Bold",
    textTransfer: "none",
    border: "1px solid #3CAA49",
    borderRedius: "5px",
  },
  actionsStyles: {
    width: "100%",
    padding: "10px 5px",
    margin: "auto",
    textAlign: "center",
  },
  popuptextsNegotiation: {
    fontSize: "26px",
    textAlign: "center",
    fontFamily: "Segoe UI Bold",
    color: "#0F172A",
    lineHeight: "28px",
  },
  overlaysNegotiation: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  contentsNegotiation: {
    backgroundColor: "#fff",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    maxWidth: "80%",
    padding: "20px",
    width: "580px",
    borderRadius: "8px",
    height: "400px",
  },
  contentsNegotiations: {
    backgroundColor: "#fff",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    maxWidth: "80%",
    padding: "20px",
    width: "526px",
    borderRadius: "8px",
    height: "322px",
  },
  deletePopuplogNegotiation: {
    marginTop: "85px",
    paddingLeft: "50px",
  },
  popuptextslogNegotiation: {
    fontSize: "26px",
    fontFamily: "Segoe UI Bold",
    color: "#0F172A",
    lineHeight: "28px",
  },
  popuptextssslogNegotiation: {
    fontSize: "20px",
    fontFamily: "Segoe UI",
    color: "#181818",
    LineHeight: "24px",
  },
  plateformExploreBtnssNegotiation: {
    height: "56px",
    width: "170px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "#ECF7ED",
    fontFamily: "Segoe UI Bold",
    fontSize: "20px",
    border: "none",
    marginRight: "15px",
    textTransform: "capitalize",
  },
  plateformListingBtnsNegotiation: {
    height: "60px",
    width: "150px",
    borderRedius: "4px",
    background: "#FFFFFFE5",
    color: "#3CAA49",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
    textTransform: "capitalize",
    border: "1px solid #3CAA49",
  },
  poupMainNegotiation: {
    padding: "10px 20px 20px 20px",
  },
  leftBoxsNegotiation: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  uploadTextsPopupNegotiation: {
    fontSize: "30px",
    fontFamily: "Segoe UI Bold",
    color: "#181818",
    textAlign: "center",
    paddingTop: "20px",
  },
  gotoNegotiation: {
    fontSize: "18px",
    fontFamily: "Segoe UI",
    color: "#676767",
    textAlign: "center",
    paddingTop: "20px",
  },
  textAreaDisable: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px 16px 24px",
    background: "#ECF7ED",
    borderRadius: "8px",
    marginTop: "20px",
  },
  textAreaDisable1: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px 16px 24px",
    background: "#ECF7ED",
    borderRadius: "8px",
    marginTop: "20px",
  },
  textAreaDisable2: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px 16px 24px",
    background: "#ECF7ED",
    borderRadius: "8px",
    marginTop: "20px",
  },
  textAreaDisables: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  spanText: {
    fontSize: "20px",
    fontFamily: "Segoe UI",
    color: "#676767",
  },
  spanText2: {
    fontSize: "20px",
    fontFamily: "Segoe UI",
    color: "#676767",
  },
  spanText11: {
    fontSize: "20px",
    fontFamily: "Segoe UI",
    color: "#676767",
  },
  checkText: {
    color: "#676767",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "24px",
  },
};

// Customizable Area End
