Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LocalListingAds";
exports.labelBodyText = "LocalListingAds Body";
exports.labelBodyText = "LocalListingAds Bodys";
exports.labelBodyText1 = "Looking Something";
exports.labelBodyText2 = "Selling Something text button";
exports.labelBodyTextss = "LocalListingAds add button Data";
exports.labelBodyTexts = "LocalListingAds add button";
exports.labelBodyTextsArea = "LocalListingAd add button Area";
exports.labelBodyTextsAreaShows = "LocalListingAds add button show Area";
exports.labelBodyTextsAreaShowText = "LocalListingAds add button show text";
exports.labelBodyTextsArea = "Listing add button Area";
exports.labelBodyTextsAreaType = "Listing add button Area Data";
exports.labelBodyTextsAreaTexts = "LocalListingAds add button Areas";
exports.labelBodyText = "LocalListingAds Body first";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End