import React from 'react';

export function RemoveListingPopup({ navigation }: any) {
  return (
    <div>
      <p style={styleD.popuptextsDeletes as React.CSSProperties} >Remove Saved Listing</p>
      <div>
        <p style={styleD.popuptextsss as React.CSSProperties} >Do you want to remove item from Saved Listing?</p>
      </div>
    </div>
  );
}

const styleD = {
  popuptextsDeletes: {
    fontSize: '32px',
    fontFamily: "Segoe UI Bold",
    color: '#0F172A',
    lineHeight: '32px',
    margin: '0',
  },
  popuptextsss: {
    fontSize: '18px',
    fontFamily: "Segoe UI",
    color: '#676767',
    width: '400px',
    LineHeight: '26px'
  },
};
