// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  styled,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import PaypalDetailsController, { Props } from "./PaypalDetailsController.web";

export default class PaypalDetails extends PaypalDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Wrapper>
        <Box>
          {this.state.loading ?
            <Box className="loader">
              <CircularProgress className="color" />
            </Box>
            : <Box className="padding">
              <hr className="hr-line" />
              <Box className="account">
                <Typography className="title">PayPal Account</Typography>
                {this.state.paypalEmail &&
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box className="email">{this.state.paypalEmail}</Box>
                    <Button className="button" data-test-id="update" onClick={() => this.loginWithPaypalAccount()}>Update</Button>
                  </Box>
                }
              </Box>
              {!this.state.paypalEmail &&
                <Box display="flex" justifyContent="center">
                  <Button className="button" data-test-id="add" onClick={() => this.loginWithPaypalAccount()}>Add</Button>
                </Box>
              }
              <hr className="hr-line" />
            </Box>}
        </Box>
      </Wrapper>
    );
  }
}

const Wrapper = styled(Box)({
  width: "100%",
  background: "rgb(247, 245, 245)",
  height: "calc(100% - 47px)",
  "& .loader": {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .color": {
      color: '#4fa94d'
    }
  },
  "& .account": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .padding": {
    padding: "30px 50px"
  },
  "& .hr-line": {
    border: "none",
    height: "1px",
    margin: "30px 0",
    backgroundColor: "rgba(203, 213, 225, 1)",
  },
  "& .title": {
    marginBottom: "15px",
    fontSize: "24px",
    fontFamily: "Segoe UI",
  },
  "& .email": {
    backgroundColor: "#fff",
    border: "1px solid #d5bfbf",
    padding: "15px 14px",
    borderRadius: "10px",
  },
  "& .button": {
    marginTop: "25px",
    height: "50px",
    width: "180px",
    borderRedius: "4px",
    background: "#3CAA49",
    color: "white",
    textTransform: "none",
    fontFamily: "Segoe UI Bold",
    fontSize: "18px",
  }
});
// Customizable Area End
